import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import jsPDF from "jspdf";
import ExportButtons from "../../../components/ExportButtons";
//Services
import * as Service from "../../../services/Service.js";
import Constants from "../../../services/Constants";
import swal from "sweetalert";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
  Typography,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";


import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";

import * as api from "../../../actions/api";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, ViewList, preview, Edit } from "@material-ui/icons";
import { cellFocusEditor } from "../../../services/Common";
import moment from "moment";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const IssueDetailsByItem = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const menukey = "form-2/3-submission-report";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;
  
  const classes = useStyles();
  let tblRef = null;
  const tableRef = useRef();
  const { useState } = React;
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();


  const [firstLoad, setFirstLoad] = useState(true);
  const currentDate = new Date();
  const [ManyJsonSave, setInputChange] = useState({});
  //const [radioValue, setRadioValue] = useState(2);
  const [productGroupData, selectProductGroupData] = useState();
  const [dataItems, setDataItems] = useState([]);
  const [currGroupCode, setcurrGroupCode] = useState(0);
  const [currSDPCode, setcurrSDPCode] = useState(0);

  const MonthList = JSON.parse(localStorage.getItem("MonthList"));
  
  let cMonthId = currentDate.getMonth();
  let cYearId = currentDate.getFullYear();
  let CurrentMonthId = 0;
  let CurrentYearId = 0;
  if(cMonthId === 0){
    CurrentMonthId = 12;
    CurrentYearId = cYearId-1;
  }else{
    CurrentMonthId = cMonthId;
    CurrentYearId = cYearId;
  }
  let CurrentMonthObj = MonthList[MonthList.findIndex(Month_List => Month_List.id == CurrentMonthId)];

  const [currMonthId, setcurrMonthId] = useState(CurrentMonthId);
  const [currYear, setcurrYear] = useState(CurrentYearId);

  //const [currMonthId, setcurrMonthId] = useState(currentDate.getMonth());
  //const [currYear, setcurrYear] = useState(currentDate.getFullYear());

  const [manyTableData, setManyTableData] = useState([]);
  const UseFor = localStorage.getItem("UseFor");
  

  const [chosenValuesMonth, setChosenValuesMonth] = useState({
    "MonthId": CurrentMonthObj
  });

  const handleChangeChoosenMonth = (name, valueobj, value) => {
    let chosenValuesDataMonth = { ...chosenValuesMonth};
    chosenValuesDataMonth[name] = valueobj;
    setChosenValuesMonth(chosenValuesDataMonth); 
  
    setFirstLoad(true);
    setLoading(false);
    setcurrMonthId(value);
  
  
  };

  
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const handleStartDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedEndDate(date);
  };
  const handleChangeMonth = (event) => {
    setFirstLoad(true);
    setLoading(false);
    setcurrMonthId(event.target.value);
  };
  const handleChangeYear = (event) => {
    setFirstLoad(true);
    setLoading(false);
    setcurrYear(event.target.value);
  };
  


 
  let params = {
    menukey: menukey,
    FacilityId: FacilityId,
    action: "getFrom23subbmissionreport",
    MonthId: currMonthId,
    Year: currYear,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
   
  };

  const [isLoading, setLoading] = useState(false);

  const { error, isError, data, refetch:stockData } = useQuery(
    [params],
    api.From23SubmissionReportList,
    {
      onSuccess: (data) => {
        if(firstLoad){
          /* localStorage.setItem(
            "StockData",
            JSON.stringify(data)
          );
           */
          setDataItems(data);
          setLoading(false);
          setFirstLoad(false);
        }
      },
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 0
  }
  ); 


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let formatedDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";


    window.open(
      finalUrl +
        "?action=From23subbmissionreportExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&MonthId=" +
        currMonthId +
        "&Year=" +
        currYear +
        "&FacilityId=" +
        FacilityId +
        "&FacilityName=" +
        FacilityName + 
        "&UseFor=" +
        UseFor + 
        "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */


  const columns = [
    {
      title: "Id",
      field: "id",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 5,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    
   
    {
      title: DispensingLanguage[lan][menukey]["SDP Group"],
      field: "FGroupName",
      headerFilter: false,
      width: 130,
    }, 
    {
      title: DispensingLanguage[lan][menukey]["Union Name"],
      field: "UnionName",
      headerFilter: false,
      width: 200,

    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Id"],
      field: "FacilityCode",
      headerFilter: false,
      width: 110,

    },
    
    {
      title: DispensingLanguage[lan][menukey]["Facility Name"],
      field: "FacilityName",
      headerFilter: true,
     
      
    },   
    
  ];




  const checkLogin = () => {
    let token = Constants.token;

    if (!token) {
      swal("Oops!", `token expired. Please login again`, "error");
      props.history.push("/login");
    }
  };


  // React.useEffect(() => {
  //   setcurrItemCode(0); 
  //   cItemList();
  // }, [currGroupCode]);


 React.useEffect(() => {
   if (tblRef == null) return;
   setLoading(true);
   stockData();
  }, [currMonthId, currYear]);

  return (
    <>
      <div className={classes.productPageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["From 2/3 Submission Report"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
             

            <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                      {/* <InputLabel id="">
                        {DispensingLanguage[lan][menukey]["Reported Month"]} 
                      </InputLabel>
                      <Select
                       // error={props.errorObject.ItemCode}
                        labelId="MonthId"
                        id="MonthId"
                        name="MonthId"
                        value={currMonthId}
                        fullWidth
                        onChange={handleChangeMonth}
                      
                      >
                
                        {MonthList.map((item, index) => {
                          return (
                           <MenuItem value={item.id}> {item.name}</MenuItem>
                          );
                        })}
                      </Select> */}

                          <Autocomplete
                            autoHighlight
                            disableClearable
                            id="MonthId"
                            options={MonthList}
                            onChange={(event, valueobj) => handleChangeChoosenMonth('MonthId', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValuesMonth['MonthId']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Reported Month"]}
                              variant="standard"
                              id="MonthId"
                              name="MonthId"
                              fullWidth
                            />
                            )}
                          />
                    </FormControl>
                  </Grid>

                  <Grid item xs={2} sm={2}>
                    <TextField
                    
                      id="Year"
                      name="Year"
                      label={
                        DispensingLanguage[lan][menukey][
                          "Reported Year"
                        ] 
                      }
                      value={currYear}
                      fullWidth
                      autoComplete="family-name"
                      onChange={handleChangeYear}
                    />
                  </Grid>

                  {/* <Grid item xs={3} sm={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      //disableToolbar
                      autoOk={true}
                      // disabled={true}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["Start Date"]}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={3} sm={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["End Date"]}
                      autoOk={true}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid> */}


              <Grid item xs={8} sm={8}>
                <div className="float-right sw_btn_control">

                <ExportButtons PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}  {...props}></ExportButtons>
                  {/* <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button> */}
                </div>
              </Grid>

            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}

          <div className="uniqueName">
            <ReactTabulator
              ref={(r) => (tblRef = r)}
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="400px"
              // groupBy="GroupName"
              options={{
                  groupBy: ["ReportStatus", "FGroupName"],
                  //groupBy: "GroupName",
                  columnCalcs: "both",
                }}
             // rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IssueDetailsByItem;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
