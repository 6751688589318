import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import swal from "sweetalert";
import { 
  Grid,
  TextField,
  Typography,
  Card,
  CardContent, 
  FormControl,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

import ExportButtons from "../../../components/ExportButtons";

import ImageModal from "../product_specification/ImageModal";


// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit,  ViewList, CloudUploadOutlined, PictureAsPdfOutlined,PermMediaOutlined } from "@material-ui/icons";
 
//const UserInfo = JSON.parse(sessionStorage.getItem("User_info"));

//Services
import * as Service from "../../../services/Service.js";
import ProductGroup from "../product_group/ProductGroup";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const ProductSpecification = (props) => {

const permissionType = props.permissionType; 
  //get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const FacilityId = localStorage.getItem("FacilityId");
const menukey = "product-specification-report";
const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo==0?'': UserInfo[0].name;

  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const [selectedRow, setSelectedRow] = useState(null);
  const [numberRowPerPage, setNumberRowPerPage] = useState(15);
  const [totalRow, setTotalRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  const [firstLoad, setFirstLoad] = useState(true);
  const [dataItems, setDataItems] = useState([]);

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const [isLoading, setLoading] = useState(true);


  

//PackageYearList.unshift({'YearId':0,'PackageYear':t(DispensingLanguage[lan][menukey]["All"])});

const PackageYearList = JSON.parse(localStorage.getItem("PackageYearList"));
PackageYearList.unshift({'YearId':0,'PackageYear':t(DispensingLanguage[lan][menukey]["All"])});

const CureentYearList =JSON.parse(localStorage.getItem("CureentYearList"));

const currentValue = (CureentYearList[0].id);
const currentYearName = (CureentYearList[0].name);

const [CurentsYearId, setYearIdData] = useState(currentValue);
//console.log('Test Indentor data',CurentsYearId);

const [CurentsIndentorId, selectIndentorData] = useState(0);
//console.log('curentindentor id',CurentsIndentorId);
let TempItemList = useState([]);
const [IndentorList, setIndentorItem] = useState(TempItemList);
//console.log('Test Indentor data',IndentorList);



const [PackageList, setPackageItem] = useState([]);
const [CurentsPackageId, selectPackageListData] = useState(0);
//console.log('Test Indentor data',CurentsPackageId);

const [currGroupCode, setcurrGroupCode] = useState(0);
const GroupNameList = JSON.parse(localStorage.getItem("GroupNameList"));
GroupNameList.unshift({'id':0,'name':t(DispensingLanguage[lan][menukey]["All"])});

let TempItemList1 = useState([]);
const [ItemList, setItemListLocal] = useState(TempItemList1);
const [currItemCode, setcurrItemCode] = useState(0);
const [SpecificationCount,setSpecificationCount] = useState(0);

  //====== Start Choosen Dropdown===========


  const [chosenValuesIndentor, setChosenValuesIndentor] = useState({
    "IndentorId": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
  
  });

  const [chosenValuesPackage, setChosenValuesPackage] = useState({
    "PackageNo": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
  
  });

const [chosenValuesYear, setChosenValuesYear] = useState({
   //  "YearId": {"YearId": "0","PackageYear": t(DispensingLanguage[lan][menukey]["All"])}
    "YearId": {"YearId": currentValue,"PackageYear": currentYearName}

});

const [chosenValues, setChosenValues] = useState({
  "GroupCode": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
});
const [chosenValuesItem, setChosenValuesItem] = useState({
  "ItemCode": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
});




const handleChangeChoosenIndentor = (name, valueobj, value) => {
  let chosenValuesDataIndentor = { ...chosenValuesIndentor};
  chosenValuesDataIndentor[name] = valueobj;
  setChosenValuesIndentor(chosenValuesDataIndentor); 

  setFirstLoad(true);
  setLoading(false);
  selectIndentorData(value);

  setChosenValuesPackage({
    "PackageNo": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
   }); 
 

 

};


const handleChangeChoosenPackage = (name, valueobj, value) => {
  let chosenValuesDataPackage = { ...chosenValuesPackage};
  chosenValuesDataPackage[name] = valueobj;
  setChosenValuesPackage(chosenValuesDataPackage); 

  setFirstLoad(true);
  setLoading(false);
  selectPackageListData(value);



};


const handleChangeChoosenYear = (name, valueobj, value) => {
  let chosenValuesDataYear = { ...chosenValuesYear};
  chosenValuesDataYear[name] = valueobj;
  setChosenValuesYear(chosenValuesDataYear); 

  setFirstLoad(true);
  setLoading(false);
  setYearIdData(value);

};


const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;
  setChosenValues(chosenValuesData); 

  setFirstLoad(true);
  setLoading(false);
  setcurrGroupCode(value);

};

const handleChangeChoosenItem = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValuesItem};
  chosenValuesData[name] = valueobj;
  setChosenValuesItem(chosenValuesData); 

  setFirstLoad(true);
  setLoading(false);
  setcurrItemCode(value);

};

//====== End Choosen Dropdown===========

const tableRowDoubleClick = (e, row) => {
  let rowData = row._row.data;
  editRecord(rowData);
};

  const {
    error1,
    isError1,
    data: limitData,
    refetch: limitRefetch,
  } = useQuery(
    [
      {
        action: "getTotal",
        menukey: menukey,
      },
    ],
    api.getTotalProductgroup,
    {
      onSuccess: (data) => {
        
        if(data!=undefined)
        setTotalRow(Math.ceil(data[0].TotalRow / numberRowPerPage));

      }, 
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    },{ 

      useErrorBoundary: (error) => error.response?.status >= 500,
    
    }
  );

  

  const [openOrder, setOpenOrder] = useState(false);

  let params = {
    action: "CatalogDataListRpt",
    menukey: menukey,
    page_limit: numberRowPerPage,
    page_number: pageNumber,
    search_key: "",
    CurentsYearId: CurentsYearId,
    CurentsIndentorId: CurentsIndentorId,
    CurentsPackageId: CurentsPackageId,
    CurentsProductGrpId: currGroupCode,
    CurentsItemId: currItemCode,
    userId:UserInfo[0].id,
  };
  const {
    error,
    isError,
    data: uomData,
    refetch: tableData,
  } = useQuery([params], api.getCatalogSpecificationListRpt, {
    onSuccess: (data) => {
      // setTotalRow(data.length);
      // setTotalRow(Math.round(data.length/numberRowPerPage));
      setDataItems(data);
      setSpecificationCount(data.length);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });



  

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=getProductSpecificationDataRpt" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" + localStorage.getItem("LangCode")+
        "&CurentsYearId=" + CurentsYearId +
        "&CurentsIndentorId=" + CurentsIndentorId +
        "&CurentsPackageId=" + CurentsPackageId + 
        "&CurentsProductGrpId="+currGroupCode+
        "&CurentsItemId="+currItemCode+
        "&userId="+ UserInfo[0].id +
        "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */
  let sl=0;
  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
     // formatter: "rownum",
      formatter: function (cell, formatterParams) {
        sl++;
        return (pageNumber-1)*numberRowPerPage+sl;
      },
      width: 70,
      frozen:true,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
     
    },/*{
    title: "",
   // field: "custom",
    hozAlign: "center",
    headerSort: false,
    width: 40,
    frozen:true,
    formatter: reactFormatter(<ActionButton />),
  },

  */
  
  
  
    
  {
    title: "specificationId",
    field: "id",
    width: 70,
    headerSort: false,
    visible:false
     
  },{
    title:  DispensingLanguage[lan][menukey]["Year"],
    field: "PackageYear",
    width: 90,
    headerFilter:true
  },
  /*
  {
    title:  DispensingLanguage[lan][menukey]["Indentor"],
    field: "IndentorName",
    width: 120,
    headerFilter:true
  },
  */
  { title: DispensingLanguage[lan][menukey]["Package"],
   headerFilter:true, field: "PackageName",width: 160, },
   { 
   
    title: DispensingLanguage[lan][menukey]["Item Code"],
    headerFilter:true, field: "ItemCode", width: 105, },

   { 
   
    title: DispensingLanguage[lan][menukey]["Item Name"],
    headerFilter:true, field: "ItemName" },

    { 
     
      title: DispensingLanguage[lan][menukey]["Lot No"],
      headerFilter:true, field: "LotNo",width: 150 },


   {
    
    title: DispensingLanguage[lan][menukey]["Specification Image"],
    field: "custom",
    hozAlign: "center",
    headerSort: false,
    width: 105,
   // frozen:true,
    formatter: reactFormatter(<ActionButtonForSpecificationImage />),

  },
   
  
   {
  
    title: DispensingLanguage[lan][menukey]["Product Image"],
    field: "custom",
    hozAlign: "center",
    headerSort: false,
    width: 105,
    //frozen:true,
    formatter: reactFormatter(<ActionButtonForProductImage />),


  },

  { 
     
    title: DispensingLanguage[lan][menukey]["Status"],
    headerFilter:true, field: "CatalogStatus",
    width: 120,
  
  },

  {
    title: DispensingLanguage[lan][menukey]["Actions"],
    field: "custom",
    hozAlign: "center",
    width: 85,
    headerSort: false,
    headerHozAlign: "center",
    formatter: reactFormatter(<ActionButtonForEditView />),
  },
     
    // { title: DispensingLanguage[lan][menukey]["Product Group Name"],
    //  headerFilter:true, field: "Specification" },
    // { title: DispensingLanguage[lan][menukey]["Product Group Name"],
    //  headerFilter:true, field: "ProductImage" },
   
    
    
  ];

  const editRecord = (data) => {
    let route = `product-specification-report/edit/${data.id}`;
    props.history.push(route);
  };

  

  function ActionButtonForEditView(props) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
   
      return (
        <>
          <ViewList
            onClick={() => {
              editRecord(rowData);
            }}
          />
        </>
      );
    

  }

  const handleChange = (event, value) => {
    console.log("value: ", value);
    console.log("event: ", event);

    setPageNumber(value);
  };
  const handleChangeRowsPerPage = (event) => {
    // setNumberRowPerPage(parseInt(event.target.value, 10));
    //console.log('event.target.value: ', event.target.value);
    // setPageNumber(value);
  };
 



  
  const viewBrand = (data) => {
    let route = `certificate-manufacturer-authorization/brand-view/${data.Id}`;
    props.history.push(route);
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    return (
      <>
        <ViewList
          onClick={() => {
            viewBrand(rowData);
          }}
        />
      </>
    );
  }

  const editManufacturerAuthorization = (data) => {
    let route = `product-specification/manufacturer-authorization/${data.specificationId}`;
    props.history.push(route);
  };
  const handleModal = (modalname) => {
    switch (modalname) {
       

        case "ImageModal":
          setOpenImage(false);
          break;
        case "ImageOpen":
          setOpenImage(true);
          break;  
      default:
        break;
    }
  };

  const [openImage, setOpenImage] = useState(false);
  const [imageName, SetImageName] = useState('');
  const removeStrFromURL = process.env.REACT_APP_API_URL;
  const REPORT_URL = removeStrFromURL.replace('backend/', '');

  /*
  function ActionButtonForProductImage(props) {
    const rowData = props.cell._cell.row.data;
    let ProductImage = rowData.ProductImage;
   

    if(ProductImage !=null){
      return (
        <>
          <div className="image_color">
            <PermMediaOutlined
            onClick={() => {
              let image=REPORT_URL+'/media/CatalogDoc/'+ProductImage;
               SetImageName({ItemName:rowData.ItemName,ProductImage:image});
               handleModal("ImageOpen")
               //UploadFileDownload(ProductImage);
             }}
               
            />
          </div>
        </>
      );
    }else{
      return (<></>);
    }
  }

  */

  function ActionButtonForProductImage(props) {
    const rowData = props.cell._cell.row.data;
    let ProductImage = rowData.ProductImage;

    if(ProductImage != null){
      return (
        <>
          <div className="image_color">
            <PermMediaOutlined
              onClick={() => {
               let image=REPORT_URL+'/media/CatalogDoc/'+ProductImage;
               let row=[];
               if(ProductImage!=''){

                let imagename=ProductImage.split('|');
                
                for(let i=0;i<imagename.length;i++){     
                  row.push(REPORT_URL+'/media/CatalogDoc/'+imagename[i]);   
            
                } 


               }
               SetImageName({ItemName:rowData.ItemName,ProductImage:'',ImageArray:row,YearName:rowData.PackageYear,PackageName:rowData.PackageName});
                handleModal("ImageOpen")
                //UploadFileDownload(ProductImage);
              }}
            />
          </div>
        </>
      );
    }else{
      return (<></>);
    }
  }

  const editCertificate = (data) => {
    let route = `product-specification/certificate/${data.specificationId}`;
    props.history.push(route);
  };

  /*
  function ActionButtonForCertificate(props) {
    const rowData = props.cell._cell.row.data;
    let Specification = rowData.Specification;

    let pdffileColor = '';
    if(Specification != null){
      pdffileColor = 'theme_color';
    }else{
      pdffileColor = 'black_color';
    }

    return (
      <>
        <div className={pdffileColor}>
          <CloudUploadOutlined
            onClick={() => {
              editCertificate(rowData);
            }}
          />
        </div>
      </>
    );
  }
  */

  const UploadFileDownload = (uploadFile) => {

    //let authCertPath = GlobalSettings.auth_cert;
   
    const removeStrFromURL = process.env.REACT_APP_API_URL;
    const REPORT_URL = removeStrFromURL.replace('backend/', '');
  
     let finalUrl = REPORT_URL + "media/CatalogDoc/"+uploadFile;
    //let finalUrl = REPORT_URL + '/'+uploadFile;
    window.open(finalUrl);
  };

  function ActionButtonForSpecificationImage(props) {
    const rowData = props.cell._cell.row.data;
    let Specification = rowData.Specification;

    if(Specification != ""){
      return (
        <>
          <div className="pdf_color">
            <PictureAsPdfOutlined
              onClick={() => {
                UploadFileDownload(Specification);
              }}
            />
          </div>
        </>
      );
    }else{
      return (<></>);
    }
  }


  React.useEffect(() => {
    selectIndentorData(0); 
    IndentorItemList();
  }, [CurentsYearId]);



    const IndentorItemList = () => {  
  
      let IndentorItemList = {action:"getIndentorList", CurentsYearId:CurentsYearId, FacilityId: FacilityId, menukey:""}
  
      Service.default
          .postApi("source/combo_generic.php",IndentorItemList)
          .then((res) => { 

            let All_Indentor_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
            const Indentor_List = [All_Indentor_label].concat(res.datalist); 

            /* setChosenValuesPackage({
              "PackageNo": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
             });  */
            
            setIndentorItem(Indentor_List);

          })
          .catch((err) => {});
    
  }


  React.useEffect(() => {
    selectPackageListData(0); 
    setPackageItemData();
  }, [CurentsYearId,CurentsIndentorId]);

    const setPackageItemData = () => {  
  
      let getPackegeListParam = {action:"getPackegeList", CurentsIndentorId:CurentsIndentorId,CurentsYearId:CurentsYearId, FacilityId: FacilityId, menukey:""}
  
      Service.default
          .postApi("source/combo_generic.php",getPackegeListParam)
          .then((res) => { 

            let All_Packege_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
            const All_Packege_label_List = [All_Packege_label].concat(res.datalist); 
            setPackageItem(All_Packege_label_List); 
          })
          .catch((err) => {});
    
  }


  const cItemList = () => {  
  
    let ItemListParam = {action:"getItemListCatalog", GroupCode:currGroupCode, FacilityId: FacilityId, menukey:""}

    Service.default
        .postApi("source/combo_generic.php",ItemListParam)
        .then((res) => { 

          let All_Item_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
          const Item_List = [All_Item_label].concat(res.datalist); 

          setChosenValuesItem({
           "ItemCode": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
          }); 

          setItemListLocal(Item_List);
          //setcurrstockitem('0');

          // setItemListLocal(res.datalist); 
        })
        .catch((err) => {});
  
}
  

  React.useEffect(() => {
    setcurrItemCode(0); 
    cItemList();
  }, [currGroupCode]);

  return (
    <>
      <div className={classes.dispenserPageTitle}>


      <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Product Specification Report"]}
              </div>
            </div>
          </Grid>
        </div>



        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                                       
                        <Autocomplete
                          autoHighlight
                          disableClearable
                          id="YearId"
                          options={PackageYearList}
                          onChange={(event, valueobj) => handleChangeChoosenYear('YearId', valueobj, valueobj?valueobj.YearId:"")}
                          getOptionLabel={(option) => option.PackageYear}
                          value={chosenValuesYear['YearId']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.PackageYear}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Select Year"]}
                            variant="standard"
                            id="YearId"
                            name="YearId"
                            fullWidth
                          />
                          )}
                        />
                </FormControl>
              </Grid>
                
              <Grid item xs={2} sm={2} className="display_none">
                    <FormControl className={classes.fullWidth}>
                   

                        <Autocomplete
                          autoHighlight
                          disableClearable
                          id="IndentorId"
                          options={IndentorList}
                          onChange={(event, valueobj) => handleChangeChoosenIndentor('IndentorId', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValuesIndentor['IndentorId']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Select Indentor"]}
                            variant="standard"
                            id="IndentorId"
                            name="IndentorId"
                            fullWidth
                          />
                          )}
                        />

                        </FormControl>
                </Grid>
                <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                    {/* <InputLabel id="demo-simple-select-helper-label">
                    { DispensingLanguage[lan][menukey]['Select Package'] } 
                    </InputLabel>
                    <Select
                      required
                      labelId="demo-simple-select-helper-label"
                      id="PackageNo"
                      name="PackageNo"
                     value={CurentsPackageId}
                      fullWidth
                      onChange={PackagehandleChange}
                    >
                      <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                    {PackageList.map((item, index) => {
                      return (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      );
                    })}
                  </Select> */}

                        <Autocomplete
                          autoHighlight
                          disableClearable
                          id="PackageNo"
                          options={PackageList}
                          onChange={(event, valueobj) => handleChangeChoosenPackage('PackageNo', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValuesPackage['PackageNo']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Select Package"]}
                            variant="standard"
                            id="PackageNo"
                            name="PackageNo"
                            fullWidth
                          />
                          )}
                        />


                        </FormControl>
                      </Grid>
                
                
                      <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  {/* <InputLabel id="">
                    {DispensingLanguage[lan][menukey]["Group"]} 
                  </InputLabel>
                  <Select
                 
                    labelId="GroupCode"
                    id="GroupCode"
                    name="GroupCode"
                 
                    value={currGroupCode}
                    fullWidth
                    onChange={handleChange}
                    disabled={
                      manyTableData.length > 0 ? true : props.bStockUpdate
                    }
                  >
                   
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                    {GroupNameList.map((item, index) => {
                      return (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      );
                    })}
                  </Select> */}

                        <Autocomplete
                          autoHighlight                  
                          disableClearable
                          id="GroupCode"
                          options={GroupNameList}
                          onChange={(event, valueobj) => handleChangeChoosen('GroupCode', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValues['GroupCode']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            //value={props.formData.GroupCode}
                            label={DispensingLanguage[lan][menukey]["Item Group"]}
                            variant="standard"
                            id="GroupCode"
                            name="GroupCode"
                            fullWidth
                          />
                          )}
                        />

                </FormControl>
                  </Grid>

                   <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                     {/*  <InputLabel id="">
                        {DispensingLanguage[lan][menukey]["Item"]} 
                      </InputLabel>
                      <Select
                       // error={props.errorObject.ItemCode}
                        labelId="ItemCode"
                        id="ItemCode"
                        name="ItemCode"
                        value={currItemCode}
                        fullWidth
                        onChange={handleChangeItemCode}
                        disabled={
                          manyTableData.length > 0 ? true : props.bStockUpdate
                        }
                      >
                        
                        <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                        {ItemList.map((item, index) => {
                          return (
                           <MenuItem value={item.id}> {item.name}</MenuItem>
                          );
                        })}
                      </Select> */}

                          <Autocomplete
                            autoHighlight                       
                            disableClearable
                            id="ItemCode"
                            options={ItemList}
                            onChange={(event, valueobj) => handleChangeChoosenItem('ItemCode', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValuesItem['ItemCode']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Item Name"]}
                              variant="standard"
                              id="ItemCode"
                              name="ItemCode"
                              fullWidth
                            />
                            )}
                          />

                    </FormControl>
                  </Grid>


              <Grid item xs={1} sm={1}>
               <h4 className="FontStyle">{DispensingLanguage[lan][menukey]['Total']+": "+SpecificationCount}</h4>
              </Grid>    
                

              <Grid item xs={3} sm={3}>
                <div className="float-right sw_btn_control">
                  
                <ExportButtons PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}  {...props}></ExportButtons>

                  {/* <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button> */}
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        {/*
        <div className="d-flex justify-dispenser mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title_inner">
                {" "}
                {
                  DispensingLanguage[lan][menukey]["Product Specification Entry Form"]
                }{" "}
              </div>
              <div className="float-right sw_btn_control">
                { <Button
                  color="info"
                  className="mr-2"
                  onClick={() => handleModal("OrderOpen")}
                  // onClick={() => {
                  //   props.history.push("/product-group/add");
                  // }}
                >
                  {DispensingLanguage[lan][menukey]["Add Product Specification"]}
                </Button>}
                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i class="fas fa-print"></i>
                </Button>

                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button>
              </div>
            </div>
          </Grid>
        </div>

        */}

        <div>
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="455px"
              /*
              options={{
                groupBy: "GroupName",
                columnCalcs: "both",
              }}
              */
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
          {/*
          <div className="marginTop20">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Pagination
                  className="pull-right"
                  count={totalRow}
                  variant="outlined"
                  shape="rounded"
                  page={pageNumber}
                  rowsPerPage={numberRowPerPage} 
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </div>
          */}

           <ImageModal
          handleClose={handleModal}
          openOrder={openImage}
          addOrder={imageName}
          {...props}
        />
        


        </div>
      </div>
    </>
  );
};

export default ProductSpecification;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
