import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import jsPDF from "jspdf";

//Services
//import * as Service from "../../../services/Service.js";
import Constants from "../../../services/Constants";
import swal from "sweetalert";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
  Typography,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormLabel,
  FormHelperText,

} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ExportButtons from "../../../components/ExportButtons";

import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";

import * as api from "../../../actions/api";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, ViewList, preview, Edit } from "@material-ui/icons";
import { cellFocusEditor } from "../../../services/Common";
import moment from "moment";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";



const StockSummary = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const menukey = "sdp-active/inactive-report";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;

  const classes = useStyles();
  let tblRef = null;
  const tableRef = useRef();
  const { useState } = React;
  const [firstLoad, setFirstLoad] = useState(true);
  const [ManyJsonSave, setInputChange] = useState({});
  const [radioValue, setRadioValue] = useState(1);
  const [productGroupData, selectProductGroupData] = useState(1);
  const [dataItems, setDataItems] = useState([]);


  const [manyTableData, setManyTableData] = useState([]);
  const GroupNameList = JSON.parse(localStorage.getItem("GroupNameList"));
  //GroupNameList.unshift({'id':0,'name':"All"});

  const StockItemName1 = JSON.parse(localStorage.getItem("StockItemName"));
  const [ItemnameList, setStockItemName] = useState([]);
  const [currStockItem, setcurrstockitem] = useState(0);
  const UseFor = localStorage.getItem("UseFor");
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  let params = {
    menukey: menukey,
    FacilityId: FacilityId,
    action: "getSDPActiveInactiveReport",
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    bShow: radioValue,
   
  };

  const [isLoading, setLoading] = useState(false);

  const { error, isError, data, refetch:stockData } = useQuery(
    [params],
    api.SDPActiveInactiveReportList,
    {
      onSuccess: (data) => {
        if(firstLoad){
         
          setDataItems(data);
          setLoading(false);
          setFirstLoad(false);
        }
      },
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 0
  }
  ); 






  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let formatedDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=SDPActiveInactiveReportExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&bShow=" +
        radioValue +
        "&FacilityId=" +
        FacilityId +
        "&FacilityName=" +
        FacilityName + 
        "&UseFor=" +
        UseFor + 
        "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */


  function RowInputData(props, type = null) {
    const rowData = props.cell._cell.row.data;
    let fieldName = props.field;
    let fieldType = props.fieldType;
   // let labelName = props.labelName;
   // let dropDownKey = props.dropDownKey;

    switch (fieldType) {
      case "check-box":
        return rowData.type == "bDispensing" ? (
          <></>
        ) : (
          <Checkbox
            color="primary"
            checked={rowData[fieldName]}
            name={fieldName}
            value={rowData[fieldName]}
          />
        );
        break;

      default:
        return <></>;
        break;
    }
    return <></>;
  }


  const columns = [
    {
      title: "Id",
      field: "id",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: "4%",
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["SDP Code"],
      field: "FacilityCode",
      headerFilter: true,
      width: "8%",
    },
    {
      title: DispensingLanguage[lan][menukey]["SDP Name"],
      field: "FacilityName",
      headerFilter: true,
      width: "15%",
    },
    // {
    //   title: DispensingLanguage[lan][menukey]["Union"],
    //   field: "UnionName",
    //   headerFilter: true,
    //   width: "8%",
    // }, 
    {
      title: DispensingLanguage[lan][menukey]["In Charge"],
      width: "7%",
      headerHozAlign: "center",
      field: "incharge",
      hozAlign: "center",
      formatter: reactFormatter(
        <RowInputData
          labelName="bDispensing"
          field="incharge"
          fieldType="check-box"
        />
      ),
    },
    {
      title: DispensingLanguage[lan][menukey]["Name"],
      field: "StaffName",
      headerFilter: true,
      width: "6%",

    },
    {
      title: DispensingLanguage[lan][menukey]["Mobile No"],
      field: "PhoneNo",
      headerFilter: true,
      width: "8%",
    }, 
    {
      title: DispensingLanguage[lan][menukey]["Birth Date"],
      field: "dateofbirth",
      headerFilter: true,
      width: "8%",
    }, 
    {
      title: DispensingLanguage[lan][menukey]["Joining Date"],
      field: "dateofjoin",
      headerFilter: true,
      width: "9%",
    }, 
    {
      title: DispensingLanguage[lan][menukey]["Designation"],
      field: "StaffDesignation",
      headerFilter: true,
      width: "8%",
    }, 
    {
      title: DispensingLanguage[lan][menukey]["National Id"],
      field: "NationalId",
      headerFilter: true,
      width: "8%",
    }, 
    {
      title: DispensingLanguage[lan][menukey]["HRIS ID"],
      field: "HRIS",
      headerFilter: true,
      width: "8%",
    }, 
    {
      title: DispensingLanguage[lan][menukey]["Unit"],
      field: "sunitname",
      headerFilter: true,
      width: "8%",
    }, 
    {
      title: DispensingLanguage[lan][menukey]["Facility"],
      field: "fpfacility",
      headerFilter: true,
      width: "8%",
    }, 

  ];

 


  const checkLogin = () => {
    let token = Constants.token;

    if (!token) {
      swal("Oops!", `token expired. Please login again`, "error");
      props.history.push("/login");
    }
  };


  const changeRadio = (value) => {
    setFirstLoad(true);
    
    setRadioValue(value);
    // setLoading(true);
  };

 React.useEffect(() => {
   if (tblRef == null) return;
   setLoading(true);
   stockData();
  }, []);

  return (
    <>
      <div className={classes.productPageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["SDP Active/Inactive Report"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
             
            <Grid item xs={10} sm={10}>
                <FormControl component="fieldset" className="mt-3 ml-3">
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="row-radio-buttons-group"
                    defaultValue="SHOW_NONE_ZERO"
                  >
                     <FormControlLabel
                      value="SHOW_NONE_ZERO"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["Active SDP"]}
                      onClick={() => changeRadio(1)}
                    />
                   
                    <FormControlLabel
                      value="SHOW_ALL"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["Inactive SDP"]}
                      onClick={() => changeRadio(0)}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

                 

              <Grid item xs={2} sm={2}>
                <div className="float-right sw_btn_control">

                <ExportButtons PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}  {...props}></ExportButtons>
                  {/* <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button> */}
                </div>
              </Grid>


             


            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}

          <div className="uniqueName">
            <ReactTabulator
              ref={(r) => (tblRef = r)}
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="400px"
              // groupBy="GroupName"
              options={{
                  groupBy: ["FGroupName","UnionName"],
                  columnCalcs: "both",
                }}
             // rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StockSummary;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
