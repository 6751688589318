import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";

import ProductSpecificationFormData from "./ProductSpecificationFormData";




const AddProductSpecification = (props) => {
  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "product-specification-report";
const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;  
const UserName = UserInfo==0?'': UserInfo[0].id;

const [firstLoad, setFirstLoad] = useState(true);

const group = props.match.params.id;
const [showgroup, setGroupCode] = useState(group);

//console.log("ddddddddddddddd",props);



const [ItemName, setItemName] = useState('');
const [UnitName, setUnitName] = useState('');
const [gItemCode, setgItemCode] = useState('');


const CureentYearList =JSON.parse(localStorage.getItem("CureentYearList"));

const currentValue = (CureentYearList[0].id);
const currentYearName = (CureentYearList[0].name);


let params={
  "menukey": "product-specification",
  ItemCode: props.match.params.id,
   action: "getItemName"
};



  const [formData, setFormData] = useState({
    ItemCode: props.match.params.id,
    PackageNo:"",
    Specification:"",
    ProductImage:"",
    YearId:currentValue,
    ItemName:ItemName,
    UnitName:UnitName,
    SupCode:"",
    ManufacturerId:"",
    EstimatedCurrencyId:"",
    LotNo:""
  });
  const [errorObject, setErrorObject] = useState({});
  const [pricebesideLevel, setPricebesideLevel] = useState(null);
  const { id } = useParams();
  const queryClient = useQueryClient();



  // console.log(params);
const {  data:gFacilityCode, refetch } = useQuery([params], api.getItemName, 
  {onSuccess: (data) => {
    console.log(data);
    
    setItemName(data[0]['ItemName']);
    setUnitName(data[0]['UnitName']);
    //setgItemCode(props.match.params.id);
    //setItemCode(showgroup);
    let fdata = { ...formData }; 
    fdata["ItemName"] = data[0]['ItemName']; 
    fdata["UnitName"] = data[0]['UnitName']; 
  
    //fdata["ItemCode"] = props.match.params.id; 
    setFormData(fdata); 
    setFirstLoad(false);

  },
  refetchOnWindowFocus: false,
  refetchOnmount: false,
  refetchOnReconnect: false,
  retry: false,
  staleTime: 0,//1000 * 60 * 60 * 24,
  }
  );


  


  const { data } = useQuery(
    ["productspecification", id],
    () =>
      api.getProductSpecification(id).then((res) => {
 
        res.data['YearId']=currentValue;
        setFormData(res.data);
        return res.data;
      }),
    {
    
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
    enabled: Boolean(id)

    }
  );

  const { isLoading, mutate } = useMutation(api.saveProductSpecification, {
    onSuccess: (data) => {
      if (data.status == 200) {
        
        
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
       // swal(data.data.message, "", "success");
      queryClient.getQueriesData("productspecification");//api page name
      props.history.push("/product-specification");//

      }else{   
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    console.log("formData: ", formData);
    data[name] = value;

    if((name=='procQty')||(name=='PurchasePrice')||(name=='ExchangeRate')){
      let procQty=data['procQty']==null?0:data['procQty'];
      let PurchasePrice=data['PurchasePrice']==null?0:data['PurchasePrice'];
      let ExchangeRate=data['ExchangeRate']==null?0:data['ExchangeRate'];
      data['TotalBDT'] =(procQty*PurchasePrice*ExchangeRate).toFixed(2);
    }

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  };

  //====== Start Choosen Dropdown===========
  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value; 
    if(name=='CurrencyId') {
      if(value==1){
        data['ExchangeRate']=1;
        let procQty=data['procQty']==null?0:data['procQty'];
        let PurchasePrice=data['PurchasePrice']==null?0:data['PurchasePrice'];
        let ExchangeRate=data['ExchangeRate']==null?0:data['ExchangeRate'];
        data['TotalBDT'] =(procQty*PurchasePrice*ExchangeRate).toFixed(2);

      }else{

        data['ExchangeRate']='';
        
        data['TotalBDT'] =0;

      }
     
    } 
    setFormData(data); 
    setErrorObject({ ...errorObject, [name]: "" });
    api.dirtyModeEnable();
  }
  //====== End Choosen Dropdown===========

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      PackageNo:"",
      YearId:"",
      ItemCode:props.match.params.id,
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["PackageNo","YearId","UnitName"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =   DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleSubmit = async (e) => {

    //console.log("ssssssssssssssssss",formData);


    let fdata = { ...formData }; 
    fdata["ItemCode"] = showgroup; 

    //setFormData(fdata); 

    //console.log("ssssssssssssssssssrrrrrrrrrrrrrrrrr",fdata);
    
    if (validateForm(fdata)) {



      let fDtat={...fdata,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat);
     
    }
  };

  return (
    <>
      <ProductSpecificationFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        {...props}
      />
    </>
  );
};

export default AddProductSpecification;

