import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";
import ExportButtons from "../../../components/ExportButtons";

const DDSkitItemSerial = (props) => {
  const permissionType = props.permissionType;
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "dds-kit-item-serial-view";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;

  const classes = useStyles();

  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { data, refetch } = useQuery("DDSKitItemList", api.getDDSkitItemSerialLists, {
    onSuccess: (data) => {
      setDataItems(data);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });

  const { mutate: deleteApi } = useMutation(api.deleteAssetUnit, {
    onSuccess: (data) => {
      if (data.data.status == 200) {
        // api.getAllDropdown("ABC_List").then((response) => {
        //   localStorage.setItem(
        //     "ABC_List",
        //     JSON.stringify(response.datalist.ABC_List)
        //   );
        // });

        refetch();
        queryClient.getQueriesData("DDSKitItemList");

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=DDSKitItemListExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  // const tableRowDoubleClick = (e, row) => {
  //   let rowData = row._row.data;
  //   editunit(rowData);
  // };

  const columns = [
    { title: "Id", field: "id", width: 50, visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#."],
      formatter: "rownum",
      width: 80,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Code"],
      field: "ProdCode",
      width: 160,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Name"],
      field: "ProdText",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Unit"],
      field: "UnitName",
    //   headerFilter: true,
      width: 160,
    },
    {
      title: DispensingLanguage[lan][menukey]["Quantity(Pcs)"],
      field: "MulFactor",
      hozAlign: "right",
      headerHozAlign: "right",
      validator: ["integer", "min:0"],
      formatter: "money",
      formatterParams: { thousand: ",", precision: false },
    //   headerFilter: true,
      width: 160,
    },
    // {
    //   title: DispensingLanguage[lan][menukey]["Actions"],
    //   field: "custom",
    //   hozAlign: "center",
    //   width: 133,
    //   headerSort: false,
    //   headerHozAlign: "center",
    //   formatter: reactFormatter(<ActionButton />),
    // },
  ];

  const editunit = (data) => {
    let route = `dds-kit-item-serial-view/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteunit = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Once deleted, you will not be able to recover this Record!"
        ]
      ),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willDelete) => {
      if (willDelete) {
        deleteApi({
          SectionId: data.id,
          menukey: menukey,
          lan: lan,
          UserName: UserName,
          action: "dataDalete",
        });
      }
    });
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    // const cellValue = props.cell._cell.value || "Edit | Show";
    if (permissionType) return <></>;
    else
      return (
        <>
          <Edit
            onClick={() => {
              editunit(rowData);
            }}
          />
          {/* {
            <DeleteOutline
              onClick={() => {
                deleteunit(rowData);
              }}
            />
          } */}
        </>
      );
  }

  return (
    <>
      <div className={classes.dispenserPageTitle}>
        <div className="d-flex justify-dispenser mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["DDS Kit Item Serial List"]}
              </div>
              <div className="float-right sw_btn_control">
                {/* <Button
                  disabled={permissionType}
                  color="info"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/dds-kit-item-serial-list/add");
                  }}
                >
                  {DispensingLanguage[lan][menukey]["Add"]}
                </Button> */}

                <ExportButtons
                  PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}
                  {...props}
                ></ExportButtons>
                {/* <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i class="fas fa-print"></i>
                </Button>

                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button> */}
              </div>
            </div>
          </Grid>
        </div>
        <div>
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="550px"
              // rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DDSkitItemSerial;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
