import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import swal from "sweetalert";
import {
  Typography,
  Grid,
  FormControlLabel,
  TextField,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Adjust, DeleteOutline, Edit, ViewList } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import ExportButtonsMany from "../../../components/ExportButtonsMany";
//react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import { useTranslation } from "react-i18next";
import ProductsModal from "../../../services/ProductsModal";
import ProductsLotModal from "../../../services/ProductsLotModal";
import ProductsLotUnusableModal from "../../../services/ProductsLotUnusableModal";
import SourceModal from "../../../services/SourceModal";

import {
  cellFocusEditor,
  dateEditor,
  dateEditorAllowPast,
  dateEditorAllowFuture,
} from "../../../services/Common";

import MomentUtils from "@date-io/moment";
import moment from "moment";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

const AdjustmentFacilityFormData = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "adjustment-facility";
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");

  const FacilityEmployee = JSON.parse(localStorage.getItem("FacilityEmployee"));

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  let ReceiveDetailsRef = null;
  let adjId = null;

  const [hasTimeElapsed, setHasTimeElapsed] = React.useState(false);

  const FacilityLabel = DispensingLanguage[lan][menukey]["Facility"];
  const WarehouseLabel = DispensingLanguage[lan][menukey]["Warehouse"];
  const SupplierLabel = DispensingLanguage[lan][menukey]["Supplier"];
  const ReceivedByLabel = DispensingLanguage[lan][menukey]["Received By"];
  const ReceivedDateLabel = DispensingLanguage[lan][menukey]["Received Date"];
  const IssuedByLabel = DispensingLanguage[lan][menukey]["Issued By"];
  const IssuedDateLabel = DispensingLanguage[lan][menukey]["Issued Date"];

  const [open, setOpen] = useState(false);
  const [openLot, setOpenLot] = useState(false);
  const [openLotUnusable, setOpenLotUnusable] = useState(false);
  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);
  const [bIsPositive, setbIsPositive] = useState();

  const [gAdjTypeId, setgAdjTypeId] = useState();

  const UseFor = localStorage.getItem("UseFor");
  let gFacilityLabel = "";
  if (UseFor === "UIMS") {
    gFacilityLabel = DispensingLanguage[lan][menukey]["To/From"];
  } else {
    gFacilityLabel = FacilityLabel;
  }
  const [FacilityWarehouseSupplierLabel, setFacilityWarehouseSupplierLabel] =
    useState(gFacilityLabel);
  const [ReceivedIssuedbyLabel, setReceivedIssuedbyLabel] =
    useState(ReceivedByLabel);
  const [ReceivedIssuedDate, setReceivedIssuedDate] =
    useState(ReceivedDateLabel);

  const [IssueToFacility, setIssueToFacility] = useState([]);
  const [swipeIssueToFacility, setswipeIssueToFacility] = useState([]);
  const [IssueToFacilityVal, setIssueToFacilityVal] = useState(false);
  const [firstIssueToFacility, setfirstIssueToFacility] = useState("");
  const [currentAdjType, setcurrentAdjType] = useState("");

  const [bDisableAddProduct, setDisableAddProduct] = useState(
    props.DisableAddBtn
  );
  const AdjType = JSON.parse(localStorage.getItem("AdjType"));

  const [SoureOpen, setSoureOpen] = useState(false);
  const [currRowId, setCurrRowId] = useState(0);

  //====== Start Choosen Dropdown===========
  const [chosenValues, setChosenValues] = useState({
    ApprovedBy: { EmpCode: "", EmpName: "" },
    PreparedBy: { EmpCode: "", EmpName: "" },
    IssuedBy: { EmpCode: "", EmpName: "" },
    AdjTypeId: { id: "", AdjType: "" },
  });

  const [chosenValuesFacility, setChosenValuesFacility] = useState({
    FromFacility: { id: "", name: "" },
  });

  useEffect(() => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData["ApprovedBy"] =
      FacilityEmployee[
        FacilityEmployee.findIndex(
          (facilityEmployee_list) =>
            facilityEmployee_list.EmpCode == props.formData.ApprovedBy
        )
      ];
    chosenValuesData["PreparedBy"] =
      FacilityEmployee[
        FacilityEmployee.findIndex(
          (facilityEmployee_list) =>
            facilityEmployee_list.EmpCode == props.formData.PreparedBy
        )
      ];
    chosenValuesData["IssuedBy"] =
      FacilityEmployee[
        FacilityEmployee.findIndex(
          (facilityEmployee_list) =>
            facilityEmployee_list.EmpCode == props.formData.IssuedBy
        )
      ];
    chosenValuesData["AdjTypeId"] =
      AdjType[
        AdjType.findIndex(
          (AdjType_list) => AdjType_list.id == props.formData.AdjTypeId
        )
      ];

    setChosenValues(chosenValuesData);
  }, [
    props.formData.ApprovedBy,
    props.formData.PreparedBy,
    props.formData.IssuedBy,
    props.formData.AdjTypeId,
  ]);

  useEffect(() => {
    let chosenValuesDataFacility = { ...chosenValuesFacility };
    chosenValuesDataFacility["transferFacilityCode"] =
      IssueToFacility[
        IssueToFacility.findIndex(
          (IssuingTo_Facility) =>
            IssuingTo_Facility.id == props.formData.FromFacility
        )
      ];
    setChosenValuesFacility(chosenValuesDataFacility);
  }, [props.formData.FromFacility]);

  const handleChangeChoosen = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;
    setChosenValues(chosenValuesData);

    let chosenValuesDataFacility = { ...chosenValuesFacility };
    chosenValuesDataFacility[name] = valueobj;
    setChosenValuesFacility(chosenValuesDataFacility);

    props.handleChangeChoosenProps(name, value);
  };

  //====== End Choosen Dropdown===========

  useEffect(() => {
    if (props.generatedInvoiceNo == 1) {
      MCWCSoftwareStartDate(props.formData);
    }
  }, [props.generatedInvoiceNo]);

  const { isLoading2, mutate: MCWCSoftwareStartDate } = useMutation(
    api.getMCWCSoftwareStartDate,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          props.formData["AdjTypeId"] = 1;
          /* props.formData["IssuedDate"] = data.datalist[0].SoftwareStartDate; */
          props.formData["TransactionDate"] = moment(data.datalist[0].SoftwareStartDate).subtract(1, 'days').format('YYYY-MM-DD');
          props.formData["AppDate1"] = moment(data.datalist[0].SoftwareStartDate).subtract(1, 'days').format('YYYY-MM-DD');
          props.formData["IssuedDate"] = moment(data.datalist[0].SoftwareStartDate).subtract(1, 'days').format('YYYY-MM-DD');

          let params = {
            action: "getWarehouseFacility",
            menukey: menukey,
            FacilityId: FacilityId,
            AdjTypeId: props.formData.AdjTypeId,
            UseFor: UseFor,
          };
          mutate(params);
        } else {
        }
      },
    }
  );

  /* =====Start of Print Excel Many Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/adjustment_facility_invoice_list_print.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TransactionId=" +
        props.formData.TransactionId +
        "&AdjType=" +
        props.formData.AdjTypeId +
        "&FacilityId=" +
        FacilityId +
        "&UseFor=" +
        UseFor +
        "&FacilityName=" +
        FacilityName +
        "&TimeStamp=" +
        Date.now() +
        "&isPositive=" +
        props.isPositive
    );
  };

  const ExcelMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/esigl_adjustment_invoice_list_excel.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TransactionId=" +
        props.formData.TransactionId +
        "&FacilityId=" +
        FacilityId +
        "&UseFor=" +
        UseFor +
        "&FacilityName=" +
        FacilityName +
        "&TimeStamp=" +
        Date.now() +
        "&isPositive=" +
        props.isPositive
    );
  };

  /* =====End of Excel Export Code==== */

  const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        setOpenLot(false);
        setOpenLotUnusable(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;

      case "LotOpen":
        setOpenLot(true);
        break;

      case "LotUnusableOpen":
        setOpenLotUnusable(true);
        break;

      case "SourceOpen":
        setSoureOpen(true);
        break;

      case "SourceClose":
        setSoureOpen(false);
        break;

      default:
        break;
    }
  };

  const addProduct = (NonOrderProductData) => {
    let countrs = ReceiveDetailsRef.table.rowManager.displayRowsCount; //ReceiveDetailsRef.table.rowCount();

    if (bIsPositive) {
      let rowsNotInGrid = [];

      NonOrderProductData.map((row, i) => {
        row.TransactionItemId = null;
        row.Quantity = null;
        row.IsPositive = -1;
        row.SKU = i + manyTableData.length + 1 + props.deleteReceiveInv.length;
      });

      if (props.ManyTableData.length > 0) {
        rowsNotInGrid = NonOrderProductData.filter((obj1) => {
          return !props.ManyTableData.some(
            (obj2) =>
              obj1.BatchNo === obj2.BatchNo &&
              obj1.ExpiryDate === obj2.ExpiryDate &&
              obj1.ItemCode === obj2.ItemCode
          );
        });
      } else {
        rowsNotInGrid = NonOrderProductData;
      }

      // let arrObjOne=rowsNotInGrid.concat(props.ManyTableData)  ;
      let arrObjOne = props.ManyTableData.concat(rowsNotInGrid);
      //const arrObjOne = [...new Map(person.map(item => [JSON.stringify(item), item])).values()];

      setManyTableData(arrObjOne);
      props.handleAdd(arrObjOne);

      handleClose("Products");
    } else {
      let rows = [];
      let autoId = 0;

      manyTableData.forEach((row, i) => {
        autoId++;
        let newRow = {};
        newRow.ItemNo = row.ItemNo;
        newRow.ItemCode = row.ItemCode;

        newRow.ItemName = row.ItemName;
        newRow.UnitPrice = row.UnitPrice;
        newRow.TransactionItemId = row.TransactionItemId;
        newRow.CurrentStockQty = row.CurrentStockQty;
        newRow.Quantity = row.Quantity;
        newRow.BatchNo = row.BatchNo;
        newRow.ItemListLotId = row.ItemListLotId;
        newRow.MfgDate = row.MfgDate;
        newRow.ExpiryDate = row.ExpiryDate;

        newRow.SourceId = row.SourceId;
        newRow.SourceName = row.SourceName;

        newRow.UnitName = row.UnitName;

        newRow.SKU = autoId;
        newRow.id = autoId;
        newRow.LineTotal = row.Quantity * row.UnitPrice;
        rows.push(newRow);
      });

      NonOrderProductData.forEach((row, i) => {
        autoId++;
        let newRow = {};
        newRow.ItemNo = row.ItemNo;

        newRow.ItemCode = row.ItemCode;

        newRow.ItemName = row.ItemName;
        newRow.UnitPrice = row.UnitPrice;

        newRow.TransactionItemId = null;
        newRow.CurrentStockQty = 0;
        newRow.Quantity = null;
        newRow.BatchNo = null;
        newRow.ItemListLotId = null;
        newRow.MfgDate = null;
        newRow.ExpiryDate = null;

        newRow.SourceId = null;
        newRow.SourceName = null;

        newRow.UnitName = row.UnitName;

        newRow.SKU = autoId;
        newRow.id = autoId;
        newRow.LineTotal = 0;
        rows.push(newRow);
      });

      setManyTableData(rows);
      props.handleAdd(rows);
      handleClose("Products");
    }

    api.dirtyModeEnable();
  };

  const addSource = (productSourceData) => {
    if (!productSourceData) {
      return;
    }

    let tempProductSourceData = productSourceData[0];
    let rows = [];

    let autoId = Date.now();

    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};

      if (row.SKU === currRowId) {
        newRow.SourceId = tempProductSourceData.SourceId;
        newRow.SourceName = tempProductSourceData.SourceName;
      } else {
        newRow.SourceId = row.SourceId;
        newRow.SourceName = row.SourceName;
      }

      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;

      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.Quantity = row.Quantity;
      newRow.BatchNo = row.BatchNo;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.SKU = row.SKU;
      newRow.id = row.id;
      newRow.LineTotal = row.Quantity * row.UnitPrice;
      rows.push(newRow);
    });

    setManyTableData(rows);
    props.handleAdd(rows);
    handleClose("SourceClose");

    api.dirtyModeEnable();
  };

  function ShowSourcePopup(propss: any) {
    const rowData = propss.cell._cell.row.data;

    if (props.bStockUpdate || props.isPositive) {
      return <></>;
    } else {
      return (
        <>
          <ViewList
            onClick={() => {
              setCurrRowId(rowData.SKU);
              handleClose("SourceOpen");
            }}
          />
        </>
      );
    }
  }

  const ReceiveDetailsColumns = [
    { title: "id", field: "SKU", visible: false },
    { title: "id", field: "TransactionItemId", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      width: 70,
      formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "ItemCode",
      width: 130,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ItemName",
      minWidth: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Unit"],
      field: "UnitName",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["Current Stock"],
      field: "CurrentStockQty",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      visible: bIsPositive,
      formatter: "money",
      formatterParams: { thousand: ",", precision: false },
    },
    {
      title: DispensingLanguage[lan][menukey]["Quantity"],
      field: "Quantity",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      validator: [
        "integer",
        "min:0",
        function (cell, value, parameters) {
          if (value == "") return true;
          if (!cell.getRow().getCell("CurrentStockQty")) return true; //when type quickly then sometimes this function return false and showing error

          if (bIsPositive) {
            const balance = cell.getRow().getCell("CurrentStockQty").getValue();
            const validstatus = parseInt(value) <= parseInt(balance);
            return validstatus;
          } else {
            return true;
          }
        },
      ],
      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        // const pricecell = currow.getCell("LineTotal");
        // const totprice = rowdata.UnitPrice * curcellval;
        // pricecell.setValue(totprice);
      },
      formatter: "money",
      formatterParams: { thousand: ",", precision: false },
    }, //
    {
      title: DispensingLanguage[lan][menukey]["Lot No"],
      field: "BatchNo",
      width: 100,
      cssClass: "tabluator-column-editable text-field-editable",
      editor:
        props.bStockUpdate == 0 ? (bIsPositive ? false : cellFocusEditor) : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Mfg. Date"],
      field: "MfgDate",
      width: 120,

      cssClass: "tabluator-column-editable text-field-editable",
      editor:
        props.bStockUpdate == 0 ? (bIsPositive ? false : cellFocusEditor) : "",
      /* cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? ( bIsPositive ? false : dateEditorAllowPast) : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
      }, */
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry Date"],
      field: "ExpiryDate",
      width: 120,
      cssClass: "tabluator-column-editable text-field-editable",
      editor:
        props.bStockUpdate == 0 ? (bIsPositive ? false : cellFocusEditor) : "",
      /* cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? ( bIsPositive ? false : dateEditorAllowFuture) : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
      }, */
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      headerHozAlign: "center",
      hozAlign: "center",
      width: 100,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  // const onPostBtnClick = () => {
  //   setbStockUpdate(true);
  //   props.handlePost();
  // };

  const checkInvoiceDate = () => {
    let InvoiceIssueDate = moment(props.formData.IssuedDate).format(
      "YYYY-MM-DD"
    );
    let InvoiceIssueDateYear = moment(props.formData.IssuedDate).format("YYYY");
    let InvoiceIssueDateMonth = moment(props.formData.IssuedDate).format("MM");

    let CurrentDate = moment().format("YYYY-MM-DD");
    let CurrentYear = moment().format("YYYY");
    let CurrentMonth = moment().format("MM");

    if (
      CurrentYear == InvoiceIssueDateYear &&
      CurrentMonth == InvoiceIssueDateMonth
    ) {
      if (CurrentDate != InvoiceIssueDate) {
        // start Alert
        swal({
          title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
          text: t(
            DispensingLanguage[lan][menukey][
              "This invoice will be posted to stock on current date."
            ]
          ),
          icon: "warning",
          buttons: {
            confirm: {
              text: t(DispensingLanguage[lan][menukey]["Yes"]),
              value: true,
              visible: true,
              className: "",
              closeModal: true,
            },
            cancel: {
              text: t(DispensingLanguage[lan][menukey]["No"]),
              value: null,
              visible: true,
              className: "",
              closeModal: true,
            },
          },
        }).then((willAction) => {
          if (willAction) {
            onPostBtnClick();
            props.handleRDateChange(
              moment().format("YYYY-MM-DD"),
              "IssuedDate"
            );
          }
        });
        // End Alert
      } else {
        onPostBtnClick();
      }
    } else {
      // start Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey]["Invoice Issue Date"] +
            ": " +
            moment(InvoiceIssueDate).format("DD/MM/YYYY") +
            ". " +
            DispensingLanguage[lan][menukey][
              "Do you want to post the invoice on this date?"
            ]
        ),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          onPostBtnClick();
        }
      });
      // End Alert
    }
  };

  const onPostBtnClick = () => {
    if (ReceiveDetailsRef == null) return;

    ///===================Check qty is blank of any item==========/////////////

    let blankinfo = {};
    ReceiveDetailsRef.table.getData().forEach((row, i) => {
      if (row.Quantity === null || row.Quantity === "") {
        blankinfo = row;
      }
    });

    if (Object.keys(blankinfo).length > 0) {
      props.openNoticeModal({
        isOpen: true,
        msg:
          t(DispensingLanguage[lan][menukey]["Blank Quantity is not allowed"]) +
          " - " +
          blankinfo.ItemName,
        msgtype: false,
      });
      return;
    }
    ///===================Check qty is blank of any item==========/////////////

    ///===================Check duplicate lots exist in invoice==========/////////////
    // if (ReceiveDetailsRef == null) return;
    let invoiceLotIds = [];
    let duplicateinfo = {};
    ReceiveDetailsRef.table.getData().forEach((row, i) => {
      let uktempid = 0;
      if (bIsPositive) {
        uktempid = row.ItemListLotId;
      } else {
        uktempid =
          row.ItemCode +
          "_" +
          row.BatchNo +
          "_" +
          row.MfgDate +
          "_" +
          row.ExpiryDate;
      }

      if (invoiceLotIds.includes(uktempid)) {
        duplicateinfo = row;
      } else {
        invoiceLotIds.push(uktempid);
      }
    });

    if (Object.keys(duplicateinfo).length > 0) {
      props.openNoticeModal({
        isOpen: true,
        msg:
          t(DispensingLanguage[lan][menukey]["Duplicate Lot available"]) +
          " - " +
          duplicateinfo.ItemName,
        msgtype: false,
      });
      return;
    }

    ///===================Check duplicate lots exist in invoice==========/////////////

    //setbStockUpdate(true);
    // props.handlePost();
    handlePost();
  };

  const handlePost = async (e) => {
    let validForm = props.checkValidateForm(
      ReceiveDetailsRef.table.getData(),
      "post"
    );
    // console.log('isval: ', validForm);
    // console.log('props from post: ', props);

    // if (validateForm(formData)) {
    if (validForm) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey][
            "Do you really want to post the stock?"
          ]
        ),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true, */
      }).then((willAction) => {
        if (willAction) {
          let fDtat = { ...validForm, bStockUpdated: 1 };
          // console.log('fDtat: ', fDtat);
          mutateUpdate(fDtat);
        } else {
          // setbStockUpdate(0);
          props.stockPosted(0);
          // let route = `${TransactionId}`;
          let route = `${props.TransactionId}`;
          props.history.push(route);
        }
      });
      // End Post Alert
    }
  };

  const onSaveUpdateBtnClick = () => {
    // props.setUpdatedDetailsGridDataHandler({"datatype":"TableData","data":ReceiveDetailsRef.table.getData()} );

    let manydata = ReceiveDetailsRef.table.getData();
    setUpdatedDetailsGridDataHandler(manydata);
  };

  const setUpdatedDetailsGridDataHandler = (rows) => {
    let validForm = props.checkValidateForm(rows, "edit");
    // console.log('isval: ', validForm);
    if (validForm) {
      // console.log('props.addProductForm: ', props.addProductForm);
      if (props.addProductForm) {
        //when save
        mutateSave(validForm);
      } else {
        //when update
        mutateUpdate(validForm);
      }
    }
  };

  const { isLoading: isLoadingSave, mutate: mutateSave } = useMutation(
    api.AdjustmentFacilityInvoiceDataInsert,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });

          let route = `edit/${data.TransactionId}`;
          props.history.push(route);
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      },
    }
  );

  const { isLoading: isLoadingUpdate, mutate: mutateUpdate } = useMutation(
    api.AdjustmentFacilityInvoiceDataUpdate,
    {
      onSuccess: (data) => {
        // console.log('mutateUpdate data: ', data);
        if (data.status == 200) {
          props.setAdjTypeIdFunction(data.data.AdjTypeId);
          if (data.bStockUpdated == 1) {
            mutatePost(props.TransactionId);
          } else {
            props.refetchInvoice(); //refetch();
            props.openNoticeModal({
              isOpen: true,
              msg: data.message,
              msgtype: data.success,
            });
          }
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      },
    }
  );

  const { isLoading: isLoadingPost, mutate: mutatePost } = useMutation(
    api.SubmitAdjustmentFacilityInvoice,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          // setDeletedDataSet([]);

          if (data.success == 0) {
            //setbStockUpdate(0);
            props.stockPosted(0);
            props.openNoticeModal({
              isOpen: true,
              msg: data.message,
              msgtype: data.success,
            });
          } else {
            // setbStockUpdate(1);
            props.stockPosted(1);
            props.refetchInvoice();

            props.openNoticeModal({
              isOpen: true,
              msg: data.message,
              msgtype: data.success,
            });
          }
        } else {
          // setbStockUpdate(0);
          props.stockPosted(0);
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      },
    }
  );

  const invoiceCancel = (e) => {
    const { name, checked } = e.target;
    let validForm = props.checkValidateForm(
      ReceiveDetailsRef.table.getData(),
      "cancel"
    );

    if (validForm) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text:
          t(DispensingLanguage[lan][menukey]["Do you really want to cancel?"]) +
          " " +
          t(
            DispensingLanguage[lan][menukey][
              "You will not able to edit this invoice after cancel"
            ]
          ),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true, */
      }).then((willCancel) => {
        if (willCancel) {
          // let fDtat={...formData,'ManyJsonSave':ManyTableData,"BCancel":1,"TableName":"adjinv",'deletedDataSet':deletedDataSet};
          let fDtat = { ...validForm, BCancel: 1, TableName: "adjinv" };
          mutateUpdate(fDtat);
        } else {
          //setFormData({ ...formData, [name]: false });
        }
      });
    }
  };

  const AdjustmentSubmit = async (e) => {
    let validForm = props.checkValidateForm(
      ReceiveDetailsRef.table.getData(),
      "edit"
    );
    // Start Post Alert

    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Do you really want to submit this adjustment?"
        ]
      ),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then((willAction) => {
      if (willAction) {
        let fDtat = { ...validForm, bSubmit: 0 };
        mutateUpdate2(fDtat);
        //mutateAdjustmentSubmit(fDtat);
      } else {
        // setbStockUpdate(0);
        props.refetchInvoice();
      }
    });
    // End Post Alert
  };

  const { isLoading: isLoadingUpdate2, mutate: mutateUpdate2 } = useMutation(
    api.AdjustmentFacilityInvoiceDataUpdateForEntry,
    {
      onSuccess: (data) => {
        // console.log('mutateUpdate data: ', data);
        if (data.status == 200) {
          props.setAdjTypeIdFunction(data.data.AdjTypeId);
          if (data.bStockUpdated == 0) {
            mutatePost({
              TransactionId: props.TransactionId,
              TransactionNo: props.TransactionNo,
            });
          } else {
            props.refetchInvoice(); //refetch();
            props.openNoticeModal({
              isOpen: true,
              msg: data.message,
              msgtype: data.success,
            });
          }
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      },
    }
  );

  const deleteReceiveInv = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Do you really want to delete the selected item?"
        ]
      ),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willDelete) => {
      if (willDelete) {
        props.deleteReceiveInv(data);
        api.dirtyModeEnable();
      }
    });
  };

  const { mutate } = useMutation(api.getWarehouseList, {
    onSuccess: (data) => {
      if (data.status == 200) {
        let firstIssueToFacilityName =
          data.data.datalist.length == 1 ? data.data.datalist[0].name : "";
        let firstIssueToFacility =
          data.data.datalist.length == 1 ? data.data.datalist[0].id : "";
        setfirstIssueToFacility(firstIssueToFacility);
        props.formData.IssueToFacility = firstIssueToFacility;

        props.setUpdatedDetailsGridDataHandler({
          datatype: "FormData",
          IssueToFacility: props.formData,
        });

        if (!props.addProductForm) {
          let chosenValuesDataFacility = { ...chosenValuesFacility };
          chosenValuesDataFacility["FromFacility"] =
            data.data.datalist[
              data.data.datalist.findIndex(
                (fpacily_Lists2) =>
                  fpacily_Lists2.id == props.formData.FromFacility
              )
            ];
          setChosenValuesFacility(chosenValuesDataFacility);
        } else {
          setChosenValuesFacility({
            FromFacility: {
              id: firstIssueToFacility,
              name: firstIssueToFacilityName,
            },
          });
          props.formData["FromFacility"] = firstIssueToFacility;
        }

        setIssueToFacility(data.data.datalist);
      }
    },
  });

  const comboChangeEvnt = (e) => {
    props.formData.FromFacility = "";
    getAdjType(e.target.value);
    setgAdjTypeId(e.target.value);

    props.handleChange(e);
  };

  const handleChangeChoosenAdjType = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;
    props.formData["FromFacility"] = "";

    setChosenValues(chosenValuesData);
    getAdjType(value);
    setgAdjTypeId(value);

    props.handleChangeChoosenProps(name, value);
  };

  const getAdjType = (AdjTypeId) => {
    let params = {
      action: "getWarehouseFacility",
      menukey: menukey,
      FacilityId: FacilityId,
      AdjTypeId: AdjTypeId,
      UseFor: UseFor,
    };
    mutate(params);

    props.formData.AdjTypeId = AdjTypeId;

    setFacilityWarehouseSupplierLabel(
      DispensingLanguage[lan][menukey]["To/From"]
    );

    if (AdjTypeId == 1) {
      setReceivedIssuedbyLabel(ReceivedByLabel);
      setReceivedIssuedDate(ReceivedDateLabel);

      setbIsPositive(false);
    } else if (AdjTypeId == 2) {
      setReceivedIssuedbyLabel(IssuedByLabel);
      setReceivedIssuedDate(IssuedDateLabel);

      setbIsPositive(true);
    } else if (AdjTypeId == 7) {
      setReceivedIssuedbyLabel(IssuedByLabel);
      setReceivedIssuedDate(IssuedDateLabel);

      setbIsPositive(true);
    } else {
      setbIsPositive(true);
    }

    /*if(UseFor === 'UIMS'){
          setFacilityWarehouseSupplierLabel(DispensingLanguage[lan][menukey]['To/From']);

          if((AdjTypeId == 1) || (AdjTypeId == 3) || (AdjTypeId == 4)) {
    
            setReceivedIssuedbyLabel(ReceivedByLabel);
            setReceivedIssuedDate(ReceivedDateLabel);
      
            setbIsPositive(false);
      
          }else if((AdjTypeId == 2) || (AdjTypeId == 5) || (AdjTypeId == 6)) {
      
            setReceivedIssuedbyLabel(IssuedByLabel);
            setReceivedIssuedDate(IssuedDateLabel);
      
            setbIsPositive(true);
      
          }else if((AdjTypeId == 7) || (AdjTypeId == 8)) {
      
            setReceivedIssuedbyLabel(IssuedByLabel);
            setReceivedIssuedDate(IssuedDateLabel);
      
            setbIsPositive(true);
      
          }else if(AdjTypeId == 9) {
            setReceivedIssuedbyLabel(ReceivedByLabel);
            setReceivedIssuedDate(ReceivedDateLabel);
      
            setbIsPositive(false);
            
          }else{
  
              setbIsPositive(true); 
          }

     }else{
        if((AdjTypeId == 1) || (AdjTypeId == 3) || (AdjTypeId == 4)){
    
          setFacilityWarehouseSupplierLabel(FacilityLabel);
          setReceivedIssuedbyLabel(ReceivedByLabel);
          setReceivedIssuedDate(ReceivedDateLabel);
    
          setbIsPositive(false);
    
        }else if((AdjTypeId == 2) || (AdjTypeId == 5) || (AdjTypeId == 6)) {
    
          setFacilityWarehouseSupplierLabel(FacilityLabel);
          setReceivedIssuedbyLabel(IssuedByLabel);
          setReceivedIssuedDate(IssuedDateLabel);
    
          setbIsPositive(true);
    
        }else if((AdjTypeId == 7) || (AdjTypeId == 8)) {
    
          setFacilityWarehouseSupplierLabel(WarehouseLabel);
          setReceivedIssuedbyLabel(IssuedByLabel);
          setReceivedIssuedDate(IssuedDateLabel);
    
          setbIsPositive(true);
    
        }else if(AdjTypeId == 9) {
          setFacilityWarehouseSupplierLabel(SupplierLabel);
          setReceivedIssuedbyLabel(IssuedByLabel);
          setReceivedIssuedDate(IssuedDateLabel);
    
          setbIsPositive(true);
          
        }else{

            setbIsPositive(true); 
        }
    }*/
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;

    const cellValue = props.cell._cell.value || "Edit | Show";
    if (bStockUpdate == 0)
      return (
        <>
          <DeleteOutline
            onClick={() => {
              if (!bStockUpdate) deleteReceiveInv(rowData);
            }}
          />
        </>
      );
    else return <></>;
  }

  let bfalse = true;

  useEffect(() => {
    if (ReceiveDetailsRef == null) return;

    if (props.bFirst) {
      setManyTableData(props.ManyTableData);
    }

    setbStockUpdate(props.bStockUpdate === 1 ? true : false);

    // setbIsPositive(props.isPositive);

    if (!props.addProductForm)
      if (props.AdjTypeId != undefined) {
        bfalse = false;
        setIssueToFacilityVal(props.formData.IssueToFacility);
        getAdjType(props.AdjTypeId);

        setgAdjTypeId(props.AdjTypeId);
      }
  }, [props.ManyTableData, props.bStockUpdate, props.AdjTypeId]);

  useEffect(() => {
    props.formData.IssueToFacility = IssueToFacilityVal;
    bfalse = true;
  }, [IssueToFacility]);

  const handleATCChange = (event, newValue) => {
    let rowId = "";
    if (newValue == null) {
      rowId = "";
    } else {
      rowId = newValue.id;
    }

    props.formData.IssueToFacility = rowId;
    setIssueToFacilityVal(rowId);
    if (rowId != "") props.handleChange("IssuedToFacility");
  };

  const backtolist = () => {
    let master_dirty_message_class = document
      .getElementById("master_dirty_message")
      .getAttribute("class");
    let idx = master_dirty_message_class.indexOf("dnone");
    if (idx === -1) {
      // start Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey][
            "You have unsaved changes which will be lost. Continue?"
          ]
        ),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          props.history.push("/adjustment-facility");
        }
      });
      // End Alert
    } else {
      props.history.push("/adjustment-facility");
    }
  };

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {
                  DispensingLanguage[lan][menukey][
                    "Adjustment Invoice - Add/Edit"
                  ]
                }
              </div>

              <div className="float-right sw_btn_control">
                {props.addProductForm ? (
                  <Grid item xs={12} className="text-center">
                    <div className="float-right sw_btn_control">
                      <Button
                        disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => props.handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button>
                      <Button
                        disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onSaveUpdateBtnClick}
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        disabled={isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        // onClick={() => props.history.push("/adjustment-facility")}
                        onClick={() => backtolist()}
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="mt-2 text-center">
                    <Button
                      disabled={
                        props.bStockUpdate ||
                        isLoadingUpdate ||
                        isLoadingPost ||
                        isLoadingUpdate2
                      }
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>

                    <Button
                      disabled={
                        props.bStockUpdate ||
                        !(manyTableData.length > 0) ||
                        isLoadingUpdate ||
                        isLoadingPost ||
                        isLoadingUpdate2
                      }
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => AdjustmentSubmit()}
                      //onChange={(e) => AdjustmentSubmit(e)}
                    >
                      {DispensingLanguage[lan][menukey]["Submit"]}
                    </Button>

                    {/* <Button                     
                      disabled={props.bStockUpdate || !(manyTableData.length>0) || isLoadingUpdate || isLoadingPost}
                      className="mr-2"
                      variant="contained"
                      color="primary"                     
                      onClick={checkInvoiceDate}
                    >
                      {DispensingLanguage[lan][menukey]['post']}
                    </Button> */}
                    <Button
                      disabled={isLoadingUpdate || isLoadingPost}
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      // onClick={() => props.history.push("/adjustment-facility")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>

                    <ExportButtonsMany
                      PrintMany={PrintMany}
                      {...props}
                    ></ExportButtonsMany>
                    {/* <Button
                    variant="contained"
                    color="primary"
                    className="mr-2 sw_icons"
                    onClick={() => PrintMany()}
                    >
                    <i class="fas fa-print"></i>
                    </Button> */}

                    {/* <Button
                    variant="contained"
                    color="primary"
                    className="mr-2 sw_icons"
                    onClick={() => ExcelMany()}
                    >
                    <i className="far fa-file-excel"></i>
                    </Button> */}
                  </Grid>
                )}
              </div>

              <div className="mr-4 float-right mt-15">
                <span
                  id="master_dirty_message"
                  className={"masterredtextcolor dnone"}
                >
                  {" "}
                  {DispensingLanguage[lan][menukey]["You have unsaved changes"]}
                </span>
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              {/* Start of Row */}
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={true /*bStockUpdate*/}
                      error={props.errorObject.TransactionNo}
                      helperText={props.errorObject.TransactionNo}
                      required
                      id="TransactionNo"
                      name="TransactionNo"
                      label={DispensingLanguage[lan][menukey]["Invoice No"]}
                      value={
                        props.formData.TransactionNo || props.generatedInvoiceNo
                      }
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={9} sm={9}></Grid>
                </Grid>
              </Grid>
              {/* end of Row */}

              {/* Start of Row */}
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      {/*  <InputLabel id="AdjTypeId-label">{DispensingLanguage[lan][menukey]['Adj. Type']}*</InputLabel>
                          <Select
                            disabled={props.bStockUpdate?props.bStockUpdate:( props.ManyTableData.length==0?false:true)}
                            ref={(AdjTypeId) => (adjId = AdjTypeId)}
                           // ref={1}
                            error={props.errorObject.AdjTypeId}
                            labelId="AdjTypeId-label"
                            id="AdjTypeId"
                            name="AdjTypeId"
                            ispositive=""
                            value={props.formData.AdjTypeId}
                            fullWidth
                            onChange={(e) => comboChangeEvnt(e)}
                          >
                            {
                              AdjType.map(
                              (item, index) => {
                                return (
                                  <MenuItem value={item.id} ispositive={item.IsPositive}>
                                    {item.AdjType}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                          <FormHelperText error={props.errorObject.AdjTypeId}>
                            {props.errorObject.AdjTypeId}
                          </FormHelperText> */}

                      <Autocomplete
                        autoHighlight
                        //disableClearable
                        /* disabled={props.bStockUpdate?props.bStockUpdate:( props.ManyTableData.length==0?false:true)} */
                        disabled={
                          props.bStockUpdate
                            ? props.bStockUpdate
                            : props.ManyTableData.length !== 0 ||
                              props.generatedInvoiceNo <= 1
                        }
                        id="AdjTypeId"
                        options={AdjType}
                        onChange={(event, valueobj) =>
                          handleChangeChoosenAdjType(
                            "AdjTypeId",
                            valueobj,
                            valueobj ? valueobj.id : ""
                          )
                        }
                        getOptionLabel={(option) => option.AdjType}
                        value={chosenValues["AdjTypeId"]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.AdjType}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.AdjTypeId}
                            label={
                              DispensingLanguage[lan][menukey]["Adj. Type"] +
                              " *"
                            }
                            variant="standard"
                            error={props.errorObject.AdjTypeId}
                            helperText={props.errorObject.AdjTypeId}
                            id="AdjTypeId"
                            name="AdjTypeId"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      {/* <InputLabel id="FromFacility-label">{FacilityWarehouseSupplierLabel}*</InputLabel>
                          <Select
                            disabled={props.bStockUpdate?props.bStockUpdate:( props.ManyTableData.length==0?false:true)}
                            ref={(FromFacility) => (adjId = FromFacility)}
                            error={props.errorObject.FromFacility}
                            labelId="FromFacility-label"
                            id="FromFacility"
                            name="FromFacility"
                            ispositive=""
                            value={props.formData.FromFacility}
                            fullWidth
                            onChange={(e) => props.handleChange(e)}
                          >
                            {
                            
                            IssueToFacility.map(
                              (item, index) => {
                                return (
                                  <MenuItem value={item.id}>
                                    {item.name}
                                  </MenuItem>
                                );
                              }
                            )}
                          </Select>
                          <FormHelperText error={props.errorObject.FromFacility}>
                            {props.errorObject.FromFacility}
                          </FormHelperText> */}

                      <Autocomplete
                        autoHighlight
                        //disableClearable
                        disabled={
                          props.bStockUpdate
                            ? props.bStockUpdate
                            : props.ManyTableData.length == 0
                            ? false
                            : true
                        }
                        id="FromFacility"
                        options={IssueToFacility}
                        onChange={(event, valueobj) =>
                          handleChangeChoosen(
                            "FromFacility",
                            valueobj,
                            valueobj ? valueobj.id : ""
                          )
                        }
                        getOptionLabel={(option) => option.name}
                        value={chosenValuesFacility["FromFacility"]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.name}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.FromFacility}
                            label={FacilityWarehouseSupplierLabel + " *"}
                            variant="standard"
                            error={props.errorObject.FromFacility}
                            helperText={props.errorObject.FromFacility}
                            id="FromFacility"
                            name="FromFacility"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    {/* <FormControl className={classes.fullWidth}>
                        <InputLabel id="demo-simple-select-helper-label">
                        { DispensingLanguage[lan][menukey]['Prepared By'] } *   
                        </InputLabel>
                        <Select
                          error={props.errorObject.PreparedBy}
                          // helperText={props.errorObject.OrderBy}
                          required
                          labelId="demo-simple-select-helper-label"
                          id="PreparedBy"
                          name="PreparedBy"
                          value={props.formData.PreparedBy}
                          fullWidth
                          onChange={(e) => props.handleChange(e)}
                          disabled={props.bStockUpdate}
                          //disabled={bCompleted===1}
                        >
                        {
                        FacilityEmployee.map(
                        (item, index) => {
                          return (
                            <MenuItem value={item.EmpCode}>
                              {item.EmpName}
                            </MenuItem>
                          );
                        }
                        )}
                      </Select>

                      <FormHelperText error={props.errorObject.PreparedBy}>
                        {props.errorObject.PreparedBy}
                      </FormHelperText>
                      </FormControl> */}

                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        hidden={props.bStockUpdate}
                        disabled={props.bStockUpdate} // props.bStockUpdate==0 then false ---- props.bStockUpdate==1 then true,
                        id="PreparedBy"
                        options={FacilityEmployee}
                        onChange={(event, valueobj) =>
                          handleChangeChoosen(
                            "PreparedBy",
                            valueobj,
                            valueobj ? valueobj.EmpCode : ""
                          )
                        }
                        getOptionLabel={(option) => option.EmpName}
                        value={chosenValues["PreparedBy"]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.EmpName}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.PreparedBy}
                            label={
                              DispensingLanguage[lan][menukey]["Prepared By"] +
                              " *"
                            }
                            variant="standard"
                            error={props.errorObject.PreparedBy}
                            helperText={props.errorObject.PreparedBy}
                            id="PreparedBy"
                            name="PreparedBy"
                            fullWidth
                          />
                        )}
                      />

                      <TextField
                        id="PreparedByName"
                        name="PreparedByName"
                        label={
                          DispensingLanguage[lan][menukey]["Prepared By"] + " *"
                        }
                        value={props.formData.PreparedByName}
                        disabled={true}
                        hidden={!props.bStockUpdate}
                        fullWidth
                        autoComplete="family-name"
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        required
                        error={props.errorObject.TransactionDate}
                        helperText={props.errorObject.TransactionDate}
                        className={classes.fullWidth}
                        // disableToolbar
                        variant="inline"
                        id="TransactionDate"
                        label={
                          DispensingLanguage[lan][menukey]["Prepared Date"]
                        }
                        autoOk={true}
                        disableFuture={true}
                        name="TransactionDate"
                        fullWidth
                        // showTodayButton={true}
                        value={props.formData.TransactionDate || null}
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "TransactionDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </Grid>
              {/* end of Row */}

              {/* Start of Row */}
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3} sm={3}>
                    {/*  <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                          { DispensingLanguage[lan][menukey]['Approved By'] } *  
                          </InputLabel>
                          <Select
                            error={props.errorObject.ApprovedBy}
                            required
                            labelId="demo-simple-select-helper-label"
                            id="ApprovedBy"
                            name="ApprovedBy"
                            value={props.formData.ApprovedBy}
                            fullWidth
                            onChange={(e) => props.handleChange(e)}
                            disabled={props.bStockUpdate}
                            //disabled={bCompleted===1}
                          >
                          {
                          FacilityEmployee.map(
                          (item, index) => {
                            return (
                              <MenuItem value={item.EmpCode}>
                                {item.EmpName}
                              </MenuItem>
                            );
                          }
                          )}
                        </Select>

                        <FormHelperText error={props.errorObject.ApprovedBy}>
                          {props.errorObject.ApprovedBy}
                        </FormHelperText>
                        </FormControl> */}

                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        hidden={props.bStockUpdate}
                        disabled={props.bStockUpdate}
                        id="ApprovedBy"
                        options={FacilityEmployee}
                        onChange={(event, valueobj) =>
                          handleChangeChoosen(
                            "ApprovedBy",
                            valueobj,
                            valueobj ? valueobj.EmpCode : ""
                          )
                        }
                        getOptionLabel={(option) => option.EmpName}
                        value={chosenValues["ApprovedBy"]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.EmpName}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.ApprovedBy}
                            label={
                              DispensingLanguage[lan][menukey]["Approved By"] +
                              " *"
                            }
                            variant="standard"
                            error={props.errorObject.ApprovedBy}
                            helperText={props.errorObject.ApprovedBy}
                            id="ApprovedBy"
                            name="ApprovedBy"
                            fullWidth
                          />
                        )}
                      />

                      <TextField
                        id="ApprovedByName"
                        name="ApprovedByName"
                        label={
                          DispensingLanguage[lan][menukey]["Approved By"] + " *"
                        }
                        value={props.formData.ApprovedByName}
                        disabled={true}
                        hidden={!props.bStockUpdate}
                        fullWidth
                        autoComplete="family-name"
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        required
                        error={props.errorObject.AppDate1}
                        helperText={props.errorObject.AppDate1}
                        className={classes.fullWidth}
                        // disableToolbar
                        variant="inline"
                        id="AppDate1"
                        label={
                          DispensingLanguage[lan][menukey]["Approved Date"]
                        }
                        autoOk={true}
                        disableFuture={true}
                        name="AppDate1"
                        fullWidth
                        // showTodayButton={true}
                        value={props.formData.AppDate1 || null}
                        format="dd/MM/yyyy"
                        onChange={(e) => props.handleRDateChange(e, "AppDate1")}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    {/*  <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                          { ReceivedIssuedbyLabel } *  
                          </InputLabel>
                          <Select
                            error={props.errorObject.IssuedBy}
                            required
                            labelId="demo-simple-select-helper-label"
                            id="IssuedBy"
                            name="IssuedBy"
                            value={props.formData.IssuedBy}
                            fullWidth
                            onChange={(e) => props.handleChange(e)}
                            disabled={props.bStockUpdate}
                            //disabled={bCompleted===1}
                          >
                          {
                          FacilityEmployee.map(
                          (item, index) => {
                            return (
                              <MenuItem value={item.EmpCode}>
                                {item.EmpName}
                              </MenuItem>
                            );
                          }
                          )}
                        </Select>

                        <FormHelperText error={props.errorObject.IssuedBy}>
                          {props.errorObject.IssuedBy}
                        </FormHelperText>
                        </FormControl> */}

                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        hidden={props.bStockUpdate}
                        disabled={props.bStockUpdate}
                        id="IssuedBy"
                        options={FacilityEmployee}
                        onChange={(event, valueobj) =>
                          handleChangeChoosen(
                            "IssuedBy",
                            valueobj,
                            valueobj ? valueobj.EmpCode : ""
                          )
                        }
                        getOptionLabel={(option) => option.EmpName}
                        value={chosenValues["IssuedBy"]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.EmpName}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.IssuedBy}
                            label={ReceivedIssuedbyLabel + " *"} //{DispensingLanguage[lan][menukey]["Approved By"]+ " *"}
                            variant="standard"
                            error={props.errorObject.IssuedBy}
                            helperText={props.errorObject.IssuedBy}
                            id="IssuedBy"
                            name="IssuedBy"
                            fullWidth
                          />
                        )}
                      />

                      <TextField
                        id="Issued_ByName"
                        name="Issued_ByName"
                        label={ReceivedIssuedbyLabel + " *"}
                        value={props.formData.Issued_ByName}
                        disabled={true}
                        hidden={!props.bStockUpdate}
                        fullWidth
                        autoComplete="family-name"
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        /* disabled={props.bStockUpdate} */
                        disabled={
                          props.bStockUpdate || props.generatedInvoiceNo == 1
                        }
                        required
                        error={props.errorObject.IssuedDate}
                        helperText={props.errorObject.IssuedDate}
                        className={classes.fullWidth}
                        // disableToolbar
                        variant="inline"
                        id="IssuedDate"
                        label={ReceivedIssuedDate}
                        autoOk={true}
                        disableFuture={true}
                        name="IssuedDate"
                        fullWidth
                        // showTodayButton={true}
                        value={props.formData.IssuedDate || null}
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "IssuedDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>
              </Grid>
              {/* end of Row */}

              {/* Start of Row */}
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      disabled={props.bStockUpdate}
                      id="AdjRemarks"
                      name="AdjRemarks"
                      label={DispensingLanguage[lan][menukey]["Remarks"]}
                      value={props.formData.AdjRemarks}
                      fullWidth
                      multiline
                      rows={3}
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="bUpdated"
                          disabled={true}
                          color="primary"
                          checked={props.formData.bUpdated > 0 ? true : false}
                          name="bUpdated"
                          value="no"
                        />
                      }
                      label={DispensingLanguage[lan][menukey]["Updated"]}
                    />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="BCancel"
                          disabled={props.bStockUpdate || props.addProductForm}
                          color="primary"
                          checked={props.formData.BCancel > 0 ? true : false}
                          // onChange={(e) => props.invoiceCancel(e)}
                          onChange={(e) => invoiceCancel(e)}
                          name="BCancel"
                          value="no"
                        />
                      }
                      label={DispensingLanguage[lan][menukey]["CANCEL"]}
                    />
                  </Grid>
                </Grid>
              </Grid>
              {/* end of Row */}

              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="TransactionId"
                      name="TransactionId"
                      label="TransactionId"
                      value={props.formData.TransactionId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                {/* new row */}
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card">
                    <CardContent>
                      <Grid container className="mb-2">
                        <Grid item xs={12} sm={12}>
                          <div className="float-right sw_btn_control">
                            {/* <Button
                              disabled={props.bStockUpdate ? props.bStockUpdate : bDisableAddProduct}
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              onClick={() => bIsPositive ? handleClose("LotOpen") : handleClose("ProductsOpen")}
                                >
                              {DispensingLanguage[lan][menukey]['SELECT PRODUCT']} 
                              </Button> */}

                            <Button
                              disabled={
                                props.bStockUpdate
                                  ? props.bStockUpdate
                                  : bDisableAddProduct
                              }
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                bIsPositive
                                  ? gAdjTypeId === 6
                                    ? handleClose("LotUnusableOpen")
                                    : handleClose("LotOpen")
                                  : handleClose("ProductsOpen")
                              }
                            >
                              {
                                DispensingLanguage[lan][menukey][
                                  "SELECT PRODUCT"
                                ]
                              }
                            </Button>
                          </div>

                          <ProductsModal
                            handleClose={handleClose}
                            open={open}
                            addProduct={addProduct}
                            {...props}
                          />

                          <ProductsLotModal
                            handleClose={handleClose}
                            open={openLot}
                            addProduct={addProduct}
                            FacilityId={FacilityId}
                            {...props}
                          />

                          <ProductsLotUnusableModal
                            handleClose={handleClose}
                            open={openLotUnusable}
                            addProduct={addProduct}
                            FacilityId={FacilityId}
                            {...props}
                          />

                          <SourceModal
                            handleClose={handleClose}
                            open={SoureOpen}
                            addProduct={addSource}
                            {...props}
                          />
                        </Grid>
                      </Grid>
                      <ReactTabulator
                        ref={(r) => (ReceiveDetailsRef = r)}
                        columns={ReceiveDetailsColumns}
                        data={manyTableData}
                        height="350px"
                        layout={"fitColumns"}
                      />
                    </CardContent>
                  </Card>
                </Grid>

                {/* new row */}
              </Grid>
            </Grid>

            {/* </CardContent>
          </Card> */}
          </Grid>

          {/* Action buttons */}
        </Grid>
      </div>
    </div>
  );
};

export default AdjustmentFacilityFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
