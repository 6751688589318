import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
//Services
import * as Service from "../../../services/Service.js";
import swal from "sweetalert";
import {
  Typography,
  TextField,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Card,
  CardContent,
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';

import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";
import ExportButtons from "../../../components/ExportButtons";

const HealthFacilityEntry = (props) => {
  const permissionType = props.permissionType;
    //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "health-facility-entry";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo==0?'': UserInfo[0].id;
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const classes = useStyles();
  let tblRef = null;
  const tableRef = useRef();
  const { useState } = React;
  const [firstLoad, setFirstLoad] = useState(true);
  const [dataItems, setDataItems] = useState([]);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const WarehouseList = JSON.parse(localStorage.getItem("gWHList"));
  WarehouseList.unshift({'id':"0",'name':"All"});
  const [currWarehouseId, setcurrWarehouseId] = useState(0);

  let TempItemList = useState([]);
  const [DistrictList, setDistrictListLocal] = useState(TempItemList);
  const [currDistrictId, setcurrDistrictId] = useState(0);

  let TempSDPUPList = useState([]);
  const [SDPUPList, setSDPUPListLocal] = useState(TempSDPUPList);
  const [currSDPUPId, setcurrSDPUPId] = useState(0);
  const FTypeList = JSON.parse(localStorage.getItem("FTypeList"));
  const [currcurrFTypeId, setcurrFTypeId] = useState('W');

  
  //====== Start Choosen Dropdown===========
  const [chosenValuesWarehouse, setChosenValuesWarehouse] = useState({
    "whrec_id": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
  });

  const [chosenValuesDistrict, setChosenValuesDistrict] = useState({
    "DistrictId": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
  });

  const [chosenValuesUpazila, setChosenValuesUpazila] = useState({
    "upazila_id": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
  });

  const [chosenValuesFType, setChosenValuesFType] = useState({
    "facilityTypeId": {"id": FTypeList[0].id,"name": FTypeList[0].name}
  });


  
const handleChangeChoosenWarehouse = (name, valueobj, value) => {
  let chosenValuesDataWarehouse = { ...chosenValuesWarehouse};
  chosenValuesDataWarehouse[name] = valueobj;
  setChosenValuesWarehouse(chosenValuesDataWarehouse); 

  setFirstLoad(true);
  setLoading(false);
  setcurrWarehouseId(value);

 setChosenValuesDistrict({
    "DistrictId": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
   }); 
  
   setChosenValuesUpazila({
    "upazila_id": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
   }); 

 

};

const handleChangeChoosenDistrict = (name, valueobj, value) => {
  let chosenValuesDataDistrict = { ...chosenValuesDistrict};
  chosenValuesDataDistrict[name] = valueobj;
  setChosenValuesDistrict(chosenValuesDataDistrict); 

  setFirstLoad(true);
  setLoading(false);
  setcurrDistrictId(value);

 setChosenValuesUpazila({
    "upazila_id": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
   }); 


};

const handleChangeChoosenUpazila = (name, valueobj, value) => {
  let chosenValuesDataUpazila = { ...chosenValuesUpazila};
  chosenValuesDataUpazila[name] = valueobj;
  setChosenValuesUpazila(chosenValuesDataUpazila); 

  setFirstLoad(true);
  setLoading(false);
  setcurrSDPUPId(value);

};

const handleChangeChoosenFType = (name, valueobj, value) => {
  let chosenValuesDataFType = { ...chosenValuesFType};
  chosenValuesDataFType[name] = valueobj;
  setChosenValuesFType(chosenValuesDataFType); 

  setFirstLoad(true);
  setLoading(false);
  setcurrFTypeId(value);

};


//====== End Choosen Dropdown===========

  const handleChangeFTypeId = (event) => {
    setFirstLoad(true);
    setLoading(false);
    setcurrFTypeId(event.target.value);
  };

  const handleChange = (event) => {
    setFirstLoad(true);
    setLoading(false);
    setcurrWarehouseId(event.target.value);
  };

  const handleChangeDistrict = (event) => {
    
   setFirstLoad(true);
    setLoading(false);
    setcurrDistrictId(event.target.value);

  };

  const handleChangeSDPUP = (event) => {
    
   setFirstLoad(true);
    setLoading(false);
    setcurrSDPUPId(event.target.value);

  };


  const cDistrictList = () => {  
  
    let DistrictListParam = {action:"DistrictListByWarehouse", whrec_id:currWarehouseId, FacilityId: FacilityId, menukey:""}

    Service.default
        .postApi("source/combo_generic.php",DistrictListParam)
        .then((res) => { 

          let All_Warehouse_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
          const Warehouse_List = [All_Warehouse_label].concat(res.datalist); 
          setDistrictListLocal(Warehouse_List);

        })
        .catch((err) => {});
  
}

const cSDPUPList = () => { 
  
  let SDPUPListParam = {action:"getSDPUPList", whrec_id:currWarehouseId, DistrictId:currDistrictId, FacilityId: FacilityId, menukey:""}

  Service.default
      .postApi("source/combo_generic.php",SDPUPListParam)
      .then((res) => { 

        let All_SDP_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
        const SDP_List = [All_SDP_label].concat(res.datalist); 
        setSDPUPListLocal(SDP_List);
      })
      .catch((err) => {});

}


let params = {
  menukey: menukey,
  FacilityId: FacilityId,
  action: "getHealthFacilityList",
  page_limit: JSON.stringify(100),
  page_number: JSON.stringify(0 + 1),
  search_key: "",
  WarehouseId: currWarehouseId,
  DistrictId: currDistrictId,
  SDPUPId: currSDPUPId,
  FTypeId: currcurrFTypeId,
};


const [isLoading, setLoading] = useState(false);

const { error, isError, data, refetch:stockData } = useQuery(
  [params],
  api.getHealthFacilityList,
  {
    onSuccess: (data) => {
      if(firstLoad){
        /* localStorage.setItem(
          "StockData",
          JSON.stringify(data)
        );
         */
        setDataItems(data);
        setLoading(false);
        setFirstLoad(false);
      }
    },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0
}
); 


  // const { isLoading, error, isError, data, refetch } = useQuery(
  //   'HealthFacilityEntry',
  //   api.getHealthFacilityList,
  //   {
  //     onSuccess: (data) => {
  //       setDataItems(data); 
  //     } 
  //   }
  //   );
  
  const { mutate:deleteApi } = useMutation(api.deleteHealthFacilityList, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        }); 
        setFirstLoad(true);
        stockData();
        queryClient.getQueriesData("HealthFacilityEntry");
       // swal(data.data.message, "", "success");
        
      }else{   
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
        }
    },
  });

/* =====Start of Excel Export Code==== */
const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

const PrintPDFExcelExportFunction = (reportType) => {
  let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

  window.open(
    finalUrl +
      "?action=HealthFacilityExport" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&FacilityId=" +
      FacilityId +
      "&FacilityName=" +
      FacilityName +
      "&WarehouseId=" +
      currWarehouseId +
      "&DistrictId=" +
      currDistrictId +
      "&SDPUPId=" +
      currSDPUPId +
      "&FTypeId=" +
      currcurrFTypeId +
      "&lan=" +
      localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
  );
};
/* =====End of Excel Export Code==== */

const tableRowDoubleClick = (e, row) => {
  let rowData = row._row.data;
  EditHealthFacilityEntry(rowData);
};

  const columns = [
    { title: "Id", field: "id", width: 50, visible: false },
    { title: DispensingLanguage[lan][menukey]['Sl#'] , formatter: "rownum", width: 80, hozAlign: "center",headerSort:false, headerHozAlign:"center"},
    { title: DispensingLanguage[lan][menukey]['Upazila'] , headerFilter: true, field: "upazila_name"},
    { title: DispensingLanguage[lan][menukey]['Union'] , headerFilter: true, field: "UnionName"},
    { title: DispensingLanguage[lan][menukey]['Health Facility'] , headerFilter: true, field: "fpfacility"},
    {
      title: DispensingLanguage[lan][menukey]['Actions'],
      field: "custom",
      hozAlign: "center",
      width: 133,
      headerSort:false,
      headerHozAlign:"center",
      //frozen:true,
      formatter: reactFormatter(<ActionButton />),
    },
  ];



  const EditHealthFacilityEntry = (data) => {
    let route = `health-facility-entry/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteHealthFacilityList = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]['Are you sure?']),
      text: t(DispensingLanguage[lan][menukey]['Once deleted, you will not be able to recover this Record!']),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willDelete) => {
      if (willDelete) {
        deleteApi({id:data.id,"menukey": menukey,"lan":lan,"UserName":UserName,"action": "dataDalete"});
      }
    });
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    // const cellValue = props.cell._cell.value || "Edit | Show";
    if(permissionType)
    return <></>
 else if(rowData.id==1)
  return (
    <>
      <Edit
        onClick={() => {
          EditHealthFacilityEntry(rowData);
        }}
      />
       
    </>
  );
  else
  return (
    <>
      <Edit
        onClick={() => {
          EditHealthFacilityEntry(rowData);
        }}
      />
      {/* <DeleteOutline
        onClick={() => {
         
          deleteHealthFacilityList(rowData);
        }}
      /> */}
    </>
  );
}






React.useEffect(() => {
  setcurrDistrictId(0); 
  cDistrictList();
}, [currWarehouseId]);



React.useEffect(() => {
  setcurrSDPUPId(0); 
  cSDPUPList();
}, [currWarehouseId, currDistrictId]);




React.useEffect(() => {
  if (tblRef == null) return;
  setLoading(true);
  stockData();
 }, [currWarehouseId, currWarehouseId, currDistrictId]);

  return (
    <>


<div className={classes.dispenserPageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Health Facility Entry"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
            
                <Grid item xs={2} sm={2}>
                  <FormControl className={classes.fullWidth}>
                    {/* <InputLabel id="">
                      {DispensingLanguage[lan][menukey]["Select Warehouse"]} 
                    </InputLabel>
                    <Select
                    // error={props.errorObject.SupCode}
                      labelId="whrec_id"
                      id="whrec_id"
                      name="whrec_id"
                    // value={'CON'}
                      value={currWarehouseId}
                      fullWidth
                      onChange={handleChange}
                      
                    >
                    
                    <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                      {WarehouseList.map((item, index) => {
                        return (
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                        );
                      })}
                    </Select> */}

                        <Autocomplete
                          autoHighlight
                          disableClearable
                          id="whrec_id"
                          options={WarehouseList}
                          onChange={(event, valueobj) => handleChangeChoosenWarehouse('whrec_id', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValuesWarehouse['whrec_id']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Select Warehouse"]}
                            variant="standard"
                            id="whrec_id"
                            name="whrec_id"
                            fullWidth
                          />
                          )}
                        />

                  </FormControl>
                </Grid>

                <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                      {/* <InputLabel id="">
                        {DispensingLanguage[lan][menukey]["Select District"]} 
                      </InputLabel>
                      <Select
                       // error={props.errorObject.ItemCode}
                        labelId="DistrictId"
                        id="DistrictId"
                        name="DistrictId"
                        value={currDistrictId}
                        fullWidth
                        onChange={handleChangeDistrict}
                        
                      >
                        
                        <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                        {DistrictList.map((item, index) => {
                          return (
                           <MenuItem value={item.id}> {item.name}</MenuItem>
                          );
                        })}
                      </Select> */}

                        <Autocomplete
                          autoHighlight
                          disableClearable
                          id="DistrictId"
                          options={DistrictList}
                          onChange={(event, valueobj) => handleChangeChoosenDistrict('DistrictId', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValuesDistrict['DistrictId']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Select District"]}
                            variant="standard"
                            id="DistrictId"
                            name="DistrictId"
                            fullWidth
                          />
                          )}
                        />

                    </FormControl>
                  </Grid> 

                <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                      {/* <InputLabel id="">
                        {DispensingLanguage[lan][menukey]["Select Upazila"]} 
                      </InputLabel>
                      <Select
                       // error={props.errorObject.ItemCode}
                        labelId="upazila_id"
                        id="upazila_id"
                        name="upazila_id"
                        value={currSDPUPId}
                        fullWidth
                        onChange={handleChangeSDPUP}
                        
                      >
                        
                        <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                        {SDPUPList.map((item, index) => {
                          return (
                           <MenuItem value={item.id}> {item.name}</MenuItem>
                          );
                        })}
                      </Select> */}

                          <Autocomplete
                            autoHighlight
                            disableClearable
                            id="upazila_id"
                            options={SDPUPList}
                            onChange={(event, valueobj) => handleChangeChoosenUpazila('upazila_id', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValuesUpazila['upazila_id']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Select Upazila"]}
                              variant="standard"
                              id="upazila_id"
                              name="upazila_id"
                              fullWidth
                            />
                            )}
                          />

                    </FormControl>
                  </Grid> 


                  <Grid item xs={2} sm={2}>
                  <FormControl className={classes.fullWidth}>
                   {/*  <InputLabel id="">
                      {DispensingLanguage[lan][menukey]["Select Facility Type"]} 
                    </InputLabel>
                    <Select
                      labelId="facilityTypeId"
                      id="facilityTypeId"
                      name="facilityTypeId"
                      value={currcurrFTypeId}
                      fullWidth
                      onChange={handleChangeFTypeId}
                    >
                    
                      {FTypeList.map((item, index) => {
                        return (
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                        );
                      })}
                    </Select> */}

                          <Autocomplete
                            autoHighlight
                            disableClearable
                            id="facilityTypeId"
                            options={FTypeList}
                            onChange={(event, valueobj) => handleChangeChoosenFType('facilityTypeId', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValuesFType['facilityTypeId']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Select Facility Type"]}
                              variant="standard"
                              id="facilityTypeId"
                              name="facilityTypeId"
                              fullWidth
                            />
                            )}
                          />

                  </FormControl>
                </Grid>

              

              <Grid item xs={4} sm={4}>
                <div className="float-right sw_btn_control">
                  <Button
                    color="info"
                    className="mr-2"
                   
                    disabled={permissionType}
                    onClick={() => {
                        
                        if(currSDPUPId){
                          props.history.push("/health-facility-entry/add/" + currSDPUPId +'_'+ currcurrFTypeId);
                        }else{
                          //alert("Please select an upazilla");
                          props.openNoticeModal({
                            isOpen: true,
                            msg: DispensingLanguage[lan][menukey]["Please select an upazilla"],
                            msgtype: 0,
                          });
                        }
                     
                    }}
                   
                  >
                    {DispensingLanguage[lan][menukey]["Add New"]}
                  </Button>

                  <ExportButtons PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}  {...props}></ExportButtons>

                  {/* <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button> */}
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
        {isLoading && <LoadingSpinnerOpaque />}
           <div className="uniqueName">
            <ReactTabulator 
            ref={(r) => (tblRef = r)}
            columns={columns} data={dataItems}
            layout={"fitColumns"}
            height={500}
            rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>

      {/* <div className={classes.dispenserPageTitle}>
        <div className="d-flex justify-dispenser mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">{DispensingLanguage[lan][menukey]['Health Facility Entry']}</div>
              <div className="float-right sw_btn_control">
                <Button
                  color="info"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/health-facility-entry/add");
                  }}
                >
                  {DispensingLanguage[lan][menukey]['Add New']} 
                </Button>
                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i class="fas fa-print"></i>
                </Button>

                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button>
              </div>
            </div>
          </Grid>
          </div>

        <div>
          <div className="uniqueName">
            <ReactTabulator columns={columns} data={dataItems}
            layout={"fitColumns"}
            height={500}
            rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default HealthFacilityEntry;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
