import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar"; 
import Notification from "../../../services/Notification";
import {checkLogin, checkUserPermission,getUserActionPermission} from "../../../services/CheckUserAccess";
import swal from "sweetalert";
//ABC screen
import NationalSupplier from "./NationalSupplier";
import AddNationalSupplier from "./AddNationalSupplier";
import EditNationalSupplier from "./EditNationalSupplier"; 

const Index = (props) => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const menukey = "national-supplier";

  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  const [hasUserPermission, setHasUserPermission] = React.useState(false);


/*   const checkLogin = () => {
    let token = sessionStorage.getItem("token");
    if (!token) {
      swal({
        title: "Oops!",
        text: "token expired. Please login again",
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          window.location.href = process.env.REACT_APP_BASE_NAME + `/login`;
          sessionStorage.clear();
        }
      });
    }
  }; */

  if (RedirectLogin) {
    setHasUserPermission(checkUserPermission(menukey));// To check user has permission in this page
    checkLogin();
    setRedirectLogin(false);
  }
    //This is for user has VIEW/EDIT permission in this page start
    const permissionType = getUserActionPermission(menukey);
    // console.log('propsprops permissionType index: ', permissionType);
    //This is for user has VIEW/EDIT permission in this page end

  React.useEffect(() => {
    // checkLogin();
    // checkAccess();
  }, []);

  const [msgObj, setMsgObj] = React.useState({
    isOpen: false,
  });
  const openNoticeModal = (obj) => {
    setMsgObj(obj);
  };
  const closeNoticeModal = (event, reason) => { 
      if (reason === 'clickaway') {
        return;
      } 
    setMsgObj({ isOpen: false });
  };

  return (
    hasUserPermission && (
        <div>
          <AfterLoginNavbar {...props} />
          <div
            className={`section signup-top-padding ${classes.dispenserContainer}`}
          >
            <Switch>
              <Route
                path={`${path}/add`}
                render={(props) => (
                  <AddNationalSupplier {...props} openNoticeModal={openNoticeModal}  permissionType={permissionType}/>
                )}
              ></Route>
              <Route
                path={`${path}/edit/:id`}
                render={(props) => (
                  <EditNationalSupplier {...props} openNoticeModal={openNoticeModal}  permissionType={permissionType}/>
                )}
              ></Route>
              <Route
                path={`${path}/`}
                render={(props) => (
                  <NationalSupplier {...props} openNoticeModal={openNoticeModal}  permissionType={permissionType}/>
                )}
              ></Route>
            </Switch>
            <Notification
              closeNoticeModal={closeNoticeModal}
              msgObj={msgObj}
              {...props}
            ></Notification>
          </div>
        </div>
    )
  );
};

export default Index;

const useStyles = makeStyles({
  dispenserContainer: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
});
