import React, { useEffect, useState } from "react";//, 
import { makeStyles, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Autocomplete from '@material-ui/lab/Autocomplete';

// style for slider
// import 'swiper/css';
// import "swiper/css/navigation";
// import "swiper/css/pagination";

import 'swiper/swiper.min.css';
import 'swiper/modules/navigation/navigation.min.css'
import 'swiper/modules/pagination/pagination.min.css';

import { Virtual, Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from "swiper";
// import '../styles/modal-slider.css'

import {
  Grid,
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Card,
  CardContent,
  TextField,
  Typography


} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import { useQuery, useMutation } from "react-query";
import * as api from "../../../actions/api";
import { useTranslation } from "react-i18next";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ImageModal = ({ handleClose, openOrder, addOrder, ...props }) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "product-specification";


  const { t, i18n } = useTranslation();
  const classes = useStyles();


  const [btnDisable, setbtnDisable] = useState(false);






  const removeStrFromURL = process.env.REACT_APP_API_URL;
  const REPORT_URL = removeStrFromURL.replace('backend/', '');
  function srcset(image, size, rows = 1, cols = 1) {


    return {
      src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
      srcSet: `${image}?w=${size * cols}&h=${size * rows
        }&fit=crop&auto=format&dpr=2 2x`
    };
  }

  const [swiper, setSwiper] = useState(0);

  const slideTo = (index) => {
if(swiper) 
swiper.slideTo(index)};

// useEffect(() => {
//   if(swiper) 
//   swiper.slideTo(5);
// },[])

  return (
    <div>

      <BootstrapDialog
        onClose={() => handleClose('ImageModal')}
        aria-labelledby="customized-dialog-title"
        open={openOrder}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('ImageModal')}
        >
         {/* {DispensingLanguage[lan][menukey]['Year']}: */}
          {addOrder['YearName']+ ', '}
          {addOrder['PackageName']+ ', '}
         {/*  {DispensingLanguage[lan][menukey]['Item Name']}: */}
          {' ' + addOrder['ItemName']}
          
        </BootstrapDialogTitle>



        <DialogContent dividers>
{/* 
          <center>
            
              <img
                {...srcset(
                
                  addOrder['ProductImage'] 
                
                , 500, 5, 5)}
              loading="lazy" 
            />
        </center> */}

          <center>

            <div className="slider-container">
              <div className='slider-image-container'>
                <Swiper
                
                /*onSwiper={setSwiper} 
                  initialSlide={swiper
                  
                   
                  }*/
                  className='image-selection-slider'
                  modules={[ Navigation, Pagination]}
                  slidesPerView={1}
                  navigation={true}
                  centeredSlides={true} 
                  pagination={{
                    // dynamicBullets: true,
                    type: "fraction",
                    clickable: true,
                  }}
                  loop={false}
                  scrollbar={{ draggable: true }}>

                   {
                    addOrder["ImageArray"]!=undefined?
                    addOrder["ImageArray"].map((item,index) => (
                      <SwiperSlide key={item} virtualIndex={index} >
                          <img src={item} className="slider-image" />
                        </SwiperSlide> 
                    )
                    ):<></>

                   } 
                   

                </Swiper>
              </div>

            </div>
          </center>


        </DialogContent>
        <DialogActions>

          <Button autoFocus
            className="float-left"
            variant="contained"

            onClick={() => handleClose('ImageModal')}>
            {DispensingLanguage[lan][menukey]['Cancel']}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ImageModal;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});