import React from 'react';
//import { Button } from "reactstrap";

import {
    Button
  } from "@material-ui/core";

import Tooltip from '@material-ui/core/Tooltip';


const ExportButtonsMany = ({ ...props }) => {

    const PrintAndExel = (PrintPDFExcelExport) => {


        props.PrintMany();
        //console.log(PrintPDFExcelExport);
       // console.log(props);
        //PrintPDFExcelExportFunction("Print");
        
      };

  return (
    
    <>
                  <Tooltip  title="Print">

                     <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PrintAndExel("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>


                    
                  
                  </Tooltip>
                  
                  
    </>
  );
};

export default ExportButtonsMany;
