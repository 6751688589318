import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";
import ExportButtons from "../../../components/ExportButtons";

const AdjustmentType = (props) => {

  //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "adjustment-type";
  const FacilityId = localStorage.getItem("FacilityId");
  const UseFor = localStorage.getItem("UseFor");
  const FacilityName = localStorage.getItem("FacilityName");
  const UserInfo = sessionStorage.getItem("User_info")? JSON.parse(sessionStorage.getItem("User_info")): 0;  
  const UserName = UserInfo==0?'': UserInfo[0].id;

  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { isLoading, error, isError, data, refetch } = useQuery(
    "adjustmentType",
    api.getAdjustmentTypes,
    {
      onSuccess: (data) => {
        setDataItems(data); 
      } 
    }
  );
  const { mutate:deleteApi } = useMutation(api.deleteAdjustmentType, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

        api.getAllDropdown('AdjType').then((response) => {
          localStorage.setItem(
            "AdjType",
            JSON.stringify(response.datalist.AdjType)
          );
        })
        refetch();
        queryClient.getQueriesData("adjustmentType");    
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
    
        }else{   
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
        }
    },
  });




  /* =====Start of Excel Export Code==== */
const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

const PrintPDFExcelExportFunction = (reportType) => {
  let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

  window.open(
    finalUrl +
      "?action=AdjustTypeExport" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&UseFor=" +
      UseFor +
      "&FacilityName=" +
      FacilityName +
      "&FacilityName=" +
      FacilityName +
      "&lan=" +
      localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
  );
};
/* =====End of Excel Export Code==== */

const tableRowDoubleClick = (e, row) => {
  let rowData = row._row.data;
  //editAdjustmentType(rowData);
};

  const columns = [
    // { title: "Id", field: "id", visible: false },
    // { title: DispensingLanguage[lan][menukey]['Sl#'], hozAlign: 'center', width: 120, formatter: "rownum", headerHozAlign:"center", headerSort:false},
    { title: DispensingLanguage[lan][menukey]['Adjustment Type'], field: "id",width:"8%", headerSort:false},
    { title: DispensingLanguage[lan][menukey]['Adjustment Type Name'], field: "AdjTypeName",width:"60%"},
    { title: DispensingLanguage[lan][menukey]['Usable In'], field: "UsableIn",width:"8%",hozAlign: 'right'},
    { title: DispensingLanguage[lan][menukey]['Usable Out'], field: "UsableOut",width:"8%",hozAlign: 'right'},
    { title: DispensingLanguage[lan][menukey]['Unsable In'], field: "UnusableIn",width:"8%",hozAlign: 'right'},
    { title: DispensingLanguage[lan][menukey]['Unsable Out'], field: "UnusableOut",width:"8%",hozAlign: 'right'},
  
    // {
    //   title: DispensingLanguage[lan][menukey]['Actions'],
    //   field: "custom",
    //   hozAlign: "center",
    //   width: 120,
    //   headerHozAlign:"center",
    //   headerSort:false,
    //   formatter: reactFormatter(<ActionButton />),
    // },
  ];


  // const editAdjustmentType = (data) => {
  //   let route = `adjustment-type/edit/${data.id}`;
  //   props.history.push(route);
  // };

  // const deleteAdjustmentType = (data) => {
  //   swal({
  //     title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
  //     text: t(
  //       DispensingLanguage[lan][menukey][
  //         "Once deleted, you will not be able to recover this Record!"
  //       ]
  //     ),
  //     icon: "warning",
  //     buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
  //     t(DispensingLanguage[lan][menukey]["Yes"])],
  //     dangerMode: true,
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       deleteApi({AdjTypeId:data.id,"menukey": menukey,"lan":lan,"UserName":UserName,"action": "dataDalete"});
      
  //     }
  //   });
  // };

  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        {/* <Edit
          onClick={() => {
            editAdjustmentType(rowData);
          }}
        /> */}
        {/* <DeleteOutline
          onClick={() => {
            deleteAdjustmentType(rowData);
          }}
        /> */}
      </>
    );
  }

  return (
    <>
      <div className={classes.AdjustmentTypePageTitle}>
        <div className="d-flex justify-adjustmentType mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">{DispensingLanguage[lan][menukey]['Adjustment Type']}</div>
              <div className="float-right sw_btn_control">
                {/* <Button
                  color="info"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/adjustment-type/add");
                  }}
                >
                 {DispensingLanguage[lan][menukey]['Add Adjustment Type']} 
                </Button> */}

              <ExportButtons PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}  {...props}></ExportButtons>

                {/* <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i class="fas fa-print"></i>
                </Button>

                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button> */}
              </div>
            </div>
          </Grid>
        </div>

        <div>
          <div className="uniqueName">
            <ReactTabulator columns={columns}
             data={dataItems}
             height={600}
             layout={"fitColumns"}
             rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
              />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdjustmentType;

const useStyles = makeStyles({
  AdjustmentTypePageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
