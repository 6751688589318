import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";

import ExportButtons from "../../../components/ExportButtons";


const EntryofWarehouse = (props) => {
  const permissionType = props.permissionType;
    //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "entry-edit-of-warehouse";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo==0?'': UserInfo[0].id;

  const classes = useStyles();

  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data, refetch } = useQuery("EntryofUpazila", api.getEntryofWarehouseLists, {
    onSuccess: (data) => {
      setDataItems(data);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });

  // const { mutate:deleteApi } = useMutation(api.deleteStoreType, {
  //   onSuccess: (data) => {

  //     if (data.data.status == 200) {

  //     refetch();
  //     queryClient.getQueriesData("StoreTypeEntry");
      
  //     props.openNoticeModal({
  //       isOpen: true,
  //       msg: data.data.message,
  //       msgtype: data.data.success,
  //     });

  //     }else{ 
  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.data.message,
  //         msgtype: data.data.success,
  //       });  
           
  //       }
  //   },
  // });

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=getWareHouseExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    editunit(rowData);
  };

  const columns = [
    { title: "Id", field: "id", width: 50, visible: false },
  {
  title: DispensingLanguage[lan][menukey]["Sl#"],
  formatter: "rownum",
  width: "3%",
  hozAlign: "center",
  headerSort: false,
  headerHozAlign: "center",
  },
  {
    title: DispensingLanguage[lan][menukey]["Warehouse Name"],
    width: "10%" ,
    field: "wh_name",
    headerFilter: true 
  },
  { 
    title: DispensingLanguage[lan][menukey]["District"],
    width: "10%",
    field: "wh_district",
    headerFilter: true 
  },
  { 
    title: DispensingLanguage[lan][menukey]["Address"],
    width: "20%",
    field: "wh_address",
    headerFilter: true 
  },
  { 
    title: DispensingLanguage[lan][menukey]["Contact Person"],
    width: "15%", 
    field: "contact_person",
    headerFilter: true 
  },
  { 
    title: DispensingLanguage[lan][menukey]["Email"],
    width: "13%",
    field: "contemail",
    headerSort: false,
    headerFilter: true
  },
  { 
    title: DispensingLanguage[lan][menukey]["Phone"],
    width: "7%",
    field: "extra",
    headerSort: false 
  },
  { 
    title: DispensingLanguage[lan][menukey]["Mobile"],
    width: "7%", 
    field: "phoneno",
    headerFilter: true 

  },
  { 
    title: DispensingLanguage[lan][menukey]["Using_Web_Desktop"],
    width: "10%", 
    field: "UsingSoftware",
    headerFilter: true 

  },


  {
    title: DispensingLanguage[lan][menukey]["Actions"],
    field: "custom",
    hozAlign: "center",
    width: "5%",
    headerSort: false,
    headerHozAlign: "center",
    formatter: reactFormatter(<ActionButton />),
  },
  ];

  const editunit = (data) => {
    let route = `entry-edit-of-warehouse/edit/${data.id}`;
    props.history.push(route);
  };



  

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    // const cellValue = props.cell._cell.value || "Edit | Show";
    if (permissionType) return <></>;
    else
    return (
      <>
        <Edit
          onClick={() => {
            editunit(rowData);
          }}
        />

      </>
    );
  }

  return (
    <>
      <div className={classes.dispenserPageTitle}>
        <div className="d-flex justify-dispenser mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Entry Edit of Warehouse"]}
              </div>
              <div className="float-right sw_btn_control">
                <Button
                  disabled={permissionType}
                  color="info"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/entry-edit-of-warehouse/add");
                  }}
                >
                  {DispensingLanguage[lan][menukey]["Add New"]}
                </Button>

                <ExportButtons PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}  {...props}></ExportButtons>
                {/* <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i class="fas fa-print"></i>
                </Button>

                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button> */}
              </div>
            </div>
          </Grid>
        </div>
        <div>
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height={600}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EntryofWarehouse;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
