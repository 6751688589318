import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from "react-i18next";
import swal from "sweetalert";


const OthersFacilityListFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  const classes = useStyles();
//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "others-facility-list";
const DistrictList = JSON.parse(localStorage.getItem("DistrictList"));
  const { t, i18n } = useTranslation();


  //====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "DistCode": {"id": "","name": ""}
});


useEffect(() => {
  
  let chosenValuesData = { ...chosenValues};
   chosenValuesData['DistCode'] = DistrictList[DistrictList.findIndex(District_List => District_List.id == formData.DistCode)];
   setChosenValues(chosenValuesData); 

  }, [formData.DistCode]);

const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  setChosenValues(chosenValuesData); 
  props.handleChangeChoosenProps(name, value);
};

//====== End Choosen Dropdown===========




const backtolist=()=>{


  let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
  let idx = master_dirty_message_class.indexOf("dnone");
  if(idx === -1){
    // start Alert
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then((willAction) => {
      if (willAction) {
        props.history.push("/others-facility-list");
      }
    });
    // End Alert
  }else{
    props.history.push("/others-facility-list");
  }

}


return (
  <div className={classes.productPageTitle}>
    <div className="sw_makeStyles_tableContainer">
      <div className="d-flex justify-product mb-3">
        <Grid item xs={12} sm={12}>
          <div className="sw_page_heading">
            <div className="sw_heading_title">
              {DispensingLanguage[lan][menukey]["Others Facility Entry Form - Add/Edit"]}
            </div>
            </div>
            <div className="float-right sw_btn_control">
              {addProductForm ? (
                <Grid item xs={12} className="text-center">
                  <div className="float-right sw_btn_control">
                    <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => handleReset()}
                    >
                      {DispensingLanguage[lan][menukey]["Reset"]}
                    </Button>
                    <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => handleSubmit()}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={() => props.history.push("/entry-edit-of-supplier")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} className="mt-2 text-center">
                  <Button
                      disabled={props.bStockUpdate}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={() => handleUpdate()}
                  >
                    {DispensingLanguage[lan][menukey]["update"]}
                  </Button>

                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    //onClick={() => props.history.push("/entry-edit-of-supplier")}
                    onClick={() => backtolist()}
                  >
                    {DispensingLanguage[lan][menukey]["Back to List"]}
                  </Button>
                </Grid>
              )}
            </div>
          
             <div className="mr-4 float-right mt-15">
              <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
            </div>
        </Grid>
      </div>

      {/* New row */}
      <Grid container spacing={3}>
        {/* New row */}
        <Grid item xs={12} sm={12}>
          <Card className="sw_card">
            <CardContent>
              <Grid container spacing={4}>

                  <Grid item xs={6} sm={4}>

                      <TextField
                        error={errorObject.FacilityCode}
                        helperText={errorObject.FacilityCode}
                        required
                        id="FacilityCode"
                        name="FacilityCode"
                        disabled ={true}
                        label={DispensingLanguage[lan][menukey]['Facility Code']}
                        value={formData.FacilityCode}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}

                      />

                      </Grid>

                    <Grid item xs={6} sm={4}>
                      <TextField
                      
                        error={errorObject.FacilityName}
                        helperText={errorObject.FacilityName}
                        required
                        disabled={props.bStockUpdate}
                        id="FacilityName"
                        name="FacilityName"
                        label= {DispensingLanguage[lan][menukey]['Facility'] }
                        value={formData.FacilityName}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                    <FormControl className={classes.fullWidth}>
                       {/*  <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]['District'] + " *"}
                        </InputLabel>
                        <Select
                          error={errorObject.DistCode}
                          labelId="demo-simple-select-helper-label"
                          id="DistCode"
                          name="DistCode"
                          value={formData.DistCode}
                          fullWidth
                          onChange={(e) => handleChange(e)}
                          disabled={props.bStockUpdate}
                        >
                            {
                      DistrictList.map(
                      (item, index) => {
                        return (
                          <MenuItem value={item.id}>
                            {item.name}
                          </MenuItem>
                        );
                      }
                    )}
                        </Select>
                        <FormHelperText error={errorObject.DistCode}>
                          {errorObject.DistCode}
                        </FormHelperText> */}

                      <Autocomplete
                        autoHighlight
                        id="DistCode"
                        options={DistrictList}
                        onChange={(event, valueobj) => handleChangeChoosen('DistCode', valueobj, valueobj?valueobj.id:"")}
                        getOptionLabel={(option) => option.name}
                        value={chosenValues['DistCode']}
                        renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          value={formData.DistCode}
                          label={DispensingLanguage[lan][menukey]["District"]+ " *"}
                          variant="standard"
                          error={errorObject.DistCode}
                          helperText={errorObject.DistCode}
                          id="DistCode"
                          name="DistCode"
                          fullWidth
                        />
                        )}
                      />

                      </FormControl>
                      
                    </Grid>

                    <Grid item xs={6} sm={4}>
                      <TextField
                      
                       // error={errorObject.PhoneNo}
                        //helperText={errorObject.PhoneNo}
                       //required
                        id="PhoneNo"
                        name="PhoneNo"
                        disabled={props.bStockUpdate}
                        label= {DispensingLanguage[lan][menukey]['Phone No.'] }
                        value={formData.PhoneNo}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                        inputProps={{
                          maxLength: 11,
                          placeholder:"Please Enter 11 Digit Phone Number",
                        }}
                      />
                    </Grid>


                    <Grid item xs={6} sm={4} className="sw_active_patient">
                    <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={formData.bShow}
                        onChange={(e) => handleCheck(e)}
                        name="bShow"
                        value="no"
                        disabled={props.bStockUpdate}
                      />
                    }
                     label={DispensingLanguage[lan][menukey]["Receiving Facility"]}
                  />
                    </Grid>
                  </Grid>

              {/* New Row */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  </div>
);
};

export default OthersFacilityListFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
