import React, { useEffect } from "react";//useState, 
import { styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { 
  Button, 
  DialogContent,
  DialogActions,
  IconButton,
  Dialog,
  DialogTitle,
  

} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";  
import { useQuery, useMutation } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";
import moment from "moment";



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const OrderModal =({ handleClose, openOrder, addOrder, ...props }) => {
  
  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "receive";
 
  let tblOrderRef = null;
  const { data } = useQuery(
    "NonReceive",
    api.getNonReceivedOrderList
  );
   
  const OrderModalColumns = [
    { title: "id", field: "id", visible: false }, 
    { title: DispensingLanguage[lan][menukey]['Sl#'],  formatter: "rownum", width:50, hozAlign: "center",
     headerHozAlign:"center", headerSort:false },
    { title: DispensingLanguage[lan][menukey]['Order No'], field: "OrderNo"  },
    { title: DispensingLanguage[lan][menukey]['Order Date'], field: "OrderDate", width: 100, 
      formatter: function (cell, formatterParams) {
      let value = cell.getValue();
       
      return (value==null?'':moment(value).format("DD/MM/YYYY"));
      
    } },
    { title: DispensingLanguage[lan][menukey]['Order Type'], field: "OrderType" , width: 100 },
  ]; 
  const OrderModaltDataItems = data?.map((item, index) => { 
    return item;
  });
  const {mutate} = useMutation(api.saveNonReceivedOrder, {
    onSuccess: (data)=>{
       
      addOrder(data);
      //queryClient.getQueriesData('receive');
    }
  })
  const SaveSelectedOrder = () => { 

    if(tblOrderRef.table.getSelectedData().length>0)
    mutate(tblOrderRef.table.getSelectedData()[0].OrderId); 
   
  };

  
useEffect(() => { 
    if (tblOrderRef == null) return;
 

}, []); 

  return (
    <div>
     
      <BootstrapDialog
        onClose={() => handleClose('OrderModal')}
        aria-labelledby="customized-dialog-title"
        open={openOrder}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('OrderModal')}
        >
         {DispensingLanguage[lan][menukey]['Order Lists']}
        </BootstrapDialogTitle>
        <DialogContent dividers>  
          <ReactTabulator ref={(r) => (tblOrderRef = r)} 
           selectable={1} 
           columns={OrderModalColumns}
           data={OrderModaltDataItems}
           height="300px" 
          />
        </DialogContent>
        <DialogActions>
          <Button 
          className="mr-2 float-left"
          variant="contained"
          color="primary" 
          onClick={SaveSelectedOrder}>
           {DispensingLanguage[lan][menukey]['ADD']} 
          </Button>
          <Button autoFocus 
          className="float-left"
          variant="contained"
          
          onClick={() => handleClose('OrderModal')}>
            {DispensingLanguage[lan][menukey]['CANCEL']} 
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default OrderModal;
