import React, { useState ,useEffect } from "react";//, useEffect
import {
  Typography
} from "@material-ui/core";

import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query'
import { useParams } from 'react-router-dom';
import * as api from '../../../actions/api';
import MasterManyFormData from './ReturnTotheSupplierFromProvisionalReceiveItems.js';
import moment from "moment";

const AddSupplier = (props) => {

  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "return-to-the-supplier-from-provisional-receive";

const FacilityId=localStorage.getItem('FacilityId');
const CurrentFacilitySetting=JSON.parse(localStorage.getItem('CurrentFacilitySetting'));
 
const CurrFacilitySetting = CurrentFacilitySetting[0];

const [selectedDate, setSelectedDate] = useState(
    //new Date()
    moment().format("YYYY-MM-DD")
);
const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState(
  ''
); 

const [selectedDefaultReturnType, setDefaultReturnType] = useState('1');

  const [formData, setFormData] = useState({
      TransactionDate: selectedDate,
      AppDate: selectedDate,
      ReturnDate: selectedDate,
      SupplierInvDate: selectedDate,
      SupplierInvNo: "",
      TransactionNo: generatedInvoiceNo, 
      FacilityId:FacilityId,
      TransactionTypeId:2,
      SupCode:"",
      OrderNo:"",
      PreparedBy:CurrentFacilitySetting[0].InvPrepBy,
      DeliveredBy: "",
      Remarks:"",
      BLNo: "",
      BLDate: selectedDate,
      YearId: "",
      PackageYear: "",
      PackageNo: "",
      PackageName: "",
      ContractNo: "",
      ContractName: "",
      IndentorId: "",
      BCancel:0,
      bUpdated:0,
      AppBy: CurrFacilitySetting.InvAppBy,
      ReturnBy: CurrFacilitySetting.InvSupBy,
      ReceivedByName: "",
      ReturnByName: "",
      AppByName: "",
      ReceivedBybActive: "",
      ReturnBybActive: "",
      AppBybActive: "",
      ReturnType: selectedDefaultReturnType,
  });


const [ManyTableData, setManyTableData] = useState( []); 
const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);

const handleRDateChange = (date, field) => {
  let newDate;
  if(date==null || date==""){
    newDate="";
   }
   else{
     newDate = moment(date).format("YYYY-MM-DD");
   }
  setFormData({ ...formData, [field]: newDate });
  setErrorObject({ ...errorObject, [field]: null });
  api.dirtyModeEnable();
};

 const handleDateChange = (date) => {
  setSelectedDate(date);
}; 
 
  const [errorObject, setErrorObject] = useState({});
   
  const { id } = useParams();
  const queryClient = useQueryClient();

  let params={"menukey": menukey, "FacilityId":FacilityId, "TransactionTypeId":2, TransactionDate: '', TableName: 'provisionalrecinv'};
  const {  data:invoiceNo, refetch } = useQuery([params], api.getInvoiceNo, 
    {onSuccess: (data) => {
      
      setGeneratedInvoiceNo(data);
      let fdata = { ...formData }; 
      fdata["TransactionNo"] = data; 
      setFormData(fdata); 

    }}
    );

  const { isLoading, mutate } = useMutation(api.ReturnTotheSupplierFromProvisionalReceiveDataInsert, {
    onSuccess: (data) => { 
      if (data.status == 200) {

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      let route = `edit/${data.TransactionId}`;
      props.history.push(route); 
      
      }else{
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })  
  const handleAdd = (NonOrderProductData) => {
      setManyTableData(NonOrderProductData);
      setUpdatedDetailGridData(NonOrderProductData);
  };
 
  const handleAddContract = (ContractData) => {

    let fdata = { ...formData }; 
    let row = ContractData;

    fdata["ContractName"] = row[0].ContractName;
    fdata["ContractNo"] = row[0].ContractNo;
    fdata["PackageName"] = row[0].PackageName;
    fdata["PackageNo"] = row[0].PackageNo;
    // fdata["PackageYear"] = row[0].PackageYear;
    // fdata["YearId"] = row[0].YearId;

    setFormData(fdata); 
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('formData: ', e.target);

      let data = { ...formData };
      
      data[name] = value; 
      setFormData(data); 
    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };

  
    //====== Start Choosen Dropdown===========
    const handleChangeChoosenProps = (name, value) => {
      let data = { ...formData };
      data[name] = value;   
      setFormData(data); 
      setErrorObject({ ...errorObject, [name]: "" });
      api.dirtyModeEnable();
    }
    //====== End Choosen Dropdown===========

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };


  const invoiceCancel = (e)=>{

    // // console.log('handleCheck e: ', e);
    // const { name, checked } = e.target;
    // // console.log('name, checked: ', name, checked);
    // // console.log('invoiceCancel formData: ', formData);

    // swal({
    //   title: DispensingLanguage[lan][menukey]["Are you sure?"],
    //   text: DispensingLanguage[lan][menukey]["Do you really want to cancel?"]+" "+DispensingLanguage[lan][menukey]["You will not able to edit this invoice after cancel"],
    //   icon: "warning",
    //   buttons: [DispensingLanguage[lan][menukey]["No"], 
    //   DispensingLanguage[lan][menukey]["Yes"]],
    //   dangerMode: true,
    // }).then((willCancel) => {
    //   if (willCancel) {
    //     let fDtat={...formData,'ManyJsonSave':ManyTableData,"BCancel":1,"menukey":"receive"};
    //     mutate(fDtat);
    //   }else{
    //     setFormData({ ...formData, [name]: false });
    //   }
    // });

  };

  const handleReset = () => {
    setFormData({
      TransactionDate: selectedDate,
      AppDate: selectedDate,
      ReturnDate: selectedDate,
      SupplierInvNo: "",
      SupplierInvDate: selectedDate,
      TransactionNo:generatedInvoiceNo, 
      FacilityId:FacilityId,
      TransactionTypeId:2, 
      SupCode:"",
      OrderNo:"",
      PreparedBy:CurrentFacilitySetting[0].InvPrepBy,
      DeliveredBy: "",
      Remarks:"",
      BLNo: "",
      BLDate: selectedDate,
      YearId: "",
      PackageYear: "",
      PackageNo: "",
      PackageName: "",
      ContractNo: "",
      ContractName: "",
      IndentorId: "",
      BCancel:0,
      bUpdated:0,
      AppBy: CurrFacilitySetting.InvAppBy,
      ReturnBy: CurrFacilitySetting.InvSupBy,
      ReceivedByName: "",
      ReturnByName: "",
      AppByName: "",
      ReceivedBybActive: "",
      ReturnBybActive: "",
      AppBybActive: "",
      ReturnType: selectedDefaultReturnType,
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["TransactionDate", "AppDate", "ReturnDate", "TransactionNo","PreparedBy","SupCode", "YearId", "ContractName"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !']
        isValid = false
      }
    })
    setErrorObject(errorData);
    return isValid
  }

  const handleSubmit = async (e) => {

    if (validateForm(formData)) {
      let fDtat={
        ...formData, 
        ManyJsonSave: ManyTableData, 
        menukey: menukey,
      };
      mutate(fDtat);
    }
  };
  const deleteReceiveInv=(data)=>{
    
    let newarray = ManyTableData.filter(element => element !== data); 
    setManyTableData(newarray);
  }

  // const setUpdatedDetailsGridDataHandler = (rows) => { 
  //   if (validateForm(formData)) {
  //     let fDtat={...formData,'ManyJsonSave':rows,"menukey": menukey}; 
  //     mutate(fDtat); 
  //     }

  //   setManyTableData(rows); 
  // };


  
  function checkValidateForm(rows, callfrom){

    if(callfrom == "edit"){
      setManyTableData(rows); 
    }

    // return validateForm(formData);
    if (validateForm(formData)) {
        let fDtat={...formData,'ManyJsonSave':rows, menukey: menukey,'deletedDataSet':[]}; 
        //mutateUpdate(fDtat); 
        return fDtat;
      }else{
        return false;
      }
  };


  useEffect(() => { 

  }, []); 

  return (
    <> 
      <MasterManyFormData 
      errorObject={errorObject} 
      addProductForm={true} 
      formData={formData} 
      handleChange={handleChange} 
      handleChangeChoosenProps={handleChangeChoosenProps} 
      handleCheck={handleCheck} 
      handleReset={handleReset} 
      // invoiceCancel={invoiceCancel} 
      // handleSubmit={handleSubmit} 
      ManyTableData={updatedDetailGridData}   
      handleRDateChange={handleRDateChange} 
      selectedDate={selectedDate} 
      deleteReceiveInv={deleteReceiveInv}
      // setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
      bStockUpdate={props.permissionType}
      handleAdd={handleAdd} 
      generatedInvoiceNo={generatedInvoiceNo}
      handleAddContract={handleAddContract}

      checkValidateForm={checkValidateForm}
      {...props} />
      
    </>
  );
};

export default AddSupplier;