import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel,
  RadioGroup,
  MenuItem,
  Paper,
  CardHeader,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "form-7b-uims";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Form7BUIMSMistakeStatus = ({ handleClose, open, actionFromMistakePopup, FacilityId, YearId, MonthId, ...props }) => {
  let tblRef = null;
  // let YearId = 2022;
  // let MonthId = 6;
  const { isLoading, error, isError, data, refetch } = useQuery(
    ["form-7b-uims1", YearId, MonthId],
    api.getItemMistakeStatus,{
      onSuccess: (isunpostinv) => {
      
      },
      // enabled: false,
      // refetchOnWindowFocus: false,
      // refetchOnmount: false,
      // refetchOnReconnect: false,
      // retry: false,
      // staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const [selectedProducts, setselectedProducts] = useState([]);

  const ProductColumns = [
    // { title: "id", field: "ItemNo", visible: false },
    { title: DispensingLanguage[lan][menukey]['Sl#'],  formatter: "rownum", width:40, hozAlign: "center" , headerHozAlign:"center", headerSort:false, visible: false},
    { title: DispensingLanguage[lan][menukey]['Form Type'], field: "fType", width: 80, headerSort:false,hozAlign: "left" , headerHozAlign:"left", visible: false },
    { title: DispensingLanguage[lan][menukey]['SDP Code'], field: "FacilityCode", width: 80, headerSort:false,hozAlign: "left" , headerHozAlign:"left"},
    { title: DispensingLanguage[lan][menukey]['SDP Name'], field: "FacilityName", headerSort:false,hozAlign: "left" , headerHozAlign:"left"},
    { title: DispensingLanguage[lan][menukey]['Injectables Distribution'], field: "Distribution", width: 100, headerSort:false,hozAlign: "right" , headerHozAlign:"right"},
    { title: DispensingLanguage[lan][menukey]['Injectables Syringe Distribution'], field: "SyringeDistribution", width: 140, headerSort:false,hozAlign: "right" , headerHozAlign:"right"},
  ];



  const ProductColumnsF2F3 = [
    { title: DispensingLanguage[lan][menukey]['Sl#'],  formatter: "rownum", width:40, hozAlign: "center" , headerHozAlign:"center", headerSort:false, visible: false},
    { title: DispensingLanguage[lan][menukey]['Form Type'], field: "fType", width: 80, headerSort:false,hozAlign: "left" , headerHozAlign:"left", visible: false },
    { title: DispensingLanguage[lan][menukey]['SDP Code'], field: "FacilityCode", width: 80, headerSort:false,hozAlign: "left" , headerHozAlign:"left"},
    { title: DispensingLanguage[lan][menukey]['SDP Name'], field: "FacilityName", headerSort:false,hozAlign: "left" , headerHozAlign:"left"},
  ];
  
  // console.log('datassssssssssssvvvv: ', data);

  let selected = [];
  let invoicelist = [];
  let f2f3list = [];

  if(data !== undefined){
    invoicelist=data.invoicelist;
    f2f3list=data.f2f3list;
  }

  const ProdustDataItems = invoicelist?.map((item, index) => { 
    return item;
  });
  // console.log('ProdustDataItems: ', ProdustDataItems);

  const ProdustDataItemsF2F3 = f2f3list?.map((item, index) => { 
    return item;
  });

  
  const SaveSelectedProducts = () => {
    if(tblRef.table.getSelectedData().length>0)
    // console.log(tblRef.table.getSelectedData());
    setselectedProducts(tblRef.table.getSelectedData());
    
  };

  useEffect(() => { 
    if (tblRef == null) return;
    actionFromMistakePopup(selectedProducts);

}, [selectedProducts]); 


  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose('Products')}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="lg"
      >


        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('ProductsMistake')}
        >
        {DispensingLanguage[lan][menukey]['Form 2 & Form 3 SDP(s) Name who have Distribution entry mistake (left side) and No Distribution entered (right side)']} 
        </BootstrapDialogTitle>


        <Grid container className="mb-2">
            <Grid item xs={8} sm={8}>
              
              <DialogContent dividers>
                <ReactTabulator
                  ref={(r) => (tblRef = r)}
                  options={{
                    // selectableRangeMode: 'click',
                    groupBy: "fType",
                   columnCalcs: "false",
                  }}
                  // selectable={true}
                  columns={ProductColumns}
                  data={ProdustDataItems}
                  height="300px"
                  layout={"fitColumns"}
           
                />
              </DialogContent>
              </Grid>

              <Grid item xs={4} sm={4}>
              <DialogContent dividers>
                <ReactTabulator
                  ref={(r) => (tblRef = r)}
                  options={{
                    // selectableRangeMode: 'click',
                    groupBy: "fType",
                    //columnCalcs: "both",
                  }}
                  // selectable={true}
                  columns={ProductColumnsF2F3}
                  data={ProdustDataItemsF2F3}
                  height="300px"
                  layout={"fitColumns"}

                />
              </DialogContent>
          </Grid>
        </Grid>


        <Grid  item xs={12} sm={12}>
          <div className="red fontBold text-center width-100">{DispensingLanguage[lan][menukey]['Do you really want to continue with Form 2 or Form 3 Distribution entry mistake(s) or No Distribution entered?']} </div>
        </Grid>


        <Grid className="margin0auto" item xs={12} sm={12} >
          <DialogActions >
            <Button
              className="mr-2 float-left"
              variant="contained"
              color="primary"
              // onClick={SaveSelectedProducts}
              onClick={() => actionFromMistakePopup('Yes')}
            >
            {DispensingLanguage[lan][menukey]['Yes']}  
            </Button>

            <Button
              autoFocus
              className="float-left"
              variant="contained" 
              onClick={() => handleClose('ProductsMistake')}
            >
              {DispensingLanguage[lan][menukey]['No']}   
            </Button>

          </DialogActions>
        </Grid>

      </BootstrapDialog>
    </div>
  );
};

export default Form7BUIMSMistakeStatus;
