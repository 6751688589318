import React, { useState, useEffect } from "react"; //, useEffect
import { Typography } from "@material-ui/core";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import DispenserFormData from "./AdjustmentFormData";
import moment from "moment";

const AddAdjustment = (props) => {
    //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "adjustment-approval";
  const FacilityId = localStorage.getItem("FacilityId");
  const CurrentFacilitySetting=JSON.parse(localStorage.getItem('CurrentFacilitySetting'));
  const UseFor = localStorage.getItem("UseFor");
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;

  const [selectedDate, setSelectedDate] = useState(moment().format("YYYY-MM-DD"));
  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState("");
  const [formData, setFormData] = useState({
    TransactionDate: selectedDate,
    AdjTypeId: "",
    TransactionNo: generatedInvoiceNo,
    FacilityId: FacilityId,
    FromFacility: '',
    TransactionTypeId: 0,
    PreparedBy:CurrentFacilitySetting[0].InvPrepBy,
    ApprovedBy:CurrentFacilitySetting[0].InvAppBy,
    AppDate1: selectedDate,
    IssuedBy: CurrentFacilitySetting[0].InvSupBy,
    IssuedDate: selectedDate,
    AdjRemarks: "",
    UseFor: UseFor,
    ApprovedByName:"",
    ApprovedByName:"",
    Issued_ByName:"",
  });

  const [ManyTableData, setManyTableData] = useState([]);
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
  const [IssueToFacility, setIssueToFacility] = useState("");

  const handleRDateChange = (date, field) => {

     let newDate;
 if(date==null){
  newDate=date;
 }else{
   newDate = moment(date).format("YYYY-MM-DD");
 }
    setFormData({ ...formData, [field]: newDate });
    setErrorObject({ ...errorObject, [field]: null });
    api.dirtyModeEnable();
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [errorObject, setErrorObject] = useState({});

  const { id } = useParams();
  const queryClient = useQueryClient();

  let params = {
    menukey: menukey,
    FacilityId: FacilityId,
    TransactionTypeId: 0,
    TransactionDate: "",
    TableName: 'adjinv'
  };

  const { data: invoiceNo, refetch } = useQuery([params], api.getInvoiceNo, {
    onSuccess: (data) => {
      setGeneratedInvoiceNo(data);
      let fdata = { ...formData };
      fdata["TransactionNo"] = data;
      setFormData(fdata);
    },
  });

  // const { isLoading, mutate } = useMutation(api.AdjustmentInvoiceDataInsert, {
  //   onSuccess: (data) => {
  //     if (data.status == 200) {

  //       let route = `edit/${data.TransactionId}`;
  //       props.history.push(route);

  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.message,
  //         msgtype: data.success,
  //       });
  //     } else {
  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.message,
  //         msgtype: data.success,
  //       });
  //     }
  //   },
  // });



  const handleAdd = (NonOrderProductData) => {
    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);
  };
  


  const handleChange = (e) => {
    if(e=='IssuedToFacility'){
      setErrorObject({ ...errorObject, ['IssueToFacility']: null });
    }
    else{
    
    const { name, value } = e.target;
    if (name == "AdjTypeId") {
    }

    let data = { ...formData };
    data[name] = value;
    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
    }
    api.dirtyModeEnable();
  };


  //====== Start Choosen Dropdown===========
  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value;   
    setFormData(data); 
    setErrorObject({ ...errorObject, [name]: "" });
    api.dirtyModeEnable();
  }
  //====== End Choosen Dropdown===========

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      TransactionDate: selectedDate,
      AdjTypeId: "",
      TransactionNo: generatedInvoiceNo,
      FacilityId: FacilityId,
      FromFacility: '',
      TransactionTypeId: 0,
      IssueToFacility: IssueToFacility,
      PreparedBy:CurrentFacilitySetting[0].InvPrepBy,
      ApprovedBy:CurrentFacilitySetting[0].InvAppBy,
      AppDate1: "",
      AppDate1: selectedDate,
      IssuedBy: CurrentFacilitySetting[0].InvSupBy,
      IssuedDate: selectedDate,
      AdjRemarks: "",
      UseFor: UseFor,
      ApprovedByName:"",
      ApprovedByName:"",
      Issued_ByName:"",
    });
  };

  const validateForm = (formData) => {
    let validateFields = [
      "TransactionDate",
      "TransactionNo",
      "AdjTypeId",
      "FromFacility",
      // "IssueToFacility",
      "PreparedBy",
      "ApprovedBy",
      "AppDate1",
      "IssuedBy",
      "IssuedDate"
    ];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  // const handleSubmit = async (e) => {
  //   if (validateForm(formData)) {
  //     let fDtat = {
  //       ...formData,
  //       ManyJsonSave: ManyTableData,
  //       menukey: "adjustment",
  //       lan: lan,
  //       UserName: UserName,
  //     };

  //     mutate(fDtat);
  //   }
  // };

  const deleteReceiveInv = (data) => {
    let newarray = ManyTableData.filter((element) => element !== data);
    setManyTableData(newarray);
  };

  const setUpdatedDetailsGridDataHandler = (rows) => {
    if (rows.datatype == "FormData") {
      setFormData(rows.IssueToFacility);
    } 
    // else {
    //   if (validateForm(formData)) {
    //     let fDtat = {
    //       ...formData,
    //       ManyJsonSave: rows.data,
    //       menukey: "adjustment",
    //       lan: lan,
    //       UserName: UserName,
    //     };

    //     mutate(fDtat);
    //   } 
    //   setManyTableData(rows.data);
    // }
  };

  // const invoiceCancel = (e)=>{};

  
  function checkValidateForm(rows, callfrom){

    if(callfrom == "edit"){
      setManyTableData(rows); 
    }

    // return validateForm(formData);
    if (validateForm(formData)) {
        let fDtat={...formData,'ManyJsonSave':rows, menukey: menukey,'deletedDataSet':[]}; 
        //mutateUpdate(fDtat); 
        return fDtat;
      }else{
        return false;
      }
  };

  // useEffect(() => {}, [isPositive]);

  return (
    <>
      <DispenserFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleCheck={handleCheck}
        handleReset={handleReset}
        // handleSubmit={handleSubmit}
        ManyTableData={updatedDetailGridData}
        handleRDateChange={handleRDateChange}
        selectedDate={selectedDate}
        deleteReceiveInv={deleteReceiveInv}
        setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
        bStockUpdate={props.permissionType}
        handleAdd={handleAdd}
        generatedInvoiceNo={generatedInvoiceNo}
        DisableAddBtn={true}
        // invoiceCancel={invoiceCancel} 
        checkValidateForm={checkValidateForm}
        {...props}
      />
    </>
  );
};

export default AddAdjustment;
