import React, { forwardRef, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Grid, Checkbox, TextField, CardContent } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import moment from "moment";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import ExportButtons from "../../../components/ExportButtons";

let tblMenuRef = null;


const Form_Item_Serial = (props) => {
  //get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "warehouse-supply-plan-items-serial";
const UserInfo = sessionStorage.getItem("User_info")? JSON.parse(sessionStorage.getItem("User_info")): 0;  
const UserName = UserInfo==0?'': UserInfo[0].id;
const FacilityId = localStorage.getItem("FacilityId");
  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const [dataItems1, setDataItems1] = useState([]);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const [selectedRoleData, setSelectedRole] = useState(6);

  const [selectedrow, setSelectedrow] = useState(false);

  const [toggleButtonValue, setToggleButton] = React.useState("1");
  
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const { isLoading, error, isError, data, refetch } = useQuery(
    "warehouse-supply-plan-items-serial",
    api.getWarehouseSupplyPlanItemsSerialList,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setSelectedrow(true);
      },
    }
  );


/* =====Start of Excel Export Code==== */
const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

const PrintPDFExcelExportFunction = (reportType) => {
  let formatedDate = moment(selectedStartDate).format("YYYY-MM-DD");
  let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

  window.open(
    finalUrl +
      "?action=WarehosueSupplyPlanItemsSerialExport" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&StartDate=" +
      formatedDate +
      "&FacilityId=" +
      FacilityId +
       "&TimeStamp=" + Date.now()
  );
};

  const columns = [
    {
      title: "Id",
      field: "id",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Code"],
      field: "ProdCode",
      headerSort: true,
      headerFilter: true,
      width: 120,
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Name"],
      field: "ProdText",
      headerSort: true,
      headerFilter: true,
      //width: 105,
    },{
      title: DispensingLanguage[lan][menukey]["Rounding Factor"],
      field: "RoundingFactor",
      hozAlign: "right",
      headerHozAlign: "right",
      headerFilter: false,
      width: 150,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == 0 ? "" : Number(value).toLocaleString();
      },

    },
    {
      title: DispensingLanguage[lan][menukey]["Mul Factor"],
      field: "MulFactor",
      hozAlign: "right",
      headerHozAlign: "right",
      headerFilter: false,
      width: 140,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == 0 ? "" : value;
      },

    },

  ];



  return (
    <>
      <div className={classes.dispenserPageTitle}>
        <div className="d-flex justify-dispenser mb-2">
          <Grid item xs={12} sm={10}>
            <div className="sw_page_heading">
              <div className="sw_heading_title"> {DispensingLanguage[lan][menukey]["Warehouse Supply Plan Items Serial"]}</div>
                </div>
          </Grid>
          <Grid item xs={2} sm={2}>
                <div className="float-right sw_btn_control">
                
                <ExportButtons PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}  {...props}></ExportButtons>

                 {/* <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button> */}
                </div>
              </Grid>
        </div>
        <CardContent>
          <Grid container spacing={10}>
            <Grid item xs={4} sm={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12}>
                  <div className="uniqueName">
                    <ReactTabulator 
                    ref={(r) => (tblMenuRef = r)}
                      options={{
                        //selectable:1,
                        //selectableRangeMode: 'click',
                         //groupBy: "GroupName",
                          //columnCalcs: "both",
                          //sorter:"date",
                       }}
                      columns={columns}
                      data={dataItems}
                      layout={"fitColumns"}
                      height="470px"
                      //rowClick={(e, row) => rowClick(e, row)}
                      
                    />
                  </div>
                </Grid>

                
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </div>
    </>
  );
};

export default Form_Item_Serial;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
