import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import jsPDF from "jspdf";

//Services
import * as Service from "../../../services/Service.js";
import Constants from "../../../services/Constants.js";
import swal from "sweetalert";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
  Typography,
  TextField,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormHelperText,
} from "@material-ui/core";

import ExportButtons from "../../../components/ExportButtons";

import Autocomplete from '@material-ui/lab/Autocomplete';

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";


import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";

import * as api from "../../../actions/api.js";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, ViewList, preview, Edit, TurnedIn } from "@material-ui/icons";
import { cellFocusEditor } from "../../../services/Common.js";
import moment from "moment";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const DigitalICRGlobal = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const menukey = "digital-icr";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;

  const classes = useStyles();
  let tblRef = null;
  const tableRef = useRef();
  const { useState } = React;
  
  const { t, i18n } = useTranslation();

  const [firstLoad, setFirstLoad] = useState(true);

  const [ManyJsonSave, setInputChange] = useState({});
  const [productGroupData, selectProductGroupData] = useState();
  const [dataItems, setDataItems] = useState([]);
  const [currGroupCode, setcurrGroupCode] = useState(0);

  const [manyTableData, setManyTableData] = useState([]);
  const GroupNameList = JSON.parse(localStorage.getItem("GroupNameList"));
  GroupNameList.unshift({'id':0,'name':t(DispensingLanguage[lan][menukey]["All"])});

  let TempItemList = useState([]);
  const [ItemList, setItemListLocal] = useState(TempItemList);
  const [currItemCode, setcurrItemCode] = useState(ItemList[0].id);

  const FacilityList = JSON.parse(localStorage.getItem("FacilityListGlobal"));

  const [currFacilityCode, setcurrFacilityCode] = useState(FacilityList.length>0? FacilityList[0].id:"");
  const [currFacilityName, setcurrFacilityName] = useState(FacilityList.length>0? FacilityList[0].name:"");

//====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "GroupCode": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
});
/* const [chosenValuesItem, setChosenValuesItem] = useState({
  "ItemCode": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
}); */
const [chosenValuesItem, setChosenValuesItem] = useState({
  "ItemCode": {"id": ItemList[0].id,"name": ItemList[0].name}
}); 

const [chosenValuesFacility, setChosenValuesFacility] = useState({
  
});



const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;
  setChosenValues(chosenValuesData); 

 /*  setFirstLoad(true);
  setLoading(false); */
  setcurrGroupCode(value);

};

const handleChangeChoosenItem = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValuesItem};
  chosenValuesData[name] = valueobj;
  setChosenValuesItem(chosenValuesData); 

/*   setFirstLoad(true);
  setLoading(false); */
  setcurrItemCode(value);

};

const handleChangeChoosenFacility = (name, valueobj, value) => {
  let chosenValuesDataFacility = { ...chosenValuesFacility};
  chosenValuesDataFacility[name] = valueobj;
  setChosenValuesFacility(chosenValuesDataFacility); 
/* 
  setFirstLoad(true);
  setLoading(false); */
  setcurrFacilityCode(value);
  setcurrFacilityName(valueobj.name);

};

//====== End Choosen Dropdown===========
 

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const handleStartDateChange = (date) => {
  /*   setFirstLoad(true);
    setLoading(true); */
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
 /*    setFirstLoad(true);
    setLoading(true); */
    setSelectedEndDate(date);
  };

  

    const handleChange = (event) => {
   /*    setFirstLoad(true);
      setLoading(false); */
      setcurrGroupCode(event.target.value);
    };


  const handleChangeItemCode = (event) => {
    
    /* setFirstLoad(true);
    setLoading(false); */
    setcurrItemCode(event.target.value);

  };


  const cItemList = () => {  
  
    let ItemListParam = {action:"ItemList", GroupCode:currGroupCode, FacilityId: FacilityId, menukey:""}

    Service.default
        .postApi("source/combo_generic.php",ItemListParam)
        .then((res) => { 

          /* let All_Item_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
          const Item_List = [All_Item_label].concat(res.datalist); 
 */
          /* setChosenValuesItem({
           "ItemCode": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
          });  */

          setChosenValuesItem( {"ItemCode": {"id": res.datalist[0].id,"name": res.datalist[0].name} }); 

          setItemListLocal(res.datalist);
          setcurrItemCode(res.datalist[0].id); 
          //setcurrstockitem('0');

          // setItemListLocal(res.datalist); 
        })
        .catch((err) => {});
  
}



  const queryClient = useQueryClient();

  const UseFor = localStorage.getItem("UseFor");


  let params = {
    menukey: menukey,
    FacilityId: currFacilityCode,
    UserName: UserName,
    action: "getDataListGlobal",
    StartDate: moment(selectedStartDate).format("YYYY-MM-DD"),
    EndDate: moment(selectedEndDate).format("YYYY-MM-DD"),
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    GroupCode: currGroupCode,
    ItemCode: currItemCode,
    UseFor: UseFor,
  };

  const [isLoading, setLoading] = useState(false);

  const { error, isError, data, refetch:stockData } = useQuery(
    [params],
    api.DigitalICR,
    {
      enabled: false, 
      onSuccess: (data) => {
        /* if(firstLoad){
          
          setDataItems(data);
          setLoading(false);
          setFirstLoad(false); 
        } */
        setDataItems(data);
      },
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 0
  }
  ); 

  

  /* =====Start of Print Excel Many Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;


const PrintPDFExcelExportFunction = (reportType) => {
    let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");

if(reportType === 'print'){
  let finalUrl =
  EXCEL_EXPORT_URL +
  "report/digitalicr_print_global.php";

  window.open(
    finalUrl +
      "?menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&StartDate=" +
      StartDate +
      "&EndDate=" +
      EndDate +
      "&UserName=" +
      UserName +
      "&FacilityId=" +
      currFacilityCode +
      "&GroupCode=" +
      currGroupCode +
      "&ItemCode=" +
      currItemCode +
      "&UseFor=" +
      UseFor +
      "&FacilityName=" +
      currFacilityName +
      
      "&TimeStamp=" +
      Date.now()
  );
}else{
  let finalUrl =
  EXCEL_EXPORT_URL +
  "report/digitalicr_excel_global.php";

  window.open(
    finalUrl +
      "?menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&StartDate=" +
      StartDate +
      "&EndDate=" +
      EndDate +
      "&UserName=" +
      UserName +
      "&FacilityId=" +
      currFacilityCode +
      "&GroupCode=" +
      currGroupCode +
      "&ItemCode=" +
      currItemCode +
      "&UseFor=" +
      UseFor +
      "&FacilityName=" +
      currFacilityName +
      
      "&TimeStamp=" +
      Date.now()
  );
}
   
};



  /* =====End of Excel Export Code==== */


  const columns = [
    {
      title: "Id",
      field: "id",
      visible: false,
    },
  
    {
      title: DispensingLanguage[lan][menukey]["Date"],
      field: "StockDate",
      headerFilter: false,
      width: "95",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return (value==null?'':moment(value).format("DD/MM/YYYY"));
      }
      

    },
   
    {//create column group
      title:"RECEIPTS",
      hozAlign: "center",
      headerHozAlign: "center",
      columns:[
            {
        title: DispensingLanguage[lan][menukey]["IIN/IV Number"],
        field: "InvoiceNo",
        headerFilter: false,
        width: "90",
      
      },
      {
        title: DispensingLanguage[lan][menukey]["Received From"],
        field: "Supplier",
        headerFilter: false,
        width: "150",
        

      },
      
      {
        title: DispensingLanguage[lan][menukey]["Quantity Received"],
        field: "QtyIn",
        hozAlign: "right",
        headerHozAlign: "right",
        headerFilter: false,
        width: "100",
        formatter: function (cell, formatterParams) {
          let value = cell.getValue();
  
          return value == 0 ? "" : Number(value).toLocaleString();
        },
      },
      {
        title: DispensingLanguage[lan][menukey]["Mfg/Exp Date"],
        field: "Mfgexpdate",
        headerFilter: false,
        width: "180",

      },    


      ],
  },

    {//create ISSUES column group
      title:"ISSUES",
      hozAlign: "center",
      headerHozAlign: "center",
          columns:[
                 {
            title: DispensingLanguage[lan][menukey]["IIN/IV Number"],
            field: "IssueInvoiceNo",
            headerFilter: false,
            width: "87",
  
          },
          {
            title: DispensingLanguage[lan][menukey]["Issued to"],
            field: "Receiver",
            hozAlign: "left",
            headerHozAlign: "left",
            headerFilter: false,
            //width: "200",
          
  
          },
  
          {
            title: DispensingLanguage[lan][menukey]["Quantity Issued"],
            field: "QtyOut",
            hozAlign: "right",
            headerHozAlign: "right",
            headerFilter: false,
            width: "93",
            formatter: function (cell, formatterParams) {
              let value = cell.getValue();
      
              return value == 0 ? "" : Number(value).toLocaleString();
            },
  
          },   
    
    
          ],
      },



    {//create QUANTITY column group
      title:"QUANTITY",
      hozAlign: "center",
      headerHozAlign: "center",
          columns:[
                  
        {
          title: DispensingLanguage[lan][menukey]["Transfered/Returned/Destroyed"],
          field: "AdjQty",
          headerFilter: false,
          width: "110",
          hozAlign: "right",
          headerHozAlign: "right",
          formatter: function (cell, formatterParams) {
            let value = cell.getValue();
    
            return value == 0 ? "" : Number(value).toLocaleString();
          },

        },

          ],
      },


    {//create BALANCE ON HAND column group
      title:"BALANCE ON HAND",
      hozAlign: "center",
      headerHozAlign: "center",
          columns:[
              
          {
            title: DispensingLanguage[lan][menukey]["Usable"],
            field: "UsableBalance",
            headerFilter: false,
            width: "90",
            hozAlign: "right",
            headerHozAlign: "right",
            formatter: function (cell, formatterParams) {
              let value = cell.getValue();
      
              return value == 0 ? "" : Number(value).toLocaleString();
            },

        },
        {
          title: DispensingLanguage[lan][menukey]["Not Usable"],
          field: "UnusableBalance",
          headerFilter: false,
          width: "85",
          hozAlign: "right",
          headerHozAlign: "right",
          formatter: function (cell, formatterParams) {
            let value = cell.getValue();
    
            return value == 0 ? "" : Number(value).toLocaleString();
          },

        },

          ],
      },

    
      {
        title: DispensingLanguage[lan][menukey]["Remarks"],
        field: "InvType",
        headerFilter: false,
        width: "110",
        formatter:"textariea",
      },

   
  ];


  const checkLogin = () => {
    let token = Constants.token;

    if (!token) {
      swal("Oops!", `token expired. Please login again`, "error");
      props.history.push("/login");
    }
  };


  React.useEffect(() => {
    //setcurrItemCode(ItemList[0].id); 
    cItemList();
  }, [currGroupCode]);


  const prevDeps = React.useRef({
    currFacilityCode,
    selectedStartDate,
    selectedEndDate,
    currGroupCode,
    currItemCode,
  });

React.useEffect(() => {
  const hasDepChanged =
    prevDeps.current.currFacilityCode !== currFacilityCode ||
    prevDeps.current.selectedStartDate !== selectedStartDate ||
    prevDeps.current.selectedEndDate !== selectedEndDate ||
    prevDeps.current.currGroupCode !== currGroupCode ||
    prevDeps.current.currItemCode !== currItemCode;

  if (tblRef == null) return;

  if (hasDepChanged) {
    stockData();
    prevDeps.current = {
      currFacilityCode,
      selectedStartDate,
      selectedEndDate,
      currGroupCode,
      currItemCode,
    };
  }
}, [currFacilityCode, selectedStartDate, selectedEndDate, currGroupCode, currItemCode]);


/*  React.useEffect(() => {
   if (tblRef == null) return;
   setLoading(true);
   stockData();
  }, [selectedStartDate, selectedEndDate, currGroupCode,currItemCode]); */

  return (
    <>
      <div className={classes.productPageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Digital ICR"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>

            <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                           
                            disableClearable
                            id="FacilityCode"
                            options={FacilityList}
                            onChange={(event, valueobj) => handleChangeChoosenFacility('FacilityCode', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                           
                            defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityCode)]}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Facility"]}
                              variant="standard"
                              id="FacilityCode"
                              name="FacilityCode"
                              fullWidth
                            />
                            )}
                          />

                    </FormControl>
                  </Grid>
              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      //disableToolbar
                      autoOk={true}
                      // disabled={true}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["Start Date"]}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["End Date"]}
                      autoOk={true}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>


              <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  {/* <InputLabel id="">
                    {DispensingLanguage[lan][menukey]["Group"]} 
                  </InputLabel>
                  <Select
                 
                    labelId="GroupCode"
                    id="GroupCode"
                    name="GroupCode"
                 
                    value={currGroupCode}
                    fullWidth
                    onChange={handleChange}
                    disabled={
                      manyTableData.length > 0 ? true : props.bStockUpdate
                    }
                  >
                   
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                    {GroupNameList.map((item, index) => {
                      return (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      );
                    })}
                  </Select> */}

                        <Autocomplete
                          autoHighlight
                          disabled={
                            manyTableData.length > 0 ? true : props.bStockUpdate
                          }
                          disableClearable
                          id="GroupCode"
                          options={GroupNameList}
                          onChange={(event, valueobj) => handleChangeChoosen('GroupCode', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValues['GroupCode']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            //value={props.formData.GroupCode}
                            label={DispensingLanguage[lan][menukey]["Group"]}
                            variant="standard"
                            id="GroupCode"
                            name="GroupCode"
                            fullWidth
                          />
                          )}
                        />

                </FormControl>
                  </Grid>

                   <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                     {/*  <InputLabel id="">
                        {DispensingLanguage[lan][menukey]["Item"]} 
                      </InputLabel>
                      <Select
                       // error={props.errorObject.ItemCode}
                        labelId="ItemCode"
                        id="ItemCode"
                        name="ItemCode"
                        value={currItemCode}
                        fullWidth
                        onChange={handleChangeItemCode}
                        disabled={
                          manyTableData.length > 0 ? true : props.bStockUpdate
                        }
                      >
                        
                        <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                        {ItemList.map((item, index) => {
                          return (
                           <MenuItem value={item.id}> {item.name}</MenuItem>
                          );
                        })}
                      </Select> */}

                          <Autocomplete
                            autoHighlight
                            disabled={
                              manyTableData.length > 0 ? true : props.bStockUpdate
                            }
                            disableClearable
                            id="ItemCode"
                            options={ItemList}
                            onChange={(event, valueobj) => handleChangeChoosenItem('ItemCode', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValuesItem['ItemCode']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Item"]}
                              variant="standard"
                              id="ItemCode"
                              name="ItemCode"
                              fullWidth
                            />
                            )}
                          />

                    </FormControl>
                  </Grid> 

                 

              <Grid item xs={1} sm={1}>
                <div className="float-right sw_btn_control">

                <ExportButtons PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}  {...props}></ExportButtons>
                {/* <Tooltip title="Print"> 
                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintMany()}
                  >
                    <i class="fas fa-print"></i>
                  </Button>
                 </Tooltip>
                 <Tooltip title="Excel"> 
                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => ExcelMany()}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>
                  </Tooltip> */}
                </div>
              </Grid>


            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}

          <div className="uniqueName">
            <ReactTabulator
              ref={(r) => (tblRef = r)}
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="400px"
            
              options={{
                  groupBy: ["GroupName", "ItemName"],
                  columnCalcs: "both",
                }}
            
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DigitalICRGlobal;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
