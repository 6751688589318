import React, { useState,useCallback, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as api from "../../../actions/api";
import {
  FormControlLabel,  
  Radio,
  FormLabel,
  RadioGroup,
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
//Services
import * as Service from "../../../services/Service.js";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import swal from "sweetalert";
import { useQuery, useMutation, useQueryClient } from "react-query";

let timeStamp = "";

const EntryofMCWCFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  open,
  ...props
}) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "entry-edit-of-mcwc";
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();


  let TempUnionList = useState([]);
  const [UnionList, setUnionListLocal] = useState(TempUnionList);


  
  //====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "UnionCode": {"id": "","name": ""}
});


React.useEffect(() => {
  
  let chosenValuesData = { ...chosenValues};
   chosenValuesData['UnionCode'] = UnionList[UnionList.findIndex(Union_List => Union_List.id == formData.UnionCode)];
   setChosenValues(chosenValuesData); 

  }, [formData.UnionCode]);

const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  setChosenValues(chosenValuesData); 
  props.handleChangeChoosenProps(name, value);
};

//====== End Choosen Dropdown===========

const [num, setNum] = useState(0);

const incrementNum = useCallback(() => {
  setNum(prev => prev + 1);
}, []);


React.useEffect(() => {
  
  incrementNum();
  if(formData.UpazilaCode && num <= 1){

    cUnionList();
  }
    
}, [formData.UpazilaCode,incrementNum]);




const cUnionList = () => {

  let UnionListParam = {action:"getUpWiseUnionList", UpazilaCode:formData.UpazilaCode, menukey:menukey}

  Service.default
      .postApi("source/combo_generic.php",UnionListParam)
      .then((res) => {
      
        if(!addProductForm){
          let chosenValuesData = { ...chosenValues};
          chosenValuesData['UnionCode'] = res.datalist[res.datalist.findIndex(fpacily_Lists2 => fpacily_Lists2.id == formData.UnionCode)];
          setChosenValues(chosenValuesData);  

      }else{
          setChosenValues({
          "UnionCode": {"id": "0","name": ""}
          }); 
          formData["UnionCode"] = "";
        } 

      
        setUnionListLocal(res.datalist); 
      })
      .catch((err) => {});

}

  
const backtolist=()=>{


  let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
  let idx = master_dirty_message_class.indexOf("dnone");
  if(idx === -1){
    // start Alert
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then((willAction) => {
      if (willAction) {
        props.history.push("/entry-edit-of-mcwc");
      }
    });
    // End Alert
  }else{
    props.history.push("/entry-edit-of-mcwc");
  }

}


const onSaveUpdateBtnClick = () => {

  setUpdatedDetailsGridDataHandler();
};


const setUpdatedDetailsGridDataHandler = () => {
  let validForm = props.checkValidateForm();
 
  // console.log('isval: ', validForm);
    if (validForm) {

      if(addProductForm){
        //when save
        mutate(validForm);
        
      }else{
        //when update
        mutateUpdate(validForm); 
      }
      

      
    }
};

const { isLoading : isLoadingSave, mutate } = useMutation(api.saveMCWCEntry, {
  onSuccess: (data) => {
   // console.log("data: ", data);

    if (data.status == 200) {
      //console.log("data: ", data.data);
      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });
      queryClient.getQueriesData("EntryofMCWC"); //api page name
      props.history.push("/entry-edit-of-mcwc"); //
    } else {
      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });
      queryClient.getQueriesData("EntryofMCWC"); //api page name
      props.history.push("/entry-edit-of-mcwc"); //
    }
  },
});

const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.updateMCWC, {
  onSuccess: (data) => { 
    if (data.status == 200) {
      // api.getAllDropdown("ABC_List").then((response) => {
      //   localStorage.setItem(
      //     "ABC_List",
      //     JSON.stringify(response.datalist.ABC_List)
      //   );
      // });

      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });

      queryClient.getQueriesData("EntryofMCWC"); // include the page name
      props.history.push("/entry-edit-of-mcwc"); //page url
      api.dirtyModeDisable();
    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
    }
  },
});



return (
  <div className={classes.productPageTitle}>
    <div className="sw_makeStyles_tableContainer">
      <div className="d-flex justify-product mb-3">
        <Grid item xs={12} sm={12}>
          <div className="sw_page_heading">
            <div className="sw_heading_title">
              {DispensingLanguage[lan][menukey]["Entry Edit of MCWC - Add/Edit"]}
            </div>
            </div>
            <div className="float-right sw_btn_control">
              {addProductForm ? (
                <Grid item xs={12} className="text-center">
                  <div className="float-right sw_btn_control">
                    <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => handleReset()}
                    >
                      {DispensingLanguage[lan][menukey]["Reset"]}
                    </Button>
                    <Button
                      disabled={props.bStockUpdate || isLoadingSave}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                      // onClick={() => handleSubmit()}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={() => props.history.push("/menu-entry")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Cancel"]}
                    </Button>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} className="mt-2 text-center">
                  <Button
                      disabled={props.bStockUpdate || isLoadingUpdate}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClick}
                    // onClick={() => handleUpdate()}
                  >
                    {DispensingLanguage[lan][menukey]["Update"]}
                  </Button>

                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    //onClick={() => props.history.push("/menu-entry")}
                    onClick={() => backtolist()}
                  >
                    {DispensingLanguage[lan][menukey]["Cancel"]}
                  </Button>
                </Grid>
              )}
            </div>
          
             <div className="mr-4 float-right mt-15">
              <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
            </div>
        </Grid>
      </div>

      {/* New row */}
      <Grid container spacing={3}>
        {/* New row */}
        <Grid item xs={12} sm={12}>
          <Card className="sw_card">
            <CardContent>
                <Grid container spacing={10}>
                <Grid item xs={4} sm={12}>
                  <Grid container spacing={3}>

                  <Grid item xs={4} sm={4}>
                    <FormControl className={classes.fullWidth}>
                       
                        <Autocomplete
                          autoHighlight
                            //disableClearable
                          disabled={!addProductForm ?true:false}
                          id="UnionCode"
                          options={UnionList}
                          onChange={(event, valueobj) => handleChangeChoosen('UnionCode', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValues['UnionCode']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            //value={formData.UnionCode}
                            label={DispensingLanguage[lan][menukey]["Union"]+" *"}
                            variant="standard"
                            error={errorObject.UnionCode}
                            helperText={errorObject.UnionCode}
                            id="UnionCode"
                            name="UnionCode"
                            fullWidth
                          />
                          )}
                        />

                      </FormControl>
                      
                    </Grid>


                    <Grid item xs={4} sm={4}>
                      <TextField
                        error={errorObject.upazila_name}
                        helperText={errorObject.upazila_name}
                        required
                        disabled={props.permissionType}
                        id="upazila_name"
                        name="upazila_name"
                        label={
                          DispensingLanguage[lan][menukey]["WCMC Name"]
                        }
                        value={formData.upazila_name}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                   

                   
                    <Grid item xs={6} sm={4}>
                      <TextField
                        error={errorObject.LandPhoneNo}
                        helperText={errorObject.LandPhoneNo}
                        disabled={props.permissionType}
                        id="LandPhoneNo"
                        name="LandPhoneNo"
                        label={DispensingLanguage[lan][menukey]["Phone No"]}
                        value={formData.LandPhoneNo}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                        inputProps={{
                          maxLength: 11,
                        }}
                      />
                    </Grid>
                 
                    <Grid item xs={6} sm={4}>
                      <TextField
                        error={errorObject.up_address}
                        helperText={errorObject.up_address}
                        disabled={props.permissionType}
                        id="up_address"
                        name="up_address"
                        label={DispensingLanguage[lan][menukey]["Address"]}
                        value={formData.up_address}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>

                    <Grid item xs={4} sm={4}>
                      <TextField
                        error={errorObject.Latitude}
                        helperText={errorObject.Latitude}
                        disabled={props.permissionType}
                        id="Latitude"
                        name="Latitude"
                        label={DispensingLanguage[lan][menukey]["Latitude"]}
                        value={formData.Latitude}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                        /* inputProps={{
                          maxLength: 11,
                        }} */
                      />
                    </Grid>

                    <Grid item xs={4} sm={4}>
                      <TextField
                        error={errorObject.Longitude}
                        helperText={errorObject.Longitude}
                        disabled={props.permissionType}
                        id="Longitude"
                        name="Longitude"
                        label={DispensingLanguage[lan][menukey]["Longitude"]}
                        value={formData.Longitude}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                        /* inputProps={{
                          maxLength: 11,
                        }} */
                      />
                    </Grid>

                    
                    <Grid item xs={6} sm={4}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        //disabled={props.bStockUpdate}
                       // required
                        error={errorObject.SoftwareStartDate}
                        helperText={errorObject.SoftwareStartDate}
                        className={classes.fullWidth}
                        //disableToolbar
                        disabled={formData.IsChangeStartDate}
                        variant="inline"
                        id="SoftwareStartDate"
                        label={DispensingLanguage[lan][menukey]["Start Date"]}
                        autoOk={true}
                        name="SoftwareStartDate"
                        fullWidth
                        showTodayButton={true}
                        disableFuture={true}
                        value={
                          formData.SoftwareStartDate || null
                        }
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "SoftwareStartDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                    

                  </Grid>
                </Grid>
              </Grid>
              {/* New Row */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  </div>
);
};

export default EntryofMCWCFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
