import React, {useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";
import {
  Grid,
  FormControl,
  Card,
  CardContent,
  Radio,
  ButtonGroup,
  Typography,
  TextField,
} from "@material-ui/core";

import { DeleteOutline, Edit } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import MomentUtils from "@date-io/moment";
import moment from "moment";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import ExportButtons from "../../../components/ExportButtons";
import Autocomplete from "@material-ui/lab/Autocomplete";

const AssetRegister = (props) => {

  const permissionType = props.permissionType;
  const FacilityId = localStorage.getItem("FacilityId");
  const menukey = "asset-register";
  const FacilityName = localStorage.getItem("FacilityName");
  const UseFor = localStorage.getItem("UseFor");
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;
  const { useState } = React;
  const [firstLoad, setFirstLoad] = useState(true);

  const classes = useStyles();
  const tableRef = useRef();
  const [dataItems, setDataItems] = useState([]);
  // console.log('get item data',dataItems);
  const [reloadGrid, setReLoadGrid] = useState(false);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const [isLoading, setLoading] = useState(true);
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem("LangCode");
// Facility filter
  const AssetStatusList = JSON.parse( localStorage.getItem("AssetStatusList"));

  AssetStatusList.unshift({MStatusId: 0,name: t(DispensingLanguage[lan][menukey]["All"]), });
  const [chosenValues, setChosenValues] = useState({
    MStatusId: { MStatusId: 0, name: t(DispensingLanguage[lan][menukey]["All"]) },
  });
  const [MStatusId, setMStatus] = useState( 0 );
  const [ProductCount, setProductCount] = useState(0);

// console.log('get id',MStatusId);

  const handleChangeChoosen = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;
    setFirstLoad(true);
    setLoading(false);
    setChosenValues(chosenValuesData);
    setMStatus(value);
  };
// End Facility Filter
  



let params = {
  action: 'getDataList',
  menukey: menukey,
  FacilityId: FacilityId,
  MStatusId: MStatusId,
  page_limit: JSON.stringify(100),
  page_number: JSON.stringify(0 + 1),
  search_key: "",
  UseFor: UseFor
};
const { error, isError, data, refetch } = useQuery(
  [params],
  api.getassetregisterList,
  {
    onSuccess: (data) => {
      // console.log(data);
      setDataItems(data);
      setProductCount(data.length);
      setLoading(false);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  }
);

  
  const { mutate:deleteApi } = useMutation(api.deleteAssetregister, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

        refetch();
        queryClient.getQueriesData("AssetRegisterServer");   
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
		
    
        }else{   
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
      
        }
    },
  });

/* =====Start of Excel Export Code==== */
const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

const PrintPDFExcelExportFunction = (reportType) => {
  let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
  window.open(
    finalUrl +
      "?action=AssetregisterExport" +
      "&reportType=" +
      reportType +
      "&MStatusId=" +
      MStatusId +
      "&FacilityId=" +
      FacilityId +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
  );
};

/* =====End of Excel Export Code==== */

const tableRowDoubleClick = (e, row) => {
  let rowData = row._row.data;
  editassetregister(rowData);
};

  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 50,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
      //frozen:true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Asset Code"],
      field: "ItemCode",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Equipment Name"],
      field: "ItemName",
      width:150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Present Status"],
      field: "MStatus",
      width:150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Asset Brand"],
      field: "BrandName",
      width:150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Asset Model"],
      field: "ModelNo",
      width:150,
      headerFilter: true,

    },
    {
      title: DispensingLanguage[lan][menukey]["Purchase Value"],
      field: "InitialPrice",
      width:150,
      hozAlign:'right',
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Life Span"],
      field: "UsefulLife",
      width:150,
      hozAlign:'right',
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Supplier"],
      field: "SupName",
      width:150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Manufacturer"],
      field: "ManufacturerName",
      width:150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Procuring Entity"],
      field: "ProcuringName",
      width:150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Country of Origin"],
      field: "CountryName",
      width:150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Department"],
      field: "DeptName",
      width:150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Unit"],
      field: "SectionName",
      width:150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Location Indication"],
      field: "AssetLocation",
      width:150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Source of Fund"],
      field: "FundingSourceName",
      width:150,
      headerFilter: true,
    },{
      title: DispensingLanguage[lan][menukey]["Product Serial No"],
      field: "MenufacturerSNo",
      width:150,
      hozAlign:'right',
      headerFilter: true,
    },{
      title: DispensingLanguage[lan][menukey]["Date of Receipt by Health Facility"],
      field: "DateRecvInStore",
      width:150,
      hozAlign:'center',
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },{
      title: DispensingLanguage[lan][menukey]["Date of Installation"],
      field: "InstallationDate",
      width:150,
      hozAlign:'center',
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },{
      title: DispensingLanguage[lan][menukey]["Date of Commissioning"],
      field: "SupplyDate",
      width:150,
      hozAlign:'center',
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    }, {
      title: DispensingLanguage[lan][menukey]["Last Date of Warranty"],
      field: "LastDateWarranty",
      width:150,
      hozAlign:'center',
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    }, {
      title: DispensingLanguage[lan][menukey]["Rate of Depreciation"],
      field: "DepRate",
      width:150,
      hozAlign:'right',
      headerFilter: true,
    }, {
      title: DispensingLanguage[lan][menukey]["Salvage Value"],
      field: "salvageval",
      width:150,
      hozAlign:'right',
      headerFilter: true,
    },{
      title: DispensingLanguage[lan][menukey]["Maintenance Schedule"],
      field: "MSchedule",
      width:150,
      headerFilter: true,
    }, {
      title: DispensingLanguage[lan][menukey]["Remarks"],
      field: "Remarks",
      width:150,
      headerFilter: false,
      headerSort:false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      headerHozAlign: "center",
      hozAlign: "center",
      width: 80,
      frozen:true,
      formatter: reactFormatter(<ActionButton />),
    },
    
  ];


  const editassetregister = (data) => {
    let route = `asset-register/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteAssetstatuslist = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Once deleted, you will not be able to recover this Record!"
        ]
      ),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willDelete) => {
      if (willDelete) {
        deleteApi({AssetDetailId:data.id,"menukey": menukey,"lan":lan,"UserName":UserName,"action": "dataDalete"});
      
      }
    });
  };

  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editassetregister(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            deleteAssetstatuslist(rowData);
          }}
        />
      </>
    );
  }

  return (
    <>
           <div className={classes.productPageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Asset Register Entry"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                  <FormControl fullWidth>
                    <Autocomplete
                      autoHighlight
                      disableClearable
                      id="MStatusId"
                      options={AssetStatusList}
                      onChange={(event, valueobj) =>
                        handleChangeChoosen("MStatusId",valueobj,valueobj ? valueobj.MStatusId : "")
                      }
                      getOptionLabel={(option) => option.name}
                      value={chosenValues["MStatusId"]}
                      renderOption={(option) => (<Typography className="sw_fontSize">{option.name}</Typography>)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={
                            DispensingLanguage[lan][menukey]["Facility"]
                          }
                          variant="standard"
                          id="MStatusId"
                          name="MStatusId"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={9}>
                  <Grid container spacing={1}>
                    <Grid item xs={8} sm={8}>
                      <div className="float-right ">
                        <h4 className="FontStyle">
                          {DispensingLanguage[lan][menukey]["Total Asset"] +
                            ": " +
                            ProductCount}
                        </h4>
                      </div>
                    </Grid>

                    <Grid item xs={4} sm={4}>
                      <div className="float-right sw_btn_control">
                        <Button
                          disabled={permissionType}
                          color="info"
                          className="mr-2"
                          onClick={() => {
                            props.history.push("/asset-register/add");
                          }}>
                          {DispensingLanguage[lan][menukey]["Add Asset Register"]}
                        </Button>

                        <ExportButtons
                          PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}
                          {...props}></ExportButtons>

                      
                      </div>

                    </Grid>
                  </Grid>
                </Grid>

                
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {/* {isLoading && <LoadingSpinnerOpaque />} */}

          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
               height="450px"
              
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AssetRegister;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
