import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import * as api from "../../../actions/api";
import AssetstatusFormData from "./AssetstatusFormData.js";

const AddAssetstatus = (props) => {

  //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "asset-status-entry";
  const UserInfo = sessionStorage.getItem("User_info")? JSON.parse(sessionStorage.getItem("User_info")): 0;  
  const UserName = UserInfo==0?'': UserInfo[0].id;

  const [ColorCode, setColorCode] = useState("#00aabb");

  const handleChangeColorAdd = (value) => {
    //console.log(value);
    setColorCode(value);
  };
 
  const [formData, setFormData] = useState({
    MStatus: "",
    colorCode: ColorCode,
    SLNUMBER: ""
  });

  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  useQuery(
    ["assetstatusServer", id],
    () =>
      api.getAssetData(id).then((res) => {
        setFormData(res.data);
      }),
    {
      enabled: Boolean(id),
    }
  );

  const { mutate } = useMutation(api.saveassetstaus, {
    onSuccess: (data) => {
      if (data.status == 200) {

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
		

        queryClient.getQueriesData("assetstatusServer");
        props.history.push("/asset-status-entry");
       
    
        }else{
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
		
        }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    console.log("formData: ", formData);
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      MStatus: "",
      colorCode: ColorCode,
      SLNUMBER: ""
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["MStatus","colorCode","SLNUMBER"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };


  const handleSubmit = async (e) => {
    console.log("from submit ", formData);
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat);
     // mutate(formData);
     
    }
  };

  useEffect(() => {}, [ColorCode]);

  return (
    <>
      <AssetstatusFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        ColorCode={ColorCode}
        handleChangeColorAdd={handleChangeColorAdd}
        
        {...props}
      />
    </>
  );
};

export default AddAssetstatus;
