import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles"; 

import {
  Grid,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  Snackbar,
  Stack 
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";



const IndentorFormData = ({
  errorObject,
  addNationalSupplierForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  open,
  ...props
}) => {
    //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "indentor";
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();


  
  const backtolist=()=>{


    let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
    let idx = master_dirty_message_class.indexOf("dnone");
    if(idx === -1){
      // start Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          props.history.push("/indentor");
        }
      });
      // End Alert
    }else{
      props.history.push("/indentor");
    }
  
  }

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Indentor Entry Form - Add/Edit"]}
              </div>
              </div>
              <div className="float-right sw_btn_control">
                {addNationalSupplierForm ? (
                  <Grid item xs={12} className="text-center">
                    <div className="float-right sw_btn_control">
                      <Button
                        disabled={props.bStockUpdate}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button>
                      <Button
                        disabled={props.bStockUpdate}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit()}
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        //onClick={() => props.history.push("/menu-entry")}
                        onClick={() => backtolist()}
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="mt-2 text-center">
                    <Button
                        disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => handleUpdate()}
                    >
                      {DispensingLanguage[lan][menukey]["update"]}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={() => props.history.push("/menu-entry")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                  </Grid>
                )}
              </div>
            
               <div className="mr-4 float-right mt-15">
                <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
              </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardContent>
                  <Grid container spacing={10}>
                  <Grid item xs={4} sm={12}>
                    <Grid container spacing={3}>

                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.IndentorName}
                          helperText={errorObject.IndentorName}
                          required
                          disabled={props.permissionType}
                          id="IndentorName"
                          name="IndentorName"
                          label= {DispensingLanguage[lan][menukey]['Indentor Name'] }
                          value={formData.IndentorName}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid> 

                      <Grid item xs={8} sm={8}>
                        <TextField
                          error={errorObject.OPDescription}
                          helperText={errorObject.OPDescription}
                          id="OPDescription"
                          name="OPDescription"
                          disabled={props.permissionType}
                          label= {DispensingLanguage[lan][menukey]['Description'] }
                          value={formData.OPDescription}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid> 
                      
                      
                      
                    </Grid>
                  </Grid>
                </Grid>

                {/* New Row */}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default IndentorFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
