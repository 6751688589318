import React, { useState ,useEffect } from "react";//, useEffect
import {
  Typography
} from "@material-ui/core";

import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query'
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import * as api from '../../../actions/api';
import DispenserFormData from './GatePassItems.js';

import Constants from "../../../services/Constants";
import moment from "moment";

const AddGatePass = (props) => {

  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const UserInfo = sessionStorage.getItem("User_info")? JSON.parse(sessionStorage.getItem("User_info")): 0;
const UserName = UserInfo == 0 ? "" : UserInfo[0].id;
const lan = localStorage.getItem('LangCode');
const menukey = "gate-pass";
const FacilityId=localStorage.getItem('FacilityId');
const CurrentFacilitySetting=JSON.parse(localStorage.getItem('CurrentFacilitySetting'));
  
const [selectedDate, setSelectedDate] = useState(
  //new Date()
  moment().format("YYYY-MM-DD")
);
const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState(
  ''
); 
  const [formData, setFormData] = useState({
      TransactionDate: selectedDate,
      //SupplierInvDate: selectedDate,
      DriverName: "",
      TransactionNo: generatedInvoiceNo, 
      "FacilityId":FacilityId,
      TransactionTypeId:0,
      ApproveBy:CurrentFacilitySetting[0].InvAppBy,
      OrderNo:"",
      PreparedBy:CurrentFacilitySetting[0].InvPrepBy,
      DeliveredBy: "",
      VehicleNo:"",
      BCancel:0,
      PreparedByName:"",
      ApproveByName:"",
      PrepBybActive:"",
      ApproveBybActive:"",
  });

  const [ManyTableData, setManyTableData] = useState( []); 
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);

   
  
const handleRDateChange = (date, field) => {

let newDate;
 if(date==null){
  newDate=date;
 }else{
   newDate = moment(date).format("YYYY-MM-DD");
 }
  setFormData({ ...formData, [field]: newDate });
  setErrorObject({ ...errorObject, [field]: null });
  api.dirtyModeEnable();
};

 const handleDateChange = (date) => {
  setSelectedDate(date);
}; 
 

  const [errorObject, setErrorObject] = useState({});
   
  const { id } = useParams();
  const queryClient = useQueryClient();

  let params={"menukey": "gate-pass", "FacilityId":FacilityId, "TransactionTypeId":0, TransactionDate: '', TableName: 'gatepass'};
  const {  data:invoiceNo, refetch } = useQuery([params], api.getInvoiceNo, 
    {onSuccess: (data) => {
      
      setGeneratedInvoiceNo(data);
      let fdata = { ...formData }; 
      fdata["TransactionNo"] = data; 
      setFormData(fdata); 

    }}
    );
  
 

  // const { isLoading, mutate } = useMutation(api.saveGatePassInv, {
  //   onSuccess: (data) => { 
  //     if (data.status == 200) {

  //     props.openNoticeModal({
  //       isOpen: true,
  //       msg: data.message,
  //       msgtype: data.success,
  //     });

  //     let route = `edit/${data.TransactionId}`;
  //     props.history.push(route); 
      
  //     }else{
  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.message,
  //         msgtype: data.success,
  //       });
  //     }
  //   }
  // })  
  const handleAdd = (NonOrderProductData) => {
  
      setManyTableData(NonOrderProductData);
      setUpdatedDetailGridData(NonOrderProductData);
      

  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('formData: ', e.target);

      let data = { ...formData };
      
      data[name] = value; 
      setFormData(data); 
    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };

    //====== Start Choosen Dropdown===========
    const handleChangeChoosenProps = (name, value) => {
      let data = { ...formData };
      data[name] = value;   
      setFormData(data); 
      setErrorObject({ ...errorObject, [name]: "" });
      api.dirtyModeEnable();
    }
    //====== End Choosen Dropdown===========

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };


  const invoiceCancel = (e)=>{

    // // console.log('handleCheck e: ', e);
    // const { name, checked } = e.target;
    // // console.log('name, checked: ', name, checked);
    // // console.log('invoiceCancel formData: ', formData);

    // swal({
    //   title: DispensingLanguage[lan][menukey]["Are you sure?"],
    //   text: DispensingLanguage[lan][menukey]["Do you really want to cancel?"]+" "+DispensingLanguage[lan][menukey]["You will not able to edit this invoice after cancel"],
    //   icon: "warning",
    //   buttons: [DispensingLanguage[lan][menukey]["No"], 
    //   DispensingLanguage[lan][menukey]["Yes"]],
    //   dangerMode: true,
    // }).then((willCancel) => {
    //   if (willCancel) {
    //     let fDtat={...formData,'ManyJsonSave':ManyTableData,"BCancel":1,"menukey":"receive"};
    //     mutate(fDtat);
    //   }else{
    //     setFormData({ ...formData, [name]: false });
    //   }
    // });

  };


  const handleReset = () => {
    setFormData({
      TransactionDate: selectedDate,
      //SupplierInvDate: selectedDate,
      DriverName: "",
      TransactionNo:generatedInvoiceNo,
      "FacilityId":FacilityId,
      TransactionTypeId:0, 
      ApproveBy:CurrentFacilitySetting[0].InvAppBy,
      OrderNo:"",
      PreparedBy:CurrentFacilitySetting[0].InvPrepBy,
      DeliveredBy: "",
      VehicleNo:"",
      BCancel:0,
      PreparedByName:"",
      ApproveByName:"",
      PrepBybActive:"",
      ApproveBybActive:"",
      ManyJsonSave: []
    });
    setManyTableData([]);
  };

  const validateForm = (formData) => {
    let validateFields = ["TransactionDate", "TransactionNo"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !']
        isValid = false
      }
    })
    setErrorObject(errorData);
    return isValid
  }

  // const handleSubmit = async (e) => {
  //   console.log("from submit ", 
    
  //   );
  //   if (validateForm(formData)) {
  //     let fDtat={...formData,'ManyJsonSave':ManyTableData,UserName: UserName, lan: lan,"menukey":"gate-pass"};
  //     mutate(fDtat);
      
  //   }
  // };
  const deleteGatePassInv=(data)=>{
    
    let newarray = ManyTableData.filter(element => element !== data); 
    setManyTableData(newarray);
     
    

  }

  // const setUpdatedDetailsGridDataHandler = (rows) => { 
  //   if (validateForm(formData)) {
  //     let fDtat={...formData,'ManyJsonSave':rows,"menukey":"gate-pass",UserName: UserName, lan: lan}; 
  //     mutate(fDtat); 
  //     }

  //   setManyTableData(rows); 
  // };


  
  function checkValidateForm(rows, callfrom){

    if(callfrom == "edit"){
      setManyTableData(rows); 
    }

    // return validateForm(formData);
    if (validateForm(formData)) {
        let fDtat={...formData,'ManyJsonSave':rows, menukey: menukey,'deletedDataSet':[]}; 
        //mutateUpdate(fDtat); 
        return fDtat;
      }else{
        return false;
      }
  };

  useEffect(() => { 
    
  }, []); 




  return (
    <> 
      <DispenserFormData 
      errorObject={errorObject} 
      addProductForm={true} 
      formData={formData} 
      handleChange={handleChange} 
      handleChangeChoosenProps={handleChangeChoosenProps}
      handleCheck={handleCheck} 
      handleReset={handleReset} 
      // invoiceCancel={invoiceCancel} 
      // handleSubmit={handleSubmit} 
      ManyTableData={updatedDetailGridData}   
      handleRDateChange={handleRDateChange} 
      selectedDate={selectedDate} 
      deleteGatePassInv={deleteGatePassInv}
      // setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
      bStockUpdate={props.permissionType}
      handleAdd={handleAdd} 
      generatedInvoiceNo={generatedInvoiceNo}
      checkValidateForm={checkValidateForm}
      {...props} />
      
      
    </>

    
  );
};

export default AddGatePass;