import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Grid,Checkbox } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

import ExportButtons from "../../../components/ExportButtons";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";


const Role = (props) => {
  const permissionType = props.permissionType;
    //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "role-entry";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo==0?'': UserInfo[0].id;

  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const { isLoading, error, isError, data, refetch } = useQuery(
    'role',
    api.getRole,
    {
      onSuccess: (data) => {
        setDataItems(data); 
      } 
    }
    );
  
  const { mutate:deleteApi } = useMutation(api.deleteRole, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        }); 
        refetch();
        queryClient.getQueriesData("roles");
       // swal(data.data.message, "", "success");
        
      }else{   
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
        }
    },
  });

/* =====Start of Excel Export Code==== */
const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

const PrintPDFExcelExportFunction = (reportType) => {
  let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

  window.open(
    finalUrl +
      "?action=RoleExport" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
  );
};
/* =====End of Excel Export Code==== */

function RowInputData(props, type = null) {
  const rowData = props.cell._cell.row.data;
  let fieldName = props.field;
  let fieldType = props.fieldType;
  // let labelName = props.labelName;
  // let dropDownKey = props.dropDownKey;

  switch (fieldType) {
    case "check-box":
      return rowData.type == "bCatalog" ? (
        <></>
      ) : (
        <Checkbox
          color="primary"
          checked={rowData[fieldName]}
          name={fieldName}
          value={rowData[fieldName]}
        />
      );
      break;

    default:
      return <></>;
      break;
  }
  return <></>;
}


function RowInputData1(props, type = null) {
  const rowData = props.cell._cell.row.data;
  let fieldName = props.field;
  let fieldType = props.fieldType;
  // let labelName = props.labelName;
  // let dropDownKey = props.dropDownKey;

  switch (fieldType) {
    case "check-box":
      return rowData.type == "bFacilitySelected" ? (
        <></>
      ) : (
        <Checkbox
          color="primary"
          checked={rowData[fieldName]}
          name={fieldName}
          value={rowData[fieldName]}
        />
      );
      break;

    default:
      return <></>;
      break;
  }
  return <></>;
}

const tableRowDoubleClick = (e, row) => {
  let rowData = row._row.data;
  editRole(rowData);
};

  const columns = [
    { title: "Id", field: "id", width: 50, visible: false },
    { title: DispensingLanguage[lan][menukey]['Sl#.'] , formatter: "rownum", width: 80, hozAlign: "center",headerSort:false, headerHozAlign:"center"},
    { title: DispensingLanguage[lan][menukey]['Role Name'] , width: 300,field: "role"},
    { title: DispensingLanguage[lan][menukey]['Default Redirect'] , field: "defaultredirect"},
    {
      title: DispensingLanguage[lan][menukey]["Is Active"],
      field: "bFacilitySelected",
      hozAlign: "center",
      headerHozAlign: "center",
      width: 80,
      headerSort: false,
      formatter: reactFormatter(
        <RowInputData1
          labelName="bFacilitySelected"
          field="bFacilitySelected"
          fieldType="check-box"
        />
      ),
    },
    {
      title: DispensingLanguage[lan][menukey]["Is Catalog"],
      field: "bCatalog",
      hozAlign: "center",
      headerHozAlign: "center",
      width: 80,
      headerSort: false,
      formatter: reactFormatter(
        <RowInputData
          labelName="bCatalog"
          field="bCatalog"
          fieldType="check-box"
        />
      ),
    },
    {
      title: DispensingLanguage[lan][menukey]['Actions'],
      field: "custom",
      hozAlign: "center",
      width: 133,
      headerSort:false,
      headerHozAlign:"center",
      formatter: reactFormatter(<ActionButton />),
    },
  ];



  const editRole = (data) => {
    let route = `role-entry/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteRole = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]['Are you sure?']),
      text: t(DispensingLanguage[lan][menukey]['Once deleted, you will not be able to recover this Record!']),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willDelete) => {
      if (willDelete) {
        deleteApi({id:data.id,"menukey": menukey,"lan":lan,"UserName":UserName,"action": "dataDalete"});
      }
    });
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    // const cellValue = props.cell._cell.value || "Edit | Show";
    if (permissionType) return <></>;
    else if(rowData.id==1)
  return (
    <>
      <Edit
        onClick={() => {
          editRole(rowData);
        }}
      />
       
    </>
  );
  else
  return (
    <>
      <Edit
        onClick={() => {
          editRole(rowData);
        }}
      />
      <DeleteOutline
        onClick={() => {
         
          deleteRole(rowData);
        }}
      />
    </>
  );
}





















  return (
    <>
      <div className={classes.dispenserPageTitle}>
        <div className="d-flex justify-dispenser mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">{DispensingLanguage[lan][menukey]['Role Entry']}</div>
              <div className="float-right sw_btn_control">
                <Button
                    disabled={permissionType}
                  color="info"
                  className="mr-2"
                  onClick={() => {
                    props.history.push("/role-entry/add");
                  }}
                >
                  {DispensingLanguage[lan][menukey]['Add Role']} 
                </Button>

                <ExportButtons PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}  {...props}></ExportButtons>
                {/* <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("print")}
                >
                  <i class="fas fa-print"></i>
                </Button>

                <Button
                  color="info"
                  className="mr-2 sw_icons"
                  onClick={() => PrintPDFExcelExportFunction("excel")}
                >
                  <i className="far fa-file-excel"></i>
                </Button> */}
              </div>
            </div>
          </Grid>
        </div>

        <div>
          <div className="uniqueName">
            <ReactTabulator columns={columns} data={dataItems}
            layout={"fitColumns"}
            rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Role;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
