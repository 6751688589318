import React, { useState, useEffect } from "react";
import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query'
import { useParams } from 'react-router-dom';
import * as api from "../../../actions/api";
import UnionEntryFormData from "./UnionEntryFormData";

const AddUnionEntry = (props) => {
//console.log(props.match.params.id);
  //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "entry-edit-of-products";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo==0?'': UserInfo[0].id;
  const FacilityId = localStorage.getItem('FacilityId');
  const group = props.match.params.id;
 //const [showgroup, setGroupCode] = useState(group);
//console.log('show group code22222',showgroup);

//const [ItemCode, setItemCode] = useState('');
//console.log('getCode',getItemCode);

  const [formData, setFormData] = useState({

    UpazilaCode:props.match.params.id, //Get the url send id
    //UpazilaCode:"",
    UnionName:"",
    bActive:"",
  });

  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();


  // let params={
  //   "menukey": "entry-edit-of-products",
  //   GroupCode: showgroup,
  //    action: "getitemCode"
  // };
  // const {  data:gFacilityCode, refetch } = useQuery([params], api.getItemListCode, 
  //   {onSuccess: (data) => {
      
  //     setItemCode(data);
  //     let fdata = { ...formData }; 
  //     fdata["ItemCode"] = data; 
  //     setFormData(fdata); 

  //   }}
  //   );



  // const { isLoading, mutate } = useMutation(api.saveUnionList, {
  //   onSuccess: (data) => {
  //    // console.log("data: ", data);
  //     if (data.status == 200) {
  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.message,
  //         msgtype: data.success,
  //       });
  //       queryClient.getQueriesData("unionserverentry"); //api page name
  //       props.history.push("/union-entry-form"); //
  //     } else {
  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.message,
  //         msgtype: data.success,
  //       });
  //       queryClient.getQueriesData("unionserverentry"); //api page name
  //       props.history.push("/union-entry-form"); //
  //     }
  //   },
  // });


  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    console.log("formData: ", formData);
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();

  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
    api.dirtyModeEnable();
  };

  const handleReset = () => {
    setFormData({
      UpazilaCode:props.match.params.id, //Get the url send id
      UnionName:"",
      bActive:"",
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["UnionName"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = (DispensingLanguage[lan][menukey]['field is Required !']);
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  function checkValidateForm(){

     
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName,};
   
    return fDtat;
    }else{
    return false;
    }

};

  // const handleSubmit = async (e) => {
  //   console.log("from submit ", formData);
  //   if (validateForm(formData)) {
  //     let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName,}; 
  //    // alert("hello");
  //     mutate(fDtat);
  //    // swal("Success!", "", "success");
  //   }
  // };

  return (
    <>
      <UnionEntryFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        checkValidateForm={checkValidateForm}
        // handleSubmit={handleSubmit}
        bStockUpdate={props.permissionType}
        {...props}
      />
    </>
  );
};

export default AddUnionEntry;

