import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import swal from "sweetalert";
import * as api from "../../../actions/api";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useQuery, useMutation, useQueryClient } from "react-query";
import { DeleteOutline, Edit, ViewList, FileCopy } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import ExportButtonsMany from "../../../components/ExportButtonsMany";
//react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { useTranslation } from "react-i18next";
import ProductsModal from "../../../services/ProvisionalReceiveItemsModal";
import SourceModal from "../../../services/SourceModal";
import ContractModalByYear from "../../../services/ContractModalByYear";
import { cellFocusEditor, dateEditor, dateEditorAllowPast, dateEditorAllowFuture } from "../../../services/Common";
import moment from "moment";
let timeStamp = "";


const MasterManyFormData = (props) => {
  const permissionType = props.permissionType;
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "provisional-receive";
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  let ReceiveDetailsRef = null;

  const [newBatchRowAdded, setNewBatchRowAdded] = useState(false);

  const [open, setOpen] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);

  const [SoureOpen, setSoureOpen] = useState(false);

  const [openContract, setContractOpen] = useState(false);

  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);
  const SupplierList = JSON.parse(localStorage.getItem("SupplierListNational"));
  const FacilityEmployee = JSON.parse(localStorage.getItem("FacilityEmployee"));
  const IndentorList = JSON.parse(localStorage.getItem("IndentorList"));
  const PackageYearList = JSON.parse(localStorage.getItem("PackageYearList"));
  
  const [currRowId, setCurrRowId] = useState(0);
  const [currFormulation, setCurrFormulation] = useState("");
  const [bIsDisable, setbIsEnable] = useState(false);

  const [currYearId, setcurrYearId] = useState("");
  const [currContractNo, setcurrContractNo] = useState("");


  
//====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "SupCode": {"SupCode": "","SupName": ""},
  "PreparedBy": {"EmpCode": "","EmpName": ""},
  "IndentorId": {"IndentorId": "","IndentorName": ""}
});

const [chosenValuesYear, setChosenValuesYear] = useState({
  "YearId": {"YearId": "","PackageYear": ""}
});



useEffect(() => {
  let chosenValuesDataYear = { ...chosenValuesYear};

  chosenValuesDataYear['YearId'] = PackageYearList[PackageYearList.findIndex(PackageYear_List => PackageYear_List.YearId == props.formData.YearId)]; 
  setChosenValuesYear(chosenValuesDataYear); 

}, [props.formData.YearId]);


useEffect(() => {
  let chosenValuesData = { ...chosenValues};
 
   chosenValuesData['SupCode'] = SupplierList[SupplierList.findIndex(Supplier_List => Supplier_List.SupCode == props.formData.SupCode)]; 
   chosenValuesData['PreparedBy'] = FacilityEmployee[FacilityEmployee.findIndex(facilityEmployee_list => facilityEmployee_list.EmpCode == props.formData.PreparedBy)]; 
   chosenValuesData['IndentorId'] = IndentorList[IndentorList.findIndex(Indentor_list => Indentor_list.IndentorId == props.formData.IndentorId)]; 

  setChosenValues(chosenValuesData); 

}, [props.formData.SupCode, props.formData.PreparedBy, props.formData.IndentorId]);

const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  setChosenValues(chosenValuesData); 
  props.handleChangeChoosenProps(name, value);
};

const onYearChangeEvntChoosen = (name, valueobj, value) => {
  let chosenValuesDataYear = { ...chosenValuesYear};
  chosenValuesDataYear[name] = valueobj;   
  setChosenValuesYear(chosenValuesDataYear); 
  setcurrYearId(value);
  props.handleChangeChoosenProps(name, value);
};

//====== End Choosen Dropdown===========



  /* =====Start of Print Excel Many Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL +
      "report/provisional_receive_entry_list_print.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TransactionId=" +
        props.formData.TransactionId +
        "&FacilityId=" +
        FacilityId +
        "&TimeStamp=" +
        Date.now()
    );
  };

  const ExcelMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL +
      "report/esigl_receive_from_warehouse_entry_list_excel.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TransactionId=" +
        props.formData.TransactionId +
        "&FacilityId=" +
        FacilityId +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */
  
  const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;
      case "OrderModal":
        setOpenOrder(false);
        break;
      case "OrderOpen":
        setOpenOrder(true);
        break;

      case "SourceOpen":
        setSoureOpen(true);
        break;

      case "SourceClose":
        setSoureOpen(false);
        break;

      case "Contract":
        setContractOpen(false);
        break;
        
      case "ContractOpen":
        setContractOpen(true);
        break;

      default:
        break;
    }
  };

  const addProduct = (NonOrderProductData) => {

    let temNonordata = NonOrderProductData;
    let rows = [];

    let autoId = 0;

    manyTableData.forEach((row, i) => {

      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;

      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.UnitName = row.UnitName;
      newRow.ContractQty = row.ContractQty;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.OrderRemainingQty = row.OrderRemainingQty;
      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.Quantity = row.Quantity;
      newRow.BatchNo = row.BatchNo;
      newRow.SupRef = row.SupRef;
      newRow.SupDate = row.SupDate;
      newRow.pReceiveDate = row.pReceiveDate;
      
      newRow.MfgDate = row.MfgDate;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = row.Quantity * row.UnitPrice;

      newRow.SourceId = row.SourceId;
      newRow.SourceName = row.SourceName;

      rows.push(newRow);
    });

    temNonordata.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;

      newRow.ItemCode = row.ItemCode;

      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.UnitName = row.UnitName;
      newRow.ContractQty = row.ContractQty;

      newRow.SourceId = null;
      newRow.SourceName = null;

      newRow.TransactionItemId = null;
      newRow.OrderRemainingQty = 0;

      // manyTableData.forEach((rowssss, ij) => {
      //   if (rowssss.ItemNo == row.ItemNo) {
      //     newRow.OrderRemainingQty = rowssss.OrderRemainingQty;
      //     return;
      //   }
      // });

      newRow.CurrentStockQty = 0;
      newRow.Quantity = 0;
      newRow.BatchNo = null;
      newRow.SupRef = null;
      newRow.SupDate = null;
      newRow.pReceiveDate = null;
      newRow.MfgDate = null;
      newRow.ExpiryDate = null;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = 0;
      rows.push(newRow);
    });


    setManyTableData(rows);
    props.handleAdd(rows);
    handleClose("Products");
    api.dirtyModeEnable();
  };

  const addContract = (ContractData) => {
    props.handleAddContract(ContractData);
    handleClose("Contract");
    setcurrContractNo(ContractData[0]['ContractNo']);
    api.dirtyModeEnable();
  };

  const addSource = (productSourceData) => {
    if (!productSourceData) {
      return;
    }

    let tempProductSourceData = productSourceData[0];
    let rows = [];

    let autoId = Date.now(); // 0;

    manyTableData.forEach((row, i) => {

      autoId++;
      let newRow = {};

      if (row.SKU === currRowId) {
        newRow.SourceId = tempProductSourceData.SourceId;
        newRow.SourceName = tempProductSourceData.SourceName;
      } else {
        newRow.SourceId = row.SourceId;
        newRow.SourceName = row.SourceName;
      }

      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;

      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.OrderRemainingQty = row.OrderRemainingQty;
      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.Quantity = row.Quantity;
      newRow.BatchNo = row.BatchNo;
      newRow.SupRef = row.SupRef;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.SKU = row.SKU;
      newRow.id = row.id;
      newRow.LineTotal = row.Quantity * row.UnitPrice;

      rows.push(newRow);
    });

    setManyTableData(rows);
    props.handleAdd(rows);
    handleClose("SourceClose");
    api.dirtyModeEnable();
  };

  function ShowSourcePopup(propss: any) {
    const rowData = propss.cell._cell.row.data;

    if (props.bStockUpdate) {
      return <></>;
    } else {
      return (
        <>
          <ViewList
            onClick={() => {
              setCurrRowId(rowData.SKU);
              setCurrFormulation(rowData.SKU);
              handleClose("SourceOpen");
            }}
          />
        </>
      );
    }
  }

  
  const editCheck = function(cell){
    const rowData = cell._cell.row.data;
    return ( (rowData.DisApprovedQty == null) && (rowData.ApprovedQty == null)); // only allow the name cell to be edited if the age is over 18
  }


  const ReceiveDetailsColumns = [
    { title: "TransactionItemId", field: "TransactionItemId", visible: false },
    { title: "id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      width: 70,
      formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Sup Inv No"],
      field: "SupRef",
      width: 100,
      cssClass: "tabluator-column-editable text-field-editable",
     // editor: currApprovedQty == null ? cellFocusEditor : "",
      editor: (!permissionType)?cellFocusEditor:"",
      editable:editCheck,
      cellEdited: function (cell) {
        //const rowData = props.cell._cell.row.data;
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Sup Date"],
      field: "SupDate",
      width: 100,
      cssClass: "tabluator-column-editable",
      //editor: props.bStockUpdate == 0 ? dateEditor : "",
      //editor: (!permissionType)?dateEditor:"",
      editor: (!permissionType)?cellFocusEditor:"",
      cssClass: "tabluator-column-editable text-field-editable",
      editable:editCheck,
      
      /* cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
      }, */
    },
    {
      title: DispensingLanguage[lan][menukey]["Receive Date"],
      field: "pReceiveDate",
      width: 100,
      cssClass: "tabluator-column-editable",
      //editor: props.bStockUpdate == 0 ? dateEditor : "",
      //editor: (!permissionType)?dateEditor:"",
      editor: (!permissionType)?cellFocusEditor:"",
      cssClass: "tabluator-column-editable text-field-editable",
      editable:editCheck,
      /* cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
      }, */
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Code"],
      field: "ItemCode",
      width: 130,
      //headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Name"],
      field: "ItemName",
      minWidth: 200,
      //headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Contract Quantity"],
      field: "ContractQty",
      width: 100,
      hozAlign: "right", headerHozAlign: "right", 
      formatter: "money",
      formatterParams:{ thousand:",",  precision:false },
      // headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Provisional Qty"],
      field: "Quantity",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      validator: ["integer", "min:0"],
      cssClass: "tabluator-column-editable",
      //editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      editor: (!permissionType)?cellFocusEditor:"",
      editable:editCheck,
      cellEdited: function (cell) {
        // const curcellval = cell.getValue();
        // const currow = cell.getRow();
        // const rowdata = currow.getData();
        // const pricecell = currow.getCell("LineTotal");
        // const totprice = rowdata.UnitPrice * curcellval;
        // pricecell.setValue(totprice);
      },
      formatter: "money",
      formatterParams:{ thousand:",",  precision:false },
    },
   
    {
      title: DispensingLanguage[lan][menukey]["Unit"],
      field: "UnitName",
      width: 80,
      // headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Lot No"],
      field: "BatchNo",
      width: 150,
      cssClass: "tabluator-column-editable text-field-editable",
      //editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      editor:  (!permissionType)?cellFocusEditor:"",
      editable:editCheck,
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Mfg. Date"],
      field: "MfgDate",
      width: 100,
      //cssClass: "tabluator-column-editable",
      //editor: props.bStockUpdate == 0 ? dateEditor : "",
      //editor: (!permissionType)?dateEditorAllowPast:"",
      editor: (!permissionType)?cellFocusEditor:"",
      cssClass: "tabluator-column-editable text-field-editable",
      editable:editCheck,
      /* cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
      }, */
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry Date"],
      field: "ExpiryDate",
      width: 100,
      //cssClass: "tabluator-column-editable",
      //editor: props.bStockUpdate == 0 ? dateEditor : "",
      //editor: (!permissionType)?dateEditorAllowFuture:"",
      editor: (!permissionType)?cellFocusEditor:"",
      cssClass: "tabluator-column-editable text-field-editable",
      editable:editCheck,
      /* cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
      }, */
    },

    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      headerHozAlign: "center",
      hozAlign: "center",
      width: 80,
      formatter: reactFormatter(
        <ActionButton/>
      ),
    },
  ];



  // const onPostBtnClick = () => {
  //   setbStockUpdate(true);
  //   props.handlePost();
  // };


  const onSaveUpdateBtnClick = () => {
    // props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData());
    let manydata = ReceiveDetailsRef.table.getData();
    setUpdatedDetailsGridDataHandler(manydata);
  };

  
  const setUpdatedDetailsGridDataHandler = (rows) => {
    let validForm = props.checkValidateForm(rows,'edit');
    // console.log('isval: ', validForm);
      if (validForm) {

        // console.log('props.addProductForm: ', props.addProductForm);
        if(props.addProductForm){
          //when save
          mutate(validForm);
        }else{
          //when update
          mutateUpdate(validForm); 
        }
        

        
      }
  };
  
  
  const { isLoading: isLoadingSave, mutate } = useMutation(api.ProvisionalReceiveDataInsert, {
    onSuccess: (data) => { 
      if (data.status == 200) {

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      let route = `edit/${data.TransactionId}`;
      props.history.push(route); 
      
      }else{
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })  

  
  const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.ProvisionalReceiveDataUpdate, {
    onSuccess: (data) => {
      // console.log('mutateUpdate data: ', data);
      if (data.status == 200) { 
      //  console.log("props: ",props.formData.TransactionId);
        //setbStockUpdate(data.bStockUpdated);
        // props.successSave(data.bStockUpdated);
        // if(data.bStockUpdated==1){
        //  mutatePost(props.TransactionId);
        // }
        // else{
          // props.refetchInvoice(data.bStockUpdated); //refetch(); 
          props.refetchInvoice(); //refetch(); 
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
 
        // }

      }else{ 
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });



  

  










  const deleteReceiveInv = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["Do you really want to delete the selected item?"]),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willDelete) => {
      if (willDelete) {
        let newarray = manyTableData.filter(element => element !== data); 
        setManyTableData(newarray); 
        props.deleteReceiveInv(data); 
        api.dirtyModeEnable();
      }
    });
  };

  const CopyBatch = () => {
    props.handleAdd(manyTableData);
  };

  const onYearChangeEvnt = (e) => {
    setcurrYearId(e.target.value);
    
    props.handleChange(e);
  };



  function ActionButton(props) {

    const rowData = props.cell._cell.row.data;

    const goToDetails = () => {
      let rowobj = props.cell._cell.row.data; 

      timeStamp = Math.round(new Date().getTime() / 100);

      setManyTableData((prevRows) => {
        const tmpPrevRows = [...prevRows];

        let setRposition = tmpPrevRows.findIndex((o) => o.SKU == rowobj.SKU);

        const obj = { ...rowobj };

        obj.id = timeStamp;
        obj.TransactionItemId = "";
        obj.ExpiryDate = "";
        obj.BatchNo = "";
        obj.SupRef = "";
        obj.Quantity = 0;
        obj.SKU = Date.now();

        tmpPrevRows.splice(setRposition + 1, 0, obj);

        return tmpPrevRows;
      });

      // props.handleAdd(manyTableData);
      CopyBatch();
      setNewBatchRowAdded((prevNewBatchRowAdded) => !prevNewBatchRowAdded);
    };

    // const cellValue = props.cell._cell.value || "Edit | Show";
   
    //if (bStockUpdate == 0)
   
    if  ( (permissionType == 0) && (rowData.DisApprovedQty == null) && (rowData.ApprovedQty == null))
      return (
        <>
          {/* <FileCopy
            onClick={() => {
              if (!bStockUpdate) goToDetails(rowData);
            }}
          /> */}

          <DeleteOutline
            onClick={() => {
              
              if (!bStockUpdate) deleteReceiveInv(rowData);
            }}
          />
        </>
      );
    else return <></>;
  }

  useEffect(() => {
    if (ReceiveDetailsRef == null) return;
    if (props.bFirst) {
      setManyTableData(props.ManyTableData);

     

    }


    setbStockUpdate(props.bStockUpdate);
  }, [props.ManyTableData, props.bStockUpdate]); 

  useEffect(() => {
    if (ReceiveDetailsRef.table != null && timeStamp != "") {
      ReceiveDetailsRef.table.scrollToRow(timeStamp, "center", true);
      ReceiveDetailsRef.table.selectRow(timeStamp);
    }
  }, [newBatchRowAdded]);

  const backtolist=()=>{


    let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
    let idx = master_dirty_message_class.indexOf("dnone");
    if(idx === -1){
      // start Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          props.history.push("/provisional-receive");
        }
      });
      // End Alert
    }else{
      props.history.push("/provisional-receive");
    }
  
  }
  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Provisional Receive - Add/Edit"]}
              </div>
 
              <div className="float-right sw_btn_control">
                {props.addProductForm ? (
                  <Grid item xs={12} className=" text-center">
                    <div className="float-right sw_btn_control">
                      <Button
                      disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => props.handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button>
                      <Button
                      disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onSaveUpdateBtnClick} //() => props.handleSubmit()
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                      disabled={isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        // onClick={() => props.history.push("/provisional-receive")}
                        onClick={() => backtolist()}
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="mt-2 text-center">
                    <Button
                      disabled={props.bStockUpdate || isLoadingUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    {/* <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onPostBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["post"]}
                    </Button> */}
                    <Button
                    disabled={isLoadingUpdate}
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      // onClick={() => props.history.push("/provisional-receive")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>

                    <ExportButtonsMany PrintMany={PrintMany}  {...props}></ExportButtonsMany>
                    
                    {/* <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PrintMany()}
                    >
                      <i class="fas fa-print"></i>
                    </Button> */}

                    {/* <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelMany()}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button> */}
                  </Grid>
                )}
              </div>

                   
              <div className="mr-4 float-right mt-15">
                <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
              </div>

            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            {/* <Card className="sw_card">
            <CardHeader title="Personal Information" />
            <CardContent> */}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={true /*bStockUpdate*/}
                      error={props.errorObject.TransactionNo}
                      helperText={props.errorObject.TransactionNo}
                      required
                      id="TransactionNo"
                      name="TransactionNo"
                      label={
                        DispensingLanguage[lan][menukey]["Invoice No"]
                      }
                      value={
                        props.formData.TransactionNo || props.generatedInvoiceNo
                      }
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={2} sm={2}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        required
                        error={props.errorObject.TransactionDate}
                        helperText={props.errorObject.TransactionDate}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        id="TransactionDate"
                        label={DispensingLanguage[lan][menukey]["Entry Date"]}
                        autoOk={true}
                        name="TransactionDate"
                        fullWidth
                        showTodayButton={true}
                        disableFuture={true}
                        value={
                          props.formData.TransactionDate || null
                        }
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "TransactionDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    {/* <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Receive By"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.PreparedBy}
                        labelId="demo-simple-select-helper-label"
                        id="PreparedBy"
                        name="PreparedBy"
                        value={props.formData.PreparedBy}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={props.bStockUpdate}
                      >
                        {FacilityEmployee.map((item, index) => {
                          return (
                            <MenuItem value={item.EmpCode}>
                              {item.EmpName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={props.errorObject.PreparedBy}>
                        {props.errorObject.PreparedBy}
                      </FormHelperText>
                    </FormControl> */}

                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            hidden={!(props.formData.ReceivedBybActive || props.addProductForm)}
                            disabled={props.bStockUpdate}
                            id="PreparedBy"
                            options={FacilityEmployee}
                            onChange={(event, valueobj) => handleChangeChoosen('PreparedBy', valueobj, valueobj?valueobj.EmpCode:"")}
                            getOptionLabel={(option) => option.EmpName}
                            value={chosenValues['PreparedBy']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.EmpName}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={props.formData.PreparedBy}
                              label={DispensingLanguage[lan][menukey]["Receive By"]+ " *"}
                              variant="standard"
                              error={props.errorObject.PreparedBy}
                              helperText={props.errorObject.PreparedBy}
                              id="PreparedBy"
                              name="PreparedBy"
                              fullWidth
                            />
                            )}
                          />

                        <TextField
                          id="ReceivedByName"
                          name="ReceivedByName"
                          label={DispensingLanguage[lan][menukey]["Receive By"]+ " *"}
                          value={props.formData.ReceivedByName}
                          disabled= {true}
                          hidden={(props.formData.ReceivedBybActive || props.addProductForm)}
                          fullWidth
                          autoComplete="family-name"
                          
                        />

                        </FormControl>

                  </Grid>

                  <Grid item xs={4} sm={4}>
                    {/* <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label-receive-from">
                        {DispensingLanguage[lan][menukey]["Supplier"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.SupCode}
                        labelId="demo-simple-select-helper-label-receive-from"
                        id="SupCode"
                        name="SupCode"
                        value={props.formData.SupCode}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={
                          manyTableData.length > 0 ? true : props.bStockUpdate
                        }
                      >
                        {SupplierList.map((item, index) => {
                          return (
                            <MenuItem value={item.SupCode}>{item.SupName}</MenuItem>
                          );
                        })}
                      </Select>

                      <FormHelperText error={props.errorObject.SupCode}>
                        {props.errorObject.SupCode}
                      </FormHelperText>
                    </FormControl> */}

                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            disabled={
                              manyTableData.length > 0 ? true : props.bStockUpdate
                            }
                            id="SupCode"
                            options={SupplierList}
                            onChange={(event, valueobj) => handleChangeChoosen('SupCode', valueobj, valueobj?valueobj.SupCode:"")}
                            getOptionLabel={(option) => option.SupName}
                            value={chosenValues['SupCode']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.SupName}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={props.formData.SupCode}
                              label={DispensingLanguage[lan][menukey]["Supplier"]+ " *"}
                              variant="standard"
                              error={props.errorObject.SupCode}
                              helperText={props.errorObject.SupCode}
                              id="SupCode"
                              name="SupCode"
                              fullWidth
                            />
                            )}
                          />

                        </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    {/* <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Procured By"]} 
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="IndentorId"
                        name="IndentorId"
                        value={props.formData.IndentorId}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={props.bStockUpdate}
                      >
                        {IndentorList.map((item, index) => {
                          return (
                            <MenuItem value={item.IndentorId}>
                              {item.IndentorName} - {item.OPDescription}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl> */}

                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            disabled={props.bStockUpdate}
                            id="IndentorId"
                            options={IndentorList}
                            onChange={(event, valueobj) => handleChangeChoosen('IndentorId', valueobj, valueobj?valueobj.IndentorId:"")}
                            getOptionLabel={(option) => option.IndentorName +' - '+ option.OPDescription}
                            value={chosenValues['IndentorId']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.IndentorName +' - '+ option.OPDescription}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={props.formData.IndentorId}
                              label={DispensingLanguage[lan][menukey]["Procured By"]}
                              variant="standard"
                              error={props.errorObject.IndentorId}
                              helperText={props.errorObject.IndentorId}
                              id="IndentorId"
                              name="IndentorId"
                              fullWidth
                            />
                            )}
                          />

                        </FormControl>
                  </Grid>

                  <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                      {/* <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Year"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.YearId}
                        labelId="demo-simple-select-helper-label"
                        id="YearId"
                        name="YearId"
                        value={props.formData.YearId}
                        fullWidth
                        onChange={(e) => onYearChangeEvnt(e)}
                        //disabled={props.bStockUpdate}
                        disabled={
                          manyTableData.length > 0 ? true : props.bStockUpdate
                        }
                      >
                        {PackageYearList.map((item, index) => {
                          return (
                            <MenuItem value={item.YearId}>
                              {item.PackageYear}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={props.errorObject.YearId}>
                        {props.errorObject.YearId}
                      </FormHelperText> */}

                          <Autocomplete
                            autoHighlight
                            disableClearable
                            disabled={
                              manyTableData.length > 0 ? true : props.bStockUpdate
                            }
                            id="YearId"
                            options={PackageYearList}
                            onChange={(event, valueobj) => onYearChangeEvntChoosen('YearId', valueobj, valueobj?valueobj.YearId:"")}
                            getOptionLabel={(option) => option.PackageYear}
                            value={chosenValuesYear['YearId']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.PackageYear}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={props.formData.YearId}
                              label={DispensingLanguage[lan][menukey]["Year"]+ " *"}
                              variant="standard"
                              error={props.errorObject.YearId}
                              helperText={props.errorObject.YearId}
                              id="YearId"
                              name="YearId"
                              fullWidth
                            />
                            )}
                          />
                    </FormControl>
                  </Grid>

                  <Grid item xs={2} sm={2}>
                    <TextField
                      disabled={true /*props.bStockUpdate*/}
                      id="PackageName"
                      name="PackageName"
                      label={DispensingLanguage[lan][menukey]["Package No"]}
                      value={props.formData.PackageName}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={5} sm={5} className="sw_relative">
                    <TextField
                      required
                      error={props.errorObject.ContractName}
                      helperText={props.errorObject.ContractName}
                      disabled={true /*props.bStockUpdate*/}
                      id="ContractName"
                      name="ContractName"
                      label={DispensingLanguage[lan][menukey]["Contract No"]}
                      value={props.formData.ContractName}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />

                    <Button
                      //disabled={props.bStockUpdate}
                      className="pick_btn_place"
                      variant="contained"
                      color="success"
                      disabled={
                        manyTableData.length > 0 ? true : props.bStockUpdate
                      }
                      onClick={() => handleClose("ContractOpen")}
                    >
                      ...
                    </Button>

                    <ContractModalByYear
                      handleClose={handleClose}
                      open={openContract}
                      yearid={currYearId == '' ? props.formData.YearId : currYearId} 
                      addContract={addContract}
                      {...props}
                    />
                    
                  </Grid>
                   {/* <Grid item xs={1} sm={1}>
                    
                    <ContractModalByYear
                      handleClose={handleClose}
                      open={openContract}
                      yearid={currYearId == '' ? props.formData.YearId : currYearId} 
                      addContract={addContract}
                      {...props}
                    />
                  </Grid> */}

                  <Grid item xs={6} sm={6}>
                    <TextField
                      disabled={props.bStockUpdate}
                      id="Remarks"
                      name="Remarks"
                      label={DispensingLanguage[lan][menukey]["Remarks"]}
                      value={props.formData.Remarks}
                      fullWidth
                      multiline
                      rows={3}
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="TransactionId"
                      name="TransactionId"
                      label="TransactionId"
                      value={props.formData.TransactionId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                {/* new row */}
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card">
                    <CardContent>
                      <Grid container className="mb-2">
                        <Grid item xs={12} sm={12}>
                          <div className="float-right sw_btn_control">
                            {/* <Button
                                  className="mr-2"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleClose("OrderOpen")}
                                >
                                  SELECT FROM ORDER
                                </Button>  */}

                            <Button
                              //disabled={props.bStockUpdate}
                              //disabled={ props.bStockUpdate || (props.formData.TransactionId > 0 ? false : true) }
                              disabled={ props.bStockUpdate || (props.formData.ContractNo > 0 ? false : true) }
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              onClick={() => handleClose("ProductsOpen")}
                            >
                              {
                                DispensingLanguage[lan][menukey]["SELECT ITEM"]
                              }
                            </Button>
                          </div>

                          <ProductsModal
                            handleClose={handleClose}
                            open={open}
                            ContractNo={currContractNo == '' ? props.formData.ContractNo : currContractNo} 
                            addProduct={addProduct}
                            {...props}
                          />

                          <SourceModal
                            handleClose={handleClose}
                            open={SoureOpen}
                            addProduct={addSource}
                            {...props}
                          />
                        </Grid>
                      </Grid>
                      <ReactTabulator
                        ref={(r) => (ReceiveDetailsRef = r)}
                        columns={ReceiveDetailsColumns}
                        data={manyTableData}
                        height="350px"
                        layout={"fitColumns"}
                      />
                    </CardContent>
                  </Card>
                </Grid>

                {/* new row */}
              </Grid>
            </Grid>

            {/* </CardContent>
          </Card> */}
          </Grid>

          {/* Action buttons */}
        </Grid>
      </div>
    </div>
  );
};

export default MasterManyFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
