import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import jsPDF from "jspdf";

//Services
import * as Service from "../../../services/Service.js";
import Constants from "../../../services/Constants";
import swal from "sweetalert";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
  Typography,
  TextField,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import ExportButtons from "../../../components/ExportButtons";

import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";

import * as api from "../../../actions/api";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, ViewList, preview, Edit } from "@material-ui/icons";
import { cellFocusEditor } from "../../../services/Common";
import moment from "moment";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";



const StockSummarybyLotGlobal = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const menukey = "stock-summary-by-lot";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;

  const classes = useStyles();
  let tblRef = null;
  const tableRef = useRef();
  const { useState } = React;
  const { t, i18n } = useTranslation();

  const [firstLoad, setFirstLoad] = useState(true);


  const [radioValue, setRadioValue] = useState(0);
  const [dataItems, setDataItems] = useState([]);


  const [currGroupCode, setcurrGroupCode] = useState(0);

  const GroupNameList = JSON.parse(localStorage.getItem("GroupNameList"));
  GroupNameList.unshift({'id':0,'name':t(DispensingLanguage[lan][menukey]["All"])});

  let TempItemList = useState([]);
  const [ItemList, setItemListLocal] = useState(TempItemList);
  const [currItemCode, setcurrItemCode] = useState(0);





  const FacilityList = JSON.parse(localStorage.getItem("FacilityListGlobal"));

  const [currFacilityCode, setcurrFacilityCode] = useState(FacilityList.length>0? FacilityList[0].id:"");
  const [currFacilityName, setcurrFacilityName] = useState(FacilityList.length>0? FacilityList[0].name:"");


  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );


  const queryClient = useQueryClient();
  const UseFor = localStorage.getItem("UseFor");


//====== Start Choosen Dropdown===========


const [chosenValuesFacility, setChosenValuesFacility] = useState({
  
});

const [chosenValues, setChosenValues] = useState({
  "GroupCode": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
});
const [chosenValuesItem, setChosenValuesItem] = useState({
  "ItemCode": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
});




const handleChangeChoosenFacility = (name, valueobj, value) => {
  let chosenValuesDataFacility = { ...chosenValuesFacility};
  chosenValuesDataFacility[name] = valueobj;
  setChosenValuesFacility(chosenValuesDataFacility); 

  setFirstLoad(true);
  setLoading(false);
  setcurrFacilityCode(value);
  setcurrFacilityName(valueobj.name);

};


const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;
  setChosenValues(chosenValuesData); 

  setFirstLoad(true);
  setLoading(false);
  setcurrGroupCode(value);

};

const handleChangeChoosenItem = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValuesItem};
  chosenValuesData[name] = valueobj;
  setChosenValuesItem(chosenValuesData); 

  setFirstLoad(true);
  setLoading(false);
  setcurrItemCode(value);

};

const cItemList = () => {  
  
  let ItemListParam = {action:"ItemList", GroupCode:currGroupCode, FacilityId: FacilityId, menukey:""}

  Service.default
      .postApi("source/combo_generic.php",ItemListParam)
      .then((res) => { 

        let All_Item_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
        const Item_List = [All_Item_label].concat(res.datalist); 

        setChosenValuesItem({
         "ItemCode": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
        }); 

        setItemListLocal(Item_List);
      })
      .catch((err) => {});

}

React.useEffect(() => {
  setcurrItemCode(0); 
  cItemList();
}, [currGroupCode]);
//====== End Choosen Dropdown===========


  let params = {
    menukey: menukey,
    FacilityId: currFacilityCode,
    action: "getStockSummaryLotViewGlobal",
    StartDate: moment(selectedStartDate).format("YYYY-MM-DD"),
    // page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    RadioValue: radioValue,
    GroupCode: currGroupCode,
    ItemCode: currItemCode,
    UseFor: UseFor,
  };

  const [isLoading, setLoading] = useState(false);

  const { error, isError, data, refetch:stockData } = useQuery(
    [params],
    api.StockSummaryBylot11,
    {
      onSuccess: (data) => {
        if(firstLoad){
          localStorage.setItem(
            "StockSummarybylot",
            JSON.stringify(data)
          );
          
          setDataItems(data);
          setLoading(false);
          setFirstLoad(false);
        }
      },
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 0
  }
  ); 






  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let formatedDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=StockSummaryByLotExcelExportGlobal" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&StartDate=" +
        formatedDate +
        "&FacilityId=" +
        currFacilityCode +
        "&GroupCode=" +
        currGroupCode +
        "&ItemCode=" +
        currItemCode +
        "&RadioValue=" +
        radioValue +
        "&FacilityName=" +
        currFacilityName + 
        "&UseFor=" +
        UseFor + 
        "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */


  const columns = [
    {
      title: "Id",
      field: "id",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: "5%",
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    // {
    //   title: DispensingLanguage[lan][menukey]["Group Name"],
    //   field: "GroupName",
    //   headerFilter: false,
    //   visible: false,
    // },
    {
      title: DispensingLanguage[lan][menukey]["Item Code"],
      field: "ItemCode",
      headerFilter: false,
      width: "12%",
    },
    {
      title: DispensingLanguage[lan][menukey]["Unit Name"],
      field: "UnitName",
      headerFilter: false,
      width: "12%",
    }, 
    {
      title: DispensingLanguage[lan][menukey]["Usable Qty"],
      field: "LotQty",
      hozAlign: "right",
      headerHozAlign: "right",
      headerFilter: false,
      width: "10%",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == 0 ? "" : Number(value).toLocaleString();
      },

    },
    {
      title: DispensingLanguage[lan][menukey]["lot_qtyUn"],
      field: "LotQtyUn",
      hozAlign: "right",
      headerHozAlign: "right",
      headerFilter: false,
      width: "10%",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == 0 ? "" : Number(value).toLocaleString();
      },

    },
    {
      title: DispensingLanguage[lan][menukey]["lot_no"],
      field: "LotNo",
      headerFilter: false,
      width: "17%",

    },
    {
      title: DispensingLanguage[lan][menukey]["mfg_date"],
      field: "MfgDate",
      headerFilter: false,
      width: "17%",

    },
    {
      title: DispensingLanguage[lan][menukey]["expiry_date"],
      field: "ExpDate",
      headerFilter: false,
      width: "17%",

    },
  ];


  const handleStartDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedStartDate(date);
    console.log("date: ",date);
    console.log("selectedStartDate: ",selectedStartDate);
  };









  const changeRadio = (value) => {
    setFirstLoad(true);
    setLoading(true);
    setRadioValue(value);
  };

 React.useEffect(() => {
   if (tblRef == null) return;
   setLoading(true);
   stockData();
  }, []);

  return (
    <>
      <div className={classes.productPageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Stock Summary"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
            <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                           
                            disableClearable
                            id="FacilityCode"
                            options={FacilityList}
                            onChange={(event, valueobj) => handleChangeChoosenFacility('FacilityCode', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                          
                            defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityCode)]}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Facility"]}
                              variant="standard"
                              id="FacilityCode"
                              name="FacilityCode"
                              fullWidth
                            />
                            )}
                          />

                    </FormControl>
                  </Grid>
              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      //disableToolbar
                      autoOk={true}
                      // disabled={true}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["Summary Date"]}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={3} sm={3}>
              <FormControl className={classes.fullWidth}>
                 

                 <Autocomplete
                   autoHighlight
                 
                   disableClearable
                   id="GroupCode"
                   options={GroupNameList}
                   onChange={(event, valueobj) => handleChangeChoosen('GroupCode', valueobj, valueobj?valueobj.id:"")}
                   getOptionLabel={(option) => option.name}
                   value={chosenValues['GroupCode']}
                   renderOption={(option) => (
                   <Typography className="sw_fontSize">{option.name}</Typography>
                   )}
                   renderInput={(params) => (
                   <TextField
                     {...params}
                     //value={props.formData.GroupCode}
                     label={DispensingLanguage[lan][menukey]["Group Name"]}
                     variant="standard"
                     id="GroupCode"
                     name="GroupCode"
                     fullWidth
                   />
                   )}
                 />

         </FormControl>
                  </Grid>

                   <Grid item xs={3} sm={3}>
                   <FormControl className={classes.fullWidth}>
                     

                     <Autocomplete
                       autoHighlight
                     
                       disableClearable
                       id="ItemCode"
                       options={ItemList}
                       onChange={(event, valueobj) => handleChangeChoosenItem('ItemCode', valueobj, valueobj?valueobj.id:"")}
                       getOptionLabel={(option) => option.name}
                       value={chosenValuesItem['ItemCode']}
                       renderOption={(option) => (
                       <Typography className="sw_fontSize">{option.name}</Typography>
                       )}
                       renderInput={(params) => (
                       <TextField
                         {...params}
                         label={DispensingLanguage[lan][menukey]["Item Name"]}
                         variant="standard"
                         id="ItemCode"
                         name="ItemCode"
                         fullWidth
                       />
                       )}
                     />

               </FormControl>
                  </Grid> 

                 

              <Grid item xs={1} sm={1}>
                <div className="float-right sw_btn_control">

                <ExportButtons PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}  {...props}></ExportButtons>
                
                  
                </div>
              </Grid>


              <Grid item xs={8} sm={12}>
                <FormControl component="fieldset" className="mt-3 ml-3">
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="row-radio-buttons-group"
                    defaultValue="SHOW_NONE_ZERO"
                  >
                    
                    <FormControlLabel
                      value="Show_Only_Usable_Stock"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["Show Only Usable Stock"]}
                      onClick={() => changeRadio(3)}
                    />
                    <FormControlLabel
                      value="SHOW_NONE_ZERO"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["Show Only Non-Zero Stock"]}
                      onClick={() => changeRadio(0)}
                    />
                    
                    <FormControlLabel
                      value="SHOW_ZERO"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["Show Only Zero Stock"]}
                      onClick={() => changeRadio(1)}
                    />
                    <FormControlLabel
                      value="SHOW_ALL"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["Show All Items"]}
                      onClick={() => changeRadio(2)}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>


            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}

          <div className="uniqueName">
            <ReactTabulator
              ref={(r) => (tblRef = r)}
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="400px"
              // groupBy="GroupName"
              options={{
                  groupBy: ["GroupName","ItemName"],
                  columnCalcs: "both",
                }}
             // rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default StockSummarybyLotGlobal;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
