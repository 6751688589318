import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import EmployeeFormData from "./EmployeeFormData";

const AddEntryOfSupplier = (props) => {
 // console.log(props.pieceOfState);

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "employee-entry";

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;

  const [formData, setFormData] = useState({
    EmpName: "",
    Email: "",
    MobileNo: "",
    DesigCode: "",
    bActive: true,
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const [open, setOpen] = React.useState(false);
  const [Massage, setMassage] = React.useState(false);

  const { isLoading, mutate } = useMutation(api.saveEmployee, {
    onSuccess: (data) => {
      console.log("data: ", data);

      if (data.status == 200) {

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

        const auth_token = sessionStorage.getItem("token")
        ? sessionStorage.getItem("token")
        : null;
        let options = {};
        options = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth_token}`,
          },
          task:'UOM'
        };


        api.getLoginFacilityListNotEqual99ANDSuppliers(options,'ALL')
        .then((response) => {

          localStorage.setItem(
            "FacilityEmployee",
            JSON.stringify(response.datalist.FacilityEmployee)
          );

        });


        queryClient.getQueriesData("Employee"); //api page name
        props.history.push("/employee-entry"); //
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        queryClient.getQueriesData("Employee"); //api page name
        props.history.push("/employee-entry"); //
      }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    console.log("formData: ", formData);
    data[name] = value;

     if (name == "MobileNo") {
      let vMobileNo = '';
        const onlyNums = value.replace(/[^0-9]/g, '');
        vMobileNo = onlyNums;
        data["MobileNo"] = vMobileNo;

    } 

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };


  //====== Start Choosen Dropdown===========
  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value;   
    setFormData(data); 
    setErrorObject({ ...errorObject, [name]: "" });
    api.dirtyModeEnable();
  }
  //====== End Choosen Dropdown===========

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
    api.dirtyModeEnable();
  };

  const handleReset = () => {
    setFormData({
      EmpName: "",
      Email: "",
      MobileNo: "",
      DesigCode: "",
      bActive: true,
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["EmpName","DesigCode","MobileNo"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
     
      let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (formData['Email'] !='') {
        if(!formData['Email'].match(mailformat)) {
          errorData['Email'] = "Email is not valid !";//DispensingLanguage[lan][menukey]["field is Required !"];
          isValid = false;
        } 
      }
      
      if(formData['MobileNo'].indexOf('0')!==0){
        errorData['MobileNo'] = "Mobile number is not valid !";//DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }

      if(formData['MobileNo'].indexOf('1')!==1){
        errorData['MobileNo'] = "Mobile number is not valid !";//DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }

      if(formData['MobileNo'].length < 11){
        errorData['MobileNo'] = "Mobile number is not valid !";//DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }

      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleSubmit = async (e) => {
    console.log("from submit ", formData);
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        menukey: menukey,
        lan: lan,
        UserName: UserName,
      };
      mutate(fDtat);
    }
  };

  return (
    <>
      <EmployeeFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        bStockUpdate={props.permissionType}
        open={open}
        {...props}
      />
    </>
  );
};

export default AddEntryOfSupplier;
