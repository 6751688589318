import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  Grid,
  TextField,
  Button,
  FormControlLabel,  
  Radio,
  FormLabel,
  RadioGroup,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import swal from "sweetalert";
import * as api from "../../../actions/api";
let timeStamp = "";

const EntryofWarehouseFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  open,
  ...props
}) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "entry-edit-of-warehouse";
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  
  const ReceivingWarehouseList = JSON.parse(
    localStorage.getItem("whstatusmapwarahouse")
  );
  const DistrictList = JSON.parse(localStorage.getItem("DistrictList"));
  const UsingSoftwareList =  [
    {
      "id": "Web",
      "name": "Web"
    },
    {
      "id": "Desktop",
      "name": "Desktop"
    }
  ];



  
  
const backtolist=()=>{


  let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
  let idx = master_dirty_message_class.indexOf("dnone");
  if(idx === -1){
    // start Alert
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then((willAction) => {
      if (willAction) {
        props.history.push("/entry-edit-of-warehouse");
      }
    });
    // End Alert
  }else{
    props.history.push("/entry-edit-of-warehouse");
  }

}

const onSaveUpdateBtnClick = () => {

  setUpdatedDetailsGridDataHandler();
};


const setUpdatedDetailsGridDataHandler = () => {
  let validForm = props.checkValidateForm();
 
  // console.log('isval: ', validForm);
    if (validForm) {

      if(addProductForm){
        //when save
        mutate(validForm);
        
      }else{
        //when update
        mutateUpdate(validForm); 
      }
      

      
    }
};

const { isLoading : isLoadingSave, mutate } = useMutation(api.saveWareHouseEntry, {
  onSuccess: (data) => {
   // console.log("data: ", data);

    if (data.status == 200) {
      //console.log("data: ", data.data);
      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });
      queryClient.getQueriesData("EntryofWarehouse"); //api page name
      props.history.push("/entry-edit-of-warehouse"); //
    } else {
      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });
      queryClient.getQueriesData("EntryofWarehouse"); //api page name
      props.history.push("/entry-edit-of-warehouse"); //
    }
  },
});

const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.updateWareHouse, {
  onSuccess: (data) => { 
    if (data.status == 200) {
      // api.getAllDropdown("ABC_List").then((response) => {
      //   localStorage.setItem(
      //     "ABC_List",
      //     JSON.stringify(response.datalist.ABC_List)
      //   );
      // });

      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });

      queryClient.getQueriesData("EntryofWarehouse"); // include the page name
      props.history.push("/entry-edit-of-warehouse"); //page url
      api.dirtyModeDisable();
    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
    }
  },
});

return (
  <div className={classes.productPageTitle}>
    <div className="sw_makeStyles_tableContainer">
      <div className="d-flex justify-product mb-3">
        <Grid item xs={12} sm={12}>
          <div className="sw_page_heading">
            <div className="sw_heading_title">
              {DispensingLanguage[lan][menukey]["Entry Edit Warehouse Name - Add/Edit"]}
            </div>
            </div>
            <div className="float-right sw_btn_control">
              {addProductForm ? (
                <Grid item xs={12} className="text-center">
                  <div className="float-right sw_btn_control">
                    <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => handleReset()}
                    >
                      {DispensingLanguage[lan][menukey]["Reset"]}
                    </Button>
                    <Button
                      disabled={props.bStockUpdate || isLoadingSave}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                      // onClick={() => handleSubmit()}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={() => props.history.push("/menu-entry")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Cancel"]}
                    </Button>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} className="mt-2 text-center">
                  <Button
                      disabled={props.bStockUpdate || isLoadingUpdate}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClick}
                    // onClick={() => handleUpdate()}
                  >
                    {DispensingLanguage[lan][menukey]["Update"]}
                  </Button>

                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    //onClick={() => props.history.push("/menu-entry")}
                    onClick={() => backtolist()}
                  >
                    {DispensingLanguage[lan][menukey]["Cancel"]}
                  </Button>
                </Grid>
              )}
            </div>
          
             <div className="mr-4 float-right mt-15">
              <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
            </div>
        </Grid>
      </div>

      {/* New row */}
      <Grid container spacing={3}>
        {/* New row */}
        <Grid item xs={12} sm={12}>
          <Card className="sw_card">
            <CardContent>
                <Grid container spacing={10}>
                <Grid item xs={4} sm={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={4} sm={4}>
                      <TextField
                        error={errorObject.wh_name}
                        helperText={errorObject.wh_name}
                        required
                        disabled={props.permissionType}
                        id="wh_name"
                        name="wh_name"
                        label={
                          DispensingLanguage[lan][menukey]["Warehouse Name"]
                        }
                        value={formData.wh_name}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>

                    <Grid item xs={4} sm={4}>
                      <TextField
                        error={errorObject.wh_id}
                        helperText={errorObject.wh_id}
                        required
                        id="wh_id"
                        name="wh_id"
                        label={
                          DispensingLanguage[lan][menukey]["Warehouse Code"]
                        }
                        value={formData.wh_id}
                        fullWidth
                        autoComplete="family-name"
                        inputProps={{ maxLength: 4 }}
                        onChange={(e) => handleChange(e)}
                        disabled={
                          !addProductForm || props.permissionType
                            ? true
                            : false
                        }
                      />
                    </Grid>

                    <Grid item xs={4} sm={4}>
                      <TextField
                        error={errorObject.contact_person}
                        helperText={errorObject.contact_person}
                        disabled={props.permissionType}
                        id="contact_person"
                        name="contact_person"
                        label={
                          DispensingLanguage[lan][menukey]["Contact Person"]
                        }
                        value={formData.contact_person}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <TextField
                        error={errorObject.contemail}
                        helperText={errorObject.contemail}
                        disabled={props.permissionType}
                        id="contemail"
                        name="contemail"
                        label={
                          DispensingLanguage[lan][menukey]["Contact E-mail"]
                        }
                        value={formData.contemail}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <TextField
                        error={errorObject.extra}
                        helperText={errorObject.extra}
                        disabled={props.permissionType}
                        id="extra"
                        name="extra"
                        label={DispensingLanguage[lan][menukey]["Mobile No."]}
                        value={formData.extra}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                        inputProps={{
                          maxLength: 11,
                        }}
                      />
                    </Grid>

                    <Grid item xs={4} sm={4}>
                      <TextField
                        error={errorObject.phoneno}
                        helperText={errorObject.phoneno}
                        disabled={props.permissionType}
                        id="phoneno"
                        name="phoneno"
                        label={DispensingLanguage[lan][menukey]["Phone No."]}
                        value={formData.phoneno}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                        inputProps={{
                          maxLength: 11,
                        }}
                      />
                    </Grid>

                    <Grid item xs={4} sm={4}>
                      <TextField
                        error={errorObject.wh_address}
                        helperText={errorObject.wh_address}
                        disabled={props.permissionType}
                        id="wh_address"
                        name="wh_address"
                        label={DispensingLanguage[lan][menukey]["Address"]}
                        value={formData.wh_address}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>


                    <Grid item xs={12} sm={2}>
                      <FormControl
                        className="sw_radiogroup"
                        component="fieldset"
                        //required
                        error={errorObject.UsingSoftware}
                        helperText={errorObject.UsingSoftware}
                      >
                        <FormLabel component="legend">
                          {DispensingLanguage[lan][menukey]["Using_Web_Desktop"]}
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-label="UsingSoftware"
                          name="UsingSoftware"
                          value={formData.UsingSoftware}
                          onChange={handleChange}
                        >
                          {UsingSoftwareList.map((item, index) => {
                            return (
                              <FormControlLabel
                                value={item.id}
                                control={<Radio />}
                                label={item.name}
                                disabled={formData.UsingSoftwareEdit === 'Web'}
                              ></FormControlLabel>
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
              {/* New Row */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  </div>
);
};


export default EntryofWarehouseFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
