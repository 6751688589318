import React, { forwardRef, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import jsPDF from "jspdf";

//Services
import * as Service from "../../../services/Service.js";
import Constants from "../../../services/Constants.js";
import swal from "sweetalert";

import {
  Grid,
  FormControl,
  Typography,
  TextField,
  Card,
  CardContent,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExportButtons from "../../../components/ExportButtons.js";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api.js";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import moment from "moment";

const MCWCAssetViewGlobal = (props) => {
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const FacilityId = localStorage.getItem("FacilityId");
  const menukey = "mcwc-asset-report-global";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;
  const classes = useStyles();
  const { useState } = React;
  const { t, i18n } = useTranslation();
  const [dataItems, setDataItems] = useState([]);
  let TempItemList = useState([]);
  const UseFor = localStorage.getItem("UseFor");

  const AssetStatusList = JSON.parse(localStorage.getItem("AssetStatusList"));
  AssetStatusList.unshift({
    MStatusId: 0,
    name: t(DispensingLanguage[lan][menukey]["All"]),
  });
  const [chosenValues, setChosenValues] = useState({
    MStatusId: {
      MStatusId: 0,
      name: t(DispensingLanguage[lan][menukey]["All"]),
    },
  });

  const [MStatusId, setMStatus] = useState(0);

  const handleChangeChoosen = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;
    // setFirstLoad(true);
    setChosenValues(chosenValuesData);
    setMStatus(value);
  };

  const FacilityList = JSON.parse(localStorage.getItem("MCWCFacilityList"));
  FacilityList.unshift({
    id: 0,
    name: t(DispensingLanguage[lan][menukey]["All"]),
  });

  const [currFacilityCode, setcurrFacilityCode] = useState(
    FacilityList.length > 0 ? FacilityList[0].id : "0"
  );
  const [currFacilityName, setcurrFacilityName] = useState(
    FacilityList.length > 0
      ? FacilityList[0].name
      : DispensingLanguage[lan][menukey]["All"]
  );

  const [chosenValuesFacility, setChosenValuesFacility] = useState({});

  const handleChangeChoosenFacility = (name, valueobj, value) => {
    let chosenValuesDataFacility = { ...chosenValuesFacility };
    chosenValuesDataFacility[name] = valueobj;
    setChosenValuesFacility(chosenValuesDataFacility);

    /* setFirstLoad(true);
    setLoading(false); */
    setcurrFacilityCode(value);
    setcurrFacilityName(valueobj.name);
  };

  let params = {
    action: "getDataList",
    menukey: menukey,
    FacilityId: FacilityId,
    MStatusId: MStatusId,
    FacilityCode: currFacilityCode,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    UseFor: UseFor,
  };
  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getassetregisterglobalList,
    {
      onSuccess: (data) => {
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {

     let curFacilityName = FacilityList[FacilityList.findIndex((Facility_List) => Facility_List.id == currFacilityCode)].name;
     let curMStatus = AssetStatusList[AssetStatusList.findIndex((AssetStatus_List) => AssetStatus_List.MStatusId == MStatusId)].name;
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=AssetReportGlobalExport" +
        "&reportType=" +
        reportType +
        "&MStatusId=" +
        MStatusId +
        "&FacilityId=" +
        FacilityId +
        "&FacilityCode=" +
        currFacilityCode +
        "&FacilityName=" +
        curFacilityName +
        "&MStatus=" +
        curMStatus +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 50,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
      //frozen:true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Code"],
      field: "FacilityCode",
      headerSort: true,
      headerFilter: true,
      width: 120,
      /* frozen: true, */
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Name"],
      field: "FacilityName",
      headerSort: true,
      headerFilter: true,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 250,
     /*  frozen: true, */
    },
    {
      title: DispensingLanguage[lan][menukey]["Asset Code"],
      field: "ItemCode",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Equipment Name"],
      field: "ItemName",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Present Status"],
      field: "MStatus",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Asset Brand"],
      field: "BrandName",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Asset Model"],
      field: "ModelNo",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Purchase Value"],
      field: "InitialPrice",
      width: 150,
      hozAlign: "right",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Life Span"],
      field: "UsefulLife",
      width: 150,
      hozAlign: "right",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Supplier"],
      field: "SupName",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Manufacturer"],
      field: "ManufacturerName",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Procuring Entity"],
      field: "ProcuringName",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Country of Origin"],
      field: "CountryName",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Department"],
      field: "DeptName",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Unit"],
      field: "SectionName",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Location Indication"],
      field: "AssetLocation",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Source of Fund"],
      field: "FundingSourceName",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Serial No"],
      field: "MenufacturerSNo",
      width: 150,
      hozAlign: "right",
      headerFilter: true,
    },
    {
      title:
        DispensingLanguage[lan][menukey]["Date of Receipt by Health Facility"],
      field: "DateRecvInStore",
      width: 150,
      hozAlign: "center",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Date of Installation"],
      field: "InstallationDate",
      width: 150,
      hozAlign: "center",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Date of Commissioning"],
      field: "SupplyDate",
      width: 150,
      hozAlign: "center",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Last Date of Warranty"],
      field: "LastDateWarranty",
      width: 150,
      hozAlign: "center",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Rate of Depreciation"],
      field: "DepRate",
      width: 150,
      hozAlign: "right",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Salvage Value"],
      field: "salvageval",
      width: 150,
      hozAlign: "right",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Maintenance Schedule"],
      field: "MSchedule",
      width: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Remarks"],
      field: "Remarks",
      width: 150,
      headerFilter: false,
      headerSort: false,
    },
  ];

  return (
    <>
      <div className={classes.productPageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["MCWC Asset Report Global"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    disableClearable
                    id="FacilityCode"
                    options={FacilityList}
                    onChange={(event, valueobj) =>
                      handleChangeChoosenFacility(
                        "FacilityCode",
                        valueobj,
                        valueobj ? valueobj.id : ""
                      )
                    }
                    getOptionLabel={(option) => option.name}
                    defaultValue={
                      FacilityList[
                        FacilityList.findIndex(
                          (facilitylist) => facilitylist.id == currFacilityCode
                        )
                      ]
                    }
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">
                        {option.name}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Facility"]}
                        variant="standard"
                        id="FacilityCode"
                        name="FacilityCode"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <Autocomplete
                    autoHighlight
                    disableClearable
                    id="MStatusId"
                    options={AssetStatusList}
                    onChange={(event, valueobj) =>
                      handleChangeChoosen(
                        "MStatusId",
                        valueobj,
                        valueobj ? valueobj.MStatusId : ""
                      )
                    }
                    getOptionLabel={(option) => option.name}
                    value={chosenValues["MStatusId"]}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">
                        {option.name}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Asset Status"]}
                        variant="standard"
                        id="MStatusId"
                        name="MStatusId"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <div className="float-right sw_btn_control">
                  <ExportButtons
                    PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}
                    {...props}
                  ></ExportButtons>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="450px"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default MCWCAssetViewGlobal;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
