import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar";
import {checkLogin, checkUserPermission,getUserActionPermission} from "../../../services/CheckUserAccess";
import Notification from "../../../services/Notification";
//Role screen
import Catalogusermap from "./Catalogusermap";


const Index = (props) => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const menukey = "catalog-user-map";

  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  const [hasUserPermission, setHasUserPermission] = React.useState(false);

/*   const checkLogin = () => {  
    let token = sessionStorage.getItem("token");
    if (!token) {
      swal({
        title: "Oops!",
        text: 'token expired. Please login again',
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) { 
          window.location.href = process.env.REACT_APP_BASE_NAME+`/login`;
          sessionStorage.clear();
        }
      });
    }
  }; */
  
  if(RedirectLogin){
    setHasUserPermission(checkUserPermission(menukey));// To check user has permission in this page
    checkLogin();
    setRedirectLogin(false);
  }

  //This is for user has VIEW/EDIT permission in this page start
  const permissionType = getUserActionPermission(menukey);
  // console.log('propsprops permissionType index: ', permissionType);
  //This is for user has VIEW/EDIT permission in this page end
    
  React.useEffect(() => {
    // checkLogin();
    // checkAccess();
  }, []);


  const [msgObj, setMsgObj] = React.useState({
    isOpen: false,
  });
  const openNoticeModal = (obj) => {
    setMsgObj(obj);
  };
  const closeNoticeModal = (event, reason) => { 
      if (reason === 'clickaway') {
        return;
      } 
    setMsgObj({ isOpen: false });
  };

 

  // const checkLogin = () => {
  //   let token = sessionStorage.getItem("token");
  //   // let token = Constants.token;
  //   // console.log(Constants.token);
  //   if (!token) {
  //     swal("Oops!", `token expired. Please login again`, "error");
  //     props.history.push("/login");
  //     sessionStorage.clear();
  //   }
  // };

  // const checkAccess = () => {
  //  // let auth = JSON.parse(sessionStorage.getItem("User_info"));
  //   let auth = Constants.USER_INFO;

    
  //   console.log("auth");
  //   console.log(auth);
  //   if (auth) {
  //     let menu = auth[0].menu;
  //     console.log("menu: "+menu);
  //     if (menu.length > 0) {
  //       var check = menu.filter(name => name.title.includes('Admin'));
        
  //       console.log("check: "+check);

  //       if(check.length == 0){
  //         // console.log("auth1: "+menu[0].url);
  //        props.history.push(menu[0].url);
  //       }
  //     } else {
  //       swal("Oops!", `You don't have permission to access any page`, "error");
  //       props.history.push("/login");
  //       sessionStorage.clear();
  //     }
  //   }
  // };

  return (
    // <div>
    //   <AfterLoginNavbar {...props} />
    //   <div className={`section signup-top-padding ${classes.dispenserContainer}`}>
    //     <Switch>
    //       <Route path={`${path}/shortorder`} component={ShortOrderMenu}></Route>
    //       <Route path={`${path}/`} component={Roleaccess}></Route>
    //     </Switch>
    //   </div>
    // </div>

    hasUserPermission && (
          <div>
      <AfterLoginNavbar {...props} />
      <div
        className={`section signup-top-padding ${classes.dispenserContainer}`}
      >
        <Switch>
          
          
          <Route
            path={`${path}/`}
            render={(props) => (
              <Catalogusermap {...props} openNoticeModal={openNoticeModal} permissionType={permissionType}/>
            )}
          ></Route>
        </Switch>
        <Notification
          closeNoticeModal={closeNoticeModal}
          msgObj={msgObj}
          {...props}
        ></Notification>
      </div>
      </div>
    )

  );
};



export default Index;

const useStyles = makeStyles({
  dispenserContainer: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
});
