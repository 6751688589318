
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, FormControl, InputLabel, Select, MenuItem, Button, Paper, CardHeader, ButtonGroup } from "@material-ui/core";


const PrevNext = (props) => {
//function Pagination({ dataList, DataId,StockDataIndex, props }) {
 
   
  const [gIndex, setGIndex] = useState(props.StockDataIndex); 
  const [stockdats, setStockdats] = useState(props.dataList); 
   
  
  function handleLast() {
    setGIndex(stockdats.length-1);  
    if(props.handleReset!='')
    props.handleReset();
    stockdats.forEach((row,i) => {
      if(stockdats.length-1==i)
      {
        props.handleIndex(i,row); 
      } 
    }); 
    
  }
  
  function handleFirst() {
    if(props.handleReset!='')
    props.handleReset();
    setGIndex(0);  
    stockdats.forEach((row,i) => {
      if(0==i)
      {
        props.handleIndex(i,row);  
      } 
    }); 
    
  }


  function goToNextPage() {
    if(props.handleReset!='')
    props.handleReset();
    setGIndex(gIndex+1);  
    stockdats.forEach((row,i) => {
      if(gIndex+1==i)
      { 
        props.handleIndex(i,row);  
      }
       
      
     
    }); 
    
   
    
  }

  function goToPreviousPage() {
    if(props.handleReset!='')
    props.handleReset();
    setGIndex(gIndex-1);  
    stockdats.forEach((row,i) => {
      if(gIndex-1==i)
      { 
        props.handleIndex(i,row);
       
      }
      
     
    
   }); 
 
  }

   

  return (

    



    <div>

      <Grid container>
          <Grid item xs={12} sm={12}>
            <div className="NextPrevCard">
                <ButtonGroup variant="contained" aria-label="outlined primary button group">
                  <Button
                  disabled={gIndex==0?true:false}
                  onClick={handleFirst}
                  ><i className="fa fa-angle-double-left"></i></Button>  

                  <Button
                  onClick={goToPreviousPage}
                  disabled={gIndex==0?true:false}
                  ><i className="fa fa-angle-left"></i></Button>

                  <Button
                  onClick={goToNextPage}
                  disabled={props.dataList.length==gIndex+1?true:false}
          
                  ><i className="fa fa-angle-right"></i></Button>
                  <Button
                    disabled={props.dataList.length==gIndex+1?true:false}
                    onClick={handleLast}
                  ><i className="fa fa-angle-double-right"></i></Button>
                </ButtonGroup>
            </div>
          </Grid>
      </Grid>
        
    </div>
  );
}
export default PrevNext;