import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  FormControlLabel,
  TextField,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel,
  MenuItem,
  FormGroup,
  Card,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import swal from "sweetalert";

import Autocomplete from '@material-ui/lab/Autocomplete';
//react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";


import { useTranslation } from "react-i18next";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

import { cellFocusEditor, dateEditor } from "../../../services/Common";
import ProfileModal from "./ProfileModal";
import ProfileModalCopy from "./ProfileModalCopy";


const UpazilaCustomSupplyPlanEntryItems = (props) => {
  
  const permissionType = props.permissionType;
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem("LangCode");
  const menukey = "upazila-custom-supply-plan";
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  let ReceiveDetailsRef = null;
  let DisapprovedItemsRef = null;
  let FacilityDetailsRefLeft = null;
  let FacilityDetailsRefRight = null;
  let FacilityAndProductDetailsRefFinal = null;

  const [wizardstep, setWizardStep] = useState(1);
  const [profileModal, setProfileModal] = useState(false);
  const [profileModalCopy, setProfileModalCopy] = useState(false);

  const [defaultProfId, setDefaultProfId] = useState(0);

  const [manyTableData, setManyTableData] = useState([]);
  const [dispprovemanyTableData, setDispprovedManyTableData] = useState([]);
  
  const [facilitiesTableLeftData, setFacilitiesTableLeftData] = useState([]);
  const [facilitiesTableRightData, setFacilitiesTableRightData] = useState([]);

  const [facilityAndProductTableDataFinal, setFacilityAndProductTableDataFinal] = useState([]);
  const SupplyPlanProfileList = JSON.parse(localStorage.getItem("SupplyPlanProfileList"));
  const auth_token = sessionStorage.getItem("token")? sessionStorage.getItem("token"): null;

//====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "ProfId": {"ProfId": "0","ProfileName": ""}
});

const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;
  setChosenValues(chosenValuesData); 
  setDefaultProfId(value);

};
//====== End Choosen Dropdown===========

  let options = {};
        options = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth_token}`,
          },
        };
        
  const { isLoading, error, isError, data, refetch } = useQuery(
    "upazilacustomsupplyplan",
    api.getUpazilaCustomSupplyPlanItems,
    {
      onSuccess: (data) => {
        setManyTableData(data);
      },
      // enabled: false,
      refetchOnWindowFocus: false,
      // refetchOnmount: false,
      // refetchOnReconnect: false,
      // retry: false,
      // staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  // const { isLoading, error, isError, data, refetch } = useQuery(["contracts", yearid], api.getContractListByYear);

  
  const { isLoading5, error5, isError5, data5, refetch:getUpazilaProfileItems } = useQuery(
    ["customsupplyplanPI", defaultProfId],
    api.getUpazilaProfileItems,
    {
      onSuccess: (data5) => {

        let rows = [];
        let autoId = 0;

        //Profile items from db
        data5.forEach((row, i) => {
            autoId++;
            let newRow = {};

            newRow.id = autoId;
            newRow.ItemCode = row.ItemCode;
            newRow.GroupName = row.GroupName;
            newRow.ItemName = row.ItemName;
            newRow.Qty = row.Qty;
            newRow.ProfId = defaultProfId;

            rows.push(newRow);
        });

        setDispprovedManyTableData(rows); //right grid state
        
      },
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  const { isLoading1, error1, isError1, data1, refetch:getFacilities } = useQuery(
    "customsupplyplanHF",
    api.getUpazilaCustomSupplyPlanFacility,
    {
      onSuccess: (data1) => {
        setFacilitiesTableLeftData(data1);
      },
      // enabled: false,
      refetchOnWindowFocus: false,
      // refetchOnmount: false,
      // refetchOnReconnect: false,
      // retry: false,
      // staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );



  
  const { isLoading2, error2, isError2, data2, refetch:getFacilitiesAndProdFinal } = useQuery(
    "customsupplyplanHFProdFinal",
    api.getUpazilaCustomSupplyPlanFacilityAndProductFinal,
    {
      onSuccess: (data2) => {
        setFacilityAndProductTableDataFinal(data2);
      },
      // enabled: false,
      refetchOnWindowFocus: false,
      // refetchOnmount: false,
      // refetchOnReconnect: false,
      // retry: false,
      // staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );




  /* =====Start of Print Excel Many Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;


  const handleClose = (modalname) => {
    switch (modalname) {
    
      case "ProfileModalClose":
        setProfileModal(false);
        break;
      case "ProfileModalOpen":
        setProfileModal(true);
        break;

      case "ProfileModalCopyClose":
        setProfileModalCopy(false);
        break;
      case "ProfileModalCopyOpen":
        setProfileModalCopy(true);
        break;

      default:
        break;
    }
  };

  const handleCopy = () => {

     //when zero then profile not selected.
     if(defaultProfId === 0){
      //setProfileModal(true);
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Please select profile"],
        msgtype: 0,
      });
      return;
    }

    handleClose("ProfileModalCopyOpen");

  };


  const checkItem = (cItemCode, searchList) => {
    let isExist = 0;
    searchList.forEach((row, i) => {
      if (row.ItemCode == cItemCode) {
        isExist = 1;
      }
    });

    return isExist;
  };


  const ProductShiftLeftToRight = () => {

      //when zero then profile not selected.
      if(defaultProfId === 0){
        //setProfileModal(true);
        props.openNoticeModal({
          isOpen: true,
          msg: DispensingLanguage[lan][menukey]["Please select profile"],
          msgtype: 0,
        });
        return;
      }


    if (ReceiveDetailsRef.table.getSelectedData().length > 0) {

      //////////////////////////////////Manage Right Side Grid Start//////////////////////////////
      let selectedItemGrid = ReceiveDetailsRef.table.getSelectedData();

      let rows = [];
      let autoId = 0;

      //New items from left grid
      selectedItemGrid.forEach((row, i) => {
        if (!checkItem(row.ItemCode, dispprovemanyTableData)) {
          autoId++;
          let newRow = {};

          newRow.id = autoId;
          newRow.ItemCode = row.ItemCode;
          newRow.GroupName = row.GroupName;
          newRow.ItemName = row.ItemName;
          newRow.Qty = null;
          newRow.ProfId = defaultProfId;

          rows.push(newRow);
        }
      });

      //Existing items in right grid
      dispprovemanyTableData.forEach((row, i) => {
        autoId++;
        let newRow = {};

        newRow.id = autoId;
        newRow.ItemCode = row.ItemCode;
        newRow.GroupName = row.GroupName;
        newRow.ItemName = row.ItemName;
        newRow.Qty = row.Qty;
        newRow.ProfId = defaultProfId;

        rows.push(newRow);
      });

      setDispprovedManyTableData(rows); //right grid state
      //////////////////////////////////Manage Right Side Grid END//////////////////////////////

      // Item remove from left side grid
      let rowsForLeftItemGrid = [];
      manyTableData.forEach((row, i) => {
        //rows = right side item grid
        if (!checkItem(row.ItemCode, rows)) {
          rowsForLeftItemGrid.push(row);
        }
        
      });
      setManyTableData(rowsForLeftItemGrid);  //left grid state
    }

  };



  const ProductShiftRightToLeft = () => {
    if (DisapprovedItemsRef.table.getSelectedData().length > 0) {

      //////////////////////////////////Manage Right Side Grid Start//////////////////////////////
      let selectedItemGrid = DisapprovedItemsRef.table.getSelectedData();

      let rows = [];
      let autoId = 0;

      //New items from left grid
      selectedItemGrid.forEach((row, i) => {
        // if (!checkItem(row.ItemCode, dispprovemanyTableData)) {
          // autoId++;
          let newRow = {};

          // newRow.id = autoId;
          newRow.ItemCode = row.ItemCode;
          newRow.GroupName = row.GroupName;
          newRow.ItemName = row.ItemName;
          // newRow.Qty = null;
          // newRow.ProfId = 1;

          rows.push(newRow);
        // }
      });
    
      //////////////////Remove From right site itemlist/////////////////////
      let dispprovemanyTableDataNew = [];
      dispprovemanyTableData.forEach((row, i) => {
        if (!checkItem(row.ItemCode, rows)) {
          // autoId++;
          let newRow = {};

          // newRow.id = autoId;
          newRow.ItemCode = row.ItemCode;
          newRow.GroupName = row.GroupName;
          newRow.ItemName = row.ItemName;
          newRow.Qty = row.Qty;
          // newRow.ProfId = 1;

          dispprovemanyTableDataNew.push(newRow);
        }
      });
      setDispprovedManyTableData(dispprovemanyTableDataNew);  //right grid state
      //////////////////////////////////////

      

      //Existing items in right grid
      manyTableData.forEach((row, i) => {
        // autoId++;
        let newRow = {};

        // newRow.id = autoId;
        newRow.ItemCode = row.ItemCode;
        newRow.GroupName = row.GroupName;
        newRow.ItemName = row.ItemName;
        // newRow.Qty = row.Qty;
        // newRow.ProfId = 1;

        rows.push(newRow);
      });

      setManyTableData(rows); //right grid state
      //////////////////////////////////Manage Right Side Grid END//////////////////////////////
    }

  };










  const checkFacility = (cItemCode, searchList) => {
    let isExist = 0;
    searchList.forEach((row, i) => {
      if (row.FacilityCode == cItemCode) {
        isExist = 1;
      }
    });

    return isExist;
  };

  const FacilityShiftLeftToRight = () => {
    if (FacilityDetailsRefLeft.table.getSelectedData().length > 0) {

      //////////////////////////////////Manage Right Side Grid Start//////////////////////////////
      let selectedItemFromLeftGrid = FacilityDetailsRefLeft.table.getSelectedData();

      let rows = [];
      let autoId = 0;

      //New items from left grid
      selectedItemFromLeftGrid.forEach((row, i) => {
        if (!checkFacility(row.FacilityCode, facilitiesTableRightData)) {
          autoId++;
          let newRow = {};

          newRow.id = autoId;
          newRow.FacilityCode = row.FacilityCode;
          newRow.FGroupName = row.FGroupName;
          newRow.FacilityName = row.FacilityName;
          // newRow.Qty = null;
          // newRow.ProfId = 1;

          rows.push(newRow);
        }
      });

      //Existing items in right grid
      facilitiesTableRightData.forEach((row, i) => {
        autoId++;
        let newRow = {};

        newRow.id = autoId;
        newRow.FacilityCode = row.FacilityCode;
        newRow.FGroupName = row.FGroupName;
        newRow.FacilityName = row.FacilityName;
        // newRow.Qty = row.Qty;
        // newRow.ProfId = 1;

        rows.push(newRow);
      });

      setFacilitiesTableRightData(rows); //right grid state
      //////////////////////////////////Manage Right Side Grid END//////////////////////////////

      // Item remove from left side grid
      let rowsForLeftItemGrid = [];
      facilitiesTableLeftData.forEach((row, i) => {
        //rows = right side item grid
        if (!checkFacility(row.FacilityCode, rows)) {
          rowsForLeftItemGrid.push(row);
        }
        
      });
      setFacilitiesTableLeftData(rowsForLeftItemGrid);  //left grid state

    }

  };



  const FacilityShiftRightToLeft = () => {
    if (FacilityDetailsRefRight.table.getSelectedData().length > 0) {

      //////////////////////////////////Manage Right Side Grid Start//////////////////////////////
      let selectedItemFromLeftGrid = FacilityDetailsRefRight.table.getSelectedData();

      let rows = [];
      let autoId = 0;

      //New items from left grid
      selectedItemFromLeftGrid.forEach((row, i) => {
        // if (!checkFacility(row.FacilityCode, facilitiesTableRightData)) {
          // autoId++;
          let newRow = {};

          // newRow.id = autoId;
          newRow.FacilityCode = row.FacilityCode;
          newRow.FGroupName = row.FGroupName;
          newRow.FacilityName = row.FacilityName;
          // newRow.Qty = null;
          // newRow.ProfId = 1;

          rows.push(newRow);
        // }
      });
    
      //////////////////Remove From right site itemlist/////////////////////
      let dispprovemanyTableDataNew = [];
      facilitiesTableRightData.forEach((row, i) => {
        if (!checkFacility(row.FacilityCode, rows)) {
          // autoId++;
          let newRow = {};

          // newRow.id = autoId;
          newRow.FacilityCode = row.FacilityCode;
          newRow.FGroupName = row.FGroupName;
          newRow.FacilityName = row.FacilityName;
          // newRow.Qty = null;
          // newRow.ProfId = 1;

          dispprovemanyTableDataNew.push(newRow);
        }
      });
      setFacilitiesTableRightData(dispprovemanyTableDataNew);  //right grid state
      //////////////////////////////////////

      

      //Existing items in right grid
      facilitiesTableLeftData.forEach((row, i) => {
        // autoId++;
        let newRow = {};

        // newRow.id = autoId;
        newRow.FacilityCode = row.FacilityCode;
        newRow.FGroupName = row.FGroupName;
        newRow.FacilityName = row.FacilityName;
        // newRow.Qty = row.Qty;
        // newRow.ProfId = 1;

        rows.push(newRow);
      });

      setFacilitiesTableLeftData(rows); //right grid state
      //////////////////////////////////Manage Right Side Grid END//////////////////////////////
    }

  };




       

  const ReceiveDetailsColumns = [
    { title: "TransactionItemId", field: "TransactionItemId", visible: false },
    { title: "id", field: "id", visible: false },
    // {
    //   title: DispensingLanguage[lan][menukey]["Sl#"],
    //   width: 30,
    //   formatter: "rownum",
    //   hozAlign: "center",
    //   headerHozAlign: "center",
    //   headerSort: false,
    // },
    {
      title: DispensingLanguage[lan][menukey]["Item Code"],
      field: "ItemCode",
      width: 90,
      headerFilter: true,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Name"],
      field: "ItemName",
      //width: 150,
      headerFilter: true,
      headerSort: false,
    },
  ];

  const DisapprovedItemsColumns = [
    { title: "id", field: "id", visible: false },
    {
      title: "ProfId",
      field: "ProfId",
      width: 90,
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Code"],
      field: "ItemCode",
      width: 90,
      // headerFilter: true,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Group"],
      field: "GroupName",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Name"],
      field: "ItemName",
      // width: 150,
      // headerFilter: true,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Qty"],
      field: "Qty",
      width: 90,
      hozAlign: "right",
      headerHozAlign: "right",
      editor: cellFocusEditor,
      validator: ["integer", "min:0"],
      cssClass: "tabluator-column-editable",
      headerSort: false,
    },

  ];

  
  const FacilityColumnsLeft = [
    { title: "id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Group"],
      field: "FGroupName",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["SDP Code"],
      field: "FacilityCode",
      width: 120,
      headerFilter: true,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["SDP Name"],
      field: "FacilityName",
      //width: 150,
      headerFilter: true,
      headerSort: false,
    },
  ];



  const FacilityColumnsRight = [
    { title: "id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Group"],
      field: "FGroupName",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["SDP Code"],
      field: "FacilityCode",
      width: 120,
      // headerFilter: true,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["SDP Name"],
      field: "FacilityName",
      //width: 150,
      // headerFilter: true,
      headerSort: false,
    },
  ];
  




  const FacilityAndProductColumnsFinal = [
    { title: "ItemSl", field: "ItemSl", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Facility Code"],
      field: "SDPCode",
      width: 130,
      headerFilter: true,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Name"],
      field: "FacilityName",
      //width: 150,
      headerFilter: true,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Code"],
      field: "ItemCode",
      width: 130,
      headerFilter: true,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Name"],
      field: "ItemName",
      // width: 150,
      // headerFilter: true,
      headerSort: false,
      visible: false
    }, {
      title:  "OldQty",
      field: "OldQty",
      visible: false
    },
    {
      title: DispensingLanguage[lan][menukey]["Qty"],
      field: "Qty",
      width: 110,
      hozAlign: "right",
      headerHozAlign: "right",
      editor: cellFocusEditor,
      validator: ["integer", "min:0"],
      cssClass: "tabluator-column-editable",
      headerSort: false,
    },
  ];
  



  const onSaveUpdateBtnClickStep1 = () => {

    //when select item then able to go NEXT
    if(DisapprovedItemsRef.table.getData().length>0){

      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey]["Do you really want to save those products on your selected profile?"]
        ),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        /* buttons: [
          t(DispensingLanguage[lan][menukey]["No"]),
          t(DispensingLanguage[lan][menukey]["Yes"]),
        ],
        dangerMode: true, */
      }).then((willCancel) => {
        if (willCancel) {
          let ProfileItems = DisapprovedItemsRef.table.getData();
          let fDtat={'ProfType':'update','ProfId':defaultProfId,'NewProfileName':"",'ProfileItems':ProfileItems};
          mutateAddProfile(fDtat); 
          
        }
      });
    

    }

  };



  const onSaveUpdateBtnClickStep2 = () => {

    //when select item then able to go NEXT
    if(FacilityDetailsRefRight.table.getData().length>0){

      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey]["Do you really want to create custom supply plan items for selected facility(s)"]
        ),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        /* buttons: [
          t(DispensingLanguage[lan][menukey]["No"]),
          t(DispensingLanguage[lan][menukey]["Yes"]),
        ],
        dangerMode: true, */
      }).then((willCancel) => {
        if (willCancel) {
          let ProfileFacilities = FacilityDetailsRefRight.table.getData();
          let fDtat={'ProfId':defaultProfId,'ProfileFacilities':ProfileFacilities};
          mutateProfileItemAndFacilityMap(fDtat); 
          
        }
      });
    

    }
    
  };



  const onSaveUpdateBtnClickStep3 = () => {
    props.setUpdatedFacilityProductGridDataHandler(
      FacilityAndProductDetailsRefFinal.table.getData()
    );
  };



  const onProfileChangeEvnt = (e) => {
    setDefaultProfId(e.target.value);
  };

  const addUpazilaProfile = (NewProfileName) => {
      let ProfileItems = [];
      let fDtat={'ProfType':'add','ProfId':0,'NewProfileName':NewProfileName,'ProfileItems':ProfileItems};
      mutateAddProfile(fDtat); 

  };
  
  
  const { isLoading4, mutate:mutateAddProfile } = useMutation(api.addUpazilaProfile, {
    onSuccess: (data) => {
      if (data.status == 200) {
  
        api.getLoginFacilityListNotEqual99ANDSuppliers(options,'SupplyPlanProfileList')
          .then((response) => {
            localStorage.setItem("SupplyPlanProfileList",JSON.stringify(response.datalist.SupplyPlanProfileList));
            
            setDefaultProfId(data.ProfId); // select the new Profile

          });

        setProfileModal(false); // hide modal

      //when press on NEXT button then ProfType = update
        if(data.ProfType === "update"){
          setWizardStep(2); // show step 2
          // const [wizardstep, setWizardStep] = useState(1);
        }

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }

    }
  })


  const copyUpazilaProfile = (NewProfileName) => {
      let ProfileItems = [];
      let fDtat={'ProfType':'copy','ProfId':defaultProfId,'NewProfileName':NewProfileName,'ProfileItems':ProfileItems};
      mutateCopyProfile(fDtat); 

  };

  
  const { isLoading41, mutate:mutateCopyProfile } = useMutation(api.copyUpazilaProfile, {
    onSuccess: (data) => {

      if (data.status == 200) {

        api.getLoginFacilityListNotEqual99ANDSuppliers(options,'SupplyPlanProfileList')
          .then((response) => {
            localStorage.setItem("SupplyPlanProfileList",JSON.stringify(response.datalist.SupplyPlanProfileList));
            
            setDefaultProfId(data.ProfId); // select the new Profile

          });

        setProfileModalCopy(false); // hide modal

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }

    }
  })
  
  const { isLoading9, mutate:mutateProfileItemAndFacilityMap } = useMutation(api.addUpazilaProfileItemAndFacilityMap, {
    onSuccess: (data) => {

      if (data.status == 200) {
        // setProfileModal(false); // hide modal
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        getFacilitiesAndProdFinal();
        setWizardStep(3); // show step 3

      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }

    }
  })



  useEffect(() => {
    getUpazilaProfileItems();
  }, [defaultProfId]);

  

  // useEffect(() => {
  //   if (ReceiveDetailsRef == null) return;
  //   if (props.bFirst) {
  //     setManyTableData(props.ManyTableData);
  //   }

  //   setbStockUpdate(props.bStockUpdate);
  // }, [props.ManyTableData, props.bStockUpdate]);

  // useEffect(() => {
  //   if (ReceiveDetailsRef.table != null && timeStamp != "") {
  //     ReceiveDetailsRef.table.scrollToRow(timeStamp, "center", true);
  //     ReceiveDetailsRef.table.selectRow(timeStamp);
  //   }
  // }, [newBatchRowAdded]);

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12} container spacing={1}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Upazila Custom Supply Plan"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            {/* <Card className="sw_card">
            <CardHeader title="Personal Information" />
            <CardContent> */}

            <Grid container spacing={1}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={1}>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    {/* <TextField
                      id="TransactionId"
                      name="TransactionId"
                      label="TransactionId"
                      value={props.formData.TransactionId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    /> */}
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                { (wizardstep === 1) && <Grid item xs={12} sm={12}>
                {DispensingLanguage[lan][menukey]["Step 1 of 3: Select Products"]}
                  </Grid>
                }
                { (wizardstep === 1) && <Grid item xs={12} sm={12} className="marginBottom10">
                <strong>
                {DispensingLanguage[lan][menukey]["Select Products from left side list or Select from existing Profile. Enter default Qty if Required"]}
                </strong> 
                  </Grid>
                }

                { (wizardstep === 2) && <Grid item xs={12} sm={12}>
                  {DispensingLanguage[lan][menukey]["Step 2 of 3: Select Facility(s)"]}
                </Grid>
                }
                { (wizardstep === 2) && <Grid item xs={12} sm={12} className="marginBottom10">
                <strong>
                  {DispensingLanguage[lan][menukey]["Select Facility(s) name from left side list"]}
                  </strong> 
                </Grid>
                }

                { (wizardstep === 3) && <Grid item xs={12} sm={12}>
                  {DispensingLanguage[lan][menukey]["Step 3 of 3: Customize Supply Plan"]}
                </Grid>
                }
                { (wizardstep === 3) && <Grid item xs={12} sm={12} className="marginBottom10">
                <strong>
                  {DispensingLanguage[lan][menukey]["Update Qty if required"]}
                  </strong> 
                </Grid>
                }


              </Grid>

              <Grid container spacing={3}>
                
                {/* new row */}
                { (wizardstep === 1 || (wizardstep === 2)) && <Grid item xs={5} sm={5}>
                  <Card className="sw_card">
                    <CardContent>

                      {/* Left side product list */}

                     { (wizardstep === 1) && <ReactTabulator
                        ref={(r) => (ReceiveDetailsRef = r)}
                        columns={ReceiveDetailsColumns}
                        data={manyTableData}
                        height="400px"
                        selectable={true}
                        layout={"fitColumns"}
                        options={{
                          groupBy: ["GroupName"],
                          columnCalcs: "both",
                        }}
                      />
                      }



                      {/* Left side facility list */}
                      { (wizardstep === 2) && <ReactTabulator
                        ref={(r) => (FacilityDetailsRefLeft = r)}
                        columns={FacilityColumnsLeft}
                        data={facilitiesTableLeftData}
                        height="400px"
                        selectable={true}
                        layout={"fitColumns"}
                        options={{
                          groupBy: ["FGroupName"],
                          columnCalcs: "both",
                        }}
                      />
                      }

                    </CardContent>
                  </Card>
                </Grid>}

                {/* new row */}

                { (wizardstep === 1 || (wizardstep === 2)) && <Grid item xs={1} sm={1}>
                  <Card className="sw_card">
                    <CardContent>
                      <Grid container className="mb-2">
                        <Grid item xs={12} sm={12}>
                          <div className="float-right sw_btn_control">
                           
                          { (wizardstep === 1) && <Button
                              className="mr-2 marginBottom10"
                              variant="contained"
                              color="primary"
                              onClick={() => ProductShiftLeftToRight()}
                              disabled={permissionType}
                            >
                          
                              {">>"}
                            </Button>
                            }
                          
                            { (wizardstep === 1) && <Button
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              onClick={() => ProductShiftRightToLeft()}
                              disabled={permissionType}
                            >
                              {"<<"}
                            </Button>
                            }



                            { (wizardstep === 2) && <Button
                              className="mr-2 marginBottom10"
                              variant="contained"
                              color="primary"
                              onClick={() => FacilityShiftLeftToRight()}
                              disabled={permissionType}
                            >
                              {">>"}
                            </Button>
                            }

                            { (wizardstep === 2) && <Button
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              onClick={() => FacilityShiftRightToLeft()}
                              disabled={permissionType}
                            >
                              {"<<"}
                            </Button>
                            }
                          </div>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                }

                {/* new row */}
                { (wizardstep === 1 || (wizardstep === 2)) && <Grid item xs={6} sm={6}>
                  <Card className="sw_card">
                    <CardContent>


                      { (wizardstep === 1) && <Grid container className="mb-2">
                        <Grid item xs={6} sm={6}>
                          <div className=" sw_btn_control">
                            <FormControl className={classes.fullWidth}>
                              {/* <InputLabel id="demo-simple-select-helper-label">
                                {
                                  DispensingLanguage[lan][menukey]["Select Profile"]
                                }
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-helper-label"
                                id="ProfId"
                                name="ProfId"
                                value={defaultProfId}
                                fullWidth
                                onChange={(e) => onProfileChangeEvnt(e)}
                              >
                                {SupplyPlanProfileList.map((item, index) => {
                                  return (
                                    <MenuItem value={item.ProfId}>
                                      {item.ProfileName}
                                    </MenuItem>
                                  );
                                })}
                              </Select> */}

                                <Autocomplete
                                  autoHighlight
                                  disableClearable
                                  id="ProfId"
                                  options={SupplyPlanProfileList}
                                  onChange={(event, valueobj) => handleChangeChoosen('ProfId', valueobj, valueobj?valueobj.ProfId:"")}
                                  getOptionLabel={(option) => option.ProfileName}
                                  value={chosenValues['ProfId']}
                                  renderOption={(option) => (
                                  <Typography className="sw_fontSize">{option.ProfileName}</Typography>
                                  )}
                                  renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={DispensingLanguage[lan][menukey]["Select Profile"]}
                                    variant="standard"
                                    id="ProfId"
                                    name="ProfId"
                                    fullWidth
                                  />
                                  )}
                                />
                            </FormControl>
                          </div>
                        </Grid>

                        <Grid item xs={1} sm={1}>
                            <ProfileModal
                              handleClose={handleClose}
                              open={profileModal}
                              addUpazilaProfile={addUpazilaProfile}
                              {...props}
                            />

                            <ProfileModalCopy
                              handleClose={handleClose}
                              open={profileModalCopy}
                              copyUpazilaProfile={copyUpazilaProfile}
                              {...props}
                            />
                        </Grid>
                        <Grid item xs={5} sm={5}>
                          <div className=" sw_btn_control">
                            <Button
                              disabled={permissionType}
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                handleClose("ProfileModalOpen")
                              }
                            >
                              {DispensingLanguage[lan][menukey]["Add New"]}
                            </Button>

                         

                            <Button
                            disabled={permissionType}
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                handleCopy()
                              }
                            >
                              {DispensingLanguage[lan][menukey]["Copy to New"]}
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                      }

                    {/* Right side item list */}
                    { (wizardstep === 1) && <ReactTabulator
                        ref={(r) => (DisapprovedItemsRef = r)}
                        columns={DisapprovedItemsColumns}
                        data={dispprovemanyTableData}
                        selectable={true}
                        height="350px"
                        layout={"fitColumns"}
                        options={{
                          groupBy: ["GroupName"],
                          columnCalcs: "both",
                        }}
                      />
                    }


                       {/* Right side facility list */}
                       { (wizardstep === 2) && <ReactTabulator
                        ref={(r) => (FacilityDetailsRefRight = r)}
                        columns={FacilityColumnsRight}
                        data={facilitiesTableRightData}
                        height="400px"
                        selectable={true}
                        layout={"fitColumns"}
                        options={{
                          groupBy: ["FGroupName"],
                          columnCalcs: "both",
                        }}
                      />
                      }

 

                    </CardContent>
                  </Card>
                </Grid>}



                { (wizardstep === 3) && <Grid item xs={12} sm={12} className=" text-center"> 
                      <ReactTabulator
                      ref={(r) => (FacilityAndProductDetailsRefFinal = r)}
                      columns={FacilityAndProductColumnsFinal}
                      data={facilityAndProductTableDataFinal}
                      height="400px"
                      // selectable={true}
                      layout={"fitColumns"}
                      options={{
                        groupBy: ["ItemName"],
                        columnCalcs: "both",
                      }}
                    />
                </Grid>
                }


                {/* new row */}
                { (wizardstep === 1) && <Grid item xs={12} sm={12} className=" text-center tex_margin">
                  <Button
                  disabled={permissionType}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClickStep1}
                  >
                    {DispensingLanguage[lan][menukey]["Next"]}
                  </Button>
                </Grid>
                }

                { (wizardstep === 2) && <Grid item xs={12} sm={12} className=" text-center">
                  <Button
                  disabled={permissionType}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClickStep2}
                  >
                    {DispensingLanguage[lan][menukey]["Next"]}
                  </Button>
                </Grid>
                }


                { (wizardstep === 3) && <Grid item xs={12} sm={12} className=" text-center">
                  <Button
                  disabled={permissionType}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClickStep3}
                  >
                    {DispensingLanguage[lan][menukey]["Next"]}
                  </Button>
                </Grid>
                }



              </Grid>
            </Grid>

            {/* </CardContent>
          </Card> */}
          </Grid>

          {/* Action buttons */}
        </Grid>
      </div>
    </div>
  );
};

export default UpazilaCustomSupplyPlanEntryItems;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
