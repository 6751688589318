import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import AssetDepartmentFormData from "./AssetDepartmentFormData";

const AddAssetDepartment = (props) => {
  console.log(props.pieceOfState);

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "department-entry";

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;

  const [formData, setFormData] = useState({
    DeptName: "",
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const [open, setOpen] = React.useState(false);
  const [Massage, setMassage] = React.useState(false);

  useQuery(
    ["DepartmentEntry", id],
    () =>
      api.geDepartment(id).then((res) => {
        setFormData(res.data);
      }),
    {
      enabled: Boolean(id),
    }
  );

  // const { isLoading, mutate } = useMutation(api.saveStoreType, {
  //   onSuccess: (data) => {
  //     console.log("data: ", data);

  //     if (data.status == 200) {
  //       // api.getAllDropdown("ABC_List").then((response) => {
  //       //   localStorage.setItem(
  //       //     "ABC_List",
  //       //     JSON.stringify(response.datalist.ABC_List)
  //       //   );
  //       // });

  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.message,
  //         msgtype: data.success,
  //       });
  //       queryClient.getQueriesData("DepartmentEntry"); //api page name
  //       props.history.push("/store-type-entry"); //
  //     } else {
  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.message,
  //         msgtype: data.success,
  //       });
  //       queryClient.getQueriesData("DepartmentEntry"); //api page name
  //       props.history.push("/store-type-entry"); //
  //     }
  //   },
  // });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    console.log("formData: ", formData);
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
    api.dirtyModeEnable();
  };

  const handleReset = () => {
    setFormData({
      DeptName: "",
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["DeptName"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  function checkValidateForm(){

     
    if (validateForm(formData)) {
    let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName,}; 
   
    return fDtat;
    }else{
    return false;
    }

};

  // const handleSubmit = async (e) => {
  //   console.log("from submit ", formData);
  //   if (validateForm(formData)) {
  //     let fDtat = {
  //       ...formData,
  //       menukey: menukey,
  //       lan: lan,
  //       UserName: UserName,
  //     };
  //     mutate(fDtat);
  //   }
  // };

  return (
    <>
      <AssetDepartmentFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        checkValidateForm={checkValidateForm}
        // handleSubmit={handleSubmit}
        bStockUpdate={props.permissionType}
        open={open}
        {...props}
      />
    </>
  );
};

export default AddAssetDepartment;
