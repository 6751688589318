import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Paper,
  CardHeader,
  ButtonGroup,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import ExportButtonsMany from "../../../components/ExportButtonsMany";
// Highcharts component
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

import swal from "sweetalert";
import * as Service from "../../../services/Service";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import { useParams } from "react-router-dom";

import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import SwipeableViews from "react-swipeable-views";
import Link from "@material-ui/core/Link";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import { useTranslation } from "react-i18next";

import { useQuery, useMutation, useQueryClient } from "react-query";

import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit, Widgets } from "@material-ui/icons";
import Tooltip from '@material-ui/core/Tooltip';

import MomentUtils from "@date-io/moment";
import moment from "moment";
import NextPrevBtn from "../../../services/NextPrevBtn";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const EditProduct = (props) => {
  const StockData = JSON.parse(localStorage.getItem("StockData"));
  const StockDataIndex = JSON.parse(localStorage.getItem("StockDataIndex"));

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const FacilityId = localStorage.getItem("FacilityId");
  const StartDate = localStorage.getItem("StartDate");
  const FacilityName = localStorage.getItem("FacilityName");
  // const SourceList = JSON.parse(localStorage.getItem("SourceList"));
  const menukey = "stock-status";

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;
  const CurrentFacilitySetting = JSON.parse(
    localStorage.getItem("CurrentFacilitySetting")
  );

  const [SourceValue, setSourceValue] = useState(0);
  console.log("props: ", props);
  let tblRef = null;
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const { t, i18n } = useTranslation();

  let { id } = useParams();
  console.log("useParams str: ", id);

  const paramsList = id.split("|");
  console.log("useParams paramsList: ", paramsList);
  id = paramsList[0];
  const filterDateMaster = paramsList[1];
  const filterRadioMaster = paramsList[0];
  console.log("useParams id: ", id);

  console.log("useParams filterDateMaster: ", filterDateMaster);

  const queryClient = useQueryClient();

  const [MasterData, setMasterData] = useState({});

  const [lotData, setLotData] = useState([]);

  const [lotData2, setLotData2] = useState([]);
  const [option_linechart, setOption_linechart] = useState([]);

  const { data: data, refetch:refetchLotData } = useQuery(
    ["stock-status", paramsList],
    () =>
      api.getLotDataView(paramsList).then((res) => {
        setMasterData(res.MasterData[0]);
        setLotData(res.LotData);
      }),
    {
      enabled: Boolean(id),
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,
    }
  );

  const [stockCardData, setStockCardData] = useState([]);

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );

  const handleStartDateChange = (date) => {
    let newDate = moment(date).format("YYYY-MM-DD");
    setSelectedStartDate(newDate);
  };
  const handleStartDateChangeChart = (date) => {
    let newDate = moment(date).format("YYYY-MM-DD");
    setSelectedStartDateChart(newDate);
  };

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const [selectedStartDateChart, setSelectedStartDateChart] = React.useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );

  const [selectedEndDateChart, setSelectedEndDateChart] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const UseFor = localStorage.getItem("UseFor");

  let params1 = {
    menukey: menukey,
    FacilityId: FacilityId,
    UseFor: UseFor,
    action: "getStockCardDataView",
    ItemNo: id,
    startDate: selectedStartDate,
    endDate: selectedEndDate,
    SourceId: SourceValue,
  };
  const { data: scData, refetch } = useQuery(
    [params1],
    api.getStockCardDataView,
    {
      onSuccess: (data) => {
        setStockCardData(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,
    },
    {
      enabled: Boolean(id),
    }
  );

  /* =====Start of Print Excel Many Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    let formatedStartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let formatedEndDate = moment(selectedEndDate).format("YYYY-MM-DD");

    let finalUrl = EXCEL_EXPORT_URL + "report/stock_card_print.php";
    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&startDate=" +
        formatedStartDate +
        "&endDate=" +
        formatedEndDate +
        "&FacilityId=" +
        FacilityId +
        "&ItemNo=" +
        id +
        "&FacilityName=" +
        FacilityName +
        "&SourceId=" +
        SourceValue +
        "&UseFor=" +
        UseFor +
        "&TimeStamp=" +
        Date.now()
    );
  };

  /* =====End of Excel Export Code==== */

  const columns = [
    {
      title: "Id",
      field: "id",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      field: "sl",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["lot No"],
      field: "LotNo",
      hozAlign: "left",
      headerHozAlign: "left",
    },
    {
      title: DispensingLanguage[lan][menukey]["Mfg Date"],
      field: "MfgDate",
      hozAlign: "left",
      headerHozAlign: "left",
      width: 150,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry Date"],
      field: "ExpDate",
      hozAlign: "left",
      headerHozAlign: "left",
      width: 150,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        // let curDate = new Date();
        // if(value==null){
        //   cell.getRow().getElement().style.backgroundColor = "#ec7063";
        // }else{

        //   // let curDate = moment(curDate).format("DD/MM/YYYY");
        //   // let ExpDate = moment(value).format("DD/MM/YYYY");

        //   // console.log('curDate: ', curDate);
        //   // console.log('ExpDate: ', ExpDate);
        // }

        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },

      // formatter: function (cell, formatterParams) {
      //   const cellValue = cell.getValue();
      //   const currow = cell.getRow();
      //   const rowdata = currow.getData();

      //   if (cellValue !== "" && cellValue > rowdata.current_balance) {
      //     cell.getRow().getElement().style.backgroundColor = "#ec7063";
      //     return cellValue;
      //   } else {
      //     cell.getRow().getElement().style.backgroundColor = "transparent";
      //     return cellValue;
      //   }
      // },

    },
    // {
    //   title: DispensingLanguage[lan][menukey]["Source Name"],
    //   field: "SourceName",
    //   hozAlign: "left",
    //   headerHozAlign: "left",
    // },
    {
      title: DispensingLanguage[lan][menukey]["Lot Qty"],
      field: "LotQty",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 150,
      bottomCalc: "sum",
      formatter: "money",
      formatterParams: { thousand: ",", precision: false },
      bottomCalcFormatter: "money",
      bottomCalcFormatterParams: {
        thousand: ",",
        precision: false,
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Lot Qty (Unusable)"],
      //field: "UnusableQty",
      field: "LotQtyUn",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 150,
      bottomCalc: "sum",
      formatter: "money",
      formatterParams: { thousand: ",", precision: false },
      bottomCalcFormatter: "money",
      bottomCalcFormatterParams: {
        thousand: ",",
        precision: false,
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      visible: id === "MCH002" && UseFor === "MCWC" ? true : false,
      hozAlign: "center",
      headerHozAlign: "center",
      width: 80,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;

    //console.log("aaaaaaaaaaaaa ",rowData);
    return (
      <Tooltip title={DispensingLanguage[lan][menukey]["Open DDS Kit"]}>
        <Widgets
          onClick={() => {
            lotData2.forEach((row, i) => {
              if (rowData.ItemNo == row.ItemNo) {
                unpackingItem(rowData);
              }
            });
          }}
        />
      </Tooltip>
    );
  }

  const unpackingItem = (data) => {
    console.log("unpackingItem ", data);

    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey]["Do you really want to unpacking?"]
      ),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then((willDelete) => {
      if (willDelete) {
        unpackingApi({
          ItemCode: data.ItemCode,
          LotQty: data.LotQty,
          LotNo: data.LotNo,
          MfgDate: data.MfgDate,
          ExpDate: data.ExpDate,
          ItemListLotId: data.ItemListLotId,
          FacilityId: FacilityId,
          PreparedBy: CurrentFacilitySetting[0].InvPrepBy,
          ApprovedBy: CurrentFacilitySetting[0].InvAppBy,
          IssuedBy: CurrentFacilitySetting[0].InvSupBy,
          menukey: menukey,
          lan: lan,
          UserName: UserName,
          action: "ItemUnpackingAction",
        });
      }
    });
  };

  const { mutate: unpackingApi } = useMutation(api.ItemUnpacking, {
    onSuccess: (data) => {
      if (data.data.status == 200) {
        const idsArray = data.data.ids.split(",");
        const TransactionIdDeduct = idsArray[0];
        const TransactionIdAdd = idsArray[1];

        unpackingPost(
          TransactionIdDeduct
          /* {
          FacilityId: FacilityId,
          TableName: "adjinv",
          TransactionId: TransactionIdDeduct,
          TransactionTypeId: 0,
          menukey: "adjustment",
          lan: lan,
          UserName: UserName,
          action: "SubmitAdjustmentInvoice",
        } */);
        unpackingPost(
          TransactionIdAdd
          /* {
          FacilityId: FacilityId,
          TableName: "adjinv",
          TransactionId: TransactionIdAdd,
          TransactionTypeId: 0,
          menukey: "adjustment",
          lan: lan,
          UserName: UserName,
          action: "SubmitAdjustmentInvoice",
        } */);

        /* refetchLotData();
        queryClient.getQueriesData("StockStatus"); */
        

        /* props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        }); */
      } else {
        /* props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        }); */
      }
    },
  });

  const { mutate: unpackingPost } = useMutation(api.updateAdjustmentFacilityInvoiceStock, {
    onSuccess: (data) => {
      if (data.status== 200) {
         refetchLotData();
         //queryClient.getQueriesData("StockStatus"); 

              swal({
                title: "",
                text: t(
                  DispensingLanguage[lan][menukey]["One DDS Kit unpacked and items added to stock."]
                ),
                icon: "success",
                buttons: {
                  
                  cancel: {
                    text: t(DispensingLanguage[lan][menukey]["Ok"]),
                    value: null,
                    visible: true,
                    className: "",
                    closeModal: true,
                  },
                },
              }).then((willDelete) => {
                if (willDelete) {
                  
                }
              });

      } else {
        /* props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        }); */
      }
    },
  });

  const columns1 = [
    // { title: "Id", field: "id", width: 50, visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      field: "sl",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Receiver"],
      field: "Receiver",
      headerSort: false,
      // width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["Invoice No"],
      field: "InvoiceNo",
      width: 85,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Invoice Date"],
      field: "StockDate",
      width: 100,
      headerSort: false,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Supplier"],
      field: "Supplier",
      width: 100,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Supplier Invoice No"],
      field: "SupRef",
      width: 85,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Supplier Date"],
      field: "SupDate",
      width: 100,
      headerSort: false,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Received"],
      field: "QtyIn",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,
      headerSort: false,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : Number(value).toLocaleString();
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Issued"],
      field: "QtyOut",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 90,
      headerSort: false,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : Number(value).toLocaleString();
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Usable Balance"],
      field: "UsableBalance",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,
      headerSort: false,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : Number(value).toLocaleString();
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Unusable In"],
      field: "UnQtyIn",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 90,
      headerSort: false,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : Number(value).toLocaleString();
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Unusable Out"],
      field: "UnQtyOut",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 90,
      headerSort: false,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : Number(value).toLocaleString();
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Unusable Balance"],
      field: "UnusableBalance",
      hozAlign: "right",
      headerHozAlign: "right",
      width: 90,
      headerSort: false,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : Number(value).toLocaleString();
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Remarks"],
      field: "InvType",
      width: 130,
      headerSort: false,
    },
  ];

  const handleEndDateChange = (date) => {
    let newDate = moment(date).format("YYYY-MM-DD");
    setSelectedEndDate(newDate);
  };
  const handleEndDateChangeChart = (date) => {
    let newDate = moment(date).format("YYYY-MM-DD");
    setSelectedEndDateChart(newDate);
  };

  let params2 = {
    menukey: menukey,
    FacilityId: FacilityId,
    action: "getChartDataView",
    ItemNo: id,
    startDate: selectedStartDateChart,
    endDate: selectedEndDateChart,
  };
  const { data: getChartDataView } = useQuery(
    [params2],
    api.getChartDataView,
    {
      onSuccess: (data) => {
        //  let option_linechart = {
        //       chart: {
        //         type: "line",
        //       },
        //       title: {
        //         text: "",
        //       },
        //       yAxis: {
        //         title: {
        //           text: "",
        //         },
        //       },
        //       xAxis: {
        //         categories: data.item_name ,
        //       },
        //       credits: {
        //         enabled: false,
        //       },
        //       series: [
        //         {
        //           name: "MIN",
        //           data: data.dataValue[0],//[150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150, 150],
        //           color: "#b60024",
        //         },
        //         {
        //           name: "MAX",
        //           data: data.dataValue[1],//[300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300, 300],
        //           color: "#6c6463",
        //         },
        //         {
        //           name: "Stock",
        //           data: data.dataValue[2],//[400, 150, 250, 360, 401, 258, 415, 362, 456, 145, 258, 180],
        //           color: "#002f6c",
        //         },
        //       ],
        //     };
        //    setOption_linechart(option_linechart);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,
    },
    {
      enabled: Boolean(id),
    }
  );

  const [formData, setFormData] = useState({
    productName: "",
    productCode: "",
    productGroup: "",
    price: "",
    key: false,
    productId: "",
  });
  const [groupData, setGroupData] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      productName: "",
      productCode: "",
      productGroup: "",
      price: "",
      key: false,
    });
  };

  const handleSubmit = async (e) => {
    swal("Success!", "", "success");
  };

  useEffect(() => {
    if (tblRef == null) return;
    setLotData2(lotData);
  }, [lotData]);

  const [all, setAll] = React.useState("0");

  const handleChangeSource = (event) => {
    setAll(event.target.value);
    //setLoading(true);
    //console.log(e.target.value);
    setSourceValue(event.target.value);
  };

  const handleIndex = (indx, row) => {
    localStorage.setItem("StockDataIndex", JSON.stringify(indx));
    let route = row.ItemNo + "|" + filterDateMaster + "|" + filterRadioMaster;
    // let route = `${row.ItemNo}`;
    props.history.push(route);
  };

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-1">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Stock Status"]}
              </div>

              <div className="float-right sw_btn_control">
                <Button
                  variant="contained"
                  type="reset"
                  onClick={() => props.history.push("/stock-status")}
                >
                  {DispensingLanguage[lan][menukey]["RETURN TO LIST"]}
                </Button>
              </div>
            </div>
          </Grid>
        </div>

        <NextPrevBtn
          dataList={StockData}
          StockDataIndex={StockDataIndex}
          handleReset={""}
          handleIndex={handleIndex}
          {...props}
        />

        {/* start of form*/}
        <Card className="sw_card mb-2">
          <CardContent>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <ul className="sw_item_list">
                  <li>
                    <strong>
                      {DispensingLanguage[lan][menukey]["Stock Date"]}:
                    </strong>{" "}
                    {moment(filterDateMaster).format("DD/MM/YYYY")}
                  </li>

                  <li>
                    <strong>
                      {DispensingLanguage[lan][menukey]["Group"]}:
                    </strong>{" "}
                    {MasterData.GroupName}
                  </li>
                  <li>
                    <strong>
                      {" "}
                      {DispensingLanguage[lan][menukey]["Product Code"]}:
                    </strong>{" "}
                    {MasterData.ProductCode}
                  </li>
                  <li>
                    <strong>
                      {DispensingLanguage[lan][menukey]["Product Name"]}:
                    </strong>{" "}
                    {MasterData.ProductName}
                  </li>
                  <li>
                    <strong>{DispensingLanguage[lan][menukey]["Unit"]}:</strong>{" "}
                    {MasterData.UnitName}
                  </li>
                  {/* <li>
                    <strong>{DispensingLanguage[lan][menukey]["AMC"]}:</strong>{" "}
                    {MasterData.AMC}
                  </li> */}
                  {/* <li>
                    <strong>
                      {DispensingLanguage[lan][menukey]["Minimum Quantity"]}:
                    </strong>{" "}
                    {MasterData.MinimumQuantity}
                  </li>
                  <li>
                    <strong>
                      {DispensingLanguage[lan][menukey]["Maximum Quantity"]}:
                    </strong>{" "}
                    {MasterData.MaximumQuantity}
                  </li> */}
                  <li>
                    <strong>
                      {DispensingLanguage[lan][menukey]["Lot Qty"]}:
                    </strong>{" "}
                    {MasterData.UsableQty}
                  </li>
                  <li>
                    <strong>
                      {DispensingLanguage[lan][menukey]["Lot Qty (Unusable)"]}:
                    </strong>{" "}
                    {MasterData.UnusableQty}
                  </li>
                  {/* <li>
                    <strong>
                      {DispensingLanguage[lan][menukey]["Unit Price"]}:
                    </strong>{" "}
                    {MasterData.UnitPrice}
                  </li>
                  <li>
                    <strong>
                      {DispensingLanguage[lan][menukey]["Line Total"]}:
                    </strong>{" "}
                    {MasterData.LineTotal}
                  </li> */}
                </ul>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of form*/}

        {/* data grid*/}
        <Grid container spacing={3}>
          {/* new row */}
          <Grid item xs={12} sm={12}>
            <div className={classes.root}>
              <Card className="sw_card">
                <CardContent>
                  <Box sx={{ width: "100%" }}>
                    <AppBar position="static" color="default" className="mb-2">
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                          value={value}
                          onChange={handleTabChange}
                          aria-label="basic tabs example"
                        >
                          <Tab
                            label={DispensingLanguage[lan][menukey]["Lots"]}
                            {...a11yProps(0)}
                          />
                          <Tab
                            label={
                              DispensingLanguage[lan][menukey]["Stock Card"]
                            }
                            {...a11yProps(1)}
                          />
                          {/* <Tab
                            label={
                              DispensingLanguage[lan][menukey]["Stock Chart"]
                            }
                            {...a11yProps(2)}
                          /> */}
                        </Tabs>
                      </Box>
                    </AppBar>

                    <TabPanel value={value} index={0}>
                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableBody>
                            <ReactTabulator
                              ref={(r) => (tblRef = r)}
                              columns={columns}
                              data={lotData2}
                              layout={"fitColumns"}
                              height={350}
                            />
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                      <Grid container spacing={3} className="mb-2">
                        <Grid item xs={2} sm={2}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                              <KeyboardDatePicker
                                className={classes.fullWidth}
                                //disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="date-picker-inline"
                                label={
                                  DispensingLanguage[lan][menukey]["Start Date"]
                                }
                                value={selectedStartDate}
                                onChange={handleStartDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={2} sm={2}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                              <KeyboardDatePicker
                                className={classes.fullWidth}
                                //disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="date-picker-inline"
                                label={
                                  DispensingLanguage[lan][menukey]["End Date"]
                                }
                                value={selectedEndDate}
                                onChange={handleEndDateChange}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Grid>

                        {/* <Grid item xs={2} sm={2}>
                          <FormControl className={classes.fullWidth}>
                            <InputLabel id="demo-simple-select-helper-label">
                              {DispensingLanguage[lan][menukey]["Source"]}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-helper-label"
                              id="SourceId"
                              name="SourceId"
                              value={all}
                              onChange={handleChangeSource}
                              fullWidth
                            >
                            <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>

                              {SourceList.map((item, index) => {
                              
                                return <MenuItem value={item.id}>{item.name}</MenuItem>;
                              })}
                            </Select>
                          </FormControl>
                        </Grid> */}

                        <Grid item xs={8} sm={8}>
                          <div className="float-right sw_btn_control">
                            <ExportButtonsMany
                              PrintMany={PrintMany}
                              {...props}
                            ></ExportButtonsMany>
                            {/* <Button
                              variant="contained"
                              color="primary"
                              className="mr-2 sw_icons"
                              onClick={() => PrintMany()}
                            >
                              <i class="fas fa-print"></i>
                            </Button> */}

                            {/* <Button
                            variant="contained"
                            color="primary"
                            className="mr-2 sw_icons"
                            onClick={() => PrintPDFExcelExportFunction("print")}
                            >
                            <i class="fas fa-print"></i>
                            </Button>

                            <Button
                            variant="contained"
                            color="primary"
                            className="mr-2 sw_icons"
                            onClick={() => PrintPDFExcelExportFunction("excel")}
                            >
                            <i className="far fa-file-excel"></i>
                            </Button> */}
                          </div>
                        </Grid>
                      </Grid>

                      <TableContainer component={Paper}>
                        <Table
                          className={classes.table}
                          aria-label="simple table"
                        >
                          <TableBody>
                            <ReactTabulator
                              columns={columns1}
                              data={stockCardData}
                              layout={"fitColumns"}
                              height={600}
                              // options={{
                              //   groupBy: "ProductFullName",
                              //   columnCalcs: "both",
                              // }}
                            />
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                      <Grid container spacing={3} className="mb-2">
                        <Grid item xs={2} sm={2}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                              <KeyboardDatePicker
                                className={classes.fullWidth}
                                // disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="date-picker-inline"
                                label={
                                  DispensingLanguage[lan][menukey]["Start Date"]
                                }
                                value={selectedStartDateChart}
                                onChange={handleStartDateChangeChart}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Grid>

                        <Grid item xs={2} sm={2}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                              <KeyboardDatePicker
                                className={classes.fullWidth}
                                // disableToolbar
                                autoOk={true}
                                variant="inline"
                                format="dd/MM/yyyy"
                                id="date-picker-inline"
                                label={
                                  DispensingLanguage[lan][menukey]["End Date"]
                                }
                                value={selectedEndDateChart}
                                onChange={handleEndDateChangeChart}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item xs={8} sm={8}></Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12} sm={12}>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={option_linechart}
                          />
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </Box>
                </CardContent>
              </Card>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default EditProduct;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
  th: {
    "border-left": "1px solid red",
  },
});
