import React from "react";
import {
  Container,
  FormControl,
  FormHelperText,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormGroup,
  Checkbox,
  Button,
  TextareaAutosize,
  Switch,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
      width: "40ch",
    },
  },
  checkboxFormControl: {
    marginTop: "3rem",
  },
}));

const EditUser = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState("male");

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const [state, setState] = React.useState({
    user: true,
    admin: false,
    superAdmin: false,
    checkedA: true,
    checkedB: false,
  });

  const handleChange1 = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const { user, admin, superAdmin } = state;

  const handleChange2 = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  let id = props.match.params.id;
  return (
    <Container className="mt-5 bg-white pad-2">
      <h3>Edit User</h3>
      <form className={classes.root} noValidate autoComplete="off">
        <TextField
          error={false}
          id="standard-basic"
          label="Name"
          helperText=""
        />
        <TextField
          error={false}
          id="standard-basic"
          label="Email"
          helperText=""
        />
        <TextField
          error={false}
          id="standard-basic"
          label="Designation"
          helperText=""
        />
        <FormControl component="fieldset" className="mt-5">
          <FormLabel component="legend">Gender</FormLabel>
          <RadioGroup
            aria-label="gender"
            name="gender1"
            value={value}
            onChange={handleChange}
          >
            <FormControlLabel
              value="female"
              control={<Radio />}
              label="Female"
            />
            <FormControlLabel value="male" control={<Radio />} label="Male" />
            <FormControlLabel value="other" control={<Radio />} label="Other" />
          </RadioGroup>
        </FormControl>
        <FormControl
          component="fieldset"
          className={classes.checkboxFormControl}
        >
          <FormLabel component="legend">Assign Role</FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={user} onChange={handleChange1} name="user" />
              }
              label="User"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={admin}
                  onChange={handleChange1}
                  name="admin"
                />
              }
              label="Admin"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={superAdmin}
                  onChange={handleChange1}
                  name="superAdmin"
                />
              }
              label="Super Admin"
            />
          </FormGroup>
          <FormHelperText>Be careful</FormHelperText>
        </FormControl>
        <FormGroup row>
          <FormLabel component="legend">Status</FormLabel>
          <FormControlLabel
            control={
              <Switch
                checked={state.checkedA}
                onChange={handleChange2}
                color="primary"
                name="checkedA"
              />
            }
            label="Active Status"
          />
          <FormControlLabel
            control={
              <Switch
                checked={state.checkedB}
                onChange={handleChange2}
                name="checkedB"
                color="primary"
              />
            }
            label="Block Status"
          />
        </FormGroup>
        <FormLabel component="legend">Description</FormLabel>
        <TextareaAutosize
          aria-label="Description"
          rowsMin={3}
          placeholder="Description"
        />
        <div>
          {" "}
          <Button variant="contained" color="primary">
            Submit
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default EditUser;
