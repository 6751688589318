import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import MaterialTable from "material-table";
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";
import {
  Typography,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import ExportButtons from "../../../components/ExportButtons";

import { DeleteOutline, Edit, ViewList } from "@material-ui/icons";

import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import MomentUtils from "@date-io/moment";
import moment from "moment";

//Services
import * as Service from "../../../services/Service.js";

const EntryeditofContractItems = (props) => {
  const permissionType = props.permissionType;
    //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const FacilityId = localStorage.getItem("FacilityId");
  const menukey = "entry-edit-of-contract-items";
  const FacilityName = localStorage.getItem("FacilityName");

  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;
  const { useState } = React;
//Group working
  const [firstLoad, setFirstLoad] = useState(true);
  const [productGroupData, selectProductGroupData] = useState("all");

const GroupNameList = JSON.parse(localStorage.getItem("GroupNameList"));
GroupNameList.unshift({'id':0,'name':"All"});

const PackageYearList = JSON.parse(localStorage.getItem("PackageYearList"));

const CureentYearList =JSON.parse(localStorage.getItem("CureentYearList"));

const currentValue = (CureentYearList[0].id);
const currentYearName = (CureentYearList[0].name);

const [CurentsYearId, setYearIdData] = useState(currentValue);
//console.log('Test Indentor data',CurentsYearId);

const [CurentsIndentorId, selectIndentorData] = useState(0);
//console.log('curentindentor id',CurentsIndentorId);
let TempItemList = useState([]);
const [IndentorList, setIndentorItem] = useState(TempItemList);
//console.log('Test Indentor data',IndentorList);



const [PackageList, setPackageItem] = useState([]);
const [CurentsPackageId, selectPackageListData] = useState(0);
//console.log('Test Indentor data',CurentsPackageId);

const [CongractList, setContractItem] = useState(TempItemList);
console.log('contract combo Data',CongractList);
const [CureentContractid, setContractLocal] = useState(0);
//console.log('Show Package Data',CureentContract);



  const classes = useStyles();
  const tableRef = useRef();
  const [selectedRow, setSelectedRow] = useState(null);
  const [numberRowPerPage, setNumberRowPerPage] = useState(5);
  const [totalRow, setTotalRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [dataItems, setDataItems] = useState([]);
  const FacilityEmployee =JSON.parse(localStorage.getItem("FacilityEmployee"));
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(90, "days").format("YYYY-MM-DD")
  );
  


  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const [isLoading, setLoading] = useState(true);



  
//====== Start Choosen Dropdown===========

const [chosenValuesIndentor, setChosenValuesIndentor] = useState({
  "IndentorId": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}

});
const [chosenValuesPackage, setChosenValuesPackage] = useState({
  "PackageNo": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}

});
const [chosenValuesContract, setChosenValuesContract] = useState({
  "ContractNo": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}

});
const [chosenValuesGroup, setChosenValuesGroup] = useState({
  "GroupCode": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}

});

const [chosenValuesYear, setChosenValuesYear] = useState({
  "YearId": {"YearId": currentValue,"PackageYear": currentYearName}

});



const handleChangeChoosenIndentor = (name, valueobj, value) => {
  let chosenValuesDataIndentor = { ...chosenValuesIndentor};
  chosenValuesDataIndentor[name] = valueobj;
  setChosenValuesIndentor(chosenValuesDataIndentor); 

  setFirstLoad(true);
  setLoading(false);
  selectIndentorData(value);

  setChosenValuesPackage({
    "PackageNo": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
   }); 

   setChosenValuesContract({
    "ContractNo": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
   }); 

 

};


const handleChangeChoosenPackage = (name, valueobj, value) => {
  let chosenValuesDataPackage = { ...chosenValuesPackage};
  chosenValuesDataPackage[name] = valueobj;
  setChosenValuesPackage(chosenValuesDataPackage); 

  setFirstLoad(true);
  setLoading(false);
  selectPackageListData(value);

  setChosenValuesContract({
    "ContractNo": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
   }); 


};


const handleChangeChoosenContract = (name, valueobj, value) => {
  let chosenValuesDataContract = { ...chosenValuesContract};
  chosenValuesDataContract[name] = valueobj;
  setChosenValuesContract(chosenValuesDataContract); 

  setFirstLoad(true);
  setLoading(false);
  setContractLocal(value);

};

const handleChangeChoosenGroup = (name, valueobj, value) => {
  let chosenValuesDataGroup = { ...chosenValuesGroup};
  chosenValuesDataGroup[name] = valueobj;
  setChosenValuesGroup(chosenValuesDataGroup); 

  setFirstLoad(true);
  setLoading(false);
  selectProductGroupData(value);

};

const handleChangeChoosenYear = (name, valueobj, value) => {
  let chosenValuesDataYear = { ...chosenValuesYear};
  chosenValuesDataYear[name] = valueobj;
  setChosenValuesYear(chosenValuesDataYear); 

  setFirstLoad(true);
  setLoading(false);
  setYearIdData(value);

};


//====== End Choosen Dropdown===========



  let params = {
    action: 'getContrackItemEntryList',
    menukey: menukey,
    FacilityId: FacilityId,
    GroupCode: productGroupData,
    CurentsYearId: CurentsYearId,
    CurentsIndentorId: CurentsIndentorId,
    CurentsPackageId: CurentsPackageId,
    CureentContract: CureentContractid,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",

    //TransactionTypeId: 3,
   
    
  };
  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getContrackItemEntryList,
    {
      onSuccess: (data) => {
          if(firstLoad){
            localStorage.setItem(
              "StockData",
              JSON.stringify(data)
            );
            
            setDataItems(data);
            setLoading(false);
            setFirstLoad(false);
          }
        

      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    setLoading(true);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    setLoading(true);
  };

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
   // let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    //let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");

    window.open(
      finalUrl +
        "?action=contractITemEnteryPrint" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&FacilityId=" +
        FacilityId +
        "&CurentsYearId=" +
        CurentsYearId +
        "&GroupCode=" +
        productGroupData +
        "&CurentsIndentorId=" +
        CurentsIndentorId +
        "&CurentsPackageId=" +
        CurentsPackageId +
        "&CureentContract=" +
        CureentContractid +
        "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e: any, row: any) => {
    let rowData = row._row.data;
    editRecord(rowData);
  };

  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: "4%",
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product"],
      field: "ItemName",
      width: "10%",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Indentor"],
      width: "8%",
      field: "IndentorName",
      headerFilter: true,
    },
    { 
      title: DispensingLanguage[lan][menukey]["Package"], 
      field: "PackageName",
      headerFilter: true,
      width: "10%",
    },
    { 
      title: DispensingLanguage[lan][menukey]["Contract"],
      field: "ContractName",
      width: "32%",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Receiving Warehouse"],
      width: "15%",
      field: "FacilityName",
      headerFilter: true,
    },
    { 
      title: DispensingLanguage[lan][menukey]["Quantity"],
      field: "ItemQty",
      headerFilter: true,
      width: "8%",
      hozAlign: "right",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Unit Price"],
      width: "8%",
      headerFilter: true,
      hozAlign: "right",
      field: "ContractUnitPrice",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },
    },{
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      headerHozAlign: "center",
      hozAlign: "center",
      width: "5%",
      formatter: reactFormatter(<ActionButton />),
    },
  ];


	const handleChange = (event) => {
   // setAll(event.target.value);
      selectProductGroupData(event.target.value);
      setFirstLoad(true);
      setLoading(false);
    };

	

    const onYearChangeEvnt = (event) => {
      setFirstLoad(true);
      setLoading(false);
     // selectYearItemData(event.target.value);
      setYearIdData(event.target.value);
    };


  React.useEffect(() => {
    selectIndentorData(0); 
    IndentorItemList();
  }, [CurentsYearId]);



    const IndentorItemList = () => {  
  
      let IndentorItemList = {action:"getIndentorList", CurentsYearId:CurentsYearId, FacilityId: FacilityId, menukey:""}
  
      Service.default
          .postApi("source/combo_generic.php",IndentorItemList)
          .then((res) => { 

            let All_Indentor_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
            const Indentor_List = [All_Indentor_label].concat(res.datalist); 

            /* setChosenValuesPackage({
              "PackageNo": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
             });  */
            
            setIndentorItem(Indentor_List);

          })
          .catch((err) => {});
    
  }



    const IndentorhandleChange = (event) => {
    
      setFirstLoad(true);
      setLoading(false);
      selectIndentorData(event.target.value);
  
    };


    const PackagehandleChange = (event) => {
      setFirstLoad(true);
      setLoading(false);
      selectPackageListData(event.target.value);
    };

    
  React.useEffect(() => {
    selectPackageListData(0); 
    setPackageItemData();
  }, [CurentsYearId,CurentsIndentorId]);

    const setPackageItemData = () => {  
  
      let getPackegeListParam = {action:"getPackegeList", CurentsIndentorId:CurentsIndentorId,CurentsYearId:CurentsYearId, FacilityId: FacilityId, menukey:""}
  
      Service.default
          .postApi("source/combo_generic.php",getPackegeListParam)
          .then((res) => { 

            let All_Packege_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
            const All_Packege_label_List = [All_Packege_label].concat(res.datalist); 
            setPackageItem(All_Packege_label_List); 
          })
          .catch((err) => {});
    
  }


const ContracthandleChange = (event) => {
  setFirstLoad(true);
  setLoading(false);
  setContractLocal(event.target.value);
};

React.useEffect(() => {
  setContractLocal(0); 
  ContractItem();
}, [CurentsPackageId,CurentsYearId,CurentsIndentorId]);

const ContractItem = () => {  
  
  let CurrentContractParam = {action:"getContractList", CurentsPackageId:CurentsPackageId,CurentsYearId:CurentsYearId,CurentsIndentorId:CurentsIndentorId, FacilityId: FacilityId, menukey:""}

  Service.default
      .postApi("source/combo_generic.php",CurrentContractParam)
      .then((res) => { 

        let All_Contract_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
        const All_Contract_label_List = [All_Contract_label].concat(res.datalist); 
        setContractItem(All_Contract_label_List); 

      })
      .catch((err) => {});

}







  const handleChangePage = (data) => {
    setPageNumber(data);
    // setFirstLoad(false);
  };
  const handleChangeRowPerPage = (data) => {
    setNumberRowPerPage(data);
  };
  
  const editRecord = (data) => {
    console.log('data d',data);
    let route = `entry-edit-of-contract-items/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteRecord = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Once deleted, you will not be able to recover this record!"
        ]
      ),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willDelete) => {
      if (willDelete) {
       // mutate(data.id);
      }
    });
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    // const cellValue = props.cell._cell.value || "Edit | Show";

    if (rowData.bStockUpdated || rowData.BCancel || permissionType){
      return (
        <>
          <ViewList
            onClick={() => {
              editRecord(rowData);
            }}
          />
        </>
      );
    }else{
      return (
        <>
          <Edit
            onClick={() => {
              editRecord(rowData);
            }}
          />
          {/* <DeleteOutline
            onClick={() => {
              deleteRecord(rowData);
            }}
          /> */}
        </>
      );
    }
  }

  return (
    <>
      <div className={classes.productPageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Contract Items Entry"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                    {/* <InputLabel id="demo-simple-select-helper-label">
                      {DispensingLanguage[lan][menukey]['Select Year']}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="YearId"
                      name="YearId"
                      value={CurentsYearId}
                      fullWidth
                      onChange={onYearChangeEvnt}
                    >
                      {PackageYearList.map((item, index) => {
                        return (
                          <MenuItem value={item.YearId}>
                            {item.PackageYear}
                          </MenuItem>
                        );
                      })}
                    </Select> */}

                        <Autocomplete
                          autoHighlight
                          disableClearable
                          id="YearId"
                          options={PackageYearList}
                          onChange={(event, valueobj) => handleChangeChoosenYear('YearId', valueobj, valueobj?valueobj.YearId:"")}
                          getOptionLabel={(option) => option.PackageYear}
                          value={chosenValuesYear['YearId']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.PackageYear}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Select Year"]}
                            variant="standard"
                            id="YearId"
                            name="YearId"
                            fullWidth
                          />
                          )}
                        />
                </FormControl>
              </Grid>
                <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                   {/*  <InputLabel id="demo-simple-select-helper-label">
                    { DispensingLanguage[lan][menukey]['Select Indentor'] } 
                    </InputLabel>
                        <Select
                          required
                          labelId="demo-simple-select-helper-label"
                          id="IndentorId"
                          name="IndentorId"
                        value={CurentsIndentorId}
                          fullWidth
                          onChange={IndentorhandleChange}
                        >
                        <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                        {IndentorList.map((item, index) => {
                            return (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            );
                          })}
                        </Select> */}

                        <Autocomplete
                          autoHighlight
                          disableClearable
                          id="IndentorId"
                          options={IndentorList}
                          onChange={(event, valueobj) => handleChangeChoosenIndentor('IndentorId', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValuesIndentor['IndentorId']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Select Indentor"]}
                            variant="standard"
                            id="IndentorId"
                            name="IndentorId"
                            fullWidth
                          />
                          )}
                        />

                        </FormControl>
                </Grid>
                <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                    {/* <InputLabel id="demo-simple-select-helper-label">
                    { DispensingLanguage[lan][menukey]['Select Package'] } 
                    </InputLabel>
                    <Select
                      required
                      labelId="demo-simple-select-helper-label"
                      id="PackageNo"
                      name="PackageNo"
                     value={CurentsPackageId}
                      fullWidth
                      onChange={PackagehandleChange}
                    >
                      <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                    {PackageList.map((item, index) => {
                      return (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      );
                    })}
                  </Select> */}

                        <Autocomplete
                          autoHighlight
                          disableClearable
                          id="PackageNo"
                          options={PackageList}
                          onChange={(event, valueobj) => handleChangeChoosenPackage('PackageNo', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValuesPackage['PackageNo']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Select Package"]}
                            variant="standard"
                            id="PackageNo"
                            name="PackageNo"
                            fullWidth
                          />
                          )}
                        />


                        </FormControl>
                      </Grid>
                <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                   
                    {/* <InputLabel id="demo-simple-select-helper-label">
                    { DispensingLanguage[lan][menukey]['Select Contract'] } 
                    </InputLabel>
                    <Select
                      required
                      labelId="demo-simple-select-helper-label"
                      id="ContractNo"
                      name="ContractNo"
                     value={CureentContractid}
                      fullWidth
                      onChange={ContracthandleChange}
                    >
                  <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                   {CongractList.map((item, index) => {
                      return (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      );
                    })}
                  </Select> */}

                          <Autocomplete
                            autoHighlight
                            disableClearable
                            id="ContractNo"
                            options={CongractList}
                            onChange={(event, valueobj) => handleChangeChoosenContract('ContractNo', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValuesContract['ContractNo']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Select Contract"]}
                              variant="standard"
                              id="ContractNo"
                              name="ContractNo"
                              fullWidth
                            />
                            )}
                          />

                        </FormControl>
                      </Grid>
                <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                 {/*  <InputLabel id="">
                    {DispensingLanguage[lan][menukey]["Select Product Group"]} 
                  </InputLabel>
                  <Select
                    labelId="GroupCode"
                    id="GroupCode"
                    name="GroupCode"
                   //s value={all}
                   defaultValue='0'
                    fullWidth
                    onChange={handleChange}
                  >
                    {GroupNameList.map((item, index) => {
                      return (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      );
                    })}
                  </Select> */}

                        <Autocomplete
                          autoHighlight
                          disableClearable
                          id="GroupCode"
                          options={GroupNameList}
                          onChange={(event, valueobj) => handleChangeChoosenGroup('GroupCode', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValuesGroup['GroupCode']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Select Product Group"]}
                            variant="standard"
                            id="GroupCode"
                            name="GroupCode"
                            fullWidth
                          />
                          )}
                        />
                </FormControl>

                      </Grid>

              <Grid item xs={2} sm={2}>
                <div className="float-right sw_btn_control">
                  <Button
                  disabled={permissionType}
                    color="info"
                    className="mr-2"
                   
                    onClick={() => {
                      if(CureentContractid>0){
                        props.history.push("/entry-edit-of-contract-items/add/"+CureentContractid);
                      }else{
                        props.openNoticeModal({
                          isOpen: true,
                          msg: DispensingLanguage[lan][menukey]["Please select an Contract"],
                          msgtype: 0,
                        });
                       // alert("Please Select the Contract");
                      }
                      
                    }}
                   
                  >
                    {DispensingLanguage[lan][menukey]["Add New"]}
                  </Button>

                  <ExportButtons PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}  {...props}></ExportButtons>
                  {/* <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button> */}
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="450px"
              options={{
                groupBy: "GroupName",
                columnCalcs: "both",
              }}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EntryeditofContractItems;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
