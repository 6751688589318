import React from "react";

import * as Service from "../../../services/Service.js";

// materialui table
import MaterialTable from "material-table";
// import tableIcons from '../../../assets/icons/materialIcons.js'

import { forwardRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Modal, Fade, Backdrop } from "@material-ui/core";
import "../common.css";

import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  UncontrolledTooltip,
} from "reactstrap";

import swal from "sweetalert";

import {MultiSelect} from "react-multi-select-component";
// import { MultiSelect } from "@progress/kendo-react-dropdowns";
// import { makeStyles } from '@material-ui/core/styles';
// import InputLabel from '@material-ui/core/InputLabel';
// import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControl from '@material-ui/core/FormControl';
// import Select from '@material-ui/core/Select';

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid lightgrey",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "50rem",
    borderRadius: "10px",
  },
  pageSet: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
}));

const userData = [
  {
    id: 1,
    name: "Ashutosh",
    surname: "Ghosh",
    designation: "Developer",
    roles: ["Admin", "User"],
    permissions: ["View", "Edit", "Read"],
  },
  {
    id: 2,
    name: "Ashok",
    surname: "Kumar",
    designation: "Developer",
    roles: ["User"],
    permissions: ["View"],
  },
  {
    id: 3,
    name: "John",
    surname: "Doe",
    designation: "Associate",
    roles: ["User"],
    permissions: ["View"],
  },
  {
    id: 4,
    name: "Test",
    surname: "Data",
    designation: "test",
    roles: ["User"],
    permissions: ["View"],
  },
];

// const useStyles = makeStyles((theme) => ({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }));

const Users = (props) => {
  const { useState } = React;
  const classes = useStyles();
  const [age, setAge] = React.useState("");
  const [temp, setTemp] = useState("");
  const [open, setOpen] = React.useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [Users, setUsers] = useState([]);
  const [Roles, setRolesData] = useState([]);
  const [RoleOption, setRoleOption] = useState([]);
  const [rData, setRowData] = useState();
  const [rSelectData, setrSelectData] = useState([]);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  React.useEffect(() => {
    loadData();
    loadRolesData();
  }, []);

  const loadData = async () => {
    let userId = JSON.parse(sessionStorage.getItem("User_info"))
      ? JSON.parse(sessionStorage.getItem("User_info"))[0].id
      : null;
    if (userId) {
      const body = {
        user_id: userId,
      };
      await Service.default
        .postAuthApi("source/users/", body)
        .then((res) => {
          if (res.status == 200) {
            setUsers(res.usersdata);
          } else if (res.message == "Unauthorized") {
            swal("Oops! token expired", `Please login again`, "error");
            props.history.push("/login");
            sessionStorage.clear();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const loadRolesData = () => {
    Service.default
      .getAuthApi("source/roles/")
      .then((res) => {
        if (res.status == 200) {
          setRolesData(res.rolesdata);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const handleDelete = (i, e) => {
  //   let arr = Users;
  //   arr.map((row) => {
  //     if (row.id == e.id) {
  //       row.roles.splice(i, 1);
  //     }
  //   });

  // };

  const deleteUser = (data) => {
    swal("Poof! User has been deleted!", {
      icon: "success",
    });
  };

  const getSeprated = (e) => {
    let arr = e;
    return arr.map((row, i) => {
      // row.indexOf(",")>0?row.replace(",",""):null;
      return i == arr.length - 1 ? row : row + " ";
    });
  };

  const handleOpen = (rowData) => {
    setRowData(rowData);
    let a = [];
    let arr = Roles;
    for (var i = 0; i < arr.length; i++) {
      a.push({ label: arr[i].role, value: arr[i].id });
    }
    setRoleOption(a);
    let b = [];
    let arr1 = rowData.role;
    for (var i = 0; i < arr1.length; i++) {
      b.push({ label: arr1[i].role, value: arr1[i].id });
    }
    setrSelectData(b);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const editRoles = (event) => {
    let arr = [];
    let a = rSelectData;
    for (var i = 0; i < a.length; i++) {
      arr.push(a[i].value);
    }
    if (rData) {
      swal("Success","", "success");
      // const body = {
      //   name: rData.name,
      //   user_id: rData.id,
      //   email: rData.email,
      //   role: arr,
      //   designation: rData.designation,
      // };
      // Service.default
      //   .postAuthApi("source/users/update.php", body)
      //   .then((res) => {
      //     if (res.status == 200) {
      //       setOpen(false);
      //       loadData();
      //       loadRolesData();
      //       swal("Success", `${res.message}`, "success");
      //     } else {
      //       setOpen(false);
      //       swal("Oops", `${res.message}`, "error");
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //     setOpen(false);
      //     swal("Oops", `Something went wrong!`, "error");
      //   });
    }
  };

  return (
    <>
      <div className={classes.pageSet}>
        <div>
          <h3>User List</h3>
        </div>

        <div className="uidash">
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div className={classes.paper}>
                <h3 id="transition-modal-title">Edit Role</h3>
                <div id="transition-modal-description">
                  <div
                    style={{
                      padding: "1rem",
                    }}
                    className="row"
                  >
                    <MultiSelect
                      className="width_80"
                      options={RoleOption}
                      value={rSelectData}
                      textField="role"
                      dataItemKey="id"
                      onChange={setrSelectData}
                      labelledBy="Roles"
                    />
                  </div>
                  <div className="mt-5 text-end">
                    <Button onClick={editRoles} color="info">
                      Update Roles
                    </Button>
                    <Button color="secondary" onClick={handleClose}>
                      <i className="now-ui-icons ui-1_simple-remove mr-1"></i>
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>
          <div className="uniqueName">
            <MaterialTable
              title="Users"
              icons={tableIcons}
              columns={[
                { title: "Name", field: "name" },
                { title: "Email", field: "email" },
                { title: "Designation", field: "designation" },
                {
                  title: "Roles",
                  field: "role",
                  render: (rowData) => {
                    return rowData.role.map((r, i) =>
                      rowData.role.length != 0 && rowData.role ? (
                        <>
                          {i == rowData.role.length - 1 ? (
                            <> {r.role}</>
                          ) : (
                            <>{r.role},</>
                          )}
                        </>
                      ) : (
                        <>No data</>
                      )
                    );
                  },
                },
                {
                  title: "Pages",
                  field: "menu",
                  render: (rowData) => {
                    return rowData.menu.map((r, i) =>
                      rowData.menu.length != 0 && rowData.menu ? (
                        <>
                          {i == rowData.menu.length - 1 ? (
                            <> {r.title}</>
                          ) : (
                            <>{r.title},</>
                          )}
                        </>
                      ) : (
                        <>No data</>
                      )
                    );
                  },
                },
              ]}
              data={Users}
              options={{
                rowStyle: (rowData) => ({
                  backgroundColor:
                    selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
                }),
                headerStyle: { position: "sticky", fontWeight: "bold" },
                search: true,
                exportButton: false,
                sorting: true,
                actionsColumnIndex: -1,
              }}
              onRowClick={(evt, selectedRow) =>
                setSelectedRow(selectedRow.tableData.id)
              }
              actions={[
                {
                  icon: () => {
                    return <i className="fa fa-print" aria-hidden="true"></i>;
                  },
                  tooltip: "Print page",
                  position: "toolbar",
                  onClick: () => {
                    window.print();
                  },
                },

                {
                  icon: tableIcons.Add,
                  tooltip: "Edit Role",
                  onClick: (event, rowData) => handleOpen(rowData),
                },
                {
                  icon: tableIcons.Edit,
                  tooltip: "Edit User",
                  onClick: (event, rowData) =>
                    swal(
                      "Coming Soon!",
                      `This feature is under progress!`,
                      "warning"
                    ),
                },
                {
                  icon: tableIcons.Delete,
                  tooltip: "Delete User",
                  onClick: (event, rowData) => deleteUser(rowData),
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Users;
