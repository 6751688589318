import React, { forwardRef, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Grid, Checkbox, TextField, CardContent } from "@material-ui/core";

import ExportButtons from "../../../components/ExportButtons";

import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import moment from "moment";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";



let tblMenuRef = null;


const Slow_Moving_Item_Report = (props) => {
  //get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "slow-moving-item-list-report";
const UserInfo = sessionStorage.getItem("User_info")? JSON.parse(sessionStorage.getItem("User_info")): 0;  
const UserName = UserInfo==0?'': UserInfo[0].id;
const FacilityId = localStorage.getItem("FacilityId");
  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const [dataItems1, setDataItems1] = useState([]);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const [selectedRoleData, setSelectedRole] = useState(1);

  const [selectedrow, setSelectedrow] = useState(false);
  
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const { isLoading, error, isError, data, refetch } = useQuery(
    "roleaccess",
    api.getslowmovingitemlist,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setSelectedrow(true);
      },
    }
  );

  let params = {
    menukey: menukey,
    FacilityId: FacilityId,
   // ItemCode: ItemCode,
    action: "getslowmoningDetails",
    ItemCode: selectedRoleData,
  };

  const {
    isLoading1,
    error1,
    isError1,
    data: menuData,
    refetch: MenuRefetch,
  } = useQuery([params], api.getslowmoningDetils, {
    onSuccess: (menuData) => {
      setDataItems1(menuData);
      
      
    },
  });

  const rowClick = (e, row) => {
    let rowData = row._row.data;
    console.log(rowData.ItemCode);
    setSelectedRole(rowData.ItemCode);
    //MenuRefetch();
  };


/* =====Start of Excel Export Code==== */
const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

const PrintPDFExcelExportFunction = (reportType) => {
  let formatedDate = moment(selectedStartDate).format("YYYY-MM-DD");
  let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

  window.open(
    finalUrl +
      "?action=SlowmovieExport" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&StartDate=" +
      formatedDate +
      "&FacilityId=" +
      FacilityId +
       "&TimeStamp=" + Date.now()
  );
};



 /*  function RowInputData(props, type = null) {
    const rowData = props.cell._cell.row.data;
    let fieldName = props.field;
    let fieldType = props.fieldType;
    let labelName = props.labelName;
    let dropDownKey = props.dropDownKey;

    switch (fieldType) {
      case "check-box":
        return rowData.type == "bDispensing" ? (
          <></>
        ) : (
          <Checkbox
            color="primary"
            checked={rowData[fieldName]}
            name={fieldName}
            value={rowData[fieldName]}
          />
        );
        break;

      default:
        return <></>;
        break;
    }
    return <></>;
  } */

  useEffect(() => {
    console.log("useEffect calling for refresh");
    if (selectedRoleData > 0) {
      MenuRefetch();
    }
  }, [selectedRoleData]);

  const columns = [
    {
      title: "Id",
      field: "id",
      visible: false,
    },
    // {
    //   title: DispensingLanguage[lan][menukey]["Sl#"],
    //   hozAlign: "center",
    //   width: 30,
    //   formatter: "rownum",
    //   headerHozAlign: "center",
    //   headerSort: false,
    // },
    {
      title: DispensingLanguage[lan][menukey]["item_code"],
      field: "GroupName",
      headerFilter: true,
      //width: 165,
      frozen:true,
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["item_code"],
      field: "ItemCode",
      //headerFilter: true,
      width: "12%",
    },
    {
      title: DispensingLanguage[lan][menukey]["item_name"],
      field: "ItemName",
      headerFilter: true,
      width: "30%",
    }, 
    {
      title: DispensingLanguage[lan][menukey]["qty"],
      field: "Qty",
      hozAlign: "right",
      headerHozAlign: "right",
      headerFilter: false,
      width: "8%",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["last_receive_data"],
      field: "ReceivedDate1",
      hozAlign: "left",
      headerHozAlign: "center",
     // headerFilter: true,
      width: "15%",
    },
    {
      title: DispensingLanguage[lan][menukey]["last_issue_date"],
      field: "IssuedDate",
      //headerFilter: true,
      width: "15%",
    }, 
    {
      title: DispensingLanguage[lan][menukey]["unit"],
      field: "UnitName",
      headerSort: false,
      width: "8%",
    }, 
    {
      title: DispensingLanguage[lan][menukey]["not_Moving_day"],
      field: "days",
      headerSort: false,
      hozAlign: "right", 
      width: "12%",
    }, 

  ];

  const columns1 = [
    { title: "Id", field: "id", width: 50, visible: false },
    {
      title: DispensingLanguage[lan][menukey]["invoice"],
      field: "InvoiceNo",
      headerFilter: false,
      headerSort: false,
      width: "11%",
     
    },
    { title: DispensingLanguage[lan][menukey]["issue_Date"],
     field: "IssuedDate",
     headerSort: false,
     width: "16%",
     },
    { title: DispensingLanguage[lan][menukey]["Receiver"],
     field: "Receiver",
     width: "33%",
     },
     { title: DispensingLanguage[lan][menukey]["lotno"],
     field: "LotNo",
     headerSort: false,
     width: "12%",
     },
    // { title: DispensingLanguage[lan][menukey]["mfg.date"],
    //  field: "MfgDate",
    //  headerSort: false,
    //  visible: false,
    //  },
    { title: DispensingLanguage[lan][menukey]["Expiry_date"],
     field: "ExpDate",
     
     width: "14%",
     },

    { title: DispensingLanguage[lan][menukey]["Issue qty"],
     field: "IssueQty",
     width: "14%",
     hozAlign: "right", 
     headerHozAlign: "right", 
     },
  ];


  
  useEffect(() => {
    if(selectedrow == true){
      tblMenuRef.table.selectRow(1);
    }
  }, [selectedrow]);


  return (
    <>
      <div className={classes.dispenserPageTitle}>
        <div className="d-flex justify-dispenser mb-2">
          <Grid item xs={12} sm={10}>
            <div className="sw_page_heading">
              <div className="sw_heading_title"> {DispensingLanguage[lan][menukey]["Slow Moving item List"]}</div>
                </div>
          </Grid>
          <Grid item xs={2} sm={2}>
                <div className="float-right sw_btn_control">
                
                <ExportButtons PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}  {...props}></ExportButtons>
                  {/* <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button> */}
                </div>
              </Grid>
        </div>
        <CardContent>
          <Grid container spacing={10}>
            <Grid item xs={4} sm={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <div className="uniqueName">
                    <ReactTabulator 
                    ref={(r) => (tblMenuRef = r)}
                      options={{
                        selectable:1,
                        selectableRangeMode: 'click',
                         groupBy: "GroupName",
                          columnCalcs: "both",
                          sorter:"date",
                       }}
                      columns={columns}
                      data={dataItems}
                      layout={"fitColumns"}
                      height="600px"
                      rowClick={(e, row) => rowClick(e, row)}
                      
                    />
                  </div>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <div className="uniqueName">
                    <ReactTabulator
                      columns={columns1}
                      data={dataItems1}
                      layout={"fitColumns"}
                      height={"600px"}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </div>
    </>
  );
};

export default Slow_Moving_Item_Report;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
