import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles"; 
import * as api from "../../../actions/api";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  Snackbar,
  Stack 
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import {  useQuery,  useMutation,  useQueryClient} from 'react-query';


const NationalSupplierFormData = ({
  errorObject,
  addNationalSupplierForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  open,
  ...props
}) => {
    //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "national-supplier";
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  
  const backtolist=()=>{


    let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
    let idx = master_dirty_message_class.indexOf("dnone");
    if(idx === -1){
      // start Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          props.history.push("/national-supplier");
        }
      });
      // End Alert
    }else{
      props.history.push("/national-supplier");
    }
  
  }


  const onSaveUpdateBtnClick = () => {

    setUpdatedDetailsGridDataHandler();
  };
  
  
  const setUpdatedDetailsGridDataHandler = () => {
    let validForm = props.checkValidateForm();
   
    // console.log('isval: ', validForm);
      if (validForm) {
  
        if(addNationalSupplierForm){
          //when save
          mutate(validForm);
          
        }else{
          //when update
          mutateUpdate(validForm); 
        }
        
  
        
      }
  };
  


  const { isLoading : isLoadingSave, mutate } = useMutation(api.saveNationalSupplier, {
    onSuccess: (data) => {
      console.log("data: ", data);

      if (data.status == 200) {

        const auth_token = sessionStorage.getItem("token")
        ? sessionStorage.getItem("token")
        : null;
        let options = {};
        options = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth_token}`,
          },
          task:'UOM'
        };


        api.getLoginFacilityListNotEqual99ANDSuppliers(options,'ALL')
        .then((response) => {

          localStorage.setItem(
            "SupplierListNational",
            JSON.stringify(response.datalist.SupplierListNational)
          );

        });


        // api.getAllDropdown("ABC_List").then((response) => {
        //   localStorage.setItem(
        //     "ABC_List",
        //     JSON.stringify(response.datalist.ABC_List)
        //   );
        // });

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        queryClient.getQueriesData("NationalSupplier"); //api page name
        props.history.push("/national-supplier"); //
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        queryClient.getQueriesData("NationalSupplier"); //api page name
        props.history.push("/national-supplier"); //
      }
    },
  });

  const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.updateNationalSupplier, {
    onSuccess: (data) => { 
      if (data.status == 200) {


        
        const auth_token = sessionStorage.getItem("token")
        ? sessionStorage.getItem("token")
        : null;
        let options = {};
        options = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth_token}`,
          },
          task:'UOM'
        };


        api.getLoginFacilityListNotEqual99ANDSuppliers(options,'ALL')
        .then((response) => {

          localStorage.setItem(
            "SupplierListNational",
            JSON.stringify(response.datalist.SupplierListNational)
          );

        });
        
        // api.getAllDropdown("ABC_List").then((response) => {
        //   localStorage.setItem(
        //     "ABC_List",
        //     JSON.stringify(response.datalist.ABC_List)
        //   );
        // });

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });

        queryClient.getQueriesData("NationalSupplier"); // include the page name
        props.history.push("/national-supplier"); //page url
        api.dirtyModeDisable();
      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });


  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["National Supplier Form - Add/Edit"]}
              </div>
              </div>
              <div className="float-right sw_btn_control">
                {addNationalSupplierForm ? (
                  <Grid item xs={12} className="text-center">
                    <div className="float-right sw_btn_control">
                      <Button
                        disabled={props.bStockUpdate}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button>
                      <Button
                        disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onSaveUpdateBtnClick}
                        // onClick={() => handleSubmit()}
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        //onClick={() => props.history.push("/menu-entry")}
                        onClick={() => backtolist()}
                      >
                        {DispensingLanguage[lan][menukey]["Cancel"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="mt-2 text-center">
                    <Button
                        disabled={props.bStockUpdate || isLoadingUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                      // onClick={() => handleUpdate()}
                    >
                      {DispensingLanguage[lan][menukey]["update"]}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={() => props.history.push("/menu-entry")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Cancel"]}
                    </Button>
                  </Grid>
                )}
              </div>
            
               <div className="mr-4 float-right mt-15">
                <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
              </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardContent>
                  <Grid container spacing={10}>
                  <Grid item xs={4} sm={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.SupName}
                          helperText={errorObject.SupName}
                          required
                          disabled={props.permissionType}
                          id="SupName"
                          name="SupName"
                          label= {DispensingLanguage[lan][menukey]['Supplier_Name'] }
                          value={formData.SupName}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid> 
                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.Address}
                          helperText={errorObject.Address}
                          id="Address"
                          name="Address"
                          disabled={props.permissionType}
                          label= {DispensingLanguage[lan][menukey]['Address'] }
                          value={formData.Address}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid> 
                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.Phone}
                          helperText={errorObject.Phone}
                          id="Phone"
                          name="Phone"
                          disabled={props.permissionType}
                          label= {DispensingLanguage[lan][menukey]['Phone'] }
                          value={formData.Phone}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid> 
                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.Fax}
                          helperText={errorObject.Fax}
                          id="Fax"
                          name="Fax"
                          disabled={props.permissionType}
                          label= {DispensingLanguage[lan][menukey]['Fax'] }
                          value={formData.Fax}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid> 
                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.EMail}
                          helperText={errorObject.EMail}
                          id="EMail"
                          name="EMail"
                          disabled={props.permissionType}
                          label= {DispensingLanguage[lan][menukey]['Email'] }
                          value={formData.EMail}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/* New Row */}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default NationalSupplierFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
