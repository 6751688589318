import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody"; 

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "products";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const SingleProductLotModal = ({ handleClose, open, changeProductLot,manyTableCurrentRow,FacilityId, ...props }) => {
  console.log('manyTableCurrentRow SingleProductLotModal: ', manyTableCurrentRow);

  let tblLotRef = null;

  const [tabledata, setTabledata] = useState([]); 
  const { isLoading, error, isError, data, refetch } =  useQuery(['single_products_lot', manyTableCurrentRow], () =>
    api.getSingleProductLots(manyTableCurrentRow).then((res) => { 
      setTabledata(res); 
      return res.data
    }), {
    enabled: true
    // enabled: Boolean(FacilityId)
  })



  const [selectedProducts, setselectedProducts] = useState([]);

  const ProductColumns = [
    { title: "id", field: "ItemNo", visible: false },
    { title: "ItemNo", field: "ItemNo", visible: false },

    { title: DispensingLanguage[lan][menukey]['Sl#'],  formatter: "rownum", width:50, hozAlign: "center" , headerHozAlign:"center", headerSort:false},
    { title: DispensingLanguage[lan][menukey]['Item Code'], field: "ItemCode", width: 120, headerFilter:true },
    { title: DispensingLanguage[lan][menukey]['Item Name'], field: "ItemName", headerFilter:true  },
    {
      title: DispensingLanguage[lan][menukey]['Lot No'],
      field: "BatchNo",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]['Mfg. Date'],
      field: "MfgDate",
      width: 100 
    },
    {
      title: DispensingLanguage[lan][menukey]['Expiry Date'],
      field: "ExpiryDate",
      width: 100 
    },
    {
      title: DispensingLanguage[lan][menukey]['Lot Qty'],
      field: "CurrentStockQty",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: "money",
      formatterParams:{ thousand:",",  precision:false },
       
    },
    { 
      title: DispensingLanguage[lan][menukey]['Unit'],
       field: "UnitName",
       width: 80
    },
    { 
      title: "ItemListLotId",
       field: "ItemListLotId",
       width: 80,
        visible: false
    }
  ];

  
  const ProdustDataItemsLot = tabledata?.map((item, index) => { 
    return item;
  });

  const [btnDisable, setbtnDisable] = useState(false);
  const SaveSelectedProducts = () => { 
    if(tblLotRef.table.getSelectedData().length>0){
      setbtnDisable(true);
      changeProductLot(tblLotRef.table.getSelectedData());  
      setTimeout(() => setbtnDisable(false), 500);
    }  
  };

  const checkSelect = (row) => { 
    console.log(row);
    console.log("row");
  };

  useEffect(() => { 
    if (tblLotRef == null) return;
    


}, []); 
  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose('ProductsLotChange')}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="md" 
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('ProductsLotChange')}
        >
          {DispensingLanguage[lan][menukey]['Item Lot']} 
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <ReactTabulator
            ref={(rf) => (tblLotRef = rf)}
            options={{
              selectableRangeMode: 'click',
             }}
            selectable={1}
            columns={ProductColumns}
            data={ProdustDataItemsLot}
            height="300px"
            layout={"fitColumns"}
            //selectableCheck={(row)=>checkSelect(row)}


          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={btnDisable}
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={SaveSelectedProducts}
          >
            {DispensingLanguage[lan][menukey]['Select']}  
          </Button>
          <Button
            autoFocus
            className="float-left"
            variant="contained" 
            onClick={() => handleClose('ProductsLotChange')}
          >
           {DispensingLanguage[lan][menukey]['Cancel']}   
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default SingleProductLotModal;
