import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import YearFormData from "./YearFormData";

const AddYear = (props) => {
  console.log(props.pieceOfState);

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "year";

  const { t, i18n } = useTranslation();

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;

  const [formData, setFormData] = useState({
    DeadLine: "",
    PackageYear: "",
    CurrentYear: "",
    bCatalogLocked:""
  });

  const [selectedDate, setSelectedDate] = useState(
    new Date()
  );
  
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const [open, setOpen] = React.useState(false);
  const [Massage, setMassage] = React.useState(false);

  useQuery(
    ["Year", id],
    () =>
      api.getYear(id).then((res) => {
        setFormData(res.data);
      }),
    {
      enabled: Boolean(id),
    }
  );

  // const { isLoading, mutate } = useMutation(api.saveYear, {
  //   onSuccess: (data) => {
  //     console.log("data: ", data);

  //     if (data.status == 200) {

  //       const auth_token = sessionStorage.getItem("token")
  //       ? sessionStorage.getItem("token")
  //       : null;
  //       let options = {};
  //       options = {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${auth_token}`,
  //         },
  //         task:'UOM'
  //       };


       

  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.message,
  //         msgtype: data.success,
  //       });
  //       queryClient.getQueriesData("year"); //api page name
  //       props.history.push("/year"); //
  //     } else {
  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.message,
  //         msgtype: data.success,
  //       });
  //       queryClient.getQueriesData("year"); //api page name
  //       props.history.push("/year"); //
  //     }
  //   },
  // });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    console.log("formData: ", formData);
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };

  const handleRDateChange = (date, field) => {
  
    setFormData({ ...formData, [field]: date });
    setErrorObject({ ...errorObject, [field]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
    api.dirtyModeEnable();
  };

  const handleReset = () => {
    setFormData({
      DeadLine: "",
      PackageYear: "",
      CurrentYear: "",
      bCatalogLocked:""
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["DeadLine","PackageYear"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  function checkValidateForm(){

     
    if (validateForm(formData)) {
    let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName,}; 
   
    return fDtat;
    }else{
    return false;
    }

};

  // const handleSubmit = async (e) => {
  //   console.log("from submit ", formData);
  //   if (validateForm(formData)) {
  //     let fDtat = {
  //       ...formData,
  //       menukey: menukey,
  //       lan: lan,
  //       UserName: UserName,
  //     };
  //     mutate(fDtat);
  //   }
  // };


  const handleCheckYear = (e) => {
    const { name, checked } = e.target;
    
    let bDispenseVal = checked;
        swal({
          title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
          text:
            bDispenseVal == true
              ? t(
                  DispensingLanguage[lan][menukey][
                    "Do you want to lock for editing in this year?"
                  ]
                )
              : t(
                  DispensingLanguage[lan][menukey][
                    "Do you want to unlock for editing in this year?"
                  ]
                ),
          icon: "warning",
          buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
          t(DispensingLanguage[lan][menukey]["Yes"])],
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            setFormData({ ...formData, [name]: checked });
  
          } else {
            //refetch();
          }
        });
  
    //setFormData({ ...formData, [name]: checked });
  };

  return (
    <>
      <YearFormData
        errorObject={errorObject}
        addNationalSupplierForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleCheckYear={handleCheckYear}
        handleReset={handleReset}
        checkValidateForm={checkValidateForm}
        // handleSubmit={handleSubmit}
        handleRDateChange={handleRDateChange}
        selectedDate={selectedDate} 
        bStockUpdate={props.permissionType}

        open={open}
        {...props}
      />
    </>
  );
};

export default AddYear;
