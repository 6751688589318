import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import MenuEntryFormData from "./MenuEntryFormData.js";


const AddMenu = (props) => {
  //get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "menu-entry";
const UserInfo = sessionStorage.getItem("User_info")
? JSON.parse(sessionStorage.getItem("User_info"))
: 0;
const UserName = UserInfo==0?'': UserInfo[0].id;

  const [formData, setFormData] = useState({
    title: "",
    url: "",
    parentId: "",
    position: "",
    MenuLevel: "",
    
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  useQuery(
    ["menuentery", id],
    () =>
      api.geImenuentrys(id).then((res) => {
        setFormData(res.data);
      }),
    {
      enabled: Boolean(id),
    }
  );

  // const { isLoading, mutate } = useMutation(api.saveMenuentry, {
  //   onSuccess: (data) => {

  //     if(data.status==200) {

  //        api.getDispensingLanguage().then((response) => {
  //          localStorage.setItem(
  //            "DispensingLanguage",
  //            JSON.stringify(response.datalist)
  //          );
  //        })

  //     props.openNoticeModal({
  //       isOpen: true,
  //       msg: data.message,
  //       msgtype: data.success,
  //     });

  //     queryClient.getQueriesData("menuentry");
  //     props.history.push("/menu-entry");

  //   }else{

  //     props.openNoticeModal({
  //       isOpen: true,
  //       msg: data.message,
  //       msgtype: data.success,
  //     });
     
  //   }

  //   },
  // });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    console.log("formData: ", formData);
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };

    //====== Start Choosen Dropdown===========
    const handleChangeChoosenProps = (name, value) => {
      let data = { ...formData };
      data[name] = value;   
      setFormData(data); 
      setErrorObject({ ...errorObject, [name]: "" });
      api.dirtyModeEnable();
    }
    //====== End Choosen Dropdown===========

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
    api.dirtyModeEnable();
  };

  const handleReset = () => {
    setFormData({
      title: "",
      url: "",
      parentId: "",
      position: "",
      MenuLevel: "",
     
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["title","url"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
        // if (formData[field] != 0) {
        //   errorData[field] = DispensingLanguage[lan][menukey]['field is Required !'];
        //   isValid = false;
        //   }
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  function checkValidateForm(){

     
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
   
    return fDtat;
    }else{
    return false;
    }

};

  // const handleSubmit = async (e) => {
  //   console.log("from submit ", formData);
  //   if (validateForm(formData)) {
  //     let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
  //     mutate(fDtat);
  //     //swal("Success!", "", "success");
  //   }
  // };

  return (
    <>
      <MenuEntryFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleCheck={handleCheck}
        handleReset={handleReset}
        checkValidateForm={checkValidateForm}
        // handleSubmit={handleSubmit}
        bStockUpdate={props.permissionType}
        {...props}
      />
    </>
  );
};

export default AddMenu;
