import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar";
import Notification from "../../../services/Notification";
import {checkLogin, checkUserPermission} from "../../../services/CheckUserAccess";
import swal from "sweetalert";
// Adjustment Type screen
import AdjustmentType from "./AdjustmentType";
import AdjustmentTypeAdd from "./AddAdjustmentType";
import AdjustmentTypeEdit from "./EditAdjustmentType";

const Index = (props) => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const menukey = "adjustment-type";

  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  const [hasUserPermission, setHasUserPermission] = React.useState(false);

  /* const checkLogin = () => {  
    let token = sessionStorage.getItem("token");
    if (!token) {
      swal({
        title: "Oops!",
        text: 'token expired. Please login again',
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) { 
          window.location.href = process.env.REACT_APP_BASE_NAME+`/login`;
          sessionStorage.clear();
        }
      });
    }
  }; */
  
  if(RedirectLogin){
    setHasUserPermission(checkUserPermission(menukey));// To check user has permission in this page
    checkLogin();
    setRedirectLogin(false);
  }

  React.useEffect(() => {
    // checkLogin();
    // checkAccess();
  }, []);

  const [msgObj, setMsgObj] = React.useState({
    isOpen: false,
  });
  const openNoticeModal = (obj) => {
    setMsgObj(obj);
  };
  const closeNoticeModal = (event, reason) => { 
      if (reason === 'clickaway') {
        return;
      } 
    setMsgObj({ isOpen: false });
  };

 

  return (
    // <div>
    //   <AfterLoginNavbar {...props} />
    //   <div className={`section signup-top-padding ${classes.adjustmentTypeContainer}`}>
    //     <Switch>
    //       <Route path={`${path}/add`} component={AdjustmentTypeAdd}></Route>
    //       <Route path={`${path}/edit/:id`} component={AdjustmentTypeEdit}></Route>
    //       <Route path={`${path}/`} component={AdjustmentType}></Route>
    //     </Switch>
    //   </div>
    // </div>

    hasUserPermission && (

          <div>
          <AfterLoginNavbar {...props} />
          <div
            className={`section signup-top-padding ${classes.adjustmentTypeContainer}`}
          >
            <Switch>
              <Route
                path={`${path}/add`}
                render={(props) => (
                  <AdjustmentTypeAdd {...props} openNoticeModal={openNoticeModal} />
                )}
              ></Route>
              <Route
                path={`${path}/edit/:id`}
                render={(props) => (
                  <AdjustmentTypeEdit {...props} openNoticeModal={openNoticeModal} />
                )}
              ></Route>
              <Route
                path={`${path}/`}
                render={(props) => (
                  <AdjustmentType {...props} openNoticeModal={openNoticeModal} />
                )}
              ></Route>
            </Switch>
            <Notification
              closeNoticeModal={closeNoticeModal}
              msgObj={msgObj}
              {...props}
            ></Notification>
          </div>
        </div>
    )


  );
};

export default Index;

const useStyles = makeStyles({
  adjustmentTypeContainer: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
});
