import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import {  
  Typography,
  TextField,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Card,
  CardContent, 
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
//Services
import * as Service from "../../../services/Service.js";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";
import ExportButtons from "../../../components/ExportButtons";
import Tooltip from '@material-ui/core/Tooltip';
const EntryofMCWC = (props) => {
  const permissionType = props.permissionType;
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "entry-edit-of-mcwc";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const classes = useStyles();

  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const WarehouseList = JSON.parse(localStorage.getItem("gWHList"));
  WarehouseList.unshift({'id':"0",'name':"All"});
  const [currWarehouseId, setcurrWarehouseId] = useState(0);

  let TempItemList = useState([]);
  const [DistrictList, setDistrictListLocal] = useState(TempItemList);
  const [currDistrictId, setcurrDistrictId] = useState(0);

  let TempSDPUPList = useState([]);
  const [SDPUPList, setSDPUPListLocal] = useState(TempSDPUPList);
  const [currSDPUPId, setcurrSDPUPId] = useState(0);

  const [firstLoad, setFirstLoad] = useState(true);
  const [isLoading, setLoading] = useState(false);
  //====== Start Choosen Dropdown===========
  const [chosenValuesWarehouse, setChosenValuesWarehouse] = useState({
    "whrec_id": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
  });

  const [chosenValuesDistrict, setChosenValuesDistrict] = useState({
    "DistrictId": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
  });

  const [chosenValuesUpazila, setChosenValuesUpazila] = useState({
    "upazila_id": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
  });




  
const handleChangeChoosenWarehouse = (name, valueobj, value) => {
  let chosenValuesDataWarehouse = { ...chosenValuesWarehouse};
  chosenValuesDataWarehouse[name] = valueobj;
  setChosenValuesWarehouse(chosenValuesDataWarehouse); 

  setFirstLoad(true);
  setLoading(false);
  setcurrWarehouseId(value);

 setChosenValuesDistrict({
    "DistrictId": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
   }); 
  
   setChosenValuesUpazila({
    "upazila_id": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
   }); 

 

};

const handleChangeChoosenDistrict = (name, valueobj, value) => {
  let chosenValuesDataDistrict = { ...chosenValuesDistrict};
  chosenValuesDataDistrict[name] = valueobj;
  setChosenValuesDistrict(chosenValuesDataDistrict); 

  setFirstLoad(true);
  setLoading(false);
  setcurrDistrictId(value);

 setChosenValuesUpazila({
    "upazila_id": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
   }); 


};

const handleChangeChoosenUpazila = (name, valueobj, value) => {
  let chosenValuesDataUpazila = { ...chosenValuesUpazila};
  chosenValuesDataUpazila[name] = valueobj;
  setChosenValuesUpazila(chosenValuesDataUpazila); 

  setFirstLoad(true);
  setLoading(false);
  setcurrSDPUPId(value);

};




//====== End Choosen Dropdown===========


const cDistrictList = () => {  
  
  let DistrictListParam = {action:"DistrictListByWarehouse", whrec_id:currWarehouseId, FacilityId: FacilityId, menukey:""}

  Service.default
      .postApi("source/combo_generic.php",DistrictListParam)
      .then((res) => { 

        let All_Warehouse_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
        const Warehouse_List = [All_Warehouse_label].concat(res.datalist); 
        setDistrictListLocal(Warehouse_List);

      })
      .catch((err) => {});

}

const cSDPUPList = () => { 

let SDPUPListParam = {action:"getSDPUPList", whrec_id:currWarehouseId, DistrictId:currDistrictId, FacilityId: FacilityId, menukey:""}

Service.default
    .postApi("source/combo_generic.php",SDPUPListParam)
    .then((res) => { 

      let All_SDP_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
      const SDP_List = [All_SDP_label].concat(res.datalist); 
      setSDPUPListLocal(SDP_List);
    })
    .catch((err) => {});

}


React.useEffect(() => {
  setcurrDistrictId(0); 
  cDistrictList();
}, [currWarehouseId]);



React.useEffect(() => {
  setcurrSDPUPId(0); 
  cSDPUPList();
}, [currWarehouseId, currDistrictId]);


let params = {
  menukey: menukey,
  action: "getList",
  WarehouseId: currWarehouseId,
  DistrictId: currDistrictId,
  SDPUPId: currSDPUPId,

};

  const { data, refetch } = useQuery(
    [params],
    api.getEntryofMCWCLists,
    {
      onSuccess: (data) => {
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=getMCWCEntryExport" +
        "&reportType=" +
        reportType +
        "&WarehouseId=" +
        currWarehouseId +
        "&DistrictId=" +
        currDistrictId +
        "&SDPUPId=" +
        currSDPUPId +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now()
    );
  };

  const UserExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=getMCWCEntryUserExport" +
        "&reportType=" +
        reportType +
        "&WarehouseId=" +
        currWarehouseId +
        "&DistrictId=" +
        currDistrictId +
        "&SDPUPId=" +
        currSDPUPId +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    editunit(rowData);
  };

  const columns = [
    { title: "Id", field: "id", width: 50, visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      formatter: "rownum",
      width: "3%",
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
    },
    {
      title: DispensingLanguage[lan][menukey]["District"],
      width: "10%",
      field: "DistrictName",
      headerFilter: true,
    }, 
    {
      title: DispensingLanguage[lan][menukey]["Upazila"],
      width: "17%",
      field: "Upazila",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Union"],
      width: "17%",
      field: "UnionName",
      headerFilter: true,
    },
    
    {
      title: DispensingLanguage[lan][menukey]["WCMC Name"],
      width: "18%",
      field: "FacilityName",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Phone No"],
      width: "12%",
      field: "PhoneNo",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Address"],
      field: "Address",
      headerSort: true,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Start Date"],
      width: "8%",
      field: "SoftwareStartDate",
      headerFilter: true,
    },
    
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      hozAlign: "center",
      width: "5%",
      headerSort: false,
      headerHozAlign: "center",
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const editunit = (data) => {
    let route = `entry-edit-of-mcwc/edit/${data.id}`;
    props.history.push(route);
  };


  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    // const cellValue = props.cell._cell.value || "Edit | Show";

    if (permissionType) return <></>;
    else
      return (
        <>
          <Edit
            onClick={() => {
              editunit(rowData);
            }}
          />
          {/* <DeleteOutline
          onClick={() => {
            deleteunit(rowData);
          }}
        /> */}
        </>
      );
  }

  return (
    <>
      <div className={classes.dispenserPageTitle}>
        <div className="d-flex justify-dispenser mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Entry Edit of MCWC"]}
              </div>
            </div>
          </Grid>
        </div>

  {/* start of filter */}
  <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
            
                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                  

                        <Autocomplete
                          autoHighlight
                          disableClearable
                          id="whrec_id"
                          options={WarehouseList}
                          onChange={(event, valueobj) => handleChangeChoosenWarehouse('whrec_id', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValuesWarehouse['whrec_id']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Select Warehouse"]}
                            variant="standard"
                            id="whrec_id"
                            name="whrec_id"
                            fullWidth
                          />
                          )}
                        />

                  </FormControl>
                </Grid>

                <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                   

                        <Autocomplete
                          autoHighlight
                          disableClearable
                          id="DistrictId"
                          options={DistrictList}
                          onChange={(event, valueobj) => handleChangeChoosenDistrict('DistrictId', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValuesDistrict['DistrictId']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Select District"]}
                            variant="standard"
                            id="DistrictId"
                            name="DistrictId"
                            fullWidth
                          />
                          )}
                        />

                    </FormControl>
                  </Grid> 

                <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                    

                          <Autocomplete
                            autoHighlight
                            disableClearable
                            id="upazila_id"
                            options={SDPUPList}
                            onChange={(event, valueobj) => handleChangeChoosenUpazila('upazila_id', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValuesUpazila['upazila_id']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Select Upazila"]}
                              variant="standard"
                              id="upazila_id"
                              name="upazila_id"
                              fullWidth
                            />
                            )}
                          />

                    </FormControl>
                  </Grid> 

              

              <Grid item xs={3} sm={3}>
                <div className="float-right sw_btn_control">
                  <Button
                    color="info"
                    className="mr-2"
                   
                    disabled={permissionType}
                    onClick={() => {
                        
                        if(currSDPUPId){
                          props.history.push("/entry-edit-of-mcwc/add/" + currSDPUPId);
                        }else{
                          //alert("Please select an upazilla");
                          props.openNoticeModal({
                            isOpen: true,
                            msg: DispensingLanguage[lan][menukey]["Please select an upazilla"],
                            msgtype: 0,
                          });
                        }
                     
                    }}
                   
                  >
                    {DispensingLanguage[lan][menukey]["Add New"]}
                  </Button>

                  <ExportButtons PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}  {...props}></ExportButtons>

                  {/* <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>
                  */}
                  <Tooltip title={DispensingLanguage[lan][menukey]["Users Export"]}>
                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => UserExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>
                  
                  </Tooltip>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}



        <div>
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height={600}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default EntryofMCWC;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
