import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom"; 
import * as api from "../../../actions/api";
import NationalSupplierFormData from "./NationalSupplierFormData";



const EditNationalSupplier = (props) => {

    //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "national-supplier";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;  
  const UserName = UserInfo==0?'': UserInfo[0].id;

  const [formData, setFormData] = useState({
    SupName: "",
    Address: "",
    Phone: "",
    Fax: "",
    EMail: "",
  });

  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  useQuery(
    ["NationalSupplier", id],
    () =>
      api.geNationalSupplier(id).then((res) => {
        setFormData(res.data);
        return res.data;
      }),
    {
      enabled: Boolean(id),
    }
  );

  // const { mutate } = useMutation(api.updateNationalSupplier, {
  //   onSuccess: (data) => { 
  //     if (data.status == 200) {


        
  //       const auth_token = sessionStorage.getItem("token")
  //       ? sessionStorage.getItem("token")
  //       : null;
  //       let options = {};
  //       options = {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${auth_token}`,
  //         },
  //         task:'UOM'
  //       };


  //       api.getLoginFacilityListNotEqual99ANDSuppliers(options,'ALL')
  //       .then((response) => {

  //         localStorage.setItem(
  //           "SupplierListNational",
  //           JSON.stringify(response.datalist.SupplierListNational)
  //         );

  //       });
        
  //       // api.getAllDropdown("ABC_List").then((response) => {
  //       //   localStorage.setItem(
  //       //     "ABC_List",
  //       //     JSON.stringify(response.datalist.ABC_List)
  //       //   );
  //       // });

  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.data.message,
  //         msgtype: data.data.success,
  //       });

  //       queryClient.getQueriesData("NationalSupplier"); // include the page name
  //       props.history.push("/national-supplier"); //page url
  //       api.dirtyModeDisable();
  //     }else{

  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.message,
  //         msgtype: data.success,
  //       });
  //     }
  //   },
  // });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
    api.dirtyModeEnable();
  };

  const handleReset = () => {
    setFormData({
      SupName: "",
      Address: "",
      Phone: "",
      Fax: "",
      EMail: "",
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["SupName"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  function checkValidateForm(){

     
    if (validateForm(formData)) {
    let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName,}; 
   
    return fDtat;
    }else{
    return false;
    }

};

  // const handleUpdate = async (e) => {
  //   if (validateForm(formData)) {
  //     let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
  //     mutate(fDtat);
     
       
  //   }
  // };

  return (
    <>
      <NationalSupplierFormData
        errorObject={errorObject}
        addNationalSupplierForm={false}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        checkValidateForm={checkValidateForm}
        // handleUpdate={handleUpdate}
        bStockUpdate={props.permissionType}
        {...props}
      />
    </>
  );
};

export default EditNationalSupplier;
