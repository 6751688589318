import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel,
  RadioGroup,
  MenuItem,
  Paper,
  CardHeader,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns";

//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "issuevoucherpush";
// const classes = useStyles();

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));



const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const SourceModal = ({ handleClose, open,PrintMany, ...props }) => {

  const [selectedPaymentDate, setSelectedPaymentDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [radioValue, setRadioValue] = useState(0);
const handleEndDateChange = (date) => {
  setSelectedPaymentDate(date);
};

// const { isLoading, mutate } = useMutation(api.UpdatePaymentDateIssue, {

//   onSuccess: (data) => { 
//     handleClose('SourceClose');
//     if (data.status == 200) {
     
//     props.openNoticeModal({
//       isOpen: true,
//       msg: data.message,
//       msgtype: data.success,
//     });

//     }else{
//       props.openNoticeModal({
//         isOpen: true,
//         msg: data.message,
//         msgtype: data.success,
//       });
//     }
//   }
// })  

const handleSubmit = async (e) => {

  PrintMany(radioValue);
   
    // mutate(fDtat);
    
 
};

const changeRadio = (value) => {
  setRadioValue(value);
};

  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose('Printmodalclose')}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="xs"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('Printmodalclose')}
        >
      {DispensingLanguage[lan][menukey][""]}
        </BootstrapDialogTitle>
        <DialogContent dividers>

        <Grid item xs={8} sm={12}>
                <FormControl component="fieldset" className="mt-3 ml-3">
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="row-radio-buttons-group"
                    defaultValue="0"
                  >
                    
                    <FormControlLabel
                      value="0"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["Print the Full Set of Invoice"]}
                      onClick={() => changeRadio(0)}
                    />
                    <FormControlLabel
                      value="1"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["Reprint 1st Copy of Invoice"]}
                      onClick={() => changeRadio(1)}
                    />
                    
                    <FormControlLabel
                      value="2"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["Reprint 2nd Copy of Invoice"]}
                      onClick={() => changeRadio(2)}
                    />
                    <FormControlLabel
                      value="3"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["Reprint 3rd Copy of Invoice"]}
                      onClick={() => changeRadio(3)}
                    />
                    <FormControlLabel
                      value="4"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["Reprint 4th Copy of Invoice"]}
                      onClick={() => changeRadio(4)}
                    />
                    <FormControlLabel
                      value="5"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["Reprint 5th Copy of Invoice"]}
                      onClick={() => changeRadio(5)}
                    />
                  </RadioGroup>
                  
                </FormControl>
              </Grid>
        

        </DialogContent>
        <DialogActions>
          <Button
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
           
           {DispensingLanguage[lan][menukey]['Print']}  
          </Button>
          <Button
            autoFocus
            className="float-left"
            variant="contained" 
            onClick={() => handleClose('Printmodalclose')}
          >
            
            {DispensingLanguage[lan][menukey]['CANCEL']}   
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default SourceModal;


