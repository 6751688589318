import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import swal from "sweetalert";
import * as api from "../../../actions/api";
import {
  Typography,
  Grid,
  FormControlLabel,
  TextField,
  Button,
  Checkbox,
  FormControl,
  Card,
  CardContent,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useQuery, useMutation, useQueryClient } from "react-query";
import { DeleteOutline, Edit, ViewList, FileCopy } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import { useTranslation } from "react-i18next";
import ProductsModal from "../../../services/ProductsModal";
import SourceModal from "../../../services/SourceModal";
import {
  cellFocusEditor,
  dateEditor,
  dateEditorAllowPast,
  dateEditorAllowFuture,
} from "../../../services/Common";
import OrderModal from "./OrderModal";
import ExportButtonsMany from "../../../components/ExportButtonsMany";
import MomentUtils from "@date-io/moment";
import moment from "moment";
let timeStamp = "";


//errorObject, addProductForm, formData, handleChange, handleCheck, handleReset, handleSubmit, handleUpdate, ManyTableData,ManyJsonSave,handleRDateChange,selectedDate,handleAdd,handleRefech,
const DispenserFormData = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "receive-facility";
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  let ReceiveDetailsRef = null;
  const UseFor = localStorage.getItem("UseFor");
  const [newBatchRowAdded, setNewBatchRowAdded] = useState(false);


  const [open, setOpen] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);

  const [SoureOpen, setSoureOpen] = useState(false);

  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);

  //const OrderTo = JSON.parse(localStorage.getItem("OrderTo"));
  const OrderTo = JSON.parse(localStorage.getItem("FacilityList"));

  // const UsersListByFacility = JSON.parse(localStorage.getItem("UsersListByFacility"));
  const FacilityEmployee = JSON.parse(localStorage.getItem("FacilityEmployee"));

  const [currRowId, setCurrRowId] = useState(0);
  const [currFormulation, setCurrFormulation] = useState("");
  const [bIsDisable, setbIsEnable] = useState(false);

  //====== Start Choosen Dropdown===========
  const [chosenValues, setChosenValues] = useState({
    "ReceiveFrom": {"id": "", "name": "" },
    "PreparedBy": {"EmpCode": "","EmpName": ""}
  });


useEffect(() => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData['ReceiveFrom'] = OrderTo[OrderTo.findIndex(orderTo_list => orderTo_list.id == props.formData.ReceiveFrom)];   
  chosenValuesData['PreparedBy'] = FacilityEmployee[FacilityEmployee.findIndex(facilityEmployee_list => facilityEmployee_list.EmpCode == props.formData.PreparedBy)]; 
  setChosenValues(chosenValuesData); 

}, [props.formData.ReceiveFrom, props.formData.PreparedBy]);

const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  setChosenValues(chosenValuesData); 
  props.handleChangeChoosenProps(name, value);
};

//====== End Choosen Dropdown===========

  /* =====Start of Print Excel Many Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/receive_facility_list_print.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TransactionId=" +
        props.formData.TransactionId +
        "&FacilityId=" +
        FacilityId +
        "&FacilityName=" +
        FacilityName +
        "&UseFor=" +
        UseFor +
        "&TimeStamp=" +
        Date.now()
    );
  };

  const ExcelMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/receive_from_warehouse_entry_list_excel.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TransactionId=" +
        props.formData.TransactionId +
        "&FacilityId=" +
        FacilityId +
        "&FacilityName=" +
        FacilityName +
        "&UseFor=" +
        UseFor +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;
      case "OrderModal":
        setOpenOrder(false);
        break;
      case "OrderOpen":
        setOpenOrder(true);
        break;

      case "SourceOpen":
        setSoureOpen(true);
        break;

      case "SourceClose":
        setSoureOpen(false);
        break;

      default:
        break;
    }
  };

  const addProduct = (NonOrderProductData) => {
    console.log("NonOrderProductData: ", NonOrderProductData);
    // return;

    let temNonordata = NonOrderProductData;
    let rows = [];

    let autoId = 0;

    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;

      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.UnitName = row.UnitName;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.OrderRemainingQty = row.OrderRemainingQty;
      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.Quantity = row.Quantity;
      newRow.BatchNo = row.BatchNo;
      newRow.MfgDate = row.MfgDate;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = row.Quantity * row.UnitPrice;

      newRow.SourceId = row.SourceId;
      newRow.SourceName = row.SourceName;

      rows.push(newRow);
    });

    temNonordata.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;

      newRow.ItemCode = row.ItemCode;

      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.UnitName = row.UnitName;

      newRow.SourceId = null;
      newRow.SourceName = null;

      newRow.TransactionItemId = null;
      newRow.OrderRemainingQty = 0;

      console.log("manyTableData: ", manyTableData);

      manyTableData.forEach((rowssss, ij) => {
        if (rowssss.ItemNo == row.ItemNo) {
          newRow.OrderRemainingQty = rowssss.OrderRemainingQty;
          return;
        }
      });

      newRow.CurrentStockQty = 0;
      newRow.Quantity = null;
      newRow.BatchNo = null;
      newRow.MfgDate = null;
      newRow.ExpiryDate = null;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = 0;
      rows.push(newRow);
    });

    setManyTableData(rows);
    props.handleAdd(rows);
    handleClose("Products");
    api.dirtyModeEnable();
  };

  const addSource = (productSourceData) => {
    if (!productSourceData) {
      return;
    }

    // console.log("rows before pick product: ", manyTableData);
    // console.log("Product batch selected data: ", productBatchData);
    // console.log("Product batch selected currRowId: ", currRowId);

    let tempProductSourceData = productSourceData[0];
    let rows = [];

    let autoId = Date.now(); // 0;

    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};

      if (row.SKU === currRowId) {
        // if(row.TransactionItemId===null)
        // {
        //   //currFormulation
        // }
        // console.log("This is my row: ", row);
        // console.log("This is my row: ", temProductBatchData);
        newRow.SourceId = tempProductSourceData.SourceId;
        newRow.SourceName = tempProductSourceData.SourceName;
      } else {
        newRow.SourceId = row.SourceId;
        newRow.SourceName = row.SourceName;
      }

      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;

      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.OrderRemainingQty = row.OrderRemainingQty;
      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.Quantity = row.Quantity;
      newRow.BatchNo = row.BatchNo;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.SKU = row.SKU;
      newRow.id = row.id;
      newRow.LineTotal = row.Quantity * row.UnitPrice;

      // newRow.SourceId =row.SourceId;
      //newRow.SourceName =row.SourceName;

      rows.push(newRow);
    });

    // console.log("rows after link with product: ", rows);

    setManyTableData(rows);
    props.handleAdd(rows);
    //productAdd(rows);
    handleClose("SourceClose");
    api.dirtyModeEnable();
  };

  function ShowSourcePopup(propss: any) {
    const rowData = propss.cell._cell.row.data;

    if (props.bStockUpdate) {
      return <></>;
    } else {
      return (
        <>
          <ViewList
            onClick={() => {
              setCurrRowId(rowData.SKU);
              setCurrFormulation(rowData.SKU);
              handleClose("SourceOpen");
            }}
          />
        </>
      );
    }
  }

  const ReceiveDetailsColumns = [
    { title: "TransactionItemId", field: "TransactionItemId", visible: false },
    { title: "id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      width: 70,
      formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "ItemCode",
      width: 130,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ItemName",
      minWidth: 200,
      headerFilter: true,
    },
    
    {
      title: DispensingLanguage[lan][menukey]["Quantity Received"],
      field: "Quantity",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      validator: ["integer", "min:0"],
      /*cssClass: "tabluator-column-editable",
       editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      cellEdited: function (cell) {
     
      }, */
      formatter: "money",
      formatterParams: { thousand: ",", precision: false },
    },
    {
      title: DispensingLanguage[lan][menukey]["Unit"],
      field: "UnitName",
      width: 80,
      // headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Lot No"],
      field: "BatchNo",
      width: 150,
      /* cssClass: "tabluator-column-editable text-field-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        
      }, */
    },
    {
      title: DispensingLanguage[lan][menukey]["Mfg. Date"],
      field: "MfgDate",
      width: 130,
      //cssClass: "tabluator-column-editable",
     /*  cssClass: "tabluator-column-editable text-field-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      */
      
      /* cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
      }, */
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry Date"],
      field: "ExpiryDate",
      width: 130,
       /* cssClass: "tabluator-column-editable", */
      /*cssClass: "tabluator-column-editable text-field-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "", */


    },
    // {
    //   title: DispensingLanguage[lan][menukey]["Actions"],
    //   field: "custom",
    //   headerSort: false,
    //   headerHozAlign: "center",
    //   hozAlign: "center",
    //   width: 80,
    //   formatter: reactFormatter(
    //     <ActionButton
    //     />
    //   ),
    // },

    // {
    //   title: "SourceId",
    //   field: "SourceId",
    //   visible: false,
    // },
    // {
    //   title: DispensingLanguage[lan][menukey]["Source"],
    //   field: "SourceName",
    //   width: 120,
    // },

    // {
    //   title: "",
    //   headerSort: false,
    //   field: "custom",
    //   hozAlign: "center",
    //   width: 8,
    //   formatter: reactFormatter(<ShowSourcePopup />),
    // },

    // {
    //   title: DispensingLanguage[lan][menukey]["Unit Price (CFA)"],
    //   field: "UnitPrice",
    //   width: 100,
    //   hozAlign: "right",
    //   headerHozAlign: "right",
    //   validator: ["min:0"],
    //   cssClass: "tabluator-column-editable",
    //   editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
    //   cellEdited: function (cell) {
    //     const curcellval = cell.getValue();
    //     const currow = cell.getRow();
    //     const rowdata = currow.getData();
    //     const pricecell = currow.getCell("LineTotal");
    //     const totprice = rowdata.Quantity * curcellval;
    //     pricecell.setValue(totprice);
    //   },
    // },
    // {
    //   title: DispensingLanguage[lan][menukey]["Line Total (CFA)"],
    //   field: "LineTotal",
    //   width: "10%",
    //   formatter: "money",
    //   hozAlign: "right",
    //   headerHozAlign: "right",
    //   bottomCalc: "sum",
    //   bottomCalcFormatter: "money",
    // },
  ];
 



  // const onPostBtnClick = () => {
  //   setbStockUpdate(true);
  //   props.handlePost();
  // };
  
  const onPostBtnClick = () => {

 ///===================Check qty is blank of any item==========/////////////
 if (ReceiveDetailsRef == null) return;
    

 let blankinfo = {};
 ReceiveDetailsRef.table.getData().forEach((row, i) => {
   if(row.Quantity === null || row.Quantity === ""){
     blankinfo = row;
   }
 });
 
 if(Object.keys(blankinfo).length>0){
   props.openNoticeModal({
     isOpen: true,
     msg: t(DispensingLanguage[lan][menukey]["Blank Quantity is not allowed"]) + ' - ' + blankinfo.ItemName,
     msgtype: false,
   });
   return;
 }
///===================Check qty is blank of any item==========/////////////


 ///===================Check duplicate lots exist in invoice==========/////////////
 // if (ReceiveDetailsRef == null) return;
 
 let invoiceLotIds = [];
 let duplicateinfo = {};
 ReceiveDetailsRef.table.getData().forEach((row, i) => {
   let uktempid = row.ItemCode+'_'+row.BatchNo+'_'+row.MfgDate+'_'+row.ExpiryDate;
   // console.log('uktempid: ', uktempid);
   if(invoiceLotIds.includes(uktempid)){
     duplicateinfo = row;
   }else{
     invoiceLotIds.push(uktempid);
   }
 });
 
 if(Object.keys(duplicateinfo).length>0){
   props.openNoticeModal({
     isOpen: true,
     msg: t(DispensingLanguage[lan][menukey]["Duplicate Lot available"]) + ' - ' + duplicateinfo.ItemName,
     msgtype: false,
   });
   return;
 }
///===================Check duplicate lots exist in invoice==========/////////////


    //setbStockUpdate(true);
    // props.handlePost();
    handlePost();
  };

  
  const handlePost = async (e) => {  
    
    let validForm = props.checkValidateForm(ReceiveDetailsRef.table.getData(), 'post');
    // console.log('isval: ', validForm);
    // console.log('props from post: ', props);

    // if (validateForm(formData)) {
      if (validForm) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you really want to post the stock?"]),
        icon: "warning",
        // buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        // t(DispensingLanguage[lan][menukey]["Yes"])],
        // dangerMode: true,
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          let fDtat={...validForm,"bStockUpdated":1};
          // console.log('fDtat: ', fDtat);
          mutateUpdate(fDtat); 
          
        }else{
          // setbStockUpdate(0);
          props.stockPosted(0);
          // let route = `${TransactionId}`;
          let route = `${validForm.TransactionId}`;
          props.history.push(route);
        }
      });  
      // End Post Alert

      
    }
     
     
  };



  const onSaveUpdateBtnClick = () => {
    // props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData());
    let manydata = ReceiveDetailsRef.table.getData();
    setUpdatedDetailsGridDataHandler(manydata);
  };

  const setUpdatedDetailsGridDataHandler = (rows) => {
    let validForm = props.checkValidateForm(rows,'edit');
    // console.log('isval: ', validForm);
      if (validForm) {

        // console.log('props.addProductForm: ', props.addProductForm);
        if(props.addProductForm){
          //when save
          mutate(validForm);
        }else{
          //when update
          mutateUpdate(validForm); 
        }
        

        
      }
  };
  
  
  const { isLoading: isLoadingSave, mutate } = useMutation(api.saveReceiveInv, {
    onSuccess: (data) => { 
      if (data.status == 200) {

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      let route = `edit/${data.TransactionId}`;
      props.history.push(route); 
      
      }else{
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })  

  
  const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.updateReceiveFacilityInv, {
    onSuccess: (data) => {
      // console.log('mutateUpdate data: ', data);
      if (data.status == 200) { 
      //  console.log("props: ",props.formData.TransactionId);
        //setbStockUpdate(data.bStockUpdated);
        // props.successSave(data.bStockUpdated);
        if(data.bStockUpdated==1){
         mutatePost(props.formData.TransactionId);
        }
        else{
          // props.refetchInvoice(data.bStockUpdated); //refetch(); 
          props.refetchInvoice(); //refetch(); 
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
 
        }

      }else{ 
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });



  
  const { isLoading: isLoadingPost, mutate:mutatePost } = useMutation(api.postRecFacilityInvoice, {
    onSuccess: (data) => { 
      if (data.status == 200) { 

       
       // setDeletedDataSet([]);

        if(data.data.success==0){ 
          //setbStockUpdate(0);
          props.stockPosted(0);
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
         
         }else{
          // setbStockUpdate(1);
          props.stockPosted(1);
          props.refetchInvoice();

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
  
         } 

      }else{
        // setbStockUpdate(0);
        props.stockPosted(0);
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    }
  });

  

  const invoiceCancel = (e)=>{

    const { name, checked } = e.target;
    let validForm = props.checkValidateForm(ReceiveDetailsRef.table.getData(), 'cancel');

    if (validForm) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you really want to cancel?"])+" "+t(DispensingLanguage[lan][menukey]["You will not able to edit this invoice after cancel"]),
        icon: "warning",
        // buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        // t(DispensingLanguage[lan][menukey]["Yes"])],
        // dangerMode: true,
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willCancel) => {
        if (willCancel) {
          // let fDtat={...formData,'ManyJsonSave':ManyTableData,"BCancel":1,"TableName":"recinv",'deletedDataSet':deletedDataSet};
          let fDtat={...validForm,"BCancel":1,"TableName":"recinv"};
          mutateUpdate(fDtat);
        }else{
          //setFormData({ ...formData, [name]: false });
        }
      });

    }

  };











  const deleteReceiveInv = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Do you really want to delete the selected item?"
        ]
      ),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willDelete) => {
      if (willDelete) {
        let newarray = manyTableData.filter((element) => element !== data);
        setManyTableData(newarray);
        props.deleteReceiveInv(data);
        api.dirtyModeEnable();
      }
    });
  };

  const CopyBatch = () => {
    props.handleAdd(manyTableData);
  };

  function ActionButton(props) {
    //console.log("console props......", props123);

    // return;

    const rowData = props.cell._cell.row.data;

    const goToDetails = () => {
      let rowobj = props.cell._cell.row.data; //{};
      // console.log("I copied this line", rowobj);
      //console.log("I copied this line", props.cell._cell.row);

      timeStamp = Math.round(new Date().getTime() / 100);

      setManyTableData((prevRows) => {
        const tmpPrevRows = [...prevRows];

        let setRposition = tmpPrevRows.findIndex((o) => o.SKU == rowobj.SKU);
        // console.log("Rposition", setRposition);

        const obj = { ...rowobj };

        obj.id = timeStamp;
        obj.TransactionItemId = "";
        obj.ExpiryDate = "";
        obj.BatchNo = "";
        obj.Quantity = 0;
        obj.SKU = Date.now();

        // console.log("new object added in grid:", obj);
        tmpPrevRows.splice(setRposition + 1, 0, obj);

        return tmpPrevRows;
      });

      // props.handleAdd(manyTableData);
      CopyBatch();
      setNewBatchRowAdded((prevNewBatchRowAdded) => !prevNewBatchRowAdded);
    };

    const cellValue = props.cell._cell.value || "Edit | Show";
    if (bStockUpdate == 0)
      return (
        <>
          {/* <FileCopy
            onClick={() => {
              if (!bStockUpdate) goToDetails(rowData);
            }}
          /> */}

          <DeleteOutline
            onClick={() => {
              if (!bStockUpdate) deleteReceiveInv(rowData);
            }}
          />
        </>
      );
    else return <></>;
  }

  useEffect(() => {
    if (ReceiveDetailsRef == null) return;
    if (props.bFirst) {
      setManyTableData(props.ManyTableData);
    }

    setbStockUpdate(props.bStockUpdate);
  }, [props.ManyTableData, props.bStockUpdate]); //

  useEffect(() => {
    if (ReceiveDetailsRef.table != null && timeStamp != "") {
      // console.log("Many table", ReceiveDetailsRef.table);
      // console.log("Many table 12222222222");

      ReceiveDetailsRef.table.scrollToRow(timeStamp, "center", true);
      ReceiveDetailsRef.table.selectRow(timeStamp);
    }
  }, [newBatchRowAdded]);

  const handleReset = () => {
    props.handleReset();
    setManyTableData([]);
  };

  
  const backtolist=()=>{


    let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
    let idx = master_dirty_message_class.indexOf("dnone");
    if(idx === -1){
      // start Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          props.history.push("/receive-facility");
        }
      });
      // End Alert
    }else{
      props.history.push("/receive-facility");
    }

  }

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Receive Facility - Add/Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {props.addProductForm ? (
                  <Grid item xs={12} className="text-center">
                    <div className="float-right sw_btn_control">
                      <Button
                        disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button>
                      <Button
                      disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onSaveUpdateBtnClick} //() => props.handleSubmit()
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                      disabled={isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        // onClick={() => props.history.push("/receive")}
                        onClick={() => backtolist()}
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="mt-2 text-center">
                    <Button
                      disabled={props.bStockUpdate || isLoadingUpdate || isLoadingPost}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    <Button
                      // disabled={props.bStockUpdate}
                      disabled={
                        props.bStockUpdate || !(manyTableData.length > 0) || isLoadingUpdate || isLoadingPost
                      }
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onPostBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["post"]}
                    </Button>
                    <Button
                    disabled={isLoadingUpdate || isLoadingPost}
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      // onClick={() => props.history.push("/receive")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>

                    <ExportButtonsMany PrintMany={PrintMany}  {...props}></ExportButtonsMany>
                    {/* <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PrintMany()}
                    >
                      <i class="fas fa-print"></i>
                    </Button> */}

                    {/* <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelMany()}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button> */}
                  </Grid>
                )}
              </div>

              
              <div className="mr-4 float-right mt-15">
                <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
              </div>
              
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            {/* <Card className="sw_card">
            <CardHeader title="Personal Information" />
            <CardContent> */}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={true /*bStockUpdate*/}
                      error={props.errorObject.TransactionNo}
                      helperText={props.errorObject.TransactionNo}
                      required
                      id="TransactionNo"
                      name="TransactionNo"
                      label={
                        DispensingLanguage[lan][menukey]["Receive Invoice No"]
                      }
                      // value={formData.FirstName || "Mahmudul"}
                      value={
                        props.formData.TransactionNo || props.generatedInvoiceNo
                      }
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        required
                        error={props.errorObject.TransactionDate}
                        helperText={props.errorObject.TransactionDate}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        id="TransactionDate"
                        label={DispensingLanguage[lan][menukey]["Receive Date"]}
                        autoOk={true}
                        name="TransactionDate"
                        fullWidth
                        showTodayButton={true}
                        disableFuture={true}
                        value={props.formData.TransactionDate || null}
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "TransactionDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    {/* <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Receive By"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.PreparedBy}
                        labelId="demo-simple-select-helper-label"
                        id="PreparedBy"
                        name="PreparedBy"
                        value={props.formData.PreparedBy}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={props.bStockUpdate}
                      >
                        {FacilityEmployee.map((item, index) => {
                          return (
                            <MenuItem value={item.EmpCode}>
                              {item.EmpName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={props.errorObject.PreparedBy}>
                        {props.errorObject.PreparedBy}
                      </FormHelperText>
                    </FormControl> */}

                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        hidden={props.bStockUpdate}
                        disabled={props.bStockUpdate}
                        autoHighlight
                        id="PreparedBy"
                        options={FacilityEmployee}
                        onChange={(event, valueobj) => handleChangeChoosen('PreparedBy', valueobj, valueobj?valueobj.EmpCode:"")}
                        getOptionLabel={(option) => option.EmpName}
                        value={chosenValues['PreparedBy']}
                        renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.EmpName}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          value={props.formData.PreparedBy}
                          label={DispensingLanguage[lan][menukey]["Receive By"]+ " *"}
                          variant="standard"
                          error={props.errorObject.PreparedBy}
                          helperText={props.errorObject.PreparedBy}
                          id="PreparedBy"
                          name="PreparedBy"
                          fullWidth
                        />
                        )}
                      />

                    <TextField
                      id="PreparedByName"
                      name="PreparedByName"
                      label={DispensingLanguage[lan][menukey]["Receive By"]+ " *"}
                      value={props.formData.PreparedByName}
                      disabled= {true}
                      hidden={!props.bStockUpdate}
                      fullWidth
                      autoComplete="family-name"
                      
                    />


                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    {/* <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label-receive-from">
                        {DispensingLanguage[lan][menukey]["Receive From"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.ReceiveFrom}
                        labelId="demo-simple-select-helper-label-receive-from"
                        id="ReceiveFrom"
                        name="ReceiveFrom"
                        value={props.formData.ReceiveFrom}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={
                          manyTableData.length > 0 ? true : props.bStockUpdate
                        }
                      >
                        {OrderTo.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>

                      <FormHelperText error={props.errorObject.ReceiveFrom}>
                        {props.errorObject.ReceiveFrom}
                      </FormHelperText>
                    </FormControl> */}

                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        disabled={
                          manyTableData.length > 0 ? true : props.bStockUpdate
                        }
                        autoHighlight
                        id="ReceiveFrom"
                        options={OrderTo}
                        onChange={(event, valueobj) => handleChangeChoosen('ReceiveFrom', valueobj, valueobj?valueobj.id:"")}
                        getOptionLabel={(option) => option.name}
                        value={chosenValues['ReceiveFrom']}
                        renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          value={props.formData.ReceiveFrom}
                          label={DispensingLanguage[lan][menukey]["Receive From"]+ " *"}
                          variant="standard"
                          error={props.errorObject.ReceiveFrom}
                          helperText={props.errorObject.ReceiveFrom}
                          id="ReceiveFrom"
                          name="ReceiveFrom"
                          fullWidth
                        />
                        )}
                      />

                    </FormControl>

                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={true}
                      error={props.errorObject.SupplierInvNo}
                      helperText={props.errorObject.SupplierInvNo}
                      id="SupplierInvNo"
                      name="SupplierInvNo"
                      label={
                        DispensingLanguage[lan][menukey][
                          "Supplier Invoice No"
                        ] + " *"
                      }
                      value={props.formData.SupplierInvNo}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={true}
                        error={props.errorObject.SupplierInvDate}
                        helperText={props.errorObject.SupplierInvDate}
                        className={classes.fullWidth}
                        // disableToolbar
                        variant="inline"
                        id="SupplierInvDate"
                        label={
                          DispensingLanguage[lan][menukey][
                            "Supplier Invoice Date"
                          ] + " *"
                        }
                        autoOk={true}
                        name="SupplierInvDate"
                        fullWidth
                        showTodayButton={true}
                        disableFuture={true}
                        value={props.formData.SupplierInvDate || null}
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "SupplierInvDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={4} sm={4}>
                    <TextField
                      disabled={props.bStockUpdate}
                      // error={props.errorObject.Remarks}
                      // helperText={props.errorObject.SupplierInvNo}
                      id="Remarks"
                      name="Remarks"
                      label={DispensingLanguage[lan][menukey]["Remarks"]}
                      value={props.formData.Remarks}
                      fullWidth
                      multiline
                      rows={3}
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  {/* <Grid item xs={12} className="marginTop10"> */}
                  <Grid item xs={1} sm={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="bUpdated"
                          disabled={true}
                          color="primary"
                          checked={props.formData.bUpdated > 0 ? true : false} 
                          name="bUpdated"
                          value="no"
                        />
                      }
                      label={DispensingLanguage[lan][menukey]["Updated"]}
                    />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="BCancel"
                          disabled={props.bStockUpdate || props.addProductForm}
                          color="primary"
                          checked={props.formData.BCancel > 0 ? true : false}
                          // onChange={(e) => props.invoiceCancel(e)}
                          onChange={(e) => invoiceCancel(e)}
                          name="BCancel"
                          value="no"
                        />
                      }
                      label={DispensingLanguage[lan][menukey]["CANCEL"]}
                    />
                  </Grid>

                  {/* <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={props.bStockUpdate}
                      id="DeliveredBy"
                      name="DeliveredBy"
                      label={DispensingLanguage[lan][menukey]["Delivered By"]}
                      value={props.formData.DeliveredBy}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid> */}

                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="TransactionId"
                      name="TransactionId"
                      label="TransactionId"
                      value={props.formData.TransactionId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                {/* new row */}
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card">
                    <CardContent>

                      {/* <Grid container className="mb-2">
                        <Grid item xs={12} sm={12}>
                          <div className="float-right sw_btn_control">

                            <Button
                              disabled={
                                props.bStockUpdate ||
                                (props.formData.TransactionId > 0
                                  ? false
                                  : true)
                              }
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              onClick={() => handleClose("ProductsOpen")}
                            >
                              {
                                DispensingLanguage[lan][menukey][
                                  "SELECT NON-ORDER PRODUCT"
                                ]
                              }
                            </Button>
                          </div>

                          <ProductsModal
                            handleClose={handleClose}
                            open={open}
                            addProduct={addProduct}
                            {...props}
                          />

                          <SourceModal
                            handleClose={handleClose}
                            open={SoureOpen}
                            addProduct={addSource}
                            {...props}
                          />
                        </Grid>
                      </Grid> */}

                      <ReactTabulator
                        ref={(r) => (ReceiveDetailsRef = r)}
                        columns={ReceiveDetailsColumns}
                        data={manyTableData}
                        height="350px"
                        layout={"fitColumns"}
                      />
                    </CardContent>
                  </Card>
                </Grid>

                {/* new row */}
              </Grid>
            </Grid>

            {/* </CardContent>
          </Card> */}
          </Grid>

          {/* Action buttons */}
        </Grid>
      </div>
    </div>
  );
};

export default DispenserFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
