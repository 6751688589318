const Constants = {
  token:
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3RcL3BocF9hdXRoX2FwaVwvIiwiYXVkIjoiaHR0cDpcL1wvbG9jYWxob3N0XC9waHBfYXV0aF9hcGlcLyIsImlhdCI6MTYzMDIxOTEwMSwiZXhwIjoxNjMwMjYyMzAxLCJkYXRhIjp7InVzZXJfaWQiOiIxIn19.ZJ0wAE10pv-dUAudnSuwSvZgusc-dC6YcERRy5S0j9s",

  USER_INFO: [
    {
      id: "1",
      name: "admin",
      email: "admin@gmail.com",
      designation: "owner",
      role: [{ id: "1", role: "admin" }],
      menu: [
        { id: "1", title: "Home", url: "/home", submenu: [] },
        { id: "2", title: "Dashboard", url: "/dashboard", submenu: [] },
        {
          id: "3",
          title: "Reports",
          url: "",
          submenu: [
            { id: "1", title: "Patient Dashboard", url: "/patient-dashboard" },
            {
              id: "2",
              title: "Logistics Dashboard",
              url: "/logistics-dashboard",
            },
          ],
        },
        { id: "4", title: "Patients", url: "/Patients", submenu: [] },
        { id: "5", title: "Prescription", url: "/prescription", submenu: [] },
        {
          id: "6",
          title: "Stock",
          url: "",
          submenu: [
            { id: "1", title: "Stock Status", url: "/stock-status" },
            { id: "2", title: "Order", url: "/order" },
            { id: "3", title: "Receive", url: "/receive" },
            { id: "4", title: "Adjustment", url: "/adjustment" },
          ],
        },
        {
          id: "7",
          title: "Administrator",
          url: "",
          submenu: [
            { id: "1", title: "Products", url: "/products" },
            { id: "2", title: "Prescriber", url: "/prescriber" },
            { id: "3", title: "Dispenser", url: "/dispenser" },       
            { id: "5", title: "Protocol", url: "/protocol" },
            


          ],
        },
        {
          id: "8",
          title: "Admin",
          url: "",
          submenu: [
           
            { id: "1", title: "Unit Of Measure Entry Form", url: "/unit-of-measure-entry-form" },
            { id: "2", title: "Direction", url: "/direction-entry" },
            { id: "3", title: "Interval", url: "/interval-entry" },
            { id: "4", title: "LabTest", url: "/labtest-entry" },
            { id: "5", title: "Route Of Admin", url: "/route-of-admin-entry" },
            { id: "6", title: "Order Type List", url: "/order-type-list-entry" },
            { id: "7", title: "Title List", url: "/title-list-entry" },
            { id: "8", title: "Marital Status", url: "/marital-status-entry" },
            { id: "9", title: "ABC", url: "/abc-entry" },
            { id: "10", title: "User List", url: "/users" },
            { id: "11", title: "User Role", url: "/admin/roles" },
            { id: "12", title: "Admin Dashboard", url: "/admin" },
            { id: "13", title: "Post", url: "/post-entry" },
            { id: "14", title: "Specialisation", url: "/specialisation" },
            { id: "15", title: "Adjustment Type", url: "/adjustment-type" },
            { id: "16", title: "Language Preference", url: "/language-preference" },
            { id: "17", title: "Pack Size", url: "/pack-size" },
            { id: "18", title: "Form", url: "/form" },
            { id: "19", title: "Strength", url: "/strength" },
            { id: "20", title: "UI Language", url: "/ui-language" },


          ],
        },
      ],
    },
  ],
  DROP_DOWN_OPTIONS: {
    "STRENTH": [
      {
        "id": "1",
        "name": "500mg"
      },
      {
        "id": "2",
        "name": "250mg"
      }
    ],
    "UNITS": [
      {
        "id": "1",
        "name": "Kg"
      },
      {
        "id": "2",
        "name": "Pcs"
      }
    ],
    "VEN": [
      {
        "id": "1",
        "name": "V"
      },
      {
        "id": "2",
        "name": "W"
      },
      {
        "id": "3",
        "name": "Z"
      }
    ],
    "FORM": [
      {
        "id": "1",
        "name": "Solution 1"
      },
      {
        "id": "2",
        "name": "Solution 2"
      },
      {
        "id": "3",
        "name": "Solution 3"
      }
    ],
    "ROUTE_OF_ADMIN": [
      {
        "id": "1",
        "name": "PO"
      },
      {
        "id": "2",
        "name": "PL"
      },
      {
        "id": "3",
        "name": "PR"
      }
    ],
    "ABC": [
      {
        "id": "1",
        "name": "A"
      },
      {
        "id": "2",
        "name": "B"
      },
      {
        "id": "3",
        "name": "C"
      }
    ],
    "PACK_SIZE": [
      {
        "id": "1",
        "name": "240"
      },
      {
        "id": "2",
        "name": "280"
      },
      {
        "id": "3",
        "name": "300"
      }
    ],
    "RX_LEVEL": [
      {
        "id": "1",
        "name": "1"
      },
      {
        "id": "2",
        "name": "2"
      },
      {
        "id": "3",
        "name": "3"
      }
    ],
    "UNITS": [
      {
        "id": "1",
        "name": "ml"
      },
      {
        "id": "2",
        "name": "mg"
      },
    ],
    "GENERIC_NAMES": [
      {
        "id": "1",
        "name": "Lamivudine 1"
      },
      {
        "id": "2",
        "name": "Lamivudine 2"
      },
      {
        "id": "3",
        "name": "Lamivudine 3"
      },
    ],
    "MARITAL_STATUS":[
      {
        "id": "married",
        "name": "Married"
      },
      {
        "id": "unMarried",
        "name": "Unmarried"
      }
    ],
    "LANGUAGE_PREFERENCE":[
      {
        "id": "fr",
        "name": "French"
      },
      {
        "id": "en",
        "name": "English"
      }
    ],
    "LAB_TEST_NAMES": [
      {
        "code": "CD4",
        "name": "CD4-count"
      },
      {
        "code": "TB",
        "name": "TB+1"
      }
    ],
    "TITLE_LIST":[
      {
        "id": "1",
        "name": "Mr."
      },
      {
        "id": "2",
        "name": "Mrs."
      }
    ],
    "POST_LIST":[
      {
        "id": "1",
        "name": "Doctor"
      },
      {
        "id": "2",
        "name": "Consultant"
      }
    ],
    "SPECIALISATION_LIST":[
      {
        "id": "1",
        "name": "Surgery"
      },
      {
        "id": "2",
        "name": "Medicine"
      }
    ],
    "COUNTRY_LIST":[
      {
        "id": "1",
        "name": "Benin"
      }
    ],
    "DEMANDER_LIST":[
      {
        "id": "1",
        "name": "Care Clinic"
      }
    ],
    "CODE_LIST":[
      {
        "id": "B20 - B24",
        "name": "B20 - B24"
      },
      {
        "id": "B21 - B25",
        "name": "B21 - B25"
      },
      {
        "id": "B22 - B26",
        "name": "B22 - B26"
      },
      {
        "id": "B23 - B27",
        "name": "B23 - B27"
      }
    ], 
    "PROTOCOL_NAME_LIST":[
      {
        "id": "Human immunodeficiency (HIV) Disease",
        "name": "Human immunodeficiency (HIV) Disease"
      },
      {
        "id": "Human immunodeficiency (TB) Disease",
        "name": "Human immunodeficiency (TB) Disease"
      },
      {
        "id": "Human immunodeficiency (Malaria) Disease",
        "name": "Human immunodeficiency (Malaria) Disease"
      },
      {
        "id": "Human immunodeficiency (COVID) Disease",
        "name": "Human immunodeficiency (COVID) Disease"
      }
    ],
    "ADJTYPE_LIST":[
		{
			"id": "1",
			"name": "Add to Stock"
		},{
			"id": "2",
			"name": "Deduct from Stock"
		},{
			"id": "3",
			"name": "Expiry"
		},{
			"id": "4",
			"name": "Loss"
		}
    ],"ORDERTO_LIST":[
      {
        "id": "1",
        "name": "Warehouse 1"
      }
    ],"ORDERTYPE_LIST":[
      {
        "id": "1",
        "name": "Main"
      },
      {
        "id": "2",
        "name": "Emergency"
      }
    ],
    "PRESCRIBERS": [
      {
        "id": "1",
        "name": "Prescriber 1"
      },
      {
        "id": "2",
        "name": "Prescriber 2"
      }
    ],"DISPENSERS": [
      {
        "id": "1",
        "name": "Dispenser 1"
      },
      {
        "id": "2",
        "name": "Dispenser 2"
      }
    ],"ICD_NUMBERS": [
      {
        "id": "1",
        "name": "B23"
      },
      {
        "id": "2",
        "name": "B24"
      }
    ],
    "INTERVALS": [
      {
        "id": "1",
        "name": "Interval 1"
      },
      {
        "id": "2",
        "name": "Interval 2"
      }
    ],
    "DIRECTIONS": [
      {
        "id": "1",
        "name": "Interval 1"
      },
      {
        "id": "2",
        "name": "Interval 2"
      }
    ],


  },

  //   USER_INFO: [
  //   {
  //     id: "1",
  //     name: "admin",
  //     email: "admin@gmail.com",
  //     designation: "owner",
  //     role: [{ id: "1", role: "admin" }],
  //     menu: [
  //       { id: "1", title: "Home", url: "/home", submenu: [] },
  //       { id: "2", title: "Dashboard", url: "/dashboard", submenu: [] },
  //       {
  //         id: "3",
  //         title: "Products",
  //         url: "",
  //         submenu: [
  //           { id: "1", title: "Products", url: "/products" },
  //           { id: "2", title: "Protocol", url: "/protocol" },
  //           { id: "3", title: "Regimen", url: "/regimen" },
  //           { id: "4", title: "Prescription", url: "/prescription" },
  //         ],
  //       },
  //       {
  //         id: "4",
  //         title: "Users",
  //         url: "",
  //         submenu: [{ id: "2", title: "users List", url: "/users" }],
  //       },
  //       {
  //         id: "5",
  //         title: "Admin",
  //         url: "",
  //         submenu: [
  //           { id: "3", title: "Roles", url: "/admin/roles" },
  //           { id: "4", title: "Admin Dashboard", url: "/admin" },
  //           { id: "5", title: "Patients", url: "/patients" },
  //         ],
  //       },
  //     ],
  //   },
  // ],

  ProductList: {
    success: 1,
    status: 200,
    message: "Details of  products by id",
    totalRecords: 5,
    Productsdata: [
      {
        ItemNo: "L540600233011",
        ItemName: "Lamivudine 10 mg/1 ml Liquid, Internal, 100 ml PO",
        ICN: "3788",
        ECN: "PHC",
        NSN: "180205055BT",
        Price: "2.50",
      },
      {
        ItemNo: "L540600233022",
        ItemName: "Azithromycin tablet 500mg",
        ICN: "5622",
        ECN: "PHC",
        NSN: "180205041BT",
        Price: "3.50",
      },
      {
        ItemNo: "L540600233033",
        ItemName: "Isoniazid (H), 100 mg",
        ICN: "6522",
        ECN: "PHC",
        NSN: "180205096DF",
        Price: "2.00",
      },
      {
        ItemNo: "L540600233044",
        ItemName: "Isoniazid (H), 300 mg",
        ICN: "1452",
        ECN: "PHC",
        NSN: "180205033FV",
        Price: "6.50",
      },
      {
        ItemNo: "L540600233055",
        ItemName: "Inj. Redemsivir 100ml",
        ICN: "8542",
        ECN: "PHC",
        NSN: "180205985AV",
        Price: "5.50",
      },
    ],
  },

  ProtocolList: {
    success: 1,
    status: 200,
    message: "Details of  protocol by id",
    totalRecords: 4,
    Productsdata: [
      {
        ItemNo: "1",
        Code: "B20 - B24",
        ProtocolName: "Human immunodeficiency (HIV) Disease",
      },
      {
        ItemNo: "2",
        Code: "B21 - B25",
        ProtocolName: "Human immunodeficiency (TB) Disease",
      },
      {
        ItemNo: "3",
        Code: "B22 - B26",
        ProtocolName: "Human immunodeficiency (Malaria) Disease",
      },
      {
        ItemNo: "4",
        Code: "B23 - B27",
        ProtocolName: "Human immunodeficiency (COVID) Disease",
      },
    ],
  },

  RegimenList: {
    success: 1,
    status: 200,
    message: "Details of  regimen by id",
    totalRecords: 5,
    Productsdata: [
      {
        ItemNo: "1",
        RegimenName: "Zadovudine 300mg 12h PO for 28days – [D.D. = 600mg]",
      },
      {
        ItemNo: "2",
        RegimenName: "ABC/3TC+DTG(20-30kg)",
      },
      {
        ItemNo: "3",
        RegimenName: "DRV/r + DTG + ABC",
      },
      {
        ItemNo: "4",
        RegimenName: "TDF/3TC/DTG",
      },
      {
        ItemNo: "5",
        RegimenName: "TDF/3TC/EFV",
      },
    ],
  },

  PrescriptionList: {
    success: 1,
    status: 200,
    message: "Details of Prescription by id",
    totalRecords: 3,
    Productsdata: [
      {
        ItemNo: "1",
        Date: "25/08/2021",
        RxNumber: "RO/08120001/0",
        FolderNo: "2345628",
        Patient: "Mahmudul Islam",
        Prescriber: "Dr. Rakibul",
        Cost: "1500",
        Status: "Posted",
        Repeats: "Repeat",
        Posted: "No",
      },
      {
        ItemNo: "2",
        Date: "20/07/2021",
        RxNumber: "RO/08120001/1",
        FolderNo: "2345628",
        Patient: "Mahmudul Islam",
        Prescriber: "Dr. Rakibul",
        Cost: "2000",
        Status: "Posted",
        Repeats: "Repeat",
        Posted: "No",
      },
      {
        ItemNo: "3",
        Date: "15/06/2021",
        RxNumber: "RO/08120001/2",
        FolderNo: "2345628",
        Patient: "Mahmudul Islam",
        Prescriber: "Dr. Rakibul",
        Cost: "2500",
        Status: "Posted",
        Repeats: "Repeat",
        Posted: "No",
      },
    ],
  },

  PrescriptionItemList: {
    success: 1,
    status: 200,
    message: "Details of Prescription Item by id",
    totalRecords: 2,
    Productsdata: [
      {
        ItemNo: "1",
        RxNumber: "RO/08120001/2",
        ItemName: "Amoxycillin 250mg Capsule [500 cap]",
      },
      {
        ItemNo: "2",
        RxNumber: "RO/08120001/2",
        ItemName: "Lamdine (3TC) Tablet [60 Tablets]",
      },
    ],
  },

  PrescriberList: {
    success: 1,
    status: 200,
    message: "Details of order by id",
    totalRecords: 5,
    Productsdata: [
      {
        ItemNo: "1",
        LastName: "Islam",
        FirstName: "Mahmudul",
        RxLevel: "5",
        PrescriberLevel: "5",
        Contact: "01715010789",
      },
      {
        ItemNo: "2",
        LastName: "Mea",
        FirstName: "Rubel",
        RxLevel: "5",
        PrescriberLevel: "4",
        Contact: "01538198763",
      },
      {
        ItemNo: "3",
        LastName: "Hossain",
        FirstName: "Anwar",
        RxLevel: "4",
        PrescriberLevel: "4",
        Contact: "01689763654",
      },
      {
        ItemNo: "4",
        LastName: "Alam",
        FirstName: "Mahbub",
        RxLevel: "3",
        PrescriberLevel: "5",
        Contact: "01921232956",
      },
      {
        ItemNo: "5",
        LastName: "Mahbub",
        FirstName: "Nazim",
        RxLevel: "3",
        PrescriberLevel: "4",
        Contact: "01856251425",
      },
    ],
  },

  OrderList: {
    success: 1,
    status: 200,
    message: "Details of  protocol by id",
    totalRecords: 4,
    Productsdata: [
      {
        ItemNo: "1",
        OrderDate: "15/09/2021",
        OrderNo: "ORD-BEN00812/21-0007",
        Status: "Daft",
      },
      {
        ItemNo: "2",
        OrderDate: "08/09/2021",
        OrderNo: "ORD-BEN00812/21-0006",
        Status: "Posted",
      },
      {
        ItemNo: "3",
        OrderDate: "02/09/2021",
        OrderNo: "ORD-BEN00812/21-0005",
        Status: "Posted",
      },
      {
        ItemNo: "4",
        OrderDate: "29/08/2021",
        OrderNo: "ORD-BEN00812/21-0004",
        Status: "Posted",
      },
      {
        ItemNo: "5",
        OrderDate: "20/08/2021",
        OrderNo: "ORD-BEN00812/21-0003",
        Status: "Posted",
      },
      {
        ItemNo: "6",
        OrderDate: "10/08/2021",
        OrderNo: "ORD-BEN00812/21-0002",
        Status: "Posted",
      },
    ],
  },

  DispenserList: {
    success: 1,
    status: 200,
    message: "Details of Dispenser by id",
    totalRecords: 5,
    Productsdata: [
      {
        ItemNo: "1",
        LastName: "Islam",
        FirstName: "Mahmudul",
        RxLevel: "5",
        DispenserLevel: "5",
        Contact: "01715010789",
      },
      {
        ItemNo: "2",
        LastName: "Mea",
        FirstName: "Rubel",
        RxLevel: "5",
        DispenserLevel: "4",
        Contact: "01538198763",
      },
      {
        ItemNo: "3",
        LastName: "Hossain",
        FirstName: "Anwar",
        RxLevel: "4",
        DispenserLevel: "4",
        Contact: "01689763654",
      },
      {
        ItemNo: "4",
        LastName: "Alam",
        FirstName: "Mahbub",
        RxLevel: "3",
        DispenserLevel: "5",
        Contact: "01921232956",
      },
      {
        ItemNo: "5",
        LastName: "Mahbub",
        FirstName: "Nazim",
        RxLevel: "3",
        DispenserLevel: "4",
        Contact: "01856251425",
      },
    ],
  },

  ReceiveList: {
    success: 1,
    status: 200,
    message: "Details of  protocol by id",
    totalRecords: 4,
    Productsdata: [
      {
        ItemNo: "1",
        ReceiveDate: "15/09/2021",
        ReceiveInvoiceNo: "REC-BEN00591/21-0001",
        OrderDate: "14/09/2021",
        OrderNo: "ORD-BEN00591/21-0001",
        Amount: "163,350.00",
        SupplierInvoiceNo: "ISS-BEN00014/21-0002",
        SupplierInvoiceDate: "14/09/2021",
        Status: "Daft",
      },
      {
        ItemNo: "2",
        ReceiveDate: "09/09/2021",
        ReceiveInvoiceNo: "REC-BEN00591/21-0005",
        OrderDate: "08/09/2021",
        OrderNo: "ORD-BEN00591/21-0003",
        Amount: "35,247.00",
        SupplierInvoiceNo: "ISS-BEN00014/21-0003",
        SupplierInvoiceDate: "09/09/2021",
        Status: "Posted",
      },
      {
        ItemNo: "3",
        ReceiveDate: "05/09/2021",
        ReceiveInvoiceNo: "REC-BEN00591/21-0003",
        OrderDate: "04/09/2021",
        OrderNo: "ORD-BEN00591/21-0004",
        Amount: "124,154.00",
        SupplierInvoiceNo: "ISS-BEN00014/21-0007",
        SupplierInvoiceDate: "05/09/2021",
        Status: "Posted",
      },
      {
        ItemNo: "4",
        ReceiveDate: "28/02/2021",
        ReceiveInvoiceNo: "REC-BEN00591/21-0002",
        OrderDate: "",
        OrderNo: "",
        Amount: "987.00",
        SupplierInvoiceNo: "ISS-BEN00014/21-0006",
        SupplierInvoiceDate: "29/08/2021",
        Status: "Posted",
      },
      {
        ItemNo: "5",
        ReceiveDate: "28/02/2021",
        ReceiveInvoiceNo: "REC-BEN00591/21-0004",
        OrderDate: "12/02/2021",
        OrderNo: "ORD-BEN00591/21-0003",
        Amount: "3,521.00",
        SupplierInvoiceNo: "ISS-BEN00014/21-0006",
        SupplierInvoiceDate: "28/02/2021",
        Status: "Posted",
      },
    ],
  },

  AdjustmentList: {
    success: 1,
    status: 200,
    message: "Details of  protocol by id",
    totalRecords: 4,
    Productsdata: [
      {
        ItemNo: "1",
        AdjDate: "10/09/2021",
        AdjInvoiceNo: "ADJ-BEN00812/21-0190",
        AdjType: "Add to Stock",
        Amount: "45,230.00",
        Status: "Daft",
      },
      {
        ItemNo: "2",
        AdjDate: "08/09/2021",
        AdjInvoiceNo: "ADJ-BEN00812/21-0110",
        AdjType: "Deduct from Stock",
        Amount: "5,162.50",
        Status: "Posted",
      },
      {
        ItemNo: "3",
        AdjDate: "20/08/2021",
        AdjInvoiceNo: "ADJ-BEN00812/21-0326",
        AdjType: "Expiry",
        Amount: "6,112.00",
        Status: "Posted",
      },
      {
        ItemNo: "4",
        AdjDate: "10/08/2021",
        AdjInvoiceNo: "ADJ-BEN00812/21-0268",
        AdjType: "Loss",
        Amount: "4,750.00",
        Status: "Posted",
      },
      {
        ItemNo: "5",
        AdjDate: "07/08/2021",
        AdjInvoiceNo: "ADJ-BEN00812/21-0307",
        AdjType: "Add to Stock",
        Amount: "1,975.00",
        Status: "Posted",
      },
    ],
  },

  StockStatusList: {
    success: 1,
    status: 200,
    message: "Details of  protocol by id",
    totalRecords: 4,
    Productsdata: [
      {
        ItemNo: "1",
        ProductCode: "2M407AB",
        ProductName: "AMOXICILLINE 500 MG CP BLISTER",
        AMC: "150",
        MinimumQuantity: "150",
        MaximumQuantity: "300",
        Quantity: "150",
        UnitPrice: "29.00",
        LineTotal: "4,350.00",
      },
      {
        ItemNo: "2",
        ProductCode: "2P0008",
        ProductName:
          "ARTEMETHER + LUMEFANTRINE 20 MG/120 MG CP BLISTER DE 06 COMP",
        AMC: "50",
        MinimumQuantity: "50",
        MaximumQuantity: "100",
        Quantity: "30",
        UnitPrice: "10.00",
        LineTotal: "300.00",
      },
      {
        ItemNo: "3",
        ProductCode: "2P0005",
        ProductName: "ARTEMETHER + LUMEFANTRINE 20 MG/120 MG CP BLISTER DE 12",
        AMC: "70",
        MinimumQuantity: "70",
        MaximumQuantity: "140",
        Quantity: "75",
        UnitPrice: "20.00",
        LineTotal: "1,500.00",
      },
      {
        ItemNo: "4",
        ProductCode: "2P0006",
        ProductName: "ARTEMETHER + LUMEFANTRINE 20 MG/120 MG CP BLISTER DE 18",
        AMC: "80",
        MinimumQuantity: "80",
        MaximumQuantity: "160",
        Quantity: "120",
        UnitPrice: "43.00",
        LineTotal: "5,160.00",
      },
      {
        ItemNo: "5",
        ProductCode: "2P0007",
        ProductName: "ARTEMETHER + LUMEFANTRINE 20 MG/120 MG CP BLISTER DE 24",
        AMC: "130",
        MinimumQuantity: "130",
        MaximumQuantity: "260",
        Quantity: "66",
        UnitPrice: "55.00",
        LineTotal: "3,630.00",
      },
    ],
  },
};

export default Constants;