import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import jsPDF from "jspdf";

//Services
import * as Service from "../../../services/Service.js";
import Constants from "../../../services/Constants";
import swal from "sweetalert";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
  Typography,
  TextField,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import ExportButtons from "../../../components/ExportButtons";

import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";

import * as api from "../../../actions/api";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, ViewList, preview, Edit } from "@material-ui/icons";
import { cellFocusEditor } from "../../../services/Common";
import moment from "moment";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const FacilityeLMISActivityStatus = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );

  const { t, i18n } = useTranslation();
  const lan = localStorage.getItem("LangCode");
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const menukey = "facility-elmis-activity-status";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;
  const DistrictList = JSON.parse(localStorage.getItem("DistrictList"));
  DistrictList.unshift({
    id: 0,
    name: t(DispensingLanguage[lan][menukey]["All"]),
  });

  const classes = useStyles();
  let tblRef = null;
  const tableRef = useRef();
  const { useState } = React;

  const [firstLoad, setFirstLoad] = useState(true);
  const [ManyJsonSave, setInputChange] = useState({});
  const [productGroupData, selectProductGroupData] = useState();
  const [dataItems, setDataItems] = useState([]);

  const [manyTableData, setManyTableData] = useState([]);

  const UseFor = localStorage.getItem("UseFor");

  const FacilityList = JSON.parse(localStorage.getItem("MCWCFacilityList"));
  FacilityList.unshift({
    id: 0,
    name: t(DispensingLanguage[lan][menukey]["All"]),
  });

  const [currFacilityCode, setcurrFacilityCode] = useState(
    FacilityList.length > 0 ? FacilityList[0].id : "0"
  );
  const [currFacilityName, setcurrFacilityName] = useState(
    FacilityList.length > 0
      ? FacilityList[0].name
      : DispensingLanguage[lan][menukey]["All"]
  );

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(30, "days").format("YYYY-MM-01")
  );

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const handleStartDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedEndDate(date);
  };

  //====== Start Choosen Dropdown===========

  const [chosenValuesFacility, setChosenValuesFacility] = useState({});

  const handleChangeChoosenFacility = (name, valueobj, value) => {
    let chosenValuesDataFacility = { ...chosenValuesFacility };
    chosenValuesDataFacility[name] = valueobj;
    setChosenValuesFacility(chosenValuesDataFacility);

    setFirstLoad(true);
    setLoading(false);
    setcurrFacilityCode(value);
    setcurrFacilityName(valueobj.name);
  };

  const queryClient = useQueryClient();

  let params = {
    menukey: menukey,
    action: "getFacilityeLMISActivityStatusReportList",
    StartDate: moment(selectedStartDate).format("YYYY-MM-DD"),
    EndDate: moment(selectedEndDate).format("YYYY-MM-DD"),
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    FacilityCode: currFacilityCode,
    UseFor: UseFor,
  };

  const [isLoading, setLoading] = useState(false);

  const {
    error,
    isError,
    data,
    refetch: stockData,
  } = useQuery([params], api.gatFacilityeLMISActivityStatusReport, {
    onSuccess: (data) => {
      if (firstLoad) {
        setDataItems(data);
        setLoading(false);
        setFirstLoad(false);
      }
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,
  });

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let formatedDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=FacilityeLMISActivityStatusExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&StartDate=" +
        formatedDate +
        "&EndDate=" +
        EndDate +
        "&FacilityCode=" +
        currFacilityCode +
        "&UseFor=" +
        UseFor +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 60,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
      frozen: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Code"],
      field: "FacilityCode",
      headerSort: true,
      headerFilter: true,
      width: 120,
      frozen: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Name"],
      field: "FacilityName",
      headerSort: true,
      headerFilter: true,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 250,
      frozen: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Last Activity"],
      field: "last_activity",
      headerSort: true,
      hozAlign: "left",
      headerHozAlign: "left",
      width: 190,
      frozen: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Non-zero Product"],
      field: "nonzeroproducts",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["Local Purchase"],
      field: "LocalPurchase",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["Receive From Upazila"],
      field: "ReceiveFromUpazila",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 130,
    },
    {
      title: DispensingLanguage[lan][menukey]["Asset Issue From Store"],
      field: "AssetIssueFromStore",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 130,
    },
    {
      title: DispensingLanguage[lan][menukey]["Dispense"],
      field: "Dispense",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,
    },
    {
      title: DispensingLanguage[lan][menukey]["Adjustment"],
      field: "Adjustment",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 130,
    },
    {
      title: DispensingLanguage[lan][menukey]["Monthly Form 3"],
      field: "MonthlyForm3",
      headerSort: true,
      hozAlign: "right",
      headerHozAlign: "right",
      width: 100,
    },
  ];

  React.useEffect(() => {
    if (tblRef == null) return;
    setLoading(true);
    stockData();
  }, [selectedStartDate, selectedEndDate, currFacilityCode]);

  return (
    <>
      <div className={classes.productPageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {
                  DispensingLanguage[lan][menukey][
                    "Facility eLMIS Activity Status"
                  ]
                }
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid item xs={12} sm={12}>
              <Grid container spacing={1}>
                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Autocomplete
                      autoHighlight
                      disableClearable
                      id="FacilityCode"
                      options={FacilityList}
                      onChange={(event, valueobj) =>
                        handleChangeChoosenFacility(
                          "FacilityCode",
                          valueobj,
                          valueobj ? valueobj.id : ""
                        )
                      }
                      getOptionLabel={(option) => option.name}
                      defaultValue={
                        FacilityList[
                          FacilityList.findIndex(
                            (facilitylist) =>
                              facilitylist.id == currFacilityCode
                          )
                        ]
                      }
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">
                          {option.name}
                        </Typography>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={DispensingLanguage[lan][menukey]["Facility"]}
                          variant="standard"
                          id="FacilityCode"
                          name="FacilityCode"
                          fullWidth
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={2} sm={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justifyContent="space-around">
                      <KeyboardDatePicker
                        className={classes.fullWidth}
                        //disableToolbar
                        autoOk={true}
                        // disabled={true}
                        variant="inline"
                        format="dd/MM/yyyy"
                        id="date-picker-inline"
                        label={DispensingLanguage[lan][menukey]["Start Date"]}
                        value={selectedStartDate}
                        onChange={handleStartDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={2} sm={2}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <Grid container justifyContent="space-around">
                      <KeyboardDatePicker
                        className={classes.fullWidth}
                        variant="inline"
                        format="dd/MM/yyyy"
                        id="date-picker-inline"
                        label={DispensingLanguage[lan][menukey]["End Date"]}
                        autoOk={true}
                        value={selectedEndDate}
                        onChange={handleEndDateChange}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </Grid>
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={5} sm={5}>
                  <div className="float-right sw_btn_control">
                    <ExportButtons
                      PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}
                      {...props}
                    ></ExportButtons>
                    {/* <Button
                          color="info"
                          className="mr-2 sw_icons"
                          onClick={() => PrintPDFExcelExportFunction("print")}
                        >
                          <i class="fas fa-print"></i>
                        </Button>

                        <Button
                          color="info"
                          className="mr-2 sw_icons"
                          onClick={() => PrintPDFExcelExportFunction("excel")}
                        >
                          <i className="far fa-file-excel"></i>
                        </Button> */}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}

          <div className="uniqueName">
            <ReactTabulator
              ref={(r) => (tblRef = r)}
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="400px"
              // groupBy="GroupName"
              // options={{
              //     groupBy: "Receiver",
              //     columnCalcs: "both",
              //   }}
              // rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FacilityeLMISActivityStatus;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  customControlSpacing: {
    padding: "0.3rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
