import React, { useState , useEffect} from "react";
import {
  Typography
} from "@material-ui/core";
import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query'
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import swal from "sweetalert";
import * as api from '../../../actions/api';
import DispenserFormData from './DispenseFacilityFormData.js';
// import history from './../../../history';
import { useTranslation } from "react-i18next";
import moment from "moment";

const EditDispenseFacility = (props) => {

  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey =  "dispense-facility";
const FacilityId=localStorage.getItem('FacilityId');

  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    TransactionNo: "", 
    TransactionDate: "", 
    FacilityName:"",
    TransactionTypeId:1,
    FacilityId:FacilityId,
    PrepBy:"",
    AppBy1:"",
    AppDate1: "", 
    IssuedBy:"",
    IssuedDate: "", 
    Remarks:"",
    BCancel:0,
    bUpdated:0,
    ExistingbShow:"",
    bShow:"",
    PrepByName:"",
    AppByName:"",
    IssuedByName:"",
    });
    const [selectedDate, setSelectedDate] = useState(
      //new Date()
      moment().format("YYYY-MM-DD")
    );
    const handleDateChange = (date) => {
      setSelectedDate(date);
    };
  const [ManyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState("0");
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
  const [TransactionId, setTransactionId] = useState("0");
  const [deletedDataSet, setDeletedDataSet] = useState([]);

  const [StockPost, setStockPost] = useState(false);

  const [toggleValue, setToggleValue] = React.useState("CON");

  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState(
    ''
  );
  
  const [bFirst, setBFirst] = useState(true); 
   
  const handleRDateChange = (date, field) => {

    let newDate;
   if(date==null || date==""){
     newDate="";
    }
    else{
      newDate = moment(date).format("YYYY-MM-DD");
    }
	setFormData({ ...formData, [field]: newDate });
	setErrorObject({ ...errorObject, [field]: null });
  api.dirtyModeEnable();
  };

  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  /*const { data, refetch } = useQuery(['dispense-facility', id], () =>
    api.getDispenseFacilitySingleUIMS(id).then((res) => {
      
      //This is for user has VIEW/EDIT permission in this page start
      if (props.permissionType === 1) {
        //when permissionType then it is only VIEW permission otherwise normal permission
        res.data.bStockUpdated = 1;
      }
      //This is for user has VIEW/EDIT permission in this page end

      setTransactionId(id); 
      setFormData(res.data); 
      setbStockUpdate(res.data.bStockUpdated);
      setManyTableData(res.data.ManyJsonSave);//res.data.ManyJsonSave
      setUpdatedDetailGridData(res.data.ManyJsonSave);
      setGeneratedInvoiceNo(res.data.TransactionNo);
      setDeletedDataSet([]);
      setBFirst(true);
      api.dirtyModeDisable();
      return res.data
    }), {
    refetchOnWindowFocus: false,
    enabled: Boolean(id)
  })*/


  const { isLoading: isLoadingUpdate, mutate: mutateRefetch } = useMutation(
    api.getDispenseFacilitySingleUIMS,
    {
      onSuccess: (res) => {
        //This is for user has VIEW/EDIT permission in this page start
        if (props.permissionType === 1) {
          //when permissionType then it is only VIEW permission otherwise normal permission
          res.data.bStockUpdated = 1;
        }
        //This is for user has VIEW/EDIT permission in this page end

        setTransactionId(id); 
        setFormData(res.data); 
        setbStockUpdate(res.data.bStockUpdated);
        setManyTableData(res.data.ManyJsonSave);//res.data.ManyJsonSave
        setUpdatedDetailGridData(res.data.ManyJsonSave);
        setGeneratedInvoiceNo(res.data.TransactionNo);
        setDeletedDataSet([]);
        setBFirst(true);
        api.dirtyModeDisable();
        return res.data
      },
    }
  );


const handleAdd = (NonOrderProductData) => {
    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);
};
  const handleChange = (e) => {
    const { name, value } = e.target;
     
      let data = { ...formData };
      data[name] = value;  

      setFormData(data); 
    setErrorObject({ ...errorObject, [name]: null });

    api.dirtyModeEnable();
  };

    //====== Start Choosen Dropdown===========
    const handleChangeChoosenProps = (name, value) => {
      let data = { ...formData };
      data[name] = value; 

      setFormData(data); 
      setErrorObject({ ...errorObject, [name]: "" });

      api.dirtyModeEnable();
    }
    //====== End Choosen Dropdown===========


  const handleCheck = (e) => {
    const { name, checked } = e.target;

    setFormData({ ...formData, [name]: checked });
  };


  // const invoiceCancel = (e)=>{

  //   const { name, checked } = e.target;

  //   swal({
  //     title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
  //     text: t(DispensingLanguage[lan][menukey]["Do you really want to cancel?"])+" "+t(DispensingLanguage[lan][menukey]["You will not able to edit this invoice after cancel"]),
  //     icon: "warning",
  //     buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
  //     t(DispensingLanguage[lan][menukey]["Yes"])],
  //     dangerMode: true,
  //   }).then((willCancel) => {
  //     if (willCancel) {

  //       let fDtat={...formData,'ManyJsonSave':ManyTableData,"BCancel":1,"TableName":"indent",'deletedDataSet':deletedDataSet};
  //       mutateUpdate(fDtat);
  //     }else{
  //       setFormData({ ...formData, [name]: false });
  //     }
  //   });

  // };

  const handleReset = () => {
    setFormData({
      TransactionNo: "", 
      TransactionDate: "", 
      FacilityName:"",
      TransactionTypeId:1,
      FacilityId:FacilityId,
      PrepBy:"",
      AppBy1:"",
      AppDate1: "", 
      IssuedBy:"",
      IssuedDate: "", 
      Remarks:"",
      BCancel:0,
      bUpdated:0,
      ExistingbShow:"",
      bShow:"",
      PrepByName:"",
      AppByName:"",
      IssuedByName:"",
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["TransactionDate", "TransactionNo","transferFacilityCode","PrepBy","AppBy1","AppDate1","IssuedBy","IssuedDate"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false
      }
    })
    setErrorObject(errorData);
    return isValid
  }

  const deleteReceiveInv=(data)=>{

    let newarray = ManyTableData.filter(element => element !== data);
    
    setManyTableData(newarray);
    setUpdatedDetailGridData(newarray);
    if(data.TransactionItemId!=null){
      let newDeletedDataSet=[data.TransactionItemId]; 
      setDeletedDataSet(newDeletedDataSet.concat(deletedDataSet)); 
    }
  }

  function checkValidateForm(rows, callfrom){

    if(callfrom == "edit"){
      setManyTableData(rows); 
    }

    // return validateForm(formData);
    if (validateForm(formData)) {
        let fDtat={...formData,'ManyJsonSave':rows,'deletedDataSet':deletedDataSet}; 
        //mutateUpdate(fDtat); 
        return fDtat;
      }else{
        return false;
      }
  };

  function onChangeToggleVal(value){
    setToggleValue(value);
  }

  function refetchInvoice(){
      // refetch();
      let fDtat = { 
        TransactionId: id,
        ItemGroupId: toggleValue 
      };

      mutateRefetch(fDtat);
  };

  function stockPosted(val){
    setbStockUpdate(val);
  };
  
  useEffect(() => {
    if(toggleValue !=''){
    refetchInvoice();
    }
  }, [toggleValue]);

  return (
    <>
      <DispenserFormData 
        errorObject={errorObject} 
        addProductForm={false}
        formData={formData} 
        handleChange={handleChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleCheck={handleCheck} 
        handleReset={handleReset} 
        // invoiceCancel={invoiceCancel} 
        // handleUpdate={handleUpdate}  
        ManyTableData={updatedDetailGridData} 
        handleRDateChange={handleRDateChange} 
        selectedDate={selectedDate} 
        handleAdd={handleAdd} 
        bStockUpdate={bStockUpdate} 
        // setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler} 
        // handlePost={handlePost} 
        deleteReceiveInv={deleteReceiveInv} 
        generatedInvoiceNo={generatedInvoiceNo} 
        bFirst={bFirst}

        checkValidateForm={checkValidateForm}
        refetchInvoice={refetchInvoice}
        stockPosted={stockPosted}
        onChangeToggleVal={onChangeToggleVal}

        // handleChangeToggle={handleChangeToggle}
        // toggleButtonValue={toggleButtonValue}
        
        {...props} />
    </>
  );
};
 
export default EditDispenseFacility;