import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel,
  RadioGroup,
  MenuItem,
  Paper,
  CardHeader,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import CloseIcon from "@material-ui/icons/Close";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

//get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "asset-register";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {/* {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const AssetStatusModal = ({ handleClose, open, addAssetStatus, ...props }) => {

  console.log("MOdal props.formData ",props);
  let tblRef = null;
  let submitBtnRef = useRef();

 /*  const { isLoading, error, isError, data, refetch } = useQuery(
    "AssetRegisterServer",
    api.getAssetList
  ); */
  const [selectedChangeStatus, setselectedChangeStatus] = useState([]);

  /* const ProductColumns = [
    { title: "id", field: "ItemNo", visible: false },
    { title: "ItemNo", field: "ItemNo", visible: false },
    { title: "Generic Name", field: "GenericName", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      formatter: "rownum",
      width: 50,
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
    },
    // { title: "Group Name", field: "GroupName", width: 140, headerFilter:true },
    {
      title: DispensingLanguage[lan][menukey]["Item Code"],
      field: "ItemCode",
      width: 160,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Group"],
      field: "GroupName",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Name"],
      field: "ItemName",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Unit"],
      field: "UnitName",
      width: 80,
    },
   
  ]; */

/*   let selected = [];
  const ProdustDataItems = data?.map((item, index) => {
    return item;
  });
 */
  const [btnDisable, setbtnDisable] = useState(false);


  const [formDataChangeStatus, setFormDataChangeStatus] = useState({
    StatusDate: null,
    StatusRemarks: "",
  });


  const SaveselectedChangeStatus = () => {
   

    if(formDataChangeStatus.StatusDate == "" || formDataChangeStatus.StatusDate == null) {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Status change date can not be blank"],
        msgtype: 0,
      });

    }else{
      
      setselectedChangeStatus(formDataChangeStatus);
    }

 
  };

  const [currStatusRemarks, setCurrStatusRemarks] = useState("");

  const handleStatusRemarksChange = (event) => {
    setCurrStatusRemarks(event.target.value);
  };

  /*   const SaveselectedChangeStatus = () => {
    if(tblRef.table.getSelectedData().length>0){ 
      
      if (submitBtnRef == null) return;
      else submitBtnRef.current.disabled = true; 
      setselectedChangeStatus(tblRef.table.getSelectedData());
    }
    
    
  };
 */

  useEffect(() => {

    addAssetStatus(selectedChangeStatus);
    handleClose("AssetStatus");
  }, [selectedChangeStatus]);


  const handleRDateChange = (date, field) => {
    let newDate;
    if (date == null || date == "") {
      newDate = "";
    } else {
      newDate = moment(date).format("YYYY-MM-DD");
    }

    setFormDataChangeStatus({ ...formDataChangeStatus, [field]: date });
    /* setErrorObject({ ...errorObject, [field]: null });
    api.dirtyModeEnable(); */
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formDataChangeStatus };
    data[name] = value;
    setFormDataChangeStatus(data);

    /* setErrorObject({ ...errorObject, [name]: null }); */
  };

  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose("AssetStatus")}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="xs"
        disableBackdropClick={true}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose("AssetStatus")}
        >
          {DispensingLanguage[lan][menukey]["Asset Status"]}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {/* <ReactTabulator
            ref={(r) => (tblRef = r)}
            options={{
              selectable: '1', // Only allow selecting one row at a time
              selectableRangeMode: 'click',
            }}
           
            columns={ProductColumns}
            data={ProdustDataItems}
            height="300px"
            layout={"fitColumns"}

          /> */}

          <Grid container spacing={3}>
            {/* Start warehouse dropdown list */}
            <Grid item xs={2} sm={2}></Grid>
            <Grid item xs={8} sm={8}>
              <MuiPickersUtilsProvider
                libInstance={moment}
                utils={DateFnsUtils}
              >
                <KeyboardDatePicker
                  variant="inline"
                  id="StatusDate"
                  label={DispensingLanguage[lan][menukey]["Status Change Date"]+"*"} 
                  autoOk={true}
                  name="StatusDate"
                  fullWidth
                  showTodayButton={true}
                  /* disableFuture={true} */
                  value={
                    formDataChangeStatus.StatusDate || null
                    //formData.dateofjoin || props.selectedDate
                  }
                  format="dd/MM/yyyy"
                  onChange={(e) => handleRDateChange(e, "StatusDate")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={2} sm={2}></Grid>
            {/* End warehouse dropdown list */}

            <Grid item xs={2} sm={2}></Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                //disabled={props.bStockUpdate}
                /*  error={props.errorObject.SupplierInvNo}
                 helperText={props.errorObject.SupplierInvNo} */
                id="StatusRemarks"
                name="StatusRemarks"
                label={DispensingLanguage[lan][menukey]["Comments"]}
                /* value={currStatusRemarks} */
                value={formDataChangeStatus.StatusRemarks}
                fullWidth
                multiline
                rows={2}
                autoComplete="family-name"
                /* onChange={handleStatusRemarksChange} */
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item xs={2} sm={2}></Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            ref={submitBtnRef}
            disabled={btnDisable}
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={SaveselectedChangeStatus}
          >
            {DispensingLanguage[lan][menukey]["Ok"]}
          </Button>
          {/* <Button
            autoFocus
            className="float-left"
            variant="contained"
            onClick={() => handleClose("AssetStatus")}
          >
            {DispensingLanguage[lan][menukey]["Cancel"]}
          </Button> */}
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default AssetStatusModal;
