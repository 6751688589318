import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import swal from "sweetalert";

import * as api from '../../../actions/api';
import {
  Typography,
  Grid,
  FormControlLabel,
  TextField,
  Button,
  Checkbox,
  FormControl,
  Card,
  CardContent,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import {  useQuery,  useMutation,  useQueryClient} from 'react-query';
import { DeleteOutline, Edit, ViewList, FileCopy } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import ExportButtonsManyWithExcel from "../../../components/ExportButtonsManyWithExcel";
//react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { useTranslation } from "react-i18next";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";
import { cellFocusEditor, dateEditor, dateEditorAllowPast, dateEditorAllowFuture } from "../../../services/Common";

import moment from "moment";
let timeStamp = "";


const DispenserFormData = (props) => {

  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "physical-inventory-entry";
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  let ReceiveDetailsRef = null;

  const [newBatchRowAdded, setNewBatchRowAdded] = useState(false);

  const [open, setOpen] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);

  const [SoureOpen, setSoureOpen] = useState(false);

  const [openContract, setContractOpen] = useState(false);

  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);
  const SupplierList = JSON.parse(localStorage.getItem("SupplierList"));
  const FacilityEmployee = JSON.parse(localStorage.getItem("FacilityEmployee"));
  const IndentorList = JSON.parse(localStorage.getItem("IndentorList"));

  const GroupNameList = JSON.parse(localStorage.getItem("GroupNameList"));
  GroupNameList.unshift({'id':0,'name':"All"});
  //GroupNameList.unshift({'id':0,'name':t(DispensingLanguage[lan][menukey]["All"])});

  
  const [currRowId, setCurrRowId] = useState(0);
  const [currFormulation, setCurrFormulation] = useState("");
  const [bIsDisable, setbIsEnable] = useState(false);
  const UseFor = localStorage.getItem("UseFor");

  const [isPreLoading, setPreLoading] = useState(false);


  const [firstLoad, setFirstLoad] = useState(true);
  const [radioValue, setRadioValue] = useState(0);
  const [FirstradioValue, setRadioValueHanddel] = useState(0);
  const [productGroupData, selectProductGroupData] = useState(0);


    //====== Start Choosen Dropdown===========
    const [chosenValues, setChosenValues] = useState({
      "PrepBy": {"EmpCode": "","EmpName": ""},
      "AppBy1": {"EmpCode": "","EmpName": ""}
    });

    const [chosenValuesGroup, setChosenValuesGroup] = useState({
      "GroupCode": {"id": "0","name": "All"}
    });
 
  useEffect(() => {
    let chosenValuesData = { ...chosenValues};
    chosenValuesData['PrepBy'] = FacilityEmployee[FacilityEmployee.findIndex(facilityEmployee_list => facilityEmployee_list.EmpCode == props.formData.PrepBy)]; 
    chosenValuesData['AppBy1'] = FacilityEmployee[FacilityEmployee.findIndex(facilityEmployee_list => facilityEmployee_list.EmpCode == props.formData.AppBy1)]; 
    setChosenValues(chosenValuesData); 

  }, [props.formData.PrepBy, props.formData.AppBy1]);

  
  const handleChangeChoosen = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues};
    chosenValuesData[name] = valueobj;   
    setChosenValues(chosenValuesData); 
    props.handleChangeChoosenProps(name, value);
  };

  const [all, setAll] = React.useState('0');
  const handleChangeChoosenGroup = (name, valueobj, value) => {
    let chosenValuesDataGroup = { ...chosenValuesGroup};
    chosenValuesDataGroup[name] = valueobj;   
    setChosenValuesGroup(chosenValuesDataGroup); 

    setAll(value);
    props.filterFandleChange(value);
    selectProductGroupData(value);
    props.handleChangeChoosenProps(name, value);
  };
  //====== End Choosen Dropdown===========



  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
   /*  let formstartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let formendtDate = moment(selectedEndDate).format("YYYY-MM-DD"); */
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=PhysicalInventoryEntryReport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        /* "&StartDate=" +
        formstartDate +
        "&EndDate=" +
        formendtDate +
        "&FacilityId=" +
        FacilityId +
        "&GroupCode=" +
        currGroupCode +
        "&ItemCode=" +
        currItemCode +
         "&AdjType=" +
         currAdjType +  */
         "&GroupCode=" +
        productGroupData +
        "&RadioValue=" +
        radioValue +
        "&FirstradioValue=" +
        FirstradioValue +
        "&TransactionId=" +
        props.formData.TransactionId +
        "&FacilityId=" +
        FacilityId +
        "&FacilityName=" +
        FacilityName + 
        "&UseFor=" +
        UseFor + 
        "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  
  /* =====Start of Print Excel Many Code==== */
  const EXCEL_EXPORT_URL_CUSTOM = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    
    let finalUrl =
    EXCEL_EXPORT_URL_CUSTOM +
      "report/physicalinventory_print.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TransactionId=" +
        props.formData.TransactionId +
        "&FacilityId=" +
        FacilityId +
        "&FacilityName=" +
        FacilityName +
        "&UseFor=" +
        UseFor +
        "&TimeStamp=" +
        Date.now()
    );
  };

  const ExcelMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL +
      "report/esigl_receive_from_warehouse_entry_list_excel.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TransactionId=" +
        props.formData.TransactionId +
        "&FacilityId=" +
        FacilityId +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */
  
  const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;
      case "OrderModal":
        setOpenOrder(false);
        break;
      case "OrderOpen":
        setOpenOrder(true);
        break;

      case "SourceOpen":
        setSoureOpen(true);
        break;

      case "SourceClose":
        setSoureOpen(false);
        break;

      case "Contract":
        setContractOpen(false);
        break;
        
      case "ContractOpen":
        setContractOpen(true);
        break;

      default:
        break;
    }
  };

  const addProduct = (NonOrderProductData) => {
    // console.log("NonOrderProductData: ", NonOrderProductData);
    // return;

    let temNonordata = NonOrderProductData;
    let rows = [];

    let autoId = 0;
    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;

      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.UnitName = row.UnitName;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.OrderRemainingQty = row.OrderRemainingQty;
      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.Quantity = row.Quantity;
      newRow.Comments = row.Comments;
      newRow.MfgDate = row.MfgDate;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = row.Quantity * row.UnitPrice;

      newRow.SourceId = row.SourceId;
      newRow.SourceName = row.SourceName;

      rows.push(newRow);
    });

    temNonordata.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;

      newRow.ItemCode = row.ItemCode;

      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.UnitName = row.UnitName;

      newRow.SourceId = null;
      newRow.SourceName = null;

      newRow.TransactionItemId = null;
      newRow.OrderRemainingQty = 0;

      // manyTableData.forEach((rowssss, ij) => {
      //   if (rowssss.ItemNo == row.ItemNo) {
      //     newRow.OrderRemainingQty = rowssss.OrderRemainingQty;
      //     return;
      //   }
      // });

      newRow.CurrentStockQty = 0;
      newRow.Quantity = 0;
      newRow.Comments = null;
      newRow.MfgDate = null;
      newRow.ExpiryDate = null;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = 0;
      rows.push(newRow);
    });

    

    setManyTableData(rows);
    props.handleAdd(rows);
    handleClose("Products");
    api.dirtyModeEnable();
  };

  const addContract = (ContractData) => {
    // console.log("ContractData: ", ContractData);

    // let row = ContractData;
    // let rows = [];
    
    // let newRow = {};
    // newRow.ContractName = row[0].ContractName;
    // newRow.ContractNo = row[0].ContractNo;
    // newRow.PackageName = row[0].PackageName;
    // newRow.PackageNo = row[0].PackageNo;
    // newRow.PackageYear = row[0].PackageYear;
    // newRow.YearId = row[0].YearId;
    // rows.push(newRow);

    // console.log(ContractData);
    // console.log(rows);

    // setManyTableData(rows);
    props.handleAddContract(ContractData);
    handleClose("Contract");
  };

  const addSource = (productSourceData) => {
    if (!productSourceData) {
      return;
    }

    // console.log("rows before pick product: ", manyTableData);
    // console.log("Product batch selected data: ", productBatchData);
    // console.log("Product batch selected currRowId: ", currRowId);

    let tempProductSourceData = productSourceData[0];
    let rows = [];

    let autoId = Date.now(); // 0;

    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};

      if (row.SKU === currRowId) {
        // if(row.TransactionItemId===null)
        // {
        //   //currFormulation
        // }
        // console.log("This is my row: ", row);
        // console.log("This is my row: ", temProductBatchData);
        newRow.SourceId = tempProductSourceData.SourceId;
        newRow.SourceName = tempProductSourceData.SourceName;
      } else {
        newRow.SourceId = row.SourceId;
        newRow.SourceName = row.SourceName;
      }

      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;

      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.OrderRemainingQty = row.OrderRemainingQty;
      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.Quantity = row.Quantity;
      newRow.Comments = row.Comments;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.SKU = row.SKU;
      newRow.id = row.id;
      newRow.LineTotal = row.Quantity * row.UnitPrice;

      // newRow.SourceId =row.SourceId;
      //newRow.SourceName =row.SourceName;

      rows.push(newRow);
    });

    // console.log("rows after link with product: ", rows);

    setManyTableData(rows);
    props.handleAdd(rows);
    //productAdd(rows);
    handleClose("SourceClose");
    api.dirtyModeEnable();
  };

  function ShowSourcePopup(propss: any) {
    const rowData = propss.cell._cell.row.data;

    if (props.bStockUpdate) {
      return <></>;
    } else {
      return (
        <>
          <ViewList
            onClick={() => {
              setCurrRowId(rowData.SKU);
              setCurrFormulation(rowData.SKU);
              handleClose("SourceOpen");
            }}
          />
        </>
      );
    }
  }

  const ReceiveDetailsColumns = [
    { title: "TransactionItemId", field: "TransactionItemId", visible: false },
    { title: "id", field: "id", visible: false },
    { title: "isedit", field: "isedit", width: 80, visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      width: 50,
      formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
    },

    {
      title: DispensingLanguage[lan][menukey]["Item Code"],
      field: "ItemCode",
      width: 110,
      headerFilter: true,
    },

    {
      title: DispensingLanguage[lan][menukey]["Item Name"],
      field: "ItemName",
      minWidth: 200,
      headerFilter: true,
    },
    
    {
      title: DispensingLanguage[lan][menukey]["Opening Balance"],
      field: "OpeningBalance",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    
    {
      title: DispensingLanguage[lan][menukey]["Received"],
      field: "ReceivedQty",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    
    {
      title: DispensingLanguage[lan][menukey]["Issued"],
      field: "IssuedQty",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["Returned"],
      field: "ReturnedQty",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    {
      title: DispensingLanguage[lan][menukey]["Closing Blance"],
      field: "ClosingBlance",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
    },
    
    {
      title: DispensingLanguage[lan][menukey]["Physical Count"],
      field: "Quantity",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      validator: ["integer", "min:0"],
      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      bottomCalcFormatter: "money",
      bottomCalcFormatterParams: {
        thousand: ",",
        precision: false,
      },
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },
      cellEdited: function (cell) {
        // const curcellval = cell.getValue();
        // const currow = cell.getRow();
        // const rowdata = currow.getData();
        // const pricecell = currow.getCell("LineTotal");
        // const totprice = rowdata.UnitPrice * curcellval;
        // pricecell.setValue(totprice);


        let currVal = cell.getValue();
        let currow = cell.getRow();
        let rowdata = currow.getData();
        
        let ClosingBlance = rowdata.ClosingBlance;
        let Quantity = rowdata.Quantity;

        if (currVal === "" || currVal === null) {
          currVal = 0;
        }

        if (ClosingBlance === "" || ClosingBlance === null) {
          ClosingBlance = 0;
        }

        let calculateValue =
          parseInt(Quantity) -
          parseInt(ClosingBlance);

        const calPushCell = currow.getCell("DifferenceQty");
        if (calPushCell) {
          console.log("calPushCell 1: ", calPushCell);
          calPushCell.setValue(calculateValue);
        }

        //Set flag this row edited
        let calPushCell12 = currow.getCell("isedit");
        calPushCell12.setValue(1);

      },
      formatter: "money",
      formatterParams:{ thousand:",",  precision:false },
    },
   /*  {
      title: DispensingLanguage[lan][menukey]["Difference"],
      field: "DifferenceQty",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
    }, */

    {
      title: DispensingLanguage[lan][menukey]["Difference"],
      field: "DifferenceQty",
      width: 90,
      hozAlign: "right",
      headerHozAlign: "right",
      //bottomCalc: "sum",
      headerSort: false,
       /*bottomCalcFormatter: "money",
      bottomCalcFormatterParams: {
        thousand: ",",
        precision: false,
      }, */
      formatter: function (cell, formatterParams) {
        // let value = cell.getValue();
        // return value == 0 ? "" : value;

        const currow = cell.getRow();
        const rowdata = currow.getData();
        const calculateValue = rowdata.Quantity - rowdata.ClosingBlance;

        if (calculateValue == 0 || rowdata.Quantity === "" || rowdata.Quantity == 0 || rowdata.Quantity === null) {
          return "";
        }else{
          return Number(calculateValue).toLocaleString();
        }

        /* return calculateValue == 0
          ? ""
          : Number(calculateValue).toLocaleString(); */


      },
    },
  /*   {
      title: DispensingLanguage[lan][menukey]["Remarks"],
      field: "Comments",
      width: 180,
      headerFilter: true,
    }, */

    {
      title: DispensingLanguage[lan][menukey]["Remarks"],
      field: "Comments",
      width: 170,
      hozAlign: "left",
      headerHozAlign: "left",
      //bottomCalc: "sum",
      headerSort: false,
      cssClass: "tabluator-column-editable text-field-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      // editor: cellFocusEditor,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },
      cellEdited: function (cell) {
         let currow = cell.getRow();

        //Set flag this row edited
        let calPushCell12 = currow.getCell("isedit");
        calPushCell12.setValue(1); 
      },
    },

   /*  {
      title: DispensingLanguage[lan][menukey]["Unit"],
      field: "UnitName",
      width: 80,
      // headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Lot No"],
      field: "BatchNo",
      width: 150,
      cssClass: "tabluator-column-editable text-field-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        //   props.setUpdatedDetailsGridDataHandler(rowdata);
      },
    }, */
    /* {
      title: DispensingLanguage[lan][menukey]["Mfg. Date"],
      field: "MfgDate",
      width: 100,
      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? dateEditorAllowPast : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry Date"],
      field: "ExpiryDate",
      width: 100,
      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? dateEditorAllowFuture : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
      },
    }, */
   /*  {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      headerHozAlign: "center",
      hozAlign: "center",
      width: 80,
      formatter: reactFormatter(
        <ActionButton/>
      ),
    }, */
  ];

  // const onPostBtnClick = () => {
  //   setbStockUpdate(true);
  //   props.handlePost();
  // };




	const handleChangePGroup = (event) => {
      setAll(event.target.value);
      props.filterFandleChange(event.target.value);
      selectProductGroupData(event.target.value);

        //========Start save on tab changed =============
        let manydata = ReceiveDetailsRef.table.getData();
        let isChanged = manydata[manydata.findIndex(many_data => many_data.isedit == 1)];
        if(isChanged){
          setUpdatedDetailsGridDataHandlerTabChange(manydata);
        }
        //==========End save on tab changed===========
        
      //setFirstLoad(true);
      //setLoading(true);
      //setPreLoading(true);
      
    };


    const changeRadioHanddel = (value) => {
      //setFirstLoad(true);
      //setLoading(true);
      if(!props.addProductForm){
        props.filterchangeRadioHanddel(value);
        setRadioValueHanddel(value);

         //========Start save on tab changed =============
          let manydata = ReceiveDetailsRef.table.getData();
          let isChanged = manydata[manydata.findIndex(many_data => many_data.isedit == 1)];
          if(isChanged){
            setUpdatedDetailsGridDataHandlerTabChange(manydata);
          }
          //==========End save on tab changed===========
       
      }

    };

    const changeRadio = (value) => {
      //setFirstLoad(true);
      //setLoading(true);
      if(!props.addProductForm){
        props.filterchangeRadioHanddeShowItem(value);
        setRadioValue(value);

          //========Start save on tab changed =============
          let manydata = ReceiveDetailsRef.table.getData();
          let isChanged = manydata[manydata.findIndex(many_data => many_data.isedit == 1)];
          if(isChanged){
            setUpdatedDetailsGridDataHandlerTabChange(manydata);
          }
          //==========End save on tab changed===========

      }

    };
  

    
  const setUpdatedDetailsGridDataHandlerTabChange = (rows) => {
    let validForm = props.checkValidateForm(rows,'edit');
      if (validForm) {
        if(props.addProductForm){
          //when save
          //mutate(validForm);
        }else{
          //when update
          mutateUpdateTabChange(validForm); 
        }
        
      }
  };
  


  const { isLoading: isLoadingUpdateTabChange, mutate:mutateUpdateTabChange } = useMutation(api.PhysicalInventoryEntryDataUpdate, {
    onSuccess: (data) => {
      if (data.status == 200) { 
        props.refetchInvoice(); 
       /*  if(data.bStockUpdated==1){
         mutatePost(props.formData.TransactionId);
        }
        else{
          props.refetchInvoice(); 
        
        } */

      }else{ 
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });


    
  const onPostBtnClick = () => {

    ///===================Check duplicate lots exist in invoice==========/////////////
    /*
    if (ReceiveDetailsRef == null) return;
    
    let invoiceLotIds = [];
    let duplicateinfo = {};
    ReceiveDetailsRef.table.getData().forEach((row, i) => {
      if(invoiceLotIds.includes(row.ItemListLotId)){
        duplicateinfo = row;
      }else{
        invoiceLotIds.push(row.ItemListLotId);
      }
    });
    
    if(Object.keys(duplicateinfo).length>0){
      props.openNoticeModal({
        isOpen: true,
        msg: t(DispensingLanguage[lan][menukey]["Duplicate Lot available"]) + ' - ' + duplicateinfo.ItemName,
        msgtype: false,
      });
      return;
    }*/
  ///===================Check duplicate lots exist in invoice==========/////////////


    setbStockUpdate(true);
    // props.handlePost();
    handlePost();
  };

  
  const handlePost = async (e) => {  
    
    let validForm = props.checkValidateForm(ReceiveDetailsRef.table.getData(), 'post');
    // console.log('isval: ', validForm);
    // console.log('props from post: ', props);

    // if (validateForm(formData)) {
      if (validForm) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you really want to post the stock?"]),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true, */
      }).then((willAction) => {
        if (willAction) {
          let fDtat={...validForm,"bStockUpdated":1};
          // console.log('fDtat: ', fDtat);
          mutateUpdate(fDtat); 
          
        }else{
          // setbStockUpdate(0);
          props.stockPosted(0);
          // let route = `${TransactionId}`;
          let route = `${validForm.TransactionId}`;
          props.history.push(route);
        }
      });  
      // End Post Alert

      
    }
     
     
  };



  const onSaveUpdateBtnClick = () => {
    // props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData());
    let manydata = ReceiveDetailsRef.table.getData();
    setUpdatedDetailsGridDataHandler(manydata);
  };

  
  const setUpdatedDetailsGridDataHandler = (rows) => {
    let validForm = props.checkValidateForm(rows,'edit');
    // console.log('isval: ', validForm);
      if (validForm) {
        // console.log('props.addProductForm: ', props.addProductForm);
        if(props.addProductForm){
          //when save
          mutate(validForm);
        }else{
          //when update
          mutateUpdate(validForm); 
        }
        

        
      }
  };


  
  
  const { isLoading: isLoadingSave, mutate } = useMutation(api.PhysicalInventoryEntryDataInsert, {
    onSuccess: (data) => { 
      if (data.status == 200) {

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      let route = `edit/${data.TransactionId}`;
      props.history.push(route); 
      
      }else{
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })  

  
  const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.PhysicalInventoryEntryDataUpdate, {
    onSuccess: (data) => {
      // console.log('mutateUpdate data: ', data);
      if (data.status == 200) { 
      //  console.log("props: ",props.formData.TransactionId);
        //setbStockUpdate(data.bStockUpdated);
        // props.successSave(data.bStockUpdated);
        if(data.bStockUpdated==1){
         mutatePost(props.formData.TransactionId);
        }
        else{
          // props.refetchInvoice(data.bStockUpdated); //refetch(); 
          props.refetchInvoice(); //refetch(); 
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
 
        }

      }else{ 
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });



  
  const { isLoading: isLoadingPost, mutate:mutatePost } = useMutation(api.postPhysicalInventoryEntry, {
    onSuccess: (data) => { 
      if (data.status == 200) { 

       
       // setDeletedDataSet([]);

        if(data.data.success==0){ 
          //setbStockUpdate(0);
          props.stockPosted(0);
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
         
         }else{
          // setbStockUpdate(1);
          props.stockPosted(1);
          props.refetchInvoice();

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
  
         } 

      }else{
        // setbStockUpdate(0);
        props.stockPosted(0);
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    }
  });


  const onGeneratePhysicalInventoryBtnClick = () => {
    setPreLoading(true);
    // props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData());
    let manydata = ReceiveDetailsRef.table.getData();
    setGeneratedDetailsGridDataHandler(manydata);
  };

  
  const setGeneratedDetailsGridDataHandler = (rows) => {
    let validForm = props.checkValidateForm(rows,'edit');
    // console.log('isval: ', validForm);
      if (validForm) {
        // console.log('props.addProductForm: ', props.addProductForm);
        if(props.addProductForm){
         
          //when save
          //mutateGenerate(validForm);
        }else{
          //when update
         mutateGenerateUpdate(validForm); 
        }
        

        
      }
  };


   
  const { isLoading: isLoadingUpdate2, mutate:mutateGenerateUpdate } = useMutation(api.PhysicalInventoryEntryGeneratedDataUpdate, {
    onSuccess: (data) => {
      // console.log('mutateUpdate data: ', data);
      if (data.status == 200) { 
        setPreLoading(false);
        if(data.bStockUpdated==1){
         //mutatePost(props.formData.TransactionId);
        }
        else{
          props.refetchInvoice(); //refetch(); 
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
 
        }

      }else{ 
        setPreLoading(false);
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });

  

  const invoiceCancel = (e)=>{

    const { name, checked } = e.target;
    let validForm = props.checkValidateForm(ReceiveDetailsRef.table.getData(), 'cancel');

    if (validForm) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you really want to cancel?"])+" "+t(DispensingLanguage[lan][menukey]["You will not able to edit this invoice after cancel"]),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true, */
      }).then((willCancel) => {
        if (willCancel) {
          // let fDtat={...formData,'ManyJsonSave':ManyTableData,"BCancel":1,"TableName":"recinv",'deletedDataSet':deletedDataSet};
          let fDtat={...validForm,"BCancel":1,"TableName":"recinv"};
          mutateUpdate(fDtat);
        }else{
          //setFormData({ ...formData, [name]: false });
        }
      });

    }

  };


  const deleteReceiveInv = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["Do you really want to delete the selected item?"]),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willDelete) => {
      if (willDelete) {
        let newarray = manyTableData.filter(element => element !== data); 
        setManyTableData(newarray); 
        props.deleteReceiveInv(data); 
        
    api.dirtyModeEnable();
      }
    });
  };

  const CopyBatch = () => {
    props.handleAdd(manyTableData);
  };



  const deletePhysicalInventoryItems = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Do you really want to delete the selected physical inventory?"
        ]
      ),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },

    }).then((willDelete) => {
      if (willDelete) {
        deletePhysicalInventoryItemsMutate(props.formData.TransactionNo);
      }
    });
  };

  const { isLoading: isLoadingDeleteMany, mutate: deletePhysicalInventoryItemsMutate } =
    useMutation(api.deletePhysicalInventoryItems, {
      onSuccess: (data) => {
        if (data.status == 200) {
          //props.ReloadItemTable();
          props.refetchInvoice();

          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
            duration: 10000,
          });
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      },
    });

  function ActionButton(props) {

    const rowData = props.cell._cell.row.data;

    const goToDetails = () => {
      let rowobj = props.cell._cell.row.data; 

      timeStamp = Math.round(new Date().getTime() / 100);

      setManyTableData((prevRows) => {
        const tmpPrevRows = [...prevRows];

        let setRposition = tmpPrevRows.findIndex((o) => o.SKU == rowobj.SKU);

        const obj = { ...rowobj };

        obj.id = timeStamp;
        obj.TransactionItemId = "";
        //obj.ExpiryDate = "";
        obj.Comments = "";
        obj.Quantity = 0;
        obj.SKU = Date.now();

        tmpPrevRows.splice(setRposition + 1, 0, obj);

        return tmpPrevRows;
      });

      // props.handleAdd(manyTableData);
      CopyBatch();
      setNewBatchRowAdded((prevNewBatchRowAdded) => !prevNewBatchRowAdded);
    };

    const cellValue = props.cell._cell.value || "Edit | Show";

    if (bStockUpdate == 0)
      return (
        <>
        
          {/* <FileCopy
            onClick={() => {
              if (!bStockUpdate) goToDetails(rowData);
            }}
          /> */}

          <DeleteOutline
            onClick={() => {
              
              if (!bStockUpdate) deleteReceiveInv(rowData);
            }}
          />
        </>
      );
    else return <></>;
  }

  useEffect(() => {
    if (ReceiveDetailsRef == null) return;
    if (props.bFirst) {
      setManyTableData(props.ManyTableData);
    }

    setbStockUpdate(props.bStockUpdate);
  }, [props.ManyTableData, props.bStockUpdate]); 

  useEffect(() => {
    if (ReceiveDetailsRef.table != null && timeStamp != "") {
      ReceiveDetailsRef.table.scrollToRow(timeStamp, "center", true);
      ReceiveDetailsRef.table.selectRow(timeStamp);
    }
  }, [newBatchRowAdded]);

  
  const backtolist=()=>{


    let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
    let idx = master_dirty_message_class.indexOf("dnone");
    if(idx === -1){
      // start Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          props.history.push("/physical-inventory-entry");
        }
      });
      // End Alert
    }else{
      props.history.push("/physical-inventory-entry");
    }
  
  }
  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Physical Inventory - Add/Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {props.addProductForm ? (
                  <Grid item xs={12} className="text-center">
                    <div className="float-right sw_btn_control">
                      <Button
                        disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => props.handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button>
                      <Button
                        disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onSaveUpdateBtnClick}
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        disabled={isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        // onClick={() => props.history.push("/physical-inventory-entry")}
                        onClick={() => backtolist()}
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="mt-2 text-center">
                    <Button
                      disabled={props.bStockUpdate || isLoadingUpdate || isLoadingPost}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    <Button
                      // disabled={props.bStockUpdate}
                      disabled={props.bStockUpdate || !(manyTableData.length>0) || isLoadingUpdate || isLoadingPost}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onPostBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["post"]}
                    </Button>
                    <Button
                      disabled={isLoadingUpdate || isLoadingPost}
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      // onClick={() => props.history.push("/physical-inventory-entry")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>

                    <ExportButtonsManyWithExcel PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}  {...props}></ExportButtonsManyWithExcel>
                    {/* <Tooltip title="Print"> 
                    <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PrintPDFExcelExportFunction("print")}
                    >
                      <i class="fas fa-print"></i>
                    </Button>
                  </Tooltip>
                  <Tooltip title="Excel"> 
                  <Button
                    variant="contained"
                    color="primary"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>
                </Tooltip> */}

                    {/* <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PrintMany()}
                    >
                      <i class="fas fa-print"></i>
                    </Button> */}

                    {/* <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelMany()}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button> */}
                  </Grid>
                )}
              </div>

              <div className="mr-4 float-right mt-15">
                <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
              </div>

            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            {/* <Card className="sw_card">
            <CardHeader title="Personal Information" />
            <CardContent> */}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={1} sm={1}>
                    <TextField
                      disabled={true /*bStockUpdate*/}
                      error={props.errorObject.TransactionNo}
                      helperText={props.errorObject.TransactionNo}
                      required
                      id="TransactionNo"
                      name="TransactionNo"
                      label={
                        DispensingLanguage[lan][menukey]["Invoice No"]
                      }
                      value={
                        props.formData.TransactionNo || props.generatedInvoiceNo
                      }
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={2} sm={2}>
                    {/* <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsUtils} >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate || manyTableData.length>0}
                        required
                        error={props.errorObject.StartDate}
                        helperText={props.errorObject.StartDate}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        id="StartDate"
                        label={DispensingLanguage[lan][menukey]["Start Date"]}
                        autoOk={true}
                        name="StartDate"
                        fullWidth
                        showTodayButton={true}
                        disableFuture={true}
                        value={
                          props.formData.StartDate || null
                        }
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "StartDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider> */}

                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                              <KeyboardDatePicker
                                disabled={props.bStockUpdate || manyTableData.length>0}
                                required
                                error={props.errorObject.StartDate}
                                helperText={props.errorObject.StartDate}
                                className={classes.fullWidth}
                                //disableToolbar
                                autoOk={true}
                                // views={["year", "month"]}
                                variant="inline"
                                // format="MM/yyyy"
                                format="dd/MM/yyyy"
                                id="date-picker-inline"
                                label={DispensingLanguage[lan][menukey]["Start Date"]}
                                value={props.formData.StartDate || null}
                                onChange={(e) =>
                                  props.handleRDateChange(e, "StartDate")
                                }
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                disableFuture={true}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={2} sm={2}>
                    {/* <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate || manyTableData.length>0}
                        required
                        error={props.errorObject.TransactionDate}
                        helperText={props.errorObject.TransactionDate}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        id="TransactionDate"
                        label={DispensingLanguage[lan][menukey]["End Date"]}
                        autoOk={true}
                        name="TransactionDate"
                        fullWidth
                        showTodayButton={true}
                        disableFuture={true}
                        value={
                          props.formData.TransactionDate || null
                        }
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "TransactionDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider> */}

                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                              <KeyboardDatePicker
                                disabled={props.bStockUpdate || manyTableData.length>0}
                                required
                                error={props.errorObject.TransactionDate}
                                helperText={props.errorObject.TransactionDate}
                                className={classes.fullWidth}
                                //disableToolbar
                                autoOk={true}
                                // views={["year", "month"]}
                                variant="inline"
                                // format="MM/yyyy"
                                format="dd/MM/yyyy"
                                id="date-picker-inline"
                                label={DispensingLanguage[lan][menukey]["End Date"]}
                                value={props.formData.TransactionDate || null}
                                onChange={(e) =>
                                  props.handleRDateChange(e, "TransactionDate")
                                }
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                disableFuture={true}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                  </Grid>

                 <Grid item xs={3} sm={3}>
                    {/* <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Prepared By"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.PrepBy}
                        labelId="demo-simple-select-helper-label"
                        id="PrepBy"
                        name="PrepBy"
                        value={props.formData.PrepBy}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={props.bStockUpdate}
                      >
                        {FacilityEmployee.map((item, index) => {
                          return (
                            <MenuItem value={item.EmpCode}>
                              {item.EmpName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={props.errorObject.PrepBy}>
                        {props.errorObject.PrepBy}
                      </FormHelperText>
                    </FormControl> */}

                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        disabled={props.bStockUpdate}
                        hidden={props.bStockUpdate}
                        autoHighlight
                        id="PrepBy"
                        options={FacilityEmployee}
                        onChange={(event, valueobj) => handleChangeChoosen('PrepBy', valueobj, valueobj?valueobj.EmpCode:"")}
                        getOptionLabel={(option) => option.EmpName}
                        value={chosenValues['PrepBy']}
                        renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.EmpName}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          value={props.formData.PrepBy}
                          label={DispensingLanguage[lan][menukey]["Prepared By"]+ " *"}
                          variant="standard"
                          error={props.errorObject.PrepBy}
                          helperText={props.errorObject.PrepBy}
                          id="PrepBy"
                          name="PrepBy"
                          fullWidth
                        />
                        )}
                      />

                      <TextField
                        id="PrepByName"
                        name="PrepByName"
                        label={DispensingLanguage[lan][menukey]["Prepared By"]+ " *"}
                        value={props.formData.PrepByName}
                        disabled= {true}
                        hidden={!props.bStockUpdate}
                        fullWidth
                        autoComplete="family-name"
                        
                      />

                    </FormControl>
                  </Grid> 

                  <Grid item xs={2} sm={2}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        required
                        error={props.errorObject.PrepDate}
                        helperText={props.errorObject.PrepDate}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        id="PrepDate"
                        label={
                          DispensingLanguage[lan][menukey]["Prepared Date"]
                        }
                        autoOk={true}
                        name="PrepDate"
                        fullWidth
                        showTodayButton={true}
                        disableFuture={true}
                        value={props.formData.PrepDate || null}
                        format="dd/MM/yyyy"
                        onChange={(e) => props.handleRDMYDateChange(e, "PrepDate")}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={1} sm={1}>
                    <FormControlLabel
                        control={
                          <Checkbox
                            id="bUpdated"
                            disabled={true}
                            color="primary"
                            checked={props.formData.bUpdated > 0 ? true : false}
                            name="bUpdated"
                            value="no"
                          />
                        }
                        label={DispensingLanguage[lan][menukey]["Updated"]} 
                      />
                  </Grid>


                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                      disabled={props.bStockUpdate}
                      hidden={props.bStockUpdate}
                      autoHighlight
                      id="AppBy1"
                      options={FacilityEmployee}
                      onChange={(event, valueobj) => handleChangeChoosen('AppBy1', valueobj, valueobj?valueobj.EmpCode:"")}
                      getOptionLabel={(option) => option.EmpName}
                      value={chosenValues['AppBy1']}
                      renderOption={(option) => (
                      <Typography className="sw_fontSize">{option.EmpName}</Typography>
                      )}
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        value={props.formData.AppBy1}
                        label={DispensingLanguage[lan][menukey]["Approved By"]+ " *"}
                        variant="standard"
                        error={props.errorObject.AppBy1}
                        helperText={props.errorObject.AppBy1}
                        id="AppBy1"
                        name="AppBy1"
                        fullWidth
                      />
                      )}
                      />

                      <TextField
                      id="AppByName"
                      name="AppByName"
                      label={DispensingLanguage[lan][menukey]["Approved By"]+ " *"}
                      value={props.formData.AppByName}
                      disabled= {true}
                      hidden={!props.bStockUpdate}
                      fullWidth
                      autoComplete="family-name"
                      
                      />

                    </FormControl>
                  </Grid> 

                  <Grid item xs={2} sm={2}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                      disabled={props.bStockUpdate}
                      required
                      error={props.errorObject.AppDate1}
                      helperText={props.errorObject.AppDate1}
                      className={classes.fullWidth}
                      //disableToolbar
                      variant="inline"
                      id="AppDate1"
                      label={
                        DispensingLanguage[lan][menukey]["Approved Date"]
                      }
                      autoOk={true}
                      name="AppDate1"
                      fullWidth
                      showTodayButton={true}
                      disableFuture={true}
                      value={props.formData.AppDate1 || null}
                      format="dd/MM/yyyy"
                      onChange={(e) => props.handleRDMYDateChange(e, "AppDate1")}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  {/* <Grid item xs={4} sm={4}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label-receive-from">
                        {DispensingLanguage[lan][menukey]["Supplier"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.SupCode}
                        labelId="demo-simple-select-helper-label-receive-from"
                        id="SupCode"
                        name="SupCode"
                        value={props.formData.SupCode}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={
                          manyTableData.length > 0 ? true : props.bStockUpdate
                        }
                      >
                        {SupplierList.map((item, index) => {
                          return (
                            <MenuItem value={item.SupCode}>{item.SupName}</MenuItem>
                          );
                        })}
                      </Select>

                      <FormHelperText error={props.errorObject.SupCode}>
                        {props.errorObject.SupCode}
                      </FormHelperText>
                    </FormControl>
                  </Grid> */}

                  {/* <Grid item xs={4} sm={4}>
                    <TextField
                      disabled={props.bStockUpdate}
                      error={props.errorObject.SupplierInvNo}
                      helperText={props.errorObject.SupplierInvNo}
                      id="SupplierInvNo"
                      name="SupplierInvNo"
                      label={DispensingLanguage[lan][menukey]["Local Purchase Invoice No"] + " *"}
                      value={props.formData.SupplierInvNo}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid> */}

                 {/*  <Grid item xs={4} sm={4}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        error={props.errorObject.SupplierInvDate}
                        helperText={props.errorObject.SupplierInvDate}
                        className={classes.fullWidth}
                        // disableToolbar
                        variant="inline"
                        id="SupplierInvDate"
                        label={
                          DispensingLanguage[lan][menukey][
                            "Local Purchase Invoice Date"
                          ] + " *"
                        }
                        autoOk={true}
                        name="SupplierInvDate"
                        fullWidth
                        showTodayButton={true}
                        disableFuture={true}
                        value={
                          props.formData.SupplierInvDate || null
                        }
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "SupplierInvDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid> */}
                  
                  {/* <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={props.bStockUpdate}
                      id="BLNo"
                      name="BLNo"
                      label={DispensingLanguage[lan][menukey]["BL No"]}
                      value={props.formData.BLNo}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid> */}

                  {/* <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        className={classes.fullWidth}
                        // disableToolbar
                        variant="inline"
                        id="BLDate"
                        label={
                        DispensingLanguage[lan][menukey]["BL Date"]}
                        autoOk={true}
                        name="BLDate"
                        fullWidth
                        showTodayButton={true}
                        value={
                          props.formData.BLDate || props.selectedDate
                        }
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "BLDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid> */}

                  {/* <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Procured By"]} 
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="IndentorId"
                        name="IndentorId"
                        value={props.formData.IndentorId}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={props.bStockUpdate}
                      >
                        {IndentorList.map((item, index) => {
                          return (
                            <MenuItem value={item.IndentorId}>
                              {item.IndentorName} - {item.OPDescription}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid> */}

                 {/*  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={true}
                      id="PackageYear"
                      name="PackageYear"
                      label={DispensingLanguage[lan][menukey]["Year"]}
                      value={props.formData.PackageYear}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid> */}

                  {/* <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={true}
                      id="PackageName"
                      name="PackageName"
                      label={DispensingLanguage[lan][menukey]["Package No"]}
                      value={props.formData.PackageName}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid> */}

                  {/* <Grid item xs={2} sm={2}>
                    <TextField
                      disabled={true}
                      id="ContractName"
                      name="ContractName"
                      label={DispensingLanguage[lan][menukey]["Contract No"]}
                      value={props.formData.ContractName}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid> */}

                  {/*  <Grid item xs={1} sm={1}>
                    <Button
                      disabled={props.bStockUpdate}
                      className="pick_btn"
                      variant="contained"
                      color="success"
                      onClick={() => handleClose("ContractOpen")}
                    >
                      ...
                    </Button>

                    <ContractModal
                      handleClose={handleClose}
                      open={openContract}
                      addContract={addContract}
                      {...props}
                    />
                  </Grid> */}

                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={props.bStockUpdate}
                      id="Remarks"
                      name="Remarks"
                      label={DispensingLanguage[lan][menukey]["Remarks"]}
                      value={props.formData.Remarks}
                      fullWidth
                      multiline
                      rows={3}
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>


                  {/* <Grid item xs={12} className="marginTop10"> */}

                  
                 {/*  <Grid item xs={1} sm={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="BCancel"
                          disabled={props.bStockUpdate || (props.addProductForm)}
                          color="primary"
                          checked={props.formData.BCancel > 0 ? true : false}
                          // onChange={(e) => props.invoiceCancel(e)}
                          onChange={(e) => invoiceCancel(e)}
                          name="BCancel"
                          value="no"
                        />
                      }
                      label={DispensingLanguage[lan][menukey]["CANCEL"]}
                    />
                  </Grid> */}
              

                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="TransactionId"
                      name="TransactionId"
                      label="TransactionId"
                      value={props.formData.TransactionId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                {/* new row */}
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card">
                    <CardContent>
                      <Grid container className="mb-2">

                        <Grid item xs={2} sm={2}>
                          <FormControl className={classes.fullWidth}>
                            {/* <InputLabel id="">
                              {DispensingLanguage[lan][menukey]["Group Name"]}
                            </InputLabel>
                            <Select
                             disabled={props.addProductForm || isLoadingUpdate2}
                              // error={props.errorObject.SupCode}
                              labelId="GroupCode"
                              id="GroupCode"
                              name="GroupCode"
                              defaultValue='0'
                              fullWidth
                              onChange={handleChangePGroup}
                             
                            >

                              {GroupNameList.map((item, index) => {
                                return (
                                  <MenuItem value={item.id}>{item.name}</MenuItem>
                                );
                              })} 
                            </Select> */}

                        <Autocomplete
                          autoHighlight
                          disabled={props.addProductForm || isLoadingUpdate2}
                          disableClearable
                          id="GroupCode"
                          options={GroupNameList}
                          onChange={(event, valueobj) => handleChangeChoosenGroup('GroupCode', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValuesGroup['GroupCode']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            //value={props.formData.GroupCode}
                            label={DispensingLanguage[lan][menukey]["Group Name"]}
                            variant="standard"
                            //error={props.errorObject.GroupCode}
                            //helperText={props.errorObject.GroupCode}
                            id="GroupCode"
                            name="GroupCode"
                            fullWidth
                          />
                          )}
                        />

                          </FormControl>
                        </Grid>

                         <Grid item xs={3} sm={3}>
                          <FormControl component="fieldset" className="mt-3 ml-3">
                            <RadioGroup
                              row
                              aria-label="gender"
                              name="row-radio-buttons-group"
                              defaultValue="Usable_Stock"
                             
                            >
                              
                              <FormControlLabel
                                value="Usable_Stock"
                                control={<Radio />}
                                label={DispensingLanguage[lan][menukey]["Usable Stock"]}
                                onClick={() => changeRadioHanddel(0)}
                                disabled={props.addProductForm || isLoadingUpdate2}
                                
                              />
                              <FormControlLabel
                                value="Unusable_Stock"
                                control={<Radio />}
                                label={DispensingLanguage[lan][menukey]["Unusable Stock"]}
                                onClick={() => changeRadioHanddel(1)}
                                disabled={props.addProductForm || isLoadingUpdate2}
                              />
                              
                              
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        <Grid item xs={5} sm={5}>
                          <FormControl component="fieldset" className="mt-3 ml-3">
                            <RadioGroup
                              row
                              aria-label="gender"
                              name="row-radio-buttons-group"
                              defaultValue="SHOW_NON_ZERO"
                            >
                              <FormControlLabel
                                value="SHOW_NON_ZERO"
                                control={<Radio />}
                                label={DispensingLanguage[lan][menukey]["Show Only Non-Zero Stock"]}
                                onClick={() => changeRadio(0)}
                                disabled={props.addProductForm || isLoadingUpdate2}
                              />
                              <FormControlLabel
                                value="ZERO"
                                control={<Radio />}
                                label={DispensingLanguage[lan][menukey]["Show Only Zero Stock"]}
                                onClick={() => changeRadio(1)}
                                disabled={props.addProductForm || isLoadingUpdate2}
                              />
                              <FormControlLabel
                                value="SHOW_ALL"
                                control={<Radio />}
                                label={DispensingLanguage[lan][menukey]["Show All Items"]}
                                onClick={() => changeRadio(2)}
                                disabled={props.addProductForm || isLoadingUpdate2}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        <Grid className="text-center" item xs={1} sm={1}>
                          <div className="float-right">
                            <Button
                              disabled={props.addProductForm || props.bStockUpdate || isLoadingUpdate2}
                              // disabled={ props.bStockUpdate || (props.formData.TransactionId > 0 ? false : true) }
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              //onClick={() => handleClose("ProductsOpen")}
                              onClick={onGeneratePhysicalInventoryBtnClick}
                            >
                              {
                                DispensingLanguage[lan][menukey]["GENERATE"]
                              }
                            </Button>
                          </div>
                        </Grid>

                        <Grid className="text-center" item xs={1} sm={1}>
                          <div className="float-right">
                            <Button
                              disabled={props.addProductForm || props.bStockUpdate || isLoadingUpdate2}
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              onClick={() => deletePhysicalInventoryItems()}
                            >
                              {
                                DispensingLanguage[lan][menukey]["DELETE"]
                              }
                            </Button>
                          </div>
                        </Grid>

                      </Grid>


                      <div>
                        <div className="sw_relative">
                          <div className="uniqueName">
                          {isPreLoading && <LoadingSpinnerOpaque />}
                          <ReactTabulator
                              ref={(r) => (ReceiveDetailsRef = r)}
                              columns={ReceiveDetailsColumns}
                              data={manyTableData}
                              height="350px"
                              layout={"fitColumns"}
                              options={{
                                groupBy: "GroupName",
                                columnCalcs: "both",
                              }}
                            />
                          </div>
                        </div>
                      </div>


                    </CardContent>
                  </Card>
                </Grid>


              </Grid>




            </Grid>

            {/* </CardContent>
          </Card> */}
          </Grid>

          {/* Action buttons */}
        </Grid>
      </div>
    </div>
  );
};

export default DispenserFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
