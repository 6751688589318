import React, { useState, useEffect } from "react"; //, useEffect
import { Typography } from "@material-ui/core";

import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import MasterManyFormData from "./UpazilaCustomSupplyPlanEntryItems.js";

import Constants from "../../../services/Constants";
// ssssssssssssssss
const AddUpazilaCustomSupplyPlanEntry = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "upazila-custom-supply-plan";

  const { t, i18n } = useTranslation();
  const FacilityId = localStorage.getItem("FacilityId");

  // const CurrentFacilitySetting = JSON.parse(localStorage.getItem('CurrentFacilitySetting'));
  // const CurrFacilitySetting = CurrentFacilitySetting[0];

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState("");
  const [formData, setFormData] = useState({
    TransactionDate: selectedDate,
    YearId: "",
    PackageName: "",
    ContractName: "",
  });

  const [ManyTableData, setManyTableData] = useState([]);
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);

  const handleRDateChange = (date, field) => {
    setFormData({ ...formData, [field]: date });
    setErrorObject({ ...errorObject, [field]: null });
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [errorObject, setErrorObject] = useState({});

  const { id } = useParams();
  const queryClient = useQueryClient();

  // let params={"menukey": menukey, "FacilityId":FacilityId, "TransactionTypeId":1, TransactionDate: '', TableName: 'provisionalrecinv'};
  // const {  data:invoiceNo, refetch } = useQuery([params], api.getInvoiceNo,
  //   {onSuccess: (data) => {

  //     setGeneratedInvoiceNo(data);
  //     let fdata = { ...formData };
  //     fdata["TransactionNo"] = data;
  //     setFormData(fdata);

  //   }}
  //   );
  // alert(85);
  // let id=11;
  // const { data,refetch } = useQuery(['upazila-custom-supply-plan', id], () =>
  //   api.getUpazilaCustomSupplyPlanItems(id).then((res) => {

  //     // setTransactionId(id);
  //     // setFormData(res.data);
  //     // setbStockUpdate(res.data.bStockUpdated);
  //     // setManyTableData(res.data.ManyJsonSave);//res.data.ManyJsonSave
  //     // setUpdatedDetailGridData(res.data.ManyJsonSave);
  //     // setGeneratedInvoiceNo(res.data.TransactionNo);
  //     // setDeletedDataSet([]);
  //     // setBFirst(true);

  //     // return res.data
  //   }), {
  //   refetchOnWindowFocus: false,
  //   enabled: Boolean(id)
  // })

  const { isLoading1, mutate: mutatePost } = useMutation(
    api.postReceiveFromSupplier,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          if (data.data.success == 0) {
            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });
          } else {
            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });
          }
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
        }
      },
    }
  );

  const handleAdd = (NonOrderProductData) => {
    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);
  };

  const handleAddContract = (ContractData) => {
    let fdata = { ...formData };
    let row = ContractData;

    fdata["ContractName"] = row[0].ContractName;
    fdata["ContractNo"] = row[0].ContractNo;
    fdata["PackageName"] = row[0].PackageName;
    fdata["PackageNo"] = row[0].PackageNo;
    fdata["PackageYear"] = row[0].PackageYear;
    fdata["YearId"] = row[0].YearId;

    setFormData(fdata);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };

    data[name] = value;
    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const invoiceCancel = (e) => {
    // const { name, checked } = e.target;
    // swal({
    //   title: DispensingLanguage[lan][menukey]["Are you sure?"],
    //   text: DispensingLanguage[lan][menukey]["Do you really want to cancel?"]+" "+DispensingLanguage[lan][menukey]["You will not able to edit this invoice after cancel"],
    //   icon: "warning",
    //   buttons: [DispensingLanguage[lan][menukey]["No"],
    //   DispensingLanguage[lan][menukey]["Yes"]],
    //   dangerMode: true,
    // }).then((willCancel) => {
    //   if (willCancel) {
    //     let fDtat={...formData,'ManyJsonSave':ManyTableData,"BCancel":1,"menukey":"receive"};
    //     mutate(fDtat);
    //   }else{
    //     setFormData({ ...formData, [name]: false });
    //   }
    // });
  };

  const handleReset = () => {
    setFormData({
      TransactionDate: "",
      YearId: "",
      PackageName: "",
      ContractName: "",
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["TransactionDate", "YearId", "ContractName"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleSubmit = async (e) => {
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        ManyJsonSave: ManyTableData,
        menukey: menukey,
      };
      mutate(fDtat);
    }
  };
  const deleteReceiveInv = (data) => {
    let newarray = ManyTableData.filter((element) => element !== data);
    setManyTableData(newarray);
  };

  // const setUpdatedDetailsGridDataHandler = (rows, disapprovedItemsRows) => {
    const setUpdatedFacilityProductGridDataHandler = (rows) => {

    // if (validateForm(formData)) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey][
            "Do you want to generate Issue Voucher from custom supply plan?"
          ]
        ),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        /* buttons: [
          t(DispensingLanguage[lan][menukey]["No"]),
          t(DispensingLanguage[lan][menukey]["Yes"]),
        ],
        dangerMode: true, */
      }).then((willCancel) => {
        if (willCancel) {
          let fDtat = {...formData,ManyJsonSave: rows,menukey: menukey,};
          mutate(fDtat);
        }
      });
    // }

    // setManyTableData(rows);
  };

  const { isLoading, mutate } = useMutation(api.CustomUpazilaSupplyPlanFacilityProductInsUp, {
    onSuccess: (data) => {
      if (data.status == 200) {

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

        let route = "issuevoucher";
        props.history.push(route);  


        // mutatePost(data.TransactionId);
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  useEffect(() => {}, []);

  return (
    <>
      <MasterManyFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        invoiceCancel={invoiceCancel}
        handleSubmit={handleSubmit}
        ManyTableData={updatedDetailGridData}
        handleRDateChange={handleRDateChange}
        selectedDate={selectedDate}
        deleteReceiveInv={deleteReceiveInv}
        setUpdatedFacilityProductGridDataHandler={setUpdatedFacilityProductGridDataHandler}
        bStockUpdate={0}
        handleAdd={handleAdd}
        generatedInvoiceNo={generatedInvoiceNo}
        handleAddContract={handleAddContract}
        {...props}
      />
    </>
  );
};

export default AddUpazilaCustomSupplyPlanEntry;
