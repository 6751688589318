import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  FormControl,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';
import swal from "sweetalert";
import * as api from "../../../actions/api";

const EntryandEditofPackageFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  const classes = useStyles();
//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "entry-and-edit-of-package";
const DistrictList = JSON.parse(localStorage.getItem("DistrictList"));

const IndentorList = JSON.parse(localStorage.getItem("IndentorList"));
const PackageYearList = JSON.parse(localStorage.getItem("PackageYearList"));


  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

//====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "IndentorId": {"IndentorId": "","IndentorName": ""},
  "YearId": {"YearId": "","PackageYear": ""}
});


useEffect(() => {
  
  let chosenValuesData = { ...chosenValues};
   chosenValuesData['IndentorId'] = IndentorList[IndentorList.findIndex(Indentor_List => Indentor_List.IndentorId == formData.IndentorId)];
   chosenValuesData['YearId'] = PackageYearList[PackageYearList.findIndex(PackageYear_List => PackageYear_List.YearId == formData.YearId)];
   setChosenValues(chosenValuesData); 

  }, [formData.IndentorId, formData.YearId]);

const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  setChosenValues(chosenValuesData); 
  props.handleChangeChoosenProps(name, value);
};

//====== End Choosen Dropdown===========


const backtolist=()=>{


  let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
  let idx = master_dirty_message_class.indexOf("dnone");
  if(idx === -1){
    // start Alert
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then((willAction) => {
      if (willAction) {
        props.history.push("/entry-and-edit-of-package");
      }
    });
    // End Alert
  }else{
    props.history.push("/entry-and-edit-of-package");
  }

}




const onSaveUpdateBtnClick = () => {

  setUpdatedDetailsGridDataHandler();
};


const setUpdatedDetailsGridDataHandler = () => {
  let validForm = props.checkValidateForm();
 
  // console.log('isval: ', validForm);
    if (validForm) {

      if(addProductForm){
        //when save
        mutate(validForm);
        
      }else{
        //when update
        mutateUpdate(validForm); 
      }
      

      
    }
};


const { isLoading : isLoadingSave, mutate} = useMutation(api.saveEntryandEditofPackageList, {
  onSuccess: (data) => {

    if(data.status==200) {

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      queryClient.getQueriesData("entryAndEditofPackages");//api page name
      props.history.push("/entry-and-edit-of-package");//

    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
     
    }
  },
});

const {isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.updateEntryandEditofPackageList, {
  onSuccess: (data) => {
    if (data.status == 200) {


      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });

      queryClient.getQueriesData("entryAndEditofPackages");// include the page name
      props.history.push("/entry-and-edit-of-package");//page url
      api.dirtyModeDisable();
    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
     
    }
  },
});

return (
  <div className={classes.productPageTitle}>
    <div className="sw_makeStyles_tableContainer">
      <div className="d-flex justify-product mb-3">
        <Grid item xs={12} sm={12}>
          <div className="sw_page_heading">
            <div className="sw_heading_title">
              {DispensingLanguage[lan][menukey]["Package Entry Form - Add/Edit"]}
            </div>
            </div>
            <div className="float-right sw_btn_control">
              {addProductForm ? (
                <Grid item xs={12} className="text-center">
                  <div className="float-right sw_btn_control">
                    <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => handleReset()}
                    >
                      {DispensingLanguage[lan][menukey]["Reset"]}
                    </Button>
                    <Button
                      disabled={props.bStockUpdate || isLoadingSave}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                      // onClick={() => handleSubmit()}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={() => props.history.push("/menu-entry")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Cancel"]}
                    </Button>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} className="mt-2 text-center">
                  <Button
                      disabled={props.bStockUpdate || isLoadingUpdate}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClick}
                    // onClick={() => handleUpdate()}
                  >
                    {DispensingLanguage[lan][menukey]["update"]}
                  </Button>

                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    //onClick={() => props.history.push("/menu-entry")}
                    onClick={() => backtolist()}
                  >
                    {DispensingLanguage[lan][menukey]["Cancel"]}
                  </Button>
                </Grid>
              )}
            </div>
          
             <div className="mr-4 float-right mt-15">
              <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
            </div>
        </Grid>
      </div>

      {/* New row */}
      <Grid container spacing={3}>
        {/* New row */}
        <Grid item xs={12} sm={12}>
          <Card className="sw_card">
            <CardContent>
               <Grid container spacing={4}>




                    <Grid item xs={6} sm={2}>
                    <FormControl className={classes.fullWidth}>
                      {/* <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Year"]} 
                      </InputLabel>
                      <Select
                        error={errorObject.YearId}
                        labelId="demo-simple-select-helper-label"
                        id="YearId"
                        name="YearId"
                        value={formData.YearId}
                        fullWidth
                        onChange={(e) => handleChange(e)}
                      >
                        {PackageYearList.map((item, index) => {
                          return (
                            <MenuItem value={item.YearId}>
                              {item.PackageYear}
                            </MenuItem>
                          );
                        })}
                      </Select>
                          <FormHelperText error={errorObject.YearId}>
                            {errorObject.YearId}
                          </FormHelperText> */}

                        <Autocomplete
                            autoHighlight
                            id="YearId" 
                            disabled={props.bStockUpdate}
                            options={PackageYearList}
                            onChange={(event, valueobj) => handleChangeChoosen('YearId', valueobj, valueobj?valueobj.YearId:"")}
                            getOptionLabel={(option) => option.PackageYear}
                            value={chosenValues['YearId']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.PackageYear}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={formData.YearId}
                              label={DispensingLanguage[lan][menukey]["Year"]}
                              variant="standard"
                              error={errorObject.YearId}
                              helperText={errorObject.YearId}
                              id="YearId"
                              name="YearId"
                              fullWidth
                            />
                            )}
                          />

                    </FormControl>
                  </Grid>




                  <Grid item xs={6} sm={5}>
                    <FormControl className={classes.fullWidth}>
                      {/* <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Indentor Name"]} 
                      </InputLabel>
                      <Select
                        error={errorObject.IndentorId}
                        labelId="demo-simple-select-helper-label"
                        id="IndentorId"
                        name="IndentorId"
                        value={formData.IndentorId}
                        fullWidth
                        onChange={(e) => handleChange(e)}
                      >
                        {IndentorList.map((item, index) => {
                          return (
                            <MenuItem value={item.IndentorId}>
                              {item.IndentorName} - {item.OPDescription}
                            </MenuItem>
                          );
                        })}
                      </Select>
                          <FormHelperText error={errorObject.IndentorId}>
                            {errorObject.IndentorId}
                          </FormHelperText> */}

                          <Autocomplete
                            autoHighlight
                            id="IndentorId"
                            disabled={props.bStockUpdate}
                            options={IndentorList}
                            onChange={(event, valueobj) => handleChangeChoosen('IndentorId', valueobj, valueobj?valueobj.IndentorId:"")}
                            getOptionLabel={(option) => option.IndentorName +' - '+ option.OPDescription}
                            value={chosenValues['IndentorId']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.IndentorName +' - '+ option.OPDescription}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={formData.IndentorId}
                              label={DispensingLanguage[lan][menukey]["Indentor Name"]}
                              variant="standard"
                              error={errorObject.IndentorId}
                              helperText={errorObject.IndentorId}
                              id="IndentorId"
                              name="IndentorId"
                              fullWidth
                            />
                            )}
                          />

                    </FormControl>
                  </Grid>



                      <Grid item xs={6} sm={5}>
                        <TextField
                        
                        disabled={props.bStockUpdate}
                          error={errorObject.PackageName}
                          helperText={errorObject.PackageName}
                          required
                          id="PackageName"
                          name="PackageName"
                          label= {DispensingLanguage[lan][menukey]['Package Name'] }
                          value={formData.PackageName}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                  


                  </Grid>

              {/* New Row */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  </div>
);
};

export default EntryandEditofPackageFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
