import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

function IndexNavbar(props) {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent_back");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 50 ||
        document.body.scrollTop > 50
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 51 ||
        document.body.scrollTop < 51
      ) {
        setNavbarColor("navbar-transparent__back");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="info">
        <div className="container-fluid">
          <div className="navbar-translate">
            <div className="logo_card">
              <div className="logo">
                  <a href={process.env.REACT_APP_BASE_NAME+"/"}>
                  <img alt="..." src={require("assets/img/logo.png")}></img>
                  </a>
              </div>
              <div className="site_name padding_top">
                <a href={process.env.REACT_APP_BASE_NAME+"/"} id="navbar-brand">DGFP eLMIS</a>
              </div>
            </div>
            {/* <NavbarBrand href={process.env.REACT_APP_BASE_NAME+"/"} id="navbar-brand">
            DGFP eLMIS
            </NavbarBrand> */}

            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end Padding_let_menu"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <Button
                  className="nav-link btn-neutral"
                  color="info"
                  id="login"
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push("/login");
                  }}
                >
                  <i className="now-ui-icons users_single-02 mr-1"></i>
                  <p>Login</p>
                </Button>
              </NavItem>
              <NavItem>
                {/* <Button
                  className="nav-link btn-neutral"
                  color="info"
                  id="signup"
                  onClick={(e) => {
                    e.preventDefault();
                    props.history.push("/signup");
                  }}
                >
                  <i className="now-ui-icons arrows-1_share-66 mr-1"></i>
                  <p>SignUp</p>
                </Button> */}
              </NavItem>
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
