import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles"; 
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  Snackbar,
  Stack,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";
import moment from "moment";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import * as api from "../../../actions/api";


const YearFormData = (props) => {
    //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "year";
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  
  const backtolist=()=>{


    let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
    let idx = master_dirty_message_class.indexOf("dnone");
    if(idx === -1){
      // start Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          props.history.push("/year");
        }
      });
      // End Alert
    }else{
      props.history.push("/year");
    }
  
  }

  const formOnchange = (e,cases,inputname) => { 
     if(cases=='handleRDateChange')
     props.handleRDateChange(e,inputname);
  
  };


  
const onSaveUpdateBtnClick = () => {

  setUpdatedDetailsGridDataHandler();
};


const setUpdatedDetailsGridDataHandler = () => {
  let validForm = props.checkValidateForm();
 
  // console.log('isval: ', validForm);
    if (validForm) {

      if(props.addNationalSupplierForm){
        //when save
        mutate(validForm);
        
      }else{
        //when update
        mutateUpdate(validForm); 
      }
      

      
    }
};

const { isLoading : isLoadingSave, mutate } = useMutation(api.saveYear, {
  onSuccess: (data) => {
    console.log("data: ", data);

    if (data.status == 200) {

      const auth_token = sessionStorage.getItem("token")
      ? sessionStorage.getItem("token")
      : null;
      let options = {};
      options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth_token}`,
        },
        task:'UOM'
      };


     

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
      queryClient.getQueriesData("year"); //api page name
      props.history.push("/year"); //
    } else {
      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
      queryClient.getQueriesData("year"); //api page name
      props.history.push("/year"); //
    }
  },
});

const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.updateYear, {
  onSuccess: (data) => { 
    if (data.status == 200) {



      api.getAllDropdown('PackageYearList').then((response) => {
        localStorage.setItem(
          "PackageYearList",
          JSON.stringify(response.datalist.PackageYearList)
        );
      })


      
      const auth_token = sessionStorage.getItem("token")
      ? sessionStorage.getItem("token")
      : null;
      let options = {};
      options = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${auth_token}`,
        },
        task:'UOM'
      };

      
     

      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });

      queryClient.getQueriesData("Year"); // include the page name
      props.history.push("/year"); //page url
      api.dirtyModeDisable();
    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
    }
  },
});

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Year Entry Form - Add/Edit"]}
              </div>
              </div>
              <div className="float-right sw_btn_control">
                {props.addNationalSupplierForm ? (
                  <Grid item xs={12} className="text-center">
                    <div className="float-right sw_btn_control">
                      <Button
                        disabled={props.bStockUpdate}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => props.handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button>
                      <Button
                        disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onSaveUpdateBtnClick}
                        // onClick={() => props.handleSubmit()}
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        //onClick={() => props.history.push("/menu-entry")}
                        onClick={() => backtolist()}
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="mt-2 text-center">
                    <Button
                        disabled={props.bStockUpdate || isLoadingUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                      // onClick={() => props.handleUpdate()}
                    >
                      {DispensingLanguage[lan][menukey]["update"]}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={() => props.history.push("/menu-entry")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                  </Grid>
                )}
              </div>
            
               <div className="mr-4 float-right mt-15">
                <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
              </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardContent>
                  <Grid container spacing={10}>
                  <Grid item xs={4} sm={12}>
                    <Grid container spacing={3}>
                      
                    
                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={props.permissionType}
                        required
                        error={props.errorObject.DeadLine}
                        helperText={props.errorObject.DeadLine}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        id="DeadLine"
                        label={DispensingLanguage[lan][menukey]["Deadline"]}
                        autoOk={true}
                        name="DeadLine"
                        fullWidth
                        showTodayButton={true}
                        //disableFuture={true}
                        value={
                          props.formData.DeadLine || null
                        }
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "DeadLine")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>





                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={props.errorObject.PackageYear}
                          helperText={props.errorObject.PackageYear}
                          id="PackageYear"
                          name="PackageYear"
                          disabled={props.permissionType}
                          label= {DispensingLanguage[lan][menukey]['Package Year'] }
                          value={props.formData.PackageYear}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => props.handleChange(e)}
                        />
                      </Grid> 

                      <br></br>
                      <br></br>

                    
                      {/* <Grid item xs={2} sm={2} className="sw_active_patient">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              disabled={props.permissionType}
                              checked={props.formData.CurrentYear}
                              onChange={(e) => props.handleCheck(e)}
                              name="CurrentYear"
                              value="no"
                            />
                          }
                          label="Current Year"
                        />
                      </Grid> */}


                      <Grid item xs={2} sm={2} className="sw_active_patient">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              disabled={props.permissionType}
                              checked={props.formData.bCatalogLocked}
                              onChange={(e) => props.handleCheckYear(e)}
                              name="bCatalogLocked"
                              value="no"
                            />
                          }
                          label="Lock for Editing"
                        />
                      </Grid>


                     
                     
                      
                    </Grid>
                  </Grid>
                </Grid>

                {/* New Row */}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default YearFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
