import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as Service from "../../../services/Service.js";
import {
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
let timeStamp = "";


const SDPListFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  open,
  ...props
}) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "sdplist";
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  const FacilityId = localStorage.getItem('FacilityId');

  //const UnitList = JSON.parse(localStorage.getItem('Unitofmeasurelist'));
  const SDPGroupList = JSON.parse(localStorage.getItem("SDPGroupList"));
  const UnitsList = JSON.parse(localStorage.getItem("UnitsList"));
  const UnionsList = JSON.parse(localStorage.getItem("UnionsList"));

  const [firstLoad, setFirstLoad] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [manyTableData, setManyTableData] = useState([]);
  const [FGroupCode, setGroupCode] = useState();

  //console.log("group max id",FGroupCode);

  let TempItemList = useState([]);
  const [SDPCode, setItemListLocal] = useState('');
  const [fpacilyListss, setfpacilyList] = useState(TempItemList);
  //console.log("SDPCode",SDPCode);
  let SDPGroupStr = "";


      
//====== Start Choosen Dropdown===========

const [chosenValuesGroup, setChosenValuesGroup] = useState({
  "FGroupCode": {"id": "","name": ""}
});


const [chosenValues, setChosenValues] = useState({
  "sunitid": {"id": "","name": ""},
  "UnionCode": {"id": "","name": ""}
});

const [chosenValuesFacility, setChosenValuesFacility] = useState({
  "fpfacilityId": {"id": "","name": ""}
});


useEffect(() => {
  let chosenValuesDataGroup = { ...chosenValuesGroup};
  chosenValuesDataGroup['FGroupCode'] = SDPGroupList[SDPGroupList.findIndex(SDPGroup_List => SDPGroup_List.id == formData.FGroupCode)];
  setChosenValuesGroup(chosenValuesDataGroup); 
  }, [formData.FGroupCode]); 

  
const handleChangeChoosenGroup = (name, valueobj, value) => {

  let chosenValuesDataGroup = { ...chosenValuesFacility};
  chosenValuesDataGroup[name] = valueobj;   


  if(value == 'F'){
    formData["StaffDesignation"] = "FWA";
   }
   else if(value == 'W'){
    formData["StaffDesignation"] = "FWV";
   }
   else if(value == 'S'){
    formData["StaffDesignation"] = "SACMO";
   }else{
    formData["StaffDesignation"] = "";
   }
   
  formData["FGroupCode"] = value;
  setGroupCode(value);

  setChosenValuesGroup(chosenValuesDataGroup); 
  props.handleChangeChoosenGroupProps(name, value);
};



 useEffect(() => {
  
  let chosenValuesData = { ...chosenValues};
   chosenValuesData['sunitid'] = UnitsList[UnitsList.findIndex(Units_List => Units_List.id == formData.sunitid)];
   chosenValuesData['UnionCode'] = UnionsList[UnionsList.findIndex(Unions_List => Unions_List.id == formData.UnionCode)];
  
   setChosenValues(chosenValuesData);

  }, [formData.sunitid, formData.UnionCode]); 

  
 useEffect(() => {
  
  let chosenValuesDataFacility = { ...chosenValuesFacility};
  chosenValuesDataFacility['fpfacilityId'] = fpacilyListss[fpacilyListss.findIndex(fpacily_Listss => fpacily_Listss.id == formData.fpfacilityId)];
  setChosenValuesFacility(chosenValuesDataFacility); 
  }, [formData.fpfacilityId]); 

const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  let chosenValuesDataFacility = { ...chosenValuesFacility};
  chosenValuesDataFacility[name] = valueobj;   
  setChosenValues(chosenValuesData); 
  setChosenValuesFacility(chosenValuesDataFacility); 
  props.handleChangeChoosenProps(name, value);
};

//====== End Choosen Dropdown===========



  const GrouphandleChange = (event) => {
    
    //setFirstLoad(true);
     //setLoading(false);
  

   if(event.target.value == 'F'){
    formData["StaffDesignation"] = "FWA";
   }
   else if(event.target.value == 'W'){
    formData["StaffDesignation"] = "FWV";
   }
   else if(event.target.value == 'S'){
    formData["StaffDesignation"] = "SACMO";
   }else{
    formData["StaffDesignation"] = "";
   }
   
    formData["FGroupCode"] = event.target.value;
    setGroupCode(event.target.value);

  };




  React.useEffect(() => {
    //setcurrItemCode(0);
    if(FGroupCode){
      cItemList();
    } 
    
  }, [formData["FGroupCode"]]);



  const cItemList = () => {
    
    let ItemListParam = { action: "SDPGroupLists", FGroupCode: formData["FGroupCode"], FacilityId: FacilityId, menukey: "sdplist" }
    Service.default
      .postApi("source/combo_generic.php", ItemListParam)
      
      .then((res) => {
        //console.log("================>>> ",res.datalist);
        setItemListLocal(res.datalist);
      })
      .catch((err) => { });

  }
// Facility Combobox

  React.useEffect(() => {
    //setcurrItemCode(0); 
   
    fpacilyList();
  }, [formData["FGroupCode"]]);



  const fpacilyList = () => {

    let ItemListParam1 = { action: "SDPfpacily", FGroupCode: formData["FGroupCode"], FacilityId: FacilityId, menukey: "sdplist" }
//console.log(ItemListParam1);
    Service.default
      .postApi("source/combo_generic.php", ItemListParam1)

      .then((res) => {

          if(!addProductForm){
              let chosenValuesDataFacility = { ...chosenValuesFacility};
              chosenValuesDataFacility['fpfacilityId'] = res.datalist[res.datalist.findIndex(fpacily_Lists2 => fpacily_Lists2.id == formData.fpfacilityId)];
              setChosenValuesFacility(chosenValuesDataFacility);  

          }else{
              setChosenValuesFacility({
              "fpfacilityId": {"id": "0","name": ""}
              }); 
              formData["fpfacilityId"] = "";
            } 

        setfpacilyList(res.datalist); 

      })
      .catch((err) => { });

  }

   

const backtolist=()=>{


  let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
  let idx = master_dirty_message_class.indexOf("dnone");
  if(idx === -1){
    // start Alert
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then((willAction) => {
      if (willAction) {
        props.history.push("/sptList-entry");
      }
    });
    // End Alert
  }else{
    props.history.push("/sptList-entry");
  }

}

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title"> {DispensingLanguage[lan][menukey]['SDPLIst Entry- Add/Edit']} </div>
            </div>
            <div className="float-right sw_btn_control">

              {addProductForm ? (
                <Grid item xs={12} className="mt-4 text-center tex_margin">
                  <Button
                  disabled={props.bStockUpdate}
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => handleReset()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Reset'])}
                  </Button>
                  <Button 
                  disabled={props.bStockUpdate}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Save'])}
                  </Button>
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    //onClick={() => props.history.push("/sptList-entry")}
                    onClick={() => backtolist()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Back to List'])}
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={12} className="mt-2 text-center">
                  <Button
                  disabled={props.bStockUpdate}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={() => handleUpdate()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Update'])}
                  </Button>
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    //onClick={() => props.history.push("/sptList-entry")}
                    onClick={() => backtolist()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Back to List'])}
                  </Button>
                </Grid>
              )}



            </div>

             <div className="mr-4 float-right mt-15">
                <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
              </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={10}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['SDP List Entry']} />
              <CardContent>
                <Grid container spacing={10}>
                  <Grid item xs={4} sm={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          {/* <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]['SDP Group']}*
                          </InputLabel>
                          <Select

                            error={errorObject.FGroupCode}
                            required
                            labelId="demo-simple-select-helper-label"
                            id="FGroupCode"
                            name="FGroupCode"
                            value={formData.FGroupCode}
                            //disabled={true}
                            disabled={addProductForm ? false: true}
                            fullWidth
                            onChange={GrouphandleChange}
                          >

                            {
                              SDPGroupList.map(
                                (item, index) => {
                                  return (
                                    <MenuItem value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                }
                              )}
                          </Select>
                          <FormHelperText error={errorObject.FGroupCode}>
                            {errorObject.FGroupCode}
                          </FormHelperText> */}

                              <Autocomplete
                                autoHighlight
                                //disableClearable
                                disabled={(!addProductForm || props.bStockUpdate) ? true:false}
                                id="FGroupCode"
                                options={SDPGroupList}
                                onChange={(event, valueobj) => handleChangeChoosenGroup('FGroupCode', valueobj, valueobj?valueobj.id:"")}
                                getOptionLabel={(option) => option.name}
                                value={chosenValuesGroup['FGroupCode']}
                                renderOption={(option) => (
                                <Typography className="sw_fontSize">{option.name}</Typography>
                                )}
                                renderInput={(params) => (
                                <TextField
                                  {...params}
                                  value={formData.FGroupCode}
                                  label={DispensingLanguage[lan][menukey]['SDP Group']+" *"}
                                  variant="standard"
                                  error={errorObject.FGroupCode}
                                  helperText={errorObject.FGroupCode}
                                  id="FGroupCode"
                                  name="FGroupCode"
                                  fullWidth
                                />
                                )}
                              />
                              
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <TextField
                          error={errorObject.FacilityCode}
                          helperText={errorObject.FacilityCode}

                          id="FacilityCode"
                          name="FacilityCode"
                          label={DispensingLanguage[lan][menukey]["SDP Code"]}
                          value={formData.FacilityCode || SDPCode}
                          //value={SDPCode}
                          disabled={true}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={12} sm={3}>

                        <TextField
                          error={errorObject.StaffName}
                          helperText={errorObject.StaffName}

                          id="StaffName"
                          name="StaffName"
                          label={DispensingLanguage[lan][menukey]["Name"]+'*'}
                          value={formData.StaffName}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                          disabled={props.bStockUpdate}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>

                        <TextField
                          error={errorObject.PhoneNo}
                          helperText={errorObject.PhoneNo}

                          id="PhoneNo"
                          name="PhoneNo"
                          label={DispensingLanguage[lan][menukey]["Phone No"]+'*'}
                          value={formData.PhoneNo}
                          fullWidth
                          autoComplete="family-name"
                          inputProps={{
                            maxLength: 11,
                          }}
                          onChange={(e) => handleChange(e)}
                          disabled={props.bStockUpdate}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>

                        <TextField
                          error={errorObject.StaffDesignation}
                          helperText={errorObject.StaffDesignation}

                          id="StaffDesignation"
                          name="StaffDesignation"
                          label={DispensingLanguage[lan][menukey]["Designation"]+'*'}
                          value={formData.StaffDesignation}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                          disabled={props.bStockUpdate}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <MuiPickersUtilsProvider
                          libInstance={moment}
                          utils={DateFnsUtils}
                        >
                          <KeyboardDatePicker
                            //disabled={bStockUpdate}
                            // required
                          disabled={props.bStockUpdate}
                            error={errorObject.dateofbirth}
                            helperText={errorObject.dateofbirth}
                            className={classes.fullWidth}
                            //disableToolbar
                            variant="inline"
                            id="dateofbirth"
                            label={DispensingLanguage[lan][menukey]["Birth Date"]}
                            autoOk={true}
                            name="dateofbirth"
                            fullWidth
                            showTodayButton={true}
                            disableFuture={true}
                            value={

                              //addProductForm?props.selectedDate:formData.dateofbirth || null
                              formData.dateofbirth || null
                            }
                            format="dd/MM/yyyy"
                            onChange={(e) =>
                              props.handleRDateChange(e, "dateofbirth")
                            }
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>

                      <Grid item xs={12} sm={3}>
                        <MuiPickersUtilsProvider
                          libInstance={moment}
                          utils={DateFnsUtils}
                        >
                          <KeyboardDatePicker
                            error={errorObject.dateofjoin}
                            helperText={errorObject.dateofjoin}
                            className={classes.fullWidth}
                            //disableToolbar
                            
                          disabled={props.bStockUpdate}
                            variant="inline"
                            id="dateofjoin"
                            label={DispensingLanguage[lan][menukey]["Joining Date"]}
                            autoOk={true}
                            name="dateofjoin"
                            fullWidth
                            showTodayButton={true}
                            disableFuture={true}
                            value={
                              formData.dateofjoin || null
                              //formData.dateofjoin || props.selectedDate
                              
                            }
                            format="dd/MM/yyyy"
                            onChange={(e) =>
                              props.handleRDateChange(e, "dateofjoin")
                            }
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        </MuiPickersUtilsProvider>
                      </Grid>
                      <Grid item xs={6} sm={3}>

                        <TextField
                          error={errorObject.NationalId}
                          helperText={errorObject.NationalId}

                          disabled={props.bStockUpdate}
                          id="NationalId"
                          name="NationalId"
                          label={DispensingLanguage[lan][menukey]["National ID"]}
                          value={formData.NationalId}
                          fullWidth
                          autoComplete="family-name"
                          inputProps={{
                            maxLength: 17,
                          }}
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>

                        <TextField
                          error={errorObject.HRIS}
                          helperText={errorObject.HRIS}

                          disabled={props.bStockUpdate}
                          id="HRIS"
                          name="HRIS"
                          label={DispensingLanguage[lan][menukey]["HRIS ID#"]}
                          value={formData.HRIS}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          {/* <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]['Units']}
                          </InputLabel>
                          <Select

                            error={errorObject.sunitid}
                            helperText={errorObject.sunitid}
                            required
                            labelId="demo-simple-select-helper-label"
                            id="sunitid"
                            name="sunitid"
                            value={formData.sunitid}
                            disabled={FGroupCode=='F' || formData.FGroupCode=='F'?false:true}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >

                            {
                              UnitsList.map(
                                (item, index) => {
                                  return (
                                    <MenuItem value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                }
                              )}
                          </Select>


                          <FormHelperText error={errorObject.sunitid}>
                            {errorObject.sunitid}

                          </FormHelperText> */}


                          <Autocomplete
                            autoHighlight
                          
                            //disabled={(!addProductForm || props.bStockUpdate) ? true:false}
                            disabled={(FGroupCode=='F' || formData.FGroupCode=='F') && !props.bStockUpdate?false:true}
                            id="sunitid"
                            options={UnitsList}
                            onChange={(event, valueobj) => handleChangeChoosen('sunitid', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValues['sunitid']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={formData.sunitid}
                              label={DispensingLanguage[lan][menukey]["Units"]}
                              variant="standard"
                              error={errorObject.sunitid}
                              helperText={errorObject.sunitid}
                              id="sunitid"
                              name="sunitid"
                              fullWidth
                            />
                            )}
                          />
                        </FormControl>

                      </Grid>
                     {/*  <Grid item xs={12} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]['Union']}
                          </InputLabel>
                          <Select

                            error={errorObject.UnionCode}
                            required
                            labelId="demo-simple-select-helper-label"
                            id="UnionCode"
                            name="UnionCode"
                            value={formData.UnionCode}
                            disabled={FGroupCode=='M' || FGroupCode=='X' || FGroupCode=='N' || formData.FGroupCode=='M' || formData.FGroupCode=='X' || formData.FGroupCode=='N'?true:false}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >

                            {
                              UnionsList.map(
                                (item, index) => {
                                  return (
                                    <MenuItem value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                }
                              )}
                          </Select>


                          <FormHelperText error={errorObject.UnionCode}>
                            {errorObject.UnionCode}

                          </FormHelperText>
                        </FormControl>

                      </Grid>

                      <Grid item xs={12} sm={3}>

                          <TextField
                            id="UnionName"
                            name="UnionName"
                            label={DispensingLanguage[lan][menukey]["Union"]}
                            value={formData.UnionName}
                            disabled= {true}
                            fullWidth
                            autoComplete="family-name"
                            onChange={(e) => handleChange(e)}
                          />
                        </Grid> */}

                   {/*  {formData.ExistingbShow || addProductForm ? ( */}

                      <Grid item xs={12} sm={3}>
                        <FormControl className={classes.fullWidth}>
                          {/* <InputLabel id="demo-simple-select-helper-label">
                          {DispensingLanguage[lan][menukey]['Union']}
                          </InputLabel>
                          <Select

                          error={errorObject.UnionCode}
                          required
                          labelId="demo-simple-select-helper-label"
                          id="UnionCode"
                          name="UnionCode"
                          value={formData.UnionCode}
                          disabled={FGroupCode=='M' || FGroupCode=='X' || FGroupCode=='N' || formData.FGroupCode=='M' || formData.FGroupCode=='X' || formData.FGroupCode=='N'?true:false}
                          fullWidth
                          onChange={(e) => handleChange(e)}
                          >

                          {
                            UnionsList.map(
                            (item, index) => {
                              return (
                              <MenuItem value={item.id}>
                                {item.name}
                              </MenuItem>
                              );
                            }
                            )}
                          </Select>


                          <FormHelperText error={errorObject.UnionCode}>
                          {errorObject.UnionCode}

                          </FormHelperText> */}

                          <Autocomplete
                            autoHighlight
                        
                            disabled={props.bStockUpdate || FGroupCode=='M' || FGroupCode=='X' || FGroupCode=='N' || formData.FGroupCode=='M' || formData.FGroupCode=='X' || formData.FGroupCode=='N'?true:false}
                            hidden={!(formData.ExistingbShow || addProductForm)}
                            id="UnionCode"
                            options={UnionsList}
                            onChange={(event, valueobj) => handleChangeChoosen('UnionCode', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValues['UnionCode']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={formData.UnionCode}
                              label={DispensingLanguage[lan][menukey]["Union"]}
                              variant="standard"
                              error={errorObject.UnionCode}
                              helperText={errorObject.UnionCode}
                              id="UnionCode"
                              name="UnionCode"
                              fullWidth
                            />
                            )}
                          />


                        <TextField
                          id="UnionName"
                          name="UnionName"
                          label={DispensingLanguage[lan][menukey]["Union"]}
                          value={formData.UnionName}
                          disabled= {true}
                          hidden={(formData.ExistingbShow || addProductForm)}
                          fullWidth
                          autoComplete="family-name"
                            //onChange={(e) => handleChange(e)}
                          />

                        </FormControl>

                      </Grid>


                      {/* ) : ( */}

                      {/* <Grid item xs={12} sm={3}>

                        <TextField
                        id="UnionName"
                        name="UnionName"
                        label={DispensingLanguage[lan][menukey]["Union"]}
                        value={formData.UnionName}
                        disabled= {true}
                        fullWidth
                        autoComplete="family-name"
                          //onChange={(e) => handleChange(e)}
                        />
                      </Grid> */}

                     {/*  )} */}


                      <Grid item xs={12} sm={3}>
                        <FormControl className={classes.fullWidth}>
                         
                         {/*  <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]['Facility']}
                          </InputLabel>
                          <Select

                            error={errorObject.fpfacilityId}
                            required
                            labelId="demo-simple-select-helper-label"
                            id="fpfacilityId"
                            name="fpfacilityId"
                            value={formData.fpfacilityId}
                            disabled={FGroupCode=='F' || formData.FGroupCode=='F'?true:false}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >

                            {
                              fpacilyListss.map(
                                (item, index) => {
                                  return (
                                    <MenuItem value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                }
                              )}
                          </Select>


                          <FormHelperText error={errorObject.fpfacilityId}>
                            {errorObject.fpfacilityId}

                          </FormHelperText> */}

                          <Autocomplete
                            autoHighlight
                            disabled={props.bStockUpdate || FGroupCode=='F' || formData.FGroupCode=='F'?true:false}
                            id="fpfacilityId"
                            options={fpacilyListss}
                            onChange={(event, valueobj) => handleChangeChoosen('fpfacilityId', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValuesFacility['fpfacilityId']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={formData.fpfacilityId}
                              label={DispensingLanguage[lan][menukey]["Facility"]}
                              variant="standard"
                              error={errorObject.fpfacilityId}
                              helperText={errorObject.fpfacilityId}
                              id="fpfacilityId"
                              name="fpfacilityId"
                              fullWidth
                            />
                            )}
                          />
                        </FormControl>

                      </Grid>
                      <Grid item xs={12} sm={2} className="sw_active_patient">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={formData.incharge}
                              onChange={(e) => handleCheck(e)}
                              name="incharge"
                              value="no"
                               disabled={props.bStockUpdate}

                            />
                          }
                          label={DispensingLanguage[lan][menukey]["In-charge"]}
                        />
                      </Grid>
                      <Grid item xs={12} sm={1} className="sw_active_patient">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={formData.bShow}
                              onChange={(e) => handleCheck(e)}
                              name="bShow"
                              value="no"
                              disabled={props.bStockUpdate}
                            />
                          }
                          label={DispensingLanguage[lan][menukey]["Active"]}
                        />
                      </Grid>
                      <Grid item xs={12} sm={9}>

                        <TextField
                          error={errorObject.FacilityName}
                          helperText={errorObject.FacilityName}

                          id="FacilityName"
                          name="FacilityName"
                          label={DispensingLanguage[lan][menukey]["SDP Name"]+'*'}
                          value={formData.FacilityName}
                          disabled={true}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}



        </Grid>
      </div>
    </div>
  );
};

export default SDPListFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
