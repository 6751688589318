import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import swal from "sweetalert";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DeleteOutline, Edit, ViewList, FileCopy } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import ExportButtonsMany from "../../../components/ExportButtonsMany";
//react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { useTranslation } from "react-i18next";
import Form7InvStatusValidation from "../../../services/Form7InvStatusValidation";
import * as api from "../../../actions/api";
let timeStamp = "";

//errorObject, addProductForm, formData, handleChange, handleCheck, handleReset, handleSubmit, handleUpdate, ManyTableData,ManyJsonSave,handleRDateChange,selectedDate,handleAdd,handleRefech,
const Form7FormData = (props) => {
  const permissionType = props.permissionType;
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "form7";
  const FacilityId = localStorage.getItem("FacilityId");

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  let ReceiveDetailsRef = null;

  const [newBatchRowAdded, setNewBatchRowAdded] = useState(false);

  // console.log("Set r batch");

  const [open, setOpen] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);

  const [SoureOpen, setSoureOpen] = useState(false);

  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);

  const IssuingToFacility = JSON.parse(localStorage.getItem("IssuingToFacility"));
  const MonthList = JSON.parse(localStorage.getItem("MonthList"));

  // const UsersListByFacility = JSON.parse(localStorage.getItem("UsersListByFacility"));
  const FacilityEmployee = JSON.parse(localStorage.getItem("FacilityEmployee"));
  const Designation = JSON.parse(localStorage.getItem("Designation"));
  const [currRowId, setCurrRowId] = useState(0);
  const [currFormulation, setCurrFormulation] = useState("");
  const [bIsDisable, setbIsEnable] = useState(false);


  
//====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "MonthId": {"id": "","name": ""}
});


useEffect(() => {
  
  let chosenValuesData = { ...chosenValues};
   chosenValuesData['MonthId'] = MonthList[MonthList.findIndex(Month_List => Month_List.id == props.formData.MonthId)];
   setChosenValues(chosenValuesData); 

}, [props.formData.MonthId]); 



const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  setChosenValues(chosenValuesData); 

  props.handleChangeChoosenProps(name, value);
};

//====== End Choosen Dropdown===========



  /* =====Start of Print Excel Many Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL +
      "report/form7_print.php";

      window.open(
        finalUrl +
          "?menukey=" +
          menukey +
          "&lan=" +
          localStorage.getItem("LangCode") +
          "&TransactionId=" +
          props.formData.TransactionNo +
          "&MonthId=" +
          props.formData.MonthId +
          "&FacilityId=" +
          FacilityId +
          "&TimeStamp=" +
          Date.now()
      );
  };


  /* =====End of Excel Export Code==== */

  const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;
      // case "OrderModal":
      //   setOpenOrder(false);
      //   break;
      // case "OrderOpen":
      //   setOpenOrder(true);
      //   break;

      // case "SourceOpen":
      //   setSoureOpen(true);
      //   break;

      // case "SourceClose":
      //   setSoureOpen(false);
      //   break;

      default:
        break;
    }
  };

  
  const onUnPostBtnClick = () => {
    props.handleUnPost();
    //getInvoiceStatusByMonthWhenUnpost();
    // setbStockUpdate(true);
    // props.handlePost();
  };

  
  // const { isLoading3, error3, isError3, data: MyData3, refetch:getInvoiceStatusByMonthWhenUnpost } = useQuery(
  //   ["form7checkunpost1", props.formData.YearName, props.formData.MonthId],
  //   api.getUnPostCheckByMonthForm7,{
  //     onSuccess: (isunpostinv) => {
  //       // console.log("============",props.formData.MonthId);
  //       // console.log("============",props.formData.YearName);
  //       // console.log('isunpostinv: ', isunpostinv);
  //       if(isunpostinv > 0){
  //         handleClose("ProductsOpen");
  //       }else{
  //         setbStockUpdate(false);
  //         props.handleUnPost();
  //       }
  //     },
  //     enabled: false,
  //     refetchOnWindowFocus: false,
  //     refetchOnmount: false,
  //     refetchOnReconnect: false,
  //     retry: false,
  //     staleTime: 0,//1000 * 60 * 60 * 24,
  //   }
  // );



  const generateForm7Items = () => {
    getInvoiceStatusByMonth();
    // handleClose("ProductsOpen");
    // props.generateForm7Items();
  };

  const { isLoading, error, isError, data: MyData, refetch:getInvoiceStatusByMonth } = useQuery(
    ["form7checkinvunpost", props.formData.YearName, props.formData.MonthId],
    api.getHasUnPostInvoiceByMonth,{
      onSuccess: (isunpostinv) => {
        // console.log("============",props.formData.MonthId);
        // console.log("============",props.formData.YearName);
        // console.log('isunpostinv: ', isunpostinv);
        if(isunpostinv > 0){
          handleClose("ProductsOpen");
        }else{
          props.generateForm7Items();
        }
      },
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

 

  const onPostBtnClick = () => {
    getInvoiceStatusByMonth1();
    // setbStockUpdate(true);
    // props.handlePost();
  };

  const { isLoading1, error1, isError1, data: MyData1, refetch:getInvoiceStatusByMonth1 } = useQuery(
    ["form7checkinvunpost1", props.formData.YearName, props.formData.MonthId],
    api.getHasUnPostInvoiceByMonth,{
      onSuccess: (isunpostinv) => {
        // console.log("============",props.formData.MonthId);
        // console.log("============",props.formData.YearName);
        // console.log('isunpostinv: ', isunpostinv);
        if(isunpostinv > 0){
          handleClose("ProductsOpen");
        }else{
          setbStockUpdate(true);
          props.handlePost();
        }
      },
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );



  const addProduct = (NonOrderProductData) => {
    console.log("NonOrderProductData: ", NonOrderProductData);
    // return;

    let temNonordata = NonOrderProductData;
    let rows = [];

    let autoId = 0;

    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;

      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.UnitName = row.UnitName;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.ReqQty = row.ReqQty;

      newRow.CurStockQty = row.CurStockQty;
      newRow.Quantity = row.Quantity;
      newRow.BatchNo = row.BatchNo;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.NoOfCartons = row.NoOfCartons;
      newRow.ItemListLotId = row.ItemListLotId;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = 0; //row.Quantity * row.UnitPrice;

      // newRow.SourceId = row.SourceId;
      // newRow.SourceName = row.SourceName;

      rows.push(newRow);
    });

    temNonordata.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;
      newRow.ItemName = row.ItemName;
      // newRow.UnitPrice = row.UnitPrice;
      newRow.UnitName = row.UnitName;
      // newRow.SourceId = null;
      // newRow.SourceName = null;
      newRow.TransactionItemId = null;
      // newRow.OrderRemainingQty = 0;
      newRow.ReqQty = null;
      console.log("manyTableData: ", manyTableData);
      // manyTableData.forEach((rowssss, ij) => {
      //   if (rowssss.ItemNo == row.ItemNo) {
      //     newRow.OrderRemainingQty = rowssss.OrderRemainingQty;
      //     return;
      //   }
      // });
      newRow.CurStockQty = row.CurrentStockQty;
      newRow.NoOfCartons = null;
      newRow.Quantity = 0;
      newRow.BatchNo = row.BatchNo;
      newRow.MfgDate = row.MfgDate;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.ItemListLotId = row.ItemListLotId;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = 0;
      rows.push(newRow);
    });

    setManyTableData(rows);
    props.handleAdd(rows);
    handleClose("Products");
  };

  let serialNo = 0;
  const ReceiveDetailsColumns = [
    { title: "TransactionItemId", field: "TransactionItemId", visible: false },
    { title: "id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      field: "ItemCode",
      width: 40,
      // formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
      formatter: function (cell, formatterParams) {
        let _ItemCode = cell.getValue();
        if(_ItemCode == "CON004" || _ItemCode == "CON005"){
          return "";
        }else{
          serialNo++;
          return serialNo;
        }
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Name"],
      field: "ProdText",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Opening Balance"],
      field: "S_OpeningBalance",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : Number(value).toLocaleString();
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Receive From"],
      hozAlign: "center",
      headerHozAlign: "center",
      columns: [
        {
          title: DispensingLanguage[lan][menukey]["Warehouse"],
          field: "S_Received_WH",
          headerHozAlign: "right",
          hozAlign: "right",
          sorter: "number",
          width: 110,
          formatter: function (cell, formatterParams) {
            let value = cell.getValue();
            return value == 0 ? "" : Number(value).toLocaleString();
          },
        },
        {
          title: DispensingLanguage[lan][menukey]["Supplier"],
          field: "S_Received_Sup",
          headerHozAlign: "right",
          hozAlign: "right",
          sorter: "number",
          width: 100,
          formatter: function (cell, formatterParams) {
            let value = cell.getValue();
            return value == 0 ? "" : Number(value).toLocaleString();
          },
        },
      ],
    },
    {
      title: DispensingLanguage[lan][menukey]["Issue To"],
      hozAlign: "center",
      headerHozAlign: "center",
      columns: [
        {
          title: DispensingLanguage[lan][menukey]["Warehouse"],
          field: "S_Issued_RWH",
          headerHozAlign: "right",
          hozAlign: "right",
          sorter: "number",
          width: 110,
          formatter: function (cell, formatterParams) {
            let value = cell.getValue();
            return value == 0 ? "" : Number(value).toLocaleString();
          },
        },
        {
          title: DispensingLanguage[lan][menukey]["Upazila"],
          field: "S_Issued_Upazila",
          headerHozAlign: "right",
          hozAlign: "right",
          sorter: "number",
          width: 100,
          formatter: function (cell, formatterParams) {
            let value = cell.getValue();
            return value == 0 ? "" : Number(value).toLocaleString();
          },
        },
      ],
    },

    {
      title: DispensingLanguage[lan][menukey]["Adjustment"],
      hozAlign: "center",
      headerHozAlign: "center",
      columns: [
        {
          title: DispensingLanguage[lan][menukey]["Plus"],
          field: "S_AdjustPlus",
          headerHozAlign: "right",
          hozAlign: "right",
          sorter: "number",
          width: 80,
          formatter: function (cell, formatterParams) {
            let value = cell.getValue();
            return value == 0 ? "" : Number(value).toLocaleString();
          },
        },
        {
          title: DispensingLanguage[lan][menukey]["Minus"],
          field: "S_AdjustMinus",
          headerHozAlign: "right",
          hozAlign: "right",
          sorter: "number",
          width: 80,
          formatter: function (cell, formatterParams) {
            let value = cell.getValue();
            return value == 0 ? "" : Number(value).toLocaleString();
          },
        },
        {
          title:
            DispensingLanguage[lan][menukey][
              "Return From Warehouse or Upazila"
            ],
          field: "S_AdjustReturnFormWH",
          headerHozAlign: "right",
          hozAlign: "right",
          sorter: "number",
          width: 100,
          formatter: function (cell, formatterParams) {
            let value = cell.getValue();
            return value == 0 ? "" : Number(value).toLocaleString();
          },
        },
        {
          title: DispensingLanguage[lan][menukey]["Return To Warehouse"],
          field: "S_AdjustReturnToWH",
          headerHozAlign: "right",
          hozAlign: "right",
          sorter: "number",
          width: 100,
          formatter: function (cell, formatterParams) {
            let value = cell.getValue();
            return value == 0 ? "" : Number(value).toLocaleString();
          },
        },
      ],
    },

    {
      title: DispensingLanguage[lan][menukey]["Closing Balance"],
      field: "S_ClosingBalance",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : Number(value).toLocaleString();
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["MOS"],
      field: "S_MOS",
      width: 75,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },
    },
    {
      title: "SOSDPCount",
      field: "SOSDPCount",
      visible: false,
    },
  ];



  const onSaveUpdateBtnClick = () => {
    props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData());
    //  props.handleUpdate();
  };

  const deleteReceiveInv = (data) => {
    let newarray = manyTableData.filter((element) => element !== data);
    setManyTableData(newarray);
    props.deleteReceiveInv(data);
  };

  const CopyBatch = () => {
    props.handleAdd(manyTableData);
  };

  function ActionButton(props) {
    //console.log("console props......", props123);

    // return;

    const rowData = props.cell._cell.row.data;

    const goToDetails = () => {
      let rowobj = props.cell._cell.row.data; //{};
      // console.log("I copied this line", rowobj);
      //console.log("I copied this line", props.cell._cell.row);

      timeStamp = Math.round(new Date().getTime() / 100);

      setManyTableData((prevRows) => {
        const tmpPrevRows = [...prevRows];

        let setRposition = tmpPrevRows.findIndex((o) => o.SKU == rowobj.SKU);
        // console.log("Rposition", setRposition);

        const obj = { ...rowobj };

        obj.id = timeStamp;
        obj.TransactionItemId = "";
        obj.ExpiryDate = "";
        obj.BatchNo = "";
        obj.Quantity = 0;
        obj.SKU = Date.now();

        // console.log("new object added in grid:", obj);
        tmpPrevRows.splice(setRposition + 1, 0, obj);

        return tmpPrevRows;
      });

      // props.handleAdd(manyTableData);
      CopyBatch();
      setNewBatchRowAdded((prevNewBatchRowAdded) => !prevNewBatchRowAdded);
    };

    const cellValue = props.cell._cell.value || "Edit | Show";
    if (bStockUpdate == 0)
      return (
        <>
          <FileCopy
            onClick={() => {
              if (!bStockUpdate) goToDetails(rowData);
            }}
          />

          <DeleteOutline
            onClick={() => {
              if (!bStockUpdate) deleteReceiveInv(rowData);
            }}
          />
        </>
      );
    else return <></>;
  }

  useEffect(() => {
    if (ReceiveDetailsRef == null) return;
    if (props.bFirst) {
      setManyTableData(props.ManyTableData);
    }

    setbStockUpdate(props.bStockUpdate);
  }, [props.ManyTableData, props.bStockUpdate]); //

  useEffect(() => {
    if (ReceiveDetailsRef.table != null && timeStamp != "") {
      // console.log("Many table", ReceiveDetailsRef.table);
      // console.log("Many table 12222222222");

      ReceiveDetailsRef.table.scrollToRow(timeStamp, "center", true);
      ReceiveDetailsRef.table.selectRow(timeStamp);
    }
  }, [newBatchRowAdded]);

  const handleReset = () => {
    props.handleReset();
    setManyTableData([]);
  };

  
  const backtolist=()=>{


    let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
    let idx = master_dirty_message_class.indexOf("dnone");
    if(idx === -1){
      // start Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          props.history.push("/form7");
        }
      });
      // End Alert
    }else{
      props.history.push("/form7");
    }

  }

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Form7 - Add/Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {props.addProductForm ? (
                  <Grid item xs={12} className="text-center">
                    <div className="float-right sw_btn_control">
                      <Button
                      disabled={props.bStockUpdate}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button>
                      <Button
                      disabled={props.bStockUpdate}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onSaveUpdateBtnClick} //() => props.handleSubmit()
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        // onClick={() => props.history.push("/form7")}
                      onClick={() => backtolist()}

                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="mt-2 text-center">
                    <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>

                    <Button
                      // disabled={props.bStockUpdate}
                      disabled={props.bStockUpdate || !(manyTableData.length>0)}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onPostBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["post"]}
                    </Button>

                    <Button
                      disabled={!props.bStockUpdate || permissionType||props.bUnpostDisable}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onUnPostBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["Unpost"]}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      // onClick={() => props.history.push("/form7")}
                      onClick={() => backtolist()}

                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>

                    <ExportButtonsMany PrintMany={PrintMany}  {...props}></ExportButtonsMany>
                    {/* <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PrintMany()}
                    >
                      <i class="fas fa-print"></i>
                    </Button> */}

                    {/* <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelMany()}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button> */}
                  </Grid>
                )}
              </div>

              <div className="mr-4 float-right mt-15">
                <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
              </div>

            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            {/* <Card className="sw_card">
            <CardHeader title="Personal Information" />
            <CardContent> */}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={true /*bStockUpdate*/}
                      error={props.errorObject.TransactionNo}
                      helperText={props.errorObject.TransactionNo}
                      required
                      id="TransactionNo"
                      name="TransactionNo"
                      label={DispensingLanguage[lan][menukey]["Id"]}
                      value={
                        props.formData.TransactionNo || props.generatedInvoiceNo
                      }
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label-receive-from">
                        {DispensingLanguage[lan][menukey]["WH"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.FacilityCode}
                        labelId="demo-simple-select-helper-label-receive-from"
                        id="FacilityCode"
                        name="FacilityCode"
                        value={props.formData.FacilityCode}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={true /*props.bStockUpdate*/}
                      >
                        {IssuingToFacility.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>

                      <FormHelperText error={props.errorObject.FacilityCode}>
                        {props.errorObject.FacilityCode}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      /* disabled={
                        props.bStockUpdate ||
                        (props.addProductForm ? false : true)
                      } */

                      disabled={ true }
                      // disabled={
                      //   manyTableData.length > 0 ? true : props.bStockUpdate
                      // }
                      // error={props.errorObject.IndentNo}
                      // helperText={props.errorObject.IndentNo}
                      id="TotalUpazila"
                      name="TotalUpazila"
                      label={DispensingLanguage[lan][menukey]["Total Upazila"]}
                      value={props.formData.TotalUpazila}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3}></Grid>

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      {/* <InputLabel id="demo-simple-select-helper-label-receive-from">
                        {DispensingLanguage[lan][menukey]["Report Month"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.MonthId}
                        labelId="demo-simple-select-helper-label-receive-from"
                        id="MonthId"
                        name="MonthId"
                        value={props.formData.MonthId}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        // disabled={props.bStockUpdate}
                        disabled={
                          props.bStockUpdate ||
                          (props.addProductForm ? false : true)
                        }
                      >
                        {MonthList.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>

                      <FormHelperText error={props.errorObject.MonthId}>
                        {props.errorObject.MonthId}
                      </FormHelperText> */}

                        <Autocomplete
                            autoHighlight
                            disableClearable
                            disabled={
                              props.bStockUpdate ||
                              (props.addProductForm ? false : true)
                            }
                            id="MonthId"
                            options={MonthList}
                            onChange={(event, valueobj) => handleChangeChoosen('MonthId', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValues['MonthId']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={props.formData.MonthId}
                              label={DispensingLanguage[lan][menukey]["Report Month"]+ " *"}
                              variant="standard"
                              error={props.errorObject.MonthId}
                              helperText={props.errorObject.MonthId}
                              id="MonthId"
                              name="MonthId"
                              fullWidth
                            />
                            )}
                          />

                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={
                        props.bStockUpdate ||
                        (props.addProductForm ? false : true)
                      }
                      error={props.errorObject.YearName}
                      helperText={props.errorObject.YearName}
                      required
                      id="YearName"
                      name="YearName"
                      label={DispensingLanguage[lan][menukey]["Report Year"]}
                      value={props.formData.YearName || props.selectedYearName}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={5} sm={5}>
                    <TextField
                      disabled={props.bStockUpdate}
                      id="Remarks"
                      name="Remarks"
                      label={DispensingLanguage[lan][menukey]["Remarks"]}
                      value={props.formData.Remarks}
                      fullWidth
                      multiline
                      rows={3}
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="TransactionId"
                      name="TransactionId"
                      label="TransactionId"
                      value={props.formData.TransactionId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                {/* new row */}
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card">
                    <CardContent>
                      <Grid container className="mb-2">
                        <Grid item xs={12} sm={12}>
                          <div className="float-right sw_btn_control">
                            {/* <Button
                                  className="mr-2"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleClose("OrderOpen")}
                                >
                                  SELECT FROM ORDER
                                </Button>  */}

                            <Button
                              // disabled={props.bStockUpdate}
                              disabled={
                                props.bStockUpdate ||
                                (props.addProductForm ? true : false)
                              }
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              // onClick={() => handleClose("ProductsOpen")}
                              // onClick={() => props.generateForm7Items()}
                              onClick={() => generateForm7Items()}
                            >
                              {DispensingLanguage[lan][menukey]["GENERATE"]}
                            </Button>
                            <Button
                              // disabled={props.bStockUpdate}
                              disabled={
                                props.bStockUpdate ||
                                (props.addProductForm ? true : false)
                              }
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              // onClick={() => handleClose("ProductsOpen")}
                              onClick={() => props.deleteForm7Items()}
                            >
                              {DispensingLanguage[lan][menukey]["DELETE"]}
                            </Button>
                          </div>


                          <Form7InvStatusValidation
                            handleClose={handleClose}
                            open={open}
                            addProduct={addProduct}
                            FacilityId={FacilityId}
                            YearId={props.formData.YearName}
                            MonthId={props.formData.MonthId}
                            {...props}
                          />
                        </Grid>
                      </Grid>
                      <ReactTabulator
                        ref={(r) => (ReceiveDetailsRef = r)}
                        columns={ReceiveDetailsColumns}
                        rowFormatter={function (row) {
                          var data = row.getData();
                          if (data.linkNo > 0) {
                            row.getElement().style.backgroundColor = "#D9FCFF";
                            row.getElement().style.fontWeight = "bold";
                          }
                        }}
                        data={manyTableData}
                        height="450px"
                        // minHeight="350px"
                        layout={"fitColumns"}
                      />
                    </CardContent>
                  </Card>
                </Grid>

                {/* new row */}
              </Grid>
            </Grid>

            {/* </CardContent>
          </Card> */}
          </Grid>

          {/* Action buttons */}
        </Grid>
      </div>
    </div>
  );
};

export default Form7FormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
