import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as api from "../../../actions/api";
import {
  FormControlLabel,  
  Radio,
  FormLabel,
  RadioGroup,
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import swal from "sweetalert";
import { useQuery, useMutation, useQueryClient } from "react-query";

let timeStamp = "";

const EntryofUPazilaFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  open,
  ...props
}) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "entry-edit-of-upazila";
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const ReceivingWarehouseList = JSON.parse(
    localStorage.getItem("gWarehouseListAll")
  );
  const DistrictList = JSON.parse(localStorage.getItem("DistrictList"));

  const UsingSoftwareList =  [
    {
      "id": "Web",
      "name": "Web"
    },
    {
      "id": "Desktop",
      "name": "Desktop"
    }
  ];
  
//====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "whrec_id": {"id": "","name": ""},
  "DistrictId": {"id": "","name": ""}
});



useEffect(() => {
  
  let chosenValuesData = { ...chosenValues};
   chosenValuesData['whrec_id'] = ReceivingWarehouseList[ReceivingWarehouseList.findIndex(ReceivingWarehouse_List => ReceivingWarehouse_List.id == formData.whrec_id)];
   chosenValuesData['DistrictId'] = DistrictList[DistrictList.findIndex(District_List => District_List.id == formData.DistrictId)];
   setChosenValues(chosenValuesData); 

  }, [formData.whrec_id, formData.DistrictId]);

const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  setChosenValues(chosenValuesData); 
  props.handleChangeChoosenProps(name, value);
};

//====== End Choosen Dropdown===========



  
const backtolist=()=>{


  let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
  let idx = master_dirty_message_class.indexOf("dnone");
  if(idx === -1){
    // start Alert
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then((willAction) => {
      if (willAction) {
        props.history.push("/entry-edit-of-upazila");
      }
    });
    // End Alert
  }else{
    props.history.push("/entry-edit-of-upazila");
  }

}


const onSaveUpdateBtnClick = () => {

  setUpdatedDetailsGridDataHandler();
};


const setUpdatedDetailsGridDataHandler = () => {
  let validForm = props.checkValidateForm();
 
  // console.log('isval: ', validForm);
    if (validForm) {

      if(addProductForm){
        //when save
        mutate(validForm);
        
      }else{
        //when update
        mutateUpdate(validForm); 
      }
      

      
    }
};

const { isLoading : isLoadingSave, mutate } = useMutation(api.saveUpazilaEntry, {
  onSuccess: (data) => {
   // console.log("data: ", data);

    if (data.status == 200) {
      //console.log("data: ", data.data);
      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });
      queryClient.getQueriesData("EntryofUpazila"); //api page name
      props.history.push("/entry-edit-of-upazila"); //
    } else {
      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });
      queryClient.getQueriesData("EntryofUpazila"); //api page name
      props.history.push("/entry-edit-of-upazila"); //
    }
  },
});

const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.updateUpazila, {
  onSuccess: (data) => { 
    if (data.status == 200) {
      // api.getAllDropdown("ABC_List").then((response) => {
      //   localStorage.setItem(
      //     "ABC_List",
      //     JSON.stringify(response.datalist.ABC_List)
      //   );
      // });

      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });

      queryClient.getQueriesData("EntryofUpazila"); // include the page name
      props.history.push("/entry-edit-of-upazila"); //page url
      api.dirtyModeDisable();
    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
    }
  },
});



return (
  <div className={classes.productPageTitle}>
    <div className="sw_makeStyles_tableContainer">
      <div className="d-flex justify-product mb-3">
        <Grid item xs={12} sm={12}>
          <div className="sw_page_heading">
            <div className="sw_heading_title">
              {DispensingLanguage[lan][menukey]["Entry Edit of Upazila - Add/Edit"]}
            </div>
            </div>
            <div className="float-right sw_btn_control">
              {addProductForm ? (
                <Grid item xs={12} className="text-center">
                  <div className="float-right sw_btn_control">
                    <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => handleReset()}
                    >
                      {DispensingLanguage[lan][menukey]["Reset"]}
                    </Button>
                    <Button
                      disabled={props.bStockUpdate || isLoadingSave}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                      // onClick={() => handleSubmit()}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={() => props.history.push("/menu-entry")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Cancel"]}
                    </Button>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} className="mt-2 text-center">
                  <Button
                      disabled={props.bStockUpdate || isLoadingUpdate}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClick}
                    // onClick={() => handleUpdate()}
                  >
                    {DispensingLanguage[lan][menukey]["Update"]}
                  </Button>

                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    //onClick={() => props.history.push("/menu-entry")}
                    onClick={() => backtolist()}
                  >
                    {DispensingLanguage[lan][menukey]["Cancel"]}
                  </Button>
                </Grid>
              )}
            </div>
          
             <div className="mr-4 float-right mt-15">
              <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
            </div>
        </Grid>
      </div>

      {/* New row */}
      <Grid container spacing={3}>
        {/* New row */}
        <Grid item xs={12} sm={12}>
          <Card className="sw_card">
            <CardContent>
                <Grid container spacing={10}>
                <Grid item xs={4} sm={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={4} sm={4}>
                      <TextField
                        error={errorObject.upazila_name}
                        helperText={errorObject.upazila_name}
                        required
                        disabled={props.permissionType}
                        id="upazila_name"
                        name="upazila_name"
                        label={
                          DispensingLanguage[lan][menukey]["Upazila Name"]
                        }
                        value={formData.upazila_name}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                    <Grid item xs={4} sm={4}>
                      <FormControl className={classes.fullWidth}>
                       {/*  <InputLabel id="demo-simple-select-helper-label">
                          {DispensingLanguage[lan][menukey]["Warehouse"]}*
                        </InputLabel>
                        <Select
                          disabled={props.permissionType}
                          error={errorObject.FacilityCode}
                          required
                          labelId="demo-simple-select-helper-label"
                          id="whrec_id"
                          name="whrec_id"
                          value={formData.whrec_id}
                          fullWidth
                          onChange={(e) => handleChange(e)}
                        >
                          <MenuItem value="0">
                            {" "}
                            {DispensingLanguage[lan][menukey]["All"]}
                          </MenuItem>
                          {ReceivingWarehouseList.map((item, index) => {
                            return (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            );
                          })}
                        </Select>

                        <FormHelperText error={errorObject.whrec_id}>
                          {errorObject.whrec_id}
                        </FormHelperText> */}

                        <Autocomplete
                          autoHighlight
                            //disableClearable
                          disabled={props.permissionType}
                          id="whrec_id"
                          options={ReceivingWarehouseList}
                          onChange={(event, valueobj) => handleChangeChoosen('whrec_id', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValues['whrec_id']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            value={formData.whrec_id}
                            label={DispensingLanguage[lan][menukey]["Warehouse"]+ " *"}
                            variant="standard"
                            error={errorObject.whrec_id}
                            helperText={errorObject.whrec_id}
                            id="whrec_id"
                            name="whrec_id"
                            fullWidth
                          />
                          )}
                        />


                      </FormControl>
                    </Grid>

                    <Grid item xs={4} sm={4}>
                      <FormControl className={classes.fullWidth}>
                        {/* <InputLabel id="demo-simple-select-helper-label">
                          {DispensingLanguage[lan][menukey]["District"]}*
                        </InputLabel>
                        <Select
                          disabled={props.permissionType}
                          error={errorObject.DistrictId}
                          required
                          labelId="demo-simple-select-helper-label"
                          id="DistrictId"
                          name="DistrictId"
                          //defaultValue='ALl'
                          //key={item.id}
                          //value={0}
                          value={formData.DistrictId}
                          fullWidth
                          onChange={(e) => handleChange(e)}
                        >
                          {DistrictList.map((item, index) => {
                            return (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            );
                          })}
                        </Select>

                        <FormHelperText error={errorObject.DistrictId}>
                          {errorObject.DistrictId}
                        </FormHelperText> */}

                        <Autocomplete
                          autoHighlight
                            //disableClearable
                          disabled={props.permissionType}
                          id="DistrictId"
                          options={DistrictList}
                          onChange={(event, valueobj) => handleChangeChoosen('DistrictId', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValues['DistrictId']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            value={formData.DistrictId}
                            label={DispensingLanguage[lan][menukey]["District"]+ " *"}
                            variant="standard"
                            error={errorObject.DistrictId}
                            helperText={errorObject.DistrictId}
                            id="DistrictId"
                            name="DistrictId"
                            fullWidth
                          />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={4} sm={4}>
                      <MuiPickersUtilsProvider
                        libInstance={moment}
                        utils={DateFnsUtils}
                      >
                        <KeyboardDatePicker
                          //disabled={bStockUpdate}
                          // required
                          disabled={props.permissionType}
                          error={errorObject.EffectiveDate}
                          helperText={errorObject.EffectiveDate}
                          className={classes.fullWidth}
                          //disableToolbar
                          variant="inline"
                          id="EffectiveDate"
                          label={
                            DispensingLanguage[lan][menukey]["Effective Date"]
                          }
                          autoOk={true}
                          name="EffectiveDate"
                          fullWidth
                          showTodayButton={true}
                          disableFuture={true}
                          value={formData.EffectiveDate || null}
                          format="dd/MM/yyyy"
                          onChange={(e) =>
                            props.handleRDateChange(e, "EffectiveDate")
                          }
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={4} sm={4}>
                      <TextField
                        error={errorObject.contact_person}
                        helperText={errorObject.contact_person}
                        disabled={props.permissionType}
                        id="contact_person"
                        name="contact_person"
                        label={
                          DispensingLanguage[lan][menukey]["Contact Person"]
                        }
                        value={formData.contact_person}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <TextField
                        error={errorObject.no_sdp}
                        helperText={errorObject.no_sdp}
                        disabled={props.permissionType}
                        id="no_sdp"
                        name="no_sdp"
                        label={DispensingLanguage[lan][menukey]["No of SDP"]}
                        value={formData.no_sdp}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <TextField
                        error={errorObject.LandPhoneNo}
                        helperText={errorObject.LandPhoneNo}
                        disabled={props.permissionType}
                        id="LandPhoneNo"
                        name="LandPhoneNo"
                        label={DispensingLanguage[lan][menukey]["Phone No"]}
                        value={formData.LandPhoneNo}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                        inputProps={{
                          maxLength: 11,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <TextField
                        error={errorObject.extra}
                        helperText={errorObject.extra}
                        disabled={props.permissionType}
                        id="extra"
                        name="extra"
                        label={DispensingLanguage[lan][menukey]["Mobile No"]}
                        value={formData.extra}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                        inputProps={{
                          maxLength: 11,
                        }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                      <TextField
                        error={errorObject.up_address}
                        helperText={errorObject.up_address}
                        disabled={props.permissionType}
                        id="up_address"
                        name="up_address"
                        label={DispensingLanguage[lan][menukey]["Address"]}
                        value={formData.up_address}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>

                    <Grid item xs={12} sm={2}>
                      <FormControl
                        className="sw_radiogroup"
                        component="fieldset"
                        //required
                        error={errorObject.UsingSoftware}
                        helperText={errorObject.UsingSoftware}
                      >
                        <FormLabel component="legend">
                          {DispensingLanguage[lan][menukey]["Using_Web_Desktop"]}
                        </FormLabel>
                        <RadioGroup
                          row
                          aria-label="UsingSoftware"
                          name="UsingSoftware"
                          value={formData.UsingSoftware}
                          onChange={handleChange}
                        >
                          {UsingSoftwareList.map((item, index) => {
                            return (
                              <FormControlLabel
                                value={item.id}
                                control={<Radio />}
                                label={item.name}
                                disabled={formData.UsingSoftwareEdit === 'Web'}
                              ></FormControlLabel>
                            );
                          })}
                        </RadioGroup>
                      </FormControl>
                    </Grid>

                  </Grid>
                </Grid>
              </Grid>
              {/* New Row */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  </div>
);
};

export default EntryofUPazilaFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
