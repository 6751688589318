import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import * as api from "../../../actions/api";

const DDFPInformationEntryFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  open,
  ...props
}) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "ddfp-information-entry";
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  
  //const UnitList = JSON.parse(localStorage.getItem('Unitofmeasurelist'));
  const UpazilaList = JSON.parse(localStorage.getItem("UpazilaList"));

  const [firstLoad, setFirstLoad] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [manyTableData, setManyTableData] = useState([]);


  
const backtolist=()=>{


  let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
  let idx = master_dirty_message_class.indexOf("dnone");
  if(idx === -1){
    // start Alert
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then((willAction) => {
      if (willAction) {
        props.history.push("/ddfp-information-entry");
      }
    });
    // End Alert
  }else{
    props.history.push("/ddfp-information-entry");
  }

}


const onSaveUpdateBtnClick = () => {

  setUpdatedDetailsGridDataHandler();
};


const setUpdatedDetailsGridDataHandler = () => {
  let validForm = props.checkValidateForm();
 
  // console.log('isval: ', validForm);
    if (validForm) {

      if(addProductForm){
        //when save
        // mutate(validForm);
        
      }else{
        //when update
        mutateUpdate(validForm); 
      }
      

      
    }
};

const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.UpdaeDDFPInformationData, {
  onSuccess: (data) => { 
    if (data.status == 200) {
   
      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });

      queryClient.getQueriesData("ddfpinformationentry"); // include the page name
      props.history.push("/ddfp-information-entry"); //page url
      api.dirtyModeDisable();
    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
    }
  },
});

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title"> {DispensingLanguage[lan][menukey]['DDFP Information- Edit']} </div>
            </div>
            <div className="float-right sw_btn_control">

              {addProductForm ? (
                <Grid item xs={12} className="mt-4 text-center tex_margin">
                  <Button
                disabled={props.permissionType}
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => handleReset()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Reset'])}
                  </Button>
                  <Button
                disabled={props.permissionType}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={() => handleSubmit()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Save'])}
                  </Button>
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    //onClick={() => props.history.push("/ddfp-information-entry")}
                    onClick={() => backtolist()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Back to List'])}
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={12} className="mt-2 text-center">
                  <Button
                disabled={props.permissionType || isLoadingUpdate}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClick}
                    //onClick={() => handleUpdate()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Update'])}
                  </Button>
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    //onClick={() => props.history.push("/ddfp-information-entry")}
                    onClick={() => backtolist()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Back to List'])}
                  </Button>
                </Grid>
              )}



            </div>

            
            <div className="mr-4 float-right mt-15">
              <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
            </div>
            
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={10}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              {/* <CardHeader title={DispensingLanguage[lan][menukey]['District Name']} /> */}
              <CardContent>
                <Grid container spacing={10}>
                  <Grid item xs={4} sm={12}>
                    <Grid container spacing={4}>
                  
                      <Grid item xs={4} sm={4}>

                        <TextField
                          error={errorObject.DistrictName}
                          helperText={errorObject.DistrictName}
                          
                        disabled={true}
                          id="DistrictName"
                          name="DistrictName"
                          label={DispensingLanguage[lan][menukey]["District Name"]}
                          value={formData.DistrictName}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>


                      <Grid item xs={4} sm={4}>

                            <TextField
                              error={errorObject.districtphone}
                              helperText={errorObject.districtphone}
                              
                         disabled={props.permissionType}
                              id="districtphone"
                              name="districtphone"
                              label={DispensingLanguage[lan][menukey]["DDFP Contact No"]}
                              value={formData.districtphone}
                              fullWidth
                              autoComplete="family-name"
                              onChange={(e) => handleChange(e)}
                            />
                      </Grid>

                      <Grid item xs={4} sm={4}>

                            <TextField
                              error={errorObject.districtEmail}
                              helperText={errorObject.districtEmail}
                              
                         disabled={props.permissionType}
                              id="districtEmail"
                              name="districtEmail"
                              label={DispensingLanguage[lan][menukey]["DDFP Email"]}
                              value={formData.districtEmail}
                              fullWidth
                              autoComplete="family-name"
                              onChange={(e) => handleChange(e)}
                            />
                      </Grid>
                     
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}



        </Grid>
      </div>
    </div>
  );
};

export default DDFPInformationEntryFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
