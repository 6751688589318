import React, { useState, useEffect } from "react";
import { makeStyles} from "@material-ui/core/styles";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  Typography,
  Grid, 
  FormControl, 
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent, 
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import swal from "sweetalert";
import * as api from "../../../actions/api";
import { useTranslation } from "react-i18next";

//get DispensingLanguage 

const MenuEntryFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "menu-entry";
  const ParentMenu = JSON.parse(localStorage.getItem("ParentMenu"));
  const queryClient = useQueryClient();
  const MenuLevelList = [
    {
        "id": "menu_level_1",
        "name": "Menu Level 1"
    },
    {
        "id": "menu_level_2",
        "name": "Menu Level 2"
    },
    {
        "id": "menu_level_3",
        "name": "Menu Level 3"
    }
];

const positionList = [
  {
      "id": "left_position",
      "name": "Left Position"
  },
  {
      "id": "right_position",
      "name": "Right Position"
  }
];


  //ParentMenu.unshift({'id':"",'name':""});

  //====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "parentId": {"id": "","name": ""},
  "MenuLevel": {"id": "","name": ""},
  "position": {"id": "","name": ""}
});


useEffect(() => {
  
  let chosenValuesData = { ...chosenValues};
   chosenValuesData['parentId'] = ParentMenu[ParentMenu.findIndex(Parent_Menu => Parent_Menu.id == formData.parentId)];
   chosenValuesData['MenuLevel'] = MenuLevelList[MenuLevelList.findIndex(MenuLevel_List => MenuLevel_List.id == formData.MenuLevel)];
   chosenValuesData['position'] = positionList[positionList.findIndex(position_List => position_List.id == formData.position)];
   setChosenValues(chosenValuesData); 

  }, [formData.parentId, formData.MenuLevel, formData.position]);

const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  setChosenValues(chosenValuesData); 
  props.handleChangeChoosenProps(name, value);
};

//====== End Choosen Dropdown===========



const backtolist=()=>{


  let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
  let idx = master_dirty_message_class.indexOf("dnone");
  if(idx === -1){
    // start Alert
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then((willAction) => {
      if (willAction) {
        props.history.push("/menu-entry");
      }
    });
    // End Alert
  }else{
    props.history.push("/menu-entry");
  }

}


const onSaveUpdateBtnClick = () => {

  setUpdatedDetailsGridDataHandler();
};


const setUpdatedDetailsGridDataHandler = () => {
  let validForm = props.checkValidateForm();
 
  // console.log('isval: ', validForm);
    if (validForm) {

      if(addProductForm){
        //when save
        mutate(validForm);
        
      }else{
        //when update
        mutateUpdate(validForm); 
      }
      

      
    }
};

const { isLoading : isLoadingSave, mutate } = useMutation(api.saveMenuentry, {
  onSuccess: (data) => {

    if(data.status==200) {

       api.getDispensingLanguage().then((response) => {
         localStorage.setItem(
           "DispensingLanguage",
           JSON.stringify(response.datalist)
         );
       })

    props.openNoticeModal({
      isOpen: true,
      msg: data.message,
      msgtype: data.success,
    });

    queryClient.getQueriesData("menuentry");
    props.history.push("/menu-entry");

  }else{

    props.openNoticeModal({
      isOpen: true,
      msg: data.message,
      msgtype: data.success,
    });
   
  }

  },
});

const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.updateMenuentry, {
  onSuccess: (data) => {
    if (data.status == 200) {
       api.getDispensingLanguage().then((response) => {
         localStorage.setItem(
           "DispensingLanguage",
           JSON.stringify(response.datalist)
         );
       })
      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });
      queryClient.getQueriesData("menuentry");
      props.history.push("/menu-entry");
      api.dirtyModeDisable();
    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
     
    }
  },
});
  
return (
  <div className={classes.productPageTitle}>
    <div className="sw_makeStyles_tableContainer">
      <div className="d-flex justify-product mb-3">
        <Grid item xs={12} sm={12}>
          <div className="sw_page_heading">
            <div className="sw_heading_title">
              {DispensingLanguage[lan][menukey]["Menu Entry - Add/Edit"]}
            </div>
            </div>
            <div className="float-right sw_btn_control">
              {addProductForm ? (
                <Grid item xs={12} className="text-center">
                  <div className="float-right sw_btn_control">
                    <Button
                      disabled={props.bStockUpdate || isLoadingSave}
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => handleReset()}
                    >
                      {DispensingLanguage[lan][menukey]["Reset"]}
                    </Button>
                    <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                      // onClick={() => handleSubmit()}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={() => props.history.push("/menu-entry")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} className="mt-2 text-center">
                  <Button
                      disabled={props.bStockUpdate || isLoadingUpdate}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClick}
                    // onClick={() => handleUpdate()}
                  >
                    {DispensingLanguage[lan][menukey]["update"]}
                  </Button>

                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    //onClick={() => props.history.push("/menu-entry")}
                    onClick={() => backtolist()}
                  >
                    {DispensingLanguage[lan][menukey]["Back to List"]}
                  </Button>
                </Grid>
              )}
            </div>
          
             <div className="mr-4 float-right mt-15">
              <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
            </div>
        </Grid>
      </div>

      {/* New row */}
      <Grid container spacing={3}>
        {/* New row */}
        <Grid item xs={12} sm={12}>
          <Card className="sw_card">
            <CardContent>
               <Grid container spacing={4}>
                    <Grid item xs={12} sm={3}>
                      <TextField
                          disabled={props.bStockUpdate}
                        error={errorObject.title}
                        helperText={errorObject.title}
                        required
                        id="title"
                        name="title"
                        label={DispensingLanguage[lan][menukey]['Menu Name']}
                        value={formData.title}
                        fullWidth
                        autoComplete="family-name"
                       // inputProps={{ maxLength: 4 }}
                        onChange={(e) => handleChange(e)}
                       
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                    <TextField
                        error={errorObject.url}
                        helperText={errorObject.url}
                        required
                        id="url"
                        name="url"
                        label={DispensingLanguage[lan][menukey]['Menu Url']}
                        value={formData.url}
                        fullWidth
                        autoComplete="family-name"
                        disabled={!addProductForm || props.bStockUpdate}
                        inputProps={{ maxLength: 150 }}
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>

                    <FormControl fullWidth>
                      {/* <InputLabel id="demo-simple-select-label">{DispensingLanguage[lan][menukey]['Menu Position']}</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="position"
                        value={formData.position}
                        fullWidth
                        onChange={(e) => handleChange(e)}
                      >
                        <MenuItem value={"left_position"}>Left Position</MenuItem>
                        <MenuItem value={"right_position"}>Right Position</MenuItem>
                      </Select> */}

                        <Autocomplete
                          autoHighlight
                          id="position"
                          disabled={props.bStockUpdate}
                          options={positionList}
                          onChange={(event, valueobj) => handleChangeChoosen('position', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValues['position']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            value={formData.position}
                            label={DispensingLanguage[lan][menukey]["Menu Position"]}
                            variant="standard"
                            error={errorObject.position}
                            helperText={errorObject.position}
                            id="position"
                            name="position"
                            fullWidth
                          />
                          )}
                        />
                    
                    </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                    <FormControl fullWidth>
                      {/* <InputLabel id="demo-simple-select-label">{DispensingLanguage[lan][menukey]['Menu MenuLevel']}</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="MenuLevel"
                        value={formData.MenuLevel}
                        fullWidth
                        onChange={(e) => handleChange(e)}
                      >
                        <MenuItem value={"menu_level_1"}>Menu Level 1</MenuItem>
                        <MenuItem value={"menu_level_2"}>Menu Level 2</MenuItem>
                        <MenuItem value={"menu_level_3"}>Menu Level 3</MenuItem>
                      </Select> */}

                        <Autocomplete
                          autoHighlight
                          id="MenuLevel"
                          disabled={props.bStockUpdate}
                          options={MenuLevelList}
                          onChange={(event, valueobj) => handleChangeChoosen('MenuLevel', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValues['MenuLevel']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            value={formData.MenuLevel}
                            label={DispensingLanguage[lan][menukey]["Menu MenuLevel"]}
                            variant="standard"
                            error={errorObject.MenuLevel}
                            helperText={errorObject.MenuLevel}
                            id="MenuLevel"
                            name="MenuLevel"
                            fullWidth
                          />
                          )}
                        />
                 
                    </FormControl>

                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <FormControl className={classes.fullWidth}>
                     {/*  <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Parent menu"]}
                      </InputLabel>
                      <Select
                        //error={errorObject.parentId}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        name="parentId"
                        value={formData.parentId}
                        fullWidth
                        onChange={(e) => handleChange(e)}
                      >

                        {ParentMenu.map((item, index) => {
                          return <MenuItem value={item.id}>{item.name}</MenuItem>;
                        })}
                      </Select> */}

                        <Autocomplete
                          autoHighlight
                          id="parentId"
                          disabled={props.bStockUpdate}
                          options={ParentMenu}
                          onChange={(event, valueobj) => handleChangeChoosen('parentId', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValues['parentId']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            value={formData.parentId}
                            label={DispensingLanguage[lan][menukey]["Parent menu"]}
                            variant="standard"
                            error={errorObject.parentId}
                            helperText={errorObject.parentId}
                            id="parentId"
                            name="parentId"
                            fullWidth
                          />
                          )}
                        />
              
                    </FormControl>
                    </Grid>
                  </Grid>

              {/* New Row */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  </div>
);
};

export default MenuEntryFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
