import statesData from "../../../assets/mapfiles/bd_districts_2024_geo.v1.json";
import {
  Map,
  ImageOverlay,
  Marker,
  Popup,
  TileLayer,
  Polygon,
  CircleMarker,
  Tooltip,
} from "react-leaflet";

import React, { useState, useEffect, useContext } from "react";
import "leaflet/dist/leaflet.css";

const center = [24.044539737546362, 90.3443647];

const MapLeaflet = (props) => {
  // console.log("Maappppppppppppppppppppppss", props);

  let maxUsableQty = 1;
  if (props.MapData != undefined) {
    maxUsableQty = props.MapData.reduce((max, facility) => {
      return parseInt(facility.UsableQty) > max
        ? parseInt(facility.UsableQty)
        : max;
    }, 0);
  }

  return (
    <Map
      center={center}
      zoom={props.zoom}
      minZoom={1}
      maxZoom={15}
      style={{ height: props.Height, width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='</a>
           <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; 
           OpenStreetMap contributors</a>'
      />

      {statesData.features.map((state) => {
        const coordinates = state.geometry.coordinates[0].map((item) => [
          item[1],
          item[0],
        ]);

        return (
          <Polygon
            fillColor="#FEFEC7"
            color="gray" // This is the stroke color
            weight={1} // Stroke width
            fillOpacity={0.7}
            opacity={1}
            dashArray={3}
            positions={coordinates}
            eventHandlers={{
              mouseover: (e) => {},
              mouseout: (e) => {},
              click: (e) => {},
            }}
          />
        );
      })}

      {props.MapData != undefined ? (
        props.MapData.map((state) => {
          if(!state.Latitude || !state.Longitude){
            return (<></>);
          }

          let coordinates = [state.Latitude, state.Longitude];
          let radious = (state.UsableQty * 40) / maxUsableQty;
          if (radious < 5) radious = 5;

          return (
            <CircleMarker
              center={coordinates}
              radius={radious}
              fillColor="#9071ce"
              color="#744ec2" // This is the stroke color
              weight={2} // Stroke width
              fillOpacity={0.6}
              opacity={0.5}
              onMouseOver={(event) => event.target.openPopup()}
            >
              <Tooltip>
                <span>
                  {" "}
                  {state.FacilityName}
                  <br></br>
                  <b>
                    {
                      props.DispensingLanguage[props.lan][props.menukey][
                        "Balance"
                      ]
                    }
                    :{" "}
                  </b>
                  {new Intl.NumberFormat().format(state.UsableQty)}{" "}
                </span>
              </Tooltip>
            </CircleMarker>
          );
        })
      ) : (
        <></>
      )}
    </Map>
  );
};

export default MapLeaflet;
