import React, { useState , useEffect} from "react";
import {
  Typography
} from "@material-ui/core";
import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query'
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import swal from "sweetalert";
import * as api from '../../../actions/api';
import AdjustmentFormData from './AdjustmentFormData';
import Constants from "../../../services/Constants";
import { useTranslation } from "react-i18next";
import moment from "moment";

const EditAdjustment = (props) => {
   
    //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "adjustment-facility-approval";
  const FacilityId=localStorage.getItem('FacilityId');
  const UseFor = localStorage.getItem("UseFor");
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;

  const { t, i18n } = useTranslation();
  const [formData, setFormData] = useState({
      TransactionDate: "",
      AdjTypeId: "", 
      TransactionNo: "", 
      FacilityId: FacilityId,
      FromFacility: '',
      TransactionId:"", 
      IssueToFacility:"",
      PreparedBy:"",
      ApprovedBy:"",
      AppDate1: "",
      IssuedBy: "",
      IssuedDate: "",
      AdjRemarks: "",
      UseFor: UseFor,
      ApprovedByName:"",
      ApprovedByName:"",
      Issued_ByName:"",
    });
    const [selectedDate, setSelectedDate] = useState(
      //new Date()
      moment().format("YYYY-MM-DD")
    );
    const handleDateChange = (date) => {
      setSelectedDate(date);
    };
  const [ManyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState("0");
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
  const [TransactionId, setTransactionId] = useState("0");
  const [deletedDataSet, setDeletedDataSet] = useState([]);
  // const [isPositive, setIsPositive] = useState(true);
  // const [pickListMethod, setPickListMethod] = useState();
  const [AdjTypeId, setAdjTypeId] = useState();
  const [renderAfterPost, setRenderAfterPost] = useState(false);
  
  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState(
    ''
  );
  const [bFirst, setBFirst] = useState(true);  
   
const handleRDateChange = (date, field) => {

  let newDate;
 if(date==null){
  newDate=date;
 }else{
   newDate = moment(date).format("YYYY-MM-DD");
 }
  setFormData({ ...formData, [field]: newDate });
  setErrorObject({ ...errorObject, [field]: null });
  api.dirtyModeEnable();
};

  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data,refetch } = useQuery(['adjustment', id], () =>
    api.getAdjustmentFacilityInvoiceListSingle(id).then((res) => {
      
      //This is for user has VIEW/EDIT permission in this page start
      if (props.permissionType === 1) {
        //when permissionType then it is only VIEW permission otherwise normal permission
        res.data.bStockUpdated = 1;
      }
      //This is for user has VIEW/EDIT permission in this page end

      
      setTransactionId(id); 
      setFormData(res.data); 
      setbStockUpdate(res.data.bStockUpdated);
      setManyTableData(res.data.ManyJsonSave);//res.data.ManyJsonSave
      setUpdatedDetailGridData(res.data.ManyJsonSave);
      setGeneratedInvoiceNo(res.data.TransactionNo); 

      // let IsPositive=res.data.IsPositive=="-1"?true:false; 
      // setIsPositive(IsPositive);
      // setPickListMethod(res.data.PickListMethod); 

      setAdjTypeId(res.data.AdjTypeId);
      setBFirst(true);
      
      api.dirtyModeDisable();
      
      return res.data
    }), {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,

      enabled: Boolean(id)
  })

  
  // const { isLoading, mutate:mutateUpdate } = useMutation(api.AdjustmentInvoiceDataUpdate, {
  //   onSuccess: (data) => {
  //     if (data.status == 200) {

  //         setAdjTypeId(data.data.AdjTypeId);

  //         if(data.bStockUpdated==1){
  //           mutatePost(TransactionId);
          
  //         }else{
            
  //           refetch(); 
  //           setDeletedDataSet([]);

  //           props.openNoticeModal({
  //             isOpen: true,
  //             msg: data.message,
  //             msgtype: data.success,
  //           });
  //         }

  //     } else {
  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.message,
  //         msgtype: data.success,
  //       });
  //     }
  //   } 
  // })
 
  
  // const { isLoading1, mutate:mutatePost } = useMutation(api.updateAdjustmentInvoiceStock, {
  //   onSuccess: (data) => { 

  //     if (data.status == 200) {

  //       //setbStockUpdate(data.bStockUpdated);
  //       setDeletedDataSet([]);

  //      if(data.success==0){
  //         setbStockUpdate(0);
  //         props.openNoticeModal({
  //           isOpen: true,
  //           msg: data.message,
  //           msgtype: data.success,
  //         });
  //      }else{
  //         setbStockUpdate(data.bStockUpdated);
  //         refetch(); 
  //         props.openNoticeModal({
  //           isOpen: true,
  //           msg: data.message,
  //           msgtype: data.success,
  //           duration: 10000,
  //         });
  //      } 
  //     }
  //   }
  // })

  const handleAdd = (NonOrderProductData) => {
      
    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);

};
  const handleChange = (e) => {

    if(e=='IssuedToFacility'){
      setErrorObject({ ...errorObject, ['IssueToFacility']: null });
    }
    else{

    const { name, value } = e.target;
    // if (name == "price" || name == "sellingPrice" || name == "markUp") {
    //   checkNumberValidation(name, value);
    // } else {
      let data = { ...formData };
      data[name] = value;            
      // let productData = generateProductCodeAndName(data);      
      // if (productData.productCode) {
      //   data["productCode"] = productData.productCode
      // }
      // if (productData.productName) {
      //   data["productName"] = productData.productName
      // }
      setFormData(data);
    // }
    setErrorObject({ ...errorObject, [name]: null });
    }
    api.dirtyModeEnable();
  };

    //====== Start Choosen Dropdown===========
    const handleChangeChoosenProps = (name, value) => {
      let data = { ...formData };
      data[name] = value;   
      setFormData(data); 
      setErrorObject({ ...errorObject, [name]: "" });
      api.dirtyModeEnable();
    }
    //====== End Choosen Dropdown===========

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      TransactionDate: selectedDate,
      SupplierInvDate: "",
      SupplierInvNo: "",
      TransactionNo: "", 
      FacilityId:FacilityId,
      FromFacility: '',
      TransactionId:"",
      PreparedBy:"",
      ApprovedBy:"",
      AppDate1: selectedDate,
      IssuedBy: "",
      IssuedDate: selectedDate,
      AdjRemarks: "",
      UseFor: UseFor,
      ApprovedByName:"",
      ApprovedByName:"",
      Issued_ByName:"",
    });
  };

  const validateForm = (formData) => {
    let validateFields = [ 
      "TransactionDate", 
      "TransactionNo",
      "FromFacility", 
      // "IssueToFacility",
      "PreparedBy", 
      "ApprovedBy", 
      "AppDate1", 
      "IssuedBy", 
      "IssuedDate"
    ]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !']
        isValid = false
      }
    })
    setErrorObject(errorData);
    return isValid
  }

  const deleteReceiveInv=(data)=>{ 
    let newarray = ManyTableData.filter(element => element !== data);
    
    setManyTableData(newarray);
    setUpdatedDetailGridData(newarray);
    if(data.TransactionItemId!=null){
      let newDeletedDataSet=[data.TransactionItemId]; 
      setDeletedDataSet(newDeletedDataSet.concat(deletedDataSet)); 
    }
    
  }

  // const handleUpdate = async (e) => {
  //   if (validateForm(formData)) {
  //   let fDtat={...formData,'ManyJsonSave':ManyTableData,'deletedDataSet':deletedDataSet}; 
  //   mutateUpdate(fDtat); 
  //   }
  // };

  // const handlePost = async (e) => {  

  //   if (validateForm(formData)) {
      
  //     // Start Post Alert
  //     swal({
  //       title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
  //       text: t(DispensingLanguage[lan][menukey]["Do you really want to post the stock?"]),
  //       icon: "warning",
  //       buttons: {
  //         confirm: {
  //           text: t(DispensingLanguage[lan][menukey]["Yes"]),
  //           value: true,
  //           visible: true,
  //           className: "",
  //           closeModal: true,
  //         },
  //         cancel: {
  //           text: t(DispensingLanguage[lan][menukey]["No"]),
  //           value: null,
  //           visible: true,
  //           className: "",
  //           closeModal: true,
  //         },
  //       },
  //       /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
  //       t(DispensingLanguage[lan][menukey]["Yes"])],
  //       dangerMode: true, */
  //     }).then((willAction) => {
  //       if (willAction) {
  //         //setStockPost(true);
  //         let fDtat={...formData,'ManyJsonSave':ManyTableData,"bStockUpdated":1,'deletedDataSet':deletedDataSet}; 
  //         mutateUpdate(fDtat); 
          
  //       }else{
          
  //         setbStockUpdate(0);
  //         let route = `${TransactionId}`;
  //         props.history.push(route);
  //       }
  //     });
  //     // End Post Alert
  //   }
  // };

  const setUpdatedDetailsGridDataHandler = (rows) => { 
    
    if (rows.datatype == "FormData") {
      setFormData(rows.IssueToFacility);
    } 
    // else {
    //   if (validateForm(formData)) {
    //     let fDtat = {
    //       ...formData,
    //       ManyJsonSave: rows.data,
    //       menukey: "adjustment",
    //       'deletedDataSet':deletedDataSet
    //     };
    //     mutateUpdate(fDtat); 
    //   } 
    //   setManyTableData(rows.data);
    // }
  };


  
  function checkValidateForm(rows, callfrom){

    if(callfrom == "edit"){
      setManyTableData(rows); 
    }

    // return validateForm(formData);
    if (validateForm(formData)) {
        let fDtat={...formData,'ManyJsonSave':rows,'deletedDataSet':deletedDataSet}; 
        //mutateUpdate(fDtat); 
        return fDtat;
      }else{
        return false;
      }
  };

  function refetchInvoice(){
      refetch();
  };

  function stockPosted(val){
    setbStockUpdate(val);
    // setDeletedDataSet([]);
  };

  function setAdjTypeIdFunction(val){
    // console.log('val222222222222222222222: ', val);
    setAdjTypeId(val);
  };



  // const invoiceCancel = (e)=>{

  //   const { name, checked } = e.target;

  //   swal({
  //     title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
  //     text: t(DispensingLanguage[lan][menukey]["Do you really want to cancel?"])+" "+t(DispensingLanguage[lan][menukey]["You will not able to edit this invoice after cancel"]),
  //     icon: "warning",
  //     buttons: {
  //       confirm: {
  //         text: t(DispensingLanguage[lan][menukey]["Yes"]),
  //         value: true,
  //         visible: true,
  //         className: "",
  //         closeModal: true,
  //       },
  //       cancel: {
  //         text: t(DispensingLanguage[lan][menukey]["No"]),
  //         value: null,
  //         visible: true,
  //         className: "",
  //         closeModal: true,
  //       },
  //     },
  //     /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
  //     t(DispensingLanguage[lan][menukey]["Yes"])],
  //     dangerMode: true, */
  //   }).then((willCancel) => {
  //     if (willCancel) {
  //       let fDtat={...formData,'ManyJsonSave':ManyTableData,"BCancel":1,'deletedDataSet':deletedDataSet};
  //       mutateUpdate(fDtat);
  //     }else{
  //       setFormData({ ...formData, [name]: false });
  //     }
  //   });
  // };

  useEffect(() => { 
    
  }, [AdjTypeId,deletedDataSet]); 

  return (
    <>
      <AdjustmentFormData 
        
        errorObject={errorObject} 
        addProductForm={false}
        formData={formData} 
        handleChange={handleChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleCheck={handleCheck} 
        handleReset={handleReset} 
        // handleUpdate={handleUpdate}  
        ManyTableData={updatedDetailGridData} 
        handleRDateChange={handleRDateChange} 
        selectedDate={selectedDate} 
        handleAdd={handleAdd} 
        bStockUpdate={bStockUpdate} 
        setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler} 
        // handlePost={handlePost} 
        deleteReceiveInv={deleteReceiveInv} 
        generatedInvoiceNo={generatedInvoiceNo} 
        DisableAddBtn={false}
        // isPositive={isPositive}
        // updateIspositive={updateIspositive}
        AdjTypeId={AdjTypeId}
        bFirst={bFirst}
        // invoiceCancel={invoiceCancel} 
        TransactionId={TransactionId}
        setAdjTypeIdFunction={setAdjTypeIdFunction}
        checkValidateForm={checkValidateForm}
        refetchInvoice={refetchInvoice}
        stockPosted={stockPosted}

        {...props} />
    </>
  );
};

export default EditAdjustment;