/*eslint-disable*/
import React from "react";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

function DarkFooter() {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col md="4">
            <div className="copyright" id="copyright">
              <div className="footer_logo"><img alt="..." src={require("assets/img/usaid_logo.png")}></img>
              </div>
              <p>
                Disclaimer:
                <br />
                This eLMIS was possible by the generous support of the American
                people through the US Agency for International Development
                (USAID) contract no. 7200AA18C00074. The contents are the
                responsibility of the authors and do not necessarily reflect the
                views of USAID or the US Government.
              </p>
            </div>
          </Col>
          <Col md="8">
            <div className="versionText">
              DGFP eLMIS v{localStorage.getItem("Version")}  last updated {process.env.REACT_APP_VERSION_APP} 
            </div> 
            
             
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default DarkFooter;
