import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import * as api from "../../../actions/api";
import CountryEntryFormData from "./CountryEntryFormData";

const EditCountryEntry = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "country-entry";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;

  const [formData, setFormData] = useState({
    CountryCode: "",
    CountryName: "",
    CenterLat: "",
    CenterLong: "",
    ISO3: "",
    NumCode: "",
    ZoomLevel: "",
  });

  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  useQuery(
    ["CountryEntry", id],
    () =>
      api.geCountryEntry(id).then((res) => {
        setFormData(res.data);
        return res.data;
      }),
    {
      enabled: Boolean(id),
    }
  );

  // const { mutate } = useMutation(api.updateStoreType, {
  //   onSuccess: (data) => {
  //     if (data.status == 200) {
  //       // api.getAllDropdown("ABC_List").then((response) => {
  //       //   localStorage.setItem(
  //       //     "ABC_List",
  //       //     JSON.stringify(response.datalist.ABC_List)
  //       //   );
  //       // });

  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.data.message,
  //         msgtype: data.data.success,
  //       });

  //       queryClient.getQueriesData("StoreTypeEntry"); // include the page name
  //       props.history.push("/store-type-entry"); //page url
  //       api.dirtyModeDisable();
  //     }else{

  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.message,
  //         msgtype: data.success,
  //       });
  //     }
  //   },
  // });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
    api.dirtyModeEnable();
  };

  const handleReset = () => {
    setFormData({
      CountryCode: "",
      CountryName: "",
      CenterLat: "",
      CenterLong: "",
      ISO3: "",
      NumCode: "",
      ZoomLevel: "",
    });
  };

  const validateForm = (formData) => {
    let validateFields = [
      "CountryCode",
      "CountryName",
      "CenterLat",
      "CenterLong",
      "ZoomLevel",
    ];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  function checkValidateForm() {
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        menukey: menukey,
        lan: lan,
        UserName: UserName,
      };

      return fDtat;
    } else {
      return false;
    }
  }
  // const handleUpdate = async (e) => {
  //   if (validateForm(formData)) {
  //     let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName};
  //     mutate(fDtat);

  //   }
  // };

  return (
    <>
      <CountryEntryFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        checkValidateForm={checkValidateForm}
        // handleUpdate={handleUpdate}
        bStockUpdate={props.permissionType}
        {...props}
      />
    </>
  );
};

export default EditCountryEntry;
