import React, { useEffect, useState } from "react";//, 
import { useParams } from "react-router-dom";
import {makeStyles, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { 
  Grid,
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Card,
  CardContent,
  TextField,
  Typography
  

} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";  
import { useQuery, useMutation,useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import { useTranslation } from "react-i18next";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";
import moment from "moment";



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const SupplierModal =({ handleClose, openOrder, addSupplier, ...props }) => {
  
  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "national-supplier";

const { id } = useParams();
const queryClient = useQueryClient();

const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;

  
const [formData, setFormData] = useState({
    SupName: "",
    Address: "",
    Phone: "",
    Fax: "",
    EMail: "",
  });
  const [errorObject, setErrorObject] = useState({});

/*
  useQuery(
    ["NationalSupplier", id],
    () =>
      api.geNationalSupplier(id).then((res) => {
        setFormData(res.data);
      }),
    {
      enabled: Boolean(id),
    }
  );

  */

  const { isLoading, mutate } = useMutation(api.saveNationalSupplierForCatalog, {
    onSuccess: (data) => {
      console.log("data: ", data);

      if (data.status == 200) {

        const auth_token = sessionStorage.getItem("token")
        ? sessionStorage.getItem("token")
        : null;
        let options = {};
        options = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth_token}`,
          },
          task:'UOM'
        };

        
        api.getLoginFacilityListNotEqual99ANDSuppliers(options,'ALL')
        .then((response) => {

          localStorage.setItem(
            "SupplierListNational",
            JSON.stringify(response.datalist.SupplierListNational)
          );

        });


        api.getLoginFacilityListNotEqual99ANDSuppliers(options,'ALL')
        .then((response) => {

          localStorage.setItem(
            "ManufacturerListNational",
            JSON.stringify(response.datalist.ManufacturerListNational)
          );

        });

        
        setSupplierData(data);
       // setSupplierData('sssssssssssss');
        setbtnDisable(true);
        setTimeout(() => setbtnDisable(false), 500);

        // api.getAllDropdown("ABC_List").then((response) => {
        //   localStorage.setItem(
        //     "ABC_List",
        //     JSON.stringify(response.datalist.ABC_List)
        //   );
        // });

        /*

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        queryClient.getQueriesData("NationalSupplier"); //api page name
        props.history.push("/national-supplier"); //

        */
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });

        //queryClient.getQueriesData("NationalSupplier"); //api page name
        //props.history.push("/national-supplier"); //
      }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    console.log("formData: ", formData);
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };

const validateForm = (formData) => {
    let validateFields = ["SupName"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };


const handleSubmit = async (e) => {
    
  //console.log("from submit ", formData);

    //if()

    if(formData.SupName==""){
    props.openNoticeModal({
      isOpen: true,
      msg: DispensingLanguage[lan][menukey]["Supplier name is required."],
      msgtype: 0,
    });
    return;
    }
    
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        menukey: menukey,
        lan: lan,
        UserName: UserName,
      };
      mutate(fDtat);
    }
    
  };

  const [SupplierData, setSupplierData] = useState([]);

  const [btnDisable, setbtnDisable] = useState(false);
  const SaveSelectedProducts = () => {
      //console.log("dddddddddddddddddd");
      //addOrder('fffffffffffffffff');
      
      setSupplierData('sssssssssssss');
      setbtnDisable(true);
      setTimeout(() => setbtnDisable(false), 500);
    
  }; 


  useEffect(() => { 
    
    addSupplier(SupplierData);

}, [SupplierData]); 
 
  

  return (
    <div>
     
      <BootstrapDialog
        onClose={() => handleClose('SupplierModal')}
        aria-labelledby="customized-dialog-title"
        open={openOrder}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('SupplierModal')}
        >
         {DispensingLanguage[lan][menukey]['National Supplier Form - Add']}
        </BootstrapDialogTitle>


        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container spacing={10}>

               
                  <Grid item xs={4} sm={12}>
                    <Grid container spacing={3}>

                    <Grid item xs={6} sm={6}>
                        <TextField
                         // error={errorObject.SupName}
                          //helperText={errorObject.SupName}
                          required
                          disabled={props.permissionType}
                          id="SupName"
                          name="SupName"
                          label= {DispensingLanguage[lan][menukey]['Supplier_Name'] }
                          //value={formData.SupName}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                    </Grid>

                    <Grid item xs={6} sm={6}>
                        <TextField
                          //error={errorObject.Address}
                          //helperText={errorObject.Address}
                          id="Address"
                          name="Address"
                          disabled={props.permissionType}
                          label= {DispensingLanguage[lan][menukey]['Address'] }
                          //value={formData.Address}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid> 
                      <Grid item xs={6} sm={6}>
                        <TextField
                         // error={errorObject.Phone}
                         // helperText={errorObject.Phone}
                          id="Phone"
                          name="Phone"
                          disabled={props.permissionType}
                          label= {DispensingLanguage[lan][menukey]['Phone'] }
                          //value={formData.Phone}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid> 
                      <Grid item xs={6} sm={6}>
                        <TextField
                         // error={errorObject.Fax}
                         // helperText={errorObject.Fax}
                          id="Fax"
                          name="Fax"
                          disabled={props.permissionType}
                          label= {DispensingLanguage[lan][menukey]['Fax'] }
                          //value={formData.Fax}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={6} sm={6} className="marginBottom20">
                        <TextField
                          //error={errorObject.EMail}
                         // helperText={errorObject.EMail}
                          id="EMail"
                          name="EMail"
                          //disabled={props.permissionType}
                          label= {DispensingLanguage[lan][menukey]['Email'] }
                          //value={formData.EMail}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      

                    </Grid> 
                </Grid>
                
                

            </Grid>
          </CardContent>
        </Card>

       
        <DialogActions>
          <Button 
          className="mr-2 float-left"
          variant="contained"
          color="primary" 
         // onClick={SaveSelectedProducts}
          onClick={() => handleSubmit()}
          >
           {DispensingLanguage[lan][menukey]['Save']} 
          </Button>
          <Button autoFocus 
          className="float-left"
          variant="contained"
          
          onClick={() => handleClose('SupplierModal')}>
            {DispensingLanguage[lan][menukey]['Cancel']} 
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default SupplierModal;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});