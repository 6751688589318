import React, { useState, useEffect } from "react"; //, useEffect
import { Typography } from "@material-ui/core";

import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import DispenserFormData from "./MCWCIssueVoucherAssetFormData.js";

import Constants from "../../../services/Constants";
import moment from "moment";

const AddMCWCIssueVoucherAsset = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "mcwcassetissuefromstore";
  const FacilityId = localStorage.getItem("FacilityId");
  const CurrentFacilitySetting = JSON.parse(
    localStorage.getItem("CurrentFacilitySetting")
  );

  const [selectedDate, setSelectedDate] = useState(
    //new Date()
    moment().format("YYYY-MM-DD")
  );
  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState("");
  const [formData, setFormData] = useState({
    TransactionNo: generatedInvoiceNo,
    TransactionDate: selectedDate,
    TransactionTypeId: 2,
    FacilityId: FacilityId,
    transferFacilityCode: FacilityId,
    PrepBy: CurrentFacilitySetting[0].InvPrepBy,
    AppBy1: CurrentFacilitySetting[0].InvAppBy,
    AppDate1: selectedDate,
    IssuedBy: CurrentFacilitySetting[0].InvSupBy,
    IssuedDate: selectedDate,
    CarriersName: "",
    Remarks: "",
    BCancel: 0,
    bUpdated: 0,
    PrepByName: "",
    AppByName: "",
    IssuedByName: "",
    DeptId:"",
    SectionId:"",
  });

  const [ManyTableData, setManyTableData] = useState([]);
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);

  const handleRDateChange = (date, field) => {
    let newDate;
    if (date == null) {
      newDate = date;
    } else {
      newDate = moment(date).format("YYYY-MM-DD");
    }
    setFormData({ ...formData, [field]: newDate });
    setErrorObject({ ...errorObject, [field]: null });
    api.dirtyModeEnable();
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const [errorObject, setErrorObject] = useState({});

  const { id } = useParams();
  const queryClient = useQueryClient();

  let params = {
    menukey: "mcwcassetissuefromstore",
    FacilityId: FacilityId,
    TransactionTypeId: 2,
    TransactionDate: "",
    TableName: "indent",
  };
  const { data: invoiceNo, refetch } = useQuery([params], api.getInvoiceNo, {
    onSuccess: (data) => {
      setGeneratedInvoiceNo(data);
      let fdata = { ...formData };
      fdata["TransactionNo"] = data;
      setFormData(fdata);
    },
  });

  
  const handleAdd = (NonOrderProductData) => {
    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("formData: ", e.target);

    let data = { ...formData };

    data[name] = value;
    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };

  //====== Start Choosen Dropdown===========
  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value;
    setFormData(data);
    setErrorObject({ ...errorObject, [name]: "" });
    api.dirtyModeEnable();
  };
  //====== End Choosen Dropdown===========

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };


  const handleReset = () => {
    setFormData({
      TransactionNo: generatedInvoiceNo,
      TransactionDate: selectedDate,
      TransactionTypeId: 2,
      FacilityId: FacilityId,
      transferFacilityCode: FacilityId,
      PrepBy: CurrentFacilitySetting[0].InvPrepBy,
      AppBy1: CurrentFacilitySetting[0].InvAppBy,
      AppDate1: selectedDate,
      IssuedBy: CurrentFacilitySetting[0].InvSupBy,
      IssuedDate: selectedDate,
      CarriersName: "",
      Remarks: "",
      BCancel: 0,
      bUpdated: 0,
      PrepByName: "",
      AppByName: "",
      IssuedByName: "",
      DeptId:"",
      SectionId:"",
      ManyJsonSave: [],
    });
    setManyTableData([]);
  };

  const validateForm = (formData) => {
    let validateFields = [
      "TransactionDate",
      "TransactionNo",
      "PrepBy",
      "AppBy1",
      "AppDate1",
      "IssuedBy",
      "IssuedDate",
      "DeptId",
      "SectionId",
    ];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  

  const deleteReceiveInv = (data) => {
    let newarray = ManyTableData.filter((element) => element !== data);
    setManyTableData(newarray);
  };


  function checkValidateForm(rows, callfrom) {
    if (callfrom == "edit") {
      setManyTableData(rows);
    }

    // return validateForm(formData);
    if (validateForm(formData)) {
      let fDtat = { ...formData, ManyJsonSave: rows, deletedDataSet: [] };
      //mutateUpdate(fDtat);
      return fDtat;
    } else {
      return false;
    }
  }

  useEffect(() => {}, []);

  return (
    <>
      <DispenserFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleCheck={handleCheck}
        handleReset={handleReset}
        // invoiceCancel={invoiceCancel}
        // handleSubmit={handleSubmit}
        ManyTableData={updatedDetailGridData}
        handleRDateChange={handleRDateChange}
        selectedDate={selectedDate}
        deleteReceiveInv={deleteReceiveInv}
        // setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
        bStockUpdate={props.permissionType}
        handleAdd={handleAdd}
        generatedInvoiceNo={generatedInvoiceNo}
        checkValidateForm={checkValidateForm}
        {...props}
      />
    </>
  );
};

export default AddMCWCIssueVoucherAsset;
