import React from "react";

// material components
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    width: "20%",
    margin: "0 1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDiv: {
    display: "flex",
  },
});

const Admin = (props) => {
  const classes = useStyles();

  const goto = (e) => {
    props.history.push(e);
  };

  return (
    <div
      style={{
        marginTop: "60px",
        padding: "10px",
      }}
    >
      <div className="uidash pad-2">
        <h3>Admin</h3>
        <div className={classes.cardDiv}>
          <Card className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Users
              </Typography>

              <Typography className={classes.pos} color="textSecondary">
                Total Users : 265
              </Typography>
              {/* <Typography className={classes.pos} color="textSecondary">
                  Total Roles : 23
                </Typography> */}
              {/* <Typography variant="body2" component="p">
                  Edit User Roles
                </Typography> */}
            </CardContent>
            <CardActions>
              <Button
                color="primary"
                onClick={() => {
                  goto("/users");
                }}
                size="medium"
              >
                Edit Users
              </Button>
            </CardActions>
          </Card>
          <Card className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Products
              </Typography>

              <Typography className={classes.pos} color="textSecondary">
                Total Products : 1572
              </Typography>
            
            </CardContent>
            <CardActions>
              <Button
                color="primary"
                onClick={() => {
                  goto("/products");
                }}
                size="medium"
              >
                Product Details
              </Button>
            </CardActions>
          </Card>
          <Card className={classes.root}>
            <CardContent>
              <Typography
                className={classes.title}
                color="textSecondary"
                gutterBottom
              >
                Roles
              </Typography>

              <Typography className={classes.pos} color="textSecondary">
                Total Roles : 23
              </Typography>
              
            </CardContent>
            <CardActions>
              <Button
                color="primary"
                onClick={() => {
                  goto("/admin/roles");
                }}
                size="medium"
              >
                Edit Roles
              </Button>
            </CardActions>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Admin;
