import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  FormControlLabel,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  MenuItem,
  Card,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import CloseIcon from "@material-ui/icons/Close";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import moment from "moment";

//get DispensingLanguage
const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "indent";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


const PickIssueInvoiceIndentModal = ({ handleClose, openIssueInvoice, addIssueInvoice, OrderingFrom, TransactionId, gStoreId, ...props }) => {
  const OrderTo = JSON.parse(localStorage.getItem("OrderTo"));
  const classes = useStyles();
  const [toggleButtonValue, setToggleButton] = React.useState("1");
  const [currFacilityId, setCurrFacilityId] = useState("");
  //const [currSelectedInvoiceType, setcurrSelectedInvoiceType] = useState(1);
  const [currIndentNo, setCurrIndentNo] = useState("");


  //====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "Warehouse": {"id": "0","name": ""}
 
});

const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;
  setChosenValues(chosenValuesData); 

  setCurrFacilityId(value);


};

//====== End Choosen Dropdown===========


  const handleFacilityChange = (event) => {
    setCurrFacilityId(event.target.value);
  };
  const handleIndentNoChange = (event) => {
    setCurrIndentNo(event.target.value);
  };

/*   const selectedInvoiceType = (val) => {
    setcurrSelectedInvoiceType(val);
  }; */


  const handleChangeToggle = (event, tmpToggleButtonValue) => {
    if (tmpToggleButtonValue) {
      //console.log(tmpToggleButtonValue);
      setToggleButton(tmpToggleButtonValue);
    }
  };

  let tblOrderRef = null;

  const [dataItems, setDataItems] = useState([]);
  const FacilityId = localStorage.getItem("FacilityId");
  const StoreId = localStorage.getItem("StoreId");


  const { mutate } = useMutation(api.insertUpdateIssueInvoicePick, {
    onSuccess: (data) => {

      if(data.success==0) {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        
      }else{
        addIssueInvoice(data);
        setCurrFacilityId("");
        setCurrIndentNo("");
      }

    }
  })
  const SaveSelectedOrder = () => {

    if(currFacilityId =="" && currIndentNo =="") {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Warehouse and inv no can not be blank"],
        msgtype: 0,
      });
    
    }else if(currFacilityId=="") {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Warehouse can not be blank"],
        msgtype: 0,
      });
    
    }else if(currIndentNo=="") {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Inv no can not be blank"],
        msgtype: 0,
      });
    
    }


    if ((currFacilityId != "") && (currIndentNo != ""))
      mutate({
        FacilityId: FacilityId,
        TransferFacilityCode: currFacilityId,
        InvoiceNo: currIndentNo,
        TransactionTypeId: 2,
        currSelectedInvoiceType: toggleButtonValue,
        language: lan
      });

  };


  useEffect(() => {
    if (tblOrderRef == null) return;


  }, []);

  return (
    <div>

      <BootstrapDialog
        onClose={() => handleClose('PickIssueInvoiceIndentModal')}
        aria-labelledby="customized-dialog-title"
        open={openIssueInvoice}
        fullWidth={true}
        maxWidth="xs"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('PickIssueInvoiceIndentModal')}
        >

          {DispensingLanguage[lan][menukey]['Select Invoice']}

        </BootstrapDialogTitle>
        <DialogContent dividers>
          {/* <ReactTabulator ref={(r) => (tblOrderRef = r)} 
         selectable={1} 
         columns={PickIssueInvoiceIndentModalColumns}
         data={dataItems}
         height="300px" 
        /> */}

          <Grid container spacing={3}>

            {/* Start warehouse dropdown list */}
            <Grid item xs={2} sm={2}></Grid>
            <Grid item xs={8} sm={8}>
              <FormControl className={classes.fullWidth}>
                {/* <InputLabel id="demo-simple-select-helper-label-receive-from">
                  {DispensingLanguage[lan][menukey]["Warehouse"] + "*"}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label-receive-from"
                  id="Warehouse"
                  name="Warehouse"
                  visible={false}
                  value={currFacilityId}
                  fullWidth
                  onChange={handleFacilityChange}
                >
                  {OrderTo.map((item, index) => {
                    return (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    );
                  })}
                </Select> */}


                      <Autocomplete
                        autoHighlight
                        disableClearable
                        id="Warehouse"
                        options={OrderTo}
                        onChange={(event, valueobj) => handleChangeChoosen('Warehouse', valueobj, valueobj?valueobj.id:"")}
                        getOptionLabel={(option) => option.name}
                        value={chosenValues['Warehouse']}
                        renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          //value={props.formData.Warehouse}
                          label={DispensingLanguage[lan][menukey]["Warehouse"]+ "*"}
                          variant="standard"
                          //error={props.errorObject.Warehouse}
                          //helperText={props.errorObject.Warehouse}
                          id="Warehouse"
                          name="Warehouse"
                          fullWidth
                        />
                        )}
                      />

              </FormControl>

            </Grid>
            <Grid item xs={2} sm={2}></Grid>
            {/* End warehouse dropdown list */}

            <Grid item xs={2} sm={2}></Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                //disabled={props.bStockUpdate}
                /*  error={props.errorObject.SupplierInvNo}
                helperText={props.errorObject.SupplierInvNo} */
                id="IndentNo"
                name="IndentNo"
                label={DispensingLanguage[lan][menukey]["Inv No"] + "*"}
                value={currIndentNo}
                fullWidth
                autoComplete="family-name"
                onChange={handleIndentNoChange}
              />
            </Grid>
            <Grid item xs={2} sm={2}></Grid>


            <Grid item xs={2} sm={2}></Grid>
            <Grid item xs={8} sm={8}>

              <div className="tp-upload-box marginTopBottom">
                <div className="tp-header">
                  <Grid item xs={12} className="mt-2 text-center">

                    <ToggleButtonGroup
                      className="sw_ToggleButtonGroup"
                      color="primary"
                      value={toggleButtonValue}
                      exclusive
                      onChange={handleChangeToggle}
                      
                    >
                      <ToggleButton 
                        value="1"
                      >
                      {DispensingLanguage[lan][menukey]['Push']}
                      </ToggleButton>
                      <ToggleButton
                        value="2"
                      >
                      {DispensingLanguage[lan][menukey]['Indent']}
                      </ToggleButton>

                    </ToggleButtonGroup>

                    {/* <Button
                      className="mr-2"
                      variant="contained"
                      color={currSelectedInvoiceType == "1" ? "primary" : "info"}
                      onClick={() => selectedInvoiceType(1)}
                    >
                      {DispensingLanguage[lan][menukey]["Push"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      color={currSelectedInvoiceType == "2" ? "primary" : "info"}
                      onClick={() => selectedInvoiceType(2)}
                    >
                      {DispensingLanguage[lan][menukey]["Indent"]}
                    </Button> */}
                  </Grid>

                </div>
              </div>
            </Grid>

            <Grid item xs={2} sm={2}></Grid>

          </Grid>

        </DialogContent>
        <DialogActions>
          <Grid item xs={12} className="mt-2 text-center">
            <Button
              className="mr-2"
              variant="contained"
              color="primary"
              onClick={SaveSelectedOrder}>
              {DispensingLanguage[lan][menukey]['Download']}
            </Button>
            <Button autoFocus
              className="mr-2"
              variant="contained"

              onClick={() => handleClose('PickIssueInvoiceIndentModal')}>
              {DispensingLanguage[lan][menukey]['Close']}
            </Button>
          </Grid>
        </DialogActions>
      </BootstrapDialog>

    </div>
  );
};

export default PickIssueInvoiceIndentModal;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
