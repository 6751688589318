import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormLabel,
} from "@material-ui/core";
import { ColorPicker, createColor } from 'material-ui-color';
import SwipeableViews from "react-swipeable-views";
import { useTranslation } from "react-i18next";
//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "asset-procuring-entry";


const ProcuringFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  handleChangeColorAdd,
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();

  const handleChangeColor = (name) => {
  
   
    if(typeof(name)=="string"){
      formData["colorval"]=name;
      handleChangeColorAdd(name);
    }else{
      formData["colorval"]=name.css.backgroundColor;
      handleChangeColorAdd(name.css.backgroundColor);
    }
      
  };
  
 
  
  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
      <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Procuring Entry - Add/Edit"]}
              </div>
            </div>
            <div className="float-right sw_btn_control">
              {addProductForm ? (
                <Grid item xs={12} className="text-center">
                  <div className="float-right sw_btn_control">
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => handleReset()}>
                      {DispensingLanguage[lan][menukey]["Reset"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => handleSubmit()}>
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() =>
                        props.history.push("/asset-procuring")
                      }>
                      {DispensingLanguage[lan][menukey]["Cancel"]}
                    </Button>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} className="mt-2 text-center">
                  <Button
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={() => handleUpdate()}>
                    {DispensingLanguage[lan][menukey]["update"]}
                  </Button>
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => props.history.push("/asset-procuring")}>
                    {DispensingLanguage[lan][menukey]["Cancel"]}
                  </Button>
                </Grid>
              )}
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardContent>
                <Grid container spacing={1}>
              
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={8} sm={8}>
                        <TextField
                          error={errorObject.ProcuringName}
                          helperText={errorObject.ProcuringName}
                          required
                          id="ProcuringName"
                          name="ProcuringName"
                          label={DispensingLanguage[lan][menukey]['Procuring']}
                          inputProps={{ maxLength: 50 }}
                          value={formData.ProcuringName}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                   
                       <Grid item xs={4}>
                        
                       <FormLabel component="legend">
                          {DispensingLanguage[lan][menukey]["Color Code"]}
                        </FormLabel> 

                        <ColorPicker
                          error={errorObject.colorval}
                          helperText={errorObject.colorval}
                          required
                          name='colorval'
                          defaultValue='#00aabb'
                          value={formData.colorval}
                          onChange={handleChangeColor}
                          
                        />
                      </Grid> 
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ProcuringFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
