import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import EntryofUPazilaFormData from "./EntryofUPazilaFormData";
import moment from "moment";
const AddEntryofUPazila = (props) => {
 // console.log(props.pieceOfState);

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "entry-edit-of-upazila";

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;


  const [selectedDate, setSelectedDate] = useState(
    moment().format("YYYY-MM-DD")
  );

  const [selectedDefaultUsingSoftware, setDefaultUsingSoftware] = useState('Web');

//console.log(selectedDate);

  const [formData, setFormData] = useState({
    upazila_name:"",
    whrec_id:"",
    DistrictId:"",
    EffectiveDate:null,
    contact_person:"",
    no_sdp:"",
    LandPhoneNo:"",
    extra:"",
    up_address:"",
    UsingSoftware:selectedDefaultUsingSoftware,
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const [open, setOpen] = React.useState(false);
  const [Massage, setMassage] = React.useState(false);





  // const { isLoading, mutate } = useMutation(api.saveUpazilaEntry, {
  //   onSuccess: (data) => {
  //    // console.log("data: ", data);

  //     if (data.status == 200) {
  //       //console.log("data: ", data.data);
  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.data.message,
  //         msgtype: data.data.success,
  //       });
  //       queryClient.getQueriesData("EntryofUpazila"); //api page name
  //       props.history.push("/entry-edit-of-upazila"); //
  //     } else {
  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.data.message,
  //         msgtype: data.data.success,
  //       });
  //       queryClient.getQueriesData("EntryofUpazila"); //api page name
  //       props.history.push("/entry-edit-of-upazila"); //
  //     }
  //   },
  // });

  const handleRDateChange = (date, field) => {
  
    setFormData({ ...formData, [field]: date });
    setErrorObject({ ...errorObject, [field]: null });
    api.dirtyModeEnable();
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    api.dirtyModeEnable();
  }; 

  const handleChange = (e) => {
    const { name, value } = e.target;
    //console.log('DistrictIdtest',e.target);

    let data = { ...formData };
    console.log("formData: ", formData);
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };

  //====== Start Choosen Dropdown===========
  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value;   
    setFormData(data); 
    setErrorObject({ ...errorObject, [name]: "" });
    api.dirtyModeEnable();
  }
  //====== End Choosen Dropdown===========

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
    api.dirtyModeEnable();
  };

  const handleReset = () => {
    setFormData({
      upazila_name:"",
      whrec_id:"",
      DistrictId:"",
      EffectiveDate:null,
      contact_person:"",
      no_sdp:"",
      LandPhoneNo:"",
      extra:"",
      up_address:"",
      UsingSoftware:selectedDefaultUsingSoftware,
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["upazila_name","whrec_id","DistrictId"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  function checkValidateForm(){

     
    if (validateForm(formData)) {
    let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName,}; 
   
    return fDtat;
    }else{
    return false;
    }

};


  // const handleSubmit = async (e) => {
  //   console.log("from submit ", formData);
  //   if (validateForm(formData)) {
  //     let fDtat = {
  //       ...formData,
  //       menukey: menukey,
  //       lan: lan,
  //       UserName: UserName,
  //     };
  //     mutate(fDtat);
  //   }
  // };

  return (
    <>
      <EntryofUPazilaFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleCheck={handleCheck}
        handleReset={handleReset}
        checkValidateForm ={checkValidateForm}
        // handleSubmit={handleSubmit}
        handleRDateChange={handleRDateChange}  
        selectedDate={selectedDate} 
        bStockUpdate={props.permissionType}
        open={open}
        {...props}
      />
    </>
  );
};

export default AddEntryofUPazila;
