import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import jsPDF from "jspdf";

//Services
import * as Service from "../../../services/Service.js";
import Constants from "../../../services/Constants.js";
import swal from "sweetalert";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
  Typography,
  TextField,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import ExportButtons from "../../../components/ExportButtons.js";

import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";

import * as api from "../../../actions/api.js";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import {
  DeleteOutline,
  ViewList,
  preview,
  Edit,
  TurnedIn,
} from "@material-ui/icons";
import { cellFocusEditor } from "../../../services/Common.js";
import moment from "moment";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const ReceiveSummaryReportGlobal = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const menukey = "receive-summary-report-global";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;

  const classes = useStyles();
  let tblRef = null;
  const tableRef = useRef();
  const { useState } = React;

  const { t, i18n } = useTranslation();
  const [firstLoad, setFirstLoad] = useState(true);

  const [ManyJsonSave, setInputChange] = useState({});
  const [radioValue, setRadioValue] = useState(0);
  const [productGroupData, selectProductGroupData] = useState();
  const [dataItems, setDataItems] = useState([]);
  const [currGroupCode, setcurrGroupCode] = useState(0);

  const [manyTableData, setManyTableData] = useState([]);
  /*   const GroupNameList = JSON.parse(localStorage.getItem("GroupNameList"));
  GroupNameList.unshift({
    id: 0,
    name: t(DispensingLanguage[lan][menukey]["All"]),
  }); */

  const FacilityList = JSON.parse(localStorage.getItem("FacilityListGlobal"));

  const [currFacilityCode, setcurrFacilityCode] = useState(
    FacilityList.length > 0 ? FacilityList[0].id : ""
  );
  const [currFacilityName, setcurrFacilityName] = useState(
    FacilityList.length > 0 ? FacilityList[0].name : ""
  );

  let TempWarehouseList = useState([]);
  const [WarehouseList, setWarehouseListLocal] = useState(TempWarehouseList);
  const [currWarehouseCode, setcurrWarehouseCode] = useState(0);

  const [currWarehouseName, setcurrWarehouseName] = useState(
    FacilityList.length > 0
      ? FacilityList[0].name
      : t(DispensingLanguage[lan][menukey]["All"])
  );

  //====== Start Choosen Dropdown===========
  /* const [chosenValues, setChosenValues] = useState({
    GroupCode: { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) },
  }); */
  const [chosenValuesItem, setChosenValuesWarehouse] = useState({
    SupplierCode: { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) },
  });

  const [chosenValuesFacility, setChosenValuesFacility] = useState({});

  const handleChangeChoosenFacility = (name, valueobj, value) => {
    let chosenValuesDataFacility = { ...chosenValuesFacility };
    chosenValuesDataFacility[name] = valueobj;
    setChosenValuesFacility(chosenValuesDataFacility);

    setFirstLoad(true);
    setLoading(false);
    setcurrFacilityCode(value);
    setcurrFacilityName(valueobj.name);
  };

  const handleChangeChoosenWarehouse = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValuesItem };
    chosenValuesData[name] = valueobj;
    setChosenValuesWarehouse(chosenValuesData);
    //console.log("valueobj --------",chosenValuesData);

    setFirstLoad(true);
    setLoading(false);
    setcurrWarehouseCode(value);
    setcurrWarehouseName(valueobj.name);
  };

  //====== End Choosen Dropdown===========

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );
  const UseFor = localStorage.getItem("UseFor");
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const handleStartDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedEndDate(date);
  };

  const handleChange = (event) => {
    setFirstLoad(true);
    setLoading(false);
    setcurrGroupCode(event.target.value);
  };

  const handleChangeItemCode = (event) => {
    setFirstLoad(true);
    setLoading(false);
    setcurrWarehouseCode(event.target.value);
  };

  const cSupplierList = () => {
    let ItemListParam = {
      action: "FacilityListForWarehouseBySupplier",
      FacilityId: currFacilityCode,
      menukey: "",
    };

    Service.default
      .postApi("source/combo_generic.php", ItemListParam)
      .then((res) => {
        let All_Item_label = {
          id: "0",
          name: t(DispensingLanguage[lan][menukey]["All"]),
        };
        const Item_List = [All_Item_label].concat(res.datalist);

        setChosenValuesWarehouse({
          SupplierCode: {
            id: "0",
            name: t(DispensingLanguage[lan][menukey]["All"]),
          },
        });

        setWarehouseListLocal(Item_List);
      })
      .catch((err) => {});
  };

  const queryClient = useQueryClient();

  let params = {
    menukey: menukey,
    FacilityId: currFacilityCode,
    action: "getMCWCDataList",
    StartDate: moment(selectedStartDate).format("YYYY-MM-DD"),
    EndDate: moment(selectedEndDate).format("YYYY-MM-DD"),
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    RadioValue: radioValue,
    FacilityCode: currFacilityCode,
    SupplierCode: currWarehouseCode,
    FType: chosenValuesItem.SupplierCode["FType"],
  };

  const [isLoading, setLoading] = useState(false);

  const {
    error,
    isError,
    data,
    refetch: stockData,
  } = useQuery([params], api.getMCWCReceiveSummaryReport, {
    onSuccess: (data) => {
      if (firstLoad) {
        /* localStorage.setItem(
            "StockData",
            JSON.stringify(data)
          );
           */
        setDataItems(data);
        setLoading(false);
        setFirstLoad(false);
      }
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,
  });

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let formatedstartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let formatedendDate = moment(selectedEndDate).format("YYYY-MM-DD");
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=ReceiveSummaryReportGlobal" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&StartDate=" +
        formatedstartDate +
        "&EndDate=" +
        formatedendDate +
        /* "&FacilityId=" +
        currWarehouseCode + */
        "&FacilityId=" +
        currFacilityCode +
        "&GroupCode=" +
        currGroupCode +
        "&SupplierCode=" +
        currWarehouseCode +
        "&RadioValue=" +
        radioValue +
        "&UseFor=" +
        UseFor +
        "&FacilityName=" +
        currFacilityName +
        "&FType=" +
        chosenValuesItem.SupplierCode["FType"] +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const columns = [
    {
      title: "Id",
      field: "id",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: "80",
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Receive Invoice Date"],
      field: "StockDate",
      headerFilter: false,
      width: 200,
    },
    {
      title: DispensingLanguage[lan][menukey]["Receive Invoice No"],
      field: "InvoiceNo",
      headerFilter: true,
      width: 200,
    },
    {
      title: DispensingLanguage[lan][menukey]["Supplier"],
      field: "supplier",
      headerFilter: true,
      minWidth: 200,
      //width: "205",
    },
    {
      title: DispensingLanguage[lan][menukey]["Supplier Reference"],
      field: "SupRef",
      headerFilter: true,
      width: 200,
    },
    {
      title: DispensingLanguage[lan][menukey]["Supplier Date"],
      field: "SupDate",
      headerFilter: false,
      width: 200,
    },
  ];

  React.useEffect(() => {
    setcurrWarehouseCode(0);
    cSupplierList();
  }, [currFacilityCode]);

  /* const changeRadio = (value) => {
  stockData();
  setFirstLoad(true);
  setLoading(true);
  setRadioValue(value);
};
 */
  React.useEffect(() => {
    if (tblRef == null) return;
    setLoading(true);
    stockData();
  }, [
    selectedStartDate,
    selectedEndDate,
    currGroupCode,
    currWarehouseCode,
    radioValue,
  ]);

  return (
    <>
      <div className={classes.productPageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {
                  DispensingLanguage[lan][menukey][
                    "Receive Summary Report"
                  ]
                }
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
             

              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      //disableToolbar
                      autoOk={true}
                      // disabled={true}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["Start Date"]}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["End Date"]}
                      autoOk={true}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              
              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    disableClearable
                    id="FacilityCode"
                    options={FacilityList}
                    onChange={(event, valueobj) =>
                      handleChangeChoosenFacility(
                        "FacilityCode",
                        valueobj,
                        valueobj ? valueobj.id : ""
                      )
                    }
                    getOptionLabel={(option) => option.name}
                    defaultValue={
                      FacilityList[
                        FacilityList.findIndex(
                          (facilitylist) => facilitylist.id == currFacilityCode
                        )
                      ]
                    }
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">
                        {option.name}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Facility"]}
                        variant="standard"
                        id="FacilityCode"
                        name="FacilityCode"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4} sm={4}>
                <FormControl className={classes.fullWidth}>
                  <Autocomplete
                    autoHighlight
                    disableClearable
                    id="SupplierCode"
                    options={WarehouseList}
                    onChange={(event, valueobj) =>
                      handleChangeChoosenWarehouse(
                        "SupplierCode",
                        valueobj,
                        valueobj ? valueobj.id : ""
                      )
                    }
                    getOptionLabel={(option) => option.name}
                    value={chosenValuesItem["SupplierCode"]}
                    renderOption={(option) => (
                      <Typography className="sw_fontSize">
                        {option.name}
                      </Typography>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Supplier"]}
                        variant="standard"
                        id="SupplierCode"
                        name="SupplierCode"
                        fullWidth
                      />
                    )}
                  />
                </FormControl>
              </Grid>


              <Grid item xs={1} sm={1}>
                <div className="float-right sw_btn_control">
                  <ExportButtons
                    PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}
                    {...props}
                  ></ExportButtons>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}

          <div className="uniqueName">
            <ReactTabulator
              ref={(r) => (tblRef = r)}
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="400px"
              /*  options={{
                groupBy: ["GroupName", "ItemName"],
                columnCalcs: "both",
              }} */
              // rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ReceiveSummaryReportGlobal;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
