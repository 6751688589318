import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import * as api from "../../../actions/api"; 
import ProductSpecificationFormData from "./ProductSpecificationFormData"




const EditProductSpecification = (props) => {

  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "product-specification-report";
const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;  
const UserName = UserInfo==0?'': UserInfo[0].id;


  const [formData, setFormData] = useState({
    ItemCode: "",
    PackageNo: "",
    Specification:"",
    ProductImage:"",
    SpecificationHidden:0,
    ProductImageHidden:0,
    YearId:"",
    ItemName:"",
    procQty:"",
    EstUnitPrice:"",
    PurchasePrice:"",
    CurrencyId:"",
    ExchangeRate:"",
    UnitName:"",
    SupCode:"",
    ManufacturerId:"",
    EstimatedCurrencyId:"",
    LotNo:""
  });
  
  
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  /*

   const { data } = useQuery(
    ["productspecification", id],
    () =>
      api.getProductSpecification(id).then((res) => {
        res.data.SpecificationHidden=0;
        res.data.ProductImageHidden=0;
        setFormData(res.data);
        return res.data;
      }),
    {
    
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
    enabled: Boolean(id),

    }
  ); 

  */

  const { data,refetch } = useQuery(
    ["productspecification", id],
    () =>
      api.getProductSpecification({id,UserName}).then((res) => {
        //console.log("B Locked",res.data.bCatalogLocked);
        res.data.SpecificationHidden=0;
        res.data.ProductImageHidden=0;
        let StatusName=res.data.StatusName;//.split('|');  
        //console.log(StatusName)
        res.data.StatusName=StatusName; 
       // alert(res.data.StatusId)
       // let StatusId=res.data.StatusId.split('|');   
        res.data.StatusId=res.data.StatusId;//StatusId; 
        setFormData(res.data);
       // setbCatalogLocked(res.data.bCatalogLocked);
        return res.data;
      }),
    {
    
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
    enabled: Boolean(id),

    }
  ); 

  const { mutate } = useMutation(api.updateProductSpecification, {
    onSuccess: (data) => {
      if (data.status == 200) {

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        }); 
        //swal(data.data.message, "", "success");
        queryClient.getQueriesData("productspecification");//api page name
        props.history.push("/product-specification");//
        api.dirtyModeDisable();
  
        }else{   
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;

    if((name=='procQty')||(name=='PurchasePrice')||(name=='ExchangeRate')){
      let procQty=data['procQty']==null?0:data['procQty'];
      let PurchasePrice=data['PurchasePrice']==null?0:data['PurchasePrice'];
      let ExchangeRate=data['ExchangeRate']==null?0:data['ExchangeRate'];
      data['TotalBDT'] =(procQty*PurchasePrice*ExchangeRate).toFixed(2);
    }

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();

  };

  //====== Start Choosen Dropdown===========
  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value; 
    if(name=='CurrencyId') {
      if(value==1){
        data['ExchangeRate']=1;
        let procQty=data['procQty']==null?0:data['procQty'];
        let PurchasePrice=data['PurchasePrice']==null?0:data['PurchasePrice'];
        let ExchangeRate=data['ExchangeRate']==null?0:data['ExchangeRate'];
        data['TotalBDT'] =(procQty*PurchasePrice*ExchangeRate).toFixed(2);

      }else{

        data['ExchangeRate']='';
        
        data['TotalBDT'] =0;

      }
     
    }   
    setFormData(data); 
    setErrorObject({ ...errorObject, [name]: "" });
    api.dirtyModeEnable();
  }
  //====== End Choosen Dropdown===========

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
    PackageNo: "",
    Specification:"",
    ProductImage:"",
    YearId:""
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["YearId","PackageNo","UnitName"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =  DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleUpdate = async (e) => {

    // let fdata = { ...formData }; 
    // fdata["ItemCode"] = showgroup; 

    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat);
       
    }
  };

  return (
    <>
      <ProductSpecificationFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleUpdate={handleUpdate}
        bStockUpdate={props.permissionType}
        {...props}
      />

    </>
  );
};

export default EditProductSpecification;
