import React, { useState } from "react";

import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import * as api from "../../../actions/api";
import UiLanguageFormData from "./UiLanguageFormData.js";

const AddUiLanguage = (props) => {
  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "ui-language";
const UserInfo = sessionStorage.getItem("User_info")? JSON.parse(sessionStorage.getItem("User_info")): 0;  
const UserName = UserInfo==0?'': UserInfo[0].id;

  const [formData, setFormData] = useState({
    MenuKey: "",
    EnglishTxt: "",
    //FranceTxt: "",
    LangKey: ""
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  useQuery(
    ["uiLanguage", id],
    () =>
      api.getUiLanguageData(id).then((res) => {
        setFormData(res.data);
      }),
    {
      enabled: Boolean(id),
    }
  );

  // const { mutate } = useMutation(api.saveUiLanguage, {
  //   onSuccess: (data) => {
  //     if (data.status == 200) {
  //       const auth_token = sessionStorage.getItem("token")
  //               ? sessionStorage.getItem("token")
  //               : null;

  //             //Dispensing Language
  //             let options = {};
  //             options = {
  //               headers: {
  //                 "Content-Type": "application/json",
  //                 Authorization: `Bearer ${auth_token}`,
  //               },
  //             };

  //       api.getDispensingLanguage(options).then((response) => {
  //         //console.log("RRRR",response.datalist)
  //         localStorage.setItem(
  //           "DispensingLanguage",
  //           JSON.stringify(response.datalist)
  //         );
  //       })

  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.message,
  //         msgtype: data.success,
  //       });

  //       queryClient.getQueriesData("uiLanguage");
  //       props.history.push("/ui-language");
  //   }else{
  //     props.openNoticeModal({
  //       isOpen: true,
  //       msg: data.message,
  //       msgtype: data.success,
  //     });

  //   }
  //   },
  // });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    console.log("formData: ", formData);
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  };

      //====== Start Choosen Dropdown===========
      const handleChangeChoosenProps = (name, value) => {
        let data = { ...formData };
        data[name] = value;   
        setFormData(data); 
        setErrorObject({ ...errorObject, [name]: "" });
      }
      //====== End Choosen Dropdown===========

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      MenuKey: "",
      EnglishTxt: "",
      //FranceTxt: "",
      LangKey: ""
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["MenuKey","LangKey","EnglishTxt"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };
  
  function checkValidateForm(){

     
    if (validateForm(formData)) {
    let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName,}; 
   
    return fDtat;
    }else{
    return false;
    }

};

  // const handleSubmit = async (e) => {
  //   console.log("from submit ", formData);
  //   if (validateForm(formData)) {
  //     mutate(formData);
  //     swal("Success!", "", "success");
  //   }
  // };

  // const handleSubmit = async (e) => {
  //   console.log("from submit ", formData);
  //   if (validateForm(formData)) {
  //     let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
  //     mutate(fDtat);
  //    // mutate(formData);
     
  //   }
  // };

  return (
    <>
      <UiLanguageFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleCheck={handleCheck}
        handleReset={handleReset}
        checkValidateForm={checkValidateForm}
        // handleSubmit={handleSubmit}
        bStockUpdate={props.permissionType}
        {...props}
      />
    </>
  );
};

export default AddUiLanguage;
