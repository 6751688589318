import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Typography, TextField, Grid, FormControl, InputLabel, Select,  MenuItem, Card, CardContent} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit, TabletTwoTone } from "@material-ui/icons";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";



const UiLanguage = (props) => {

  const permissionType = props.permissionType;
//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "ui-language";
const UserInfo = sessionStorage.getItem("User_info")? JSON.parse(sessionStorage.getItem("User_info")): 0;  
const UserName = UserInfo==0?'': UserInfo[0].id;
const { t, i18n } = useTranslation();

const MenuList = JSON.parse(localStorage.getItem("MenuList"));
MenuList.unshift({'id':0,'name':t(DispensingLanguage[lan][menukey]["All"])});

  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
 const [dataItems, setDataItems] = useState([]);


  const queryClient = useQueryClient();

  const [MenuKeyValue, setMenuKeyValue] = useState(0);
  const [isLoadingUi, setLoading] = useState(true);

  const [all, setAll] = React.useState('0');
  
//====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "MenuKey": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
 
});

const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;
  setChosenValues(chosenValuesData); 

    //setFirstLoad(true);
    setLoading(false);
    setMenuKeyValue(value);


};

//====== End Choosen Dropdown===========



const handleChange = event => {
  setAll(event.target.value);
  setLoading(true);
  //console.log(e.target.value);
  setMenuKeyValue(event.target.value);

};



  let params = {
    menukey: menukey,
    action: "getDataList",
    MenuKeyValue: MenuKeyValue,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    
  };

  const { isLoading, error, isError, data, refetch } = useQuery(
    [params],
    api.getUiLanguages,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setLoading(false);
      },
    }
  );

  const { mutate:LangTextUpdate } = useMutation(api.updateUiLanguage, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

        console.log("RRRR",data);
            api.getDispensingLanguage().then((response) => {
              //console.log("RRRR",response.datalist.en_GB.patients)
              localStorage.setItem(
                "DispensingLanguage",
                JSON.stringify(response.datalist)
              );
            })
            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });

          }else{
            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });

          }

           // queryClient.getQueriesData("uiLanguage");
           // props.history.push("/ui-language");

            /*

      api.getDispensingLanguage(options).then((response) => {
        let DispensingLanguageData = response.datalist;
        localStorage.setItem(
          "DispensingLanguage",
          JSON.stringify(DispensingLanguageData)
        );
        })

        */

        
          

    }
  })


  
  const cellDataUpdate = (id, cell) => {
    let curcellval = cell.getValue();
    let LangText = curcellval;
    let Celldata = cell.getRow().getData();

    console.log('LangTextUpdate: ', 11111);

    //console.log(id, LangText);
    LangTextUpdate({id, LangText});
    

  };




  const columns = [
    { title: "Id", field: "id", visible: false },
    { title: DispensingLanguage[lan][menukey]['Sl#'], hozAlign: 'center', width: 120, formatter: "rownum", headerHozAlign:"center", headerSort:false},
    { title: DispensingLanguage[lan][menukey]['Page Name'],  
      headerFilter: true, 
      width: 200, 
      field: lan == "en_GB" ? ("EnglishMenu"):("FranceMenu")
      
    },
    { title: DispensingLanguage[lan][menukey]['Language Tag'], field: "LangKey", headerFilter: true,},
    { title: DispensingLanguage[lan][menukey]['English Text'], 
      field: "EnglishText",
      hozAlign:"left",  
      headerHozAlign:"left",
      cssClass: "tabluator-column-editable text-field-editable",
      headerFilter: true,
      editor:permissionType?false:true,
      cellEdited: function(cell) {
        const currow = cell.getRow();
        const rowdata = currow.getData();
        const id = rowdata.LangLabelIdEn;
        cellDataUpdate(id,cell);
    },
    },
     /*  {
        title: DispensingLanguage[lan][menukey]['French Text'],
        field: "FranceText",
        hozAlign:"left",
        headerHozAlign:"left",
        cssClass: "tabluator-column-editable text-field-editable",
        headerFilter: true,
        editor:true,
          cellEdited: function(cell) {
            const currow = cell.getRow();
            const rowdata = currow.getData();
            const id = rowdata.LangLabelIdFr;
            cellDataUpdate(id,cell);
        },
      
     }, */


  ];

  const editUiLanguage = (data) => {
    let route = `ui-language/edit/${data.id}`;
    props.history.push(route);
  };


  function ActionButton(props: any) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    return (
      <>
        <Edit
          onClick={() => {
            editUiLanguage(rowData);
          }}
        />
        <DeleteOutline
          onClick={() => {
            //deleteUiLanguage(rowData);
          }}
        />
      </>
    );
  }



  return (
    <>
      <div className={classes.uiLanguagePageTitle}>
        <div className="d-flex justify-uiLanguage mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["UI Language"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  {/* <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Page Name"]}
                  </InputLabel>
                  <Select
                    //error={errorObject.MenuKey}
                    labelId="demo-simple-select-helper-label"
                    id="MenuKey"
                    name="MenuKey"
                    value={all}
                    onChange={handleChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>

                    {MenuList.map((item, index) => {
                    
                      return <MenuItem value={item.id}>{DispensingLanguage[lan]['menu'][item.name]}</MenuItem>;
                    })}
                  </Select> */}

                    <Autocomplete
                        autoHighlight
                        disableClearable
                        id="MenuKey"
                        options={MenuList}
                        onChange={(event, valueobj) => handleChangeChoosen('MenuKey', valueobj, valueobj?valueobj.id:"")}
                        getOptionLabel={(option) => option.name}
                        value={chosenValues['MenuKey']}
                        renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          //value={props.formData.MenuKey}
                          label={DispensingLanguage[lan][menukey]["Page Name"]}
                          variant="standard"
                          //error={props.errorObject.MenuKey}
                          //helperText={props.errorObject.MenuKey}
                          id="MenuKey"
                          name="MenuKey"
                          fullWidth
                        />
                        )}
                      />

                </FormControl>
              </Grid>

              <Grid item xs={10} sm={10}>
                <div className="float-right sw_btn_control">
                  <Button
                    disabled={permissionType}
                    color="info"
                    className="mr-2"
                    onClick={() => {
                      props.history.push("/ui-language/add");
                    }}
                  >
                    {DispensingLanguage[lan][menukey]["Add UI Language"]}
                  </Button>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoadingUi && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              height={500}
              layout={"fitColumns"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UiLanguage;

const useStyles = makeStyles({
  uiLanguagePageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },


});
