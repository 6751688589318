import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as api from "../../../actions/api";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import {  useQuery,  useMutation,  useQueryClient} from 'react-query';

const UnionEntryFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  open,
  ...props
}) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "union-entry-form";
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  
  //const UnitList = JSON.parse(localStorage.getItem('Unitofmeasurelist'));
  const UpazilaList = JSON.parse(localStorage.getItem("UpazilaList"));

  const [firstLoad, setFirstLoad] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [manyTableData, setManyTableData] = useState([]);


  
const backtolist=()=>{


  let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
  let idx = master_dirty_message_class.indexOf("dnone");
  if(idx === -1){
    // start Alert
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then((willAction) => {
      if (willAction) {
        props.history.push("/union-entry-form");
      }
    });
    // End Alert
  }else{
    props.history.push("/union-entry-form");
  }

}


const onSaveUpdateBtnClick = () => {

  setUpdatedDetailsGridDataHandler();
};


const setUpdatedDetailsGridDataHandler = () => {
  let validForm = props.checkValidateForm();
 
  // console.log('isval: ', validForm);
    if (validForm) {

      if(addProductForm){
        //when save
        mutate(validForm);
        
      }else{
        //when update
        mutateUpdate(validForm); 
      }
      

      
    }
};


const { isLoading : isLoadingSave, mutate } = useMutation(api.saveUnionList, {
  onSuccess: (data) => {
   // console.log("data: ", data);
    if (data.status == 200) {
      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
      queryClient.getQueriesData("unionserverentry"); //api page name
      props.history.push("/union-entry-form"); //
    } else {
      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
      queryClient.getQueriesData("unionserverentry"); //api page name
      props.history.push("/union-entry-form"); //
    }
  },
});


const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.UpdaeUnionData, {
  onSuccess: (data) => { 
    if (data.status == 200) {
   
      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });

      queryClient.getQueriesData("unionserverentry"); // include the page name
      props.history.push("/union-entry-form"); //page url
      api.dirtyModeDisable();
    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
    }
  },
});

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title"> {DispensingLanguage[lan][menukey]['Union List- Add/Edit']} </div>
            </div>
            <div className="float-right sw_btn_control">

              {addProductForm ? (
                <Grid item xs={12} className="mt-4 text-center tex_margin">
                  <Button
                disabled={props.permissionType}
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => handleReset()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Reset'])}
                  </Button>
                  <Button
                disabled={props.permissionType || isLoadingSave}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClick}
                    // onClick={() => handleSubmit()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Save'])}
                  </Button>
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    //onClick={() => props.history.push("/union-entry-form")}
                    onClick={() => backtolist()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Back to List'])}
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={12} className="mt-2 text-center">
                  <Button
                disabled={props.permissionType || isLoadingUpdate}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClick}
                    // onClick={() => handleUpdate()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Update'])}
                  </Button>
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    //onClick={() => props.history.push("/union-entry-form")}
                    onClick={() => backtolist()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Back to List'])}
                  </Button>
                </Grid>
              )}



            </div>

            
            <div className="mr-4 float-right mt-15">
              <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
            </div>
            
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={10}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['Union List']} />
              <CardContent>
                <Grid container spacing={10}>
                  <Grid item xs={4} sm={12}>
                    <Grid container spacing={4}>
                  
                      <Grid item xs={6} sm={4}>
                      <FormControl className={classes.fullWidth}>
                          <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]['Upazila Name']}*
                          </InputLabel>
                          <Select

                            error={errorObject.UpazilaCode}
                            required
                            labelId="demo-simple-select-helper-label"
                            id="UpazilaCode"
                            name="UpazilaCode"
                            value={formData.UpazilaCode}
                            disabled={true}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >
                            
                            {
                              UpazilaList.map(
                                (item, index) => {
                                  return (
                                    <MenuItem value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                }
                              )}
                          </Select>


                          <FormHelperText error={errorObject.UpazilaCode}>
                            {errorObject.UpazilaCode}

                          </FormHelperText>
                        </FormControl>
                      
                      </Grid>
                      <Grid item xs={6} sm={6}>

                        <TextField
                          error={errorObject.UnionName}
                          helperText={errorObject.UnionName}
                          
                disabled={props.permissionType}
                          id="UnionName"
                          name="UnionName"
                          label={DispensingLanguage[lan][menukey]["Union Name"]}
                          value={formData.UnionName}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={2} className="sw_active_patient">
                      <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={formData.bActive}
                          onChange={(e) => handleCheck(e)}
                          name="bActive"
                          value="no"
                          
                disabled={props.permissionType}
                        />
                      }
                       label={DispensingLanguage[lan][menukey]["Active"]}
                    />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}



        </Grid>
      </div>
    </div>
  );
};

export default UnionEntryFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
