import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import swal from "sweetalert";
import * as Service from "../../../services/Service.js";

import * as api from "../../../actions/api";
import {
  Typography,
  Grid,
  FormControlLabel,
  TextField,
  Button,
  Checkbox,
  FormControl,
  Card,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useQuery, useMutation, useQueryClient } from "react-query";

import { DeleteOutline, Edit, ViewList, Cached } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import Tooltip from "@material-ui/core/Tooltip";
//react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import { useTranslation } from "react-i18next";
import AssetsLotModal from "../../../services/AssetsLotModal";
import SingleProductLotModal from "../../../services/SingleProductLotModal";
import { cellFocusEditor, dateEditor } from "../../../services/Common";
import PrintModal from "../../../services/PrintModal";
import moment from "moment";
let timeStamp = "";

const MCWCIssueVoucherAssetFormData = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "mcwcassetissuefromstore";
  const FacilityId = localStorage.getItem("FacilityId");

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;

  //console.log("All User Info",UserInfo[0].UseFor);

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  let ReceiveDetailsRef = null;

  const [newBatchRowAdded, setNewBatchRowAdded] = useState(false);
  const [open, setOpen] = useState(false);
  const [openLotChnage, setOpenLotChnage] = useState(false);
  const [manyTableCurrentRow, setManyTableCurrentRow] = useState([]);

  const [PrintOpen, setPrintOpen] = useState(false);

  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);
  const IssuingToFacility = JSON.parse(
    localStorage.getItem("IssuingToFacility")
  );
  const FacilityEmployee = JSON.parse(localStorage.getItem("FacilityEmployee"));

  const DepartmentList = JSON.parse(localStorage.getItem("DepartmentList"));

  let TempItemList = useState([]);

  //====== Start Choosen Dropdown===========
  const [chosenValues, setChosenValues] = useState({
    transferFacilityCode: { id: "", name: "" },
    PrepBy: { EmpCode: "", EmpName: "" },
    AppBy1: { EmpCode: "", EmpName: "" },
    IssuedBy: { EmpCode: "", EmpName: "" },
    DeptId: { id: "", DeptName: "" },
    SectionId: { id: "", SectionName: "" },
  });

  useEffect(() => {
    let chosenValuesData = { ...chosenValues };

    chosenValuesData["DeptId"] =
      DepartmentList[
        DepartmentList.findIndex(
          (department_list) => department_list.DeptId == props.formData.DeptId
        )
      ];
    chosenValuesData["SectionId"] =
      UnitList[
        UnitList.findIndex(
          (Section_list) => Section_list.SectionId == props.formData.SectionId
        )
      ];
    chosenValuesData["transferFacilityCode"] =
      IssuingToFacility[
        IssuingToFacility.findIndex(
          (IssuingToFacility_list) =>
            IssuingToFacility_list.id == props.formData.transferFacilityCode
        )
      ];
    chosenValuesData["PrepBy"] =
      FacilityEmployee[
        FacilityEmployee.findIndex(
          (facilityEmployee_list) =>
            facilityEmployee_list.EmpCode == props.formData.PrepBy
        )
      ];
    chosenValuesData["AppBy1"] =
      FacilityEmployee[
        FacilityEmployee.findIndex(
          (facilityEmployee_list) =>
            facilityEmployee_list.EmpCode == props.formData.AppBy1
        )
      ];
    chosenValuesData["IssuedBy"] =
      FacilityEmployee[
        FacilityEmployee.findIndex(
          (facilityEmployee_list) =>
            facilityEmployee_list.EmpCode == props.formData.IssuedBy
        )
      ];
    setChosenValues(chosenValuesData);
  }, [
    props.formData.transferFacilityCode,
    props.formData.PrepBy,
    props.formData.AppBy1,
    props.formData.IssuedBy,
    props.formData.DeptId,
    props.formData.SectionId,
  ]);

  const handleChangeChoosen = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;
    setChosenValues(chosenValuesData);
    props.handleChangeChoosenProps(name, value);
  };

  // department section
  const [DeptId, setcurrDepartment] = useState(0);
  const [UnitList, setUnitListLocal] = useState(TempItemList);
  // console.log(UnitList);
  const [currUnitId, setcurrUnitId] = useState(0);

  const handleChangeDepartment = (name, valueobj, value) => {
    //var value = valueobj.DeptId;
    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;
    setChosenValues(chosenValuesData);
    /* setFirstLoad(true);
     setLoading(false); */
    setcurrDepartment(value);

    props.handleChangeChoosenProps(name, value);
  };

  const handleChangeChoosenUnit = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;
    props.formData["SectionId"] = "";

    setChosenValues(chosenValuesData);
    /* setFirstLoad(true);
     setLoading(false); */
    setcurrUnitId(value);

    props.handleChangeChoosenProps(name, value);
  };

  React.useEffect(() => {
    if (props.formData.DeptId !== "") {
      setcurrDepartment(props.formData.DeptId);
    }
  }, [props.formData.DeptId]);

  React.useEffect(() => {
    cItemList();
  }, [DeptId]);

  const cItemList = () => {
    let ItemListParam = {
      action: "getUnitItemList",
      DeptId: DeptId,
      menukey: "",
    };

    Service.default
      .postApi("source/combo_generic.php", ItemListParam)
      .then((res) => {
        setUnitListLocal(res.datalist);
      })
      .catch((err) => {});
  };

  //====== End Choosen Dropdown===========

  /* =====Start of Print Excel Many Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = (PrintType) => {
    //let finalUrl = EXCEL_EXPORT_URL + "report/issue_voucher_push_print.php";
    let finalUrl = EXCEL_EXPORT_URL + "report/mcwc_asset_issue_from_store_print.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TransactionId=" +
        props.formData.TransactionId +
        "&FacilityId=" +
        FacilityId +
        "&PrintType=" +
        PrintType +
        "&TimeStamp=" +
        Date.now()
    );
  };

  const PreviewMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/issue_voucher_push_print_preview.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TransactionId=" +
        props.formData.TransactionId +
        "&FacilityId=" +
        FacilityId +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of  Export Code==== */

  const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;

      case "ProductsLotChange":
        setOpenLotChnage(false);
        break;
      case "ProductsOpenLotChange":
        setOpenLotChnage(true);
        break;

      case "Printmodalclose":
        setPrintOpen(false);
        break;
      case "PrintModal":
        setPrintOpen(true);
        break;

      default:
        break;
    }
  };

  const addProduct = (NonOrderProductData) => {
    // console.log("NonOrderProductData: ", NonOrderProductData);
    let temNonordata = NonOrderProductData;
    let rows = [];

    let autoId = 0;

    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;

      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.UnitName = row.UnitName;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.OrderRemainingQty = row.OrderRemainingQty;
      newRow.CurStockQty = row.CurStockQty;
      newRow.Quantity = row.Quantity;
      newRow.BatchNo = row.BatchNo;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.MfgDate = row.MfgDate;
      newRow.NoOfCartons = row.NoOfCartons;
      newRow.ItemListLotId = row.ItemListLotId;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = 0; //row.Quantity * row.UnitPrice;

      rows.push(newRow);
    });

    temNonordata.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;
      newRow.ItemName = row.ItemName;
      newRow.UnitName = row.UnitName;
      newRow.TransactionItemId = null;
      newRow.CurStockQty = row.CurrentStockQty;
      newRow.NoOfCartons = null;
      newRow.Quantity = null;
      newRow.BatchNo = row.BatchNo;
      newRow.MfgDate = row.MfgDate;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.ItemListLotId = row.ItemListLotId;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = 0;
      rows.push(newRow);
    });

    setManyTableData(rows);
    props.handleAdd(rows);
    handleClose("Products");
    api.dirtyModeEnable();
  };

  const changeProductLot = (NewLotRowPopup) => {
    // console.log("ssssssssssmanyTableCurrentRow: ", manyTableCurrentRow);
    // console.log("sssssssssssssssssNewLotRowPopup: ", NewLotRowPopup[0]);

    let temNewLotRowPopup = NewLotRowPopup[0];
    let rows = [];

    let curLotchangerowId = manyTableCurrentRow.id;
    // console.log('curLotchangerowId: ', curLotchangerowId);
    let curLotchangeItemListLotId = manyTableCurrentRow.ItemListLotId;
    // console.log('curLotchangeItemListLotId: ', curLotchangeItemListLotId);

    manyTableData.forEach((row, i) => {
      // autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;
      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.UnitName = row.UnitName;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.OrderRemainingQty = row.OrderRemainingQty;
      newRow.Quantity = row.Quantity;

      if (row.id === curLotchangerowId) {
        //lot changing row in many table
        // console.log("Changing");
        newRow.CurStockQty = temNewLotRowPopup.CurrentStockQty;
        newRow.BatchNo = temNewLotRowPopup.BatchNo;
        newRow.ExpiryDate = temNewLotRowPopup.ExpiryDate;
        newRow.MfgDate = temNewLotRowPopup.MfgDate;
        newRow.ItemListLotId = temNewLotRowPopup.ItemListLotId;
      } else {
        newRow.CurStockQty = row.CurStockQty;
        newRow.BatchNo = row.BatchNo;
        newRow.ExpiryDate = row.ExpiryDate;
        newRow.MfgDate = row.MfgDate;
        newRow.ItemListLotId = row.ItemListLotId;
      }

      newRow.NoOfCartons = row.NoOfCartons;
      newRow.SKU = row.SKU;
      newRow.id = row.id;
      newRow.LineTotal = row.LineTotal;

      rows.push(newRow);
    });

    setManyTableData(rows);
    props.handleAdd(rows);

    handleClose("ProductsLotChange");
    api.dirtyModeEnable();
  };

  const ReceiveDetailsColumns = [
    { title: "TransactionItemId", field: "TransactionItemId", visible: false },
    { title: "id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      width: 70,
      formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Code"],
      field: "ItemCode",
      width: 120,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Name"],
      field: "ItemName",
      headerFilter: true,
      minWidth: 200,
    },
    {
      title: DispensingLanguage[lan][menukey]["Quantity"],
      field: "Quantity",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      // validator: ["integer", "min:0"],
      validator: [
        "integer",
        "min:0",
        function (cell, value, parameters) {
          if (value == "") return true;
          if (!cell.getRow().getCell("CurStockQty")) return true; //when type quickly then sometimes this function return false and showing error
          // if (!props.isPositive) return true;
          const balance = cell.getRow().getCell("CurStockQty").getValue();
          const validstatus = parseInt(value) <= parseInt(balance);
          return validstatus;
        },
      ],
      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      cellEdited: function (cell) {
        // const curcellval = cell.getValue();
        // const currow = cell.getRow();
        // const rowdata = currow.getData();
        // const pricecell = currow.getCell("LineTotal");
        // const totprice = rowdata.UnitPrice * curcellval;
        // pricecell.setValue(totprice);
      },
      formatter: "money",
      formatterParams: { thousand: ",", precision: false },
    },
    {
      title: DispensingLanguage[lan][menukey]["Unit"],
      field: "UnitName",
      width: 80,
      // headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Stock Qty"],
      field: "CurStockQty",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: "money",
      formatterParams: { thousand: ",", precision: false },
    },
    {
      title: DispensingLanguage[lan][menukey]["No Of Cartons"],
      field: "NoOfCartons",
      width: 80,
      visible: false,
      hozAlign: "right",
      headerHozAlign: "right",
      validator: ["integer", "min:0"],
      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
      },
    },

    {
      title: DispensingLanguage[lan][menukey]["Lot No"],
      field: "BatchNo",
      width: 140,
      // cssClass: "tabluator-column-editable text-field-editable",
      // editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      // cellEdited: function (cell) {
      //   const curcellval = cell.getValue();
      //   const currow = cell.getRow();
      //   const rowdata = currow.getData();
      //     props.setUpdatedDetailsGridDataHandler(rowdata);
      // },
    },
    {
      title: DispensingLanguage[lan][menukey]["Mfg. Date"],
      field: "MfgDate",
      width: 110,
      // cssClass: "tabluator-column-editable",
      // editor: props.bStockUpdate == 0 ? dateEditor : "",
      // cellEdited: function (cell) {
      //   const curcellval = cell.getValue();
      //   const currow = cell.getRow();
      //   const rowdata = currow.getData();
      // },
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry Date"],
      field: "ExpiryDate",
      width: 110,
      // cssClass: "tabluator-column-editable",
      // editor: props.bStockUpdate == 0 ? dateEditor : "",
      // cellEdited: function (cell) {
      //   const curcellval = cell.getValue();
      //   const currow = cell.getRow();
      //   const rowdata = currow.getData();
      // },
    },
    {
      title: "ItemListLotId",
      field: "ItemListLotId",
      width: 60,
      visible: false,
    },

    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      headerHozAlign: "center",
      hozAlign: "center",
      width: 80,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const checkInvoiceDate = () => {
    ///////////////// ///////////////// ///////////////// ///////////////// ///////////////// ///////////////// ///////////////// /////////////////
    // console.log( "Current IssuedDate: ", moment(props.formData.IssuedDate).format("YYYY-MM-DD") );
    // console.log("Current IssuedDate Year: ", moment(props.formData.IssuedDate).format("YYYY"));
    // console.log( "Current IssuedDate Month: ", moment(props.formData.IssuedDate).format("MM") );
    let InvoiceIssueDate = moment(props.formData.IssuedDate).format(
      "YYYY-MM-DD"
    );
    let InvoiceIssueDateYear = moment(props.formData.IssuedDate).format("YYYY");
    let InvoiceIssueDateMonth = moment(props.formData.IssuedDate).format("MM");

    // console.log("Current Date: ", moment().format("YYYY-MM-DD"));
    // console.log("Current Date Year: ", moment().format("YYYY"));
    // console.log("Current Date Month: ", moment().format("MM"));
    let CurrentDate = moment().format("YYYY-MM-DD");
    let CurrentYear = moment().format("YYYY");
    let CurrentMonth = moment().format("MM");

    if (
      CurrentYear == InvoiceIssueDateYear &&
      CurrentMonth == InvoiceIssueDateMonth
    ) {
      if (CurrentDate != InvoiceIssueDate) {
        // start Alert
        swal({
          title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
          text: t(
            DispensingLanguage[lan][menukey][
              "This invoice will be posted to stock on current date."
            ]
          ),
          icon: "warning",
          buttons: {
            confirm: {
              text: t(DispensingLanguage[lan][menukey]["Yes"]),
              value: true,
              visible: true,
              className: "",
              closeModal: true,
            },
            cancel: {
              text: t(DispensingLanguage[lan][menukey]["No"]),
              value: null,
              visible: true,
              className: "",
              closeModal: true,
            },
          },
        }).then((willAction) => {
          if (willAction) {
            //
            // console.log('IssuedDate update');
            props.formData["IssuedDate"] = moment().format("YYYY-MM-DD");
            onPostBtnClick();
            props.handleRDateChange(
              moment().format("YYYY-MM-DD"),
              "IssuedDate"
            );
            // console.log('IssuedDate update onPostBtnClick');
          }
        });
        // End Alert
      } else {
        onPostBtnClick();
      }
    } else {
      // start Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey]["Invoice Issue Date"] +
            ": " +
            moment(InvoiceIssueDate).format("DD/MM/YYYY") +
            ". " +
            DispensingLanguage[lan][menukey][
              "Do you want to post the invoice on this date?"
            ]
        ),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          onPostBtnClick();
        }
      });
      // End Alert
    }
  };

  const onPostBtnClick = () => {
    if (ReceiveDetailsRef == null) return;

    ///===================Check qty is blank of any item==========/////////////

    let blankinfo = {};
    ReceiveDetailsRef.table.getData().forEach((row, i) => {
      if (row.Quantity === null || row.Quantity === "") {
        blankinfo = row;
      }
    });

    if (Object.keys(blankinfo).length > 0) {
      props.openNoticeModal({
        isOpen: true,
        msg:
          t(DispensingLanguage[lan][menukey]["Blank Quantity is not allowed"]) +
          " - " +
          blankinfo.ItemName,
        msgtype: false,
      });
      return;
    }
    ///===================Check qty is blank of any item==========/////////////

    ///===================Check duplicate lots exist in invoice==========/////////////
    // if (ReceiveDetailsRef == null) return;

    let invoiceLotIds = [];
    let duplicateinfo = {};
    ReceiveDetailsRef.table.getData().forEach((row, i) => {
      if (invoiceLotIds.includes(row.ItemListLotId)) {
        duplicateinfo = row;
      } else {
        invoiceLotIds.push(row.ItemListLotId);
      }
    });

    // console.log('invoiceLotIds: ', invoiceLotIds);
    // console.log('duplicateinfo: ', duplicateinfo);

    if (Object.keys(duplicateinfo).length > 0) {
      props.openNoticeModal({
        isOpen: true,
        msg:
          t(DispensingLanguage[lan][menukey]["Duplicate Lot available"]) +
          " - " +
          duplicateinfo.ItemName,
        msgtype: false,
      });
      return;
    }
    ///===================Check duplicate lots exist in invoice==========/////////////

    //setbStockUpdate(true);
    // props.handlePost();
    handlePost();
  };

  const handlePost = async (e) => {
    let validForm = props.checkValidateForm(
      ReceiveDetailsRef.table.getData(),
      "post"
    );
    // console.log('isval: ', validForm);
    // console.log('props from post: ', props);

    // if (validateForm(formData)) {
    if (validForm) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey][
            "Do you really want to post the stock?"
          ]
        ),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true, */
      }).then((willAction) => {
        if (willAction) {
          let fDtat = { ...validForm, bStockUpdated: 1 };
          //console.log('fDtat: ', fDtat);
          mutateUpdate(fDtat);
        } else {
          // setbStockUpdate(0);
          props.stockPosted(0);
          // let route = `${TransactionId}`;
          let route = `${validForm.TransactionId}`;
          props.history.push(route);
        }
      });
      // End Post Alert
    }
  };

  const onSaveUpdateBtnClick = () => {
    // props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData());
    let manydata = ReceiveDetailsRef.table.getData();
    // if(manydata.length==0){
    //   manydata = [];
    // }
    // console.log('manydata: ', manydata);
    // console.log('manydata: ', manydata.length);

    setUpdatedDetailsGridDataHandler(manydata);
  };

  const setUpdatedDetailsGridDataHandler = (rows) => {
    let validForm = props.checkValidateForm(rows, "edit");
    // console.log('isval: ', validForm);
    if (validForm) {
      // console.log('props.addProductForm: ', props.addProductForm);
      if (props.addProductForm) {
        //when save
        mutate(validForm);
      } else {
        //when update
        mutateUpdate(validForm);
      }
    }
  };

  const { isLoading: isLoadingSave, mutate } = useMutation(
    api.saveMCWCIssueVoucherAssetInv,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });

          let route = `edit/${data.TransactionId}`;
          props.history.push(route);
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      },
    }
  );

  const { isLoading: isLoadingUpdate, mutate: mutateUpdate } = useMutation(
    api.MCWCissueVoucherAssetUpdate,
    {
      onSuccess: (data) => {
        // console.log('mutateUpdate data: ', data);
        if (data.status == 200) {
          //  console.log("props: ",props.formData.TransactionId);
          //setbStockUpdate(data.bStockUpdated);
          // props.successSave(data.bStockUpdated);
          if (data.bStockUpdated == 1) {
            mutatePost(props.formData.TransactionId);
          } else {
            // props.refetchInvoice(data.bStockUpdated); //refetch();
            props.refetchInvoice(); //refetch();
            props.openNoticeModal({
              isOpen: true,
              msg: data.message,
              msgtype: data.success,
            });
          }
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      },
    }
  );

  const { isLoading: isLoadingPost, mutate: mutatePost } = useMutation(
    api.postMCWCIssueVoucherAsset,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          // setDeletedDataSet([]);

          if (data.data.success == 0) {
            //setbStockUpdate(0);
            props.stockPosted(0);
            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });
          } else {
            // setbStockUpdate(1);
            props.stockPosted(1);
            props.refetchInvoice();

            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });
          }
        } else {
          // setbStockUpdate(0);
          props.stockPosted(0);
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
        }
      },
    }
  );

  const invoiceCancel = (e) => {
    const { name, checked } = e.target;
    let validForm = props.checkValidateForm(
      ReceiveDetailsRef.table.getData(),
      "cancel"
    );

    if (validForm) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text:
          t(DispensingLanguage[lan][menukey]["Do you really want to cancel?"]) +
          " " +
          t(
            DispensingLanguage[lan][menukey][
              "You will not able to edit this invoice after cancel"
            ]
          ),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        /*  buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true, */
      }).then((willCancel) => {
        if (willCancel) {
          // let fDtat={...formData,'ManyJsonSave':ManyTableData,"BCancel":1,"TableName":"indent",'deletedDataSet':deletedDataSet};
          let fDtat = { ...validForm, BCancel: 1, TableName: "indent" };
          mutateUpdate(fDtat);
        } else {
          //setFormData({ ...formData, [name]: false });
        }
      });
    }
  };

  const deleteReceiveInv = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Do you really want to delete the selected item?"
        ]
      ),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: [t(DispensingLanguage[lan][menukey]["No"]),
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willDelete) => {
      if (willDelete) {
        let newarray = manyTableData.filter((element) => element !== data);
        setManyTableData(newarray);
        props.deleteReceiveInv(data);
        api.dirtyModeEnable();
      }
    });
  };

  // const CopyBatch = () => {
  //   props.handleAdd(manyTableData);
  // };

  function ActionButton(props) {
    // console.log("console props......", props);

    const rowData = props.cell._cell.row.data;
    // const goToDetails = () => {
    //   let rowobj = props.cell._cell.row.data;
    //   timeStamp = Math.round(new Date().getTime() / 100);

    //   setManyTableData((prevRows) => {
    //     const tmpPrevRows = [...prevRows];
    //     let setRposition = tmpPrevRows.findIndex((o) => o.SKU == rowobj.SKU);
    //     const obj = { ...rowobj };

    //     obj.id = timeStamp;
    //     obj.TransactionItemId = "";
    //     obj.ExpiryDate = "";
    //     obj.BatchNo = "";
    //     obj.Quantity = 0;
    //     obj.SKU = Date.now();
    //     tmpPrevRows.splice(setRposition + 1, 0, obj);
    //     return tmpPrevRows;
    //   });

    //   CopyBatch();
    //   setNewBatchRowAdded((prevNewBatchRowAdded) => !prevNewBatchRowAdded);
    // };

    // const cellValue = props.cell._cell.value || "Edit | Show";
    if (bStockUpdate == 0)
      return (
        <>
          {/* <FileCopy
            onClick={() => {
              if (!bStockUpdate) goToDetails(rowData);
            }}
          /> */}

          <Cached
            onClick={() => {
              if (!bStockUpdate) {
                setManyTableCurrentRow(rowData);
                // console.log("Current Row......", rowData);
                // console.log("ItemCode......", rowData.ItemCode);
                // console.log("TransactionItemId......", rowData.TransactionItemId);
                // console.log("ItemListLotId......", rowData.ItemListLotId);
                handleClose("ProductsOpenLotChange");
              }
            }}
          />

          <DeleteOutline
            onClick={() => {
              if (!bStockUpdate) deleteReceiveInv(rowData);
            }}
          />
        </>
      );
    else return <></>;
  }

  useEffect(() => {
    if (ReceiveDetailsRef == null) return;
    if (props.bFirst) {
      setManyTableData(props.ManyTableData);
    }

    setbStockUpdate(props.bStockUpdate);
  }, [props.ManyTableData, props.bStockUpdate]); //

  useEffect(() => {
    if (ReceiveDetailsRef.table != null && timeStamp != "") {
      // console.log("Many table", ReceiveDetailsRef.table);
      // console.log("Many table 12222222222");

      ReceiveDetailsRef.table.scrollToRow(timeStamp, "center", true);
      ReceiveDetailsRef.table.selectRow(timeStamp);
    }
  }, [newBatchRowAdded]);

  const handleReset = () => {
    props.handleReset();
    setManyTableData([]);
  };

  const backtolist = () => {
    let master_dirty_message_class = document
      .getElementById("master_dirty_message")
      .getAttribute("class");
    let idx = master_dirty_message_class.indexOf("dnone");
    if (idx === -1) {
      // start Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey][
            "You have unsaved changes which will be lost. Continue?"
          ]
        ),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          props.history.push("/mcwcassetissuefromstore");
        }
      });
      // End Alert
    } else {
      props.history.push("/mcwcassetissuefromstore");
    }
  };

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {
                  DispensingLanguage[lan][menukey][
                    "MCWC Asset Issue from Store- Add/Edit"
                  ]
                }
              </div>

              <div className="float-right sw_btn_control">
                {props.addProductForm ? (
                  <Grid item xs={12} className=" text-center">
                    <div className="float-right sw_btn_control">
                      <Button
                        disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button>
                      <Button
                        disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onSaveUpdateBtnClick}
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        disabled={isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        // onClick={() => props.history.push("/mcwcassetissuefromstore")}
                        onClick={() => backtolist()}
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="mt-2 text-center">
                    <Button
                      disabled={
                        props.bStockUpdate || isLoadingUpdate || isLoadingPost
                      }
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    <Button
                      // disabled={props.bStockUpdate}
                      disabled={
                        props.bStockUpdate ||
                        !(manyTableData.length > 0) ||
                        isLoadingUpdate ||
                        isLoadingPost
                      }
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      // onClick={onPostBtnClick}
                      onClick={checkInvoiceDate}
                    >
                      {DispensingLanguage[lan][menukey]["post"]}
                    </Button>
                    <Button
                      disabled={isLoadingUpdate || isLoadingPost}
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      // onClick={() => props.history.push("/mcwcassetissuefromstore")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                    {/* <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PreviewMany()}
                    >
                      <i className="fa fa-search"></i>
                    </Button> */}

                    <Tooltip title="Print">
                      <Button
                        disabled={
                          props.bStockUpdate == 0 &&
                          UserInfo[0].UseFor == "WIMS"
                            ? true
                            : false
                        }
                        variant="contained"
                        color="primary"
                        className="mr-2 sw_icons"
                        /* onClick={() => handleClose("PrintModal")} */
                         onClick={() => PrintMany()}
                      >
                        <i class="fas fa-print"></i>
                      </Button>
                    </Tooltip>
                  </Grid>
                )}
              </div>
              <div className="mr-4 float-right mt-15">
                <span
                  id="master_dirty_message"
                  className={"masterredtextcolor dnone"}
                >
                  {" "}
                  {DispensingLanguage[lan][menukey]["You have unsaved changes"]}
                </span>
              </div>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            {/* <Card className="sw_card">
            <CardHeader title="Personal Information" />
            <CardContent> */}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={2}>
                        <TextField
                          disabled={true /*bStockUpdate*/}
                          error={props.errorObject.TransactionNo}
                          helperText={props.errorObject.TransactionNo}
                          required
                          id="TransactionNo"
                          name="TransactionNo"
                          label={DispensingLanguage[lan][menukey]["Invoice No"]}
                          value={
                            props.formData.TransactionNo ||
                            props.generatedInvoiceNo
                          }
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => props.handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={12} sm={5}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            required
                            id="DeptId"
                            disabled={props.bStockUpdate}
                            options={DepartmentList}
                            onChange={(event, valueobj) =>
                              handleChangeDepartment(
                                "DeptId",
                                valueobj,
                                valueobj ? valueobj.DeptId : ""
                              )
                            }
                            getOptionLabel={(option) => option.DeptName}
                            value={chosenValues["DeptId"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.DeptName}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={props.formData.DeptId}
                                // value={chosenValues["DeptId"]}
                                label={
                                  DispensingLanguage[lan][menukey]["Department"]+
                                  " *"
                                }
                                variant="standard"
                                error={props.errorObject.DeptId}
                                helperText={props.errorObject.DeptId}
                                id="DeptId"
                                name="DeptId"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={5}>
                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            required
                            id="SectionId"
                            disabled={props.bStockUpdate}
                            options={UnitList}
                            onChange={(event, valueobj) =>
                              handleChangeChoosenUnit(
                                "SectionId",
                                valueobj,
                                valueobj ? valueobj.SectionId : ""
                              )
                            }
                            getOptionLabel={(option) => option.SectionName}
                            value={chosenValues["SectionId"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.SectionName}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={props.formData.SectionId}
                                label={DispensingLanguage[lan][menukey]["Unit"]+
                                " *"}
                                variant="standard"
                                error={props.errorObject.SectionId}
                                helperText={props.errorObject.SectionId}
                                id="SectionId"
                                name="SectionId"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* <Grid item xs={3} sm={3}>

                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        disabled={props.bStockUpdate}
                        id="transferFacilityCode"
                        options={IssuingToFacility}
                        onChange={(event, valueobj) =>
                          handleChangeChoosen(
                            "transferFacilityCode",
                            valueobj,
                            valueobj ? valueobj.id : ""
                          )
                        }
                        getOptionLabel={(option) => option.name}
                        value={chosenValues["transferFacilityCode"]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.name}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.transferFacilityCode}
                            label={
                              DispensingLanguage[lan][menukey]["Facility"] +
                              " *"
                            }
                            variant="standard"
                            error={props.errorObject.transferFacilityCode}
                            helperText={props.errorObject.transferFacilityCode}
                            id="transferFacilityCode"
                            name="transferFacilityCode"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Grid> */}

                  <Grid item xs={3} sm={3}>
                    {/* <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Prepareed By"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.PrepBy}
                        labelId="demo-simple-select-helper-label"
                        id="PrepBy"
                        name="PrepBy"
                        value={props.formData.PrepBy}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={props.bStockUpdate}
                      >
                        {FacilityEmployee.map((item, index) => {
                          return (
                            <MenuItem value={item.EmpCode}>
                              {item.EmpName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={props.errorObject.PrepBy}>
                        {props.errorObject.PrepBy}
                      </FormHelperText>
                    </FormControl> */}

                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        hidden={props.bStockUpdate}
                        disabled={props.bStockUpdate}
                        id="PrepBy"
                        options={FacilityEmployee}
                        onChange={(event, valueobj) =>
                          handleChangeChoosen(
                            "PrepBy",
                            valueobj,
                            valueobj ? valueobj.EmpCode : ""
                          )
                        }
                        getOptionLabel={(option) => option.EmpName}
                        value={chosenValues["PrepBy"]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.EmpName}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.PrepBy}
                            label={
                              DispensingLanguage[lan][menukey]["Prepareed By"] +
                              " *"
                            }
                            variant="standard"
                            error={props.errorObject.PrepBy}
                            helperText={props.errorObject.PrepBy}
                            id="PrepBy"
                            name="PrepBy"
                            fullWidth
                          />
                        )}
                      />

                      <TextField
                        id="PrepByName"
                        name="PrepByName"
                        label={
                          DispensingLanguage[lan][menukey]["Prepareed By"] +
                          " *"
                        }
                        value={props.formData.PrepByName}
                        disabled={true}
                        hidden={!props.bStockUpdate}
                        fullWidth
                        autoComplete="family-name"
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        required
                        error={props.errorObject.TransactionDate}
                        helperText={props.errorObject.TransactionDate}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        id="TransactionDate"
                        label={
                          DispensingLanguage[lan][menukey]["Prepared Date"]
                        }
                        autoOk={true}
                        name="TransactionDate"
                        fullWidth
                        showTodayButton={true}
                        disableFuture={true}
                        value={props.formData.TransactionDate || null}
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "TransactionDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    {/* <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Approved By"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.AppBy1}
                        labelId="demo-simple-select-helper-label"
                        id="AppBy1"
                        name="AppBy1"
                        value={props.formData.AppBy1}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={props.bStockUpdate}
                      >
                        {FacilityEmployee.map((item, index) => {
                          return (
                            <MenuItem value={item.EmpCode}>
                              {item.EmpName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={props.errorObject.AppBy1}>
                        {props.errorObject.AppBy1}
                      </FormHelperText>
                    </FormControl> */}

                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        hidden={props.bStockUpdate}
                        disabled={props.bStockUpdate}
                        id="AppBy1"
                        options={FacilityEmployee}
                        onChange={(event, valueobj) =>
                          handleChangeChoosen(
                            "AppBy1",
                            valueobj,
                            valueobj ? valueobj.EmpCode : ""
                          )
                        }
                        getOptionLabel={(option) => option.EmpName}
                        value={chosenValues["AppBy1"]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.EmpName}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.AppBy1}
                            label={
                              DispensingLanguage[lan][menukey]["Approved By"] +
                              " *"
                            }
                            variant="standard"
                            error={props.errorObject.AppBy1}
                            helperText={props.errorObject.AppBy1}
                            id="AppBy1"
                            name="AppBy1"
                            fullWidth
                          />
                        )}
                      />

                      <TextField
                        id="AppByName"
                        name="AppByName"
                        label={
                          DispensingLanguage[lan][menukey]["Approved By"] + " *"
                        }
                        value={props.formData.AppByName}
                        disabled={true}
                        hidden={!props.bStockUpdate}
                        fullWidth
                        autoComplete="family-name"
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        required
                        error={props.errorObject.AppDate1}
                        helperText={props.errorObject.AppDate1}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        id="AppDate1"
                        label={
                          DispensingLanguage[lan][menukey]["Approved Date"]
                        }
                        autoOk={true}
                        name="AppDate1"
                        fullWidth
                        showTodayButton={true}
                        disableFuture={true}
                        value={props.formData.AppDate1 || null}
                        format="dd/MM/yyyy"
                        onChange={(e) => props.handleRDateChange(e, "AppDate1")}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    {/* <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Issued By"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.IssuedBy}
                        labelId="demo-simple-select-helper-label"
                        id="IssuedBy"
                        name="IssuedBy"
                        value={props.formData.IssuedBy}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={props.bStockUpdate}
                      >
                        {FacilityEmployee.map((item, index) => {
                          return (
                            <MenuItem value={item.EmpCode}>
                              {item.EmpName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={props.errorObject.IssuedBy}>
                        {props.errorObject.IssuedBy}
                      </FormHelperText>
                    </FormControl> */}

                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        hidden={props.bStockUpdate}
                        disabled={props.bStockUpdate}
                        id="IssuedBy"
                        options={FacilityEmployee}
                        onChange={(event, valueobj) =>
                          handleChangeChoosen(
                            "IssuedBy",
                            valueobj,
                            valueobj ? valueobj.EmpCode : ""
                          )
                        }
                        getOptionLabel={(option) => option.EmpName}
                        value={chosenValues["IssuedBy"]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.EmpName}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={props.formData.IssuedBy}
                            label={
                              DispensingLanguage[lan][menukey]["Issued By"] +
                              " *"
                            }
                            variant="standard"
                            error={props.errorObject.IssuedBy}
                            helperText={props.errorObject.IssuedBy}
                            id="IssuedBy"
                            name="IssuedBy"
                            fullWidth
                          />
                        )}
                      />

                      <TextField
                        id="IssuedByName"
                        name="IssuedByName"
                        label={
                          DispensingLanguage[lan][menukey]["Issued By"] + " *"
                        }
                        value={props.formData.IssuedByName}
                        disabled={true}
                        hidden={!props.bStockUpdate}
                        fullWidth
                        autoComplete="family-name"
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        required
                        error={props.errorObject.IssuedDate}
                        helperText={props.errorObject.IssuedDate}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        id="IssuedDate"
                        label={DispensingLanguage[lan][menukey]["Issued Date"]}
                        autoOk={true}
                        name="IssuedDate"
                        fullWidth
                        showTodayButton={true}
                        disableFuture={true}
                        value={props.formData.IssuedDate || null}
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "IssuedDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  {/* <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={props.bStockUpdate}
                      id="CarriersName"
                      name="CarriersName"
                      label={DispensingLanguage[lan][menukey]["Carrier Name"]}
                      value={props.formData.CarriersName}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid> */}
                  <Grid item xs={5} sm={5}>
                    <TextField
                      disabled={props.bStockUpdate}
                      id="Remarks"
                      name="Remarks"
                      label={DispensingLanguage[lan][menukey]["Remarks"]}
                      value={props.formData.Remarks}
                      fullWidth
                      multiline
                      rows={3}
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  {/* <Grid item xs={12} className="marginTop10"> */}
                  <Grid item xs={1} sm={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="bUpdated"
                          disabled={true}
                          color="primary"
                          checked={props.formData.bUpdated > 0 ? true : false}
                          name="bUpdated"
                          value="no"
                        />
                      }
                      label={DispensingLanguage[lan][menukey]["Updated"]}
                    />
                  </Grid>

                  <Grid item xs={1} sm={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="BCancel"
                          disabled={props.bStockUpdate || props.addProductForm}
                          color="primary"
                          checked={props.formData.BCancel > 0 ? true : false}
                          // onChange={(e) => props.invoiceCancel(e)}
                          onChange={(e) => invoiceCancel(e)}
                          name="BCancel"
                          value="no"
                        />
                      }
                      label={DispensingLanguage[lan][menukey]["CANCEL"]}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="TransactionId"
                      name="TransactionId"
                      label="TransactionId"
                      value={props.formData.TransactionId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                {/* new row */}
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card">
                    <CardContent>
                      <Grid container className="mb-2">
                        <Grid item xs={12} sm={12}>
                          <div className="float-right sw_btn_control">
                            {/* <Button
                                  className="mr-2"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleClose("OrderOpen")}
                                >
                                  SELECT FROM ORDER
                                </Button>  */}

                            <Button
                              disabled={props.bStockUpdate}
                              // disabled={ props.bStockUpdate || (props.formData.TransactionId > 0 ? false : true) }
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              onClick={() => handleClose("ProductsOpen")}
                            >
                              {DispensingLanguage[lan][menukey]["SELECT ITEM"]}
                            </Button>
                          </div>

                          <AssetsLotModal
                            // handleClose={handleClose}
                            // open={open}
                            // addProduct={addProduct}
                            // {...props}

                            handleClose={handleClose}
                            open={open}
                            addProduct={addProduct}
                            FacilityId={FacilityId}
                            {...props}
                          />

                          <SingleProductLotModal
                            handleClose={handleClose}
                            open={openLotChnage}
                            changeProductLot={changeProductLot}
                            manyTableCurrentRow={manyTableCurrentRow}
                            FacilityId={FacilityId}
                            {...props}
                          />

                          <PrintModal
                            handleClose={handleClose}
                            open={PrintOpen}
                            PrintMany={PrintMany}
                            {...props}
                          />

                          {/* <SourceModal
                            handleClose={handleClose}
                            open={SoureOpen}
                            addProduct={addSource}
                            {...props}
                          /> */}
                        </Grid>
                      </Grid>
                      <ReactTabulator
                        ref={(r) => (ReceiveDetailsRef = r)}
                        columns={ReceiveDetailsColumns}
                        data={manyTableData}
                        height="350px"
                        layout={"fitColumns"}
                      />
                    </CardContent>
                  </Card>
                </Grid>

                {/* new row */}
              </Grid>
            </Grid>

            {/* </CardContent>
          </Card> */}
          </Grid>

          {/* Action buttons */}
        </Grid>
      </div>
    </div>
  );
};

export default MCWCIssueVoucherAssetFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
