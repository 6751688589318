import React, { forwardRef, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Grid, Checkbox, TextField, CardContent } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

let tblMenuRef = null;

const Roleaccess = (props) => {
  const permissionType = props.permissionType;
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "role-access-entry";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;

  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const [dataItems1, setDataItems1] = useState([]);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const [selectedRoleData, setSelectedRole] = useState(1);

  const [selectedrow, setSelectedrow] = useState(false);

  const { isLoading, error, isError, data, refetch } = useQuery(
    "roleaccess",
    api.getRoleAccessEntry,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setSelectedrow(true);
      },

      //enabled: false,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  let params = {
    menukey: menukey,
    action: "getMenuLists",
    role_id: selectedRoleData,
  };

  const {
    isLoading1,
    error1,
    isError1,
    data: menuData,
    refetch: MenuRefetch,
  } = useQuery([params], api.getMenuPerEntry, {
    onSuccess: (menuData) => {
      setDataItems1(menuData);
    },
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0, //1000 * 60 * 60 * 24,
  });

  const rowClick = (e: any, row: any) => {
    let rowData = row._row.data;
    // console.log(rowData.id);
    setSelectedRole(rowData.id);
    //MenuRefetch();
  };

  const { isLoadingLang, mutate: bCheckedUpdate } = useMutation(
    api.rolesToMenuInsert,
    {
      onSuccess: (data) => {
        if (data.data.status == 200) {
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
        }
      },
    }
  );

  function bCheckedInsertUpdateDelete(parmas) {
    // console.log('parmas: ', parmas);
    api.rolesToMenuInsertUpdateDelete(parmas, props);
  }

  // const { isLoadingLang3, mutate: bCheckedInsertUpdateDelete } = useMutation(
  //   api.rolesToMenuInsertUpdateDelete,
  //   {
  //     onSuccess: (data) => {

  //       if (data.data.status == 200) {

  //         props.openNoticeModal({
  //           isOpen: true,
  //           msg: data.data.message,
  //           msgtype: data.data.success,
  //         });

  //         }else{
  //           props.openNoticeModal({
  //             isOpen: true,
  //             msg: data.data.message,
  //             msgtype: data.data.success,
  //           });
  //       }

  //     },
  //      enabled: false,
  //     refetchOnWindowFocus: false,
  //     refetchOnmount: false,
  //     refetchOnReconnect: false,
  //     retry: false,
  //     staleTime: 0,//1000 * 60 * 60 * 24,
  //   }
  // );

  function RowInputData(props: any, type = null) {
    const rowData = props.cell._cell.row.data;
    let fieldName = props.field;
    let fieldType = props.fieldType;
    let labelName = props.labelName;
    let dropDownKey = props.dropDownKey;

    switch (fieldType) {
      case "check-box":
        return rowData.type == "bDispensing" ? (
          <></>
        ) : (
          <Checkbox
            color="primary"
            checked={rowData[fieldName]}
            name={fieldName}
            value={rowData[fieldName]}
          />
        );
        break;

      default:
        return <></>;
        break;
    }
    return <></>;
  }

  useEffect(() => {
    console.log("useEffect calling for refresh");
    if (selectedRoleData > 0) {
      MenuRefetch();
    }
  }, [selectedRoleData]);

  const columns = [
    { title: "Id", field: "id", width: 50, visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      formatter: "rownum",
      width: 80,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
    },
    { title: DispensingLanguage[lan][menukey]["Role Name"], field: "role" },
  ];

  function setFormPermission(menu_id, cell) {
    const currow = cell.getRow();
    const rowdata = currow.getData();
    let editpermission = rowdata.editpermission;
    let beditpermission = rowdata.beditpermission;
    let seteditpermission = 0;

    //console.log("Row clicked ============ ",editpermission);

    if (beditpermission === "No") {
      let currCol = cell.getColumn().getField();
      let value = cell.getValue();
      let curNewValue = "View";
      const calPushCell = currow.getCell(currCol);
      calPushCell.setValue(curNewValue);
      seteditpermission = 1;
    } else if (beditpermission === "View") {
      let currCol = cell.getColumn().getField();
      let value = cell.getValue();
      let curNewValue = "Edit";
      const calPushCell = currow.getCell(currCol);
      calPushCell.setValue(curNewValue);
      seteditpermission = 2;
    } else if (beditpermission === "Edit") {
      let currCol = cell.getColumn().getField();
      let value = cell.getValue();
      let curNewValue = "No";
      const calPushCell = currow.getCell(currCol);
      calPushCell.setValue(curNewValue);
      seteditpermission = 0;
    }

    bCheckedInsertUpdateDelete({
      selectedRoleData,
      menu_id,
      seteditpermission,
    });
  }

  const columns1 = [
    { title: "Id", field: "id", width: 50, visible: false },
    {
      title: "editpermission",
      field: "editpermission",
      width: 50,
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Role Access"],
      visible: false,
      field: "bChecked",
      hozAlign: "center",
      headerHozAlign: "center",
      width: 100,
      headerSort: false,
      formatter: reactFormatter(
        <RowInputData
          labelName="bDispensing"
          field="bChecked"
          fieldType="check-box"
        />
      ),
      cellClick: function (e, cell) {
        cell.setValue(!cell.getValue());
        const currow = cell.getRow();
        const rowdata = currow.getData();
        const menu_id = rowdata.menupkid;
        cellDataUpdatePop(menu_id, cell);
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Access"],
      width: 100,
      field: "beditpermission",
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
      headerFilter: true,
      cellClick: function (e, cell) {
        if (!permissionType) {
          const currow = cell.getRow();
          const rowdata = currow.getData();
          const menu_id = rowdata.menupkid;
          setFormPermission(menu_id, cell);
        }
      },
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        if (value === "View") {
          return (
            "<span style='font-weight:bold;color:#0013ff;'>" + value + "</span>"
          );
        } else if (value === "Edit") {
          return (
            "<span style='font-weight:bold;color:#01a031;'>" + value + "</span>"
          );
        } else {
          return (
            "<span style='font-weight:bold;color:#ff2d00;'>" + value + "</span>"
          );
        }
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Menu Name"],
      field: "menuname",
      headerSort: false,
      headerFilter: true,
      formatter:function(cell, formatterParams){
        let value = cell.getValue();
        let subMenu = "-";
        let subSubMenu = "--";
        let subMenuIndex = value.indexOf(subMenu);
        let subSubMenuIndex = value.lastIndexOf(subMenu);

         if (subMenuIndex !== -1 && value[subMenuIndex + 1] === subMenu) {
           return "<span style='margin-left: 40px;'>" + value + "</span>";
         } else if (subSubMenuIndex !== -1) {
           return "<span style='margin-left: 20px;'>" + value + "</span>";
         }else{
           return value;
         }

         
      },
    },
  ];

  const cellDataUpdatePop = (menu_id, cell) => {
    let curcellval = cell.getValue();
    let bCheckedVal = curcellval;

    bCheckedUpdate({ selectedRoleData, menu_id, bCheckedVal });
  };

  useEffect(() => {
    if (selectedrow == true) {
      tblMenuRef.table.selectRow(1);
    }
  }, [selectedrow]);

  return (
    <>
      <div className={classes.dispenserPageTitle}>
        <div className="d-flex justify-dispenser mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {" "}
                {DispensingLanguage[lan][menukey]["Role to Menu Permission"]}
              </div>

              <div className="float-right sw_btn_control"></div>
            </div>
          </Grid>
        </div>
        <CardContent>
          <Grid container spacing={10}>
            <Grid item xs={4} sm={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={4}>
                  <div className="uniqueName">
                    <ReactTabulator
                      ref={(r) => (tblMenuRef = r)}
                      options={{
                        selectable: 1,
                        selectableRangeMode: "click",
                      }}
                      columns={columns}
                      data={dataItems}
                      layout={"fitColumns"}
                      rowClick={(e, row) => rowClick(e, row)}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} sm={8}>
                  <div className="uniqueName">
                    <ReactTabulator
                      columns={columns1}
                      data={dataItems1}
                      layout={"fitColumns"}
                      height={"600px"}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </div>
    </>
  );
};

export default Roleaccess;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
