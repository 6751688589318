import React from "react";
import "assets/css/custom.css";

const LoadingSpinner = () => {
  return (
    <div className="sw_preloader_center_bg">
		<div className="sw_preloader_center">
		  <div className="sw_preloader">
			<span className="lds-dual-ring"></span>
		  </div>
		</div>
    </div>
  );
};

export default LoadingSpinner;