import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  TextField,
  Button,
  FormControl, 
  Card,
  CardContent, 
} from "@material-ui/core";
import swal from "sweetalert";
import Autocomplete from '@material-ui/lab/Autocomplete'; 
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";


import { useTranslation } from "react-i18next"; 
import SOReasonModal from "./SOReasonModal";
import EligibilityReasonModal from "./EligibilityReasonModal";

import * as api from "../../../actions/api";
import { cellFocusEditor, dateEditor, getDefaultMonthYear, cellselectEditor } from "../../../services/Common";
// import OrderModal from "./OrderModal";

import MomentUtils from "@date-io/moment";
import moment from "moment";
let timeStamp = "";

const MCWCMonthlyForm3FormData = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "mcwc-monthly-form-3";
  const FacilityId = localStorage.getItem("FacilityId");

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  let ReceiveDetailsRef = null;
  const [open, setOpen] = useState(false);
  const [openSOReason, setOpenSOReason] = useState(false);
  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);
  const MonthList = JSON.parse(localStorage.getItem("MonthList"));

  const [currentCellOnClick, setCurrentCellOnClick] = useState([]);

  const [SupplyPlanSettingItems,setSupplyPlanSettingItems] = useState({});
  // const [toggleButtonValue, setToggleButton] = React.useState("CON008");

  const SoReasonListAsscociate = JSON.parse(localStorage.getItem("SoReasonList"));
  const SoReasonList = [];
  Object.keys(SoReasonListAsscociate).map((key) => (
    SoReasonList.push(key)   
  ))

  const ReasonNameListAsscociate = JSON.parse(localStorage.getItem("ReasonNameList"));
  const ReasonNameList = [];
  Object.keys(ReasonNameListAsscociate).map((key) => (
    ReasonNameList.push(key)
  ))


  const [openIssueInvoice, setIssueInvoice] = useState(false);

 let AdjustPlusIdx = 3;
 let AdjustMinusIdx = 4;
 let DistributionIdx = 6;
 let ClosingBalanceIdx = 7;
 let SOReasonIDIdx = 8;
 let SODaysIdx = 9;
 let bEntitledIdx = 10;
 let ReasonIdIdx = 11;

//====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "ReportedMonthId": {"id": "","name": ""}
});

useEffect(() => {
  
  let chosenValuesData = { ...chosenValues};
  chosenValuesData['ReportedMonthId'] = MonthList[MonthList.findIndex(Month_List => Month_List.id == props.formData.ReportedMonthId)];
   setChosenValues(chosenValuesData); 

}, [props.formData.ReportedMonthId]); 


const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  setChosenValues(chosenValuesData); 

  // let chosenValuesDataFacility = { ...chosenValuesFacility};
  // chosenValuesDataFacility[name] = valueobj;  
  // setChosenValuesFacility(chosenValuesDataFacility); 

  props.handleChangeChoosenProps(name, value);
};

//====== End Choosen Dropdown===========

  /* =====Start of Print Excel Many Code==== */
   const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

   const PrintMany = () => {
     let finalUrl = EXCEL_EXPORT_URL + "report/mcwc_month_form3_print.php";
     let formstartDate = moment(props.formData.TransactionDate).format("YYYY-MM-DD");
     window.open(
       finalUrl +
         "?menukey=" +
         menukey +
         "&lan=" +
         localStorage.getItem("LangCode") +
         "&ReportedMonthId=" +
         props.formData.ReportedMonthId +
         "&ReportedYear=" +
         props.formData.ReportedYear +
         "&ItemCode=" +
         'CON002' +
         "&TransactionDate=" +
        formstartDate +
         "&FacilityId=" +
         FacilityId +
         "&TransactionId=" +
         props.formData.TransactionNo +
         "&TimeStamp=" +
         Date.now()
     );
   };
 

   const ExcelMany = () => {
    let finalUrl = EXCEL_EXPORT_URL + "report/mcwc_monthly_form3_excel.php";
    let formstartDate = moment(props.formData.TransactionDate).format("YYYY-MM-DD");
    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&ReportedMonthId=" +
        props.formData.ReportedMonthId +
        "&ReportedYear=" +
        props.formData.ReportedYear +
        "&ItemCode=" +
        'CON002' +
        "&TransactionDate=" +
       formstartDate +
        "&FacilityId=" +
        FacilityId +
        "&TransactionId=" +
        props.formData.TransactionNo +
        "&TimeStamp=" +
        Date.now()
    );
  };
   /* =====End of Excel Export Code==== */
 
   
  let id = (props.addProductForm ? 0 : props.formData.TransactionNo);
  const { data:SettingItems,refetch:getSettingItems } = useQuery(['lmisform3settingitems', id], () =>
  api.getMCWCMonthlyForm3SettingItems(id).then((res) => {
    // console.log('SettingItems: ', res.data);
    
    // if(Object.keys(res.data).length>0){
    //   setToggleButton(res.data[0].ProdCode);
    // }
    
    // console.log("SupplyPlanSettingItems:",SupplyPlanSettingItems);
    // console.log("SupplyPlanSettingItems:",Object.keys(SupplyPlanSettingItems).length);
    setSupplyPlanSettingItems(res.data); 
    // console.log("res.data[0]",res.data[0].ProdCode); 
    
    return res.data
  }), {
  refetchOnWindowFocus: false,
  enabled: Boolean(props.formData.TransactionNo)
})



   const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;
      case "SOReasons":
        setOpenSOReason(false);
        break;
      case "SOReasonOpen":
        setOpenSOReason(true);
        break;

      default:
        break;
    }
  };

  
  const addSOReason = (selectedReasonName) => {
      // console.log("SOReason Data: ", selectedReasonName[0].SOReasonName);
      let cell = currentCellOnClick;
      const currow = cell.getRow();
      let currCol = cell.getColumn().getField();
      let curNewValue = selectedReasonName[0].SOReasonName;
      const calPushCell = currow.getCell(currCol);
      calPushCell.setValue(curNewValue);

      handleClose("SOReasons");
      api.dirtyModeEnable();
  };



  const addEligibilityReason = (selectedReasonName) => {
    // console.log("Eligibility Reason Data : ", selectedReasonName.ReasonName);
      let cell = currentCellOnClick;
      const currow = cell.getRow();
      let currCol = cell.getColumn().getField();
      let curNewValue = selectedReasonName[0].ReasonName;
      const calPushCell = currow.getCell(currCol);
      calPushCell.setValue(curNewValue);

      handleClose("Products");
      api.dirtyModeEnable();
  };



  const generateForm7Items = () => {
    props.generateForm7Items();
  };

  const generateForm7ItemsAndPost = () => {
    props.generateForm7ItemsAndPost();
  };

  // const generateIssueVoucher = () => {
  //   props.generateIssueVoucher();
  // }; 

  const editCheck = function(cell){

    // if(props.bStockUpdate>0){
    //   return false;
    // }
    
    // const rowData = cell._cell.row.data;
    // let Serial = rowData.Serial;

    // let OpeningSerial = 1;
    // let ReceiveSerial = 2;
    // let BalanceThisMonthSerial = 3;
    // let TotalThisMonthSerial = 6;
    // let ClosingSerial = 8;
    // let StockOutReasonSerial = 9;
    // let EligibleSerial = 11;
    // let EligibleReasonSerial = 12;

    // if(Serial === OpeningSerial || Serial === ReceiveSerial || Serial === BalanceThisMonthSerial 
    //    || Serial === TotalThisMonthSerial || Serial === ClosingSerial 
    //    || Serial === EligibleSerial || Serial === StockOutReasonSerial || Serial === EligibleReasonSerial){
    //   return false; 
    // }else{
    //   return true; 
      
    // }
  }
/*
  function editorType(cell, onRendered, success, cancel, editorParams){
   // console.log('cell editorType: ', cell);
    const rowData = cell._cell.row.data;
    // console.log('rowData: ', rowData);
    let Serial = rowData.Serial;
    // let currVal = rowData.Serial;
    // console.log('currVal: ', currVal);
    //console.log('Serial: ', Serial);
    let retEditType;

    //Stockout reason code
    if(Serial === 999){
      // var editorParams = [
      //   "1: Supply not Received",
      //   "2: Inadequate Supply",
      //   "3: Sudden Increase of Demand",
      //   "4: Others"
      // ];
      var editorParams = SoReasonList;
      let currVal = cell.getValue();
      retEditType = cellselectEditor(cell, onRendered, success, cancel, editorParams, currVal);
    }else if(Serial === 12){
      // var editorParams = [
      //   "NGO has no service provision",
      //   "NGO affiliation expired",
      //   "NGO has no trained person",
      //   " SDP not capable",
      //   "Absence of Female service provider",
      //   "Administrative reason"
      // ];
      
      var editorParams = ReasonNameList;
      let currVal = cell.getValue();
      retEditType = cellselectEditor(cell, onRendered, success, cancel, editorParams, currVal);
    }else{
      retEditType = cellFocusEditor(cell, onRendered, success, cancel, editorParams);
    }
    // retEditType = cellselectEditor(cell, onRendered, success, cancel, editorParams);
    //console.log('retEditType: ', retEditType);
    
    return retEditType;
  }
*/
  function setEligibleAndReasonsValue(cell){

    if(props.bStockUpdate>0){
      return;
    }


    const currow = cell.getRow();
    // console.log('currow: ', currow);
    const rowdata = currow.getData();
    // console.log('rowdata: ', rowdata);
    let Serial = rowdata.Serial;

    //Serial = 11 = Eligible
    if(Serial === 11){
      let currCol = cell.getColumn().getField();
      let value = cell.getValue();
      let curNewValue = (value==='Yes')?'No':'Yes';
      const calPushCell = currow.getCell(currCol);
      calPushCell.setValue(curNewValue);

    }else if(Serial === 9 || Serial === 12){
      //Serial = 9 = Stockout reason, 12= eligible reason
      setCurrentCellOnClick(cell);

      // const currow = cell.getRow();
      // const rowdata = currow.getData();
      // let Serial = rowdata.Serial;
      if(Serial === 9){
        // console.log("Stockoutreasonmodal");
        handleClose("SOReasonOpen");
      }else  if(Serial === 12){
        // console.log("Eligible reasonmodal");
        handleClose("ProductsOpen");
      }
    }



    //e - the click event object
    //cell - cell component
  }

  function closingBalanceAlert(){
    props.openNoticeModal({
      isOpen: true,
      msg: DispensingLanguage[lan][menukey]["Closing balance can not be less then zero"],
      msgtype: 0,
    }); 
  }

  function ClosingBalanceCellColor(Serial,value, cell){
    //Serial===6=closing balance

    // if((Serial===8) && (value<=0) && (value != null)){
    if((Serial===6) && (value<=0) && (value != null)){
      console.log('value: ', value);
      cell.getElement().style.backgroundColor = "#FF0000";
    }else{
      cell.getElement().style.backgroundColor = "transparent";
    }
  }

  function ColumnVisibilityAndName(ColumnSerialORItemCode, FuncType){
    let isVisible = false;
    let columnTitle = "";
    let productObj = {};
    // console.log('SupplyPlanSettingItems: ', SupplyPlanSettingItems);

    if(Object.keys(SupplyPlanSettingItems).length>0){
    
     //when ColumnSerial exist in SupplyPlanSettingItems then visible=true
      SupplyPlanSettingItems.forEach((ProdObj,idx)=>{
        
        //Item search by Serial or Itemcode
        if((ColumnSerialORItemCode === ProdObj.SL) || (ColumnSerialORItemCode === ProdObj.ProdCode)){
          isVisible = true;
          columnTitle = ProdObj.ProdText;
          productObj = ProdObj;
          // console.log('ColumnSerial: ', ColumnSerial);
          // console.log('ProdObj.SL: ', ProdObj.SL);
          // console.log('ProdText: ', ProdObj.ProdText);
        }

      });
    }
    // console.log('isVisible: ', isVisible);

    let retValue;
    if(FuncType === "visible"){
      retValue = isVisible;
    }else if(FuncType === "productsettings"){
      retValue = productObj;
    }else{
      retValue = columnTitle;

    }
    return retValue;

  }

  /*
function updateLinkedColumnInfo(cell){
        
  const currow = cell.getRow(); 
  // console.log('currow: ', currow);
  const rowdata = currow.getData();
  // console.log('rowdata: ', rowdata);
  let Serial = rowdata.Serial;

  //Serial = 11 = Eligible, 12 = Eligible Reason
  if(Serial === 11 || Serial === 12){

    let currColumnSerial = cell.getColumn()._column.field; //'V8';
    let value = cell.getValue();
    
    console.log('SupplyPlanSettingItems: ', SupplyPlanSettingItems);
    
    let currProductSettings = ColumnVisibilityAndName(currColumnSerial,'productsettings');
    console.log('currProductSettings: ', currProductSettings);


      //when + exist in ItemParent then it is linked item.
      //when + not available in ItemParent then indexOf return -1 othrewise return +index
      if(currProductSettings.ItemParent.indexOf("+") >=0){
        let currProdCode = currProductSettings.ProdCode;
        console.log('currProdCode: ', currProdCode);
        let parentItemCodeList = currProductSettings.ItemParent.split("+");
        
        // loop in linked items
        parentItemCodeList.forEach((LinkItemCode,i)=>{
          // console.log('LinkItemCode: ', LinkItemCode);
          //self cell no need update only will update linked others cells
          if(currProdCode != LinkItemCode){
            console.log('LinkItemCode: ', LinkItemCode);
            let linkedProductSettings = ColumnVisibilityAndName(LinkItemCode,'productsettings');
          //   {
          //     "ProdSerial": 88,
          //     "ProdCode": "CON005",
          //     "ProdText": "Implanon (Single Rod)",
          //     "SL": "V8",
          //     "ItemParent": "CON005+CON004"
          // }

            // let lonkedColIdx = 'V9';
            let linkedColumnSerial = linkedProductSettings.SL; //'V9';

            // console.log(" YES/NO currColIdx",  curTable[10].getData()[currColIdx]);
            // console.log(" YES/NO lonkedColIdx",  curTable[10].getData()[linkedColumnSerial]);

            let updateRowIdx;
            if(Serial === 11){
              updateRowIdx = bEntitledIdx; //bEntitledIdx=10
            }else if(Serial === 12){
              updateRowIdx = ReasonIdIdx; //bEntitledIdx=11
            }
          
            let EligibleRow = ReceiveDetailsRef.table.getRows()[updateRowIdx];

            const linkedEligibleCell= EligibleRow.getCell(linkedColumnSerial);
            linkedEligibleCell.setValue(value);

          }

        });

      }
    
  }
}
*/

  const ReceiveDetailsColumns = [
    // { title: "TransactionItemId", field: "TransactionItemId", visible: false },
    // { title: "id", field: "id", visible: false },
    // {
    //   title: DispensingLanguage[lan][menukey]["Sl#"],
    //   field: "ItemCode",
    //   width: 40,
    //   formatter: "rownum",
    //   hozAlign: "center",
    //   headerHozAlign: "center",
    //   headerSort: false,
    // },
    {
      title: "",//DispensingLanguage[lan][menukey]["Name of FWA"],
      field: "TransType",
      width: 160,
      // headerFilter: true,
      headerSort: false,
      frozen:true,
    },
    {
      // title: DispensingLanguage[lan][menukey]["Shukhi"],
      title: ColumnVisibilityAndName("V1","title"),
      field: "V1",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V1","visible"),
      editable: false,// editCheck,
      editor: "",//cellFocusEditor,
      validator: ["integer", "min:0"],
      // editor: editorType, 
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        // return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        // let bEntitled = rowdata.V1;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },
      // cellClick:function(e, cell){
      //   // console.log('cellClick: ', cell);
      //   setEligibleAndReasonsValue(cell);

      //   },
      /*
      cellEdited: function (cell) {

        if(ReceiveDetailsRef == null) return;
        //console.log('cell: ', cell);
        // let currVal = cell.getValue();
        // console.log('currVal: ', currVal);

        // let currow = cell.getRow();
        // console.log('currow: ', currow);
        // let rowdata = currow.getData();

        // let curTable = ReceiveDetailsRef.table.getRows();
        // let curTableSingleRow = curTable[4];
        // let rowdata = curTableSingleRow.getData();
        // let OpeningBalance = rowdata.V1;
        // console.log('OpeningBalance: ', OpeningBalance);

        // console.log('curTableSingleRow: ', curTableSingleRow);
        // const calPushCell1 = curTableSingleRow.getCell("V1");
        // calPushCell1.setValue(200);
        let curTable = ReceiveDetailsRef.table.getRows();
        // console.log('curTable: ', curTable);
        // console.log('curTable[0].getData(): ', curTable[0].getData());
        // console.log('curTable.length: ', curTable.length);

        // let dataList = [];
        // for(let i=0; i<curTable.length;i++){
        //   //console.log('row: ',i, curTable[i].getData());
        //   let row = curTable[i].getData();
        //   //console.log('row: ', row);

        //   for (const idx in row) {
        //     console.log('idx: ', idx);
        //     console.log('value: ', row[idx]);
        //     //dataList[i][idx] = row[idx];
        //   }

        //   // console.log('row.length: ', row.length);
        //   // row.forEach((value,idx)=>{
        //   //   //console.log('row: ',i, curTable[i].getData());
        //   //   dataList[i][idx] = value;
        //   // });

        // }
        // console.log('dataList: ', dataList);



        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V1;
        let Received = curTable[1].getData().V1;
        let AdjustmentPlus = curTable[3].getData().V1;
        let AdjustmentMinus = curTable[4].getData().V1;
        let Distribution = curTable[6].getData().V1;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        // let rowdataop = curTableSingleRowOP.getData();
        // let OpeningBalance = rowdataop.V1;
        // console.log('OpeningBalance: ', OpeningBalance);
        // console.log('Received: ', Received);
        // console.log('AdjustmentPlus: ', AdjustmentPlus);
        // console.log('AdjustmentMinus: ', AdjustmentMinus);
        // console.log('Distribution: ', Distribution);

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V1");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V1");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////

        if(calculateClosing<0){
          closingBalanceAlert();
        }

        // let curTableSingleRowAdjMinus = curTable[4];
        // let rowdataadjminus = curTableSingleRowAdjMinus.getData();
        // let AdjustmentMinus = rowdataadjminus.V1;
        // console.log('OpeningBalance: ', AdjustmentMinus);

        // console.log('curTableSingleRow: ', curTableSingleRowAdjMinus);
        // const calPushCell1 = curTableSingleRowAdjMinus.getCell("V1");
        // calPushCell1.setValue(200);

      },*/
    },
    {
      // title: DispensingLanguage[lan][menukey]["Shukhi (3rd Gen)"],
      title: ColumnVisibilityAndName("V2","title"),
      field: "V2",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V2","visible"),
      editable: false,//editCheck,
      editor:"",// editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        // return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        // let bEntitled = rowdata.V2;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
       // console.log('cellClick: ', cell);
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;

        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V2;
        let Received = curTable[1].getData().V2;
        let AdjustmentPlus = curTable[3].getData().V2;
        let AdjustmentMinus = curTable[4].getData().V2;
        let Distribution = curTable[6].getData().V2;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V2");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V2");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////

        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },
  */
    },
    {
      // title: DispensingLanguage[lan][menukey]["Oral Pill Apon"],
      title: ColumnVisibilityAndName("V3","title"),
      field: "V3",
      width: 75,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V3","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        // return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        // let bEntitled = rowdata.V3;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        //console.log('cellClick: ', cell);
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V3;
        let Received = curTable[1].getData().V3;
        let AdjustmentPlus = curTable[3].getData().V3;
        let AdjustmentMinus = curTable[4].getData().V3;
        let Distribution = curTable[6].getData().V3;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V3");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V3");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },

    {
      // title: DispensingLanguage[lan][menukey]["Condom"],
      title: ColumnVisibilityAndName("V4","title"),
      field: "V4",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V4","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V4;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
       // console.log('cellClick: ', cell);
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V4;
        let Received = curTable[1].getData().V4;
        let AdjustmentPlus = curTable[3].getData().V4;
        let AdjustmentMinus = curTable[4].getData().V4;
        let Distribution = curTable[6].getData().V4;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V4");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V4");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      // title: DispensingLanguage[lan][menukey]["Injectables (Vials)"],
      title: ColumnVisibilityAndName("V5","title"),
      field: "V5",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V5","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V5;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        //console.log('cellClick: ', cell);
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V5;
        let Received = curTable[1].getData().V5;
        let AdjustmentPlus = curTable[3].getData().V5;
        let AdjustmentMinus = curTable[4].getData().V5;
        let Distribution = curTable[6].getData().V5;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V5");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V5");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      // title: DispensingLanguage[lan][menukey]["AD Syringe (1ML)"],
      title: ColumnVisibilityAndName("V6","title"),
      field: "V6",
      width: 70,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V6","visible"),
      editable: false,//editCheck,
      editor:"",// editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V6;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
       // console.log('cellClick: ', cell);
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V6;
        let Received = curTable[1].getData().V6;
        let AdjustmentPlus = curTable[3].getData().V6;
        let AdjustmentMinus = curTable[4].getData().V6;
        let Distribution = curTable[6].getData().V6;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V6");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V6");
        calPushCellClosing.setValue(calculateClosing);

                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////

        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },

    {
      // title: DispensingLanguage[lan][menukey]["IUD"],
      title: ColumnVisibilityAndName("V7","title"),
      field: "V7",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V7","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V7;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V7;
        let Received = curTable[1].getData().V7;
        let AdjustmentPlus = curTable[3].getData().V7;
        let AdjustmentMinus = curTable[4].getData().V7;
        let Distribution = curTable[6].getData().V7;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V7");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V7");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    
    {
      // title: DispensingLanguage[lan][menukey]["Implanon (Single Rod)"],
      title: ColumnVisibilityAndName("V8","title"),
      field: "V8",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V8","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V8;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V8;
        let Received = curTable[1].getData().V8;
        let AdjustmentPlus = curTable[3].getData().V8;
        let AdjustmentMinus = curTable[4].getData().V8;
        let Distribution = curTable[6].getData().V8;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V8");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V8");
        calPushCellClosing.setValue(calculateClosing);



        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////

        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
   
    {
      // title: DispensingLanguage[lan][menukey]["Jadelle (2 Rod)"],
      title: ColumnVisibilityAndName("V9","title"),
      field: "V9",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V9","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V9;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V9;
        let Received = curTable[1].getData().V9;
        let AdjustmentPlus = curTable[3].getData().V9;
        let AdjustmentMinus = curTable[4].getData().V9;
        let Distribution = curTable[6].getData().V9;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V9");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V9");
        calPushCellClosing.setValue(calculateClosing);

        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      // title: DispensingLanguage[lan][menukey]["ECP"],
      title: ColumnVisibilityAndName("V10","title"),
      field: "V10",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V10","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V10;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V10;
        let Received = curTable[1].getData().V10;
        let AdjustmentPlus = curTable[3].getData().V10;
        let AdjustmentMinus = curTable[4].getData().V10;
        let Distribution = curTable[6].getData().V10;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V10");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V10");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      // title: DispensingLanguage[lan][menukey]["Tab. Misoprostol (Dose)"],
      title: ColumnVisibilityAndName("V11","title"),
      field: "V11",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V11","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V11;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V11;
        let Received = curTable[1].getData().V11;
        let AdjustmentPlus = curTable[3].getData().V11;
        let AdjustmentMinus = curTable[4].getData().V11;
        let Distribution = curTable[6].getData().V11;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V11");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V11");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      // title: DispensingLanguage[lan][menukey]["MRM (PACK)"],
      title: ColumnVisibilityAndName("V12","title"),
      field: "V12",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V12","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V12;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V12;
        let Received = curTable[1].getData().V12;
        let AdjustmentPlus = curTable[3].getData().V12;
        let AdjustmentMinus = curTable[4].getData().V12;
        let Distribution = curTable[6].getData().V12;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V12");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V12");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      // title: DispensingLanguage[lan][menukey]["7.1% CHLOROHEXIDINE"],
      title: ColumnVisibilityAndName("V13","title"),
      field: "V13",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V13","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V13;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V13;
        let Received = curTable[1].getData().V13;
        let AdjustmentPlus = curTable[3].getData().V13;
        let AdjustmentMinus = curTable[4].getData().V13;
        let Distribution = curTable[6].getData().V13;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V13");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V13");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      // title: DispensingLanguage[lan][menukey]["INJ. MgSo4"],
      title: ColumnVisibilityAndName("V14","title"),
      field: "V14",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V14","visible"),
      editable: false,//editCheck,
      editor:"",// editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V14;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V14;
        let Received = curTable[1].getData().V14;
        let AdjustmentPlus = curTable[3].getData().V14;
        let AdjustmentMinus = curTable[4].getData().V14;
        let Distribution = curTable[6].getData().V14;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V14");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V14");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      // title: DispensingLanguage[lan][menukey]["INJ. OXYTOCIN 5 IU/ML"],
      title: ColumnVisibilityAndName("V15","title"),
      field: "V15",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V15","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V15;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V15;
        let Received = curTable[1].getData().V15;
        let AdjustmentPlus = curTable[3].getData().V15;
        let AdjustmentMinus = curTable[4].getData().V15;
        let Distribution = curTable[6].getData().V15;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V15");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V15");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      // title: DispensingLanguage[lan][menukey]["MNP (SUSSET)"],
      title: ColumnVisibilityAndName("V16","title"),
      field: "V16",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V16","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V16;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V16;
        let Received = curTable[1].getData().V16;
        let AdjustmentPlus = curTable[3].getData().V16;
        let AdjustmentMinus = curTable[4].getData().V16;
        let Distribution = curTable[6].getData().V16;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V16");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V16");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      // title: DispensingLanguage[lan][menukey]["Sanatary Napkin/Pad"],
      title: ColumnVisibilityAndName("V17","title"),
      field: "V17",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V17","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V17;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V17;
        let Received = curTable[1].getData().V17;
        let AdjustmentPlus = curTable[3].getData().V17;
        let AdjustmentMinus = curTable[4].getData().V17;
        let Distribution = curTable[6].getData().V17;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V17");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V17");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      // title: DispensingLanguage[lan][menukey]["MR(MVA) KIT (NOS)"],
      title: ColumnVisibilityAndName("V18","title"),
      field: "V18",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V18","visible"),
      editable:false,// editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V18;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V18;
        let Received = curTable[1].getData().V18;
        let AdjustmentPlus = curTable[3].getData().V18;
        let AdjustmentMinus = curTable[4].getData().V18;
        let Distribution = curTable[6].getData().V18;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V18");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V18");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      // title: DispensingLanguage[lan][menukey]["Normal Delivery Kit"],
      title: ColumnVisibilityAndName("V19","title"),
      field: "V19",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V19","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V19;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V19;
        let Received = curTable[1].getData().V19;
        let AdjustmentPlus = curTable[3].getData().V19;
        let AdjustmentMinus = curTable[4].getData().V19;
        let Distribution = curTable[6].getData().V19;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V19");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V19");
        calPushCellClosing.setValue(calculateClosing);

                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////

        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      // title: DispensingLanguage[lan][menukey]["DDS KIT"],
      title: ColumnVisibilityAndName("V20","title"),
      field: "V20",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V20","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V20;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V20;
        let Received = curTable[1].getData().V20;
        let AdjustmentPlus = curTable[3].getData().V20;
        let AdjustmentMinus = curTable[4].getData().V20;
        let Distribution = curTable[6].getData().V20;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V20");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V20");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      // title: DispensingLanguage[lan][menukey]["Inj. Antenatal Corticosteroid"],
      title: ColumnVisibilityAndName("V21","title"),
      field: "V21",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V21","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V21;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V21;
        let Received = curTable[1].getData().V21;
        let AdjustmentPlus = curTable[3].getData().V21;
        let AdjustmentMinus = curTable[4].getData().V21;
        let Distribution = curTable[6].getData().V21;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V21");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V21");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      // title: DispensingLanguage[lan][menukey]["Inj. Gantamicin"],
      title: ColumnVisibilityAndName("V22","title"),
      field: "V22",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V22","visible"),
      editable: false,//editCheck,
      editor:"",// editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V22;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V22;
        let Received = curTable[1].getData().V22;
        let AdjustmentPlus = curTable[3].getData().V22;
        let AdjustmentMinus = curTable[4].getData().V22;
        let Distribution = curTable[6].getData().V22;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V22");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V22");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      // title: DispensingLanguage[lan][menukey]["Lungee (pcs)"],
      title: ColumnVisibilityAndName("V23","title"),
      field: "V23",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V23","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V23;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V23;
        let Received = curTable[1].getData().V23;
        let AdjustmentPlus = curTable[3].getData().V23;
        let AdjustmentMinus = curTable[4].getData().V23;
        let Distribution = curTable[6].getData().V23;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V23");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V23");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      // title: DispensingLanguage[lan][menukey]["Saree (pcs)"],
      title: ColumnVisibilityAndName("V24","title"),
      field: "V24",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V24","visible"),
      editable: false,//editCheck,
      editor:"",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V24;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },
      // cellClick:function(e, cell){
      //   setEligibleAndReasonsValue(cell);
      // },
      // cellEdited: function (cell) {
      //   if(ReceiveDetailsRef == null) return;
      //   let curTable = ReceiveDetailsRef.table.getRows();

      //   //index start from 0,1,2,3.....
      //   let OpeningBalance = curTable[0].getData().V24;
      //   let Received = curTable[1].getData().V24;
      //   let AdjustmentPlus = curTable[3].getData().V24;
      //   let AdjustmentMinus = curTable[4].getData().V24;
      //   let Distribution = curTable[6].getData().V24;

      //   if(OpeningBalance === "" || OpeningBalance === null){
      //     OpeningBalance=0;
      //   }
      //   if(Received === "" || Received === null){
      //     Received=0;
      //   }
      //   if(AdjustmentPlus === "" || AdjustmentPlus === null){
      //     AdjustmentPlus=0;
      //   }
      //   if(AdjustmentMinus === "" || AdjustmentMinus === null){
      //     AdjustmentMinus=0;
      //   }
      //   if(Distribution === "" || Distribution === null){
      //     Distribution=0;
      //   }

      //   let calculateTotalThisMonth = parseInt(OpeningBalance)+
      //   parseInt(Received) +
      //   parseInt(AdjustmentPlus) -
      //   parseInt(AdjustmentMinus);
      //   let totalThisMonthRow = curTable[5];
      //   const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V24");
      //   calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


      //   let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
      //   let totalClosingRow = curTable[7];
      //   const calPushCellClosing= totalClosingRow.getCell("V24");
      //   calPushCellClosing.setValue(calculateClosing);
                
      //   ////////////////////////////////////////////////////////////////////
      //   updateLinkedColumnInfo(cell);
      //   ////////////////////////////////////////////////////////////////////


      //   if(calculateClosing<0){
      //     closingBalanceAlert();
      //   }

      // },
    },








/* Extra column for future */
 /*//////////////////35 column fixed in tabulator start//////////////////////////////////////////////*/
    {
      title: ColumnVisibilityAndName("V25","title"),
      field: "V25",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V25","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V24;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V25;
        let Received = curTable[1].getData().V25;
        let AdjustmentPlus = curTable[3].getData().V25;
        let AdjustmentMinus = curTable[4].getData().V25;
        let Distribution = curTable[6].getData().V25;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V25");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V25");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      title: ColumnVisibilityAndName("V26","title"),
      field: "V26",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V26","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V24;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V26;
        let Received = curTable[1].getData().V26;
        let AdjustmentPlus = curTable[3].getData().V26;
        let AdjustmentMinus = curTable[4].getData().V26;
        let Distribution = curTable[6].getData().V26;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V26");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V26");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      title: ColumnVisibilityAndName("V27","title"),
      field: "V27",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V27","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V24;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V27;
        let Received = curTable[1].getData().V27;
        let AdjustmentPlus = curTable[3].getData().V27;
        let AdjustmentMinus = curTable[4].getData().V27;
        let Distribution = curTable[6].getData().V27;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V27");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V27");
        calPushCellClosing.setValue(calculateClosing);
        
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      title: ColumnVisibilityAndName("V28","title"),
      field: "V28",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V28","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V24;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V28;
        let Received = curTable[1].getData().V28;
        let AdjustmentPlus = curTable[3].getData().V28;
        let AdjustmentMinus = curTable[4].getData().V28;
        let Distribution = curTable[6].getData().V28;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V28");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V28");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      title: ColumnVisibilityAndName("V29","title"),
      field: "V29",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V29","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V24;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V29;
        let Received = curTable[1].getData().V29;
        let AdjustmentPlus = curTable[3].getData().V29;
        let AdjustmentMinus = curTable[4].getData().V29;
        let Distribution = curTable[6].getData().V29;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V29");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V29");
        calPushCellClosing.setValue(calculateClosing);
        
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      title: ColumnVisibilityAndName("V30","title"),
      field: "V30",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V30","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V24;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V30;
        let Received = curTable[1].getData().V30;
        let AdjustmentPlus = curTable[3].getData().V30;
        let AdjustmentMinus = curTable[4].getData().V30;
        let Distribution = curTable[6].getData().V30;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V30");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V30");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      title: ColumnVisibilityAndName("V31","title"),
      field: "V31",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V31","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V24;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V31;
        let Received = curTable[1].getData().V31;
        let AdjustmentPlus = curTable[3].getData().V31;
        let AdjustmentMinus = curTable[4].getData().V31;
        let Distribution = curTable[6].getData().V31;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V31");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V31");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      title: ColumnVisibilityAndName("V32","title"),
      field: "V32",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V32","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V24;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V32;
        let Received = curTable[1].getData().V32;
        let AdjustmentPlus = curTable[3].getData().V32;
        let AdjustmentMinus = curTable[4].getData().V32;
        let Distribution = curTable[6].getData().V32;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V32");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V32");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      title: ColumnVisibilityAndName("V33","title"),
      field: "V33",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V33","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V24;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V33;
        let Received = curTable[1].getData().V33;
        let AdjustmentPlus = curTable[3].getData().V33;
        let AdjustmentMinus = curTable[4].getData().V33;
        let Distribution = curTable[6].getData().V33;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V33");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V33");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      // title: DispensingLanguage[lan][menukey]["Saree (pcs)"],
      title: ColumnVisibilityAndName("V34","title"),
      field: "V34",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      headerSort: false,
      visible: ColumnVisibilityAndName("V34","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V24;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V34;
        let Received = curTable[1].getData().V34;
        let AdjustmentPlus = curTable[3].getData().V34;
        let AdjustmentMinus = curTable[4].getData().V34;
        let Distribution = curTable[6].getData().V34;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V34");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V34");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
    {
      title: ColumnVisibilityAndName("V35","title"),
      field: "V35",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      // bottomCalc: "sum",
      visible: ColumnVisibilityAndName("V35","visible"),
      editable: false,//editCheck,
      editor: "",//editorType, 
      validator: ["integer", "min:0"],
      // bottomCalcFormatter: "money",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        //return value == 0 ? "" : value;
        
        const currow = cell.getRow();
        const rowdata = currow.getData();
        let Serial = rowdata.Serial;
        let bEntitled = rowdata.V24;

        // if(Serial === 11 && bEntitled === "No"){
        //   //when Eligibility = No then fontcolor is red
        //   return "<span style='color:#F72807;'>" + value + "</span>";
        // }else{
          ClosingBalanceCellColor(Serial,value, cell);
          return value === 0 ? "" : value;
        // }

      },/*
      cellClick:function(e, cell){
        setEligibleAndReasonsValue(cell);
      },
      cellEdited: function (cell) {
        if(ReceiveDetailsRef == null) return;
        let curTable = ReceiveDetailsRef.table.getRows();

        //index start from 0,1,2,3.....
        let OpeningBalance = curTable[0].getData().V35;
        let Received = curTable[1].getData().V35;
        let AdjustmentPlus = curTable[3].getData().V35;
        let AdjustmentMinus = curTable[4].getData().V35;
        let Distribution = curTable[6].getData().V35;

        if(OpeningBalance === "" || OpeningBalance === null){
          OpeningBalance=0;
        }
        if(Received === "" || Received === null){
          Received=0;
        }
        if(AdjustmentPlus === "" || AdjustmentPlus === null){
          AdjustmentPlus=0;
        }
        if(AdjustmentMinus === "" || AdjustmentMinus === null){
          AdjustmentMinus=0;
        }
        if(Distribution === "" || Distribution === null){
          Distribution=0;
        }

        let calculateTotalThisMonth = parseInt(OpeningBalance)+
        parseInt(Received) +
        parseInt(AdjustmentPlus) -
        parseInt(AdjustmentMinus);
        let totalThisMonthRow = curTable[5];
        const calPushCellTotalThisMonth= totalThisMonthRow.getCell("V35");
        calPushCellTotalThisMonth.setValue(calculateTotalThisMonth);


        let calculateClosing = parseInt(calculateTotalThisMonth)-parseInt(Distribution);
        let totalClosingRow = curTable[7];
        const calPushCellClosing= totalClosingRow.getCell("V35");
        calPushCellClosing.setValue(calculateClosing);
                
        ////////////////////////////////////////////////////////////////////
        updateLinkedColumnInfo(cell);
        ////////////////////////////////////////////////////////////////////


        if(calculateClosing<0){
          closingBalanceAlert();
        }

      },*/
    },
     //////////////////35 column fixed in tabulator start//////////////////////////////////////////////



 
  ];

  const onSaveUpdateBtnClick = () => {

    var tableMain = ReceiveDetailsRef.table;
    var tableData = tableMain.getData();
    console.log('tableData: ', tableData);
    console.log('tableMain.columnManager.columnsByField: ', tableMain.columnManager.columnsByField);

    let rowValidateFlag = 0;
    let errorRow = {};
    let productName = "";

  //   let rowSODaysReason = tableData[8];
  //   let rowSODays = tableData[9];
  //   // console.log('rowSODays: ', rowSODays);
  //   // console.log('rowSODaysReason: ', rowSODaysReason);

  //   for (const key in rowSODays) {
  //     if (rowSODays.hasOwnProperty(key)) {
  //       let SODay = rowSODays[key];
  //       let SODayReason = rowSODaysReason[key];
  //       if((SODay>0) && (SODayReason==null)){
  //         rowValidateFlag = 1;
  //         productName = tableMain.columnManager.columnsByField[key].definition.title;
  //       }
  //       // console.log(`${key}: ${rowSODays[key]}`);
  //       // console.log(`${key}: ${rowSODaysReason[key]}`);
  //     }
  //   }


  // if(rowValidateFlag===0){
  //     let rowEligibleReason = tableData[11];
  //     let rowEligible = tableData[10];
  //     console.log('rowEligible: ', rowEligible);
  //     console.log('rowEligibleReason: ', rowEligibleReason);

  //     // let entries = Object.values(rowEligible);
  //     // console.log('entries: ', entries);

  //     for (const key in rowEligible) {
  //       if (rowEligible.hasOwnProperty(key)) {
  //         let Eligible = rowEligible[key];
  //         let EligibleReason = rowEligibleReason[key];
  //         if((Eligible === 'No') && (EligibleReason==null)){
  //           rowValidateFlag = 2;
  //           productName = tableMain.columnManager.columnsByField[key].definition.title;
  //         }
  //         // console.log(`${key}: ${rowEligible[key]}`);
  //         // console.log(`${key}: ${rowEligibleReason[key]}`);
  //       }
  //     }
  //   }


  //check any closing balance is less than zero
    if(rowValidateFlag===0){
      let rowClosing = tableData[7];
      console.log('rowClosing: ', rowClosing);
      for (const key in rowClosing) {
        if (rowClosing.hasOwnProperty(key)) {
          let ClosingBalance = rowClosing[key];
          if((ClosingBalance < 0) && (rowValidateFlag==0)){
            rowValidateFlag = 3;
            productName = tableMain.columnManager.columnsByField[key].definition.title;
          }
        }
      }
    }




    console.log('rowValidateFlag: ', rowValidateFlag);
  

    console.log('rowValidateFlag: ', rowValidateFlag);
    if(rowValidateFlag==0){
      props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData());
    }else{
      console.log("Error:",errorRow);
      let errorText = "";
      
      if(rowValidateFlag == 3){
        errorText = DispensingLanguage[lan][menukey]["closing balance is negative"];
      }
      // else if(rowValidateFlag == 1){
      //   errorText = DispensingLanguage[lan][menukey]["SO reason is missing"];
      // }else if(rowValidateFlag == 2){
      //   errorText = DispensingLanguage[lan][menukey]["non-eligibility reason is missing"];
      // }
      props.openNoticeModal({
        isOpen: true,
        msg: productName +' '+ errorText,
        msgtype: 0,
      });
    }


    
 
  };




  

//   const onSavePostBtnClick = async (e) => {

//    console.log('onSavePostBtnClick : ', props.formData);

// // Start Post Alert
  
//     swal({
//         title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
//     text: t(DispensingLanguage[lan][menukey]["Do you really want to post ?"]),
//     icon: "warning",
//     buttons: {
//       confirm: {
//         text: t(DispensingLanguage[lan][menukey]["Yes"]),
//         value: true,
//         visible: true,
//         className: "",
//         closeModal: true,
//       },
//       cancel: {
//         text: t(DispensingLanguage[lan][menukey]["No"]),
//         value: null,
//         visible: true,
//         className: "",
//         closeModal: true,
//       },
//     },

// }).then((willAction) => {
//   if (willAction) {
//     props.handlePost();
//    /* mutateSubmitToUpazila(props.formData); */
  
//   }else{
   
    
//   }
// });  


// }

  const onUnPostBtnClick = async (e) => {

    swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
    text: t(DispensingLanguage[lan][menukey]["Do you really want to unpost ?"]),
    icon: "warning",
    buttons: {
      confirm: {
        text: t(DispensingLanguage[lan][menukey]["Yes"]),
        value: true,
        visible: true,
        className: "",
        closeModal: true,
      },
      cancel: {
        text: t(DispensingLanguage[lan][menukey]["No"]),
        value: null,
        visible: true,
        className: "",
        closeModal: true,
      },
    },

}).then((willAction) => {
  if (willAction) {

   /* mutateUnpost(props.formData); */
   props.handleUnPost();
  
  }else{
   
    
  }
});  


}



/* 
const { isLoading, mutate:mutateSubmitToUpazila } = useMutation(api.MCWCMonthlyForm3SubmitToUpazila, {
  onSuccess: (data) => {
 
    if (data.status == 200) {

        setbStockUpdate(1);
        props.reFetchItems();

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
  
    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
      
    }
  }
}) */

/* 
const { isLoading5, mutate:mutateUnpost } = useMutation(api.MCWCMonthlyForm3Unpost, {
  onSuccess: (data) => {
 
    if (data.status == 200) {

        setbStockUpdate(0);
        props.reFetchItems();

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
  
    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
      
    }
  }
})
 */



  const deleteReceiveInv = (data) => {
    let newarray = manyTableData.filter((element) => element !== data);
    setManyTableData(newarray);
    props.deleteReceiveInv(data);
  };

  const CopyBatch = () => {
    props.handleAdd(manyTableData);
  };

  function ActionButton(props) {
    const cellValue = props.cell._cell.value;
    // console.log('cellValue: ', cellValue);
    if (cellValue)
      return (
        <>
          <i class="fa fa-lock"></i>
        </>
      );
    else return <></>;

  }

  useEffect(() => {
    if (ReceiveDetailsRef == null) return;
    if (props.bFirst) {
      setManyTableData(props.ManyTableData);
    }

    setbStockUpdate(props.bStockUpdate);
  }, [props.ManyTableData, props.bStockUpdate]); //

  // useEffect(() => {
  //   if (ReceiveDetailsRef.table != null && timeStamp != "") {
  //     // console.log("Many table", ReceiveDetailsRef.table);
  //     // console.log("Many table 12222222222");

  //     ReceiveDetailsRef.table.scrollToRow(timeStamp, "center", true);
  //     ReceiveDetailsRef.table.selectRow(timeStamp);
  //   }
  // }, [newBatchRowAdded]);

  const handleReset = () => {
    props.handleReset();
    setManyTableData([]);
  };

  const backtolist=()=>{


    let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
    let idx = master_dirty_message_class.indexOf("dnone");
    if(idx === -1){
      // start Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          props.history.push("/mcwc-monthly-form3");
        }
      });
      // End Alert
    }else{
      props.history.push("/mcwc-monthly-form3");
    }

  }

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["MCWC Monthly Form 3 - Add/Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {props.addProductForm ? (
                  <Grid item xs={12} className=" text-center">
                    <div className="float-right sw_btn_control">
                      <Button
                       disabled={props.bStockUpdate}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button>
                      <Button
                       disabled={props.bStockUpdate}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onSaveUpdateBtnClick} //() => props.handleSubmit()
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        // onClick={() => props.history.push("/mcwc-monthly-form3")}
                        onClick={() => backtolist()}

                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                      
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="mt-2 text-center">

                    <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      // onClick={onSavePostBtnClick}
                      onClick={() => generateForm7ItemsAndPost()}

                    >
                      {DispensingLanguage[lan][menukey]["Post"]}
                    </Button> 

                    <Button
                      /* disabled={!props.bStockUpdate || permissionType} */
                      disabled={!props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onUnPostBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["Unpost"]}
                    </Button>


                    {/* <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button> */}

                    {/* <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onPostBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["post"]}
                    </Button> */}
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      // onClick={() => props.history.push("/mcwc-monthly-form3")}
                      onClick={() => backtolist()}

                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>

                     <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PrintMany()}
                    >
                      <i class="fas fa-print"></i>
                    </Button> 

                     <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelMany()}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button>
                  </Grid>
                )}
              </div>

              
              <div className="mr-4 float-right mt-15">
                <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
              </div>


            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>

                  <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                    
                      <Autocomplete
                        autoHighlight
                        disableClearable
                        disabled={props.bStockUpdate || manyTableData.length>0}
                        id="ReportedMonthId"
                        options={MonthList}
                        onChange={(event, valueobj) => handleChangeChoosen('ReportedMonthId', valueobj, valueobj?valueobj.id:"")}
                        getOptionLabel={(option) => option.name}
                        value={chosenValues['ReportedMonthId']}
                        renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          value={props.formData.ReportedMonthId}
                          label={DispensingLanguage[lan][menukey]["Report Month"]+ " *"}
                          variant="standard"
                          error={props.errorObject.ReportedMonthId}
                          helperText={props.errorObject.ReportedMonthId}
                          id="ReportedMonthId"
                          name="ReportedMonthId"
                          fullWidth
                        />
                        )}
                      />

                    </FormControl>
                  </Grid>

                  <Grid item xs={2} sm={2}>
                    <TextField
                      // disabled={true}
                      // error={props.errorObject.TotalWorkers}
                      // helperText={props.errorObject.TotalWorkers}
                      required
                      //disabled={props.bStockUpdate || manyTableData.length>0}
                      disabled={manyTableData.length > 0 ? true : props.bStockUpdate}
                      id="ReportedYear"
                      name="ReportedYear"
                      label={DispensingLanguage[lan][menukey]["Reported Year"] } 
                      value={props.formData.ReportedYear}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>


                  <Grid item xs={2} sm={2}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={true}
                        required
                        error={props.errorObject.TransactionDate}
                        helperText={props.errorObject.TransactionDate}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        id="TransactionDate"
                        label={DispensingLanguage[lan][menukey]["Entry Date"]}
                        autoOk={true}
                        name="TransactionDate"
                        fullWidth
                        showTodayButton={true}
                        disableFuture={true}
                        value={
                          props.formData.TransactionDate || props.selectedDate
                        }
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "TransactionDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  

                  {/* <Grid item xs={5} sm={5}>
                    <TextField
                      disabled={props.bStockUpdate}
                      id="Remarks"
                      name="Remarks"
                      label={DispensingLanguage[lan][menukey]["Remarks"]}
                      value={props.formData.Remarks}
                      fullWidth
                      multiline
                      rows={3}
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid> */}

                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="TransactionId"
                      name="TransactionId"
                      label="TransactionId"
                      value={props.formData.TransactionNo}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                {/* new row */}
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card">
                    <CardContent>

                      <Grid item xs={12} sm={12}>
                        <ReactTabulator
                          ref={(r) => (ReceiveDetailsRef = r)}
                          columns={ReceiveDetailsColumns}
                          rowFormatter={function (row) {
                            var data = row.getData();
                            //console.log('data: ', data);
                            /*
                            if (data.Serial === 1 || data.Serial === 2 || data.Serial === 3 || data.Serial === 6 || data.Serial === 8) {
                              row.getElement().style.backgroundColor = "#D9FCFF";
                              //row.getElement().style.color = "#EB280A";
                              //row.getElement().style.fontWeight = "bold";
                            }*/
                          }}
                          data={manyTableData}
                          height="300px"
                          // minHeight="350px"
                          layout={"fitColumns"}
                        />
                      </Grid>
 
                      <Grid item xs={12} sm={12}>
                        <div
                          className="float-rights sw_btns_control"
                          style={{ padding: "5px 0px 5px 0px" }}
                        >
                          
                          
                          <Button
                            disabled={
                              props.bStockUpdate ||
                              (props.addProductForm ? true : false)
                            }
                            className="mr-2"
                            variant="contained"
                            color="primary"
                            onClick={() => generateForm7Items()}
                          >
                            {DispensingLanguage[lan][menukey]["Generate"]}
                          </Button>
                          <Button
                            disabled={
                              props.bStockUpdate ||
                              (props.addProductForm ? true : false)
                            }
                            className="mr-2"
                            variant="contained"
                            color="secondary"
                            onClick={() => props.deleteForm7Items()}
                          >
                            {DispensingLanguage[lan][menukey]["Delete"]}
                          </Button>
                          
                          {/* {props.formData.CreationDate &&
                            <  >
                              {DispensingLanguage[lan][menukey]["Stock as on"] +" "+ moment(props.formData.CreationDate).format("DD/MM/YYYY") +": "+ getItemStock(props.formData.StockJson) }
                            </ >
                          } */}
                        
                        </div>

                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
 
 
 
                <SOReasonModal
                  handleClose={handleClose}
                  open={openSOReason}
                  addSOReason={addSOReason}
                  {...props}
                />

               <EligibilityReasonModal
                  handleClose={handleClose}
                  open={open}
                  addEligibilityReason={addEligibilityReason}
                  {...props}
                />

 

                {/* new row */}
              </Grid>
            </Grid>

            {/* </CardContent>
          </Card> */}
          </Grid>

          {/* Action buttons */}
        </Grid>
      </div>
    </div>
  );
};

export default MCWCMonthlyForm3FormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
  paddingTop: {
    paddingTop: "15px",
  },
});