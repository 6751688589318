import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  FormControlLabel,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  MenuItem,
  Card,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import CloseIcon from "@material-ui/icons/Close";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import moment from "moment";

//get DispensingLanguage
const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "indent";



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


const PickIndentModal = ({ handleClose, openOrder, addOrder, OrderingFrom, TransactionId, gStoreId, ...props }) => {

  const OrderTo = JSON.parse(localStorage.getItem("OrderTo"));
  const UpazilaList = JSON.parse(localStorage.getItem("UpazilaList"));
  const classes = useStyles();
  const [toggleButtonValue, setToggleButton] = React.useState("1");
  const [currFacilityId, setCurrFacilityId] = useState("");
  const [currIndentNo, setCurrIndentNo] = useState("");
  //const [currFacilityType, setCurrFacilityType] = useState(1);
  
  
//====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "Warehouse": {"id": "0","name": ""},
  "Upazila": {"id": "0","name": ""}
 
});



const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;
  setChosenValues(chosenValuesData); 

  setCurrFacilityId(value);


};

//====== End Choosen Dropdown===========


  
  const handleChangeToggle = (event, tmpToggleButtonValue) => {
    if (tmpToggleButtonValue) {
      //console.log(tmpToggleButtonValue);
      setToggleButton(tmpToggleButtonValue);
      setCurrFacilityId("");
      setCurrIndentNo("");

      setChosenValues({
        "Warehouse": {"id": "0","name": ""},
        "Upazila": {"id": "0","name": ""}
       }); 

    }
  };


  const handleFacilityChange = (event) => {
    setCurrFacilityId(event.target.value);
  };

  const handleIndentNoChange = (event) => {
    setCurrIndentNo(event.target.value);
  };
/*   const showHideFacilityCombo = (val) => {
    setCurrFacilityType(val);
    setCurrFacilityId(0);
  };
 */

  let tblOrderRef = null;

  const [dataItems, setDataItems] = useState([]);
  const FacilityId = localStorage.getItem("FacilityId");
  const StoreId = localStorage.getItem("StoreId");

  //const FacilityId = JSON.parse(localStorage.getItem("getCentralWarehouseId"));
  const UsersListByFacility = JSON.parse(localStorage.getItem("UsersListByFacility"));

  const [GeneratedTransactionNo, setTransactionNo] = useState();

  // let params1 = { "menukey": "issuevoucherindent", "FacilityId": FacilityId, "TransactionTypeId": 2, TransactionDate: '', TableName: 'indent' };
  
  // const { data: invoiceNo } = useQuery([params1], api.getInvoiceNo,
  //   {
  //     onSuccess: (data) => {
  //       setTransactionNo(data);
  //     },
  //     refetchOnWindowFocus: false,
  //     refetchOnmount: false,
  //     refetchOnReconnect: false,
  //     retry: false,
  //     staleTime: 0,//1000 * 60 * 60 * 24,
  //   }
  // );

  // let params = {
  //   menukey: menukey,
  //   action: "getPickIndentList",
  //   page_limit: JSON.stringify(100),
  //   page_number: JSON.stringify(0 + 1),
  //   search_key: "",
  //   lan: lan
  // };

  // const { error, isError, data, refetch } = useQuery(
  //   [params],
  //   api.getPickIndentList, {
  //   onSuccess: (data) => {
  //     setDataItems(data);
  //   },
  //   refetchOnWindowFocus: false,
  //   refetchOnmount: false,
  //   refetchOnReconnect: false,
  //   retry: false,
  //   staleTime: 0,//1000 * 60 * 60 * 24,
  // }
  // );

  /*   const PickIndentModalColumns = [
      { title: "id", field: "id", visible: false },
      { title: "IndentNo", field: "IndentNo", visible: false },
      { title: "transferFacilityCode", field: "transferFacilityCode", visible: false },
      { title: DispensingLanguage[lan][menukey]['Sl#'],  formatter: "rownum", width:50, hozAlign: "center" , headerHozAlign:"center", headerSort:false},
      { title: DispensingLanguage[lan][menukey]['Indent No'], field: "TransactionNo", width: 120, headerFilter:true },
      { title: DispensingLanguage[lan][menukey]['Invoice Date'], field: "TransactionDate", width: 180, headerFilter:true  },
      { title: DispensingLanguage[lan][menukey]['Indentor'], field: "FacilityName" , headerFilter:true},
    ];  */

  const { mutate } = useMutation(api.insertUpdateIssueIndentPick, {
    onSuccess: (data) => {
    
      if(data.success==0) {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        
      }else{
        addOrder(data);
        setCurrFacilityId("");
        setCurrIndentNo("");
      }

      
      
    }
  })
  const SaveSelectedOrder = () => {

    /* console.log("Current Facility ID ============= >> ",currFacilityId, "currIndentNo ======== >>", currIndentNo);
    return; */
    /* let PreparedBy = '';
    if(UsersListByFacility.length>0){
      PreparedBy = UsersListByFacility[0].id;
    }
 */
    //if(tblOrderRef.table.getSelectedData().length>0)


    if(currFacilityId =="" && currIndentNo =="") {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Warehouse/Upazila and indent no can not be blank"],
        msgtype: 0,
      });
    
    }else if(currFacilityId=="") {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Warehouse/Upazila can not be blank"],
        msgtype: 0,
      });
    
    }else if(currIndentNo=="") {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Indent no can not be blank"],
        msgtype: 0,
      });
    
    }

    if ((currFacilityId != "") && (currIndentNo != ""))
      mutate({
       
        FacilityId: FacilityId,
        TransferFacilityCode: currFacilityId,
        IndentNo: currIndentNo,
        TransactionTypeId: 2,
        currFacilityType: toggleButtonValue,
        language: lan
      });

  };


  useEffect(() => {
    if (tblOrderRef == null) return;


  }, []);

  return (
    <div>

      <BootstrapDialog
        onClose={() => handleClose('PickIndentModal')}
        aria-labelledby="customized-dialog-title"
        open={openOrder}
        fullWidth={true}
        maxWidth="xs"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('PickIndentModal')}
        >

          {DispensingLanguage[lan][menukey]['Select Indent']}

        </BootstrapDialogTitle>
        <DialogContent dividers>
          {/* <ReactTabulator ref={(r) => (tblOrderRef = r)} 
           selectable={1} 
           columns={PickIndentModalColumns}
           data={dataItems}
           height="300px" 
          /> */}

          <Grid container spacing={3}>
            <Grid item xs={2} sm={2}></Grid>
            <Grid item xs={8} sm={8}>

              <div className="tp-upload-box marginTopBottom">
                <div className="tp-header">
                  <Grid item xs={12} className="mt-2 text-center">

                  <ToggleButtonGroup
                      className="sw_ToggleButtonGroup"
                      color="primary"
                      value={toggleButtonValue}
                      exclusive
                      onChange={handleChangeToggle}
                      
                    >
                      <ToggleButton 
                        value="1"
                      >
                      {DispensingLanguage[lan][menukey]['Warehouse']}
                      </ToggleButton>
                      <ToggleButton
                        value="2"
                      >
                      {DispensingLanguage[lan][menukey]['Upazila']}
                      </ToggleButton>

                    </ToggleButtonGroup>
                    
                    {/* <Button
                      className="mr-2"
                      variant="contained"
                      color={currFacilityType == "1" ? "primary" : "info"}
                      onClick={() => showHideFacilityCombo(1)}
                    >
                      {DispensingLanguage[lan][menukey]["Warehouse"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      color={currFacilityType == "2" ? "primary" : "info"}
                      onClick={() => showHideFacilityCombo(2)}
                    >
                      {DispensingLanguage[lan][menukey]["Upazila"]}
                    </Button> */}


                  </Grid>

                </div>
              </div>
            </Grid>

            <Grid item xs={2} sm={2}></Grid>
            {/* Start warehouse dropdown list */}
            <Grid item xs={2} sm={2}></Grid>
            <Grid item xs={8} sm={8}>

              {
                toggleButtonValue == "1" ?
                  <FormControl className={classes.fullWidth}>
                    {/* <InputLabel id="demo-simple-select-helper-label-receive-from">
                      {DispensingLanguage[lan][menukey]["Warehouse"] +"*"}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label-receive-from"
                      id="Warehouse"
                      name="Warehouse"
                      visible={false}
                      value={currFacilityId}
                      fullWidth
                      onChange={handleFacilityChange}
                    >
                      {OrderTo.map((item, index) => {
                        return (
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                        );
                      })}
                    </Select> */}

                      <Autocomplete
                        autoHighlight
                        disableClearable
                        id="Warehouse"
                        options={OrderTo}
                        onChange={(event, valueobj) => handleChangeChoosen('Warehouse', valueobj, valueobj?valueobj.id:"")}
                        getOptionLabel={(option) => option.name}
                        value={chosenValues['Warehouse']}
                        renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          //value={props.formData.Warehouse}
                          label={DispensingLanguage[lan][menukey]["Warehouse"]+ "*"}
                          variant="standard"
                          //error={props.errorObject.Warehouse}
                          //helperText={props.errorObject.Warehouse}
                          id="Warehouse"
                          name="Warehouse"
                          fullWidth
                        />
                        )}
                      />

                  </FormControl>
                  : (
                    <FormControl className={classes.fullWidth}>
                      {/* <InputLabel id="demo-simple-select-helper-label-receive-from">
                        {DispensingLanguage[lan][menukey]["Upazila"] +"*"}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label-receive-from"
                        id="Upazila"
                        name="Upazila"
                        value={currFacilityId}
                        fullWidth
                        onChange={handleFacilityChange}
                      //disabled={ manyTableData.length > 0 ? true : props.bStockUpdate}
                      >
                        {UpazilaList.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select> */}

                      <Autocomplete
                        autoHighlight
                        disableClearable
                        id="Upazila"
                        options={UpazilaList}
                        onChange={(event, valueobj) => handleChangeChoosen('Upazila', valueobj, valueobj?valueobj.id:"")}
                        getOptionLabel={(option) => option.name}
                        value={chosenValues['Upazila']}
                        renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          //value={props.formData.Upazila}
                          label={DispensingLanguage[lan][menukey]["Upazila"]+ "*"}
                          variant="standard"
                          //error={props.errorObject.Upazila}
                          //helperText={props.errorObject.Upazila}
                          id="Upazila"
                          name="Upazila"
                          fullWidth
                        />
                        )}
                      />

                    </FormControl>
                  )
              }

            </Grid>
            <Grid item xs={2} sm={2}></Grid>
            {/* End warehouse dropdown list */}

            <Grid item xs={2} sm={2}></Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                //disabled={props.bStockUpdate}
                /*  error={props.errorObject.IndentNo}
                helperText={props.errorObject.IndentNo} */
                id="IndentNo"
                name="IndentNo"
                label={
                  DispensingLanguage[lan][menukey][
                  "Indent No"
                  ] +"*"
                }
                value={currIndentNo}
                fullWidth
                autoComplete="family-name"
                onChange={handleIndentNoChange}
              />
            </Grid>
            <Grid item xs={2} sm={2}></Grid>
          </Grid>



        </DialogContent>
        <DialogActions>
        <Grid item xs={2} className="mt-2 text-center">
          {/* sdfdsgfdsf */}
          </Grid>
          <Grid item xs={8} className="mt-2 text-center">
            <Button
              className="mr-2"
              variant="contained"
              color="primary"
              onClick={SaveSelectedOrder}>
              {DispensingLanguage[lan][menukey]['Download']}
            </Button>
            <Button autoFocus
              className="mr-2"
              variant="contained"

              onClick={() => handleClose('PickIndentModal')}>
              {DispensingLanguage[lan][menukey]['Close']}
            </Button>
          </Grid>
          
        <Grid item xs={2} className="mt-2 text-center">
          </Grid>
        </DialogActions>
      </BootstrapDialog>

    </div>
  );
};

export default PickIndentModal;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
