import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar";
import Notification from "../../../services/Notification";
import {checkLogin, checkUserPermission} from "../../../services/CheckUserAccess";
import swal from "sweetalert";
// Products screen
import IssueDetailsByItem from "./SDPLedgerReportGlobal.js";

const Index = (props) => {
  const classes = useStyles();
  const { path } = useRouteMatch();
  const menukey = "sdp-ledger-report-global";

  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  const [hasUserPermission, setHasUserPermission] = React.useState(false);

 /*  const checkLogin = () => {  
    let token = sessionStorage.getItem("token");
    if (!token) {
      swal({
        title: "Oops!",
        text: 'token expired. Please login again',
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) { 
          window.location.href = process.env.REACT_APP_BASE_NAME+`/login`;
          sessionStorage.clear();
        }
      });
    }
  }; */
  
  if(RedirectLogin){
    setHasUserPermission(checkUserPermission(menukey));// To check user has permission in this page
    checkLogin();
    setRedirectLogin(false);
  }

  React.useEffect(() => {
    // checkLogin();
    // checkAccess();
  }, []);

  const [msgObj, setMsgObj] = React.useState({
    isOpen: false,
  });
  const openNoticeModal = (obj) => {
    let obj1=obj;
    if(obj.duration==undefined)
    obj1={...obj,duration:2000}
    setMsgObj(obj1);
  };
  const closeNoticeModal = (event, reason) => { 
      if (reason === 'clickaway') {
        return;
      } 
    setMsgObj({ isOpen: false });
  };
  
  return (
    hasUserPermission && (
        <div>
          <AfterLoginNavbar {...props} />
          <div className={`section signup-top-padding ${classes.productContainer}`}>


            <Switch>

              <Route
                path={`${path}/`}
                render={(props) => (
                  <IssueDetailsByItem {...props} openNoticeModal={openNoticeModal} />
                )}
              ></Route>
            </Switch>

            <Notification
              closeNoticeModal={closeNoticeModal}
              msgObj={msgObj}
              {...props}
            ></Notification>

          </div>
        </div>
      )
  );
};

export default Index;

const useStyles = makeStyles({
  productContainer: {
    backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
    backgroundSize: "cover",
    backgroundPosition: "top center",
    minHeight: "753px",
  },
});
