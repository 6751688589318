import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as Service from "../../../services/Service.js";
import * as api from '../../../actions/api';
import {
  Grid,
  TextField,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import {  useQuery,  useMutation,  useQueryClient} from 'react-query';

const EntryeditofContractItemsFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  open,
  ...props
}) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "entry-edit-of-contract-items";
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();

  const FacilityId = localStorage.getItem('FacilityId');
  const FacilityName = localStorage.getItem('FacilityName');

  const ReceivingWarehouseList = JSON.parse(localStorage.getItem('OrderTo'));


  const [firstLoad, setFirstLoad] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [manyTableData, setManyTableData] = useState([]);
  const GroupNameList = JSON.parse(localStorage.getItem("GroupNameList"));
  const [GroupCode, setGroupCode] = useState(0);

  const queryClient = useQueryClient();

  let TempItemList = useState([]);
  const [ItemList, setItemListLocal] = useState(TempItemList);


  //====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "FacilityCode": {"id": "","name": ""},
  "GroupCode": {"id": "","name": ""}
 

});

const [chosenValuesFacility, setChosenValuesFacility] = useState({
  "ItemCode": {"id": "","name": ""}
});



React.useEffect(() => {
  
  let chosenValuesData = { ...chosenValues};
   chosenValuesData['FacilityCode'] = ReceivingWarehouseList[ReceivingWarehouseList.findIndex(ReceivingWarehouse_List => ReceivingWarehouse_List.id == formData.FacilityCode)];
   chosenValuesData['GroupCode'] = GroupNameList[GroupNameList.findIndex(GroupName_List => GroupName_List.id == formData.GroupCode)];
   setChosenValues(chosenValuesData); 

  }, [formData.FacilityCode, formData.GroupCode]);

  React.useEffect(() => {
  
    let chosenValuesDataFacility = { ...chosenValuesFacility};
    chosenValuesDataFacility['ItemCode'] = ItemList[ItemList.findIndex(Item_List => Item_List.id == formData.ItemCode)];
    setChosenValuesFacility(chosenValuesDataFacility); 
  }, [formData.ItemCode]); 
  
  

const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  setChosenValues(chosenValuesData);

  let chosenValuesDataFacility = { ...chosenValuesFacility};
  chosenValuesDataFacility[name] = valueobj;  
  setChosenValuesFacility(chosenValuesDataFacility); 

  props.handleChangeChoosenProps(name, value);
};



const handleChangeChoosenFGroupCode = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  formData["ItemCode"] = "";

  setChosenValues(chosenValuesData);
  setGroupCode(value);

  props.handleChangeChoosenProps(name, value);

};

//====== End Choosen Dropdown===========


//Updaet depadenchi combo update code
  React.useEffect(() => { 
    if(formData.GroupCode !==''){
      setGroupCode(formData.GroupCode);
    }
  }, [formData.GroupCode]);


  React.useEffect(() => {
    cItemList();
  }, [GroupCode]);


  const cItemList = () => {

    let ItemListParam = { action: "ItemList", GroupCode: GroupCode, FacilityId: FacilityId, menukey: "" }

    Service.default
      .postApi("source/combo_generic.php", ItemListParam)
      .then((res) => {

        if(!props.addProductForm){
          let chosenValuesDataFacility = { ...chosenValuesFacility};
          chosenValuesDataFacility['ItemCode'] = res.datalist[res.datalist.findIndex(fpacily_Lists2 => fpacily_Lists2.id == formData.ItemCode)];
          setChosenValuesFacility(chosenValuesDataFacility);  

      }else{
          setChosenValuesFacility({
          "ItemCode": {"id": "","name": ""}
          }); 
          formData["ItemCode"] = "";
        } 

        setItemListLocal(res.datalist);


      })
      .catch((err) => { });

  }

  const GrouphandleChange = (event) => {
    
    setFirstLoad(true);
    setLoading(false);
    setGroupCode(event.target.value);
   
    //console.log('Group ID',event.target.value);
  };



  
const backtolist=()=>{


  let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
  let idx = master_dirty_message_class.indexOf("dnone");
  if(idx === -1){
    // start Alert
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then((willAction) => {
      if (willAction) {
        props.history.push("/entry-edit-of-contract-items");
      }
    });
    // End Alert
  }else{
    props.history.push("/entry-edit-of-contract-items");
  }

}

const onSaveUpdateBtnClick = () => {

  setUpdatedDetailsGridDataHandler();
};


const setUpdatedDetailsGridDataHandler = () => {
  let validForm = props.checkValidateForm();
 
  // console.log('isval: ', validForm);
    if (validForm) {

      if(addProductForm){
        //when save
        mutate(validForm);
        
      }else{
        //when update
        mutateUpdate(validForm); 
      }
      

      
    }
};



const { isLoading : isLoadingSave, mutate } = useMutation(api.saveContractItem, {
  onSuccess: (data) => {
   // console.log("data: ", data);
    if (data.status == 200) {
      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
      queryClient.getQueriesData("ContrackItemEntry"); //api page name
      props.history.push("/entry-edit-of-contract-items"); //
    } else {
      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
      queryClient.getQueriesData("ContrackItemEntry"); //api page name
      props.history.push("/entry-edit-of-contract-items"); //
    }
  },
});


const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.UpdateontrackItem, {
  onSuccess: (data) => { 
    if (data.status == 200) {
   
      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });

      queryClient.getQueriesData("ContrackItemEntry"); // include the page name
      props.history.push("/entry-edit-of-contract-items"); //page url
      api.dirtyModeDisable();
    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
    }
  },
});

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title"> {DispensingLanguage[lan][menukey]['Contract Items Entry - Add/Edit']} </div>
            </div>
            <div className="float-right sw_btn_control">

              {addProductForm ? (
                <Grid item xs={12} className="mt-4 text-center tex_margin">
                  <Button
                disabled={props.bStockUpdate}
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => handleReset()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Reset'])}
                  </Button>
                  <Button
                disabled={props.bStockUpdate || isLoadingSave}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClick}
                    // onClick={() => handleSubmit()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Save'])}
                  </Button>
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    //onClick={() => props.history.push("/entry-edit-of-contract-items")}
                    onClick={() => backtolist()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Back to List'])}
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={12} className="mt-2 text-center">
                  <Button
                disabled={props.bStockUpdate || isLoadingUpdate}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClick}
                    // onClick={() => handleUpdate()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Update'])}
                  </Button>
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    //onClick={() => props.history.push("/entry-edit-of-contract-items")}
                    onClick={() => backtolist()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Back to List'])}
                  </Button>
                </Grid>
              )}



            </div>

            <div className="mr-4 float-right mt-15">
              <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
            </div>
            
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={10}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['Contract Items Entry']} />
              <CardContent>
                <Grid container spacing={10}>
                  <Grid item xs={4} sm={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={6} sm={6}>
                        <FormControl className={classes.fullWidth}>
                          {/* <InputLabel id="">
                            {DispensingLanguage[lan][menukey]["Produc Group"]}
                          </InputLabel>
                          <Select
                            error={errorObject.GroupCode}
                            required
                            disabled={props.bStockUpdate}
                            labelId="GroupCode"
                            id="GroupCode"
                            name="GroupCode"
                            value={formData.GroupCode}
                            fullWidth
                             onChange={GrouphandleChange}
                          >
                           
                            {GroupNameList.map((item, index) => {
                              return (
                                <MenuItem value={item.id}> {item.name}</MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText error={errorObject.GroupCode}>
                            {errorObject.GroupCode}
                          </FormHelperText> */}

                          <Autocomplete
                            autoHighlight
                            //disableClearable
                            disabled={props.bStockUpdate}
                            id="GroupCode"
                            options={GroupNameList}
                            onChange={(event, valueobj) => handleChangeChoosenFGroupCode('GroupCode', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValues['GroupCode']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={formData.GroupCode}
                              label={DispensingLanguage[lan][menukey]["Produc Group"]}
                              variant="standard"
                              error={errorObject.GroupCode}
                              helperText={errorObject.GroupCode}
                              id="GroupCode"
                              name="GroupCode"
                              fullWidth
                            />
                            )}
                            />

                        </FormControl>
                      </Grid>

                      <Grid item xs={6} sm={6}>
                        <FormControl className={classes.fullWidth}>
                          {/* <InputLabel id="">
                            {DispensingLanguage[lan][menukey]["Product"]}*
                          </InputLabel>
                          <Select
                            error={errorObject.ItemCode}
                          
                            labelId="ItemCode"
                            id="ItemCode"
                            name="ItemCode"
                            value={formData.ItemCode}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                            disabled={
                              (manyTableData.length > 0 || props.bStockUpdate) ? true : props.bStockUpdate
                            }
                          >


                            <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                            {ItemList.map((item, index) => {
                              return (
                                <MenuItem value={item.id}> {item.name}</MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText error={errorObject.ItemCode}>
                            {errorObject.ItemCode}
                          </FormHelperText> */}

                      <Autocomplete
                        autoHighlight
                        //disableClearable
                        disabled={
                          (manyTableData.length > 0 || props.bStockUpdate) ? true : props.bStockUpdate
                        }
                        id="ItemCode"
                        options={ItemList}
                        onChange={(event, valueobj) => handleChangeChoosen('ItemCode', valueobj, valueobj?valueobj.id:"")}
                        getOptionLabel={(option) => option.name}
                        value={chosenValuesFacility['ItemCode']}
                        renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          value={formData.ItemCode}
                          label={DispensingLanguage[lan][menukey]["Product"]+" *"}
                          variant="standard"
                          error={errorObject.ItemCode}
                          helperText={errorObject.ItemCode}
                          id="ItemCode"
                          name="ItemCode"
                          fullWidth
                        />
                        )}
                        />

                        </FormControl>
                      </Grid>

                      <Grid item xs={6} sm={6}>

                        <TextField
                          error={errorObject.ItemQty}
                          helperText={errorObject.ItemQty}
                          required
                          id="ItemQty"
                          name="ItemQty"
                          label={DispensingLanguage[lan][menukey]["Quantity"]}
                          value={formData.ItemQty}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                          disabled={props.bStockUpdate}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>

                        <TextField
                          error={errorObject.ContractUnitPrice}
                          helperText={errorObject.ContractUnitPrice}
                          
                          id="ContractUnitPrice"
                          name="ContractUnitPrice"
                          label={DispensingLanguage[lan][menukey]["Unit Price"]}
                          value={formData.ContractUnitPrice}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                          disabled={props.bStockUpdate}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <FormControl className={classes.fullWidth}>
                          {/* <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]['Receiving Warehouse']}*
                          </InputLabel>
                          <Select
                            disabled={props.bStockUpdate}
                            error={errorObject.FacilityCode}
                            required
                            labelId="demo-simple-select-helper-label"
                            id="FacilityCode"
                            name="FacilityCode"
                            value={formData.FacilityCode}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                            
                          >
                            
                            <MenuItem  value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                            {
                              ReceivingWarehouseList.map(
                                (item, index) => {
                                  return (
                                    <MenuItem value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                }
                              )}
                          </Select>


                          <FormHelperText error={errorObject.currentreceiving}>
                            {errorObject.currentreceiving}

                          </FormHelperText> */}

                            <Autocomplete
                              autoHighlight
                                //disableClearable
                              disabled={props.bStockUpdate}
                              id="FacilityCode"
                              options={ReceivingWarehouseList}
                              onChange={(event, valueobj) => handleChangeChoosen('FacilityCode', valueobj, valueobj?valueobj.id:"")}
                              getOptionLabel={(option) => option.name}
                              value={chosenValues['FacilityCode']}
                              renderOption={(option) => (
                              <Typography className="sw_fontSize">{option.name}</Typography>
                              )}
                              renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.FacilityCode}
                                label={DispensingLanguage[lan][menukey]["Receiving Warehouse"]+ " *"}
                                variant="standard"
                                error={errorObject.FacilityCode}
                                helperText={errorObject.FacilityCode}
                                id="FacilityCode"
                                name="FacilityCode"
                                fullWidth
                              />
                              )}
                            />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}



        </Grid>
      </div>
    </div>
  );
};

export default EntryeditofContractItemsFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
