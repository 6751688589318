import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom"; 
import * as api from "../../../actions/api";
import UnitEntryFormData from "./UnitEntryFormData";


const EditUnitEntry = (props) => {

    //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "unit-entry";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;  
  const UserName = UserInfo==0?'': UserInfo[0].id;

  const [formData, setFormData] = useState({
    UpazilaCode:"",
    sunitname:"",
  });

  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  useQuery(
    ["UnitEntry", id],
    () =>
      api.getUnitEntrys(id).then((res) => {
        setFormData(res.data);
        api.dirtyModeDisable();
      }),
    {
      enabled: Boolean(id),
    }
  );

  // const { mutate } = useMutation(api.UpdateUnitEn, {
  //   onSuccess: (data) => { 
  //     if (data.status == 200) {
     
  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.data.message,
  //         msgtype: data.data.success,
  //       });

  //       queryClient.getQueriesData("UnitEntry"); // include the page name
  //       props.history.push("/unit-entry"); //page url
  //     }else{

  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.message,
  //         msgtype: data.success,
  //       });
  //     }
  //   },
  // });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };

  //====== Start Choosen Dropdown===========
  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value;   
    setFormData(data); 
    setErrorObject({ ...errorObject, [name]: "" });
    api.dirtyModeEnable();
  }
  //====== End Choosen Dropdown===========

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      UpazilaCode:"",
      sunitname:"",
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["UpazilaCode", "sunitname"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  function checkValidateForm(){

     
    if (validateForm(formData)) {
    let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName,}; 
   
    return fDtat;
    }else{
    return false;
    }

};

  // const handleUpdate = async (e) => {
  //   if (validateForm(formData)) {
  //     let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
  //     mutate(fDtat);
  //   }
  // };

  return (
    <>
      <UnitEntryFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleCheck={handleCheck}
        handleReset={handleReset}
        checkValidateForm ={checkValidateForm}
        // handleUpdate={handleUpdate}
        bStockUpdate={props.permissionType}
        {...props}
      />
    </>
  );
};

export default EditUnitEntry;
