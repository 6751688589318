import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import * as api from "../../../actions/api";
import OthersFacilityListFormData from "./OthersFacilityListFormData";

const AddothersFacilityList = (props) => {

  //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "others-facility-list";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo==0?'': UserInfo[0].id;

  const [getOthersFacilityCode, setFacilityCode] = useState('');

  const [formData, setFormData] = useState({
    FacilityName: "",
    DistCode:"",
    PhoneNo:"",
    FacilityCode: getOthersFacilityCode,
    bShow: true,
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  let params={"menukey": "others-facility-list", action: "getOthersFacilityCode"};
  const {  data:gFacilityCode, refetch } = useQuery([params], api.getOthersFacilityCode, 
    {onSuccess: (data) => {
      
      setFacilityCode(data);
      let fdata = { ...formData }; 
      fdata["FacilityCode"] = data; 
      setFormData(fdata); 

    }}
    );

  useQuery(
    ["othersFacilityList", id],
    () =>
      api.geOthersFacilityLists(id).then((res) => {
        setFormData(res.data);
      }),
    {
      enabled: Boolean(id),
    }
  );

  const { isLoading, mutate } = useMutation(api.saveOthersFacilityList, {
    onSuccess: (data) => {

      if(data.status==200) {
  
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
  
        queryClient.getQueriesData("othersFacilityLists");//api page name
        props.history.push("/others-facility-list");//
  
      }else{
  
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
       
      }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    console.log("formData: ", formData);
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };

    //====== Start Choosen Dropdown===========
    const handleChangeChoosenProps = (name, value) => {
      let data = { ...formData };
      data[name] = value;   
      setFormData(data); 
      setErrorObject({ ...errorObject, [name]: "" });
      api.dirtyModeEnable();
    }
    //====== End Choosen Dropdown===========

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
    api.dirtyModeEnable();
  };

  const handleReset = () => {
    setFormData({
      FacilityName: "",
      DistCode:"",
      PhoneNo:"",
      FacilityCode:"",
      bShow: true,
      
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["FacilityName", "DistCode", "FacilityCode"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = (DispensingLanguage[lan][menukey]['field is Required !']);
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleSubmit = async (e) => {
    console.log("from submit ", formData);
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat);
     // swal("Success!", "", "success");
    }
  };

  return (
    <>
      <OthersFacilityListFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        bStockUpdate={props.permissionType}
        {...props}
      />
    </>
  );
};

export default AddothersFacilityList;

