import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import jsPDF from "jspdf";
import ExportButtons from "../../../components/ExportButtons";
//Services
import * as Service from "../../../services/Service.js";
import Constants from "../../../services/Constants";
import swal from "sweetalert";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
  Typography,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";


import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";

import * as api from "../../../actions/api";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, ViewList, preview, Edit } from "@material-ui/icons";
import { cellFocusEditor } from "../../../services/Common";
import moment from "moment";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const IssueDetailsByItem = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const menukey = "sdp-stock-out-report";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;

  const classes = useStyles();
  let tblRef = null;
  const tableRef = useRef();
  const { useState } = React;

  const [firstLoad, setFirstLoad] = useState(true);
  
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const currentDate = new Date();
  const [ManyJsonSave, setInputChange] = useState({});
  //const [radioValue, setRadioValue] = useState(2);
  const [productGroupData, selectProductGroupData] = useState();
  const [dataItems, setDataItems] = useState([]);
  const [currGroupCode, setcurrGroupCode] = useState(0);
 
  
  const MonthList = JSON.parse(localStorage.getItem("MonthList"));
  let cMonthId = currentDate.getMonth();
  let cYearId = currentDate.getFullYear();
 
  let CurrentMonthId = 0;
  let CurrentYearId = 0;
  if(cMonthId === 0){
    CurrentMonthId = 12;
    CurrentYearId = cYearId-1;
  }else{
    CurrentMonthId = cMonthId;
    CurrentYearId = cYearId;
  }
  let CurrentMonthObj = MonthList[MonthList.findIndex(Month_List => Month_List.id == CurrentMonthId)];

  const [currMonthId, setcurrMonthId] = useState(CurrentMonthId);
  const [currYear, setcurrYear] = useState(CurrentYearId);

  //const [currMonthId, setcurrMonthId] = useState(currentDate.getMonth());
   //const [currYear, setcurrYear] = useState(currentDate.getFullYear());
  
  const [manyTableData, setManyTableData] = useState([]);
  const GroupNameList = JSON.parse(localStorage.getItem("ItemGroupNavFieldGlobal"));
  GroupNameList.unshift({'id':0,'name':t(DispensingLanguage[lan][menukey]["All"])});



  //let CurrentMonth = MonthList[MonthList.findIndex(Month_List => Month_List.id == currMonthId)];

  let TempItemList = useState([]);
  const [ItemList, setItemListLocal] = useState(TempItemList);
  const [currItemCode, setcurrItemCode] = useState(0);
  const UseFor = localStorage.getItem("UseFor");

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const FacilityList = JSON.parse(localStorage.getItem("FacilityListGlobal"));
  const [currFacilityCode, setcurrFacilityCode] = useState(FacilityList.length>0? FacilityList[0].id:"");
  const [currFacilityName, setcurrFacilityName] = useState(FacilityList.length>0? FacilityList[0].name:"");
  const [chosenValuesFacility, setChosenValuesFacility] = useState({
  
  });
  
  let TempSdpList = useState([]);
  const [SDPList, setSDPListLocal] = useState(TempSdpList);
  const [currSDPCode, setcurrSDPCode] = useState(0);


  //====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "GroupCode": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
});
const [chosenValuesItem, setChosenValuesItem] = useState({
  "ItemCode": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
});

const [chosenValuesSDP, setChosenValuesSDP] = useState({
  "SdpCode": { "id": "0", "name": t(DispensingLanguage[lan][menukey]["All"]) }
});
 //"MonthId": {"id": CurrentMonthId,"name": CurrentMonthName}
const [chosenValuesMonth, setChosenValuesMonth] = useState({
  "MonthId": CurrentMonthObj
});




const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;
  setChosenValues(chosenValuesData); 


  setFirstLoad(true);
  setLoading(false);
  setcurrGroupCode(value);

    /* setFirstLoad(true);
    setLoading(false);
    setcurrstockitem('0');

    if (chosenValuesData['GroupCode'].id !=='0'){
      let StockItemParam = {action:"StockItemName",FacilityId:FacilityId,GroupCode:chosenValuesData['GroupCode'].id,menukey:""}
      StockItemNameList(StockItemParam);
      selectProductGroupData(chosenValuesData['GroupCode'].id);
    
    } */

};


const handleChangeChoosenFacility = (name, valueobj, value) => {
    let chosenValuesDataFacility = { ...chosenValuesFacility};
    chosenValuesDataFacility[name] = valueobj;
    setChosenValuesFacility(chosenValuesDataFacility); 
  
    setFirstLoad(true);
    setLoading(false);
    setcurrFacilityCode(value);
    setcurrFacilityName(valueobj.name);
  
  };
  

const handleChangeChoosenItem = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValuesItem};
  chosenValuesData[name] = valueobj;
  setChosenValuesItem(chosenValuesData); 

  setFirstLoad(true);
  setLoading(false);
  setcurrItemCode(value);


};

const handleChangeChoosenSDP = (name, valueobj, value) => {
  let chosenValuesDataSDP = { ...chosenValuesSDP};
  chosenValuesDataSDP[name] = valueobj;
  setChosenValuesSDP(chosenValuesDataSDP); 

  setFirstLoad(true);
  setLoading(false);
  setcurrSDPCode(value);


};

const handleChangeChoosenMonth = (name, valueobj, value) => {
  let chosenValuesDataMonth = { ...chosenValuesMonth};
  chosenValuesDataMonth[name] = valueobj;
  setChosenValuesMonth(chosenValuesDataMonth); 

  setFirstLoad(true);
  setLoading(false);
  setcurrMonthId(value);


};

//====== End Choosen Dropdown===========

  const handleStartDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    let newDate = moment(date).format("YYYY-MM-DD");
    setSelectedStartDate(newDate);
  };

  const handleEndDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    let newDate = moment(date).format("YYYY-MM-DD");
    setSelectedEndDate(newDate);
  };

  

    const handleChange = (event) => {
      setFirstLoad(true);
      setLoading(false);
      setcurrGroupCode(event.target.value);
    };


    const handleChangeSDP = (event) => {
      setFirstLoad(true);
      setLoading(false);
      setcurrSDPCode(event.target.value);
    };

    const handleChangeMonth = (event) => {
      setFirstLoad(true);
      setLoading(false);
      setcurrMonthId(event.target.value);
    };
    const handleChangeYear = (event) => {
      setFirstLoad(true);
      setLoading(false);
      setcurrYear(event.target.value);
    };
    
  const handleChangeItemCode = (event) => {
    
    setFirstLoad(true);
    setLoading(false);
    setcurrItemCode(event.target.value);

  };


  const cItemList = () => {  
  
    let ItemListParam = {action:"ItemListLookUpWithAllGlobal", GroupCode:currGroupCode, FacilityId: currFacilityCode, menukey:""}

    Service.default
        .postApi("source/combo_generic.php",ItemListParam)
        .then((res) => { 

          let All_Item_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
          const Item_List = [All_Item_label].concat(res.datalist); 

          setChosenValuesItem({
           "ItemCode": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
          }); 

          setItemListLocal(Item_List);
          //setcurrstockitem('0');

           //setItemListLocal(res.datalist); 
        })
        .catch((err) => {});
  
}


const cSdpList = () => {

  let ItemListParam = { action: "SDPListGlobal", FacilityId: currFacilityCode, menukey: "" }

  Service.default
    .postApi("source/combo_generic.php", ItemListParam)
    .then((res) => {

      let All_Item_label = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
      const Sdp_List = [All_Item_label].concat(res.datalist);

      setChosenValuesSDP({
        "SdpCode": { "id": "0", "name": t(DispensingLanguage[lan][menukey]["All"]) }
      });

      setSDPListLocal(Sdp_List);

      //setItemListLocal(res.datalist); 
    })
    .catch((err) => { });

}




  let params = {
    menukey: menukey,
    FacilityId: currFacilityCode,
    action: "getDataListGlobal",
    MonthId: currMonthId,
    Year: currYear,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    //RadioValue: radioValue,
    GroupCode: currGroupCode,
    ItemCode: currItemCode,
    SDPCode:currSDPCode,
  };

  const [isLoading, setLoading] = useState(false);

  const { error, isError, data, refetch:stockData } = useQuery(
    [params],
    api.SDPStockOutReportList,
    {
      onSuccess: (data) => {
        if(firstLoad){
          /* localStorage.setItem(
            "StockData",
            JSON.stringify(data)
          );
           */
          setDataItems(data);
          setLoading(false);
          setFirstLoad(false);
        }
      },
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 0
  }
  ); 


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let formatedDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";


    window.open(
      finalUrl +
        "?action=SDPStockOutReportExportGlobal" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&MonthId=" +
        currMonthId +
        "&Year=" +
        currYear +
        "&FacilityId=" +
        currFacilityCode +
        "&GroupCode=" +
        currGroupCode +
        "&ItemCode=" +
        currItemCode +
         "&SDPCode=" +
         currSDPCode + 
        "&FacilityName=" +
        currFacilityName + 
        "&UseFor=" +
        UseFor + 
        "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */


  const columns = [
    {
      title: "Id",
      field: "id",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: "5%",
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    
   
    {
      title: DispensingLanguage[lan][menukey]["Facility Group"],
      field: "FGroupName",
      headerFilter: false,
      width: "7%",
    }, 
    {
      title: DispensingLanguage[lan][menukey]["Union Name"],
      field: "UnionName",
      headerFilter: false,
      width: "15%",

    },
    {
      title: DispensingLanguage[lan][menukey]["SDP Code"],
      field: "FacilityCode",
      headerFilter: false,
      width: "8%",

    },
    
    {
      title: DispensingLanguage[lan][menukey]["SDP Name"],
      field: "FacilityName",
      headerFilter: true,
     
      
    },   
    
  ];




  const checkLogin = () => {
    let token = Constants.token;

    if (!token) {
      swal("Oops!", `token expired. Please login again`, "error");
      props.history.push("/login");
    }
  };


  React.useEffect(() => {
    setcurrItemCode(0); 
    cItemList();
  }, [currFacilityCode,currGroupCode]);

  React.useEffect(() => {
    setcurrSDPCode(0);
    cSdpList();
  }, [currFacilityCode]);

 React.useEffect(() => {
   if (tblRef == null) return;
   setLoading(true);
   stockData();
  }, [currMonthId, currYear, currGroupCode,currItemCode,currSDPCode]);

  return (
    <>
      <div className={classes.productPageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["SDP Stock Out Report"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container spacing={2}>


            <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                           
                            disableClearable
                            id="FacilityCode"
                            options={FacilityList}
                            onChange={(event, valueobj) => handleChangeChoosenFacility('FacilityCode', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                           
                            defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityCode)]}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Facility"]}
                              variant="standard"
                              id="FacilityCode"
                              name="FacilityCode"
                              fullWidth
                            />
                            )}
                          />

                    </FormControl>
                  </Grid> 

              <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>

                        <Autocomplete
                          autoHighlight
                          disabled={
                            manyTableData.length > 0 ? true : props.bStockUpdate
                          }
                          disableClearable
                          id="GroupCode"
                          options={GroupNameList}
                          onChange={(event, valueobj) => handleChangeChoosen('GroupCode', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValues['GroupCode']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            //value={props.formData.GroupCode}
                            label={DispensingLanguage[lan][menukey]["Group"]}
                            variant="standard"
                            id="GroupCode"
                            name="GroupCode"
                            fullWidth
                          />
                          )}
                        />

                </FormControl>
                  </Grid>

                   <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            disabled={
                              manyTableData.length > 0 ? true : props.bStockUpdate
                            }
                            disableClearable
                            id="ItemCode"
                            options={ItemList}
                            onChange={(event, valueobj) => handleChangeChoosenItem('ItemCode', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValuesItem['ItemCode']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              //value={props.formData.ItemCode}
                              label={DispensingLanguage[lan][menukey]["Item Name"]}
                              variant="standard"
                              //error={props.errorObject.ItemCode}
                               //helperText={props.errorObject.ItemCode}
                              id="ItemCode"
                              name="ItemCode"
                              fullWidth
                            />
                            )}
                          />
                    </FormControl>
                  </Grid> 

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            disabled={
                              manyTableData.length > 0 ? true : props.bStockUpdate
                            }
                            disableClearable
                            id="SdpCode"
                            options={SDPList}
                            onChange={(event, valueobj) => handleChangeChoosenSDP('SdpCode', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValuesSDP['SdpCode']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["SDP"]}
                              variant="standard"
                              id="SdpCode"
                              name="SdpCode"
                              fullWidth
                            />
                            )}
                          />

                    </FormControl>
                  </Grid> 

                  {/* <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      //disableToolbar
                      autoOk={true}
                      // disabled={true}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["Start Date"]}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid> */}

              {/* <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["End Date"]}
                      autoOk={true}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid> */}
                 
                
              <Grid item xs={1} sm={1}>
                <div className="float-right sw_btn_control">

                <ExportButtons PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}  {...props}></ExportButtons>
                 
                </div>
              </Grid>


              <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            disableClearable
                            id="MonthId"
                            options={MonthList}
                            onChange={(event, valueobj) => handleChangeChoosenMonth('MonthId', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValuesMonth['MonthId']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Reported Month"]}
                              variant="standard"
                              id="MonthId"
                              name="MonthId"
                              fullWidth
                            />
                            )}
                          />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <TextField
                    
                      id="Year"
                      name="Year"
                      label={
                        DispensingLanguage[lan][menukey][
                          "Reported Year"
                        ] 
                      }
                      value={currYear}
                      fullWidth
                      autoComplete="family-name"
                      onChange={handleChangeYear}
                    />
                  </Grid>



            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}

          <div className="uniqueName">
            <ReactTabulator
              ref={(r) => (tblRef = r)}
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="400px"
              // groupBy="GroupName"
              options={{
                  groupBy: ["ItemName", "FGroupName"],
                  //groupBy: "GroupName",
                  columnCalcs: "both",
                }}
             // rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IssueDetailsByItem;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
