import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import jsPDF from "jspdf";

//Services
import * as Service from "../../../services/Service.js";
import Constants from "../../../services/Constants";
import swal from "sweetalert";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
  Typography,
  TextField,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import ExportButtons from "../../../components/ExportButtons";

import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";

import * as api from "../../../actions/api";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, ViewList, preview, Edit } from "@material-ui/icons";
import { cellFocusEditor } from "../../../services/Common";
import moment from "moment";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const IssueDetailsByItem = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const menukey = "sdp-ledger-report";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;

  const classes = useStyles();
  let tblRef = null;
  const tableRef = useRef();
  const { useState } = React;
  
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();


  const [firstLoad, setFirstLoad] = useState(true);

  const [ManyJsonSave, setInputChange] = useState({});
  //const [radioValue, setRadioValue] = useState(2);
  const [productGroupData, selectProductGroupData] = useState();
  const [dataItems, setDataItems] = useState([]);
  const [currGroupCode, setcurrGroupCode] = useState(0);
  const [currSDPCode, setcurrSDPCode] = useState(0);

  const [manyTableData, setManyTableData] = useState([]);
  const GroupNameList = JSON.parse(localStorage.getItem("ItemGroupNavField"));
  GroupNameList.unshift({'id':0,'name':t(DispensingLanguage[lan][menukey]["All"])});
  const SDPList = JSON.parse(localStorage.getItem("SDPList"));
  SDPList.unshift({'id':0,'name':t(DispensingLanguage[lan][menukey]["All"])});

  let TempItemList = useState([]);
  const [ItemList, setItemListLocal] = useState(TempItemList);
  const [currItemCode, setcurrItemCode] = useState(0);
  const UseFor = localStorage.getItem("UseFor");


   
  //====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "GroupCode": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
});
const [chosenValuesItem, setChosenValuesItem] = useState({
  "ItemCode": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
});

const [chosenValuesSDP, setChosenValuesSDP] = useState({
  "FacilityCode": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
});


const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;
  setChosenValues(chosenValuesData); 

  setFirstLoad(true);
  setLoading(false);
  setcurrGroupCode(value);

};

const handleChangeChoosenItem = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValuesItem};
  chosenValuesData[name] = valueobj;
  setChosenValuesItem(chosenValuesData); 

  setFirstLoad(true);
  setLoading(false);
  setcurrItemCode(value);


};

const handleChangeChoosenSDP = (name, valueobj, value) => {
  let chosenValuesDataSDP = { ...chosenValuesSDP};
  chosenValuesDataSDP[name] = valueobj;
  setChosenValuesSDP(chosenValuesDataSDP); 

  setFirstLoad(true);
  setLoading(false);
  setcurrSDPCode(value);


};

//====== End Choosen Dropdown===========

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(30, "days").format("YYYY-MM-01")
  );

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const handleStartDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedEndDate(date);
  };

  

    const handleChange = (event) => {
      setFirstLoad(true);
      setLoading(false);
      setcurrGroupCode(event.target.value);
    };


    const handleChangeSDP = (event) => {
      setFirstLoad(true);
      setLoading(false);
      setcurrSDPCode(event.target.value);
    };

  const handleChangeItemCode = (event) => {
    
    setFirstLoad(true);
    setLoading(false);
    setcurrItemCode(event.target.value);

  };


  const cItemList = () => {  
  
    let ItemListParam = {action:"ItemListLookUpWithAll", GroupCode:currGroupCode, FacilityId: FacilityId, menukey:""}

    Service.default
        .postApi("source/combo_generic.php",ItemListParam)
        .then((res) => { 

          let All_Item_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
          const Item_List = [All_Item_label].concat(res.datalist); 

          setChosenValuesItem({
           "ItemCode": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
          }); 

          setItemListLocal(Item_List);

           //setItemListLocal(res.datalist); 
        })
        .catch((err) => {});
  
}



  let params = {
    menukey: menukey,
    FacilityId: FacilityId,
    action: "getSDPLedgerReport",
    StartDate: moment(selectedStartDate).format("YYYY-MM-DD"),
    EndDate: moment(selectedEndDate).format("YYYY-MM-DD"),
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    //RadioValue: radioValue,
    GroupCode: currGroupCode,
    ItemCode: currItemCode,
    SDPCode:currSDPCode,
  };

  const [isLoading, setLoading] = useState(false);

  const { error, isError, data, refetch:stockData } = useQuery(
    [params],
    api.SDPLedgerReportList,
    {
      onSuccess: (data) => {
        if(firstLoad){
          /* localStorage.setItem(
            "StockData",
            JSON.stringify(data)
          );
           */
          setDataItems(data);
          setLoading(false);
          setFirstLoad(false);
        }
      },
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 0
  }
  ); 


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let formatedDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";


    window.open(
      finalUrl +
        "?action=SDPLedgerReportExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&StartDate=" +
        formatedDate +
        "&EndDate=" +
        EndDate +
        "&FacilityId=" +
        FacilityId +
        "&GroupCode=" +
        currGroupCode +
        "&ItemCode=" +
        currItemCode +
         "&SDPCode=" +
         currSDPCode +
        "&FacilityName=" +
        FacilityName +
        "&UseFor=" +
        UseFor + 
        "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */


  const columns = [
    {
      title: "Id",
      field: "id",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 5,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    
   
    {
      title: DispensingLanguage[lan][menukey]["Item Name"],
      field: "ItemName",
      headerFilter: false,
      
    }, 
    {
      title: DispensingLanguage[lan][menukey]["SDP Code"],
      field: "FacilityCode",
      headerFilter: false,
      width: 110,

    },
    {
      title: DispensingLanguage[lan][menukey]["Opening Bal"],
      field: "OBL",
      hozAlign: "right",
      headerHozAlign: "right",
      headerFilter: false,
      width: 100,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : Number(value).toLocaleString();
      },

    },
    
    {
      title: DispensingLanguage[lan][menukey]["Received"],
      field: "Received",
      hozAlign: "right",
      headerHozAlign: "right",
      headerFilter: false,
      width: 100,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == 0 ? "" : Number(value).toLocaleString();
      },
     
      
    },   

    {
      title: DispensingLanguage[lan][menukey]["Distribution"],
      field: "Distribution",
      hozAlign: "right",
      headerHozAlign: "right",
      headerFilter: false,
      width: 100,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == 0 ? "" : Number(value).toLocaleString();
      },
     
      
    },   
    {
      title: DispensingLanguage[lan][menukey]["Adj.(+)"],
      field: "AdjustPlus",
      hozAlign: "right",
      headerHozAlign: "right",
      headerFilter: false,
      width: 100,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == 0 ? "" : Number(value).toLocaleString();
      },
     
      
    },  
    {
      title: DispensingLanguage[lan][menukey]["Adj.(-)"],
      field: "AdjustMinus",
      hozAlign: "right",
      headerHozAlign: "right",
      headerFilter: false,
      width: 100,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == 0 ? "" : Number(value).toLocaleString();
      },
      
    },   
    {
      title: DispensingLanguage[lan][menukey]["Closing Bal"],
      field: "col",
      hozAlign: "right",
      headerHozAlign: "right",
      headerFilter: false,
      width: 100,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == 0 ? "" : Number(value).toLocaleString();
      },
     
      
    },    
    
  ];




  const checkLogin = () => {
    let token = Constants.token;

    if (!token) {
      swal("Oops!", `token expired. Please login again`, "error");
      props.history.push("/login");
    }
  };


  React.useEffect(() => {
    setcurrItemCode(0); 
    cItemList();
  }, [currGroupCode]);


 React.useEffect(() => {
   if (tblRef == null) return;
   setLoading(true);
   stockData();
  }, [selectedStartDate, selectedEndDate, currGroupCode,currItemCode,currSDPCode]);

  return (
    <>
      <div className={classes.productPageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["SDP Ledger Report"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
             

              <Grid item xs={2} sm={2}>
                <FormControl className={classes.fullWidth}>
                  {/* <InputLabel id="">
                    {DispensingLanguage[lan][menukey]["Item Group"]} 
                  </InputLabel>
                  <Select
                   // error={props.errorObject.SupCode}
                    labelId="GroupCode"
                    id="GroupCode"
                    name="GroupCode"
                   // value={'CON'}
                    value={currGroupCode}
                    fullWidth
                    onChange={handleChange}
                    disabled={
                      manyTableData.length > 0 ? true : props.bStockUpdate
                    }
                  >
                   
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                    {GroupNameList.map((item, index) => {
                      return (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      );
                    })}
                  </Select> */}

                      <Autocomplete
                        autoHighlight
                        disabled={
                          manyTableData.length > 0 ? true : props.bStockUpdate
                        }
                        disableClearable
                        id="GroupCode"
                        options={GroupNameList}
                        onChange={(event, valueobj) => handleChangeChoosen('GroupCode', valueobj, valueobj?valueobj.id:"")}
                        getOptionLabel={(option) => option.name}
                        value={chosenValues['GroupCode']}
                        renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          //value={props.formData.GroupCode}
                          label={DispensingLanguage[lan][menukey]["Item Group"]}
                          variant="standard"
                          id="GroupCode"
                          name="GroupCode"
                          fullWidth
                        />
                        )}
                      />

                </FormControl>
                  </Grid>

                   <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                      {/* <InputLabel id="">
                        {DispensingLanguage[lan][menukey]["Item Name"]} 
                      </InputLabel>
                      <Select
                       // error={props.errorObject.ItemCode}
                        labelId="ItemCode"
                        id="ItemCode"
                        name="ItemCode"
                        value={currItemCode}
                        fullWidth
                        onChange={handleChangeItemCode}
                        disabled={
                          manyTableData.length > 0 ? true : props.bStockUpdate
                        }
                      >
                        
                        <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                        {ItemList.map((item, index) => {
                          return (
                           <MenuItem value={item.id}> {item.name}</MenuItem>
                          );
                        })}
                      </Select> */}

                          <Autocomplete
                            autoHighlight
                            disabled={
                              manyTableData.length > 0 ? true : props.bStockUpdate
                            }
                            disableClearable
                            id="ItemCode"
                            options={ItemList}
                            onChange={(event, valueobj) => handleChangeChoosenItem('ItemCode', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValuesItem['ItemCode']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Item Name"]}
                              variant="standard"
                              id="ItemCode"
                              name="ItemCode"
                              fullWidth
                            />
                            )}
                          />
                    </FormControl>
                  </Grid> 

                  <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                      {/* <InputLabel id="">
                        {DispensingLanguage[lan][menukey]["SDP"]} 
                      </InputLabel>
                      <Select
                       // error={props.errorObject.ItemCode}
                        labelId="FacilityCode"
                        id="FacilityCode"
                        name="FacilityCode"
                        value={currSDPCode}
                        fullWidth
                        onChange={handleChangeSDP}
                        disabled={
                          manyTableData.length > 0 ? true : props.bStockUpdate
                        }
                      >
                        
                        <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                        {SDPList.map((item, index) => {
                          return (
                           <MenuItem value={item.id}> {item.name}</MenuItem>
                          );
                        })}
                      </Select> */}
                      
                      <Autocomplete
                            autoHighlight
                            disabled={
                              manyTableData.length > 0 ? true : props.bStockUpdate
                            }
                            disableClearable
                            id="FacilityCode"
                            options={SDPList}
                            onChange={(event, valueobj) => handleChangeChoosenSDP('FacilityCode', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValuesSDP['FacilityCode']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["SDP"]}
                              variant="standard"
                              id="FacilityCode"
                              name="FacilityCode"
                              fullWidth
                            />
                            )}
                          />
                    </FormControl>
                  </Grid> 
                 

                  <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      //disableToolbar
                      autoOk={true}
                      // disabled={true}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["Start Date"]}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["End Date"]}
                      autoOk={true}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>


              <Grid item xs={2} sm={2}>
                <div className="float-right sw_btn_control">
                  
                <ExportButtons PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}  {...props}></ExportButtons>

                  {/* <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button> */}
                </div>
              </Grid>

            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}

          <div className="uniqueName">
            <ReactTabulator
              ref={(r) => (tblRef = r)}
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="400px"
             
              options={{
                  groupBy: ["FGroupName", "FacilityName"],
                 
                  columnCalcs: "both",
                }}
             // rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IssueDetailsByItem;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
