import React, { useEffect, useState } from "react";//, 
import {makeStyles, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { 
  Grid,
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Card,
  CardContent,
  TextField,
  Typography
  

} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";  
import { useQuery, useMutation } from "react-query";
import * as api from "../../../actions/api";
import { useTranslation } from "react-i18next";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";
import moment from "moment";



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const SupplierModal =({ handleClose, openOrder, addOrder, ...props }) => {
  
  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "national-supplier";


const handleSubmit = async (e) => {
    //console.log("from submit ", formData);
    /*
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        menukey: menukey,
        lan: lan,
        UserName: UserName,
      };
      mutate(fDtat);
    }
    */
  };

  const [SupplierData, setSupplierData] = useState([]);

  const [btnDisable, setbtnDisable] = useState(false);
  const SaveSelectedProducts = () => {
      console.log("dddddddddddddddddd");
      addOrder('fffffffffffffffff');
      setbtnDisable(true);
      setTimeout(() => setbtnDisable(false), 500);
    
  }; 
 
  

  return (
    <div>
     
      <BootstrapDialog
        onClose={() => handleClose('SupplierModal')}
        aria-labelledby="customized-dialog-title"
        open={openOrder}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('SupplierModal')}
        >
         {DispensingLanguage[lan][menukey]['National Supplier Form - Add']}
        </BootstrapDialogTitle>


        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container spacing={10}>

               
                  <Grid item xs={4} sm={12}>
                    <Grid container spacing={3}>

                    <Grid item xs={6} sm={6}>
                        <TextField
                          //error={errorObject.SupName}
                          //helperText={errorObject.SupName}
                          required
                          disabled={props.permissionType}
                          id="SupName"
                          name="SupName"
                          label= {DispensingLanguage[lan][menukey]['Supplier_Name'] }
                          //value={0}//{formData.SupName}
                          fullWidth
                          autoComplete="family-name"
                          //onChange={(e) => handleChange(e)}
                        />
                    </Grid>

                    <Grid item xs={6} sm={6}>
                        <TextField
                          //error={errorObject.Address}
                          //helperText={errorObject.Address}
                          id="Address"
                          name="Address"
                          disabled={props.permissionType}
                          label= {DispensingLanguage[lan][menukey]['Address'] }
                          //value={formData.Address}
                          fullWidth
                          autoComplete="family-name"
                          //onChange={(e) => handleChange(e)}
                        />
                      </Grid> 
                      <Grid item xs={6} sm={6}>
                        <TextField
                         // error={errorObject.Phone}
                         // helperText={errorObject.Phone}
                          id="Phone"
                          name="Phone"
                          disabled={props.permissionType}
                          label= {DispensingLanguage[lan][menukey]['Phone'] }
                          //value={formData.Phone}
                          fullWidth
                          autoComplete="family-name"
                         // onChange={(e) => handleChange(e)}
                        />
                      </Grid> 
                      <Grid item xs={6} sm={6}>
                        <TextField
                         // error={errorObject.Fax}
                         // helperText={errorObject.Fax}
                          id="Fax"
                          name="Fax"
                          disabled={props.permissionType}
                          label= {DispensingLanguage[lan][menukey]['Fax'] }
                          //value={formData.Fax}
                          fullWidth
                          autoComplete="family-name"
                         // onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={6} sm={6} className="marginBottom20">
                        <TextField
                          //error={errorObject.EMail}
                         // helperText={errorObject.EMail}
                          id="EMail"
                          name="EMail"
                          //disabled={props.permissionType}
                          label= {DispensingLanguage[lan][menukey]['Email'] }
                          //value={formData.EMail}
                          fullWidth
                          autoComplete="family-name"
                          //onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      

                    </Grid> 
                </Grid>
                
                

            </Grid>
          </CardContent>
        </Card>

       
        <DialogActions>
          <Button 
          className="mr-2 float-left"
          variant="contained"
          color="primary" 
          onClick={SaveSelectedProducts}>
           {DispensingLanguage[lan][menukey]['Save']} 
          </Button>
          <Button autoFocus 
          className="float-left"
          variant="contained"
          
          onClick={() => handleClose('SupplierModal')}>
            {DispensingLanguage[lan][menukey]['Cancel']} 
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default SupplierModal;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});