import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as api from "../../../actions/api";
import {
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import { useQuery, useMutation, useQueryClient } from "react-query";

const EntryofproductFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  open,
  ...props
}) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "entry-edit-of-products";
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();

  const UnitList = JSON.parse(localStorage.getItem("Unitofmeasurelist"));

  const [firstLoad, setFirstLoad] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [manyTableData, setManyTableData] = useState([]);
  const queryClient = useQueryClient();

  //====== Start Choosen Dropdown===========
  const [chosenValues, setChosenValues] = useState({
    UnitId: { id: "", name: "" },
  });

  useEffect(() => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData["UnitId"] =
      UnitList[
        UnitList.findIndex((Unit_List) => Unit_List.id == formData.UnitId)
      ];
    setChosenValues(chosenValuesData);
  }, [formData.DesigCode, formData.UnitId]);

  const handleChangeChoosen = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;
    setChosenValues(chosenValuesData);
    props.handleChangeChoosenProps(name, value);
  };

  //====== End Choosen Dropdown===========

  const backtolist = () => {
    let master_dirty_message_class = document
      .getElementById("master_dirty_message")
      .getAttribute("class");
    let idx = master_dirty_message_class.indexOf("dnone");
    if (idx === -1) {
      // start Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey][
            "You have unsaved changes which will be lost. Continue?"
          ]
        ),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          props.history.push("/entry-edit-of-products");
        }
      });
      // End Alert
    } else {
      props.history.push("/entry-edit-of-products");
    }
  };

  const onSaveUpdateBtnClick = () => {
    setUpdatedDetailsGridDataHandler();
  };

  const setUpdatedDetailsGridDataHandler = () => {
    let validForm = props.checkValidateForm();

    // console.log('isval: ', validForm);
    if (validForm) {
      if (addProductForm) {
        //when save
        mutate(validForm);
      } else {
        //when update
        mutateUpdate(validForm);
      }
    }
  };

  const { isLoading: isLoadingSave, mutate } = useMutation(
    api.saveEntryofProdcutItem,
    {
      onSuccess: (data) => {
        console.log("data: ", data);
        if (data.status == 200) {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
          queryClient.getQueriesData("entryofproduct"); //api page name
          props.history.push("/entry-edit-of-products"); //
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
          queryClient.getQueriesData("entryofproduct"); //api page name
          props.history.push("/entry-edit-of-products"); //
        }
      },
    }
  );

  const { isLoading: isLoadingUpdate, mutate: mutateUpdate } = useMutation(
    api.updateItemProduct,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

          queryClient.getQueriesData("entryofproduct"); // include the page name
          props.history.push("/entry-edit-of-products"); //page url
          api.dirtyModeDisable();
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      },
    }
  );

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {" "}
                {
                  DispensingLanguage[lan][menukey]["Item List Entry - Add/Edit"]
                }{" "}
              </div>
            </div>
            <div className="float-right sw_btn_control">
              {addProductForm ? (
                <Grid item xs={12} className="text-center tex_margin">
                  <Button
                    disabled={props.bStockUpdate}
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => handleReset()}
                  >
                    {t(DispensingLanguage[lan][menukey]["Reset"])}
                  </Button>
                  <Button
                    disabled={props.bStockUpdate || isLoadingSave}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClick}
                    // onClick={() => handleSubmit()}
                  >
                    {t(DispensingLanguage[lan][menukey]["Save"])}
                  </Button>
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => backtolist()}
                  >
                    {t(DispensingLanguage[lan][menukey]["Back to List"])}
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={12} className="mt-2 text-center">
                  <Button
                    disabled={props.bStockUpdate || isLoadingUpdate}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClick}
                    // onClick={() => handleUpdate()}
                  >
                    {t(DispensingLanguage[lan][menukey]["Update"])}
                  </Button>
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    //onClick={() => props.history.push("/entry-edit-of-products")}
                    onClick={() => backtolist()}
                  >
                    {t(DispensingLanguage[lan][menukey]["Back to List"])}
                  </Button>
                </Grid>
              )}
            </div>

            <div className="mr-4 float-right mt-15">
              <span
                id="master_dirty_message"
                className={"masterredtextcolor dnone"}
              >
                {" "}
                {DispensingLanguage[lan][menukey]["You have unsaved changes"]}
              </span>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={10}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader
                title={DispensingLanguage[lan][menukey]["Item List Entry"]}
              />
              <CardContent>
                <Grid container spacing={10}>
                  <Grid item xs={4} sm={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          error={errorObject.ItemCode}
                          helperText={errorObject.ItemCode}
                          required
                          disabled={props.permissionType}
                          id="ItemCode"
                          name="ItemCode"
                          label={DispensingLanguage[lan][menukey]["Item Code"]}
                          value={formData.ItemCode}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          error={errorObject.ItemName}
                          helperText={errorObject.ItemName}
                          disabled={props.permissionType}
                          id="ItemName"
                          name="ItemName"
                          label={DispensingLanguage[lan][menukey]["Item Name"]}
                          value={formData.ItemName}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                        <FormControl className={classes.fullWidth}>
                          {/* <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]['Unit']}*
                          </InputLabel>
                          <Select

                            error={errorObject.UnitId}
                            required
                            labelId="demo-simple-select-helper-label"
                            id="UnitId"
                            name="UnitId"
                            value={formData.UnitId}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                          >
                            <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                            {
                              UnitList.map(
                                (item, index) => {
                                  return (
                                    <MenuItem value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                }
                              )}
                          </Select>


                          <FormHelperText error={errorObject.UnitId}>
                            {errorObject.UnitId}

                          </FormHelperText> */}

                          <Autocomplete
                            autoHighlight
                            id="UnitId"
                            disabled={props.permissionType}
                            options={UnitList}
                            onChange={(event, valueobj) =>
                              handleChangeChoosen(
                                "UnitId",
                                valueobj,
                                valueobj ? valueobj.id : ""
                              )
                            }
                            getOptionLabel={(option) => option.name}
                            value={chosenValues["UnitId"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.name}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.UnitId}
                                label={
                                  DispensingLanguage[lan][menukey]["Unit"] +
                                  " *"
                                }
                                variant="standard"
                                error={errorObject.UnitId}
                                helperText={errorObject.UnitId}
                                id="UnitId"
                                name="UnitId"
                                fullWidth
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={6} sm={2} className="sw_active_patient">
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              checked={formData.isAsset}
                              onChange={(e) => handleCheck(e)}
                              name="isAsset"
                              value="no"
                              disabled={props.permissionType}
                            />
                          }
                          label={DispensingLanguage[lan][menukey]["Is Asset"]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
        </Grid>
      </div>
    </div>
  );
};

export default EntryofproductFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
