import React, { useState } from "react";
import { Typography } from "@material-ui/core";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import swal from "sweetalert";
import * as api from "../../../actions/api";
import UserEntryFormData from "./UserEntryFormData.js";
 

//get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "user-entry";

const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;  
const userId = UserInfo==0?'': UserInfo[0].id;
const UserName = UserInfo==0?'': UserInfo[0].id;
const UserEntryEdit = (props) => {

 

  const [formData, setFormData] = useState({
    id: "",
    name: "",
    email: "",
    loginname: "",
    password: "",
    designation: "",
    //LangCode: "",
    role_id: "",
    FacilityId: "",
    IsActive: false,
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data } = useQuery(
    ["userlist", id],
    () =>
      api.getUser(id).then((res) => {
        
        setFormData(res.data);
        return res.data;
      }),
    {
      enabled: Boolean(id),
    }
  );

  // const { mutate } = useMutation(api.updateUser, {
  //   onSuccess: (data) => {
  //     if (data.status == 200) {
         
  //      //  
  //       if(data.data.switchUserData==1){
  //         if(userId==data.data.UserId){
            
  //         const auth_token = sessionStorage.getItem("token")
  //         ? sessionStorage.getItem("token")
  //         : null;
  //         let options = {};
  //         options = {
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${auth_token}`,
  //           },
  //           task:'UOM'
  //         };

  //         api.getDispensingLanguage(options).then((res1) => {

            
  //           //console.log(res)
  //           let DispensingLanguageData = res1.datalist;
  //           localStorage.setItem(
  //             "DispensingLanguage",
  //             JSON.stringify(DispensingLanguageData)
  //           );

  //           api.getAllDropdown('ALL').then((response) => {
  //             if (response.success == 1) {
  //               localStorage.setItem(
  //                 "AdjType",
  //                 JSON.stringify(response.datalist.AdjType)
  //               );
  //              /*  localStorage.setItem(
  //                 "CountryList",
  //                 JSON.stringify(response.datalist.CountryList)
  //               ); */
  //              /*  localStorage.setItem(
  //                 "DirectionList",
  //                 JSON.stringify(response.datalist.DirectionList)
  //               ); */
  //               /* localStorage.setItem(
  //                 "DosageFormlist",
  //                 JSON.stringify(response.datalist.DosageFormlist)
  //               ); */
  //               localStorage.setItem(
  //                 "FacilityList",
  //                 JSON.stringify(response.datalist.FacilityList)
  //               );
  //              /*  localStorage.setItem(
  //                 "GenericList",
  //                 JSON.stringify(response.datalist.GenericList)
  //               ); */
  //               /* localStorage.setItem(
  //                 "IntervalList",
  //                 JSON.stringify(response.datalist.IntervalList)
  //               ); */
  //              /*  localStorage.setItem(
  //                 "OrderType",
  //                 JSON.stringify(response.datalist.OrderType)
  //               ); */
  //               /* localStorage.setItem(
  //                 "PackSizelist",
  //                 JSON.stringify(response.datalist.PackSizelist)
  //               ); */
  //               /* localStorage.setItem(
  //                 "PostList",
  //                 JSON.stringify(response.datalist.PostList)
  //               ); */
  //               /* localStorage.setItem(
  //                 "RouteOfAdminlist",
  //                 JSON.stringify(response.datalist.RouteOfAdminlist)
  //               ); */
  //               /* localStorage.setItem(
  //                 "RxLevelList",
  //                 JSON.stringify(response.datalist.RxLevelList)
  //               ); */
  //               /* localStorage.setItem(
  //                 "SpecialisationList",
  //                 JSON.stringify(response.datalist.SpecialisationList)
  //               ); */
  //              /*  localStorage.setItem(
  //                 "Strengthlist",
  //                 JSON.stringify(response.datalist.Strengthlist)
  //               ); */
  //               /* localStorage.setItem(
  //                 "TitleList",
  //                 JSON.stringify(response.datalist.TitleList)
  //               ); */
  //              /*  localStorage.setItem(
  //                 "VEN_List",
  //                 JSON.stringify(response.datalist.VEN_List)
  //               ); */

  //               localStorage.setItem(
  //                 "Unitofmeasurelist",
  //                 JSON.stringify(response.datalist.Unitofmeasurelist)
  //               );

  //               /* localStorage.setItem(
  //                 "ABC_List",
  //                 JSON.stringify(response.datalist.ABC_List)
  //               ); */

  //               /* localStorage.setItem(
  //                 "language_preference",
  //                 JSON.stringify(response.datalist.t_language_preference)
  //               ); */
  //               /* localStorage.setItem(
  //                 "Gender",
  //                 JSON.stringify(response.datalist.Gender)
  //               ); */
  //              /*  localStorage.setItem(
  //                 "MaritalStatus_List",
  //                 JSON.stringify(response.datalist.MaritalStatus_List)
  //               ); */
  //               /* localStorage.setItem(
  //                 "LABTest_List",
  //                 JSON.stringify(response.datalist.LABTest_List)
  //               ); */
  //               localStorage.setItem(
  //                 "MenuList",
  //                 JSON.stringify(response.datalist.MenuList)
  //               );

               
  //               localStorage.setItem(
  //                 "UserList",
  //                 JSON.stringify(response.datalist.UserList)
  //               );

  //               localStorage.setItem(
  //                 "UserListEdit",
  //                 JSON.stringify(response.datalist.UserListEdit)
  //               );

  //               localStorage.setItem(
  //                 "YearList",
  //                 JSON.stringify(response.datalist.YearList)
  //               );

  //               localStorage.setItem(
  //                 "MonthList",
  //                 JSON.stringify(response.datalist.MonthList)
  //               );

  //               /* localStorage.setItem(
  //                 "Direction",
  //                 JSON.stringify(response.datalist.Direction)
  //               ); */

  //               localStorage.setItem(
  //                 "RoleList",
  //                 JSON.stringify(response.datalist.RoleList)
  //               );

  //               localStorage.setItem(
  //                 "LanguageList",
  //                 JSON.stringify(response.datalist.LanguageList)
  //               );
  //               localStorage.setItem(
  //                 "UseFor", 
  //                 data.data.FacilityId.substring(0, 1)==='T'?'UIMS':'WIMS'
  //               );
                
  //               localStorage.setItem(
  //                 "FacilityId",
  //                 data.data.FacilityId
  //               );
  //               for (
  //                 let i = 0;
  //                 i < response.datalist.FacilityList.length;
  //                 i++
  //               ) {
  //                 if (
  //                   response.datalist.FacilityList[i]["id"] ==
  //                   data.data.FacilityId
  //                 ) {
  //                   localStorage.setItem(
  //                     "FacilityName",
  //                     response.datalist.FacilityList[i]["name"]
  //                   );
  //                   // console.log('response.datalist.FacilityList[i][]: ', response.datalist.FacilityList[i]['name']);
  //                   break;
  //                 } else {
  //                   localStorage.setItem("FacilityName", "");
  //                   //  console.log('FacilityName: ', 'FacilityName');
  //                 }
  //               }

  //               //localStorage.setItem("LangCode", data.data.LangCode);

                 

  //               api
  //                 .getLoginFacilityListNotEqual99ANDSuppliers(options,'ALL')
  //                 .then((response) => {

  //                   localStorage.setItem(
  //                     "OrderTo",
  //                     JSON.stringify(response.datalist.OrderTo)
  //                   );

  //                   localStorage.setItem(
  //                     "PrescriberList",
  //                     JSON.stringify(response.datalist.PrescriberList)
  //                   );
  //                   localStorage.setItem(
  //                     "DispenserList",
  //                     JSON.stringify(response.datalist.DispenserList)
  //                   );

                     

  //                   //queryClient.getQueriesData("userlist");
  //                  // props.history.push("/user-entry");
  //                   window.location.href = `${process.env.REACT_APP_BASE_NAME}/user-entry`;
  //                 });
  //             }

  //             //
  //           });
  //         });
  //       }else{
  //         queryClient.getQueriesData("userlist");
  //         props.history.push("/user-entry");
  //       }


  //       }else{
  //         queryClient.getQueriesData("userlist");
  //         props.history.push("/user-entry");
  //       }
  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.data.message,
  //         msgtype: data.data.success,
  //       });
  //       queryClient.getQueriesData("userlist");
  //         props.history.push("/user-entry");
  //         api.dirtyModeDisable();
  //     }else{
  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.message,
  //         msgtype: data.success,
  //       });
  //       queryClient.getQueriesData("userlist");
  //         props.history.push("/user-entry");
  //     }
      
  //   },
  // });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;
    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();

  };

  
    //====== Start Choosen Dropdown===========
    const handleChangeChoosenProps = (name, value) => {
      let data = { ...formData };
      data[name] = value;   
      setFormData(data); 
      setErrorObject({ ...errorObject, [name]: "" });
      api.dirtyModeEnable();
    }
    //====== End Choosen Dropdown===========

  const handleCheck = (e) => {
     

    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
    api.dirtyModeEnable();
  };

  const handleReset = () => {
    setFormData({
      adminid: "",
      name: "",
      email: "",
      loginname: "",
      password: "",
      designation: "",
      //LangCode: "",
      role_id: "",
      FacilityId: "",
      IsActive: false,
    });
  };

  const validateForm = (formData) => {
    let validateFields = [
      "name",
      "email",
      "loginname",
      "designation",
      //"LangCode",
      "role_id",
    ];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {


      let EmailCheck = '';
      if((formData['email'] == 'null') || (formData['email'] == null)){
        EmailCheck = '';
      }else{
        EmailCheck = formData['email'];
      }
     
      if (EmailCheck !==''){
        let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(!EmailCheck.match(mailformat)) {
          errorData['email'] = (DispensingLanguage[lan][menukey]["Email is not valid !"]);;//DispensingLanguage[lan][menukey]["field is Required !"];
          isValid = false;
        } 
      }

      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }

      if (formData["loginname"].indexOf(" ") >= 0) {
        errorData["loginname"] =
          DispensingLanguage[lan][menukey][
            "White space is not allowed in login name."
          ];
        isValid = false;
      }

      //-----start confirm change password-----
        let cpassword = '';
        let cconfirmChangePassword = '';

        if(formData["password"]){
          cpassword = formData["password"].trim();
        }else{
          cpassword = '';
        }

        if(formData["confirmChangePassword"]){
          cconfirmChangePassword = formData["confirmChangePassword"].trim();
        }else{
          cconfirmChangePassword = '';
        }
       
        if (cpassword !== ''){

          let strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])');
          let passStrengthCheck = strongPassword.test(cpassword);
          
          if(passStrengthCheck){
              if (cconfirmChangePassword == ''){
                    errorData["confirmChangePassword"] = DispensingLanguage[lan][menukey]["Enter Confirm Password"];
                    isValid = false;
                }else if (cpassword != cconfirmChangePassword){
                  errorData["confirmChangePassword"] = DispensingLanguage[lan][menukey]["Password did not match"];
                  isValid = false;
                } else{
                  errorData["confirmChangePassword"] = '';
                }
              }else{
                errorData["password"] = DispensingLanguage[lan][menukey]["Password format check"];
                isValid = false;
           }




        } 

        //-----end confirm change password-----




    });
    setErrorObject(errorData);
    return isValid;
  };
  function checkValidateForm(){

     
    if (validateForm(formData)) {
    let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName,}; 
   
    return fDtat;
    }else{
    return false;
    }

};

  // const handleUpdate = async (e) => {
  //   if (validateForm(formData)) {
  //     let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
  //     mutate(fDtat);
     
       
  //   }
  // };

  return (
    <>
      <UserEntryFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleCheck={handleCheck}
        handleReset={handleReset}
        checkValidateForm={checkValidateForm}
        // handleUpdate={handleUpdate}
        bStockUpdate={props.permissionType}
        {...props}
      />
    </>
  );
};

export default UserEntryEdit;
