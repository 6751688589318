import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel,
  RadioGroup,
  MenuItem,
  Paper,
  CardHeader,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "products";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ProductsModal = ({ handleClose, open, addProduct, ...props }) => {
  let tblRef = null;
  let submitBtnRef = useRef();

  const { isLoading, error, isError, data, refetch } = useQuery(
    "AssetRegisterServer",
    api.getAssetList
  );
  const [selectedProducts, setselectedProducts] = useState([]);

  const ProductColumns = [
    { title: "id", field: "ItemNo", visible: false },
    { title: "ItemNo", field: "ItemNo", visible: false },
    { title: "Generic Name", field: "GenericName", visible: false },
    { title: DispensingLanguage[lan][menukey]['Sl#'],  formatter: "rownum", width:50, hozAlign: "center" , headerHozAlign:"center", headerSort:false},
    // { title: "Group Name", field: "GroupName", width: 140, headerFilter:true },
    { title: DispensingLanguage[lan][menukey]['Item Code'], field: "ItemCode", width: 160, headerFilter:true },
    { title: DispensingLanguage[lan][menukey]['Item Group'], field: "GroupName", headerFilter:true },
    { title: DispensingLanguage[lan][menukey]['Item Name'], field: "ItemName", headerFilter:true  },
    { title: DispensingLanguage[lan][menukey]['Unit'], field: "UnitName", width: 80},
    // { title: DispensingLanguage[lan][menukey]['Product Price'], field: "UnitPrice", width: 80, hozAlign: "right", headerHozAlign:"right"  },
  ];

  let selected = [];
  const ProdustDataItems = data?.map((item, index) => { 
    return item;
  });

  const [btnDisable, setbtnDisable] = useState(false);

  const SaveSelectedProducts = () => {
    
    if(tblRef.table.getSelectedData().length>0)
      setbtnDisable(true);
      setselectedProducts(tblRef.table.getSelectedData());
      setTimeout(() => setbtnDisable(false), 500);
    
  }; 



/*   const SaveSelectedProducts = () => {
    if(tblRef.table.getSelectedData().length>0){ 
      
      if (submitBtnRef == null) return;
      else submitBtnRef.current.disabled = true; 
      setselectedProducts(tblRef.table.getSelectedData());
    }
    
    
  };
 */

  useEffect(() => { 
    if (tblRef == null) return;
    addProduct(selectedProducts);

}, [selectedProducts]); 


  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose('Products')}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('Products')}
        >
        {DispensingLanguage[lan][menukey]['Item List']} 
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <ReactTabulator
            ref={(r) => (tblRef = r)}
            options={{
              selectable: '1', // Only allow selecting one row at a time
              selectableRangeMode: 'click',
            }}
           
            columns={ProductColumns}
            data={ProdustDataItems}
            height="300px"
            layout={"fitColumns"}

          />
        </DialogContent>
        <DialogActions>
          <Button
            ref={submitBtnRef}
            disabled={btnDisable}
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={SaveSelectedProducts}
          >
           {DispensingLanguage[lan][menukey]['Add']}
          </Button>
          <Button
            autoFocus
            className="float-left"
            variant="contained" 
            onClick={() => handleClose('Products')}
          >
            {DispensingLanguage[lan][menukey]['Cancel']}   
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ProductsModal;
