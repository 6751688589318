import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import swal from "sweetalert";
import * as api from "../../../actions/api";
import { useMutation } from "react-query";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  Card,
  CardContent,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DeleteOutline, Edit, ViewList, FileCopy } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import { useTranslation } from "react-i18next";
import ProductsModal from "../../../services/StandingBoardItemsModal";
import DisapprovedProductsModal from "../../../services/DisapprovedStandingBoardItemsModal";
import SourceModal from "../../../services/SourceModal";
import ContractModalByYear from "../../../services/ContractModalByYear";
import { cellFocusEditor, dateEditor } from "../../../services/Common";
import moment from "moment";
let timeStamp = "";


const MasterManyFormData = (props) => {

  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "standing-board-entry";
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  let ReceiveDetailsRef = null;
  let DisapprovedItemsRef = null;

  const [newBatchRowAdded, setNewBatchRowAdded] = useState(false);

  const [open, setOpen] = useState(false);
  const [OpenDisapprovedProducts, setOpenDisapprovedProducts] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);

  const [SoureOpen, setSoureOpen] = useState(false);

  const [openContract, setContractOpen] = useState(false);

  const [manyTableData, setManyTableData] = useState([]);
  const [dispprovemanyTableData, setDispprovedManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);
  const SupplierList = JSON.parse(localStorage.getItem("SupplierListNational"));
  const FacilityEmployee = JSON.parse(localStorage.getItem("FacilityEmployee"));
  const IndentorList = JSON.parse(localStorage.getItem("IndentorList"));
  const PackageYearList = JSON.parse(localStorage.getItem("PackageYearList"));

  const [currRowId, setCurrRowId] = useState(0);
  const [currFormulation, setCurrFormulation] = useState("");
  const [bIsDisable, setbIsEnable] = useState(false);

  const [currYearId, setcurrYearId] = useState("");
  const [currContractNo, setcurrContractNo] = useState("");


  
//====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "YearId": {"YearId": "","PackageYear": ""}
});


useEffect(() => {
  let chosenValuesData = { ...chosenValues};
   chosenValuesData['YearId'] = PackageYearList[PackageYearList.findIndex(PackageYear_List => PackageYear_List.YearId == props.formData.YearId)]; 
   setChosenValues(chosenValuesData); 

}, [props.formData.YearId]);

const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  setChosenValues(chosenValuesData); 
  props.handleChangeChoosenProps(name, value);
};

//====== End Choosen Dropdown===========




  /* =====Start of Print Excel Many Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL +
      "report/receive_from_supplier_entry_list_print.php";

    window.open(
      finalUrl +
      "?menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&TransactionId=" +
      props.formData.TransactionId +
      "&FacilityId=" +
      FacilityId +
      "&FacilityName=" +
      FacilityName +
      "&TimeStamp=" +
      Date.now()
    );
  };

  const ExcelMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL +
      "report/esigl_receive_from_warehouse_entry_list_excel.php";

    window.open(
      finalUrl +
      "?menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&TransactionId=" +
      props.formData.TransactionId +
      "&FacilityId=" +
      FacilityId +
      "&TimeStamp=" +
      Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;
      case "DisapprovedProducts":
        setOpenDisapprovedProducts(false);
        break;
      case "DisapprovedProductsOpen":
        setOpenDisapprovedProducts(true);
        break;
      case "OrderModal":
        setOpenOrder(false);
        break;
      case "OrderOpen":
        setOpenOrder(true);
        break;

      case "SourceOpen":
        setSoureOpen(true);
        break;

      case "SourceClose":
        setSoureOpen(false);
        break;

      case "Contract":
        setContractOpen(false);
        break;

      case "ContractOpen":
        setContractOpen(true);
        break;

      default:
        break;
    }
  };

  const addProduct = (NonOrderProductData) => {
    
    //console.log("approved product modal...............");
    let temNonordata = NonOrderProductData;
    let rows = [];

    let autoId = 0;
    temNonordata.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;
      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.UnitName = row.UnitName;
      newRow.ContractQty = row.ContractQty;
      // newRow.SourceId = null;
      // newRow.SourceName = null;

      newRow.TransactionItemId = null;
      newRow.OrderRemainingQty = 0;
      newRow.CurrentStockQty = 0;
      newRow.Quantity = row.Quantity;
      newRow.PRQuantity = row.PRQuantity;
      newRow.BatchNo = row.BatchNo;
      newRow.SupDate = row.SupDate;
      newRow.SupRef = row.SupRef;
      newRow.ItemSl = row.ItemSl;
      newRow.SupCode = row.SupCode;
      
      newRow.MfgDate = row.MfgDate;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = 0;
      rows.push(newRow);
    });

    manyTableData.forEach((row, i) => {

      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;

      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.UnitName = row.UnitName;
      newRow.ContractQty = row.ContractQty;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.OrderRemainingQty = row.OrderRemainingQty;
      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.Quantity = row.Quantity;
      newRow.PRQuantity = row.PRQuantity;
      newRow.BatchNo = row.BatchNo;
      newRow.MfgDate = row.MfgDate;
      newRow.SupDate = row.SupDate;
      newRow.SupRef = row.SupRef;
      newRow.ItemSl = row.ItemSl;
      newRow.SupCode = row.SupCode;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = 0;//row.Quantity * row.UnitPrice;

      // newRow.SourceId = row.SourceId;
      // newRow.SourceName = row.SourceName;

      rows.push(newRow);
    });

    setManyTableData(rows);
    props.handleAdd(rows);
    props.handleAddManyMerge(rows);
    handleClose("Products");
  };



  const addProductDisapproved = (NonOrderProductData) => {
    //console.log("disapproved product modal...............");
    let temNonordata = NonOrderProductData;
    let rows = [];

    let autoId = 0;
    temNonordata.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;

      newRow.ItemCode = row.ItemCode;

      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.UnitName = row.UnitName;
      newRow.ContractQty = row.ContractQty;

      newRow.SourceId = null;
      newRow.SourceName = null;

      newRow.TransactionItemId = null;
      newRow.OrderRemainingQty = 0;

      // manyTableData.forEach((rowssss, ij) => {
      //   if (rowssss.ItemNo == row.ItemNo) {
      //     newRow.OrderRemainingQty = rowssss.OrderRemainingQty;
      //     return;
      //   }
      // });

      newRow.CurrentStockQty = 0;
      newRow.Quantity = row.Quantity;
      newRow.PRQuantity = row.PRQuantity;
      newRow.BatchNo = row.BatchNo;
      newRow.MfgDate = row.MfgDate;
      newRow.SupDate = row.SupDate;
      newRow.SupRef = row.SupRef;
      newRow.ItemSl = row.ItemSl;
      newRow.SupCode = row.SupCode;

      newRow.Remarks = null;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = 0;
      rows.push(newRow);
    });

    dispprovemanyTableData.forEach((row, i) => {

      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;

      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.UnitName = row.UnitName;
      newRow.ContractQty = row.ContractQty;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.OrderRemainingQty = row.OrderRemainingQty;
      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.Quantity = row.Quantity;
      newRow.PRQuantity = row.PRQuantity;
      newRow.BatchNo = row.BatchNo;
      newRow.MfgDate = row.MfgDate;
      newRow.SupDate = row.SupDate;
      newRow.SupRef = row.SupRef;
      newRow.ItemSl = row.ItemSl;
      newRow.SupCode = row.SupCode;
      
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.Remarks = row.Remarks;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = 0;// row.Quantity * row.UnitPrice;

      newRow.SourceId = row.SourceId;
      newRow.SourceName = row.SourceName;

      rows.push(newRow);
    });

    setDispprovedManyTableData(rows);
    props.handleAdd(rows);
    props.handleAddManyMerge(rows);
    handleClose("DisapprovedProducts");
  };



  const addDisapprovedProductRowOnChange = (cell) => {
    let rowData = cell._cell.row.data;
    //console.log("PRQuantity=====", rowData.PRQuantity, "Quantity===", rowData.Quantity);


    //if (rowData.PRQuantity !== rowData.Quantity) {
    if (Number(rowData.PRQuantity) !== Number(rowData.Quantity)) {

          if(Number(rowData.PRQuantity) < Number(rowData.Quantity)){
                 //remove item from right table
                  //console.log("PRQuantity===== 333");

                  let autoId = 0;
                  let rows = [];
                  let trows = [];
                  let newRow = {};


                  let DisApprovedQty = (rowData.PRQuantity - rowData.Quantity);

                  newRow.ItemNo = rowData.ItemNo;
                  newRow.ItemCode = rowData.ItemCode;
                  newRow.ItemName = rowData.ItemName;
                  newRow.ContractQty = rowData.ContractQty;
                  newRow.OrderRemainingQty = rowData.OrderRemainingQty;
                  newRow.Quantity = DisApprovedQty;
                  newRow.PRQuantity = rowData.PRQuantity;
                  newRow.BatchNo = rowData.BatchNo;
                  newRow.MfgDate = rowData.MfgDate;
                  newRow.Remarks = null;
                  newRow.SupDate = rowData.SupDate;
                  newRow.SupRef = rowData.SupRef;
                  newRow.ItemSl = rowData.ItemSl;
                  newRow.SupCode = rowData.SupCode;

                  newRow.ExpiryDate = rowData.ExpiryDate;

                  trows.push(newRow);
                  //  });


                  //let filteredProductList = dispprovemanyTableData.filter(o1 => !trows.some(o2 => o1.ItemCode === o2.ItemCode));
                  let filteredProductList = dispprovemanyTableData.filter(o1 => !trows.some(o2 => (o1.ItemCode === o2.ItemCode) && (o1.BatchNo === o2.BatchNo) && (o1.MfgDate === o2.MfgDate) && (o1.ExpiryDate === o2.ExpiryDate) && (o1.ItemSl === o2.ItemSl)));
                

                  //dispprovemanyTableData.forEach((row, i) => {
                  filteredProductList.forEach((row, i) => {

                    autoId++;
                    let newRow = {};
                    newRow.ItemNo = row.ItemNo;
                    newRow.ItemCode = row.ItemCode;

                    newRow.ItemName = row.ItemName;
                    newRow.UnitPrice = row.UnitPrice;
                    newRow.UnitName = row.UnitName;
                    newRow.ContractQty = row.ContractQty;
                    newRow.TransactionItemId = row.TransactionItemId;
                    newRow.OrderRemainingQty = row.OrderRemainingQty;
                    newRow.CurrentStockQty = row.CurrentStockQty;
                    newRow.Quantity = row.Quantity;
                    newRow.PRQuantity = row.PRQuantity;
                    newRow.BatchNo = row.BatchNo;
                    newRow.MfgDate = row.MfgDate;
                    newRow.SupDate = row.SupDate;
                    newRow.SupRef = row.SupRef;
                    newRow.ItemSl = row.ItemSl;
                    newRow.SupCode = row.SupCode;
                  
                    newRow.ExpiryDate = row.ExpiryDate;
                    newRow.Remarks = row.Remarks;
                    newRow.SKU = autoId;
                    newRow.id = autoId;
                    newRow.LineTotal = row.Quantity * row.UnitPrice;

                    newRow.SourceId = row.SourceId;
                    newRow.SourceName = row.SourceName;

                    rows.push(newRow);
                  });

                  setDispprovedManyTableData(rows);
          }else{

                 //calculate disapproved item in right table
                 // console.log("PRQuantity===== 111");
                  

                    let autoId = 0;
                    let rows = [];
                    let newRow = {};


                    //  dispprovemanyTableData.forEach((row, i) => {

                    //autoId++;
                    let DisApprovedQty = (rowData.PRQuantity - rowData.Quantity);

                    newRow.ItemNo = rowData.ItemNo;
                    newRow.ItemCode = rowData.ItemCode;
                    newRow.ItemName = rowData.ItemName;
                    newRow.ContractQty = rowData.ContractQty;
                    newRow.OrderRemainingQty = rowData.OrderRemainingQty;
                    newRow.Quantity = DisApprovedQty;
                    newRow.PRQuantity = rowData.PRQuantity;
                    newRow.BatchNo = rowData.BatchNo;
                    newRow.MfgDate = rowData.MfgDate;
                    newRow.SupDate = rowData.SupDate;
                    newRow.SupRef = rowData.SupRef;
                    newRow.ItemSl = rowData.ItemSl;
                    newRow.SupCode = rowData.SupCode;
                    
                    newRow.ExpiryDate = rowData.ExpiryDate;
                    newRow.Remarks = null;

                    rows.push(newRow);
                    //  });


                    //let filteredProductList = dispprovemanyTableData.filter(o1 => !rows.some(o2 => o1.ItemCode === o2.ItemCode));
                    let filteredProductList = dispprovemanyTableData.filter(o1 => !rows.some(o2 => (o1.ItemCode === o2.ItemCode) && (o1.BatchNo === o2.BatchNo)&& (o1.MfgDate === o2.MfgDate)&& (o1.ExpiryDate === o2.ExpiryDate) && (o1.ItemSl === o2.ItemSl)));
                  
                    //dispprovemanyTableData.forEach((row, i) => {
                    filteredProductList.forEach((row, i) => {

                      autoId++;
                      let newRow = {};
                      newRow.ItemNo = row.ItemNo;
                      newRow.ItemCode = row.ItemCode;

                      newRow.ItemName = row.ItemName;
                      newRow.UnitPrice = row.UnitPrice;
                      newRow.UnitName = row.UnitName;
                      newRow.ContractQty = row.ContractQty;
                      newRow.TransactionItemId = row.TransactionItemId;
                      newRow.OrderRemainingQty = row.OrderRemainingQty;
                      newRow.CurrentStockQty = row.CurrentStockQty;
                      newRow.Quantity = row.Quantity;
                      newRow.PRQuantity = row.PRQuantity;
                      newRow.BatchNo = row.BatchNo;
                      newRow.MfgDate = row.MfgDate;
                      newRow.SupDate = row.SupDate;
                      newRow.SupRef = row.SupRef;
                      newRow.ItemSl = row.ItemSl;
                      newRow.SupCode = row.SupCode;
                      
                      newRow.ExpiryDate = row.ExpiryDate;
                      newRow.Remarks = row.Remarks;
                      newRow.SKU = autoId;
                      newRow.id = autoId;
                      newRow.LineTotal = row.Quantity * row.UnitPrice;

                      newRow.SourceId = row.SourceId;
                      newRow.SourceName = row.SourceName;

                      rows.push(newRow);
                    });

                    setDispprovedManyTableData(rows);
          }


            

            

    }


      //remove item from left table
      if (Number(rowData.Quantity) == 0) {

        //console.log("PRQuantity===== 222");

        let autoId = 0;
        let rows = [];
        let trows = [];
        let newRow = {};
  
        //  dispprovemanyTableData.forEach((row, i) => {
  
        //autoId++;
        let DisApprovedQty = (rowData.PRQuantity - rowData.Quantity);
  
        newRow.ItemNo = rowData.ItemNo;
        newRow.ItemCode = rowData.ItemCode;
        newRow.ItemName = rowData.ItemName;
        newRow.ContractQty = rowData.ContractQty;
        newRow.OrderRemainingQty = rowData.OrderRemainingQty;
        newRow.Quantity = DisApprovedQty;
        newRow.PRQuantity = rowData.PRQuantity;
        newRow.BatchNo = rowData.BatchNo;
        newRow.SupDate = rowData.SupDate;
        newRow.MfgDate = rowData.MfgDate;
        newRow.ItemSl = rowData.ItemSl;
        newRow.SupCode = rowData.SupCode;
        
        newRow.Remarks = null;
        newRow.SupRef = rowData.SupRef;
        newRow.ExpiryDate = rowData.ExpiryDate;
  
        trows.push(newRow);
        //  });
  
  
        //let filteredProductList = manyTableData.filter(o1 => !trows.some(o2 => o1.ItemCode === o2.ItemCode));
        let filteredProductList = manyTableData.filter(o1 => !trows.some(o2 => (o1.ItemCode === o2.ItemCode) && (o1.BatchNo === o2.BatchNo)&& (o1.MfgDate === o2.MfgDate) && (o1.ExpiryDate === o2.ExpiryDate) && (o1.ItemSl === o2.ItemSl)));
     
  
  
        //dispprovemanyTableData.forEach((row, i) => {
        filteredProductList.forEach((row, i) => {
  
          autoId++;
          let newRow = {};
          newRow.ItemNo = row.ItemNo;
          newRow.ItemCode = row.ItemCode;
  
          newRow.ItemName = row.ItemName;
          newRow.UnitPrice = row.UnitPrice;
          newRow.UnitName = row.UnitName;
          newRow.ContractQty = row.ContractQty;
          newRow.TransactionItemId = row.TransactionItemId;
          newRow.OrderRemainingQty = row.OrderRemainingQty;
          newRow.CurrentStockQty = row.CurrentStockQty;
          newRow.Quantity = row.Quantity;
          newRow.PRQuantity = row.PRQuantity;
          newRow.BatchNo = row.BatchNo;
          newRow.MfgDate = row.MfgDate;
          newRow.SupDate = row.SupDate;
          newRow.SupRef = row.SupRef;
          newRow.ItemSl = row.ItemSl;
          newRow.SupCode = row.SupCode;
          newRow.ExpiryDate = row.ExpiryDate;
          newRow.Remarks = row.Remarks;
          newRow.SKU = autoId;
          newRow.id = autoId;
          newRow.LineTotal = 0;//row.Quantity * row.UnitPrice;
  
          newRow.SourceId = row.SourceId;
          newRow.SourceName = row.SourceName;
  
          rows.push(newRow);
        });
  
        setManyTableData(rows);
  
      }

    //remove item from right table
    // if ((rowData.PRQuantity < rowData.Quantity) || (rowData.PRQuantity == rowData.Quantity)) {
    if (Number(rowData.PRQuantity) == Number(rowData.Quantity)) {
      //remove item from right table
      //console.log("PRQuantity===== 333");

      let autoId = 0;
      let rows = [];
      let trows = [];
      let newRow = {};


      //  dispprovemanyTableData.forEach((row, i) => {

      //autoId++;
      let DisApprovedQty = (rowData.PRQuantity - rowData.Quantity);

      newRow.ItemNo = rowData.ItemNo;
      newRow.ItemCode = rowData.ItemCode;
      newRow.ItemName = rowData.ItemName;
      newRow.ContractQty = rowData.ContractQty;
      newRow.OrderRemainingQty = rowData.OrderRemainingQty;
      newRow.Quantity = DisApprovedQty;
      newRow.PRQuantity = rowData.PRQuantity;
      newRow.BatchNo = rowData.BatchNo;
      newRow.MfgDate = rowData.MfgDate;
      newRow.Remarks = null;
      newRow.SupDate = rowData.SupDate;
      newRow.SupRef = rowData.SupRef;
      newRow.ItemSl = rowData.ItemSl;
      newRow.SupCode = rowData.SupCode;

      newRow.ExpiryDate = rowData.ExpiryDate;

      trows.push(newRow);
      //  });


      //let filteredProductList = dispprovemanyTableData.filter(o1 => !trows.some(o2 => o1.ItemCode === o2.ItemCode));
      let filteredProductList = dispprovemanyTableData.filter(o1 => !trows.some(o2 => (o1.ItemCode === o2.ItemCode) && (o1.BatchNo === o2.BatchNo) && (o1.MfgDate === o2.MfgDate) && (o1.ExpiryDate === o2.ExpiryDate) && (o1.ItemSl === o2.ItemSl)));
     

      //dispprovemanyTableData.forEach((row, i) => {
      filteredProductList.forEach((row, i) => {

        autoId++;
        let newRow = {};
        newRow.ItemNo = row.ItemNo;
        newRow.ItemCode = row.ItemCode;

        newRow.ItemName = row.ItemName;
        newRow.UnitPrice = row.UnitPrice;
        newRow.UnitName = row.UnitName;
        newRow.ContractQty = row.ContractQty;
        newRow.TransactionItemId = row.TransactionItemId;
        newRow.OrderRemainingQty = row.OrderRemainingQty;
        newRow.CurrentStockQty = row.CurrentStockQty;
        newRow.Quantity = row.Quantity;
        newRow.PRQuantity = row.PRQuantity;
        newRow.BatchNo = row.BatchNo;
        newRow.MfgDate = row.MfgDate;
        newRow.SupDate = row.SupDate;
        newRow.SupRef = row.SupRef;
        newRow.ItemSl = row.ItemSl;
        newRow.SupCode = row.SupCode;
      
        newRow.ExpiryDate = row.ExpiryDate;
        newRow.Remarks = row.Remarks;
        newRow.SKU = autoId;
        newRow.id = autoId;
        newRow.LineTotal = row.Quantity * row.UnitPrice;

        newRow.SourceId = row.SourceId;
        newRow.SourceName = row.SourceName;

        rows.push(newRow);
      });

      setDispprovedManyTableData(rows);

    }



    


  };



  const addContract = (ContractData) => {
    props.handleAddContract(ContractData);
    handleClose("Contract");
    setcurrContractNo(ContractData[0]['ContractNo']);
  };

  const addSource = (productSourceData) => {
    if (!productSourceData) {
      return;
    }

    let tempProductSourceData = productSourceData[0];
    let rows = [];

    let autoId = Date.now(); // 0;

    manyTableData.forEach((row, i) => {

      autoId++;
      let newRow = {};

      if (row.SKU === currRowId) {
        newRow.SourceId = tempProductSourceData.SourceId;
        newRow.SourceName = tempProductSourceData.SourceName;
      } else {
        newRow.SourceId = row.SourceId;
        newRow.SourceName = row.SourceName;
      }

      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;

      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.OrderRemainingQty = row.OrderRemainingQty;
      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.Quantity = row.Quantity;
      newRow.PRQuantity = row.PRQuantity;
      newRow.BatchNo = row.BatchNo;
      newRow.MfgDate = row.MfgDate;
      newRow.SupDate = row.SupDate;
      newRow.SupRef = row.SupRef;
      newRow.ItemSl = row.ItemSl;
      newRow.SupCode = row.SupCode;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.SKU = row.SKU;
      newRow.id = row.id;
      newRow.LineTotal = row.Quantity * row.UnitPrice;

      rows.push(newRow);
    });

    setManyTableData(rows);
    props.handleAdd(rows);
    handleClose("SourceClose");
  };

  function ShowSourcePopup(propss: any) {
    const rowData = propss.cell._cell.row.data;

    if (props.bStockUpdate) {
      return <></>;
    } else {
      return (
        <>
          <ViewList
            onClick={() => {
              setCurrRowId(rowData.SKU);
              setCurrFormulation(rowData.SKU);
              handleClose("SourceOpen");
            }}
          />
        </>
      );
    }
  }


  const editCheck = function (cell) {
    const rowData = cell._cell.row.data;
    return ((rowData.DisApprovedQty == null) && (rowData.ApprovedQty == null)); // only allow the name cell to be edited if the age is over 18
  }


  const ReceiveDetailsColumns = [
    { title: "TransactionItemId", field: "TransactionItemId", visible: false },
    { title: "id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      width: 30,
      formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Code"],
      field: "ItemCode",
      width: 90,
      // headerFilter: true,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Name"],
      field: "ItemName",
      width: 150,
      // headerFilter: true,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Provisional Received Qty"],
      field: "PRQuantity",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      headerSort: false,
      formatter: "money",
      formatterParams:{ thousand:",",  precision:false },

    },

    {
      title: DispensingLanguage[lan][menukey]["Approved Qty"],
      field: "Quantity",
      width: 90,
      hozAlign: "right",
      headerSort: false,
      headerHozAlign: "right",
      validator: ["integer", "min:0"],
      cssClass: "tabluator-column-editable",
      //editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      editor: cellFocusEditor,
      editable: editCheck,
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        addDisapprovedProductRowOnChange(cell);
        //console.log("curent value==========", curcellval);
        // const currow = cell.getRow();
        // const rowdata = currow.getData();
        // const pricecell = currow.getCell("LineTotal");
        // const totprice = rowdata.UnitPrice * curcellval;
        // pricecell.setValue(totprice);
      },
      formatter: "money",
      formatterParams:{ thousand:",",  precision:false },
    },


    {
      title: DispensingLanguage[lan][menukey]["Lot No"],
      field: "BatchNo",
      width: 90,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Exp Date"],
      field: "ExpiryDate",
      width: 90,
      headerSort: false,
    },

    {
      title: DispensingLanguage[lan][menukey]["Mfg. Date"],
      field: "MfgDate",
      width: 90,
      headerSort: false,
    },
    {
      title: "ItemSl",
      field: "ItemSl",
      width: 90,
      visible: false
    },
    {
      title: "SupRef",
      field: "SupRef",
      width: 90,
      visible: false
    },
    {
      title: "SupDate",
      field: "SupDate",
      width: 90,
      visible: false
    },
    {
      title: "SupCode",
      field: "SupCode",
      width: 90,
      visible: false
    },

  ];

  

  const DisapprovedItemsColumns = [
    { title: "TransactionItemId", field: "TransactionItemId", visible: false },
    { title: "id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      width: 30,
      formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
    },

    {
      title: DispensingLanguage[lan][menukey]["Item Code"],
      field: "ItemCode",
      width: 90,
      // headerFilter: true,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Name"],
      field: "ItemName",
      width: 150,
      // headerFilter: true,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Provisional Received Qty"],
      field: "PRQuantity",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      headerSort: false,
      formatter: "money",
      formatterParams:{ thousand:",",  precision:false },

    },

    {
      title: DispensingLanguage[lan][menukey]["Disapproved Qty"],
      field: "Quantity",
      width: 90,
      hozAlign: "right",
      headerHozAlign: "right",
      validator: ["integer", "min:0"],
      headerSort: false,
      formatter: "money",
      formatterParams:{ thousand:",",  precision:false },

    },

    {
      title: DispensingLanguage[lan][menukey]["Remarks"],
      field: "Remarks",
      width: 90,
      headerSort: false,
      cssClass: "tabluator-column-editable text-field-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      editor: cellFocusEditor,
      editable: editCheck,
      cellEdited: function (cell) {
        /* const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData(); */
      },
    },

    {
      title: DispensingLanguage[lan][menukey]["Lot No"],
      field: "BatchNo",
      width: 90,
      headerSort: false,
    },

    {
      title: DispensingLanguage[lan][menukey]["Mfg. Date"],
      field: "MfgDate",
      width: 90,
      headerSort: false,
    },

    {
      title: DispensingLanguage[lan][menukey]["Exp Date"],
      field: "ExpiryDate",
      width: 90,
      headerSort: false,
    },
    {
      title: "ItemSl",
      field: "ItemSl",
      width: 90,
      visible: false
    },
    {
      title: "SupRef",
      field: "SupRef",
      width: 90,
      visible: false
    },
    {
      title: "SupDate",
      field: "SupDate",
      width: 90,
      visible: false
    },
    {
      title: "SupCode",
      field: "SupCode",
      width: 90,
      visible: false
    },

    
  ];



  const onPostBtnClick = () => {
    setbStockUpdate(true);
    props.handlePost();
  };
  
  const onSaveUpdateBtnClick = () => {
    //props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData(), DisapprovedItemsRef.table.getData());
   
    let manydataReceiveDetailsRef = ReceiveDetailsRef.table.getData();
    let manydataDisapprovedItemsRef = DisapprovedItemsRef.table.getData();
    setUpdatedDetailsGridDataHandler(manydataReceiveDetailsRef,manydataDisapprovedItemsRef);

  };


  const setUpdatedDetailsGridDataHandler = (manydataReceiveDetailsRef, manydataDisapprovedItemsRef) => {
    let validForm = props.checkValidateForm(manydataReceiveDetailsRef, manydataDisapprovedItemsRef, "edit");

    if (validForm) {
      if (props.addProductForm) {
        //mutate(validForm);

        swal({
          title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
          text: t(DispensingLanguage[lan][menukey]["Do you really want to generate received invoice?"]),
          icon: "warning",
          buttons: {
            confirm: {
              text: t(DispensingLanguage[lan][menukey]["Yes"]),
              value: true,
              visible: true,
              className: "",
              closeModal: true,
            },
            cancel: {
              text: t(DispensingLanguage[lan][menukey]["No"]),
              value: null,
              visible: true,
              className: "",
              closeModal: true,
            },
          },

        }).then((willCancel) => {
          if (willCancel) {
            //let fDtat={...formData,'ManyJsonSave':rows, 'DisapprovedManyJsonSave':disapprovedItemsRows, "menukey": menukey}; 
            mutate(validForm);
          }
        });

        
      } else {
        //mutateUpdate(validForm);
      }
    }
  };


  const { isLoading: isLoadingUpdate, mutate } = useMutation(
    api.StandingBoardEntryDataInsert,
    {
      onSuccess: (data) => {
        if (data.status == 200) {

          mutatePost(data.TransactionId);
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      },
    }
  );


  
  const { isLoading: isLoadingpost, mutate:mutatePost } = useMutation(api.postReceiveFromSupplier, {
    onSuccess: (data) => { 
      if (data.status == 200) {
        //let route = "provisional-receive";
        let route = "receive-from-supplier";
        props.history.push(route);  

        if(data.data.success==0){ 

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
         
         }else{
         
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
  
         } 

      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    }
  })




  const deleteReceiveInv = (data) => {
    let newarray = manyTableData.filter(element => element !== data);
    setManyTableData(newarray);
    props.deleteReceiveInv(data);
  };

  const CopyBatch = () => {
    props.handleAdd(manyTableData);
  };

  const onYearChangeEvnt = (e) => {
    setcurrYearId(e.target.value);

    props.handleChange(e);
  };

  function ActionButton(props) {

    const rowData = props.cell._cell.row.data;

    const goToDetails = () => {
      let rowobj = props.cell._cell.row.data;

      timeStamp = Math.round(new Date().getTime() / 100);

      setManyTableData((prevRows) => {
        const tmpPrevRows = [...prevRows];

        let setRposition = tmpPrevRows.findIndex((o) => o.SKU == rowobj.SKU);

        const obj = { ...rowobj };

        obj.id = timeStamp;
        obj.TransactionItemId = "";
        obj.ExpiryDate = "";
        obj.BatchNo = "";
        obj.MfgDate = "";
        obj.SupDate = "";
        obj.SupRef = "";
        obj.ItemSl = "";
        obj.SupCode = "";
        //obj.Quantity = 0;

        obj.SKU = Date.now();

        tmpPrevRows.splice(setRposition + 1, 0, obj);

        return tmpPrevRows;
      });

      // props.handleAdd(manyTableData);
      CopyBatch();
      setNewBatchRowAdded((prevNewBatchRowAdded) => !prevNewBatchRowAdded);
    };

    const cellValue = props.cell._cell.value || "Edit | Show";

    //if (bStockUpdate == 0)

    if ((rowData.DisApprovedQty == null) && (rowData.ApprovedQty == null))
      return (
        <>
          {/* <FileCopy
            onClick={() => {
              if (!bStockUpdate) goToDetails(rowData);
            }}
          /> */}

          <DeleteOutline
            onClick={() => {

              if (!bStockUpdate) deleteReceiveInv(rowData);
            }}
          />
        </>
      );
    else return <></>;
  }

  useEffect(() => {
    if (ReceiveDetailsRef == null) return;
    if (props.bFirst) {
      setManyTableData(props.ManyTableData);

    }

    setbStockUpdate(props.bStockUpdate);
  }, [props.ManyTableData, props.bStockUpdate]);

  useEffect(() => {
    if (ReceiveDetailsRef.table != null && timeStamp != "") {
      ReceiveDetailsRef.table.scrollToRow(timeStamp, "center", true);
      ReceiveDetailsRef.table.selectRow(timeStamp);
    }
  }, [newBatchRowAdded]);

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Standing Board Entry"]}
              </div>


            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            {/* <Card className="sw_card">
            <CardHeader title="Personal Information" />
            <CardContent> */}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={2} sm={2}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        required
                        error={props.errorObject.TransactionDate}
                        helperText={props.errorObject.TransactionDate}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        id="TransactionDate"
                        label={DispensingLanguage[lan][menukey]["Meeting Date"]}
                        autoOk={true}
                        name="TransactionDate"
                        fullWidth
                        showTodayButton={true}
                        disableFuture={true}
                        value={
                          props.formData.TransactionDate || null
                        }
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "TransactionDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>



                  <Grid item xs={2} sm={2}>
                    {/* <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Year"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.YearId}
                        labelId="demo-simple-select-helper-label"
                        id="YearId"
                        name="YearId"
                        value={props.formData.YearId}
                        fullWidth
                        onChange={(e) => onYearChangeEvnt(e)}
                        //disabled={props.bStockUpdate}
                        disabled={
                          manyTableData.length > 0 ? true : props.bStockUpdate
                        }
                      >
                        {PackageYearList.map((item, index) => {
                          return (
                            <MenuItem value={item.YearId}>
                              {item.PackageYear}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={props.errorObject.YearId}>
                        {props.errorObject.YearId}
                      </FormHelperText>
                    </FormControl> */}

                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            disabled={
                              manyTableData.length > 0 ? true : props.bStockUpdate
                            }
                            id="YearId"
                            options={PackageYearList}
                            onChange={(event, valueobj) => handleChangeChoosen('YearId', valueobj, valueobj?valueobj.YearId:"")}
                            getOptionLabel={(option) => option.PackageYear}
                            value={chosenValues['YearId']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.PackageYear}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={props.formData.YearId}
                              label={DispensingLanguage[lan][menukey]["Year"]+ " *"}
                              variant="standard"
                              error={props.errorObject.YearId}
                              helperText={props.errorObject.YearId}
                              id="YearId"
                              name="YearId"
                              fullWidth
                            />
                            )}
                          />

                        </FormControl>
                  </Grid>

                  <Grid item xs={2} sm={2}>
                    <TextField
                      disabled={true /*props.bStockUpdate*/}
                      id="PackageName"
                      name="PackageName"
                      label={DispensingLanguage[lan][menukey]["Package No"]}
                      value={props.formData.PackageName}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={5} sm={5} className="sw_relative">
                    <TextField
                      required
                      error={props.errorObject.ContractName}
                      helperText={props.errorObject.ContractName}
                      disabled={true /*props.bStockUpdate*/}
                      id="ContractName"
                      name="ContractName"
                      label={DispensingLanguage[lan][menukey]["Contract No"]}
                      value={props.formData.ContractName}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />

                    <Button
                      //disabled={props.bStockUpdate}
                      className="pick_btn_place"
                      variant="contained"
                      color="success"
                      disabled={
                        manyTableData.length > 0 ? true : props.bStockUpdate
                      }
                      onClick={() => handleClose("ContractOpen")}
                    >
                      ...
                    </Button>


                  </Grid>
                  <Grid item xs={1} sm={1}>
                
                    <ContractModalByYear
                      handleClose={handleClose}
                      open={openContract}
                      yearid={currYearId == '' ? props.formData.YearId : currYearId}
                      addContract={addContract}
                      {...props}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="TransactionId"
                      name="TransactionId"
                      label="TransactionId"
                      value={props.formData.TransactionId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                {/* new row */}
                <Grid item xs={6} sm={6}>
                  <Card className="sw_card">
                    <CardContent>
                      <Grid container className="mb-2">
                        <Grid item xs={12} sm={12}>
                          <div className="float-right sw_btn_control">

                            <Button
                              disabled={props.bStockUpdate || (props.formData.ContractNo > 0 ? false : true)}
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              onClick={() => handleClose("ProductsOpen")}
                            >
                              {
                                DispensingLanguage[lan][menukey]["Select Approved Items"]
                              }
                            </Button>
                          </div>

                          <ProductsModal
                            handleClose={handleClose}
                            open={open}
                            ContractNo={currContractNo == '' ? props.formData.ContractNo : currContractNo}
                            addProduct={addProduct}
                            {...props}
                          />

                          <SourceModal
                            handleClose={handleClose}
                            open={SoureOpen}
                            addProduct={addSource}
                            {...props}
                          />
                        </Grid>
                      </Grid>
                      <ReactTabulator
                        ref={(r) => (ReceiveDetailsRef = r)}
                        columns={ReceiveDetailsColumns}
                        data={manyTableData}
                        height="350px"
                        layout={"fitColumns"}
                      />
                    </CardContent>
                  </Card>
                </Grid>

                {/* new row */}


                {/* new row for Disapproved Item */}
                <Grid item xs={6} sm={6}>
                  <Card className="sw_card">
                    <CardContent>
                      <Grid container className="mb-2">
                        <Grid item xs={12} sm={12}>
                          <div className="float-right sw_btn_control">

                            <Button
                              disabled={props.bStockUpdate || (props.formData.ContractNo > 0 ? false : true)}
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              onClick={() => handleClose("DisapprovedProductsOpen")}
                            >
                              {
                                DispensingLanguage[lan][menukey]["Select Disapproved Items"]
                              }
                            </Button>
                          </div>

                          <DisapprovedProductsModal
                            handleClose={handleClose}
                            open={OpenDisapprovedProducts}
                            ContractNo={currContractNo == '' ? props.formData.ContractNo : currContractNo}
                            addProductDisapproved={addProductDisapproved}
                            {...props}
                          />

                          <SourceModal
                            handleClose={handleClose}
                            open={SoureOpen}
                            addProduct={addSource}
                            {...props}
                          />
                        </Grid>
                      </Grid>
                      <ReactTabulator
                        ref={(r) => (DisapprovedItemsRef = r)}
                        columns={DisapprovedItemsColumns}
                        data={dispprovemanyTableData}
                        height="350px"
                        layout={"fitColumns"}
                      />
                    </CardContent>
                  </Card>
                </Grid>

                {/* new row */}


                <Grid item xs={12} sm={12} className=" text-center">

                  <Button
                    disabled={!(manyTableData.length > 0 || dispprovemanyTableData.length > 0) || isLoadingUpdate || isLoadingpost || props.bStockUpdate}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClick} //() => props.handleSubmit()
                  >
                    {DispensingLanguage[lan][menukey]["Generate Received Invoice"]}
                  </Button>

                </Grid>


              </Grid>
            </Grid>

            {/* </CardContent>
          </Card> */}
          </Grid>

          {/* Action buttons */}
        </Grid>
      </div>
    </div>
  );
};

export default MasterManyFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
