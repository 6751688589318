import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import * as api from "../../../actions/api";
import MenuEntryFormData from "./MenuEntryFormData.js";


function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
const EditMenu = (props) => {
    //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "menu-entry";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo==0?'': UserInfo[0].id;

  const [formData, setFormData] = useState({
    id:"",
    title: "",
    url: "",
    parentId: "",
    position: "",
    MenuLevel: "",
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data } = useQuery(
    ["menuentry", id],
    () =>
      api.geImenuentrys(id).then((res) => {
        setFormData(res.data);
        return res.data;
      }),
    {
      enabled: Boolean(id),
    }
  );

  // const { isLoading, mutate } = useMutation(api.updateMenuentry, {
  //   onSuccess: (data) => {
  //     if (data.status == 200) {
  //        api.getDispensingLanguage().then((response) => {
  //          localStorage.setItem(
  //            "DispensingLanguage",
  //            JSON.stringify(response.datalist)
  //          );
  //        })
  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.data.message,
  //         msgtype: data.data.success,
  //       });
  //       queryClient.getQueriesData("menuentry");
  //       props.history.push("/menu-entry");
  //       api.dirtyModeDisable();
  //     }else{

  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.message,
  //         msgtype: data.success,
  //       });
       
  //     }
  //   },
  // });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };

    //====== Start Choosen Dropdown===========
    const handleChangeChoosenProps = (name, value) => {
      let data = { ...formData };
      data[name] = value;   
      setFormData(data); 
      setErrorObject({ ...errorObject, [name]: "" });
      api.dirtyModeEnable();
    }
    //====== End Choosen Dropdown===========

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
    api.dirtyModeEnable();
  };

  const handleReset = () => {
    setFormData({
      title: "",
      url: "",
      parentId: "",
      position: "",
      MenuLevel: "",
    });
  };

  // const validateForm = (formData) => {
  //   let validateFields = ["title","url","position","MenuLevel","parentId"];
  //   let errorData = {};
  //   let isValid = true;
  //   validateFields.map((field) => {
  //     if (!formData[field]) {
  //       errorData[field] =
  //         DispensingLanguage[lan][menukey]["field is Required !"];
  //       isValid = false;
  //     }
  //   });
  //   setErrorObject(errorData);
  //   return isValid;
  // };

  const validateForm = (formData) => {
    let validateFields = ["title","url"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        if (formData[field] != 0) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false;
        }
      }

    });
    setErrorObject(errorData);
    return isValid;
  };
  function checkValidateForm(){

     
    if (validateForm(formData)) {
    let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName,}; 
   
    return fDtat;
    }else{
    return false;
    }

};


  // const handleUpdate = async (e) => {
  //   if (validateForm(formData)) {
  //     let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
  //     mutate(fDtat);
  //     //swal("Success!", "", "success");
  //   }
  // };

  return (
    <>
      <MenuEntryFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleCheck={handleCheck}
        handleReset={handleReset}
        checkValidateForm={checkValidateForm}
        // handleUpdate={handleUpdate}
        bStockUpdate={props.permissionType}
        {...props}
      />
    </>
  );
};

export default EditMenu;
