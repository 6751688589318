import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "assets/css/export_import.css";
import {
  Typography,
  Grid,
  FormControl,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";

//import Tooltip from '@material-ui/core/Tooltip';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

import {Clear, CloudUploadOutlined, PictureAsPdf,PermMediaOutlined,Add ,ViewList } from "@material-ui/icons";
import LoaderForGlobal from "services/LoaderForGlobal";

import { useTranslation } from "react-i18next";
import Autocomplete from '@material-ui/lab/Autocomplete';
import swal from "sweetalert";
import * as Service from "../../../services/Service.js";
import { setYear } from "date-fns";
import ImageModal from "./ImageModal";
import SupplierModal from "./SupplierModal";
import ManufacturerModal from "./ManufacturerModal";
import DGDAPriceModal from "./DGDAPriceModal";
import NationalSupplier from "../nationalsupplier/NationalSupplier";
//import ManufacturerModal from "./ManufacturerModal";

const ProductSpecificationFormData = (props) => {
  const classes = useStyles();
//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "product-specification";

const FacilityId = localStorage.getItem("FacilityId");
const FacilityName = localStorage.getItem("FacilityName");

const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;

const DistrictList = JSON.parse(localStorage.getItem("DistrictList"));

const SupplierListNational = JSON.parse(localStorage.getItem("SupplierListNational"));
const ManufacturerListNational = JSON.parse(localStorage.getItem("ManufacturerListNational"));

const IndentorList = JSON.parse(localStorage.getItem("IndentorList"));
const PackageYearList = JSON.parse(localStorage.getItem("PackageYearList"));
const PackageList = JSON.parse(localStorage.getItem("PackageList"));
const CurrencyIdList = JSON.parse(localStorage.getItem("CurrencyList"));
const EstimatedCurrencyList = JSON.parse(localStorage.getItem("EstimatedCurrencyList"));
const [currYearId, setcurrYearId] = useState(0);

const GlobalSettings = JSON.parse(localStorage.getItem("GlobalSettings"));
const [buttonDisabled, setbuttonDisabled] = useState(true);
const [buttonDisabledProduct, setbuttonDisabledProduct] = useState(true);
const [CertificateUploadedFileName, setCertificateUploadedFileName] = useState();
const [CertificateUploadedFileNameProduct, setCertificateUploadedFileNameProduct] = useState();
const [ErrorMgsCertificate, setErrorMgsCertificate] = useState();
const [ErrorMgsCertificateProduct, setErrorMgsCertificateProduct] = useState();
const [OldCertificateUploadedFileName, setOldCertificateUploadedFileName] = useState();
const [isLoading, setLoading] = useState(false);
const [isLoadingProduct, setLoadingProduct] = useState(false);
const [ErrorMsgShowing, setErrorMsgShowing] = useState(false);
const [ErrorMsgShowingProduct, setErrorMsgShowingProduct] = useState(false);

const [ShowErrorMsg, setShowErrorMsg] = useState();
const [ShowErrorMsgProduct, setShowErrorMsgProduct] = useState();

const removeStrFromURL = process.env.REACT_APP_API_URL;
const REPORT_URL = removeStrFromURL.replace('backend/', '');

const [uploadFormData, setFormData] = useState({
  menukey: menukey,
  lan: lan,
  UserName: UserName,
  BrandNo: "",// Need to change
  CertificateName: "",
  BrandCode: "",
  ManufacturerId: "",
  DateExpiration: "",
  UploadStatus: "",
  UploadStatusProduct: "",
  ProductImage: "",
  Specification:""
});

  const { t, i18n } = useTranslation();

  
const CureentYearList =JSON.parse(localStorage.getItem("CureentYearList"));

const currentValue = (CureentYearList[0].id);
const currentYearName = (CureentYearList[0].name);

const [CurentsYearId, setYearIdData] = useState(currentValue);
const [formDisable, setFormDisable] = useState(false);
const [imageArray, setImageArray] = useState([]);
const queryClient = useQueryClient();

//====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
 // "PackageNo": {"PackageNo": "","PackageName": ""},
  
 "YearId": {"YearId": currentValue,"PackageYear": currentYearName},

  "CurrencyId": {"CurrencyId": "","CurrencyName": "","ConvRate": ""},
  "EstimatedCurrencyId": {"EstimatedCurrencyId": "","EstimatedCurrencyName": ""},
  "SupCode": {"SupCode": "","SupName": ""},
  "ManufacturerId": {"ManufacturerId": "","ManufacturerName": ""},
});


const [chosenValuesFacility, setChosenValuesFacility] = useState({
  "PackageNo": {"PackageNo": "","PackageName": ""}
});


const [chosenValuesCurrency, setChosenValuesCurrency] = useState({
  "CurrencyId": {"CurrencyId": "","CurrencyName": "","ConvRate": ""}
});


useEffect(() => {
  
  let chosenValuesData = { ...chosenValues};
  // chosenValuesData['IndentorId'] = IndentorList[IndentorList.findIndex(Indentor_List => Indentor_List.IndentorId == formData.IndentorId)];
  
  
  chosenValuesData['YearId'] = PackageYearList[PackageYearList.findIndex(PackageYear_List => PackageYear_List.YearId == props.formData.YearId)];
   chosenValuesData['CurrencyId'] = CurrencyIdList[CurrencyIdList.findIndex(CurrencyId_List => CurrencyId_List.CurrencyId == props.formData.CurrencyId)];
   chosenValuesData['EstimatedCurrencyId'] = EstimatedCurrencyList[EstimatedCurrencyList.findIndex(EstimatedCurrency_List => EstimatedCurrency_List.EstimatedCurrencyId == props.formData.EstimatedCurrencyId)];
   chosenValuesData['SupCode'] = SupplierListNational[SupplierListNational.findIndex(NationalSupplier_List => NationalSupplier_List.SupCode == props.formData.SupCode)];
   chosenValuesData['ManufacturerId'] = ManufacturerListNational[ManufacturerListNational.findIndex(ManufacturerId_List => ManufacturerId_List.ManufacturerId == props.formData.ManufacturerId)];
   setChosenValues(chosenValuesData);  

    
   if(props.formData.Specification!="") {
    uploadFormData["CertificateName"] = props.formData.Specification;
    uploadFormData["UploadStatus"] = 'FileUpload';
   }

   if(!props.addProductForm ){

    if((props.formData.ProductImage!="")) { 
      if(props.formData.ProductImage!=null){
      
      uploadFormData["ProductImage"] = props.formData.ProductImage.split('|');
      uploadFormData["UploadStatusProduct"] = 'FileUpload';
      let imagename=props.formData.ProductImage.split('|');
      let row=[];
      for(let i=0;i<imagename.length;i++){     
        row.push(REPORT_URL+'/media/CatalogDoc/'+imagename[i]);   
  
      }  
      setImageArray(row); 
      uploadFormData["ProductImage"]=row;
      setCertificateUploadedFileNameProduct(props.formData.ProductImage.split('|'));
      }
     }


   } 
 

   
  }, [props.formData.YearId,props.formData.CurrencyId]);
   


  React.useEffect(() => {
  
    let chosenValuesDataFacility = { ...chosenValuesFacility};
    chosenValuesDataFacility['PackageNo'] = PackageList[PackageList.findIndex(Package_List => Package_List.PackageNo == props.formData.PackageNo)];
    setChosenValuesFacility(chosenValuesDataFacility); 
  }, [props.formData.PackageNo]);


/*  

const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  setChosenValues(chosenValuesData); 
  props.handleChangeChoosenProps(name, value);
};

*/

const handleChangeChoosen = (name, valueobj, value) => {
  console.log("oooooooo",valueobj);
  
  let chosenValuesData = { ...chosenValues};
  console.log("oooooooorrr",chosenValuesData);
  chosenValuesData[name] = valueobj; 
  console.log("ooooooooddddd",chosenValuesData);  
  setChosenValues(chosenValuesData);

    if(name=='CurrencyId') {
      if(value==1){
        
        setFormDisable(true);
      }else{ 
        setFormDisable(false);
      }
    
    } 

  let chosenValuesDataFacility = { ...chosenValuesFacility};
  chosenValuesDataFacility[name] = valueobj;  
  setChosenValuesFacility(chosenValuesDataFacility); 

  props.handleChangeChoosenProps(name, value);
};


const handleChangeYearDependency = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  //props.formData["PackageNo"] = "";

  setChosenValues(chosenValuesData);
  setcurrYearId(value);

  props.handleChangeChoosenProps(name, value);

  //console.log("Change Value", PackageYearList[PackageYearList.findIndex(atclist => atclist.YearId == valueobj.YearId)]['bCatalogLocked']);

  let YearLockedVal = PackageYearList[PackageYearList.findIndex(atclist => atclist.YearId == valueobj.YearId)]['bCatalogLocked']
 
  setbCatalogLocked(YearLockedVal); 
  setdynamicBTNLoc(YearLockedVal);
  //props.bCatalogLocked = YearLockedVal;
  //props.bCatalogLocked=PackageYearList[PackageYearList.findIndex(atclist => atclist.YearId == valueobj.YearId)]['bCatalogLocked']==1?true:false;
};

React.useEffect(() => { 
  if(props.formData.YearId !==''){
    setcurrYearId(props.formData.YearId);
  }
   
}, [props.formData.YearId]);


React.useEffect(() => {
  cPackageList();
}, [currYearId]);

//====== End Choosen Dropdown===========

  /* =====Start of Print Excel Many Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/product_specification_entry_list_print.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&SpecificationId=" +
        props.match.params.id +
        "&TimeStamp=" +
        Date.now()
    );
  };

  const ExcelMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL +
      "report/esigl_receive_from_warehouse_entry_list_excel.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TransactionId=" +
        props.formData.TransactionId +
        "&FacilityId=" +
        FacilityId +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

let TempItemList = useState([]);
const [ItemList, setItemListLocal] = useState(TempItemList);

const cPackageList = () => {

  let PackageListParam = { action: "getPackegeListCatalog", YearId: currYearId, menukey: "",userId:UserInfo[0].id }

  Service.default
    .postApi("source/combo_catalog.php", PackageListParam)
    .then((res) => {

      if(!props.addProductForm){
        let chosenValuesDataFacility = { ...chosenValuesFacility};
        chosenValuesDataFacility['PackageNo'] = res.datalist[res.datalist.findIndex(fpacily_Lists2 => fpacily_Lists2.PackageNo == props.formData.PackageNo)];
        setChosenValuesFacility(chosenValuesDataFacility);  

      }else{
        setChosenValuesFacility({
        "PackageNo": {"PackageNo": "","PackageName": ""}
        }); 
        props.formData["PackageNo"] = "";
      } 

      setItemListLocal(res.datalist);


    })
    .catch((err) => { });

}


const backtolist=()=>{


  let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
  let idx = master_dirty_message_class.indexOf("dnone");
  if(idx === -1){
    // start Alert
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then((willAction) => {
      if (willAction) {
        props.history.push("/product-specification");
      }
    });
    // End Alert
  }else{
    props.history.push("/product-specification");
  }

}




/* =============Certificate=========== */

const [CatalogStatusId, setCatalogStatusId] = useState(0);

const handleUpdateStatus = (name, value,btnname) => {

  setCatalogStatusId(value);

  if((uploadFormData["CertificateName"]=="")||(uploadFormData["CertificateName"]==undefined)){
    setErrorMgsCertificate("field is Required !");
    return;
  }

  let validForm = props.checkValidateForm();

  if (validForm) {

    

  swal({
    title: t(DispensingLanguage[lan][menukey]["Are you sure want to"]+' '+btnname+'?'),
   // text: t('Confirm '+btnname),
    icon: "warning",
    buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
    t(DispensingLanguage[lan][menukey]["Yes"])],
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {

     // {CatalogStatus:value,SpecificationId:props.match.params.id}
     //console.log("Loooooo",value);

      
      mutateUpdatePost(validForm);
     
      
    }else{
     
    }
  });


}

  //handlePost();

  
}



const {  mutate:mutateUpdatePost } = useMutation(api.updateProductSpecification, {
  onSuccess: (data) => {

     
    if (data.status == 200) {

      if(data.data.status == 500){

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });

      }else{
         setCatalogStatus({CatalogStatus:CatalogStatusId,SpecificationId:props.match.params.id});
         api.dirtyModeDisable();
      } 
      }else{   
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
  },
});




const {  mutate: setCatalogStatus } = useMutation(api.setCatalogStatus, {
  onSuccess: (data) => { 
    if (data.status === 200) { 
      props.refetch();
     // props.history.push("/product-specification");//
 
    }
  }
});


const getBrandData = () => {

  let params={
    action:"getBrandDataById",
    menukey: menukey, 
    BrandNo: "", // need to change
  };
 // mutateBrandData(params);
};

const APP_URL = process.env.REACT_APP_API_URL;

const changeHandlerForCertificate = (event,action) => {
//console.log(event.target.files);
  let selectedFile;
  let FileName;
  let FileExt;
  let Filetype;
  let fileSizeNum;
 
 

switch(action){
  case 'Specification':

    selectedFile = event.target.files[0];
    if(selectedFile == undefined){
      return;
    }

     FileName = selectedFile.name;
     FileExt = FileName.split(".").pop();
     Filetype = FileExt.toLowerCase();
     fileSizeNum = selectedFile.size;
  
    setErrorMsgShowing(false);
    setLoading(true);
    setbuttonDisabled(false);
  
   
  
    //File size Greater then 5MiB!
    // const fileSizeNum = selectedFile.size / 1024 / 1024; // in MiB
    // const fileSize = fileSizeNum.toFixed(2);
  
    //The file Check extension
    if (Filetype == "pdf" || Filetype == "PDF") {
      if(fileSizeNum<=5242880){

        setErrorMgsCertificate("");
        handleUploadFile(action,selectedFile);
      }else{
        setErrorMgsCertificate(
          DispensingLanguage[lan][menukey]["Sorry, file more than 5MB is not allowed"]
        ); 
        setbuttonDisabled(true);
        setLoading(false);

      }
    } else {
      setErrorMgsCertificate(
        DispensingLanguage[lan][menukey]["Sorry, only pdf file are allowed"]
      );
      // setCertificateUploadedFileName("");
      // uploadFormData["CertificateName"] = "";
      setbuttonDisabled(true);
      setLoading(false);
    }

  break;
  case 'ProductName':
    
    selectedFile = event.target.files; 
    console.log(selectedFile);
    if(selectedFile == undefined){
      return;
    }
     

    console.log(imageArray);
    let rows = [];
    for(let i=0;i<selectedFile.length;i++){

      let fileinfo=selectedFile[i];
 console.log(fileinfo); 
      // let newRow={} ;
      // newRow.ItemNo =row.ItemNo;
       
      // rows.push(newRow);


      FileName = fileinfo.name;
      FileExt = FileName.split(".").pop();
      Filetype = FileExt.toLowerCase();
      fileSizeNum = fileinfo.size;
      setErrorMsgShowing(false);
      setLoadingProduct(true);
      setbuttonDisabledProduct(false); 
    
      //File size Greater then 5MiB!
      // const fileSizeNum = selectedFile.size / 1024 / 1024; // in MiB
      // const fileSize = fileSizeNum.toFixed(2);
    
      //The file Check extension
      if (Filetype == "jpeg" || Filetype == "png" || Filetype == "jpg") {
     
       
  
  
        if(fileSizeNum<=5242880){
  
          setErrorMgsCertificateProduct("");
         
        }else{
          setErrorMgsCertificateProduct(
            DispensingLanguage[lan][menukey]["Sorry, file more than 5MB is not allowed"]
          ); 
          setbuttonDisabledProduct(true);
          setLoadingProduct(false);
          return;
        }
  
  
      } else {
        setErrorMgsCertificateProduct(
          DispensingLanguage[lan][menukey]["Sorry, only image file are allowed"]
        );
        // setCertificateUploadedFileName("");
        // uploadFormData["CertificateName"] = "";
        setbuttonDisabledProduct(true);
        setLoadingProduct(false);
        return;
      }
      if(selectedFile.length-1==i)
      handleUploadFile(action,selectedFile);
    
  

    }
     
    
  break;
}
 

  
};



const handleUploadFile = async (action,selectedFile) => {
  handleFileUploadForCertificate(action,selectedFile);

  /*
  swal({
    title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
    text: t(DispensingLanguage[lan][menukey]["Confirm Certificate PDF File Upload"]),
    icon: "warning",
    buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
    t(DispensingLanguage[lan][menukey]["Yes"])],
    dangerMode: true,
  }).then((willDelete) => {
    if (willDelete) {
      
    }else{
      uploadFormData["CertificateName"] = OldCertificateUploadedFileName;
      uploadFormData["UploadStatus"] = '';
      setCertificateUploadedFileName('');
      
      setLoading(false);
      setbuttonDisabled(true);
    }
  });*/
};


const handleFileUploadForCertificate = (action,selectedFile) => { 

if((chosenValues['YearId']==undefined)||(chosenValuesFacility['PackageNo']==undefined)){
  props.openNoticeModal({
    isOpen: true,
    msg: DispensingLanguage[lan][menukey]["Please fill year and Package first"],
    msgtype: false,
  });
  setLoading(false);
  return;
}else if(chosenValuesFacility['PackageNo'].PackageNo==''){
  props.openNoticeModal({
    isOpen: true,
    msg: DispensingLanguage[lan][menukey]["Please fill year and Package first"],
    msgtype: false,
  });
  setLoading(false);
  return;
  
}

let ItemCode=props.formData.ItemCode==undefined?props.match.params.id:props.formData.ItemCode;
 let PackageName=PackageList[PackageList.findIndex(Package_List => Package_List.PackageNo == props.formData.PackageNo)].PackageName;
 let Year=PackageYearList[PackageYearList.findIndex(PackageYear_List => PackageYear_List.YearId == props.formData.YearId)].PackageYear;
  
  const formData = new FormData();
  
  if(action=='ProductName')
  for(let i=0;i<selectedFile.length;i++){
    formData.append('FileName[]', selectedFile[i]); 
  }
  else
  formData.append("FileName", selectedFile);
 
  formData.append("ItemCode",ItemCode);
  formData.append("PackageNo", PackageName);
  
  formData.append("YearId", Year);
  formData.append("Action", action);
  formData.append("ExistingImage", imageArray );
  //let authCertPath = GlobalSettings.auth_cert;
  //formData.append("authCertPath", authCertPath);


  
  let finalUrl = APP_URL + "upload/specification_upload.php";
  switch(action){
    case 'Specification':
     
          
        fetch(finalUrl, {
          method: "POST",
          body: formData,
         
        })
          .then((response) => response.json())
          .then((res) => {
            if (res.msgType == "success") {
            
              setCertificateUploadedFileName(res.FileName);
              uploadFormData["CertificateName"] = res.FileName;
              uploadFormData["UploadStatus"] = 'FileUpload';

              props.formData.Specification=res.FileName;
              props.formData.SpecificationHidden=2;
              setErrorMgsCertificate("");
              //handleSubmit();

            } else {
              setErrorMgsCertificate(res.msg);
            }
            setLoading(false);
          })
          .catch((error) => {
            setErrorMgsCertificate(error);
            setLoading(false);
          });
        break;
        case 'ProductName':
         
      
            fetch(finalUrl, {
              method: "POST",
              body: formData, 
             // headers: { 'Content-Type': 'multipart/form-data' }
            })
              .then((response) => response.json())
              .then((res) => {
                if (res.msgType == "success") {
                
                 

                  let row=[];let imagename="";
                  for(let i=0;i<imageArray.length;i++){     
                    row.push(imageArray[i]); 
                    let hold=imageArray[i].split("/");
                    imagename+=imagename==""?"":"|";
                    imagename+=hold[hold.length - 1];
                   
                      
              
                  }  
                  

                  for(let i=0;i<res.ImageNames.length;i++){     
                    row.push(REPORT_URL+'/media/files/'+res.ImageNames[i]); 
                    imagename+=imagename==""?"":"|";
                    imagename+=res.ImageNames[i];
                    
              
                  } 
                  setImageArray(row);

                  uploadFormData["ProductImage"] = row;
                  uploadFormData["UploadStatusProduct"] = 'FileUpload';
                  setCertificateUploadedFileNameProduct(imagename);
                  props.formData.ProductImage=imagename;
                  props.formData.ProductImageHidden=2;
                  setErrorMgsCertificateProduct("");
                  //handleSubmit();
    
                } else {
                  setErrorMgsCertificateProduct(res.msg);
                }
                setLoadingProduct(false);
              })
              .catch((error) => {
                setErrorMgsCertificateProduct(error);
                setLoadingProduct(false);
              });
            break;
  }
   


  }; 

/*
  const handleSubmit = (data) => {
    setLoading(true);
    setbuttonDisabled(true);
    mutate(uploadFormData);
  }

*/
  const UploadFileDownload = (uploadFile,action) => {
    
   // let authCertPath = GlobalSettings.auth_cert;//REPORT_URL + authCertPath+
    let imgUrl="";
    if(action=='Specification'){
       

      
      if((uploadFormData["CertificateName"]=="")||(uploadFormData["CertificateName"]==undefined))
       imgUrl=REPORT_URL+'/media/CatalogDoc/'+uploadFile;
      else {

        if(document.getElementById("CertificateName_selectFile").value==""){
          imgUrl=REPORT_URL+'/media/CatalogDoc/'+uploadFile;   
        }
        else imgUrl=REPORT_URL+'/media/files/'+uploadFile;   
      } 
       
    }else{
      if((uploadFormData["ProductImage"]=="")||(uploadFormData["ProductImage"]==undefined))
       imgUrl=REPORT_URL+'/media/CatalogDoc/'+uploadFile;
      else{

        if(document.getElementById("ProductImage_selectFile").value==""){
          imgUrl=REPORT_URL+'/media/CatalogDoc/'+uploadFile;   
        } 
        else imgUrl=REPORT_URL+'/media/files/'+uploadFile; 
      } 
     
    }
    
    let finalUrl = imgUrl;
  
    // let finalUrl = REPORT_URL + "writable_files/auth_cert/"+uploadFile;
    if(action=='Specification') {
      window.open(finalUrl);
    }else{
      let SYearName = PackageYearList[PackageYearList.findIndex(PackageYear_List => PackageYear_List.YearId == props.formData.YearId)].PackageYear;
      let SPackageName=PackageList[PackageList.findIndex(Package_List => Package_List.PackageNo == props.formData.PackageNo)].PackageName;
      //console.log("Year Name",SYearName);
      SetImageName({ItemName:props.formData.ItemName,ProductImage:imgUrl,ImageArray:imageArray,YearName:SYearName,PackageName:SPackageName});
      handleModal("ImageOpen");
    }
    

    

  };

  const DeleteUploadFile = async (e,action,filename) => {

    
    if(action=="Specification"){
      uploadFormData["CertificateName"] = "";
      uploadFormData["UploadStatus"] = 'FileDelete';
      setCertificateUploadedFileName("");
      props.formData.SpecificationHidden=1; 
      props.formData.Specification="";   
    // handleSubmit();  
      setbuttonDisabled(false);

    }else{

      let rows=[];let imagename="";
       
      for(let i=0;i<imageArray.length;i++){

        
        
        if(imageArray[i]!=filename){
          let hold=imageArray[i].split('/');
          rows.push(imageArray[i]);
          imagename+=imagename==""?"":"|";
          imagename+=hold[hold.length - 1];
        }

      }
     //alert(imagename)
      setImageArray(rows);
      uploadFormData["ProductImage"] =rows;
      uploadFormData["UploadStatusProduct"] = 'FileDelete';
      setCertificateUploadedFileNameProduct(imagename);  
      props.formData.ProductImageHidden=1;
     
      props.formData.ProductImage=imagename; 
      setbuttonDisabledProduct(false);

    }
     
  };


 // const onConfirmDeleteUploadFile = (action) => {}

  /*
  useEffect(() => {
    getBrandData();
  
  }, [BrandNo]);

  */

  const handleUpdate = (e,action) => {
     
    if((chosenValues['YearId']==undefined)||(chosenValuesFacility['PackageNo']==undefined)){
      if(action=="Save")
      setUpdatedDetailsGridDataHandler('Save');
      // props.handleSubmit();
      else
      setUpdatedDetailsGridDataHandler('Update');
      // props.handleUpdate();
    }
    else if(chosenValuesFacility['PackageNo'].PackageNo==''){

      if(action=="Save")
      setUpdatedDetailsGridDataHandler('Save');
      // props.handleSubmit();
      else
      setUpdatedDetailsGridDataHandler('Update');
      // props.handleUpdate();

    }
    if((uploadFormData["CertificateName"]=="")||(uploadFormData["CertificateName"]==undefined)){
      setErrorMgsCertificate("field is Required !");
    }else{
      if(action=="Save")
      setUpdatedDetailsGridDataHandler('Save');
      // props.handleSubmit();
      else
      setUpdatedDetailsGridDataHandler('Update');
      // props.handleUpdate();
    }
   
    
  }


  
function srcset(image, size, rows = 1, cols = 1) {

  // if((uploadFormData["ProductImage"]=="")||(uploadFormData["ProductImage"]==undefined))
  // image=REPORT_URL+'/media/CatalogDoc/'+image;
  // else  
  // image=REPORT_URL+'/media/files/'+image;
  
  return {
    src: `${image}`,
    // src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    // srcSet: `${image}?w=${size * cols}&h=${
    //   size * rows
    // }&fit=crop&auto=format&dpr=2 2x`
  };

  
}


 
const [openImage, setOpenImage] = useState(false);
const [imageName, SetImageName] = useState('');


const [SupplierOpen, setSupplierOpen] = useState(false);
const [ManufacturerOpen, setManufacturerOpen] = useState(false);
const [DGDAPriceOpen, setDGDAPriceOpen] = useState(false);

const handleModal = (modalname) => {
  switch (modalname) {
     

      case "ImageModal":
        setOpenImage(false);
        break;
      case "ImageOpen":
        setOpenImage(true);
        break;  

      case "SupplierModal":
        setSupplierOpen(false);
        break;

      case "SupplierOpen":
        setSupplierOpen(true);
        break;

      case "ManufacturerModal":
        setManufacturerOpen(false);
        break;

      case "ManufacturerOpen":
        setManufacturerOpen(true);
        break;


      case "DGDAPriceModal":
        setDGDAPriceOpen(false);
        break;

      case "DGDAPriceOpen":
        setDGDAPriceOpen(true);
        break;

    default:
      break;
  }
};



const [supplierCode, setSupplierCode] = React.useState("");

useEffect(() => { 

  let chosenValuesData = { ...chosenValues};
    

    chosenValuesData['SupCode'] = SupplierListNational[SupplierListNational.findIndex(NationalSupplier_List => NationalSupplier_List.SupCode == supplierCode)];
    setChosenValues(chosenValuesData);

    props.formData["SupCode"] = supplierCode;
  
}, [supplierCode]); 

const [btnDisable, setbtnDisable] = useState(false);

const addSupplier = (SupplierData) => {

//alert(SupplierData);
console.log("dddddddddddddddd",SupplierData);
console.log("local storage ",SupplierListNational);
  if(SupplierData!=""){
  
  handleModal("SupplierModal");
  //setSupplierCode(SupplierData.SupCode);
  setTimeout(() => setSupplierCode(SupplierData.SupCode), 500);
  

  /*
  chosenValues['SupCode'] = {
    //"SupCode": {"SupCode": SupplierData.SupCode,"SupName": "abc"}
     "SupCode": {"SupCode": 386,"SupName": "bbbff"}
    };  

    */

    
    
    //chosenValuesData['ManufacturerId'] = ManufacturerListNational[ManufacturerListNational.findIndex(ManufacturerId_List => ManufacturerId_List.ManufacturerId == props.formData.ManufacturerId)];

  }
  //setChosenValuesFacility(chosenValuesDataFacility); 

 /* props.handleChangeChoosenProps(name, value);

  console.log("oooooooooooooo",SupplierData);


    handleChangeChoosen({
    "SupCode": {"SupCode": "","SupName": ""}
    }); 

    */
   // props.formData["SupCode"] = "";
  
  
    
  
   };


   const addDGDAPrice = (SupplierData) => {
       /*
      if(SupplierData!=""){  
      handleModal("SupplierModal");
      setTimeout(() => setSupplierCode(SupplierData.SupCode), 500);
      }        
      */
  };



const [manufacturerCode, setManufacturerCode] = React.useState("");

useEffect(() => { 

  let chosenValuesData = { ...chosenValues};
    

    chosenValuesData['ManufacturerId'] = ManufacturerListNational[ManufacturerListNational.findIndex(Manufacturer_List2 => Manufacturer_List2.ManufacturerId == manufacturerCode)];
    setChosenValues(chosenValuesData);

    props.formData["ManufacturerId"] = manufacturerCode;
  
}, [manufacturerCode]); 



const addManufacturer = (ManufacturerData) => {

//alert(SupplierData);
//console.log("dddddddddddddddd",ManufacturerData.SupCode);
//console.log("local storage mmm",ManufacturerListNational);
  if(ManufacturerData!=""){ 
  handleModal("ManufacturerModal");
  setTimeout(() => setManufacturerCode(ManufacturerData.SupCode), 500);

  }
  
   };

const [bCatalogLocked, setbCatalogLocked] = useState(false); 
const [dynamicBTNLoc, setdynamicBTNLoc] = useState(false); 
useEffect(() => {
    
  setbCatalogLocked(props.bCatalogLocked);  
   
     
}, [props.bCatalogLocked]); //  
useEffect(() => { 
  setdynamicBTNLoc(props.dynamicBTNLoc);  
   
  
     
}, [props.dynamicBTNLoc]); //  

 
const handleBtnClick = (e) => {
  e.preventDefault();
  console.log('The link was clicked.');
};





const setUpdatedDetailsGridDataHandler = (buttonType) => {
  let validForm = props.checkValidateForm();
 
  // console.log('isval: ', validForm);
    if (validForm) {

      if(buttonType =='Save'){
        //when save
        mutate(validForm);
        
      }else{
        //when update
        mutateUpdate(validForm); 
      }
      

      
    }
};

const { isLoading : isLoadingSave, mutate} = useMutation(api.saveProductSpecification, {
  onSuccess: (data) => {
    if (data.status == 200) {
      
      
      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
     // swal(data.data.message, "", "success");
    queryClient.getQueriesData("productspecification");//api page name
    props.history.push("/product-specification");//

    }else{   
      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
    }
  },
});

const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.updateProductSpecification, {
  onSuccess: (data) => {

     
    if (data.status == 200) {

      if(data.data.status == 500){

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });

      }else{
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        }); 
        //swal(data.data.message, "", "success");
        queryClient.getQueriesData("productspecification");//api page name
        props.history.push("/product-specification");//
        api.dirtyModeDisable();

      } 
      }else{   
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
  },
});

return (
  <div className={classes.productPageTitle}>
    <div className="sw_makeStyles_tableContainer">
      <div className="d-flex justify-product mb-3">
        <Grid item xs={12} sm={12}>
          <div className="sw_page_heading">
            <div className="sw_heading_title">
              {DispensingLanguage[lan][menukey]["Product Specification Entry Form - Add/Edit"]}
            </div>
            </div>
            <div className="float-right sw_btn_control">
              {props.addProductForm ? (
                <Grid item xs={12} className="text-center">
                  <div className="float-right sw_btn_control">
                    <Button
                      disabled={props.bStockUpdate||bCatalogLocked}
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => props.handleReset()}
                    >
                      {DispensingLanguage[lan][menukey]["Reset"]}
                    </Button>
                    <Button
                      disabled={props.bStockUpdate||bCatalogLocked}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={(e) => handleUpdate(e,'Save')}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={() => props.history.push("/menu-entry")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} className="mt-2 text-center">
                  <Button
                      disabled={props.bStockUpdate || bCatalogLocked}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={(e) => handleUpdate(e,'handleUpdate')}
                  >
                    {DispensingLanguage[lan][menukey]["update"]}
                  </Button>

{/**************    Status BTN           ******* */}

                  {
                    
                    props.formData.StatusName==undefined?<></>:
                    props.formData.StatusName.map((item,i) => (
                      
                      <Button
                        //  disabled={props.bStockUpdate || props.bCatalogLocked}
                       // props.formData.StatusId[i]
                        disabled={dynamicBTNLoc}
                        className={i==0?(props.formData.StatusId[i]==1?'mr-2 dynamicBTNLocGreen':'mr-2 dynamicBTNLocDanger'):'mr-2 dynamicBTNLocGreen'} 
                        variant="contained"
                        color="primary" 
                        onClick={(e) => handleUpdateStatus(e,props.formData.StatusId[i],DispensingLanguage[lan][menukey][item])}
                      >
                        {DispensingLanguage[lan][menukey][item]}
                      </Button>
                      
                      ))

                  }



                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    //onClick={() => props.history.push("/menu-entry")}
                    onClick={() => backtolist()}
                  >
                    {DispensingLanguage[lan][menukey]["Back to List"]}
                  </Button>

                  {/* <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PrintMany()}
                    >
                      <i class="fas fa-print"></i>
                  </Button> */}
                </Grid>
              )}
            </div>
          
             <div className="mr-4 float-right mt-15">
              <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
            </div>
        </Grid>
      </div>

      {/* New row */}
      <Grid container spacing={3}>
        {/* New row */}
        <Grid item xs={12} sm={12}>
          {/* <Card className="sw_card">
            <CardContent> */}
               <Grid container spacing={4}>
 

                      <Grid item xs={2} sm={2}>
                        <TextField
                        
                          disabled={true}
                          error={props.errorObject.ItemCode}
                          helperText={props.errorObject.ItemCode}
                          required
                          id="ItemCode"                        
                          name="ItemCode"
                          label= {DispensingLanguage[lan][menukey]['Item Code'] }
                          value={props.formData.ItemCode}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => props.handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={7} sm={7}>
                        <TextField
                        
                          disabled={true}
                          error={props.errorObject.ItemName}
                          helperText={props.errorObject.ItemName}                         
                          required
                          id="ItemName"
                          name="ItemName"
                          label= {DispensingLanguage[lan][menukey]['Item Name'] }
                          value={props.formData.ItemName}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => props.handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={3} sm={3}>
                        <TextField
                        
                          disabled={props.bStockUpdate || bCatalogLocked}
                          error={props.errorObject.UnitName}
                          helperText={props.errorObject.UnitName}
                          required
                          id="UnitName"                        
                          name="UnitName"
                          label= {DispensingLanguage[lan][menukey]['Unit'] }
                          value={props.formData.UnitName}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => props.handleChange(e)}
                        />
                      </Grid>

                      <Grid item xs={2} sm={2}>
                    {/* <FormControl className={classes.fullWidth}> */}
                      

                        <Autocomplete
                            autoHighlight
                            id="YearId" 
                            disabled={props.bStockUpdate || props.bCatalogLocked}
                            options={PackageYearList}
                            onChange={(event, valueobj) => handleChangeYearDependency('YearId', valueobj, valueobj?valueobj.YearId:"")}
                            getOptionLabel={(option) => option.PackageYear}
                            //value={chosenValuesFacility['YearId']}
                            value={chosenValues['YearId']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.PackageYear}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              value={props.formData.YearId}
                              label={DispensingLanguage[lan][menukey]["Year"]}
                              variant="standard"
                              error={props.errorObject.YearId}
                              helperText={props.errorObject.YearId}
                              id="YearId"
                              name="YearId"
                              fullWidth
                            />
                            )}
                          />

                    {/* </FormControl> */}
                  </Grid>


                  <Grid item xs={5} sm={5}>
                    {/* <FormControl className={classes.fullWidth}> */}
                      

                        <Autocomplete
                            autoHighlight
                            id="PackageNo" 
                            disabled={props.bStockUpdate || bCatalogLocked}
                            options={ItemList}
                            onChange={(event, valueobj) => handleChangeChoosen('PackageNo', valueobj, valueobj?valueobj.PackageNo:"")}
                            getOptionLabel={(option) => option.PackageName}
                            //value={chosenValues['PackageNo']}
                            value={chosenValuesFacility['PackageNo']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.PackageName}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              required
                              value={props.formData.PackageNo}
                              label={DispensingLanguage[lan][menukey]["Package"]}
                              variant="standard"
                              error={props.errorObject.PackageNo}
                              helperText={props.errorObject.PackageNo}
                              id="PackageNo"
                              name="PackageNo"
                              fullWidth
                            />
                            )}
                          />

                    {/* </FormControl> */}
                  </Grid>


                     <Grid item xs={5} sm={5}>
                        <TextField
                        
                          disabled={props.bStockUpdate || bCatalogLocked}
                          error={props.errorObject.LotNo}
                          helperText={props.errorObject.LotNo}                     
                          id="LotNo"                        
                          name="LotNo"
                          label= {DispensingLanguage[lan][menukey]['Lot No'] }
                          value={props.formData.LotNo}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => props.handleChange(e)}
                        />
                      </Grid>





                  <Grid item xs={6} sm={6}>
                            {/* ====Upload Box=== */}
                            <div className="tp-upload-box marginTopBottompp">
                              <div className="text-right sw_relative">
                                {isLoading && <LoaderForGlobal />}
                              </div>

                              <div className="tp-body">
                                <label class="label-ctm" for="CertificateName_selectFile">
                                  <b>{DispensingLanguage[lan][menukey]["Specification Image"]}*</b>
                                  </label>
                                <div className="tp-file-upload">
                                  <input
                                    type="text"
                                    name="CertificateName_selectFile"
                                    id="CertificateName_selectFile"
                                    value={CertificateUploadedFileName}
                                    placeholder={
                                      DispensingLanguage[lan][menukey]["Choose a file"]
                                    }
                                    className="tp-form-control"
                                    readOnly={true}
                                  />
                                  <div className="file_browse_box">
                                    <input
                                      disabled={bCatalogLocked}
                                      type="file"
                                      accept=".pdf, .PDF"
                                      name="CertificateName"
                                      id="CertificateName"
                                      className="tp-upload"
                                      onChange={(e) => changeHandlerForCertificate(e,"Specification")} 
                                    />
                                    <label for="CertificateName" className="file_browse_icon">
                                      <span className="icon-upload">
                                        <CloudUploadOutlined />
                                      </span>
                                      {DispensingLanguage[lan][menukey]["Browse"]}
                                    </label>
                                  </div>
                                </div>
                                <div className="selectFile text-left">
                                  <span className="red">{ErrorMgsCertificate}</span>
                                </div>
                                {uploadFormData.CertificateName && 
                                <div className="attachment_view_card pdf_view_card">
                                  <Button
                                      hidden={bCatalogLocked}
                                      className="delete_icon"
                                      onClick={(e) => DeleteUploadFile(e,'Specification',uploadFormData.CertificateName)}
                                    >
                                    <Clear />
                                  </Button>
                                  <div className="view_icon">
                                    <PictureAsPdf
                                      onClick={() => UploadFileDownload(uploadFormData.CertificateName,'Specification')}
                                    />  
                                  </div>
                                </div>
                                }
                              </div>
                            </div>
                            {/* ====Upload Box=== */}
                  </Grid>
                  
                 
                  <Grid item xs={6} sm={6}>
                            {/* ====Upload Box=== */}
                            <div className="tp-upload-box marginTopBottompp thumbnail_tp-upload-box">
                              <div className="text-right sw_relative">
                                {isLoadingProduct && <LoaderForGlobal />}
                              </div>

                              <div className="tp-body">
                                <label class="label-ctm" for="ProductImage_selectFile"><b> {DispensingLanguage[lan][menukey]["Product Image"]}</b></label>
                                
                                <div className="tp-file-upload">
                                  <input
                                    type="text"
                                    name="ProductImage_selectFile"
                                    id="ProductImage_selectFile"
                                    value={CertificateUploadedFileNameProduct}
                                    placeholder={
                                      DispensingLanguage[lan][menukey]["Choose a file"]
                                    }
                                    className="tp-form-control"
                                    readOnly={true}
                                  />
                                  <div className="file_browse_box">
                                    <input
                                      type="file"
                                      disabled={bCatalogLocked}
                                      multiple="multiple"
                                      accept=".png, .jpeg, .jpg"
                                      name="ProductImage"
                                      id="ProductImage"
                                      className="tp-upload"
                                      onChange={(e) => changeHandlerForCertificate(e,"ProductName")} 
                                    //  onChange={changeHandlerForCertificate("ProductName")}
                                    />
                                    <label for="ProductImage" className="file_browse_icon">
                                      <span className="icon-upload">
                                        <CloudUploadOutlined />
                                      </span>
                                      {DispensingLanguage[lan][menukey]["Browse"]}
                                    </label>
                                  </div>
                                </div>
                                <div className="selectFile text-left">
                                  <span className="red">{ErrorMgsCertificateProduct}</span>
                                </div>
                                {uploadFormData.ProductImage && uploadFormData.ProductImage!='' && 

                              uploadFormData.ProductImage!=null ?
                              (
                               
                              uploadFormData.ProductImage.map((item) => (
                                <div className="attachment_view_card thumbnail_view_card">
                                  <Button
                                      hidden={bCatalogLocked}
                                      className="delete_icon"
                                      onClick={(e) => DeleteUploadFile(e,'ProductImage',item)}
                                    >
                                    <Clear />
                                  </Button>
                                   
                                 
                                  
                               


                                      <img
                                     {...srcset(
                                      
                                      item
                                      
                                      
                                      
                                      , 20, 1, 1)}
                                    // alt={item.title}
                                    loading="lazy"                                   
                                    onClick={() => UploadFileDownload(item,'ProductImage')}
                                  />


                                    
                                  



                                    {/* <PermMediaOutlined 
                                      onClick={() => UploadFileDownload(uploadFormData.ProductImage,'ProductImage')}
                                    />   */}
                                  </div>
                                

                              )) 
                              ):(<>
                              </>)



                                }
                              </div>
                             
                            </div>
                            {/* ====Upload Box=== */}
                  </Grid>

                  </Grid>

              {/* New Row */}


               
              <Grid container spacing={4}> 
                <Grid item xs={12} sm={12}>

                  <div className={classes.AdditionalFields}>Additional Fields</div>
                  <FormControl className={classes.fullWidth}>
                    <Grid container spacing={4}>
                       <Grid item xs={2} sm={2}>
                          <TextField
                           disabled={props.bStockUpdate|| bCatalogLocked}
                            error={props.errorObject.procQty}
                            helperText={props.errorObject.procQty}
                            inputProps={{min: 0, style: { textAlign: 'right' }}}
                            id="procQty"                        
                            name="procQty"
                            label= {DispensingLanguage[lan][menukey]['Proc. Qty'] }
                            value={props.formData.procQty}
                            fullWidth
                            autoComplete="family-name"
                            onChange={(e) => props.handleChange(e)}
                          />
                        </Grid>

                        <Grid item xs={3} sm={3}>
                          <TextField                        
                            disabled={props.bStockUpdate || bCatalogLocked}
                            error={props.errorObject.EstUnitPrice}
                            helperText={props.errorObject.EstUnitPrice} 
                            inputProps={{min: 0, style: { textAlign: 'right' }}}
                            id="EstUnitPrice"
                            name="EstUnitPrice"
                            label= {DispensingLanguage[lan][menukey]['EstUnitPrice'] }
                            value={props.formData.EstUnitPrice}
                            fullWidth
                            autoComplete="family-name"
                            onChange={(e) => props.handleChange(e)}
                          />
                        </Grid>


                        <Grid item xs={1} sm={1}>

                        <Autocomplete
                          disableClearable
                          disabled={props.bStockUpdate || bCatalogLocked}
                          autoHighlight
                          id="EstimatedCurrencyId"
                          options={EstimatedCurrencyList}
                          onChange={(event, valueobj) => handleChangeChoosen('EstimatedCurrencyId', valueobj, valueobj?valueobj.EstimatedCurrencyId:"")}
                          getOptionLabel={(option) => option.EstimatedCurrencyName}
                         // value={acValue}
                          value={chosenValues['EstimatedCurrencyId']}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.EstimatedCurrencyName}</Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={props.formData.EstimatedCurrencyId}
                              label={DispensingLanguage[lan][menukey]["Currency"]}
                              variant="standard"
                              error={props.errorObject.EstimatedCurrencyId}
                              helperText={props.errorObject.EstimatedCurrencyId}
                              id="EstimatedCurrencyId"
                              name="EstimatedCurrencyId"
                              fullWidth
                            />
                          )}
                        />

                        </Grid>


                      <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth_1}>
                        <Autocomplete
                          disabled={props.bStockUpdate || bCatalogLocked}
                          autoHighlight
                          id="SupCode"
                          options={SupplierListNational}
                          onChange={(event, valueobj) => handleChangeChoosen('SupCode', valueobj, valueobj?valueobj.SupCode:"")}
                          getOptionLabel={(option) => option.SupName}
                         // value={acValue}
                          value={chosenValues['SupCode']}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.SupName}</Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={props.formData.SupCode}
                              label={DispensingLanguage[lan][menukey]["Supplier"]}
                              variant="standard"
                              error={props.errorObject.SupCode}
                              helperText={props.errorObject.SupCode}
                              id="SupCode"
                              name="SupCode"
                              fullWidth
                            />
                          )}
                        />

                      </FormControl>
                  
                  <FormControl className={classes.fullWidth_2}>
                      <Add 
                    className="add_plus"
                    hidden={props.bStockUpdate || bCatalogLocked}
                    onClick={() => handleModal("SupplierOpen")}
                     
                   /> 


                   {/*

                { <Button
                  color="info"
                  className="mr-2 plus_sss"
                  onClick={() => handleModal("SupplierOpen")}

                >
                  ++
                </Button>}
                */}

                   </FormControl>

                   <SupplierModal
                  handleClose={handleModal}
                  openOrder={SupplierOpen}
                  addSupplier={addSupplier}
                  {...props}
                  /> 

                  </Grid>

                  



                  <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth_1}>
                        <Autocomplete
                          disabled={props.bStockUpdate || bCatalogLocked}
                          autoHighlight
                          id="ManufacturerId"
                          options={ManufacturerListNational}
                          onChange={(event, valueobj) => handleChangeChoosen('ManufacturerId', valueobj, valueobj?valueobj.ManufacturerId:"")}
                          getOptionLabel={(option) => option.ManufacturerName}
                         // value={acValue}
                          value={chosenValues['ManufacturerId']}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.ManufacturerName}</Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={props.formData.ManufacturerId}
                              label={DispensingLanguage[lan][menukey]["Manufacturer"]}
                              variant="standard"
                              error={props.errorObject.ManufacturerId}
                              helperText={props.errorObject.ManufacturerId}
                              id="ManufacturerId"
                              name="ManufacturerId"
                              fullWidth
                            />
                          )}
                        />

                      </FormControl>

                      <FormControl className={classes.fullWidth_2}>

                      <Add className="add_plus"
                      hidden={props.bStockUpdate || bCatalogLocked}
                      onClick={() => handleModal("ManufacturerOpen")}
                      />

                      </FormControl>

                      <ManufacturerModal
                        handleClose={handleModal}
                        openOrder={ManufacturerOpen}
                        addManufacturer={addManufacturer}
                        {...props}
                      />

                      

                  </Grid>

                  {/* <Grid item xs={1} sm={1}>
                  
                  <Add className="add_plus"
                  hidden={props.bStockUpdate || bCatalogLocked}
                  onClick={() => handleModal("ManufacturerOpen")}
                   />
                            
                  </Grid> */}
                        

                        <Grid item xs={3} sm={3}>
                        <TextField                        
                          disabled={props.bStockUpdate || bCatalogLocked}
                          error={props.errorObject.PurchasePrice}
                          helperText={props.errorObject.PurchasePrice}                         
                          inputProps={{min: 0, style: { textAlign: 'right' }}}
                          id="PurchasePrice"
                          name="PurchasePrice"
                          label= {DispensingLanguage[lan][menukey]['PurchasePrice'] }
                          value={props.formData.PurchasePrice}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => props.handleChange(e)}
                        />

                        

                      </Grid>
                      
                      <Grid item xs={1} sm={1}>


          
                     
                    <br></br>
                    
              <a className="mt-dgda"  href="https://dgdagov.info/index.php/registered-products/allopathic" target="_blank">
              <Tooltip title={DispensingLanguage[lan][menukey]['DGDA PRICE LOOKUP'] }><ViewList/></Tooltip>
                </a> 


      

                



                        {/*

                      <Button
                          //disabled={props.bStockUpdate}
                          className="mr-2 mt-15"
                          variant="contained"
                          color="success"
                          onClick={() => handleModal("DGDAPriceOpen")}
                        >
                          ...
                        </Button>
                        */}
                            {/*
                            <Button                      
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              onClick={() => handleModal("DGDAPriceOpen")}
                            >
                              {
                                DispensingLanguage[lan][menukey]["DGDA MRP"]
                              }
                            </Button>*/}

                      </Grid> 


                      <DGDAPriceModal
                        handleClose={handleModal}
                        openOrder={DGDAPriceOpen}
                        addDGDAPrice={addDGDAPrice}
                        {...props}
                      />

                      <Grid item xs={3} sm={3}>
                                         

                          <Autocomplete
                              autoHighlight
                              disabled={props.bStockUpdate || bCatalogLocked}
                              id="CurrencyId" 
                              options={CurrencyIdList}
                              onChange={(event, valueobj) => handleChangeChoosen('CurrencyId', valueobj, valueobj?valueobj.CurrencyId:"")}
                              getOptionLabel={(option) => option.CurrencyName}
                              //value={chosenValuesFacility['YearId']}
                              value={chosenValues['CurrencyId']}
                              renderOption={(option) => (
                              <Typography className="sw_fontSize">{option.CurrencyName}</Typography>
                              )}
                              renderInput={(params) => (
                              <TextField
                                {...params}
                                value={props.formData.CurrencyId}
                                label={DispensingLanguage[lan][menukey]["Currency"]}
                                variant="standard"
                                error={props.errorObject.CurrencyId}
                                helperText={props.errorObject.CurrencyId}
                                id="CurrencyId"
                                name="CurrencyId"
                                fullWidth
                              />
                              )}
                            />            
                                     
                      </Grid> 

                      <Grid item xs={2} sm={2}>
                            

                            <TextField                        
                            disabled={(formDisable==true?true:(props.formData.CurrencyId==1?true:false)) || bCatalogLocked}
                            error={props.errorObject.ExchangeRate}
                            helperText={props.errorObject.ExchangeRate}             
                            inputProps={{min: 0, style: { textAlign: 'right' }}}
                            id="ExchangeRate"
                            name="ExchangeRate"
                            label= {DispensingLanguage[lan][menukey]['ExchangeRate'] }
                            value={props.formData.ExchangeRate}
                            fullWidth
                            autoComplete="family-name"
                            onChange={(e) => props.handleChange(e)}
                          />
                          
                        </Grid> 


                        <Grid item xs={3} sm={3}> 
                            <TextField                        
                            disabled={true}
                            error={props.errorObject.TotalBDT}
                            helperText={props.errorObject.TotalBDT}             
                            inputProps={{min: 0, style: { textAlign: 'right' }}}
                            id="TotalBDT"
                            name="TotalBDT"
                            label= {DispensingLanguage[lan][menukey]['TotalBDT'] }
                            value={props.formData.TotalBDT}
                            fullWidth
                            autoComplete="family-name"
                            onChange={(e) => props.handleChange(e)}
                          />
                          
                        </Grid> 



                     </Grid> 

                  </FormControl>
                 </Grid>

                 
                      
 
                 <ImageModal
                  handleClose={handleModal}
                  openOrder={openImage}
                  addOrder={imageName}
                  {...props}
                />  
                        

                     
                     
  
                  </Grid>




            {/* </CardContent>
          </Card> */}
        </Grid>
      </Grid>
    </div>
  </div>
);
};

export default ProductSpecificationFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth_1: {
    width: "90%",
  },
  fullWidth_2: {
    width: "10%",
  },
  AdditionalFields: {
    fontSize: "18px",
    fontWeight: "bold",
    marginBottom: "15px"
  },
  text_right: {
    textAlign: "right"
  }
});
