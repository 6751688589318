import React, { useState, useContext } from "react";

// services & auth
import * as Service from "../../services/Service.js";
import * as api from "actions/api";

import { UserContext } from "../../context/user-info-context";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useForm } from "react-hook-form";

import swal from "sweetalert";

//Css
import "assets/css/custom.css";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import TransparentFooter from "components/Footers/TransparentFooter.js";
// import { Constants } from "ag-grid-community";

import Constants from "services/Constants";

const regex =
  /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/;

function LoginPage(props) {
  const userCtx = useContext(UserContext);

  const { buttonLabel, className } = props;
  // const { register, handleSubmit,  errors } = useForm();
  const [passwordShown, setPasswordShown] = useState(false);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [state, setState] = React.useState({
    service: Service,
    email: "",
    password: "",
  });
  const [modal, setModal] = useState(false);

  React.useEffect(() => {
    //
    // if(state.service.default.authToken() != null){
    //   swal("Oops!", "Your are already logged in!", "warning");
    //   props.history.push("/home");
    // }
    document.body.classList.add("login-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("login-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.value });
  };

  const LoginPage = (data) => {
    if (state.email.length > 0 && state.password.length > 0) {
      //if (!regex.test(state.email)) {
      // swal("Oops Error!", "Your email is not valid!", "error");
      //} else {

      const body = {
        email: state.email,
        password: state.password,
      };
      state.service.default
        .postApi("source/login.php", body)
        .then((res) => {
          if (res.success == 1) {
            sessionStorage.setItem("token", res.token);
            sessionStorage.setItem("User_info", JSON.stringify(res.user_data));

            const auth_token = sessionStorage.getItem("token")
              ? sessionStorage.getItem("token")
              : null;

            //Dispensing Language
            let options = {};
            options = {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${auth_token}`,
              },
            };

            api.getLoginDispensingLanguage(options).then((res1) => {
              //console.log(res)
              let DispensingLanguageData = res1.datalist;
              localStorage.setItem(
                "DispensingLanguage",
                JSON.stringify(DispensingLanguageData)
              );

              api.getLoginAllDropdown(options, "ALL").then((response) => {
                if (response.success == 1) {
                  // localStorage.setItem(
                  //   "AdjType",
                  //   JSON.stringify(response.datalist.AdjType)
                  // );

                  localStorage.setItem(
                    "CountryList",
                    JSON.stringify(response.datalist.CountryList)
                  );
                  // localStorage.setItem(
                  //   "DirectionList",
                  //   JSON.stringify(response.datalist.DirectionList)
                  // );
                  // localStorage.setItem(
                  //   "DosageFormlist",
                  //   JSON.stringify(response.datalist.DosageFormlist)
                  // );
                  localStorage.setItem(
                    "FacilityList",
                    JSON.stringify(response.datalist.FacilityList)
                  );
                  localStorage.setItem(
                    "MCWCFacilityList",
                    JSON.stringify(response.datalist.MCWCFacilityList)
                  );
                  localStorage.setItem(
                    "FacilityListGlobal",
                    JSON.stringify(response.datalist.FacilityListGlobal)
                  );
                  // localStorage.setItem(
                  //   "GenericList",
                  //   JSON.stringify(response.datalist.GenericList)
                  // );
                  // localStorage.setItem(
                  //   "IntervalList",
                  //   JSON.stringify(response.datalist.IntervalList)
                  // );
                  // localStorage.setItem(
                  //   "OrderType",
                  //   JSON.stringify(response.datalist.OrderType)
                  // );
                  // localStorage.setItem(
                  //   "PackSizelist",
                  //   JSON.stringify(response.datalist.PackSizelist)
                  // );
                  // localStorage.setItem(
                  //   "PostList",
                  //   JSON.stringify(response.datalist.PostList)
                  // );
                  // localStorage.setItem(
                  //   "RouteOfAdminlist",
                  //   JSON.stringify(response.datalist.RouteOfAdminlist)
                  // );
                  // localStorage.setItem(
                  //   "RxLevelList",
                  //   JSON.stringify(response.datalist.RxLevelList)
                  // );
                  // localStorage.setItem(
                  //   "SpecialisationList",
                  //   JSON.stringify(response.datalist.SpecialisationList)
                  // );
                  // localStorage.setItem(
                  //   "Strengthlist",
                  //   JSON.stringify(response.datalist.Strengthlist)
                  // );
                  // localStorage.setItem(
                  //   "TitleList",
                  //   JSON.stringify(response.datalist.TitleList)
                  // );
                  // localStorage.setItem(
                  //   "VEN_List",
                  //   JSON.stringify(response.datalist.VEN_List)
                  // );

                  localStorage.setItem(
                    "Unitofmeasurelist",
                    JSON.stringify(response.datalist.Unitofmeasurelist)
                  );
                  localStorage.setItem(
                    "ParentMenu",
                    JSON.stringify(response.datalist.ParentMenu)
                  );
                  localStorage.setItem(
                    "MenuUrl",
                    JSON.stringify(response.datalist.MenuUrl)
                  );
                  // localStorage.setItem(
                  //   "ABC_List",
                  //   JSON.stringify(response.datalist.ABC_List)
                  // );

                  // localStorage.setItem(
                  //   "language_preference",
                  //   JSON.stringify(response.datalist.t_language_preference)
                  // );
                  // localStorage.setItem(
                  //   "Gender",
                  //   JSON.stringify(response.datalist.Gender)
                  // );
                  // localStorage.setItem(
                  //   "MaritalStatus_List",
                  //   JSON.stringify(response.datalist.MaritalStatus_List)
                  // );
                  // localStorage.setItem(
                  //   "LABTest_List",
                  //   JSON.stringify(response.datalist.LABTest_List)
                  // );
                  localStorage.setItem(
                    "MenuList",
                    JSON.stringify(response.datalist.MenuList)
                  );

                  localStorage.setItem(
                    "UserList",
                    JSON.stringify(response.datalist.UserList)
                  );

                  localStorage.setItem(
                    "UserListEdit",
                    JSON.stringify(response.datalist.UserListEdit)
                  );

                  localStorage.setItem(
                    "YearList",
                    JSON.stringify(response.datalist.YearList)
                  );
                  localStorage.setItem(
                    "Form3SDPGroupList",
                    JSON.stringify(response.datalist.Form3SDPGroupList)
                  );
                  localStorage.setItem(
                    "CureentYearList",
                    JSON.stringify(response.datalist.CureentYearList)
                  );

                  localStorage.setItem(
                    "MonthList",
                    JSON.stringify(response.datalist.MonthList)
                  );

                  // localStorage.setItem(
                  //   "Direction",
                  //   JSON.stringify(response.datalist.Direction)
                  // );

                  localStorage.setItem(
                    "RoleList",
                    JSON.stringify(response.datalist.RoleList)
                  );

                  localStorage.setItem(
                    "LanguageList",
                    JSON.stringify(response.datalist.LanguageList)
                  );

                  localStorage.setItem(
                    "GroupNameList",
                    JSON.stringify(response.datalist.GroupNameList)
                  );

                  localStorage.setItem(
                    "gWarehouseListAll",
                    JSON.stringify(response.datalist.gWarehouseListAll)
                  );

                  localStorage.setItem(
                    "whstatusmapwarahouse",
                    JSON.stringify(response.datalist.whstatusmapwarahouse)
                  );

                  localStorage.setItem(
                    "AdjTypeList",
                    JSON.stringify(response.datalist.AdjTypeList)
                  );

                  // localStorage.setItem(
                  //   "MosTypelist",
                  //   JSON.stringify(response.datalist.MosTypelist)
                  // );

                  // localStorage.setItem(
                  //   "FacilityLocation",
                  //   JSON.stringify(response.datalist.FacilityLocation)
                  // );

                  // localStorage.setItem(
                  //   "ATC_List",
                  //   JSON.stringify(response.datalist.ATC_List)
                  // );

                  localStorage.setItem(
                    "OrderListApprovalUser",
                    JSON.stringify(response.datalist.OrderListApprovalUser)
                  );

                  localStorage.setItem(
                    "PackageYearList",
                    JSON.stringify(response.datalist.PackageYearList)
                  );

                  /* Catalog */
                  localStorage.setItem(
                    "PackageList",
                    JSON.stringify(response.datalist.PackageList)
                  );

                  localStorage.setItem(
                    "CurrencyList",
                    JSON.stringify(response.datalist.CurrencyList)
                  );

                  localStorage.setItem(
                    "EstimatedCurrencyList",
                    JSON.stringify(response.datalist.EstimatedCurrencyList)
                  );

                  localStorage.setItem(
                    "DistrictList",
                    JSON.stringify(response.datalist.DistrictList)
                  );

                  // localStorage.setItem(
                  //   "Regionlist",
                  //   JSON.stringify(response.datalist.Regionlist)
                  // );

                  // localStorage.setItem(
                  //   "Zonelist",
                  //   JSON.stringify(response.datalist.Zonelist)
                  // );

                  // localStorage.setItem(
                  //   "Communelist",
                  //   JSON.stringify(response.datalist.Communelist)
                  // );

                  localStorage.setItem(
                    "FlevelList",
                    JSON.stringify(response.datalist.FlevelList)
                  );

                  // localStorage.setItem(
                  //   "SourceList",
                  //   JSON.stringify(response.datalist.SourceList)
                  // );

                  localStorage.setItem(
                    "FTypeList",
                    JSON.stringify(response.datalist.FTypeList)
                  );

                  localStorage.setItem(
                    "WarehouseAndFacilityList",
                    JSON.stringify(response.datalist.WarehouseAndFacilityList)
                  );

                  // localStorage.setItem(
                  //   "Ownertypelist",
                  //   JSON.stringify(response.datalist.Ownertypelist)
                  // );

                  // localStorage.setItem(
                  //   "Servicearealist",
                  //   JSON.stringify(response.datalist.Servicearealist)
                  // );

                  // localStorage.setItem(
                  //   "Regimen_List",
                  //   JSON.stringify(response.datalist.Regimen_List)
                  // );
                  localStorage.setItem(
                    "FacilityId",
                    res.user_data[0].FacilityId
                  );
                  localStorage.setItem("UseFor", res.user_data[0].UseFor);
                  // localStorage.setItem(
                  //   "UseFor",
                  //   res.user_data[0].FacilityId.substring(0, 1)==='T'?'UIMS':'WIMS'
                  // );

                  for (
                    let i = 0;
                    i < response.datalist.FacilityList.length;
                    i++
                  ) {
                    if (
                      response.datalist.FacilityList[i]["id"] ==
                      res.user_data[0].FacilityId
                    ) {
                      localStorage.setItem(
                        "FacilityName",
                        response.datalist.FacilityList[i]["name"]
                      );
                      // console.log('response.datalist.FacilityList[i][]: ', response.datalist.FacilityList[i]['name']);
                      break;
                    } else {
                      localStorage.setItem("FacilityName", "");
                      //  console.log('FacilityName: ', 'FacilityName');
                    }
                  }

                  localStorage.setItem("LangCode", res.user_data[0].LangCode);
                  localStorage.setItem(
                    "bFacilitySelected",
                    res.user_data[0].bFacilitySelected
                  );
                  userCtx.replaceFacilityHandler({
                    FacilityId: res.user_data[0].FacilityId,
                    FacilityName:
                      response.datalist.FacilityList[
                        res.user_data[0].FacilityId
                      ],
                    LangId: res.user_data[0].LangCode,
                  });

                  api.getLoginFacilityListNotEqual99ANDSuppliers(options, "ALL")
                    .then((response) => {

                      localStorage.setItem(
                        "FacilityEmployee",
                        JSON.stringify(response.datalist.FacilityEmployee)
                      );

                      localStorage.setItem(
                        "StoreType",
                        JSON.stringify(response.datalist.StoreType)
                      );

                      localStorage.setItem(
                        "UpazilaList",
                        JSON.stringify(response.datalist.UpazilaList)
                      );

                      localStorage.setItem(
                        "SDPGroupList",
                        JSON.stringify(response.datalist.SDPGroupList)
                      );
                      localStorage.setItem(
                        "UnionsList",
                        JSON.stringify(response.datalist.UnionsList)
                      );
                      localStorage.setItem(
                        "UnitsList",
                        JSON.stringify(response.datalist.UnitsList)
                      );

                      localStorage.setItem(
                        "gWHList",
                        JSON.stringify(response.datalist.gWHList)
                      );

                      localStorage.setItem(
                        "SoReasonList",
                        JSON.stringify(response.datalist.SoReasonList)
                      );

                      localStorage.setItem(
                        "ReasonNameList",
                        JSON.stringify(response.datalist.ReasonNameList)
                      );

                      localStorage.setItem(
                        "CurrentFacilitySetting",
                        JSON.stringify(response.datalist.CurrentFacilitySetting)
                      );

                      localStorage.setItem(
                        "Designation",
                        JSON.stringify(response.datalist.Designation)
                      );
                      localStorage.setItem(
                        "IndentDesignation",
                        JSON.stringify(response.datalist.IndentDesignation)
                      );

                      localStorage.setItem(
                        "SupplyPlanProfileList",
                        JSON.stringify(response.datalist.SupplyPlanProfileList)
                      );

                      localStorage.setItem(
                        "SupplierList",
                        JSON.stringify(response.datalist.SupplierList)
                      );

                      localStorage.setItem(
                        "SupplierListNational",
                        JSON.stringify(response.datalist.SupplierListNational)
                      );

                      /* Catalog*/

                      localStorage.setItem(
                        "ManufacturerListNational",
                        JSON.stringify(response.datalist.ManufacturerListNational)
                      );

                      localStorage.setItem(
                        "DepartmentList",
                        JSON.stringify(response.datalist.DepartmentList)
                      );
                      localStorage.setItem(
                        "MaintenanceScheduleList",
                        JSON.stringify(response.datalist.MaintenanceScheduleList)
                      );
                      localStorage.setItem(
                        "IndentorList",
                        JSON.stringify(response.datalist.IndentorList)
                      );
                      localStorage.setItem(
                        "AssetStatusList",
                        JSON.stringify(response.datalist.AssetStatusList)
                      );
                      localStorage.setItem(
                        "AssetProcuringList",
                        JSON.stringify(response.datalist.AssetProcuringList)
                      );
                      localStorage.setItem(
                        "FundingSourceList",
                        JSON.stringify(response.datalist.FundingSourceList)
                      );

                      localStorage.setItem(
                        "IssuingToFacility",
                        JSON.stringify(response.datalist.IssuingToFacility)
                      );
                      localStorage.setItem(
                        "OrderTo",
                        JSON.stringify(response.datalist.OrderTo)
                      );
                      localStorage.setItem(
                        "UsersListByFacility",
                        JSON.stringify(response.datalist.UsersListByFacility)
                      );

                      // localStorage.setItem(
                      //   "PrescriberList",
                      //   JSON.stringify(response.datalist.PrescriberList)
                      // );
                      // localStorage.setItem(
                      //   "DispenserList",
                      //   JSON.stringify(response.datalist.DispenserList)
                      // );
                      localStorage.setItem(
                        "SDPList",
                        JSON.stringify(response.datalist.SDPList)
                      );
                      localStorage.setItem(
                        "ItemGroupNavField",
                        JSON.stringify(response.datalist.ItemGroupNavField)
                      );
                      localStorage.setItem(
                        "ItemGroupNavFieldGlobal",
                        JSON.stringify(response.datalist.ItemGroupNavFieldGlobal)
                      );

                      localStorage.setItem(
                        "AdjType",
                        JSON.stringify(response.datalist.AdjType)
                      );			  
					  
                      window.location.href =
                        `${process.env.REACT_APP_BASE_NAME}` +
                        res.user_data[0].roleredirect;

                      //  window.location.href = `${process.env.REACT_APP_BASE_NAME}/dashboard`;
                    });
                }

                //
              });
            });

            //localStorage.setItem('AdjType', []);

            // window.location.href = "/dashboard";
          } else if (res.success == 0) {
            swal("Error!", `${res.message}`, "error");
          }
        })
        .catch((err) => {
          swal("Error!", `${err}`, "error");
        });
      //}
    } else {
      swal("Oops Error!", "Please fill all required fields", "error");
    }
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const toggle = () => setModal(!modal);

  return (
    <>
      <ExamplesNavbar />
      <div className="page-header">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
          }}
        ></div>
        <div className="content">
          <Container>
            <Col className="ml-auto mr-auto" md="4">
              <Card className="card-login card-plain">
                <Form action="" className="form" method="">
                  <CardHeader className="text-center">
                    <div className="logo-container"></div>
                  </CardHeader>
                  <CardBody>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (firstFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText className="sw_login_padd">
                          <i className="now-ui-icons users_circle-08"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="placeholder_color"
                        placeholder="Username..."
                        type="text"
                        name="email"
                        onChange={(e) => handleChange(e)}
                        // autoComplete="username"
                        valid={false}
                        invalid={false}
                        // ref={register({ required: true })}
                        onFocus={() => setFirstFocus(true)}
                        onBlur={() => setFirstFocus(false)}
                      ></Input>
                      {/* {errors.username &&
                        errors.username.type === "required" && (
                          <span>This field is required</span>
                        )} */}
                    </InputGroup>
                    <InputGroup
                      className={
                        "no-border input-lg" +
                        (lastFocus ? " input-group-focus" : "")
                      }
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText className="sw_login_padd">
                          <i className="now-ui-icons ui-1_lock-circle-open"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        className="placeholder_color"
                        placeholder="Password..."
                        name="password"
                        onChange={(e) => handleChange(e)}
                        // autoComplete="password"
                        type={passwordShown ? "text" : "password"}
                        // ref={register({ required: true })}
                        onFocus={() => setLastFocus(true)}
                        onBlur={() => setLastFocus(false)}
                      ></Input>
                      <InputGroupAddon addonType="append">
                        <InputGroupText className="password-eye">
                          <i
                            onClick={togglePasswordVisiblity}
                            className="fa fa-eye"
                            aria-hidden="true"
                          ></i>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </CardBody>
                  <CardFooter className="text-center">
                    <Button
                      block
                      className="btn-round"
                      color="info"
                      // href="#pablo"
                      onClick={(e) => {
                        e.preventDefault();
                        LoginPage();
                      }}
                      size="lg"
                    >
                      LOGIN
                    </Button>
                    {/* <div className="pull-left">
                      <h6>
                        <a
                          className="link"
                          // href="/signup-page"
                          onClick={(e) => {
                            e.preventDefault();
                            props.history.push("/signup");
                          }}
                        >
                          Create Account
                        </a>
                      </h6>
                    </div> */}
                    {/* <div className="pull-right">
                      <h6>
                        <a
                          className="link"
                          href="javascript:void(0)"
                          onClick={() => toggle()}
                        >
                          Forgot Password?
                        </a>
                      </h6>
                    </div> */}
                  </CardFooter>
                </Form>
              </Card>
            </Col>
          </Container>
        </div>
        {/* <TransparentFooter /> */}
      </div>

      <Modal isOpen={modal} toggle={toggle} className={className} centered>
        <ModalHeader toggle={toggle}>Forgot Password Link</ModalHeader>
        <ModalBody className="text-center">
          <a className="" href={"./reset-password"}>
            This is the Link to Change Password
          </a>
        </ModalBody>
        <ModalFooter className="reset-modal-btn">
          <Button
            color="primary"
            onClick={(e) => {
              e.preventDefault(e);
              props.history.push("./reset-password");
            }}
          >
            Reset Password
          </Button>
          {/* <Button color="secondary" onClick={toggle}>Cancel</Button> */}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default LoginPage;
