import React, { useState, useRef, useEffect } from "react"; //forwardRef,
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
// materialui table
import MaterialTable from "material-table";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import { 
  FormControl,
    Grid, 
    Card,
    CardContent , 
    Typography,
    TextField,

} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import ExportButtons from "../../../components/ExportButtons";

//import ReactExport from "react-data-export";
import { useTranslation } from "react-i18next";

//import XLSX from "xlsx";
//import FileSaver from "file-saver";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
// import OrderModal from "./OrderModal";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import { DeleteOutline, Edit, ViewList } from "@material-ui/icons";



const Form7B = (props) => {
  //get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "form-7b";
const FacilityId = localStorage.getItem("FacilityId");
const FacilityName = localStorage.getItem("FacilityName");

  const [openOrder, setOpenOrder] = useState(false);

  const classes = useStyles();
  const tableRef = useRef();

  const [selectedRow, setSelectedRow] = useState(null);
  const [productsData, selectProductsData] = useState([]);
  const [productsAllData, selectProductsAllData] = useState([]);
  const [productGroupData, selectProductGroupData] = useState([]);
  const [productGroupFilter, selectProductGroupFilter] = useState("0");
  const [firstLoad, setFirstLoad] = useState(true);
  const [numberRowPerPage, setNumberRowPerPage] = useState(5);
  const [totalRow, setTotalRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [dataItems, setDataItems] = useState([]);
  //const [query, setQuery] = useState("");

  const FacilityList = JSON.parse(localStorage.getItem("whstatusmapwarahouse"));

  const [currFacilityCode, setcurrFacilityCode] = useState(FacilityList.length>0? FacilityList[0].id:"");
  const [currFacilityName, setcurrFacilityName] = useState(FacilityList.length>0? FacilityList[0].name:"");

  const currentDate = new Date();
  const MonthList = JSON.parse(localStorage.getItem("MonthList"));
  const YearList = JSON.parse(localStorage.getItem("YearList"));
  let cMonthId = currentDate.getMonth();
  let cYearId = currentDate.getFullYear();
 
  let CurrentMonthId = 0;
  let CurrentYearId = 0;
  if(cMonthId === 0){
    CurrentMonthId = 12;
    CurrentYearId = cYearId-1;
  }else{
    CurrentMonthId = cMonthId;
    CurrentYearId = cYearId;
  }
  let CurrentMonthObj = MonthList[MonthList.findIndex(Month_List => Month_List.id == CurrentMonthId)];
  let CurrentYearObj = YearList[YearList.findIndex(Year_List => Year_List.id == CurrentYearId)];
  const [currMonthId, setcurrMonthId] = useState(CurrentMonthId);
  const [currYear, setcurrYear] = useState(CurrentYearId);
  const [chosenValuesFacility, setChosenValuesFacility] = useState({
  

  });
 //"MonthId": {"id": CurrentMonthId,"name": CurrentMonthName}
 const [chosenValuesMonth, setChosenValuesMonth] = useState({
  "MonthId": CurrentMonthObj
});

const [chosenValues, setChosenValues] = useState({
  "YearId": CurrentYearObj
});


  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(90, "days").format("YYYY-MM-01")
  );

  const handleStartDateChange = (date) => {
    let newDate = moment(date).format("YYYY-MM-DD");
    setSelectedStartDate(newDate);
    setLoading(true);
  };

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const handleEndDateChange = (date) => {
    let newDate = moment(date).format("YYYY-MM-DD");
    setSelectedEndDate(newDate);
    setLoading(true);
  };

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const [isLoading, setLoading] = useState(true);


  const handleChangeChoosenFacility = (name, valueobj, value) => {
    let chosenValuesDataFacility = { ...chosenValuesFacility};
    chosenValuesDataFacility[name] = valueobj;
    setChosenValuesFacility(chosenValuesDataFacility); 
  
    setFirstLoad(true);
    setLoading(false);
    setcurrFacilityCode(value);
    setcurrFacilityName(valueobj.name);
  
  };

  const handleChangeChoosenMonth = (name, valueobj, value) => {
    let chosenValuesDataMonth = { ...chosenValuesMonth};
    chosenValuesDataMonth[name] = valueobj;
    setChosenValuesMonth(chosenValuesDataMonth); 
  
    setFirstLoad(true);
    setLoading(false);
    setcurrMonthId(value);
  
  
  };

  const handleChangeChoosenYear = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues};
    chosenValuesData[name] = valueobj;   
    setChosenValues(chosenValuesData); 
    setcurrYear(value);
  };
 

  let params = {
    menukey: menukey,
    action: 'getForm7BListGlobal',
    FacilityId: currFacilityCode,
    page_limit: JSON.stringify(1000),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    MonthId: currMonthId,
    Year: currYear,
    TransactionTypeId: 0,
  };
  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getForm7B,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setLoading(false);
      },
    }
  );

  const { mutate } = useMutation(api.deleteForm7, {
    onSuccess: (data) => {
      if (data.status == 200) {
      refetch();
   
      queryClient.getQueriesData("form7b");
      
      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
        duration: 10000,
      });

      }else{ 
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });  
           
        }
    },
  });

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");

    window.open(
      finalUrl +
        "?action=form7BExcelExportGlobal" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&FacilityId=" +
        currFacilityCode +
        "&FacilityName=" +
        currFacilityName +
        "&MonthId=" +
        currMonthId +
        "&Year=" +
        currYear + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
   
    editReceiveInv(rowData);
  };

  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 70,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
      
    },
    {
      title: '',
      field: "FacilityCode",
      headerFilter: true,
      visible: false
      // width: 110,
    },
    {
      title: DispensingLanguage[lan][menukey]["Report Month"],
      field: "MonthYear",
      headerFilter: true,
      visible: false
      // width: 110,
    },
    
    {
      title: DispensingLanguage[lan][menukey]["Upazila"],
      field: "FacilityName",
      headerFilter: true,
      // width: 110,
    },
    {
      title: DispensingLanguage[lan][menukey]["Report Year"],
      field: "reportYear",
      headerFilter: true,
      width: 130,
    },
    {
      title: DispensingLanguage[lan][menukey]["Report Month"],
      field: "reportMonth",
      headerFilter: true,
      width: 130,
    },
    {
      title: DispensingLanguage[lan][menukey]["District"],
      field: "DistrictName",
      headerFilter: true,
      // width: 110,
    },
    {
      title: DispensingLanguage[lan][menukey]["Total SDP"],
      field: "TotalSDP",
      hozAlign: "right",
      headerHozAlign: "right",
      headerFilter: true,
      width: 130,
    },
    {
      title: DispensingLanguage[lan][menukey]["Reports Submitted"],
      field: "TotalRepSubmitted",
      hozAlign: "right",
      headerHozAlign: "right",
      headerFilter: true,
      width: 175,
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      hozAlign: "center", 
      headerHozAlign: "center", 
      width: 110,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const handleChangePage = (data) => {
    setPageNumber(data);
    setFirstLoad(false);
  };
  const handleChangeRowPerPage = (data) => {
    setNumberRowPerPage(data);
  };

  const editReceiveInv = (data) => {
   
    let route = '/form7b-global/edit/'+data.id+'|'+data.transferFacilityCode+'|'+ data.FacilityCode;
    props.history.push(route);
  };

  const deleteReceiveInv = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Once deleted, you will not be able to recover this data!"
        ]
      ),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willDelete) => {
      if (willDelete) {
        mutate(data.id);
      }
    });
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    const cellValue = props.cell._cell.value || "Edit | Show";
    // if (rowData.bStockUpdated || rowData.BCancel)
      return (
        <>
          <ViewList
            onClick={() => {
              editReceiveInv(rowData);
            }}
          />
        </>
      );
    // else
    //   return (
    //     <>
    //       <Edit
    //         onClick={() => {
    //           editReceiveInv(rowData);
    //         }}
    //       />
    //       <DeleteOutline
    //         onClick={() => {
    //           deleteReceiveInv(rowData);
    //         }}
    //       />
    //     </>
    //   );
  }

  const addOrder = (OrderProductData) => {
    editReceiveInv(OrderProductData);

    //setManyTableData(OrderProductData.concat(ManyTableData));
    // handleAdd(OrderProductData.concat(ManyTableData));
    // handleClose('OrderModal');
  };

  const handleModal = (modalname) => {
    switch (modalname) {
      case "OrderModal":
        setOpenOrder(false);
        break;
      case "OrderOpen":
        setOpenOrder(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
   
  }, [currFacilityCode]);

  return (
    <>
      <div className={classes.ReceivePageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Form 7B"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>

            <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                           
                            disableClearable
                            id="FacilityCode"
                            options={FacilityList}
                            onChange={(event, valueobj) => handleChangeChoosenFacility('FacilityCode', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                           
                            defaultValue={FacilityList[FacilityList.findIndex(facilitylist => facilitylist.id == currFacilityCode)]}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["WareHouse"]}
                              variant="standard"
                              id="FacilityCode"
                              name="FacilityCode"
                              fullWidth
                            />
                            )}
                          />

                    </FormControl>
                  </Grid>
              {/* <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["From"]}
                      autoOk={true}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["To"]}
                      autoOk={true}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid> */}

              <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            disableClearable
                            id="MonthId"
                            options={MonthList}
                            onChange={(event, valueobj) => handleChangeChoosenMonth('MonthId', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValuesMonth['MonthId']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Report Month"]}
                              variant="standard"
                              id="MonthId"
                              name="MonthId"
                              fullWidth
                            />
                            )}
                          />
                    </FormControl>
                  </Grid>
                  <Grid item xs={2} sm={2}>

                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                           
                            id="YearId"
                            options={YearList}
                           
                            onChange={(event, valueobj) => handleChangeChoosenYear('YearId', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValues['YearId']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              
                              label={DispensingLanguage[lan][menukey]["Report Year"]}
                              variant="standard"
                             
                              id="YearId"
                              name="YearId"
                              fullWidth
                            />
                            )}
                          />

                        </FormControl>
                  </Grid>

              <Grid item xs={5} sm={5}>
                <div className="float-right sw_btn_control">
                  {/* <Button
                    color="info"
                    className="mr-2"
                    onClick={() => handleModal("OrderOpen")}
                  >
                    {DispensingLanguage[lan][menukey]["Select From Order"]}
                  </Button> */}
                  
                  {/* <Button
                    color="info"
                    className="mr-2"
                    onClick={() => {
                      props.history.push("/form7b/add");
                    }}
                  >
                    {DispensingLanguage[lan][menukey]["Add Form7B"]}
                  </Button> */}

                 <ExportButtons PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}  {...props}></ExportButtons>
                 
                  {/* <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button> */}
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        {/* <OrderModal
          hand9leClose={handleModal}
          openOrder={openOrder}
          addOrder={addOrder}
          {...props}
        /> */}
        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="500px"
              options={{
                groupBy: "MonthYear",
                columnCalcs: "both",
                
              }}
              
               rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
             
              
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Form7B;

const useStyles = makeStyles({
  ReceivePageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
