import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom"; 
import * as api from "../../../actions/api";
import SDPListFormData from "./SDPListFormData";
import moment from "moment";


const EditSDPList = (props) => {


    //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "sdplist";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;  
  const UserName = UserInfo==0?'': UserInfo[0].id;

  const [selectedDate, setSelectedDate] = useState(
    new Date()
  );
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  //const dateValue = props.selectedDate ? moment(props.selectedDate) : null

  const [formData, setFormData] = useState({
    FGroupCode:"",
    FacilityCode:"",
    StaffName:"",
    FacilityName:"",
    StaffName:"",
    PhoneNo:"",
    StaffDesignation:"",
    dateofbirth:null,
    dateofjoin:null,
    NationalId:"",
    HRIS:"",
    sunitid:"",
    UnionCode:"",
    fpfacilityId:"",
    incharge:false,
    bShow:false,
    ExistingbShow:"",
    UnionName:"",
  });

  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  //console.log('sdasd',id);
  const queryClient = useQueryClient();

  useQuery(
    ["SdpList", id],
    () =>
      api.getSDPListss(id).then((res) => {
        setFormData(res.data);
        api.dirtyModeDisable();
      }),
    {
      enabled: Boolean(id),
    }
  );

  const { mutate } = useMutation(api.UpdaeSDPData, {
    onSuccess: (data) => { 

      if (data.data.status == 200) {

       
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });

        queryClient.getQueriesData("SdpList"); // include the page name
        props.history.push("/sptList-entry"); //page url
      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });



  //=================================================
  const generateSDPName = (data) => {

    const {
      sunitid,
      StaffName,
      StaffDesignation,
      UnionCode,
      incharge,
    } = data;

    let FacilityName = "";

    const unitsNames = JSON.parse(localStorage.getItem("UnitsList"));

    let unitsNameStr = "";
    if (unitsNames != "") {
      unitsNameStr = unitsNames.find((g) => g.id == sunitid)?.name;
    }
   

    const UnionsNames = JSON.parse(localStorage.getItem("UnionsList"));

    let unionsNameStr = "";
    if (UnionsNames != "") {
      unionsNameStr = UnionsNames.find((g) => g.id == UnionCode)?.name;
    }
   
   
   let Staff_Designation_Name = '';
    if(StaffDesignation){
      Staff_Designation_Name = ', '+StaffDesignation;
    }
   
   let Staff__Name = '';
    if(StaffName){
      Staff__Name = StaffName+"";
    }
   
  let unionsNam_Name = ' ';
    if(unionsNameStr){
      unionsNam_Name = ', '+unionsNameStr;
    } 
    
    let incharge_Label = '';
    if(incharge === true){
      //console.log("incharge ................");
      incharge_Label = ', '+'(In-Charge)';
    }else{
      incharge_Label = "";
    } 
   

    if(unitsNameStr){
      FacilityName = (unitsNameStr || "") +", " + Staff__Name+ Staff_Designation_Name+unionsNam_Name+incharge_Label;  
    }else{
      FacilityName = Staff__Name+ Staff_Designation_Name+unionsNam_Name+incharge_Label;
    
    }
   
    
    return {
      FacilityName: FacilityName,
    };
  };
  //=================================================



  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;

    let productData = generateSDPName(data);

    //console.log("Update form data >>>>> ",data.incharge);
    if (productData.FacilityName) {
      let FindInchargeText = productData.FacilityName.search("In-Charge");
      console.log("productData.FacilityName ",FindInchargeText);
      
      if(data.incharge == 1){
        if(FindInchargeText == -1){
          data["FacilityName"] = productData.FacilityName+' (In-Charge)';
        }else{
          data["FacilityName"] = productData.FacilityName;
        }
        
      }else{
        data["FacilityName"] = productData.FacilityName;
      }
     
    }

    if (name == "PhoneNo") {
      let vPhoneNoNo = '';
        const onlyNums = value.replace(/[^0-9]/g, '');
        vPhoneNoNo = onlyNums;
        data["PhoneNo"] = vPhoneNoNo;

    } 

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };

  //====== Start Choosen Dropdown===========
  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value;   

    let productData = generateSDPName(data);

    //console.log("Update form data >>>>> ",data.incharge);
    if (productData.FacilityName) {
      let FindInchargeText = productData.FacilityName.search("In-Charge");
      //console.log("productData.FacilityName ",FindInchargeText);
      
      if(data.incharge == 1){
        if(FindInchargeText == -1){
          data["FacilityName"] = productData.FacilityName+' (In-Charge)';
        }else{
          data["FacilityName"] = productData.FacilityName;
        }
        
      }else{
        data["FacilityName"] = productData.FacilityName;
      }
     
    }


    if (name == "PhoneNo") {
      let vPhoneNoNo = '';
        const onlyNums = value.replace(/[^0-9]/g, '');
        vPhoneNoNo = onlyNums;
        data["PhoneNo"] = vPhoneNoNo;

    } 
    setFormData(data); 
    setErrorObject({ ...errorObject, [name]: "" });
    api.dirtyModeEnable();
  }
  //====== End Choosen Dropdown===========

  const handleCheck = (e) => {
    const { name, checked } = e.target;

    //===================
     let data = { ...formData };
    data[name] = checked;
    if(data.incharge === true || data.incharge === false){
      
        let productData = generateSDPName(data);
        if (productData.FacilityName) {
          data["FacilityName"] = productData.FacilityName;
        }
      } 
    //===================

    setFormData({ ...formData, [name]: checked });
    setFormData(data); //added for update incharge text
    api.dirtyModeEnable();
  };




  const handleReset = () => {
    setFormData({
      FGroupCode:"",
      FacilityCode:"",
      StaffName:"",
      FacilityName:"",
      StaffName:"",
      PhoneNo:"",
      StaffDesignation:"",
      dateofbirth:null,
      dateofjoin:null,
      NationalId:"",
      HRIS:"",
      sunitid:"",
      UnionCode:"",
      fpfacilityId:"",
      incharge:false,
      bShow:false,
      ExistingbShow:"",
      UnionName:"",
    });
  };

  const validateForm = (formData) => {
    //let validateFields = ["FGroupCode","FacilityName","StaffName","PhoneNo","StaffDesignation","dateofbirth","dateofjoin","NationalId","HRIS"];
    let validateFields = ["FGroupCode","FacilityName","StaffName","PhoneNo","StaffDesignation"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      



       

      if((formData['FGroupCode'] == 'F') || (formData['FGroupCode'] == 'S') || (formData['FGroupCode'] == 'W') || (formData['FGroupCode'] == 'X')){
          if (formData['HRIS'] == ''){
            errorData['HRIS'] = "field is Required !";
            isValid = false;
          }
    
      } 

      if((formData['FGroupCode'] == 'F')){
        if (formData['sunitid'] == ''){
          errorData['sunitid'] = "field is Required !";
          isValid = false;
        }
      } 

      if((formData['FGroupCode'] == 'F') || (formData['FGroupCode'] == 'S') || (formData['FGroupCode'] == 'W')){
        if (formData['UnionCode'] == ''){
          errorData['UnionCode'] = "field is Required !";
          isValid = false;
      }
      } 

      if((formData['FGroupCode'] == 'W') || (formData['FGroupCode'] == 'N') ){
        if (formData['fpfacilityId'] == ''){
          errorData['fpfacilityId'] = "field is Required !";
          isValid = false;
        }
      } 
    
      if((formData['FGroupCode'] == 'F') || (formData['FGroupCode'] == 'W') || (formData['FGroupCode'] == 'X') || (formData['FGroupCode'] == 'S')){
        if ((formData['NationalId'] == '') || (formData['NationalId'] == 'null') || (formData['NationalId'] == null)){
          errorData['NationalId'] = "field is Required !";
          isValid = false;
        }
      } 

      
        if((formData['FGroupCode'] == 'F') || (formData['FGroupCode'] == 'O') || (formData['FGroupCode'] == 'S') || (formData['FGroupCode'] == 'W') || (formData['FGroupCode'] == 'X')){
              
          
              if ((formData['dateofbirth'] == '') || (formData['dateofbirth'] == 'null') || (formData['dateofbirth'] == null)){
                errorData['dateofbirth'] = "field is Required !";
                isValid = false;
              }

              if ((formData['dateofjoin'] == '') || (formData['dateofjoin'] == 'null') || (formData['dateofjoin'] == null)){
                errorData['dateofjoin'] = "field is Required !";
                isValid = false;
              }


            
            if((formData['dateofbirth'] !='') || (formData['dateofbirth'] != 'null') || (formData['dateofbirth'] != null)){
                let todayDate = moment().format("YYYY-MM-DD");
                let dateofbirthCheck = moment(formData['dateofbirth']).format("YYYY-MM-DD");
                let dateofjoinCheck = moment(formData['dateofjoin']).format("YYYY-MM-DD");

                if(dateofbirthCheck > todayDate){
                  errorData['dateofbirth'] = (DispensingLanguage[lan][menukey]["Birth Date can't be greater than today."]);
                  isValid = false;
                } 

                if(dateofjoinCheck > todayDate){
                  errorData['dateofjoin'] = (DispensingLanguage[lan][menukey]["Joining Date can't be greater than today."]);
                  isValid = false;
                } 
              

                if(dateofbirthCheck >= dateofjoinCheck){
                  errorData['dateofjoin'] = (DispensingLanguage[lan][menukey]["Joining Date can't be greater than Birth Date."]);
                  isValid = false;
                }
    
                

            } 

         
          
          

        } 




      if(formData['PhoneNo']!==''){
        if(formData['PhoneNo'].indexOf('0')!==0){
          errorData['PhoneNo'] = (DispensingLanguage[lan][menukey]["Mobile number is not valid !"]);//DispensingLanguage[lan][menukey]["field is Required !"];
          isValid = false;
        } 
        if(formData['PhoneNo'].indexOf('1')!==1){
          errorData['PhoneNo'] = (DispensingLanguage[lan][menukey]["Mobile number is not valid !"]);//DispensingLanguage[lan][menukey]["field is Required !"];
          isValid = false;
        }
  
        if(formData['PhoneNo'].length < 11){
          errorData['PhoneNo'] = (DispensingLanguage[lan][menukey]["Mobile number is not valid !"]);//DispensingLanguage[lan][menukey]["field is Required !"];
          isValid = false;
        }
      }

      if((formData['NationalId']!=='') || (formData['NationalId'] !=='NULL') || (formData['NationalId'] !== null ) ){
        
        if (!!formData['NationalId']) {
          if((formData['NationalId'].length == '10') || (formData['NationalId'].length == '13') || (formData['NationalId'].length == '17')){
        
          }else{
            errorData['NationalId'] = (DispensingLanguage[lan][menukey]["National ID is not valid !"]);//DispensingLanguage[lan][menukey]["field is Required !"];
            isValid = false;
          }
        } else {
          errorData['NationalId'] = "field is Required !";
          isValid = false;
        }
        
       
        
      }



      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleUpdate = async (e) => {
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat);
    }
  };


  const handleRDateChange = (date, field) => {
  
    setFormData({ ...formData, [field]: date });
    setErrorObject({ ...errorObject, [field]: null });
    api.dirtyModeEnable();
  };


  return (
    <>
      <SDPListFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleUpdate={handleUpdate}
        selectedDate={selectedDate} 
        handleRDateChange={handleRDateChange}
        bStockUpdate={props.permissionType}
        {...props}
      />
    </>
  );
};

export default EditSDPList;
