import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import * as api from "../../../actions/api";
import OthersFacilityListFormData from "./OthersFacilityListFormData"

const EditothersFacilityList = (props) => {
    //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "others-facility-list";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo==0?'': UserInfo[0].id;

  const [formData, setFormData] = useState({
    FacilityName: "",
    DistCode: "",
    PhoneNo: "",
    FacilityCode: "",
    bShow: false,
  });
  
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

   const { data } = useQuery(
    ["othersFacilityList", id],
    () =>
      api.geOthersFacilityLists(id).then((res) => {
        setFormData(res.data);
        return res.data;
        api.dirtyModeDisable();
      }),
    {
      enabled: Boolean(id),
    }
  ); 

  const { isLoading, mutate } = useMutation(api.updateOthersFacilityList, {
    onSuccess: (data) => {
      if (data.status == 200) {


        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });

        queryClient.getQueriesData("othersFacilityList");// include the page name
        props.history.push("/others-facility-list");//page url
      }else{
  
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
       
      }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };

    //====== Start Choosen Dropdown===========
    const handleChangeChoosenProps = (name, value) => {
      let data = { ...formData };
      data[name] = value;   
      setFormData(data); 
      setErrorObject({ ...errorObject, [name]: "" });
      api.dirtyModeEnable();
    }
    //====== End Choosen Dropdown===========

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
    api.dirtyModeEnable();
  };

  const handleReset = () => {
    setFormData({
      FacilityName: "",
      DistCode:"",
      PhoneNo:"",
      FacilityCode:"",
      bShow: false,
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["FacilityName", "DistCode", "FacilityCode"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = (DispensingLanguage[lan][menukey]['field is Required !']);
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleUpdate = async (e) => {
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat);
     
    }
  };

  return (
    <>
      <OthersFacilityListFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleUpdate={handleUpdate}
        bStockUpdate={props.permissionType}
        {...props}
      />
    </>
  );
};

export default EditothersFacilityList;
