import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  FormControlLabel,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  MenuItem,
  Card,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import CloseIcon from "@material-ui/icons/Close";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import moment from "moment";

//get DispensingLanguage
const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "issue-voucher";
const UseFor = localStorage.getItem("UseFor");

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


const PickInvoicePrintModal = ({ handleClose, openIssueInvoice, addIssueInvoice, OrderingFrom, TransactionId, ...props }) => {
  const OrderTo = JSON.parse(localStorage.getItem("OrderTo"));
  const classes = useStyles();
  const [toggleButtonValue, setToggleButton] = React.useState("1");
  const [currFacilityId, setCurrFacilityId] = useState("");
  //const [currSelectedInvoiceType, setcurrSelectedInvoiceType] = useState(1);
  const [currIndentNo, setCurrIndentNo] = useState("");
  const [currToInvoiceNo, setToInvoiceNo] = useState("");
  const [currFromInvoiceNo, setFromInvoiceNo] = useState("");
  const FacilityId = localStorage.getItem("FacilityId");

  const handleFacilityChange = (event) => {
    setCurrFacilityId(event.target.value);
  };
  const handleIndentNoChange = (event) => {
    setCurrIndentNo(event.target.value);
  };
  
  const FromInvoiceNoChange = (event) => {

    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
        setFromInvoiceNo(event.target.value);
    }
    
  };

  const ToInvoiceNoChange = (event) => {
   
    const re = /^[0-9\b]+$/;
    if (event.target.value === '' || re.test(event.target.value)) {
        setToInvoiceNo(event.target.value);
    }
  };


  let tblOrderRef = null;

  const [dataItems, setDataItems] = useState([]);
  const StoreId = localStorage.getItem("StoreId");
  


  const { mutate } = useMutation(api.insertUpdateIssueInvoicePick, {
    onSuccess: (data) => {

      if(data.success==0) {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        
      }else{
        addIssueInvoice(data);
        setCurrFacilityId("");
        setCurrIndentNo("");
      }

    }
  })

  
  /* =====Start of Print Excel Many Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const SaveSelectedOrder = () => {

    //console.log("currFromInvoiceNo 1111111111 ",currFromInvoiceNo);
    //console.log("currToInvoiceNo 1111111111 ",currToInvoiceNo);

    if(currFromInvoiceNo =="" || currToInvoiceNo =="") {
      props.openNoticeModal({
        isOpen: true,
        msg: DispensingLanguage[lan][menukey]["Invoice No can not be blank"],
        msgtype: 0,
      });
    
    }else if(currFromInvoiceNo > currToInvoiceNo) {
        props.openNoticeModal({
          isOpen: true,
          msg: DispensingLanguage[lan][menukey]["From cannot be greater than the To invoice number"],
          msgtype: 0,
        });
    }else{
          let finalUrl = EXCEL_EXPORT_URL + "report/issue_voucher_pdf.php";

          window.open(
            finalUrl +
              "?menukey=" + menukey +
              "&lan=" +localStorage.getItem("LangCode") +
              "&FromInvoiceNo=" +currFromInvoiceNo +
              "&ToInvoiceNo=" +currToInvoiceNo +
              "&FacilityId=" +FacilityId + 
              "&UseFor=" +UseFor + 
              "&TimeStamp=" +Date.now()
          );

        handleClose('PickInvoicePrintModal');
    }



    /* if ((currFacilityId != "") && (currIndentNo != ""))
      mutate({
        FacilityId: FacilityId,
        TransferFacilityCode: currFacilityId,
        InvoiceNo: currIndentNo,
        TransactionTypeId: 2,
        currSelectedInvoiceType: toggleButtonValue,
        language: lan
      }); */

  };


  
  useEffect(() => {
    if (tblOrderRef == null) return;


  }, []);

  return (
    <div>

      <BootstrapDialog
        onClose={() => handleClose('PickInvoicePrintModal')}
        aria-labelledby="customized-dialog-title"
        open={openIssueInvoice}
        fullWidth={true}
        maxWidth="xs"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('PickInvoicePrintModal')}
        >

          {DispensingLanguage[lan][menukey]['Print Invoice']}

        </BootstrapDialogTitle>
        <DialogContent dividers>
         
          <Grid container spacing={3}>

            {/* Start warehouse dropdown list */}
            <Grid item xs={2} sm={2}></Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                //disabled={props.bStockUpdate}
                /*  error={props.errorObject.SupplierInvNo}
                helperText={props.errorObject.SupplierInvNo} */
                id="FromInvoiceNo"
                name="FromInvoiceNo"
                label={DispensingLanguage[lan][menukey]["From"] + "*"}
                value={currFromInvoiceNo}
                fullWidth
                autoComplete="family-name"
                onChange={FromInvoiceNoChange}
              />
            </Grid>
            <Grid item xs={2} sm={2}></Grid>
            {/* End warehouse dropdown list */}

            <Grid item xs={2} sm={2}></Grid>
            <Grid item xs={8} sm={8}>
              <TextField
                //disabled={props.bStockUpdate}
                /*  error={props.errorObject.SupplierInvNo}
                helperText={props.errorObject.SupplierInvNo} */
                id="ToInvoiceNo"
                name="ToInvoiceNo"
                label={DispensingLanguage[lan][menukey]["To"] + "*"}
                value={currToInvoiceNo}
                fullWidth
                autoComplete="family-name"
                onChange={ToInvoiceNoChange}
              />
            </Grid>
            <Grid item xs={2} sm={2}></Grid>

            

          </Grid>

        </DialogContent>
        <DialogActions>
          <Grid item xs={12} className="mt-2 text-center">
            <Button
              className="mr-2"
              variant="contained"
              color="primary"
              onClick={SaveSelectedOrder}>
              {DispensingLanguage[lan][menukey]['Print']}
            </Button>
            <Button autoFocus
              className="mr-2"
              variant="contained"

              onClick={() => handleClose('PickInvoicePrintModal')}>
              {DispensingLanguage[lan][menukey]['Close']}
            </Button>
          </Grid>
        </DialogActions>
      </BootstrapDialog>

    </div>
  );
};

export default PickInvoicePrintModal;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
