import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import swal from "sweetalert";
import {
  Grid,
  Typography,
  FormControlLabel,
  TextField,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel,
  MenuItem,
  FormGroup,
  Card,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import { DeleteOutline, Edit, ViewList, FileCopy } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import SwipeableViews from "react-swipeable-views";
import { useTranslation } from "react-i18next";
import Constants from "../../../services/Constants";
// import ProductsModal from "../../../services/ProductsModal";
// import Form7InfoModal from "../../../services/Form7InfoModal";
import Form7InvStatusValidation from "../../../services/Form7InvStatusValidation";

import * as api from "../../../actions/api";
// import SourceModal from "../../../services/SourceModal";
import { cellFocusEditor, dateEditor } from "../../../services/Common";
// import OrderModal from "./OrderModal";

import MomentUtils from "@date-io/moment";
import moment from "moment";
let timeStamp = "";

// import Modal from '@mui/material/Modal';
// import { Modal } from '@mui/material';

//errorObject, addProductForm, formData, handleChange, handleCheck, handleReset, handleSubmit, handleUpdate, ManyTableData,ManyJsonSave,handleRDateChange,selectedDate,handleAdd,handleRefech,
const LMISForm2FormData = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "lmisform2";
  const FacilityId = localStorage.getItem("FacilityId");

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  let ReceiveDetailsRef = null;

  const [newBatchRowAdded, setNewBatchRowAdded] = useState(false);

  // console.log("Set r batch");

  const [open, setOpen] = useState(false);
  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);

  const MonthList = JSON.parse(localStorage.getItem("MonthList"));
  const UnionsList = JSON.parse(localStorage.getItem("UnionsList"));
  const SoReasonList = JSON.parse(localStorage.getItem("SoReasonList"));
  const ReasonNameList = JSON.parse(localStorage.getItem("ReasonNameList"));

  const [SupplyPlanSettingItems, setSupplyPlanSettingItems] = useState({});
  const [toggleButtonValue, setToggleButton] = React.useState("");
  // const [toggleButtonValue, setToggleButton] = React.useState("CON008");


  
  
//====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "UnionCode": {"id": "","name": ""},
  "ReportedMonthId": {"id": "","name": ""}
});


useEffect(() => {
  
  let chosenValuesData = { ...chosenValues};
   chosenValuesData['UnionCode'] = UnionsList[UnionsList.findIndex(Unions_List => Unions_List.id == props.formData.UnionCode)];
   chosenValuesData['ReportedMonthId'] = MonthList[MonthList.findIndex(Month_List => Month_List.id == props.formData.ReportedMonthId)];
   setChosenValues(chosenValuesData); 

  }, [props.formData.UnionCode, props.formData.ReportedMonthId]);

const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  setChosenValues(chosenValuesData); 
  props.handleChangeChoosenProps(name, value);
};

//====== End Choosen Dropdown===========



  /* =====Start of Print Excel Many Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    let finalUrl = EXCEL_EXPORT_URL + "report/supplyplan_print_excel.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TransactionId=" +
        props.formData.TransactionNo +
        "&currItemCode=" +
        props.formData.currItemCode +
        "&FacilityId=" +
        FacilityId +
        "&TimeStamp=" +
        Date.now()
    );
  };

  /* =====End of Excel Export Code==== */

  let id = props.addProductForm ? 0 : props.formData.TransactionNo;
  const { data: SettingItems, refetch: getSettingItems } = useQuery(
    ["lmisform2settingitems", id],
    () =>
      api.getLMISForm2SettingItems(id).then((res) => {
        console.log("SettingItems: ", res.data);

        if (Object.keys(res.data).length > 0) {
          setToggleButton(res.data[0].ProdCode);
        }

        // console.log("SupplyPlanSettingItems:",SupplyPlanSettingItems);
        // console.log("SupplyPlanSettingItems:",Object.keys(SupplyPlanSettingItems).length);
        setSupplyPlanSettingItems(res.data);
        // console.log("res.data[0]",res.data[0].ProdCode);

        return res.data;
      }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(props.formData.TransactionNo),
    }
  );

  const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;

      default:
        break;
    }
  };

  const fncgenerateForm7Items = () => {
    // props.generateForm7Items();
    generateForm7Items();
  };

  const generateForm7Items = async (e) => {
    let manydata = ReceiveDetailsRef.table.getData();
    let validForm = props.checkValidateForm(manydata, "edit");
    if (validForm) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey][
            "Do you really want to fill the Form 2 items?"
          ]
        ),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true, */
      }).then((willAction) => {
        if (willAction) {
          props.setPreLoadingState(true);
          //let fDtat={...formData,'ManyJsonSave':ManyTableData,"bStockUpdated":1,'deletedDataSet':deletedDataSet};
          let fDtat = props.formDataForGenerate(1);
          mutateUpdateMain(fDtat);
        } else {
          // setbStockUpdate(0);
          // let route = `${TransactionId}`;
          // props.history.push(route);
        }
      });
      // End Post Alert
    }
  };

  // const generateIssueVoucher = () => {
  //   props.generateIssueVoucher();
  // };

  const ReceiveDetailsColumns = [
    { title: "TransactionItemId", field: "TransactionItemId", visible: false },
    { title: "id", field: "id", visible: false },
    { title: "isedit", field: "isedit", width: 80, visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      field: "ItemCode",
      width: 40,
      formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
      // formatter: function (cell, formatterParams) {
      //   let _ItemCode = cell.getValue();
      //   if(_ItemCode == "CON004" || _ItemCode == "CON005"){
      //     return "";
      //   }else{
      //     serialNo++;
      //     return serialNo;
      //   }
      // },
    },
    {
      title: DispensingLanguage[lan][menukey]["Name of FWA"],
      field: "FacilityName",
      minWidth: 200,
      headerFilter: true,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Opening Balance"],
      field: "OpeningBalance",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      bottomCalc: "sum",
      headerSort: false,
      // bottomCalcFormatter: "money",
      bottomCalcFormatter: "money",
      bottomCalcFormatterParams: {
        thousand: ",",
        precision: false,
      },
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : Number(value).toLocaleString();
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Received"],
      field: "Received",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      bottomCalc: "sum",
      headerSort: false,
      bottomCalcFormatter: "money",
      bottomCalcFormatterParams: {
        thousand: ",",
        precision: false,
      },
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : Number(value).toLocaleString();
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Total"],
      field: "Total",
      width: 75,
      hozAlign: "right",
      headerHozAlign: "right",
      bottomCalc: "sum",
      headerSort: false,
      bottomCalcFormatter: "money",
      bottomCalcFormatterParams: {
        thousand: ",",
        precision: false,
      },
      formatter: function (cell, formatterParams) {
        // let value = cell.getValue();
        // return value == 0 ? "" : value;

        const currow = cell.getRow();
        const rowdata = currow.getData();
        const calculateValue = rowdata.OpeningBalance + rowdata.Received;
        return calculateValue == 0
          ? ""
          : Number(calculateValue).toLocaleString();
      },
    },

    {
      title: DispensingLanguage[lan][menukey]["Adjust Plus"],
      field: "AdjustPlus",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      bottomCalc: "sum",
      headerSort: false,
      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      //editor: cellFocusEditor,
      bottomCalcFormatter: "money",
      bottomCalcFormatterParams: {
        thousand: ",",
        precision: false,
      },
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },

      cellEdited: function (cell) {
        let currVal = cell.getValue();
        let currow = cell.getRow();
        let rowdata = currow.getData();
        // const calculateValue = rowdata.OpeningBalance + rowdata.Received + rowdata.AdjustPlus - currVal;
        let OpeningBalance = rowdata.OpeningBalance;
        let Received = rowdata.Received;
        let AdjustMinus = rowdata.AdjustMinus;
        let Distribution = rowdata.Distribution;

        if (currVal === "" || currVal === null) {
          currVal = 0;
        }

        if (OpeningBalance === "" || OpeningBalance === null) {
          OpeningBalance = 0;
        }

        if (Received === "" || Received === null) {
          Received = 0;
        }

        if (AdjustMinus === "" || AdjustMinus === null) {
          AdjustMinus = 0;
        }

        if (Distribution === "" || Distribution === null) {
          Distribution = 0;
        }
        // let calculateValue = (OpeningBalance === ""? 0 : parseInt(OpeningBalance))+
        //   (Received === ""? 0 : parseInt(Received)) +
        //   (AdjustPlus === ""? 0 : parseInt(AdjustPlus)) -
        //   (currVal === ""? 0 : parseInt(currVal));

        console.log("OpeningBalance: ", OpeningBalance);
        console.log("Received: ", Received);
        console.log("currVal: ", currVal);
        console.log("AdjustMinus: ", AdjustMinus);
        console.log("Distribution: ", Distribution);
        let calculateValue =
          parseInt(OpeningBalance) +
          parseInt(Received) +
          parseInt(currVal) -
          parseInt(AdjustMinus);

        console.log("calculateValue: ", calculateValue);

        let calPushCell = currow.getCell("GrandTotal");
        if (calPushCell) {
          console.log("calPushCell: ", calPushCell);
          calPushCell.setValue(calculateValue);
        }

        let calculateValue11 =
          parseInt(OpeningBalance) +
          parseInt(Received) +
          parseInt(currVal) -
          parseInt(AdjustMinus) -
          parseInt(Distribution);

        // calculateValue11=50;

        // console.log("calculateValue1: ", calculateValue11);
        let calPushCell11 = currow.getCell("ClosingBalance");
        if (calPushCell11) {
          calPushCell11.setValue(calculateValue11);
        }

        if (calculateValue11 < 0) {
          props.openNoticeModal({
            isOpen: true,
            msg: DispensingLanguage[lan][menukey][
              "Closing balance can not be less then zero"
            ],
            msgtype: 0,
          });
        }

        //Set flag this row edited
        let calPushCell12 = currow.getCell("isedit");
        calPushCell12.setValue(1);
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Adjust Minus"],
      field: "AdjustMinus",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      bottomCalc: "sum",
      headerSort: false,
      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      //editor: cellFocusEditor,
      bottomCalcFormatter: "money",
      bottomCalcFormatterParams: {
        thousand: ",",
        precision: false,
      },
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },

      cellEdited: function (cell) {
        let currVal = cell.getValue();
        let currow = cell.getRow();
        let rowdata = currow.getData();
        // const calculateValue = rowdata.OpeningBalance + rowdata.Received + rowdata.AdjustPlus - currVal;
        let OpeningBalance = rowdata.OpeningBalance;
        let Received = rowdata.Received;
        let AdjustPlus = rowdata.AdjustPlus;
        let Distribution = rowdata.Distribution;

        if (currVal === "" || currVal === null) {
          currVal = 0;
        }

        if (OpeningBalance === "" || OpeningBalance === null) {
          OpeningBalance = 0;
        }

        if (Received === "" || Received === null) {
          Received = 0;
        }

        if (AdjustPlus === "" || AdjustPlus === null) {
          AdjustPlus = 0;
        }

        if (Distribution === "" || Distribution === null) {
          Distribution = 0;
        }

        console.log("OpeningBalance: ", OpeningBalance);
        console.log("Received: ", Received);
        console.log("AdjustPlus: ", AdjustPlus);
        console.log("currVal: ", currVal);
        console.log("Distribution: ", Distribution);

        let calculateValue =
          parseInt(OpeningBalance) +
          parseInt(Received) +
          parseInt(AdjustPlus) -
          parseInt(currVal);

        const calPushCell = currow.getCell("GrandTotal");
        if (calPushCell) {
          console.log("calPushCell 1: ", calPushCell);
          calPushCell.setValue(calculateValue);
        }

        let calculateValue1 =
          parseInt(OpeningBalance) +
          parseInt(Received) +
          parseInt(AdjustPlus) -
          parseInt(currVal) -
          parseInt(Distribution);

        const calPushCell1 = currow.getCell("ClosingBalance");
        if (calPushCell1) {
          calPushCell1.setValue(calculateValue1);
        }

        if (calculateValue1 < 0) {
          props.openNoticeModal({
            isOpen: true,
            msg: DispensingLanguage[lan][menukey][
              "Closing balance can not be less then zero"
            ],
            msgtype: 0,
          });
        }

        //Set flag this row edited
        let calPushCell12 = currow.getCell("isedit");
        calPushCell12.setValue(1);
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Grand Total"],
      field: "GrandTotal",
      width: 70,
      hozAlign: "right",
      headerHozAlign: "right",
      bottomCalc: "sum",
      headerSort: false,
      formatter: "money",
      formatterParams: { thousand: ",", precision: false },
      bottomCalcFormatter: "money",
      bottomCalcFormatterParams: {
        thousand: ",",
        precision: false,
      },
      // formatter: function (cell, formatterParams) {
      //   const currow = cell.getRow();
      //   const rowdata = currow.getData();
      //   // const calculateValue = rowdata.OpeningBalance + rowdata.Received + rowdata.AdjustPlus - rowdata.AdjustMinus;
      //   const calculateValue = rowdata.OpeningBalance + rowdata.Received + rowdata.AdjustPlus - rowdata.AdjustMinus;
      //  //let calculateValue=rowdata.AdjustPlus;
      //   return calculateValue == 0 ? "" : calculateValue;
      // },
    },

    {
      title: DispensingLanguage[lan][menukey]["Distribution"],
      field: "Distribution",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      bottomCalc: "sum",
      headerSort: false,
      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      //editor: cellFocusEditor,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },

      cellEdited: function (cell) {
        let currVal = cell.getValue();
        let currow = cell.getRow();
        let rowdata = currow.getData();
        // const calculateValue = rowdata.OpeningBalance + rowdata.Received + rowdata.AdjustPlus - currVal;
        let OpeningBalance = rowdata.OpeningBalance;
        let Received = rowdata.Received;
        let AdjustPlus = rowdata.AdjustPlus;
        let AdjustMinus = rowdata.AdjustMinus;

        if (currVal === "" || currVal === null) {
          currVal = 0;
        }

        if (OpeningBalance === "" || OpeningBalance === null) {
          OpeningBalance = 0;
        }

        if (Received === "" || Received === null) {
          Received = 0;
        }

        if (AdjustPlus === "" || AdjustPlus === null) {
          AdjustPlus = 0;
        }

        if (AdjustMinus === "" || AdjustMinus == null) {
          AdjustMinus = 0;
        }

        console.log("OpeningBalance: ", OpeningBalance);
        console.log("Received: ", Received);
        console.log("AdjustPlus: ", AdjustPlus);
        console.log("currVal: ", currVal);

        let calculateValue1 =
          parseInt(OpeningBalance) +
          parseInt(Received) +
          parseInt(AdjustPlus) -
          parseInt(AdjustMinus) -
          parseInt(currVal);

        const calPushCell1 = currow.getCell("ClosingBalance");
        if (calPushCell1) {
          calPushCell1.setValue(calculateValue1);
          console.log("calculateValue1: ", calculateValue1);
        }

        if (calculateValue1 < 0) {
          props.openNoticeModal({
            isOpen: true,
            msg: DispensingLanguage[lan][menukey][
              "Closing balance can not be less then zero"
            ],
            msgtype: 0,
          });
        }

        //Set flag this row edited
        let calPushCell12 = currow.getCell("isedit");
        calPushCell12.setValue(1);
      },
    },

    {
      title: DispensingLanguage[lan][menukey]["Closing Blance"],
      field: "ClosingBalance",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      bottomCalc: "sum",
      headerSort: false,
      bottomCalcFormatter: "money",
      bottomCalcFormatterParams: {
        thousand: ",",
        precision: false,
      },
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        if (value <= 0) {
          cell.getElement().style.backgroundColor = "#FF0000";
        } else {
          cell.getElement().style.backgroundColor = "transparent";
        }
        return value == 0 ? "" : Number(value).toLocaleString();
      },
    },

    // {title:"Gender", field:"gender", editor:"select",
    // editorParams:{
    //   values:{
    //     "1: Supply not Received":"1: Supply not Received",
    //      "2: Inadequate Supply":"2: Inadequate Supply",
    //      "3: Sudden Increase of Demand":"3: Sudden Increase of Demand",
    //      "4: Others":"4: Others"}
    //     }
    // },

    {
      title: DispensingLanguage[lan][menukey]["SO Reason Id"],
      field: "SoReason",
      // field: "SOReasonID",
      width: 100,
      hozAlign: "left",
      headerHozAlign: "left",
      //bottomCalc: "sum",
      headerSort: false,
      //editor:"select",
      editor: props.bStockUpdate == 0 ? "select" : "",
      editorParams: {
        /* values:{
          "1: Supply not Received":"1: Supply not Received",
          "2: Inadequate Supply":"2: Inadequate Supply", 
          "3: Sudden Increase of Demand":"3: Sudden Increase of Demand", 
          "4: Others":"4: Others"}
          }, */

        values: SoReasonList,
      },
      cssClass: "tabluator-column-editable",

      cellEdited: function (cell) {
        let currow = cell.getRow();

        //Set flag this row edited
        let calPushCell12 = currow.getCell("isedit");
        calPushCell12.setValue(1);
        api.dirtyModeEnable();
      },
    },
    // {
    //   title: DispensingLanguage[lan][menukey]["SO Reason Id"],
    //   field: "SOReasonID",
    //   width: 100,
    //   hozAlign: "right",
    //   headerHozAlign: "right",
    //   bottomCalc: "sum",
    //   headerSort: false,
    //   cssClass: "tabluator-column-editable",
    //   // editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
    //   editor: cellFocusEditor,
    //   // formatter: function (cell, formatterParams) {
    //   //   let value = cell.getValue();
    //   //   return value == 0 ? "" : value;
    //   // },
    // },
    {
      title: DispensingLanguage[lan][menukey]["SO Days"],
      field: "SODays",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      //bottomCalc: "sum",
      headerSort: false,
      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      // editor: cellFocusEditor,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },
      cellEdited: function (cell) {
        let currow = cell.getRow();

        //Set flag this row edited
        let calPushCell12 = currow.getCell("isedit");
        calPushCell12.setValue(1);
      },
    },

    {
      title: DispensingLanguage[lan][menukey]["Eligible"],
      field: "bEntitled",
      headerSort: false,
      hozAlign: "center",
      headerHozAlign: "center",
      width: 75,
      // editor: true,
      editor: props.bStockUpdate == 0 ? true : false,
      formatter: "tickCross",
      cellEdited: function (cell) {
        let currow = cell.getRow();

        //Set flag this row edited
        let calPushCell12 = currow.getCell("isedit");
        calPushCell12.setValue(1);
        
        api.dirtyModeEnable();
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Non-eligibility reason"],
      field: "ReasonName",
      // field: "ReasonId",
      width: 100,
      hozAlign: "left",
      headerHozAlign: "left",
      // bottomCalc: "sum",
      headerSort: false,
      cssClass: "tabluator-column-editable",
      // editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      // editor: cellFocusEditor,
      // formatter: function (cell, formatterParams) {
      //   let value = cell.getValue();
      //   return value == 0 ? "" : value;
      // },
      editor: props.bStockUpdate == 0 ? "select" : "",
      // editor:"select",
      editorParams: {
        /* values:{
          "NGO has no service provision":"NGO has no service provision",
          "NGO affiliation expired":"NGO affiliation expired", 
          "NGO has no trained person":"NGO has no trained person", 
          " SDP not capable":" SDP not capable", 
          "SDP not trained":"SDP not trained", 
          "Absence of Female service provider":"Absence of Female service provider", 
          "Administrative reason":"Administrative reason"}
          */
        values: ReasonNameList,
      },
      cellEdited: function (cell) {
        let currow = cell.getRow();

        //Set flag this row edited
        let calPushCell12 = currow.getCell("isedit");
        calPushCell12.setValue(1);
        
         api.dirtyModeEnable();
      },
    },

    // {
    //   title: "Actual Supply Quantity Old Value",
    //   field: "ActualSupply_Old",
    //   width: 125,
    //   visible: false,
    // },
  ];

  const handleChangeToggle = (event, tmpToggleButtonValue) => {
    if (tmpToggleButtonValue) {
      // console.log("tmpToggleButtonValue: ", tmpToggleButtonValue);

      let isValid = checkFormValidation();
      //when valid then return 0 otherwise greater than 0
      if (isValid > 0) {
        return;
      }

      var tableData = ReceiveDetailsRef.table.getData();
      // console.log("handleChangeToggle props.formData: ", props.formData);
      // console.log("handleChangeToggle tableData: ", tableData);
      let isedit = 0;
      tableData.forEach((row, i) => {
        if (row.isedit === 1) {
          isedit = 1;
        }
      });

      console.log("isedit: ", isedit);
      console.log("props.bStockUpdate: ", props.bStockUpdate);

      //isedit = 0 when not changed any row data, isedit = 1 when changed any row data
      if (isedit === 0 || props.bStockUpdate) {
        props.reFetchItems(tmpToggleButtonValue);
        setToggleButton(tmpToggleButtonValue);
      } else {
        ///////////When TAB change in fron-end then call this function ///////////////////////////
        let fDtat = {
          ...props.formData,
          ManyJsonSave: tableData,
          tmpToggleButtonValue: tmpToggleButtonValue,
        };
        mutateUpdate(fDtat);
      }
    }
  };

  ///////////When TAB change in fron-end then call this function ///////////////////////////
  const { isLoading: isLoadingUpdateMany, mutate: mutateUpdate } = useMutation(
    api.LMISForm2UpdateOnlyMany,
    {
      onSuccess: (data) => {
        console.log("mutateUpdate data: ", data);

        if (data.status == 200) {
          props.reFetchItems(data.ItemCode);
          setToggleButton(data.ItemCode);
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      },
    }
  );

  // const getItemStock = (currStockJson) => {
  //   let stockQty = 0;
  //   if (currStockJson) {
  //     let currStockJsonList = JSON.parse(currStockJson);
  //     // console.log("getItemStock toggleButtonValue: ", toggleButtonValue);

  //     currStockJsonList.forEach((row, i) => {
  //       if (row.ItemCode == toggleButtonValue) {
  //         stockQty = row.Qty;
  //       }
  //     });
  //   }

  //   return stockQty;
  // };

  function checkFormValidation() {
    var tableData = ReceiveDetailsRef.table.getData();
    // console.log("tableData: ", tableData);

    let rowValidateFlag = 0;
    let errorRow = {};
    tableData.forEach((row, i) => {
      let SODays = row.SODays;
      let SoReason = row.SoReason;
      if (SODays > 0 && SoReason == null && rowValidateFlag == 0) {
        rowValidateFlag = 1;
        errorRow = row;
      }

      let bEntitled = row.bEntitled;
      let ReasonName = row.ReasonName;
      if (bEntitled == 0 && ReasonName == null && rowValidateFlag == 0) {
        rowValidateFlag = 2;
        errorRow = row;
      }

      let ClosingBalance = row.ClosingBalance;
      if (ClosingBalance < 0 && rowValidateFlag == 0) {
        rowValidateFlag = 3;
        errorRow = row;
      }
    });

    // console.log("rowValidateFlag: ", rowValidateFlag);
    // if(rowValidateFlag==0){
    //   props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData());
    // }else{

    //console.log("Error:",errorRow);
    let errorText = "";
    if (rowValidateFlag > 0) {
      if (rowValidateFlag == 3) {
        errorText =
          DispensingLanguage[lan][menukey]["closing balance is negative"];
      } else if (rowValidateFlag == 1) {
        errorText = DispensingLanguage[lan][menukey]["SO reason is missing"];
      } else if (rowValidateFlag == 2) {
        errorText =
          DispensingLanguage[lan][menukey]["non-eligibility reason is missing"];
      }

      props.openNoticeModal({
        isOpen: true,
        msg: errorRow.FacilityName + " " + errorText,
        msgtype: 0,
      });
    }

    // }
    return rowValidateFlag;
  }

  const onSaveUpdateBtnClick = () => {
    let isValid = checkFormValidation();
    //when valid then return 0 otherwise greater than 0
    if (isValid === 0) {
      // props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData());
      let manydata = ReceiveDetailsRef.table.getData();
      setUpdatedDetailsGridDataHandler(manydata);
    }
  };

  const setUpdatedDetailsGridDataHandler = (rows) => {
    let validForm = props.checkValidateForm(rows, "edit");
    // console.log('isval: ', validForm);
    if (validForm) {
      // console.log('props.addProductForm: ', props.addProductForm);
      if (props.addProductForm) {
        //when save
        mutateSave(validForm);
      } else {
        //when update
        mutateUpdateMain(validForm);
      }
    }
  };

  const { isLoading: isLoadingSave, mutate: mutateSave } = useMutation(
    api.saveLMISForm2,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });

          let route = `edit/${data.TransactionId}`;
          props.history.push(route);
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      },
    }
  );

  const { isLoading: isLoadingUpdate, mutate: mutateUpdateMain } = useMutation(
    api.LMISForm2Update,
    {
      onSuccess: (data) => {
        // console.log('mutateUpdate data: ', data);
        if (data.status == 200) {
          props.stockPosted(data.bStockUpdated);

          if (data.bStockUpdated == 1) {
            let fDtat = props.formDataForGenerate(0);
            mutateGenerateForm7(fDtat);
            //  mutatePost(props.formData.TransactionId);
          } else {
            props.refetchInvoice(); //refetch();
            props.setPreLoadingState(false);
            props.openNoticeModal({
              isOpen: true,
              msg: data.message,
              msgtype: data.success,
            });
          }
        } else {
          props.setPreLoadingState(false);
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      },
    }
  );

  const { isLoadingF7: isLoadingGenerate, mutate: mutateGenerateForm7 } =
    useMutation(api.generateLMISForm2Items, {
      onSuccess: (data) => {
        console.log("mutateGenerateForm7 data: ", data);

        if (data.status == 200) {
          if (data.data.success == 1) {
            props.ReloadItemTable();

            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });
          } else {
            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });
          }
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }

        props.setPreLoadingState(false);
      },
    });

  const deleteForm7Items = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Do you really want to delete the selected Form 2?"
        ]
      ),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willDelete) => {
      if (willDelete) {
        deleteForm7ItemsMutate(props.formData.TransactionNo);
      }
    });
  };

  const { isLoading: isLoadingDeleteMany, mutate: deleteForm7ItemsMutate } =
    useMutation(api.deleteLMISForm2Items, {
      onSuccess: (data) => {
        if (data.status == 200) {
          props.ReloadItemTable();

          // queryClient.getQueriesData("lmisform2");

          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
            duration: 10000,
          });
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      },
    });

  function ActionButton(props) {
    const cellValue = props.cell._cell.value;
    // console.log('cellValue: ', cellValue);
    if (cellValue)
      return (
        <>
          <i class="fa fa-lock"></i>
        </>
      );
    else return <></>;
  }

  useEffect(() => {
    if (ReceiveDetailsRef == null) return;
    if (props.bFirst) {
      setManyTableData(props.ManyTableData);
    }

    setbStockUpdate(props.bStockUpdate);
  }, [props.ManyTableData, props.bStockUpdate]); //

  useEffect(() => {
    if (ReceiveDetailsRef.table != null && timeStamp != "") {
      // console.log("Many table", ReceiveDetailsRef.table);
      // console.log("Many table 12222222222");

      ReceiveDetailsRef.table.scrollToRow(timeStamp, "center", true);
      ReceiveDetailsRef.table.selectRow(timeStamp);
    }
  }, [newBatchRowAdded]);

  const handleReset = () => {
    props.handleReset();
    setManyTableData([]);
  };

  
  const backtolist=()=>{


    let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
    let idx = master_dirty_message_class.indexOf("dnone");
    if(idx === -1){
      // start Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          props.history.push("/lmisform2");
        }
      });
      // End Alert
    }else{
      props.history.push("/lmisform2");
    }

  }

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["LMIS Form 2 - Add/Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {props.addProductForm ? (
                  <Grid item xs={12} className=" text-center">
                    <div className="float-right sw_btn_control">
                      <Button
                        disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button>
                      <Button
                        disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onSaveUpdateBtnClick}
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        disabled={isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        // onClick={() => props.history.push("/lmisform2")}
                        onClick={() => backtolist()}

                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="mt-2 text-center">
                    <Button
                      disabled={
                        props.bStockUpdate ||
                        isLoadingUpdateMany ||
                        isLoadingDeleteMany ||
                        isLoadingUpdate ||
                        isLoadingGenerate
                      }
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    {/* <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onPostBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["post"]}
                    </Button> */}
                    <Button
                      disabled={
                        isLoadingUpdateMany ||
                        isLoadingDeleteMany ||
                        isLoadingUpdate ||
                        isLoadingGenerate
                      }
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      // onClick={() => props.history.push("/lmisform2")}
                      onClick={() => backtolist()}

                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>

                    {/* <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PrintMany()}
                    >
                      <i class="fas fa-print"></i>
                    </Button> */}

                    {/* <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelMany()}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button> */}
                  </Grid>
                )}
              </div>

              
              <div className="mr-4 float-right mt-15">
                <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
              </div>

            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            {/* <Card className="sw_card">
            <CardHeader title="Personal Information" />
            <CardContent> */}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={1} sm={1}>
                    <TextField
                      disabled={true}
                      error={props.errorObject.TransactionNo}
                      helperText={props.errorObject.TransactionNo}
                      required
                      id="TransactionNo"
                      name="TransactionNo"
                      label={DispensingLanguage[lan][menukey]["Id"]}
                      value={
                        props.formData.TransactionNo || props.generatedInvoiceNo
                      }
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  {/* ////////////////////////////////////////////////////////////////////////////////////////////// */}
                  {/* {props.formData.ExistingbShow || props.addProductForm ? ( */}
                    <Grid item xs={3} sm={3}>
                     {/* <FormControl className={classes.fullWidth}>
                        <InputLabel id="demo-simple-select-helper-label-receive-from">
                          {DispensingLanguage[lan][menukey]["Union Name"]} *
                        </InputLabel>
                        <Select
                          error={props.errorObject.UnionCode}
                          labelId="demo-simple-select-helper-label-receive-from"
                          id="UnionCode"
                          name="UnionCode"
                          value={props.formData.UnionCode}
                          disabled={
                            manyTableData.length > 0 ? true : props.bStockUpdate
                          }
                          fullWidth
                          onChange={(e) => props.handleChange(e)}
                         
                        >
                          {UnionsList.map((item, index) => {
                            return (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            );
                          })}
                        </Select>

                        <FormHelperText error={props.errorObject.UnionCode}>
                          {props.errorObject.UnionCode}
                        </FormHelperText>
                      </FormControl>  */}
                  
                         <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            hidden={!(props.formData.ExistingbShow || props.addProductForm)}
                            disabled={
                              manyTableData.length > 0 ? true : props.bStockUpdate
                            }
                            id="UnionCode"
                            options={UnionsList}
                            onChange={(event, valueobj) => handleChangeChoosen('UnionCode', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValues['UnionCode']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={props.formData.UnionCode}
                              label={DispensingLanguage[lan][menukey]["Union Name"]+ " *"}
                              variant="standard"
                              error={props.errorObject.UnionCode}
                              helperText={props.errorObject.UnionCode}
                              id="UnionCode"
                              name="UnionCode"
                              fullWidth
                            />
                            )}
                          />

                        <TextField
                          id="UnionName"
                          name="UnionName"
                          label={
                            DispensingLanguage[lan][menukey]["Union Name"] + "*"
                          }
                          value={props.formData.UnionName}
                          disabled={true}
                          hidden={(props.formData.ExistingbShow || props.addProductForm)}
                          fullWidth
                          autoComplete="family-name"
                        />


                        </FormControl> 


                      

                    </Grid>







                {/*   ) : ( */}
                    {/* <Grid item xs={3} sm={3}>
                      <TextField
                        id="UnionName"
                        name="UnionName"
                        label={
                          DispensingLanguage[lan][menukey]["Union Name"] + "*"
                        }
                        value={props.formData.UnionName}
                        disabled={true}
                        hidden={(props.formData.ExistingbShow || props.addProductForm)}
                        fullWidth
                        autoComplete="family-name"
                      />
                    </Grid> */}
                 {/*  )} */}

                  {/* ////////////////////////////////////////////////////////////////////////////////////////////// */}

                  <Grid item xs={1} sm={1}>
                    <TextField
                      disabled={true}
                      // error={props.errorObject.TotalWorkers}
                      // helperText={props.errorObject.TotalWorkers}
                      // required
                      id="TotalWorkers"
                      name="TotalWorkers"
                      label={DispensingLanguage[lan][menukey]["Total FWAs"]}
                      value={props.formData.TotalWorkers}
                      fullWidth
                      autoComplete="family-name"
                      //onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={2} sm={2}>
                    <TextField
                      disabled={true}
                      // error={props.errorObject.TotalWorkers}
                      // helperText={props.errorObject.TotalWorkers}
                      // required
                      id="ReportsSubmitted"
                      name="ReportsSubmitted"
                      label={
                        DispensingLanguage[lan][menukey]["Reports Submitted"]
                      }
                      value={props.formData.ReportsSubmitted}
                      fullWidth
                      autoComplete="family-name"

                      // onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>
                  {/* <Grid item xs={3} sm={3}>
                    {" "}
                  </Grid> */}

                  <Grid item xs={2} sm={2}>
                   {/*  <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label-receive-from">
                        {DispensingLanguage[lan][menukey]["Report Month"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.ReportedMonthId}
                        labelId="demo-simple-select-helper-label-receive-from"
                        id="ReportedMonthId"
                        name="ReportedMonthId"
                        value={props.formData.ReportedMonthId}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={
                          manyTableData.length > 0 ? true : props.bStockUpdate
                        }
                      >
                        {MonthList.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>

                      <FormHelperText error={props.errorObject.ReportedMonthId}>
                        {props.errorObject.ReportedMonthId}
                      </FormHelperText>
                    </FormControl> */}

                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            disabled={
                              manyTableData.length > 0 ? true : props.bStockUpdate
                            }
                            id="ReportedMonthId"
                            options={MonthList}
                            onChange={(event, valueobj) => handleChangeChoosen('ReportedMonthId', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValues['ReportedMonthId']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={props.formData.ReportedMonthId}
                              label={DispensingLanguage[lan][menukey]["Report Month"]+ " *"}
                              variant="standard"
                              error={props.errorObject.ReportedMonthId}
                              helperText={props.errorObject.ReportedMonthId}
                              id="ReportedMonthId"
                              name="ReportedMonthId"
                              fullWidth
                            />
                            )}
                          />
                        </FormControl> 
                  </Grid>

                  <Grid item xs={1} sm={1}>
                    <TextField
                      disabled={
                        manyTableData.length > 0 ? true : props.bStockUpdate
                      }
                      error={props.errorObject.ReportedYear}
                      helperText={props.errorObject.ReportedYear}
                      required
                      id="ReportedYear"
                      name="ReportedYear"
                      label={DispensingLanguage[lan][menukey]["Reported Year"]}
                      value={props.formData.ReportedYear}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={2} sm={2}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={true}
                        required
                        error={props.errorObject.TransactionDate}
                        helperText={props.errorObject.TransactionDate}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        id="TransactionDate"
                        label={DispensingLanguage[lan][menukey]["Entry Date"]}
                        autoOk={true}
                        name="TransactionDate"
                        fullWidth
                        showTodayButton={true}
                        disableFuture={true}
                        value={
                          props.formData.TransactionDate || props.selectedDate
                        }
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "TransactionDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  {/* <Grid item xs={5} sm={5}>
                    <TextField
                      disabled={props.bStockUpdate}
                      id="Remarks"
                      name="Remarks"
                      label={DispensingLanguage[lan][menukey]["Remarks"]}
                      value={props.formData.Remarks}
                      fullWidth
                      multiline
                      rows={3}
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid> */}

                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="TransactionId"
                      name="TransactionId"
                      label="TransactionId"
                      value={props.formData.TransactionNo}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                {/* new row */}
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card">
                    <CardContent>
                      {/* <Grid container className="mb-2">
                        <Grid item xs={12} sm={12}>

                          <Form7InvStatusValidation
                            handleClose={handleClose}
                            open={open}
                            addProduct={addProduct}
                            FacilityId={FacilityId}
                            YearId={props.formData.YearName}
                            MonthId={props.formData.MonthId}
                            {...props}
                          />
                        </Grid>
                      </Grid> */}

                      <Grid item xs={12} sm={12}>
                        <ReactTabulator
                          ref={(r) => (ReceiveDetailsRef = r)}
                          columns={ReceiveDetailsColumns}
                          rowFormatter={function (row) {
                            var data = row.getData();
                            if (data.linkNo > 0) {
                              row.getElement().style.backgroundColor =
                                "#D9FCFF";
                              row.getElement().style.fontWeight = "bold";
                            }
                          }}
                          data={manyTableData}
                          height="300px"
                          // minHeight="350px"
                          layout={"fitColumns"}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <div
                          className="float-rights sw_btns_control"
                          style={{ padding: "5px 0px 5px 0px" }}
                        >
                          <Button
                            disabled={
                              props.bStockUpdate ||
                              (props.addProductForm ? true : false) ||
                              isLoadingUpdateMany ||
                              isLoadingDeleteMany ||
                              isLoadingUpdate ||
                              isLoadingGenerate
                            }
                            className="mr-2"
                            variant="contained"
                            color="primary"
                            onClick={() => fncgenerateForm7Items()}
                          >
                            {DispensingLanguage[lan][menukey]["Generate"]}
                          </Button>
                          <Button
                            disabled={
                              props.bStockUpdate ||
                              (props.addProductForm ? true : false) ||
                              isLoadingUpdateMany ||
                              isLoadingDeleteMany ||
                              isLoadingUpdate ||
                              isLoadingGenerate
                            }
                            className="mr-2"
                            variant="contained"
                            color="secondary"
                            // onClick={() => handleClose("ProductsOpen")}
                            // onClick={() => props.deleteForm7Items()}
                            onClick={() => deleteForm7Items()}
                          >
                            {DispensingLanguage[lan][menukey]["Delete"]}
                          </Button>

                          {/* {props.formData.CreationDate &&
                            <  >
                              {DispensingLanguage[lan][menukey]["Stock as on"] +" "+ moment(props.formData.CreationDate).format("DD/MM/YYYY") +": "+ getItemStock(props.formData.StockJson) }
                            </ >
                          } */}
                        </div>
                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <ToggleButtonGroup
                          className="sw_ToggleButtonGroup"
                          size="small"
                          aria-label="Small sizes"
                          color="primary"
                          value={toggleButtonValue}
                          exclusive
                          onChange={handleChangeToggle}
                        >
                          {Object.keys(SupplyPlanSettingItems).length &&
                            SupplyPlanSettingItems.map((item, index) => {
                              return (
                                <ToggleButton
                                  // disabled={ props.bStockUpdate || (props.addProductForm ? true : false) }
                                  disabled={props.addProductForm ? true : false}
                                  value={item.ProdCode}
                                >
                                  {item.ProdText}
                                </ToggleButton>
                              );
                            })}

                          {/* 
                          <ToggleButton value="CON008">
                            {DispensingLanguage[lan][menukey]["Shukhi"]}
                          </ToggleButton>

                          <ToggleButton value="CON010">
                            {DispensingLanguage[lan][menukey]["Shukhi (3rd Gen)"]}
                          </ToggleButton>

                          <ToggleButton value="CON009">
                            {DispensingLanguage[lan][menukey]["Oral Pill Apon"]}
                          </ToggleButton>

                          <ToggleButton value="CON002">
                            {DispensingLanguage[lan][menukey]["Condom"]}
                          </ToggleButton>

                          <ToggleButton value="CON006">
                            {DispensingLanguage[lan][menukey]["Injectables (Vials)"]}
                          </ToggleButton>

                          <ToggleButton value="CON001">
                            {DispensingLanguage[lan][menukey]["AD Syringe (1ML)"]}
                          </ToggleButton>

                          <ToggleButton value="CON003">
                            {DispensingLanguage[lan][menukey]["ECP"]}
                          </ToggleButton>
                          
                          <ToggleButton value="MCH021">
                            {DispensingLanguage[lan][menukey]["Tab. Misoprostol (Dose)"]}
                          </ToggleButton>
                          
                          <ToggleButton value="MCH051">
                            {DispensingLanguage[lan][menukey]["7.1% CHLOROHEXIDINE"]}
                          </ToggleButton>
                          
                          <ToggleButton value="MCH012">
                            {DispensingLanguage[lan][menukey]["MNP (SUSSET)"]}
                          </ToggleButton>
                          
                          <ToggleButton value="MCH018">
                            {DispensingLanguage[lan][menukey]["Iron-Folic Acid (NOS)"]}
                          </ToggleButton> */}
                        </ToggleButtonGroup>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                {/* new row */}
              </Grid>
            </Grid>

            {/* </CardContent>
          </Card> */}
          </Grid>

          {/* Action buttons */}
        </Grid>
      </div>
    </div>
  );
};

export default LMISForm2FormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
  paddingTop: {
    paddingTop: "15px",
  },
});
