import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel,
  RadioGroup,
  MenuItem,
  Paper,
  CardHeader,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "receive-from-supplier";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ContractModal = ({ handleClose, open, addContract, ...props }) => {
  let tblRef = null;
  const { isLoading, error, isError, data, refetch } = useQuery("contracts",api.getContractList);
  
  const [selectedContracts, setSelectedContracts] = useState([]);

  const ContractColumns = [
  
    // { title: "id", field: "ItemNo", visible: false },
    // { title: "ItemNo", field: "ItemNo", visible: false },
    // { title: "Generic Name", field: "GenericName", visible: false },
	
    { title: "ContractNo", field: "ContractNo", visible: false },
    { title: "PackageNo", field: "PackageNo", visible: false },
    { title: "YearId", field: "YearId", visible: false },
    { title: DispensingLanguage[lan][menukey]['Sl#'],  formatter: "rownum", width:50, hozAlign: "center" , headerHozAlign:"center", headerSort:false},
    { title: DispensingLanguage[lan][menukey]['Year'], field: "PackageYear", width: 80, headerFilter:true },
    { title: DispensingLanguage[lan][menukey]['Package No'], field: "PackageName", width: 150, headerFilter:true},
    { title: DispensingLanguage[lan][menukey]['Contract No'], field: "ContractName", headerFilter:true},
  ];

  let selected = [];
  const ContractDataItems = data?.map((item, index) => { 
    return item;
  });
  const SaveSelectedContracts = () => {
    if(tblRef.table.getSelectedData().length>0)
    setSelectedContracts(tblRef.table.getSelectedData());
  };

  useEffect(() => { 
    if (tblRef == null) return;
    addContract(selectedContracts);

 }, [selectedContracts]); 

  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose('Contract')}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('Contract')}
        >
        {DispensingLanguage[lan][menukey]['Contract List']} 
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <ReactTabulator
            ref={(r) => (tblRef = r)}
            options={{
              selectableRangeMode: 'click',
             }}
            selectable={1}
            columns={ContractColumns}
            data={ContractDataItems}
            height="300px"
            layout={"fitColumns"}

          />
        </DialogContent>
        <DialogActions>
          <Button
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={SaveSelectedContracts}
          >
           {DispensingLanguage[lan][menukey]['ADD']}  
          </Button>
          <Button
            autoFocus
            className="float-left"
            variant="contained" 
            onClick={() => handleClose('Contract')}
          >
            {DispensingLanguage[lan][menukey]['CANCEL']}   
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ContractModal;
