import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel,
  RadioGroup,
  MenuItem,
  Paper,
  FormGroup,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  FormHelperText,
} from "@material-ui/core";

import Autocomplete from '@material-ui/lab/Autocomplete';

import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import SwipeableViews from "react-swipeable-views";
import { useTranslation } from "react-i18next";
import Constants from "../../../services/Constants";
import NextPrevBtn from '../../../services/NextPrevBtn';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}



const ProductFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ValidateProductForDispensing,
  handleATCChange,
  ...props
}) => {

    const StockData=JSON.parse(
      localStorage.getItem("ProductDataNextPrev")
    );
    
    const StockDataIndex=JSON.parse(
      localStorage.getItem("ProductDataIndex")
    );

    //get DispensingLanguage
    const DispensingLanguage = JSON.parse(
      localStorage.getItem("DispensingLanguage")
    );
    const lan = localStorage.getItem("LangCode");
    const menukey = "products";

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();

  const GenericList = JSON.parse(localStorage.getItem("GenericList"));
  const Strengthlist = JSON.parse(localStorage.getItem("Strengthlist"));
  const DosageFormlist = JSON.parse(localStorage.getItem("DosageFormlist"));
  const PackSizelist = JSON.parse(localStorage.getItem("PackSizelist"));
  const Unitofmeasurelist = JSON.parse(localStorage.getItem("Unitofmeasurelist"));
  const RouteOfAdminlist = JSON.parse(localStorage.getItem("RouteOfAdminlist"));
  const VEN_List = JSON.parse(localStorage.getItem("VEN_List"));
  const ABC_List = JSON.parse(localStorage.getItem("ABC_List"));
  const RxLevelList = JSON.parse(localStorage.getItem("RxLevelList"));
  const ATC_List = JSON.parse(localStorage.getItem("ATC_List"));

//  const [atcDefaultValue, setAtcDefaultValue] = useState(ATC_List[defaultATCIndex]);
// const atcDefaultValue = ATC_List[defaultATCIndex];
// console.log('atcDefaultValuesssssss: ', atcDefaultValue);
//  console.log('ATC_List[defaultATCIndex]: ', ATC_List[defaultATCIndex]);



  function tabChangeHandler(event, newValue) {
    setValue(newValue);
  }

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const getPricebesideLevel = (type = null) => {
    let level = "";
    let extraString = "";
    if (type == "shippingPack") {
      extraString = "x ";
    } else {
      extraString = "/ ";
    }

    if (formData.packSize && formData.unit) {
      let packSizes = PackSizelist;
      let selectedPackSize = packSizes.find(
        (packSize) => packSize.id == formData.packSize
      );

      let units = Unitofmeasurelist;
      let selectedUnit = units.find((unit) => unit.id == formData.unit);
      level = extraString + selectedPackSize.name + " " + selectedUnit.name;
    }
    return level;
  };

  const handleIndex = (indx,row) => {
    localStorage.setItem(
      "ProductDataIndex",
      JSON.stringify(indx)
    );
    let route = `${row.id}`; 
    props.history.push(route); 
  };

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Product Entry"]} -{" "}
                {addProductForm
                  ? DispensingLanguage[lan][menukey]["Add"]
                  : DispensingLanguage[lan][menukey]["Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {addProductForm ? (
                  <Grid item xs={12} className="text-center">
                    <div className="float-right sw_btn_control">
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit()}
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => props.history.push("/products")}
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="text-center">
                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => handleUpdate()}
                    >
                      {DispensingLanguage[lan][menukey]["Update"]}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => props.history.push("/products")}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                  </Grid>
                )}
              </div>
            </div>
          </Grid>
        </div>

        {addProductForm ? (
          <></>
        ) : (
        <NextPrevBtn dataList={StockData} StockDataIndex={StockDataIndex} handleReset={handleReset} handleIndex={handleIndex}  {...props}/> 
        )}

        {/* New row */}
        <Grid container spacing={3}>
          <Grid item xs={6} sm={3}>
            <TextField
              required
              id="productCode"
              name="productCode"
              label={DispensingLanguage[lan][menukey]["Product Code"]}
              value={formData.productCode}
              fullWidth
              disabled={true}
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextField
              required
              id="productName"
              name="productName"
              label={DispensingLanguage[lan][menukey]["Product Name"]}
              value={formData.productName}
              fullWidth
              disabled={true}
              autoComplete="given-name"
              onChange={(e) => handleChange(e)}
            />
            <TextField
              hidden
              id="ItemShortName"
              name="ItemShortName"
              label="Item Short Name"
              value={formData.ItemShortName}
              fullWidth
              disabled={true}
              autoComplete="given-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["VEN"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    name="ven"
                    value={formData.ven}
                    fullWidth
                    onChange={(e) => handleChange(e)}
                  >
                    {VEN_List.map((item, index) => {
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={6} sm={6}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["ABC"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    name="abc"
                    value={formData.abc}
                    fullWidth
                    onChange={(e) => handleChange(e)}
                  >
                    {ABC_List.map((item, index) => {
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          {/* New row */}
          <Grid item xs={4} sm={3}>
            <TextField
              error={errorObject.icn}
              helperText={errorObject.icn}
              required
              id="icn"
              name="icn"
              label={DispensingLanguage[lan][menukey]["ICN"]}
              value={formData.icn}
              fullWidth
              autoComplete="given-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <FormControl className={classes.fullWidth}>
              <InputLabel id="demo-simple-select-helper-label">
                {DispensingLanguage[lan][menukey]["Generic Name"]}*
              </InputLabel>
              <Select
                error={errorObject.genericName}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                name="genericName"
                value={formData.genericName}
                fullWidth
                onChange={(e) => handleChange(e)}
              >
                {GenericList.map((item, index) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </Select>
              <FormHelperText error={errorObject.genericName}>
                {errorObject.genericName}
              </FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={4} sm={3}>
            <TextField
              id="tradeName"
              name="tradeName"
              label={
                DispensingLanguage[lan][menukey]["Trade Name or Other Name"]
              }
              value={formData.tradeName}
              fullWidth
              autoComplete="given-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>
          <Grid item xs={4} sm={3}>
            <FormControl className={classes.fullWidth}>
              <InputLabel id="demo-simple-select-helper-label">
                {DispensingLanguage[lan][menukey]["Rx Level"]}
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                name="rxLevel"
                value={formData.rxLevel}
                fullWidth
                onChange={(e) => handleChange(e)}
              >
                {RxLevelList.map((item, index) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Grid>

          {/* New row */}
          <Grid item xs={4} sm={3}>
            <TextField
              id="ecn"
              name="ecn"
              label={DispensingLanguage[lan][menukey]["ECN"]}
              value={formData.ecn}
              fullWidth
              autoComplete="given-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>

          <Grid item xs={4} sm={3}>
            <FormControl className={classes.fullWidth}>
              <InputLabel id="demo-simple-select-helper-label">
                {DispensingLanguage[lan][menukey]["Strength/Size"]}*
              </InputLabel>
              <Select
                error={errorObject.strengthSize}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                name="strengthSize"
                value={formData.strengthSize}
                fullWidth
                onChange={(e) => handleChange(e)}
              >
                {Strengthlist.map((item, index) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </Select>
              <FormHelperText error={errorObject.strengthSize}>
                {errorObject.strengthSize}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={4} sm={3}>
            <div className="sw_overlay_text">
              <TextField
                id="price"
                name="price"
                type="number"
                label={DispensingLanguage[lan][menukey]["Price (CFA)"]}
                value={formData.price}
                fullWidth
                autoComplete="shipping address-level2"
                onChange={(e) => handleChange(e)}
              />{" "}
              <span className="sw_pack_size">{getPricebesideLevel()}</span>
            </div>
          </Grid>

          <Grid item xs={4} sm={3}>
            <TextField
              id="binLocation"
              name="binLocation"
              label={DispensingLanguage[lan][menukey]["Bin Location"]}
              value={formData.binLocation}
              fullWidth
              autoComplete="given-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>

          {/* New row */}
          <Grid item xs={4} sm={3}>
            <TextField
              id="nsn"
              name="nsn"
              label={DispensingLanguage[lan][menukey]["NSN"]}
              value={formData.nsn}
              fullWidth
              autoComplete="given-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>

          <Grid item xs={4} sm={3}>
            <FormControl className={classes.fullWidth}>
              <InputLabel id="demo-simple-select-helper-label">
                {DispensingLanguage[lan][menukey]["Form"]}*
              </InputLabel>
              <Select
                error={errorObject.form}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                name="form"
                value={formData.form}
                fullWidth
                onChange={(e) => handleChange(e)}
              >
                {DosageFormlist.map((item, index) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </Select>
              <FormHelperText error={errorObject.form}>
                {errorObject.form}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={4} sm={3}>
            <FormControl className={classes.fullWidth}>
              <InputLabel id="demo-simple-select-helper-label">
                {DispensingLanguage[lan][menukey]["Route of Admin"]}*
              </InputLabel>
              <Select
                error={errorObject.routeOfAdmin}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                name="routeOfAdmin"
                value={formData.routeOfAdmin}
                fullWidth
                onChange={(e) => handleChange(e)}
              >
                {RouteOfAdminlist.map((item, index) => {
                  return <MenuItem value={item.id}>{item.name}</MenuItem>;
                })}
              </Select>
              <FormHelperText error={errorObject.routeOfAdmin}>
                {errorObject.routeOfAdmin}
              </FormHelperText>
            </FormControl>
          </Grid>

          <Grid item xs={4} sm={3}>
              <FormControl className={classes.fullWidth}>

                {formData.atc !== "" && (
                  <Autocomplete
                      autoHighlight
                      id="atc"
                      options={ATC_List}
                      onChange={(event, newValue) => handleATCChange(event, newValue)}
                      getOptionLabel={(option) => option.id+' - '+option.name}
                      defaultValue={ATC_List[ATC_List.findIndex(atclist => atclist.id == formData.atc)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.id+' - '+option.name}</Typography>
                      )}
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["ATC"]}
                        variant="standard"
                      />
                      )}
                    />
                    )}

                {formData.atc == "" && (
                  <Autocomplete
                  
                      autoHighlight
                      id="atc"
                      options={ATC_List}
                      onChange={(event, newValue) => handleATCChange(event, newValue)}
                      getOptionLabel={(option) => option.id+' - '+option.name}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.id+' - '+option.name}</Typography>
                      )}
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["ATC"]}
                        variant="standard"
                      />
                      )}
                    />
                    )}

                </FormControl>


          </Grid>

          {/* New row */}
          <Grid item xs={4} sm={3}>
            <TextField
              id="fin"
              name="fin"
              label={DispensingLanguage[lan][menukey]["FIN"]}
              value={formData.fin}
              fullWidth
              autoComplete="given-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>

          <Grid item xs={4} sm={3}>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={6}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Pack Size"]}*
                  </InputLabel>
                  <Select
                    error={errorObject.packSize}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    name="packSize"
                    value={formData.packSize}
                    fullWidth
                    onChange={(e) => handleChange(e)}
                  >
                    {PackSizelist.map((item, index) => {
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                  <FormHelperText error={errorObject.packSize}>
                    {errorObject.packSize}
                  </FormHelperText>
                </FormControl>
              </Grid>

              <Grid item xs={6} sm={6}>
                <FormControl className={classes.fullWidth}>
                  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Unit"]}*
                  </InputLabel>
                  <Select
                    error={errorObject.unit}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    name="unit"
                    value={formData.unit}
                    fullWidth
                    onChange={(e) => handleChange(e)}
                  >
                    {Unitofmeasurelist.map((item, index) => {
                      return <MenuItem value={item.id}>{item.name}</MenuItem>;
                    })}
                  </Select>
                  <FormHelperText error={errorObject.unit}>
                    {errorObject.unit}
                  </FormHelperText>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={4} sm={3}>
            <TextField
              id="markUp"
              name="markUp"
              label={DispensingLanguage[lan][menukey]["Markup%"]}
              value={formData.markUp}
              fullWidth
              // type="number"
              autoComplete="given-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>

          <Grid item xs={4} sm={3}>
            <TextField
              id="SellingPrice"
              name="SellingPrice"
              label={DispensingLanguage[lan][menukey]["Selling Price (CFA)"]}
              value={formData.SellingPrice}
              type="number"
              fullWidth
              autoComplete="given-name"
              onChange={(e) => handleChange(e)}
            />
          </Grid>

          {/* New row */}
          <Grid item xs={4} sm={3}>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                {DispensingLanguage[lan][menukey]["Product Type"]}
              </FormLabel>
              <RadioGroup
                aria-label="ProductTypeId"
                name="ProductTypeId"
                value="1"
                className="sw_radiogroup"
              >
                <FormControlLabel
                  value="1"
                  checked={formData.ProductTypeId == 1}
                  control={<Radio />}
                  label={DispensingLanguage[lan][menukey]["Drug"]}
                  onChange={(e) => handleChange(e)}
                />
                <FormControlLabel
                  value="2"
                  checked={formData.ProductTypeId == 2}
                  control={<Radio />}
                  label={DispensingLanguage[lan][menukey]["Medical Supply"]}
                  onChange={(e) => handleChange(e)}
                />
                <FormControlLabel
                  value="3"
                  checked={formData.ProductTypeId == 3}
                  control={<Radio />}
                  label={DispensingLanguage[lan][menukey]["Others"]}
                  onChange={(e) => handleChange(e)}
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={4} sm={3}>
            <Grid item xs={12}>
              <FormControl className={classes.fullWidth}>
                <InputLabel id="demo-simple-select-helper-label">
                  {DispensingLanguage[lan][menukey]["Administration Unit"]}*
                </InputLabel>
                <Select
                  error={errorObject.administrationUnit}
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  name="administrationUnit"
                  value={formData.administrationUnit}
                  fullWidth
                  onChange={(e) => handleChange(e)}
                >
                  {Unitofmeasurelist.map((item, index) => {
                    return <MenuItem value={item.id}>{item.name}</MenuItem>;
                  })}
                </Select>
                <FormHelperText error={errorObject.administrationUnit}>
                  {errorObject.administrationUnit}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} className="marginTop10">
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={formData.bPediatric}
                    onChange={(e) => handleCheck(e)}
                    name="bPediatric"
                    value="no"
                  />
                }
                label={DispensingLanguage[lan][menukey]["Pediatric"]}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={formData.bInjectable}
                    onChange={(e) => handleCheck(e)}
                    name="bInjectable"
                    value="no"
                  />
                }
                label={DispensingLanguage[lan][menukey]["Injectable"]}
              />
            </Grid>
          </Grid>
          <Grid item xs={4} sm={3}>
            <div className="sw_overlay_text">
              <TextField
                id="shippingPack"
                name="shippingPack"
                type="number"
                label={DispensingLanguage[lan][menukey]["Shipping Pack"]}
                value={formData.shippingPack}
                fullWidth
                autoComplete="given-name"
                onChange={(e) => handleChange(e)}
              />
              <span className="sw_pack_size">
                {getPricebesideLevel("shippingPack")}
              </span>
            </div>
          </Grid>
          <Grid item xs={4} sm={3}>
          </Grid>

          {/* new row */}
          <Grid item xs={12} sm={12} className="dnone">
            <FormControl component="fieldset" className={classes.formControl}>
              <FormGroup className="sw_Checkboxgroup">
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={formData.bIncludeTrade}
                      onChange={(e) => handleCheck(e)}
                      name="bIncludeTrade"
                      value="no"
                    />
                  }
                  label={
                    DispensingLanguage[lan][menukey][
                      "Include Trade name in Description"
                    ]
                  }
                />

                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={formData.bExcludeGeneric}
                      onChange={(e) => handleCheck(e)}
                      name="bExcludeGeneric"
                      value="no"
                    />
                  }
                  label={
                    DispensingLanguage[lan][menukey][
                      "Exclude Generic name from Description"
                    ]
                  }
                />
              </FormGroup>
            </FormControl>
          </Grid>

          {/* new row TAB */}

          {/*
          <Grid item xs={12} sm={12}>
            <div className={classes.root}>
              <AppBar position="static" color="default">
                <Tabs
                  value={value}
                  onChange={tabChangeHandler}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab
                    label={DispensingLanguage[lan][menukey]["Dispensing"]}
                    {...a11yProps(0)}
                  />
                </Tabs>
              </AppBar>

              <SwipeableViews
                axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={6}>
                      <Card className="sw_card">
                        <CardHeader
                          title={
                            DispensingLanguage[lan][menukey][
                              "Packing Information"
                            ]
                          }
                        />
                        <CardContent>
                          <Grid container spacing={3}>
                            <Grid item xs={6} sm={6}>
                              <Typography variant="body2" component="p">
                                {DispensingLanguage[lan][menukey]["Strenght"]}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <Typography
                                className={classes.pos}
                                color="textSecondary"
                              >
                                10mg/1ml
                              </Typography>
                            </Grid>

                            <Grid item xs={6} sm={6}>
                              <Typography variant="body2" component="p">
                                {
                                  DispensingLanguage[lan][menukey][
                                    "Strength Unit"
                                  ]
                                }
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <Typography
                                className={classes.pos}
                                color="textSecondary"
                              >
                                ml
                              </Typography>
                            </Grid>

                            <Grid item xs={6} sm={6}>
                              <Typography variant="body2" component="p">
                                {DispensingLanguage[lan][menukey]["Pack Size"]}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <Typography
                                className={classes.pos}
                                color="textSecondary"
                              >
                                240ml
                              </Typography>
                            </Grid>

                            <Grid item xs={6} sm={6}>
                              <Typography variant="body2" component="p">
                                {
                                  DispensingLanguage[lan][menukey][
                                    "Dispening Pack Unit"
                                  ]
                                }
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <Typography
                                className={classes.pos}
                                color="textSecondary"
                              >
                                240ml
                              </Typography>
                            </Grid>

                            <Grid item xs={6} sm={6}>
                              <Typography variant="body2" component="p">
                                {DispensingLanguage[lan][menukey]["Rx Level"]}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <Typography
                                className={classes.pos}
                                color="textSecondary"
                              >
                                240ml
                              </Typography>
                            </Grid>

                            <Grid item xs={6} sm={6}>
                              <Typography variant="body2" component="p">
                                {
                                  DispensingLanguage[lan][menukey][
                                    "Strength Co-efficient"
                                  ]
                                }
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <Typography
                                className={classes.pos}
                                color="textSecondary"
                              >
                                ml
                              </Typography>
                            </Grid>

                            <Grid item xs={6} sm={6}>
                              <Typography variant="body2" component="p">
                                {
                                  DispensingLanguage[lan][menukey][
                                    "Pack Co-efficient"
                                  ]
                                }
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <Typography
                                className={classes.pos}
                                color="textSecondary"
                              >
                                ml
                              </Typography>
                            </Grid>

                            <Grid item xs={6} sm={6}>
                              <Typography variant="body2" component="p">
                                {
                                  DispensingLanguage[lan][menukey][
                                    "Dispensing Unit"
                                  ]
                                }
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <Typography
                                className={classes.pos}
                                color="textSecondary"
                              >
                                Tablet
                              </Typography>
                            </Grid>

                            <Grid item xs={6} sm={6}>
                              <Typography variant="body2" component="p">
                                {
                                  DispensingLanguage[lan][menukey][
                                    "Administrative Form"
                                  ]
                                }
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <Typography
                                className={classes.pos}
                                color="textSecondary"
                              >
                                Tablet
                              </Typography>
                            </Grid>

                            <Grid item xs={6} sm={6}>
                              <Typography variant="body2" component="p">
                                {
                                  DispensingLanguage[lan][menukey][
                                    "Administrative Mode"
                                  ]
                                }
                              </Typography>
                            </Grid>
                            <Grid item xs={6} sm={6}>
                              <Typography
                                className={classes.pos}
                                color="textSecondary"
                              >
                                Tablet
                              </Typography>
                            </Grid>

                            <Grid item xs={12} sm={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={formData.availableforDispensing}
                                    onChange={(e) => handleCheck(e)}
                                    name="availableforDispensing"
                                    value="no"
                                  />
                                }
                                label={
                                  DispensingLanguage[lan][menukey][
                                    "Available for Dispensing"
                                  ]
                                }
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>

                    <Grid item xs={6} sm={6}>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        className="sw_fullWidth_hidden"
                      >
                        <Button
                          className="mb-3 float-right"
                          variant="contained"
                          color="primary"
                          onClick={() => ValidateProductForDispensing()}
                        >
                          {
                            DispensingLanguage[lan][menukey][
                              "Validate product for dispensing"
                            ]
                          }
                        </Button>
                      </Grid>
                      <Card className="sw_card">
                        <CardHeader
                          title={
                            DispensingLanguage[lan][menukey]["Label Display"]
                          }
                        />
                        <CardContent>
                          <Grid container spacing={3}>
                            <Grid item xs={4} sm={4}>
                              <Typography variant="body2" component="p">
                                {
                                  DispensingLanguage[lan][menukey][
                                    "Label Display"
                                  ]
                                }
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                              <Typography
                                className={classes.pos}
                                color="textSecondary"
                              >
                                10mg/1ml
                              </Typography>
                            </Grid>

                            <Grid item xs={4} sm={4}></Grid>

                            <Grid item xs={4} sm={4}>
                              <Typography variant="body2" component="p">
                                {
                                  DispensingLanguage[lan][menukey][
                                    "Lookup Display"
                                  ]
                                }
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4}>
                              <Typography
                                className={classes.pos}
                                color="textSecondary"
                              >
                                ml
                              </Typography>
                            </Grid>
                            <Grid item xs={4} sm={4}></Grid>

                            <Grid item xs={12} sm={12}>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={formData.breakAdministrationUnit}
                                    onChange={(e) => handleCheck(e)}
                                    name="breakAdministrationUnit"
                                    value="no"
                                  />
                                }
                                label={
                                  DispensingLanguage[lan][menukey][
                                    "Break Administration Unit"
                                  ]
                                }
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </TabPanel>
              </SwipeableViews>
            </div>
          </Grid>*/}

          {/* {addProductForm ? (
            <Grid item xs={12} className="mt-4 text-center">
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                {DispensingLanguage[lan][menukey]["Reset"]}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {DispensingLanguage[lan][menukey]["Save"]}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/products")}
              >
                {DispensingLanguage[lan][menukey]["Cancel"]}
              </Button>
            </Grid>
          ) : (
            <Grid item xs={12} className="mt-2 text-center">
              <Button
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {DispensingLanguage[lan][menukey]["Update"]}
              </Button>
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/products")}
              >
                {DispensingLanguage[lan][menukey]["Cancel"]}
              </Button>
            </Grid>
          )} */}
        </Grid>
      </div>
    </div>
  );
};

export default ProductFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
