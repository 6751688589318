import React from "react";

// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme

import * as Service from "../../services/Service.js";

// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";
import IndexNavbar from "components/Navbars/IndexNavbar.js";

const ReportPage = (props) => {

  return (
    <>
      {Service.default.authToken() != null ? (
        <AfterLoginNavbar {...props} />
      ) : (
        <IndexNavbar {...props} />
      )}


      <div
        className="section signup-top-padding"
        style={{
          backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          minHeight: "753px",
        }}
      >
        <div class="permission_card">
          <h1>You have no permission to this page.</h1>
          <p>
            {/* Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum. */}
          </p>
          <a class="goto_btn" href={process.env.REACT_APP_BASE_NAME} >
            Go To Home
          </a>
        </div>
      </div>
    </>
  );
};




export default ReportPage;

const useStyles = makeStyles({
  root: {
    width: "20%",
    margin: "0 1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDiv: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },
});
