import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import * as api from "../../../actions/api";
import ProcuringFormData from "./ProcuringFormData.js";


const EditProcuring = (props) => {
  //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "asset-procuring-entry";
  const UserInfo = sessionStorage.getItem("User_info")? JSON.parse(sessionStorage.getItem("User_info")): 0;  
  const UserName = UserInfo==0?'': UserInfo[0].id;

  const [ColorCode, setColorCode] = useState("#00aabb");
  
  const handleChangeColorAdd = (value) => {
    //console.log(value);
    setColorCode(value);
  };

  const [formData, setFormData] = useState({
    ProcuringName: "",
    colorval: ColorCode,
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  // console.log('get item',id);
  const queryClient = useQueryClient();

  const { data } = useQuery(
   
    ["AssetProcuring", id],
    () =>
      api.getProcuringData(id).then((res) => {
         console.log(res.data);
        setFormData(res.data);
        return res.data;
      }),
    {
      enabled: Boolean(id),
    }
  );

  const { isLoading, mutate } = useMutation(api.updateProcuring, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
		
        queryClient.getQueriesData("AssetProcuring");
        props.history.push("/asset-procuring");
            
        }else{   
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
		
        }
    
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      ProcuringName: "",
      colorval: ColorCode,
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["ProcuringName","colorval"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
       // if (formData[field] != 0) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false;
       // }
      }

    });
    setErrorObject(errorData);
    return isValid;
  };
  

  const handleUpdate = async (e) => {
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      mutate(fDtat);
      //mutate(formData);
     
    }
  };

  return (
    <>
      <ProcuringFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleUpdate={handleUpdate}
        handleChangeColorAdd={handleChangeColorAdd}
        {...props}
      />
    </>
  );
};

export default EditProcuring;
