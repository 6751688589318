import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FadeLoader from "react-spinners/FadeLoader";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
} from "@material-ui/core";

//import ReactExport from "react-data-export";
import { Button } from "reactstrap";

import MaterialTable from "material-table";
import * as Service from "../../../services/Service.js";

// Highcharts component
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { parse } from "date-fns";



const useStyles = makeStyles((theme) => ({
  pageSet: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  whiteBG: {
    padding: "1rem",
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    color: "#111",
  },
  fullWidth: {
    width: "95%",
  },
}));

const Reports = (props) => {
  const classes = useStyles();
  const tableRef = useRef();
  let [loading, setLoading] = useState(true);
  let [excelDisable, setExcelDisable] = useState(true);
  let [color, setColor] = useState("#111");
  const [selectedRow, setSelectedRow] = useState(null);
  const [numberRowPerPage, setNumberRowPerPage] = useState(20);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const [provinceList, setProvinceList] = useState([]);
  const [bczList, setBczList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [reportList, setReportList] = useState([]);
  const [reportChartList, setReportChartList] = useState([]);
  const [excelReportList, setExcelReportList] = useState([]);
  const [graphProduct, setGraphProduct] = useState([]);
  const [dataSet, setDataSet] = useState([]);
  const [graphBalance, setGraphBalance] = useState([]);
  const [state, setState] = useState({
    province: "ALL",
    bcz: "ALL",
    month: "0",
    year: "0",
  });



  const optionThree = {
    chart: {
      type: "bar",
      height: graphProduct.length>60? window.screen.availHeight+2700:window.screen.availHeight+300,
    },
    title: {
      text: "Stock Status Report",
    },
    xAxis: {
      categories: graphProduct ? graphProduct : null,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Balance",
      },
    },
    legend: {
      reversed: true,
    },
    plotOptions: {
      series: {
        stacking: "normal",
      },
    },
    series: [
      {
        name: "Balance",
        data: graphBalance ? graphBalance : null,
      },
    ],
  };

  const handleChangePage = (data) => {
    setPageNumber(data);
  };
  const handleChangeRowPerPage = (data) => {
    setNumberRowPerPage(data);
  };

  const handleFilter = (e, v) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const fetchProvinceFilter = () => {
    // Service.default
    //   .getAuthApi("source/reports/province_list.php")
    //   .then((res) => {
    //     if (res.status == 200) {
    //       setProvinceList(res.Productgroupdata);
    //     } else {
    //       console.log(res.msg);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  const fetchBczFilter = () => {
    // Service.default
    //   .getAuthApi("source/reports/bcz_list.php")
    //   .then((res) => {
    //     if (res.status == 200) {
    //       setBczList(res.Productgroupdata);
    //     } else {
    //       console.log(res.msg);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  const fetchMonthFilter = async () => {
    // await Service.default
    //   .getAuthApi("source/reports/month.php")
    //   .then((res) => {
    //     if (res.status == 200) {
    //       setMonthList(res.Productgroupdata);
    //     } else {
    //       console.log(res.msg);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  const fetchYearFilter = async () => {
    // await Service.default
    //   .getAuthApi("source/reports/year.php")
    //   .then((res) => {
    //     if (res.status == 200) {
    //       setYearList(res.Productgroupdata);
    //     } else {
    //       console.log(res.msg);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  const fetchData = async () => {
    // setLoading(true);
    // const body = {
    //   provience: state.province,
    //   bcz: state.bcz,
    //   month: state.month,
    //   year: state.year,
    //   page_number: parseInt(pageNumber) + 1,
    // };
    // await Service.default
    //   .postAuthApi("source/reports/list.php", body)
    //   .then((res) => {
    //     if (res.status == 200) {
    //       setReportList(res.reportsData);
    //       setTotalRow(res.totalRecords);

    //       setLoading(false);
    //     } else {
    //       console.log(res.msg);
    //       setLoading(false);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };




  const fetchGraphData = () => {
    // // setLoading(true);
    // const body = {
    //   month: state.month,
    //   year: state.year,
    // };
    // Service.default
    //   .postAuthApi("source/reports/chart.php", body)
    //   .then((res) => {
    //     if (res.status == 200) {

    //       setReportChartList(res.reportsData);
    //       // setLoading(false);
    //     } else {
    //       console.log(res.msg);
    //       // setLoading(false);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     // setLoading(false);
    //   });
  };

  useEffect(() => {
    // if (reportChartList.length > 0) {
    //   let tempName = [];
    //   let tempBal = [];
    //   reportChartList.map((r) => {
    //     tempName.push(r.ProductName);
    //     tempBal.push( r.Balance ? parseFloat(r.Balance) : 0);
    //   });
    //   setGraphProduct(tempName);
    //   setGraphBalance(tempBal);
    // } else {
    //   setGraphProduct([]);
    //   setGraphBalance([]);
    // }
  }, [reportChartList]);

  useEffect(() => {
    if (
      state.province &&
      state.bcz &&
      state.year !== "0" &&
      state.month !== "0"
    ) {
      fetchData();
      fetchGraphData();
      // fetchAllExcelData();
    }
  }, [state.province, state.bcz, state.month, state.year]);

  useEffect(() => {
    if (yearList.length > 0) {
      setState({ ...state, year: yearList[0].Year });
    }
    if (monthList.length > 0) {
      setState({ ...state, month: monthList[0].MonthName });
    }
  }, [monthList, yearList]);

  React.useEffect(() => {
    fetchProvinceFilter();
    fetchBczFilter();
    fetchMonthFilter();
    fetchYearFilter();
  }, []);

  React.useEffect(() => {
    if (yearList.length > 0) {
      setState({ ...state, year: yearList[0].Year });
    }
  }, [yearList]);

  React.useEffect(() => {
    if (yearList.length > 0) {
      setState({ ...state, year: yearList[0].Year });
    }
  }, [yearList]);

  React.useEffect(() => {
    if (pageNumber > 0) {
      fetchData();
    }
  }, [pageNumber]);

  return (
    <>
      {loading ? (
        <div className="loader-div">
          {/* <CircularProgress color="info" className="loader" /> */}
          <FadeLoader
            color={color}
            loading={loading}
            css={{ top: "50%", left: "50%" }}
            size={250}
          />
        </div>
      ) : null}
      <div className={classes.pageSet}>
        <div className={classes.whiteBG}>
          <div className="text-center">
            <h3>Report</h3>
          </div>

          <div className="row mt-4">
            <div className="col-md-7 col-sm-3 pad-1">
              <div className="row mb-5">
                <div className="col-md-3">
                  <FormControl className={classes.fullWidth}>
                    <InputLabel id="demo-simple-select-helper-label">
                      Province
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      name="province"
                      value={state.province || ""}
                      fullWidth
                      onChange={(e) => handleFilter(e)}
                    >
                      <MenuItem value={"ALL"}>ALL</MenuItem>
                      {provinceList.map((row, index) => {
                        return (
                          <MenuItem key={index} value={row.ProvinceName}>
                            {row.ProvinceName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-md-3">
                  <FormControl className={classes.fullWidth}>
                    <InputLabel id="demo-simple-select-helper-label">
                      BCZ
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      name="bcz"
                      value={state.bcz || ""}
                      fullWidth
                      onChange={(e) => handleFilter(e)}
                    >
                      <MenuItem value={"ALL"}>ALL</MenuItem>
                      {bczList.map((row, index) => {
                        return (
                          <MenuItem key={index} value={row.BCZName}>
                            {row.BCZName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-md-3">
                  <FormControl className={classes.fullWidth}>
                    <InputLabel id="demo-simple-select-helper-label">
                      Month
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      name="month"
                      value={state.month || ""}
                      fullWidth
                      onChange={(e) => handleFilter(e)}
                    >
                      {monthList.map((row, index) => {
                        return (
                          <MenuItem key={index} value={row.MonthName}>
                            {row.MonthName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
                <div className="col-md-3">
                  <FormControl className={classes.fullWidth}>
                    <InputLabel id="demo-simple-select-helper-label">
                      Year
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      name="year"
                      value={state.year || ""}
                      fullWidth
                      onChange={(e) => handleFilter(e)}
                    >
                      {yearList.map((row, index) => {
                        return (
                          <MenuItem key={index} value={row.Year}>
                            {row.Year}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </div>
              <MaterialTable
                tableRef={tableRef}
                title={"Report"}
                icons={Service.default.tableicons()}
                columns={[
                  { title: "Province", field: "ProvinceName" },
                  { title: "BCZ", field: "BCZName" },
                  { title: "Facility", field: "FacilityName" },
                  { title: "Product", field: "ProductName" },
                  { title: "Balance", field: "ClosingStock" },
                  { title: "AMC", field: "AMC" },
                  { title: "MOS", field: "MOS" },
                ]}
                data={reportList}
                components={{
                  Pagination: (props) => (
                    <TablePagination
                      {...props}
                      rowsPerPageOptions={[20]}
                      rowsPerPage={numberRowPerPage}
                      count={totalRow}
                      page={pageNumber}
                      onChangePage={(e, page) => {
                        handleChangePage(page);
                      }}
                      onChangeRowsPerPage={(event) => {
                        props.onChangeRowsPerPage(event);
                        handleChangeRowPerPage(event.target.value);
                      }}
                    />
                  ),
                }}
                options={{
                  showTitle: false,
                  toolbar: false,
                  rowStyle: (rowData) => ({
                    backgroundColor:
                      selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
                  }),
                  headerStyle: {
                    position: "sticky",
                    fontWeight: "bold",
                    border: "0.5px solid lightgrey",
                    zIndex: "0",
                  },
                  sorting: true,
                  search: false,
                  pageSize: 20,
                  // pageSizeOptions: [6],
                }}
              />
            </div>
            <div className="col-md-5 col-sm-3 pad-1 pt-6">
              <HighchartsReact highcharts={Highcharts} options={optionThree} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Reports;
