import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import swal from "sweetalert";

import * as api from "../../../actions/api";
import {
  RadioGroup,
  Grid,
  Typography,
  FormControlLabel,
  TextField,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel,
  MenuItem,
  FormGroup,
  Card,
  CardContent,
  FormHelperText,
} from "@material-ui/core";

import ExportButtonsMany from "../../../components/ExportButtonsMany";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useQuery, useMutation, useQueryClient } from "react-query";
import { DeleteOutline, Edit, ViewList, FileCopy } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import SwipeableViews from "react-swipeable-views";
import { useTranslation } from "react-i18next";
import Constants from "../../../services/Constants";
import ProductsModal from "../../../services/ReturnProvisionalReceiveItemsModal";
import SourceModal from "../../../services/SourceModal";
import ContractModalByYear from "../../../services/ContractModalByYear";

import { cellFocusEditor, dateEditor, dateEditorAllowPast } from "../../../services/Common";
import OrderModal from "./OrderModal";

import MomentUtils from "@date-io/moment";
import moment from "moment";
let timeStamp = "";


const MasterManyFormData = (props) => {
  const permissionType = props.permissionType;
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "return-to-the-supplier-from-provisional-receive";
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  let ReceiveDetailsRef = null;

  const [newBatchRowAdded, setNewBatchRowAdded] = useState(false);

  const [open, setOpen] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);

  const [SoureOpen, setSoureOpen] = useState(false);

  const [openContract, setContractOpen] = useState(false);

  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);
  const SupplierList = JSON.parse(localStorage.getItem("SupplierListNational"));
  const FacilityEmployee = JSON.parse(localStorage.getItem("FacilityEmployee"));
  const IndentorList = JSON.parse(localStorage.getItem("IndentorList"));
  const PackageYearList = JSON.parse(localStorage.getItem("PackageYearList"));
  
  const [currRowId, setCurrRowId] = useState(0);
  const [currFormulation, setCurrFormulation] = useState("");
  const [bIsDisable, setbIsEnable] = useState(false);

  const [currYearId, setcurrYearId] = useState("");
  const [currContractNo, setcurrContractNo] = useState("");

  const ReturnTypeList =  [
    {
      "id": '1',
      "name": t(DispensingLanguage[lan][menukey]["Disapproved Return"])
    },
    {
      "id": '2',
      "name": t(DispensingLanguage[lan][menukey]["Direct Return"])
    }
  ];
  
  

  
  
//====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "YearId": {"YearId": "","PackageYear": ""},
  "SupCode": {"SupCode": "","SupName": ""},
  "PreparedBy": {"EmpCode": "","EmpName": ""},
  "AppBy": {"EmpCode": "","EmpName": ""},
  "ReturnBy": {"EmpCode": "","EmpName": ""}
});


useEffect(() => {
  let chosenValuesData = { ...chosenValues};
   chosenValuesData['YearId'] = PackageYearList[PackageYearList.findIndex(PackageYear_List => PackageYear_List.YearId == props.formData.YearId)]; 
   chosenValuesData['SupCode'] = SupplierList[SupplierList.findIndex(Supplier_List => Supplier_List.SupCode == props.formData.SupCode)]; 
   chosenValuesData['PreparedBy'] = FacilityEmployee[FacilityEmployee.findIndex(FacilityEmployee_List => FacilityEmployee_List.EmpCode == props.formData.PreparedBy)]; 
   chosenValuesData['AppBy'] = FacilityEmployee[FacilityEmployee.findIndex(FacilityEmployee_List => FacilityEmployee_List.EmpCode == props.formData.AppBy)]; 
   chosenValuesData['ReturnBy'] = FacilityEmployee[FacilityEmployee.findIndex(FacilityEmployee_List => FacilityEmployee_List.EmpCode == props.formData.ReturnBy)]; 
   setChosenValues(chosenValuesData); 

}, [props.formData.YearId, props.formData.SupCode, props.formData.PreparedBy, props.formData.AppBy, props.formData.ReturnBy]);

const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  setChosenValues(chosenValuesData); 
  props.handleChangeChoosenProps(name, value);
};

//====== End Choosen Dropdown===========



  /* =====Start of Print Excel Many Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL +
      "report/return_to_the_supplier_from_provisioal_received.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TransactionId=" +
        props.formData.TransactionId +
        "&FacilityId=" +
        FacilityId +
        "&ReturnType=" +
        props.formData.ReturnType +
        "&TimeStamp=" +
        Date.now()
    );
  };

  const ExcelMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL +
      "report/esigl_receive_from_warehouse_entry_list_excel.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TransactionId=" +
        props.formData.TransactionId +
        "&FacilityId=" +
        FacilityId +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */
  
  const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;
      case "OrderModal":
        setOpenOrder(false);
        break;
      case "OrderOpen":
        setOpenOrder(true);
        break;

      case "SourceOpen":
        setSoureOpen(true);
        break;

      case "SourceClose":
        setSoureOpen(false);
        break;

      case "Contract":
        setContractOpen(false);
        break;
        
      case "ContractOpen":
        setContractOpen(true);
        break;

      default:
        break;
    }
  };

  const addProduct = (NonOrderProductData) => {

    let temNonordata = NonOrderProductData;
    let rows = [];

    let autoId = 0;
    temNonordata.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;

      newRow.ItemCode = row.ItemCode;

      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.UnitName = row.UnitName;
      newRow.DisApprovedQty = row.DisApprovedQty;
      newRow.SupDate = row.SupDate;
      newRow.pReceiveDate = row.pReceiveDate;
      newRow.ItemSl = row.ItemSl;
      newRow.ContractQty = row.ContractQty;
      newRow.MfgDate = row.MfgDate;

      newRow.SourceId = null;
      newRow.SourceName = null;

      newRow.TransactionItemId = null;
      newRow.OrderRemainingQty = 0;

      // manyTableData.forEach((rowssss, ij) => {
      //   if (rowssss.ItemNo == row.ItemNo) {
      //     newRow.OrderRemainingQty = rowssss.OrderRemainingQty;
      //     return;
      //   }
      // });

      newRow.CurrentStockQty = 0;
      newRow.RemQty = row.RemQty;
      newRow.BatchNo = row.BatchNo;
      newRow.SupRef = row.SupRef;
      newRow.ExpDate = row.ExpDate;
      newRow.Remarks = "";
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = 0;
      rows.push(newRow);
    });

    manyTableData.forEach((row, i) => {

      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;

      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.UnitName = row.UnitName;
      newRow.DisApprovedQty = row.DisApprovedQty;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.OrderRemainingQty = row.OrderRemainingQty;
      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.RemQty = row.RemQty;
      newRow.BatchNo = row.BatchNo;
      newRow.SupRef = row.SupRef;
      newRow.SupDate = row.SupDate;
      newRow.pReceiveDate = row.pReceiveDate;
      newRow.ItemSl = row.ItemSl;
      newRow.ContractQty = row.ContractQty;
      newRow.MfgDate = row.MfgDate;
      newRow.ExpDate = row.ExpDate;
      newRow.Remarks = row.Remarks;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = row.Quantity * row.UnitPrice;

      newRow.SourceId = row.SourceId;
      newRow.SourceName = row.SourceName;

      rows.push(newRow);
    });

    setManyTableData(rows);
    props.handleAdd(rows);
    handleClose("Products");
    api.dirtyModeEnable();
  };

  const addContract = (ContractData) => {
    props.handleAddContract(ContractData);
    handleClose("Contract");
    setcurrContractNo(ContractData[0]['ContractNo']);
    api.dirtyModeEnable();
  };

  const addSource = (productSourceData) => {
    if (!productSourceData) {
      return;
    }

    let tempProductSourceData = productSourceData[0];
    let rows = [];

    let autoId = Date.now(); // 0;

    manyTableData.forEach((row, i) => {

      autoId++;
      let newRow = {};

      if (row.SKU === currRowId) {
        newRow.SourceId = tempProductSourceData.SourceId;
        newRow.SourceName = tempProductSourceData.SourceName;
      } else {
        newRow.SourceId = row.SourceId;
        newRow.SourceName = row.SourceName;
      }

      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;

      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.OrderRemainingQty = row.OrderRemainingQty;
      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.RemQty = row.RemQty;
      newRow.BatchNo = row.BatchNo;
      newRow.SupRef = row.SupRef;
      newRow.SupDate = row.SupDate;
      newRow.pReceiveDate = row.pReceiveDate;
      newRow.ItemSl = row.ItemSl;
      newRow.ContractQty = row.ContractQty;
      newRow.MfgDate = row.MfgDate;
      newRow.ExpDate = row.ExpDate;
      newRow.Remarks = row.Remarks;
      newRow.SKU = row.SKU;
      newRow.id = row.id;
      newRow.LineTotal = row.Quantity * row.UnitPrice;

      rows.push(newRow);
    });

    setManyTableData(rows);
    props.handleAdd(rows);
    handleClose("SourceClose");
    api.dirtyModeEnable();
  };

  function ShowSourcePopup(propss: any) {
    const rowData = propss.cell._cell.row.data;

    if (props.bStockUpdate) {
      return <></>;
    } else {
      return (
        <>
          <ViewList
            onClick={() => {
              setCurrRowId(rowData.SKU);
              setCurrFormulation(rowData.SKU);
              handleClose("SourceOpen");
            }}
          />
        </>
      );
    }
  }

  
  const editCheck = function(cell){
    const rowData = cell._cell.row.data;
    return ( (rowData.DisApprovedQty == null) && (rowData.ApprovedQty == null)); // only allow the name cell to be edited if the age is over 18
  }


  const ReceiveDetailsColumns = [
    { title: "TransactionItemId", field: "TransactionItemId", visible: false },
    { title: "id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      width: 50,
      formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Sup Inv No"],
      field: "SupRef",
      width: 80,
      //cssClass: "tabluator-column-editable text-field-editable",
      //editor: cellFocusEditor,
      //editable:editCheck,
      /* cellEdited: function (cell) {
        //const rowData = props.cell._cell.row.data;
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        
      }, */
    },
    {
      title: DispensingLanguage[lan][menukey]["Sup Date"],
      field: "SupDate",
      width: 100,
      //cssClass: "tabluator-column-editable",
      //editor: props.bStockUpdate == 0 ? dateEditor : "",
      /* editor: dateEditor,
      editable:editCheck,
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
      }, */
    },
    {
      title: DispensingLanguage[lan][menukey]["Return Date"],
      field: "pReceiveDate",
      width: 100,
      //cssClass: "tabluator-column-editable",
      //editor: props.bStockUpdate == 0 ? dateEditor : "",
      //editor: (!permissionType)?dateEditorAllowPast:"",
      editor: (!permissionType)?cellFocusEditor:"",
      cssClass: "tabluator-column-editable text-field-editable",
      //editable:editCheck,
      /* cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
      }, */
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Code"],
      field: "ItemCode",
      width: 100,
      //headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Name"],
      field: "ItemName",
      minWidth: 200,
      //headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Returnable Qty"],
      field: "DisApprovedQty",
      width: 100,
      hozAlign: "right", headerHozAlign: "right", 
      formatter: "money",
      formatterParams:{ thousand:",",  precision:false },
      // headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Return Qty"],
      field: "RemQty",
      width: 90,
      hozAlign: "right",
      headerHozAlign: "right",
      validator: ["integer", "min:0"],
      cssClass: "tabluator-column-editable",
      //editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      editor: (!permissionType)?cellFocusEditor:"",
      //editable:editCheck,
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        // const pricecell = currow.getCell("LineTotal");
        // const totprice = rowdata.UnitPrice * curcellval;
        // pricecell.setValue(totprice);
      },
      formatter: "money",
      formatterParams:{ thousand:",",  precision:false },
    },
   
    {
      title: DispensingLanguage[lan][menukey]["Unit"],
      field: "UnitName",
      width: 80,
      // headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Lot No"],
      field: "BatchNo",
      width: 110,
      //cssClass: "tabluator-column-editable text-field-editable",
      //editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      /* editor: cellFocusEditor,
      editable:editCheck,
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
      }, */
    },
    {
      title: DispensingLanguage[lan][menukey]["Mfg. Date"],
      field: "MfgDate",
      width: 100,
     // cssClass: "tabluator-column-editable",
      //editor: props.bStockUpdate == 0 ? dateEditor : "",
      /* editor: dateEditor,
      editable:editCheck,
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
      }, */
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry Date"],
      field: "ExpDate",
      width: 100,
      //cssClass: "tabluator-column-editable",
      //editor: props.bStockUpdate == 0 ? dateEditor : "",
      /* editor: dateEditor,
      editable:editCheck,
      cellEdited: function (cell) {
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
      }, */
    },

    {
      title: DispensingLanguage[lan][menukey]["Remarks"],
      field: "Remarks",
      width: 100,
     cssClass: "tabluator-column-editable text-field-editable",
      editor: (!permissionType)?cellFocusEditor:"",
      //editable:editCheck,
     cellEdited: function (cell) {
        //const rowData = props.cell._cell.row.data;
        const curcellval = cell.getValue();
        const currow = cell.getRow();
        const rowdata = currow.getData();
        
      },
    },

    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      headerHozAlign: "center",
      hozAlign: "center",
      width: 80,
      formatter: reactFormatter(
        <ActionButton/>
      ),
    },
  ];



  // const onPostBtnClick = () => {
  //   setbStockUpdate(true);
  //   props.handlePost();
  // };

  const onSaveUpdateBtnClick = () => {
    // props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData());
    let manydata = ReceiveDetailsRef.table.getData();
    setUpdatedDetailsGridDataHandler(manydata);
  };

  const setUpdatedDetailsGridDataHandler = (rows) => {
    let validForm = props.checkValidateForm(rows,'edit');
    // console.log('isval: ', validForm);
      if (validForm) {

        // console.log('props.addProductForm: ', props.addProductForm);
        if(props.addProductForm){
          //when save
          mutate(validForm);
        }else{
          //when update
          mutateUpdate(validForm); 
        }
        

        
      }
  };
  
  
  const { isLoading: isLoadingSave, mutate } = useMutation(api.ReturnTotheSupplierFromProvisionalReceiveDataInsert, {
    onSuccess: (data) => { 
      if (data.status == 200) {

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      let route = `edit/${data.TransactionId}`;
      props.history.push(route); 
      
      }else{
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })  

  
  const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.ReturnTotheSupplierFromProvisionalReceiveDataUpdate, {
    onSuccess: (data) => {
      // console.log('mutateUpdate data: ', data);
      if (data.status == 200) { 
      //  console.log("props: ",props.formData.TransactionId);
        //setbStockUpdate(data.bStockUpdated);
        // props.successSave(data.bStockUpdated);
        // if(data.bStockUpdated==1){
        //  mutatePost(props.TransactionId);
        // }
        // else{
          // props.refetchInvoice(data.bStockUpdated); //refetch(); 
          props.refetchInvoice(); //refetch(); 
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
 
        // }

      }else{ 
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });











  const deleteReceiveInv = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["Do you really want to delete the selected item?"]),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willDelete) => {
      if (willDelete) {
        let newarray = manyTableData.filter(element => element !== data); 
        setManyTableData(newarray); 
        props.deleteReceiveInv(data); 
        api.dirtyModeEnable();
      }
    });
  };

  const CopyBatch = () => {
    props.handleAdd(manyTableData);
  };

  const onYearChangeEvnt = (e) => {
    setcurrYearId(e.target.value);
    
    props.handleChange(e);
  };

  function ActionButton(props) {

    const rowData = props.cell._cell.row.data;

    const goToDetails = () => {
      let rowobj = props.cell._cell.row.data; 

      timeStamp = Math.round(new Date().getTime() / 100);

      setManyTableData((prevRows) => {
        const tmpPrevRows = [...prevRows];

        let setRposition = tmpPrevRows.findIndex((o) => o.SKU == rowobj.SKU);

        const obj = { ...rowobj };

        obj.id = timeStamp;
        obj.TransactionItemId = "";
        //obj.ExpiryDate = "";
        //obj.BatchNo = "";
        //obj.SupRef = "";
        //obj.RemQty = 0;
        obj.SKU = Date.now();

        tmpPrevRows.splice(setRposition + 1, 0, obj);

        return tmpPrevRows;
      });

      // props.handleAdd(manyTableData);
      CopyBatch();
      setNewBatchRowAdded((prevNewBatchRowAdded) => !prevNewBatchRowAdded);
    };

   // const cellValue = props.cell._cell.value || "Edit | Show";
   
    //if (bStockUpdate == 0)
   
   if  (permissionType == 0)
      return (
        <>
          {/* <FileCopy
            onClick={() => {
              if (!bStockUpdate) goToDetails(rowData);
            }}
          /> */}

          <DeleteOutline
            onClick={() => {
              
              if (!bStockUpdate) deleteReceiveInv(rowData);
            }}
          />
        </>
      );
    else return <></>;
  }

  useEffect(() => {
    if (ReceiveDetailsRef == null) return;
    if (props.bFirst) {
      setManyTableData(props.ManyTableData);

     

    }


    setbStockUpdate(props.bStockUpdate);
  }, [props.ManyTableData, props.bStockUpdate]); 

  useEffect(() => {
    if (ReceiveDetailsRef.table != null && timeStamp != "") {
      ReceiveDetailsRef.table.scrollToRow(timeStamp, "center", true);
      ReceiveDetailsRef.table.selectRow(timeStamp);
    }
  }, [newBatchRowAdded]);

  
  const backtolist=()=>{


    let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
    let idx = master_dirty_message_class.indexOf("dnone");
    if(idx === -1){
      // start Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          props.history.push("/return-to-the-supplier-from-provisional-receive");
        }
      });
      // End Alert
    }else{
      props.history.push("/return-to-the-supplier-from-provisional-receive");
    }
  
  }

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Return to the Supplier from Provisional Receive - Add/Edit"]}
              </div>
 
              <div className="float-right sw_btn_control">
                {props.addProductForm ? (
                  <Grid item xs={12} className=" text-center">
                    <div className="float-right sw_btn_control">
                      <Button
                      disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => props.handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button>
                      <Button
                      disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onSaveUpdateBtnClick}
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                      disabled={isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        // onClick={() => props.history.push("/return-to-the-supplier-from-provisional-receive")}
                        onClick={() => backtolist()}
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="mt-2 text-center">
                    <Button
                      disabled={props.bStockUpdate || isLoadingUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    {/* <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onPostBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["post"]}
                    </Button> */}
                    <Button
                    disabled={isLoadingUpdate}
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      // onClick={() => props.history.push("/return-to-the-supplier-from-provisional-receive")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>

                    <ExportButtonsMany PrintMany={PrintMany}  {...props}></ExportButtonsMany>
                    {/* <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PrintMany()}
                    >
                      <i class="fas fa-print"></i>
                    </Button> */}

                    {/* <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelMany()}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button> */}
                  </Grid>
                )}
              </div>

                 
              <div className="mr-4 float-right mt-15">
                <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
              </div>

            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            {/* <Card className="sw_card">
            <CardHeader title="Personal Information" />
            <CardContent> */}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={true /*bStockUpdate*/}
                      error={props.errorObject.TransactionNo}
                      helperText={props.errorObject.TransactionNo}
                      required
                      id="TransactionNo"
                      name="TransactionNo"
                      label={
                        DispensingLanguage[lan][menukey]["Invoice No"]
                      }
                      value={
                        props.formData.TransactionNo || props.generatedInvoiceNo
                      }
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    {/* <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label-receive-from">
                        {DispensingLanguage[lan][menukey]["Supplier"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.SupCode}
                        labelId="demo-simple-select-helper-label-receive-from"
                        id="SupCode"
                        name="SupCode"
                        value={props.formData.SupCode}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={
                          manyTableData.length > 0 ? true : props.bStockUpdate
                        }
                      >
                        {SupplierList.map((item, index) => {
                          return (
                            <MenuItem value={item.SupCode}>{item.SupName}</MenuItem>
                          );
                        })}
                      </Select>

                      <FormHelperText error={props.errorObject.SupCode}>
                        {props.errorObject.SupCode}
                      </FormHelperText>
                    </FormControl> */}

                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            disabled={
                              manyTableData.length > 0 ? true : props.bStockUpdate
                            }
                            id="SupCode"
                            options={SupplierList}
                            onChange={(event, valueobj) => handleChangeChoosen('SupCode', valueobj, valueobj?valueobj.SupCode:"")}
                            getOptionLabel={(option) => option.SupName}
                            value={chosenValues['SupCode']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.SupName}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={props.formData.SupCode}
                              label={DispensingLanguage[lan][menukey]["Supplier"]+ " *"}
                              variant="standard"
                              error={props.errorObject.SupCode}
                              helperText={props.errorObject.SupCode}
                              id="SupCode"
                              name="SupCode"
                              fullWidth
                            />
                            )}
                          />

                        </FormControl>

                  </Grid>

                  
                  <Grid item xs={3} sm={3}>
                    {/* <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Prepared By"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.PreparedBy}
                        labelId="demo-simple-select-helper-label"
                        id="PreparedBy"
                        name="PreparedBy"
                        value={props.formData.PreparedBy}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={props.bStockUpdate}
                      >
                        {FacilityEmployee.map((item, index) => {
                          return (
                            <MenuItem value={item.EmpCode}>
                              {item.EmpName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={props.errorObject.PreparedBy}>
                        {props.errorObject.PreparedBy}
                      </FormHelperText>
                    </FormControl> */}

                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            hidden={!(props.formData.ReceivedBybActive || props.addProductForm)}
                            disabled={props.bStockUpdate}
                            id="PreparedBy"
                            options={FacilityEmployee}
                            onChange={(event, valueobj) => handleChangeChoosen('PreparedBy', valueobj, valueobj?valueobj.EmpCode:"")}
                            getOptionLabel={(option) => option.EmpName}
                            value={chosenValues['PreparedBy']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.EmpName}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={props.formData.PreparedBy}
                              label={DispensingLanguage[lan][menukey]["Prepared By"]+ " *"}
                              variant="standard"
                              error={props.errorObject.PreparedBy}
                              helperText={props.errorObject.PreparedBy}
                              id="PreparedBy"
                              name="PreparedBy"
                              fullWidth
                            />
                            )}
                          />

                        <TextField
                          id="ReceivedByName"
                          name="ReceivedByName"
                          label={DispensingLanguage[lan][menukey]["Prepared By"]+ " *"}
                          value={props.formData.ReceivedByName}
                          disabled= {true}
                          hidden={(props.formData.ReceivedBybActive || props.addProductForm)}
                          fullWidth
                          autoComplete="family-name"
                          
                        />

                        </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        required
                        error={props.errorObject.TransactionDate}
                        helperText={props.errorObject.TransactionDate}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        id="TransactionDate"
                        label={DispensingLanguage[lan][menukey]["Prepared Date"]}
                        autoOk={true}
                        name="TransactionDate"
                        fullWidth
                        showTodayButton={true}
                        disableFuture={true}
                        value={
                          props.formData.TransactionDate || null
                        }
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "TransactionDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>


                 

                  <Grid item xs={3} sm={3}>
                   {/*  <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Approved By"]} 
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="AppBy"
                        name="AppBy"
                        value={props.formData.AppBy}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={props.bStockUpdate}
                      >
                        {FacilityEmployee.map((item, index) => {
                          return (
                            <MenuItem value={item.EmpCode}>
                              {item.EmpName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl> */}

                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            hidden={!(props.formData.AppBybActive || props.addProductForm)}
                            disabled={props.bStockUpdate}
                            id="AppBy"
                            options={FacilityEmployee}
                            onChange={(event, valueobj) => handleChangeChoosen('AppBy', valueobj, valueobj?valueobj.EmpCode:"")}
                            getOptionLabel={(option) => option.EmpName}
                            value={chosenValues['AppBy']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.EmpName}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={props.formData.AppBy}
                              //label={DispensingLanguage[lan][menukey]["Approved By"]+ " *"}
                              label={DispensingLanguage[lan][menukey]["Approved By"]}
                              variant="standard"
                              error={props.errorObject.AppBy}
                              helperText={props.errorObject.AppBy}
                              id="AppBy"
                              name="AppBy"
                              fullWidth
                            />
                            )}
                          />

                          <TextField
                            id="AppByName"
                            name="AppByName"
                            label={DispensingLanguage[lan][menukey]["Approved By"]}
                            value={props.formData.AppByName}
                            disabled= {true}
                            hidden={(props.formData.AppBybActive || props.addProductForm)}
                            fullWidth
                            autoComplete="family-name"
                            
                          />

                        </FormControl>
                  </Grid>


                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        required
                        error={props.errorObject.AppDate}
                        helperText={props.errorObject.AppDate}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        id="AppDate"
                        label={DispensingLanguage[lan][menukey]["Date"]}
                        autoOk={true}
                        name="AppDate"
                        fullWidth
                        showTodayButton={true}
                        disableFuture={true}
                        value={
                          props.formData.AppDate || null
                        }
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "AppDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>


                  <Grid item xs={3} sm={3}>
                    {/* <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Issued By"]} 
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="ReturnBy"
                        name="ReturnBy"
                        value={props.formData.ReturnBy}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={props.bStockUpdate}
                      >
                        {FacilityEmployee.map((item, index) => {
                          return (
                            <MenuItem value={item.EmpCode}>
                              {item.EmpName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl> */}

                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            hidden={!(props.formData.ReturnBybActive || props.addProductForm)}
                            disabled={props.bStockUpdate}
                            id="ReturnBy"
                            options={FacilityEmployee}
                            onChange={(event, valueobj) => handleChangeChoosen('ReturnBy', valueobj, valueobj?valueobj.EmpCode:"")}
                            getOptionLabel={(option) => option.EmpName}
                            value={chosenValues['ReturnBy']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.EmpName}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={props.formData.ReturnBy}
                              //label={DispensingLanguage[lan][menukey]["Approved By"]+ " *"}
                              label={DispensingLanguage[lan][menukey]["Issued By"]}
                              variant="standard"
                              error={props.errorObject.ReturnBy}
                              helperText={props.errorObject.ReturnBy}
                              id="ReturnBy"
                              name="ReturnBy"
                              fullWidth
                            />
                            )}
                          />

                        <TextField
                          id="ReturnByName"
                          name="ReturnByName"
                          label={DispensingLanguage[lan][menukey]["Issued By"]}
                          value={props.formData.ReturnByName}
                          disabled= {true}
                          hidden={(props.formData.ReturnBybActive || props.addProductForm)}
                          fullWidth
                          autoComplete="family-name"
                          
                        />

                        </FormControl>

                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        required
                        error={props.errorObject.ReturnDate}
                        helperText={props.errorObject.ReturnDate}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        id="ReturnDate"
                        label={DispensingLanguage[lan][menukey]["Date"]}
                        autoOk={true}
                        name="ReturnDate"
                        fullWidth
                        showTodayButton={true}
                        disableFuture={true}
                        value={
                          props.formData.ReturnDate || null
                        }
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "ReturnDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>


                {/* Start Return Type Radio Button  */}

               
                  <Grid item xs={12} sm={3}>
                        <FormControl
                          className="sw_radiogroup"
                          component="fieldset"
                          //required
                          error={props.errorObject.ReturnType}
                          helperText={props.errorObject.ReturnType}
                        >
                          <FormLabel component="legend">
                            {DispensingLanguage[lan][menukey]["Return Type"]}
                          </FormLabel>
                          <RadioGroup
                            row
                            aria-label="ReturnType"
                            name="ReturnType"
                            value={props.formData.ReturnType}
                            onChange={props.handleChange}
                            
                          >
                            {ReturnTypeList.map((item, index) => {
                              return (
                                <FormControlLabel
                                disabled={
                                  manyTableData.length > 0 ? true : props.bStockUpdate
                                }
                                  value={item.id}
                                  control={<Radio />}
                                  label={item.name}
                                ></FormControlLabel>
                              );
                            })}
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                  {/* End Return Type Radio Button  */}

                  <Grid item xs={2} sm={2}>
                    {/* <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Year"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.YearId}
                        labelId="demo-simple-select-helper-label"
                        id="YearId"
                        name="YearId"
                        value={props.formData.YearId}
                        fullWidth
                        onChange={(e) => onYearChangeEvnt(e)}
                        //disabled={props.bStockUpdate}
                        disabled={
                          manyTableData.length > 0 ? true : props.bStockUpdate
                        }
                      >
                        {PackageYearList.map((item, index) => {
                          return (
                            <MenuItem value={item.YearId}>
                              {item.PackageYear}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={props.errorObject.YearId}>
                        {props.errorObject.YearId}
                      </FormHelperText>
                    </FormControl> */}

                        <FormControl className={classes.fullWidth}>
                          <Autocomplete
                            autoHighlight
                            disabled={
                              manyTableData.length > 0 ? true : props.bStockUpdate
                            }
                            id="YearId"
                            options={PackageYearList}
                            onChange={(event, valueobj) => handleChangeChoosen('YearId', valueobj, valueobj?valueobj.YearId:"")}
                            getOptionLabel={(option) => option.PackageYear}
                            value={chosenValues['YearId']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.PackageYear}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={props.formData.YearId}
                              label={DispensingLanguage[lan][menukey]["Year"]+ " *"}
                              variant="standard"
                              error={props.errorObject.YearId}
                              helperText={props.errorObject.YearId}
                              id="YearId"
                              name="YearId"
                              fullWidth
                            />
                            )}
                          />

                        </FormControl>
                  </Grid>

                  {/* <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={true }
                      id="PackageName"
                      name="PackageName"
                      label={DispensingLanguage[lan][menukey]["Package No"]}
                      value={props.formData.PackageName}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid> */}

                  <Grid item xs={5} sm={5}  className="sw_relative">
                    <TextField
                      required
                      error={props.errorObject.ContractName}
                      helperText={props.errorObject.ContractName}
                      disabled={true /*props.bStockUpdate*/}
                      id="ContractName"
                      name="ContractName"
                      label={DispensingLanguage[lan][menukey]["Contract No"]}
                      value={props.formData.ContractName}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />

                    <Button
                      //disabled={props.bStockUpdate}
                      className="pick_btn_place"
                      variant="contained"
                      color="success"
                      disabled={
                        manyTableData.length > 0 ? true : props.bStockUpdate
                      }
                      onClick={() => handleClose("ContractOpen")}
                    >
                      ...
                    </Button>

                    <ContractModalByYear
                      handleClose={handleClose}
                      open={openContract}
                      yearid={currYearId == '' ? props.formData.YearId : currYearId} 
                      addContract={addContract}
                      {...props}
                    />

                  </Grid>
                   {/* <Grid item xs={1} sm={1}>
                    <ContractModalByYear
                      handleClose={handleClose}
                      open={openContract}
                      yearid={currYearId == '' ? props.formData.YearId : currYearId} 
                      addContract={addContract}
                      {...props}
                    />
                  </Grid> */}

                  <Grid item xs={2} sm={2}>
                    <TextField
                      disabled={props.bStockUpdate}
                      id="Remarks"
                      name="Remarks"
                      label={DispensingLanguage[lan][menukey]["Remarks"]}
                      value={props.formData.Remarks}
                      fullWidth
                      multiline
                      rows={3}
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={1} sm={1}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="TransactionId"
                      name="TransactionId"
                      label="TransactionId"
                      value={props.formData.TransactionId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                {/* new row */}
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card">
                    <CardContent>
                      <Grid container className="mb-2">
                        <Grid item xs={12} sm={12}>
                          <div className="float-right sw_btn_control">
                            {/* <Button
                                  className="mr-2"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleClose("OrderOpen")}
                                >
                                  SELECT FROM ORDER
                                </Button>  */}

                            <Button
                              //disabled={props.bStockUpdate}
                              //disabled={ props.bStockUpdate || (props.formData.TransactionId > 0 ? false : true) }
                              disabled={ props.bStockUpdate || (props.formData.ContractNo > 0 ? false : true) }
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              onClick={() => handleClose("ProductsOpen")}
                            >
                              {
                                DispensingLanguage[lan][menukey]["Select Return Items"]
                              }
                            </Button>
                          </div>

                          <ProductsModal
                            handleClose={handleClose}
                            open={open}
                            ContractNo={currContractNo == '' ? props.formData.ContractNo : currContractNo} 
                            addProduct={addProduct}
                            Ts={Date.now()}
                            ReturnType={props.formData.ReturnType}
                            {...props}
                          />

                          <SourceModal
                            handleClose={handleClose}
                            open={SoureOpen}
                            addProduct={addSource}
                            {...props}
                          />
                        </Grid>
                      </Grid>
                      <ReactTabulator
                        ref={(r) => (ReceiveDetailsRef = r)}
                        columns={ReceiveDetailsColumns}
                        data={manyTableData}
                        height="350px"
                        layout={"fitColumns"}
                      />
                    </CardContent>
                  </Card>
                </Grid>

                {/* new row */}
              </Grid>
            </Grid>

            {/* </CardContent>
          </Card> */}
          </Grid>

          {/* Action buttons */}
        </Grid>
      </div>
    </div>
  );
};

export default MasterManyFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
