import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";

import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import IndentorFormData from "./IndentorFormData";

const AddIndentor = (props) => {
  console.log(props.pieceOfState);

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "indentor";

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;

  const [formData, setFormData] = useState({
    IndentorName: "",
    OPDescription: ""
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const [open, setOpen] = React.useState(false);
  const [Massage, setMassage] = React.useState(false);

  useQuery(
    ["indentor", id],
    () =>
      api.getIndentor(id).then((res) => {
        setFormData(res.data);
      }),
    {
      enabled: Boolean(id),
    }
  );

  const { isLoading, mutate } = useMutation(api.saveIndentor, {
    onSuccess: (data) => {
      console.log("data: ", data);

      if (data.status == 200) {

        const auth_token = sessionStorage.getItem("token")
        ? sessionStorage.getItem("token")
        : null;
        let options = {};
        options = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${auth_token}`,
          },
          task:'UOM'
        };


        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        queryClient.getQueriesData("indentor"); //api page name
        props.history.push("/indentor"); //
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        queryClient.getQueriesData("indentor"); //api page name
        props.history.push("/indentor"); //
      }
    },
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    console.log("formData: ", formData);
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
    api.dirtyModeEnable();
  };

  const handleReset = () => {
    setFormData({
      IndentorName: "",
      OPDescription: ""
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["IndentorName","OPDescription"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const handleSubmit = async (e) => {
    console.log("from submit ", formData);
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        menukey: menukey,
        lan: lan,
        UserName: UserName,
      };
      mutate(fDtat);
    }
  };

  return (
    <>
      <IndentorFormData
        errorObject={errorObject}
        addNationalSupplierForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        bStockUpdate={props.permissionType}
        open={open}
        {...props}
      />
    </>
  );
};

export default AddIndentor;
