import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel,
  RadioGroup,
  MenuItem,
  Paper,
  CardHeader,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "custom-supply-plan";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ProfileModal = ({ handleClose, open, addProfile, ...props }) => {
  let tblRef = null;

  const [selectedContracts, setSelectedContracts] = useState([]);
  
  const [profileName, setProfileName] = React.useState('');

const handleChange = (e) => {
  const { name, value } = e.target;
  console.log('name, value: ', name, value);
   
  setProfileName(value);
};


  const SaveProfileName = () => {
    if(profileName){
      addProfile(profileName);
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={() => handleClose('ProfileModalClose')}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="xs"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('ProfileModalClose')}
        >
        {DispensingLanguage[lan][menukey]['Profile']} 
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {/* <ReactTabulator
            ref={(r) => (tblRef = r)}
            options={{
              selectableRangeMode: 'click',
             }}
            selectable={1}
            columns={ContractColumns}
            data={ContractDataItems}
            height="300px"
            layout={"fitColumns"}

          /> */}
          {/* <Grid item xs={3} sm={3}> */}
            <TextField
              // disabled={true /*bStockUpdate*/}
              // error={props.errorObject.TransactionNo}
              // helperText={props.errorObject.TransactionNo}
              required
              id="ProfileName"
              name="ProfileName"
              label={
                DispensingLanguage[lan][menukey]["Profile Name"]
              }
              // value={
              //   props.formData.TransactionNo || props.generatedInvoiceNo
              // }
              fullWidth
              autoComplete="family-name"
              onChange={(e) => handleChange(e)}
            />
          {/* </Grid> */}
          
        </DialogContent>
        <DialogActions>
          <Button
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={SaveProfileName}
          >
           {DispensingLanguage[lan][menukey]['Save']}  
          </Button>
          <Button
            autoFocus
            className="float-left"
            variant="contained" 
            onClick={() => handleClose('ProfileModalClose')}
          >
            {DispensingLanguage[lan][menukey]['Close']}   
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ProfileModal;
