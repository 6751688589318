import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel,
  RadioGroup,
  MenuItem,
  Paper,
  FormGroup,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  FormHelperText,
} from "@material-ui/core";


import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import * as api from "../../../actions/api";
//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "product-group";



const ProductGroupFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();

  const queryClient = useQueryClient();


  const backtolist=()=>{


    let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
    let idx = master_dirty_message_class.indexOf("dnone");
    if(idx === -1){
      // start Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          props.history.push("/product-group");
        }
      });
      // End Alert
    }else{
      props.history.push("/product-group");
    }
  
  }


  const onSaveUpdateBtnClick = () => {

    setUpdatedDetailsGridDataHandler();
  };
  
  
  const setUpdatedDetailsGridDataHandler = () => {
    let validForm = props.checkValidateForm();
   
    // console.log('isval: ', validForm);
      if (validForm) {
  
        if(addProductForm){
          //when save
          mutate(validForm);
          
        }else{
          //when update
          mutateUpdate(validForm); 
        }
        
  
        
      }
  };

  
  const { isLoading : isLoadingSave, mutate } = useMutation(api.saveProductGroup, {
    onSuccess: (data) => {
      if (data.status == 200) {
        api.getAllDropdown('ProductGroup').then((response) => {
          localStorage.setItem(
            "ProductGroup",
            JSON.stringify(response.datalist.ProductGroup)
          );
        }) 
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
       // swal(data.data.message, "", "success");
      queryClient.getQueriesData("productgroup");//api page name
      props.history.push("/product-group");//

      }else{   
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.upProductGroup, {
    onSuccess: (data) => {
      if (data.status == 200) {

        api.getAllDropdown('ProductGroup').then((response) => {
          localStorage.setItem(
            "ProductGroup",
            JSON.stringify(response.datalist.ProductGroup)
          );
        }) 
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        }); 
        //swal(data.data.message, "", "success");
        queryClient.getQueriesData("productgroup");//api page name
        props.history.push("/product-group");//
        api.dirtyModeDisable();
  
        }else{   
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
    },
  });



  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        

        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title"> {DispensingLanguage[lan][menukey]['Product Group Entry Form - Add/Edit']} </div>
            </div>
            <div className="float-right sw_btn_control">

              {addProductForm ? (
                <Grid item xs={12} className="text-center tex_margin">
                  <Button
                    disabled={props.bStockUpdate}
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => handleReset()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Reset'])}
                  </Button>
                  <Button
                    disabled={props.bStockUpdate || isLoadingSave}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClick}
                    // onClick={() => handleSubmit()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Save'])}
                  </Button>
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    //onClick={() => props.history.push("/entry-edit-of-products")}
                    onClick={() => backtolist()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Back to List'])}
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={12} className="mt-2 text-center">
                  <Button
                    disabled={props.bStockUpdate || isLoadingUpdate}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClick}
                    // onClick={() => handleUpdate()}
                  >
                    {t(DispensingLanguage[lan][menukey]['update'])}
                  </Button>
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    //onClick={() => props.history.push("/entry-edit-of-products")}
                    onClick={() => backtolist()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Back to List'])}
                  </Button>
                </Grid>
              )}



            </div>

            <div className="mr-4 float-right mt-15">
              <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
            </div>

          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={10}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title= {DispensingLanguage[lan][menukey]['Product Group Entry Form'] }/>
              <CardContent>
              <Grid container spacing={10}>
                  <Grid item xs={4} sm={12}>
                    <Grid container spacing={3}>
                        
                        <Grid item xs={6} sm={6}>
                        <TextField
                          error={errorObject.GroupCode}
                          helperText={errorObject.GroupCode}
                          required
                          disabled={props.permissionType}
                          id="GroupCode"
                          name="GroupCode"
                          label={DispensingLanguage[lan][menukey]["Group Code"]}
                          value={formData.GroupCode}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                        <br></br>
                        <br></br>
                        </Grid>
                                <Grid item xs={6} sm={6}>
                                  <TextField
                                    error={errorObject.GroupName}
                                    helperText={errorObject.GroupName}
                                    disabled={props.permissionType}
                                    required
                                    id="GroupName"
                                    name="GroupName"
                                    label={DispensingLanguage[lan][menukey]['Product Group Name'] }
                                    // value={formData.FirstName || "Mahmudul"}
                                    value={formData.GroupName}
                                    fullWidth
                                    autoComplete="family-name"
                                    onChange={(e) => handleChange(e)}
                                  />
                                  <br></br>
                                  <br></br>
                                  
                                </Grid>
                                
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          
          
          
        </Grid>
      </div>
    </div>
  );
};

export default ProductGroupFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
