import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar";
import {checkLogin} from "../../../services/CheckUserAccess";
import swal from "sweetalert";
// user screens
import Admin from "./Admin";
import AdminRoles from "./AdminRoles";

const Index = (props) => {
  const { path } = useRouteMatch();

  React.useEffect(() => {
    checkLogin();
    checkAccess();
    // props.history.push("/home");
  }, []);

/*   const checkLogin = () =>{
    let token = sessionStorage.getItem("token");

    if(!token){
      swal("Oops!", `token expired. Please login again`, "error");
      props.history.push("/login");
    }
  } */

  const checkAccess = () => {
    let auth = JSON.parse(sessionStorage.getItem("User_info"));
    if (auth) {
      let menu = auth[0].menu;
      if(menu.length>0){
        var check = menu.filter(name => name.title.includes('Admin'));
        if(check.length == 0){
         props.history.push(menu[0].url);
        }

    }else{
      swal("Oops!", `You don't have permission to access any page`, "error");
      props.history.push("/login");
      sessionStorage.clear();
    }

    }
  };


  return (
    <div>
      <AfterLoginNavbar {...props} />
      <div
        className="section signup-top-padding"
        style={{
          backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          minHeight: "753px",
        }}
      >
        <Switch>
          <Route path={`${path}/roles`} exact component={AdminRoles}></Route>
          <Route path={`${path}/`} component={Admin}></Route>
        </Switch>
      </div>
    </div>
  );
};

export default Index;
