import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel,
  RadioGroup,
  MenuItem,
  Paper,
  CardHeader,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Card,
  CardContent,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { useQuery, useMutation, useQueryClient } from "react-query";
//import * as api from "../actions/api";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "sdplist";
const SDPGroupList = JSON.parse(localStorage.getItem("SDPGroupList"));

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ProductsModal = ({ handleClose, open, addSOReason, ...props }) => {

const classes = useStyles();

const [dataItems, setDataItems] = useState([]);

const [FGroupCodeData, selectFGroupCodeData] = useState(SDPGroupList[0].id);
const FacilityId = localStorage.getItem('FacilityId');
const UseFor = localStorage.getItem("UseFor");

const handleChange = (event) => {
  selectFGroupCodeData(event.target.value);
};


let params = {
  menukey: menukey,
  FacilityId: FacilityId,
  action: "getSDPSerialList",
  page_limit: JSON.stringify(100),
  page_number: JSON.stringify(0 + 1),
  search_key: "",
  FGroupCodeData: FGroupCodeData,
  UseFor: UseFor,
}; 

  let tblRef = null;

  
  const { error, isError, data, refetch } = useQuery(
    [params],
    api.SdpList,
    {
      onSuccess: (data) => {
        setDataItems(data);
      },
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 0
  }
  ); 



/*  const { isLoading, error, isError, data, refetch } = useQuery(
    "SdpList",
    api.getSDPSerialList
  ); 
 */
  
  const [selectedProducts, setselectedProducts] = useState([]);


  function RowInputData(props, type = null) {
    const rowData = props.cell._cell.row.data;
    let fieldName = props.field;
    let fieldType = props.fieldType;
   // let labelName = props.labelName;
   // let dropDownKey = props.dropDownKey;

    switch (fieldType) {
      case "check-box":
        return rowData.type == "bDispensing" ? (
          <></>
        ) : (
          <Checkbox
            color="primary"
            checked={rowData[fieldName]}
            name={fieldName}
            value={rowData[fieldName]}
          />
        );
        break;

      default:
        return <></>;
        break;
    }
    return <></>;
  }




  const ProductColumns = [
    {rowHandle:true, formatter:"handle", headerSort:false, frozen:true, width:30, minWidth:30},
    { title: DispensingLanguage[lan][menukey]['SDP Code'], field: "FacilityCode", width:110 },
    { title: DispensingLanguage[lan][menukey]['SDP Name'], field: "FacilityName" },
    //{ title: DispensingLanguage[lan][menukey]['Union Name'], field: "UnionName",  width:140,  headerFilter:true },
    {
      title: DispensingLanguage[lan][menukey]["Active"],
      field: "bShow",
      hozAlign: "center",
      headerHozAlign: "center",
      width:80,
      headerSort: false,
      formatter: reactFormatter(
        <RowInputData
          labelName="bDispensing"
          field="bShow"
          fieldType="check-box"
        />
      ),
    },

  ];

  let selected = [];
  const ProdustDataItems = data?.map((item, index) => { 
    return item;
  });


/*   const SaveSelectedProducts = () => {
    if(tblRef.table.getSelectedData().length>0){
    console.log(tblRef.table.getSelectedData());
    setselectedProducts(tblRef.table.getSelectedData());
    }
  }; */

  
  const { isLoadingLang, mutate:SDPOrderUpdate } = useMutation(api.updateSDPShortOrder, {
    onSuccess: (data) => {
      //props.history.push("/sptList-entry");
      selectFGroupCodeData(SDPGroupList[0].id);
      refetch();
      handleClose('SOReasons');
      
      if (data.data.status == 200) {
       
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
        
       
        }else{   
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
      }


    }
  })


  const SaveSelectedOrder = () => { 
    let SDPData = tblRef.table.getData();
    SDPOrderUpdate({SDPData});
    setselectedProducts(SDPData);
    
  };

  useEffect(() => { 
    if (tblRef == null) return;
    addSOReason(selectedProducts);

}, [selectedProducts]); 


const options={
  headerSort:false,
  movableRows:true,
  groupBy: "UnionName",
 
 }


  return (
    <div>


      
      <BootstrapDialog
        onClose={() => handleClose('SOReasons')}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="md"
      >
        
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('SOReasons')}
        >
        {DispensingLanguage[lan][menukey]['SDP List Shorting']} 
        </BootstrapDialogTitle>




      {/* start of filter */}
     
      <Grid container>
              <Grid item xs={4} sm={4}>  </Grid>
                  <Grid item xs={4} sm={4}>
                      <FormControl className={classes.fullWidth}>
                        <InputLabel id="">
                          {DispensingLanguage[lan][menukey]["SDP Group"]} 
                        </InputLabel>
                        <Select
                          labelId="FGroupCode"
                          id="FGroupCode"
                          name="FGroupCode"
                          value={FGroupCodeData}
                          fullWidth
                          onChange={handleChange}
                        >
                          
                          {SDPGroupList.map((item, index) => {
                            return (
                              <MenuItem value={item.id}>{item.name}</MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                  </Grid>
                  <Grid item xs={4} sm={4}></Grid>
                  
          </Grid>
        
      {/* end of filter */}



        <DialogContent dividers>
       
          <ReactTabulator
            ref={(r) => (tblRef = r)}
            options={options}
            columns={ProductColumns}
            data={ProdustDataItems}
            height="450px"
            layout={"fitColumns"}
            rowMoved={(row)=>{
              console.log("row moved: ",row.getData().FacilityName);    
             
              
             }}
          />
 
        </DialogContent>
        <DialogActions>
          <Button
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={SaveSelectedOrder}
          >
           {DispensingLanguage[lan][menukey]['Save']}  
          </Button>
          <Button
            autoFocus
            className="float-left"
            variant="contained" 
            onClick={() => handleClose('SOReasons')}
          >
            {DispensingLanguage[lan][menukey]['Close']}   
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ProductsModal;


const useStyles = makeStyles({

  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
