import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import swal from "sweetalert";
import * as api from "../../../actions/api";
import {
  Grid,
  Typography,
  TextField,
  Button,
  FormControl,
  Card,
  CardContent,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useQuery, useMutation, useQueryClient } from "react-query";
import { DeleteOutline, Edit, ViewList, FileCopy } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import ExportButtonsMany from "../../../components/ExportButtonsMany";
//react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import { useTranslation } from "react-i18next";
import ProductsModal from "../../../services/InvoiceModal";
import SourceModal from "../../../services/SourceModal";
import moment from "moment";
let timeStamp = "";

//errorObject, addProductForm, formData, handleChange, handleCheck, handleReset, handleSubmit, handleUpdate, ManyTableData,ManyJsonSave,handleRDateChange,selectedDate,handleAdd,handleRefech,
const DispenserFormData = (props) => {
  const permissionType = props.permissionType;
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "gate-pass";
  const FacilityId = localStorage.getItem("FacilityId");

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  let GatePassDetailsRef = null;

  const [newBatchRowAdded, setNewBatchRowAdded] = useState(false);

  const [open, setOpen] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);

  const [SoureOpen, setSoureOpen] = useState(false);

  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);

  const OrderTo = JSON.parse(localStorage.getItem("OrderTo"));

  // const UsersListByFacility = JSON.parse(localStorage.getItem("UsersListByFacility"));
  const FacilityEmployee = JSON.parse(localStorage.getItem("FacilityEmployee"));

  const [currRowId, setCurrRowId] = useState(0);
  const [currFormulation, setCurrFormulation] = useState("");
  const [bIsDisable, setbIsEnable] = useState(false);


  
   //====== Start Choosen Dropdown===========
   const [chosenValues, setChosenValues] = useState({
    "ApproveBy": {"EmpCode": "","EmpName": ""},
    "PreparedBy": {"EmpCode": "","EmpName": ""}
  });


useEffect(() => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData['ApproveBy'] = FacilityEmployee[FacilityEmployee.findIndex(facilityEmployee_list => facilityEmployee_list.EmpCode == props.formData.ApproveBy)]; 
  chosenValuesData['PreparedBy'] = FacilityEmployee[FacilityEmployee.findIndex(facilityEmployee_list => facilityEmployee_list.EmpCode == props.formData.PreparedBy)]; 

  setChosenValues(chosenValuesData); 

}, [props.formData.ApproveBy, props.formData.PreparedBy]);

const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  setChosenValues(chosenValuesData); 
  props.handleChangeChoosenProps(name, value);
};

//====== End Choosen Dropdown===========


  /* =====Start of Print Excel Many Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL +
      "report/gate_pass_print.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TransactionId=" +
        props.formData.TransactionId +
        "&FacilityId=" +
        FacilityId +
        "&TimeStamp=" +
        Date.now()
    );
  };

  const ExcelMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL +
      "report/esigl_receive_from_warehouse_entry_list_excel.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TransactionId=" +
        props.formData.TransactionId +
        "&FacilityId=" +
        FacilityId +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;
      case "OrderModal":
        setOpenOrder(false);
        break;
      case "OrderOpen":
        setOpenOrder(true);
        break;

      case "SourceOpen":
        setSoureOpen(true);
        break;

      case "SourceClose":
        setSoureOpen(false);
        break;

      default:
        break;
    }
  };

  const addProduct = (NonOrderProductData) => {
    console.log("NonOrderProductData: ", NonOrderProductData);
    // return;

    let temNonordata = NonOrderProductData;
    let rows = [];

    let autoId = 0;
    

    manyTableData.forEach((row, i) => {
  
      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;
      newRow.FacilityCode = row.FacilityCode;

      newRow.InvNo = row.InvNo;
      newRow.FacilityName = row.FacilityName;
      //newRow.UnitName = row.UnitName;
      newRow.TransactionItemId = row.TransactionItemId;
      //newRow.OrderRemainingQty = row.OrderRemainingQty;
     // newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.InvType = row.InvType;
      newRow.InvTypeName = row.InvTypeName;
      //newRow.ExpiryDate = row.ExpiryDate;
     // newRow.SKU = autoId;
      newRow.id = autoId;
      //newRow.LineTotal = row.Quantity * row.UnitPrice;

     // newRow.SourceId = row.SourceId;
      //newRow.SourceName = row.SourceName;

      rows.push(newRow);
    });



    temNonordata.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;

      newRow.FacilityCode = row.FacilityCode;

      newRow.InvNo = row.InvNo;
      newRow.InvType = row.InvType;
      newRow.InvTypeName = row.InvTypeName;
      newRow.FacilityName = row.FacilityName;
     // newRow.UnitName = row.UnitName;

      newRow.SourceId = null;
      newRow.SourceName = null;

      newRow.TransactionItemId = null;
      //newRow.OrderRemainingQty = 0;

      console.log("manyTableData: ", manyTableData);

     manyTableData.forEach((rowssss, ij) => {
        if (rowssss.ItemNo == row.ItemNo) {
          newRow.OrderRemainingQty = rowssss.OrderRemainingQty;
          return;
        }
      }); 

      newRow.CurrentStockQty = 0;
      //newRow.InvType = "";
      //newRow.InvTypeName = "";
      newRow.ExpiryDate = null;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = 0;
      rows.push(newRow);
    });

    setManyTableData(rows);
    props.handleAdd(rows);
    handleClose("Products");
    api.dirtyModeEnable();
  };

  const addSource = (productSourceData) => {
    if (!productSourceData) {
      return;
    }

    // console.log("rows before pick product: ", manyTableData);
    // console.log("Product batch selected data: ", productBatchData);
    // console.log("Product batch selected currRowId: ", currRowId);

    let tempProductSourceData = productSourceData[0];
    let rows = [];

    let autoId = Date.now(); // 0;

    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};

      if (row.SKU === currRowId) {
        // if(row.TransactionItemId===null)
        // {
        //   //currFormulation
        // }
        // console.log("This is my row: ", row);
        // console.log("This is my row: ", temProductBatchData);
        newRow.SourceId = tempProductSourceData.SourceId;
        newRow.SourceName = tempProductSourceData.SourceName;
      } else {
        newRow.SourceId = row.SourceId;
        newRow.SourceName = row.SourceName;
      }

      newRow.ItemNo = row.ItemNo;
      newRow.FacilityCode = row.FacilityCode;

      newRow.InvNo = row.InvNo;
      newRow.FacilityName = row.FacilityName;
      newRow.TransactionItemId = row.TransactionItemId;
      //newRow.OrderRemainingQty = row.OrderRemainingQty;
     // newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.InvType = row.InvType;
      newRow.InvTypeName = row.InvTypeName;
      //newRow.ExpiryDate = row.ExpiryDate;
      //newRow.SKU = row.SKU;
      newRow.id = row.id;
     // newRow.LineTotal = row.Quantity * row.UnitPrice;

      // newRow.SourceId =row.SourceId;
      //newRow.SourceName =row.SourceName;

      rows.push(newRow);
    });

    // console.log("rows after link with product: ", rows);

    setManyTableData(rows);
    props.handleAdd(rows);
    //productAdd(rows);
    handleClose("SourceClose");
    api.dirtyModeEnable();
  };

  function ShowSourcePopup(propss: any) {
    const rowData = propss.cell._cell.row.data;

    if (props.bStockUpdate) {
      return <></>;
    } else {
      return (
        <>
          <ViewList
            onClick={() => {
              setCurrRowId(rowData.SKU);
              setCurrFormulation(rowData.SKU);
              handleClose("SourceOpen");
            }}
          />
        </>
      );
    }
  }

  const GatePassDetailsColumns = [
    { title: "TransactionItemId", field: "TransactionItemId", visible: false },
    { title: "id", field: "id", visible: false },
    { title: "InvType", field: "InvType", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      width: 70,
      formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
    },


    {
      title: DispensingLanguage[lan][menukey]["Invoice No"],
      field: "InvNo",
      width: 130,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Invoice Type"],
      field: "InvTypeName",
      width: 200,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility Name"],
      field: "FacilityName",
      minWidth: 200,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      headerHozAlign: "center",
      hozAlign: "center",
      width: 80,
      formatter: reactFormatter(
        <ActionButton
        /* insertBatchInUpdatedDetailGridData={props.insertBatchInUpdatedDetailGridData} */
        />
      ),
    }
 

   
  ];


  // const onPostBtnClick = () => {
  //   setbStockUpdate(true);
  //   props.handlePost();
  // };

  const onSaveUpdateBtnClick = () => {
    // props.setUpdatedDetailsGridDataHandler(GatePassDetailsRef.table.getData());
    let manydata = GatePassDetailsRef.table.getData();
    setUpdatedDetailsGridDataHandler(manydata);
  };


  const setUpdatedDetailsGridDataHandler = (rows) => {
    let validForm = props.checkValidateForm(rows,'edit');
    // console.log('isval: ', validForm);
      if (validForm) {

        // console.log('props.addProductForm: ', props.addProductForm);
        if(props.addProductForm){
          //when save
          mutate(validForm);
        }else{
          //when update
          mutateUpdate(validForm); 
        }
        

        
      }
  };
  
  
  const { isLoading: isLoadingSave, mutate } = useMutation(api.saveGatePassInv, {
    onSuccess: (data) => { 
      if (data.status == 200) {

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      let route = `edit/${data.TransactionId}`;
      props.history.push(route); 
      
      }else{
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })  

  
  const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.updateGatePassInv, {
    onSuccess: (data) => {
      // console.log('mutateUpdate data: ', data);
      if (data.status == 200) { 
      //  console.log("props: ",props.formData.TransactionId);
        //setbStockUpdate(data.bStockUpdated);
        // props.successSave(data.bStockUpdated);
        // if(data.bStockUpdated==1){
        //  mutatePost(props.formData.TransactionId);
        // }
        // else{
          // props.refetchInvoice(data.bStockUpdated); //refetch(); 
          props.refetchInvoice(); //refetch(); 
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
 
        // }

      }else{ 
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  });





















  const deleteGatePassInv = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["Do you really want to delete the selected item?"]),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willDelete) => {
      if (willDelete) {
        let newarray = manyTableData.filter(element => element !== data); 
        setManyTableData(newarray); 
        props.deleteGatePassInv(data);
        api.dirtyModeEnable();
      }
    });
  };

  const CopyBatch = () => {
    props.handleAdd(manyTableData);
  };

  function ActionButton(props) {

    const rowData = props.cell._cell.row.data;

    const goToDetails = () => {
      let rowobj = props.cell._cell.row.data; //{};
      // console.log("I copied this line", rowobj);
      //console.log("I copied this line", props.cell._cell.row);

      timeStamp = Math.round(new Date().getTime() / 100);

      setManyTableData((prevRows) => {
        const tmpPrevRows = [...prevRows];

        let setRposition = tmpPrevRows.findIndex((o) => o.SKU == rowobj.SKU);
        // console.log("Rposition", setRposition);

        const obj = { ...rowobj };

        obj.id = timeStamp;
        obj.TransactionItemId = "";
        obj.ExpiryDate = "";
        obj.InvTypeName = "";
        obj.InvType = "";
        obj.SKU = Date.now();

        // console.log("new object added in grid:", obj);
        tmpPrevRows.splice(setRposition + 1, 0, obj);

        return tmpPrevRows;
      });

      // props.handleAdd(manyTableData);
      CopyBatch();
      setNewBatchRowAdded((prevNewBatchRowAdded) => !prevNewBatchRowAdded);
    };

    const cellValue = props.cell._cell.value || "Edit | Show";
  /*   if (bStockUpdate == 0)
      return (
        <>
          <FileCopy
            onClick={() => {
              if (!bStockUpdate) goToDetails(rowData);
            }}
          />

          <DeleteOutline
            onClick={() => {
              if (!bStockUpdate) deleteGatePassInv(rowData);
            }}
          />
        </>
      ); */
      // disabled={props.bStockUpdate}

      if (permissionType == 0)
        return (
          <>
            {/* <FileCopy
              onClick={() => {
              goToDetails(rowData);
              }}
            /> */}

            <DeleteOutline
              onClick={() => {
                deleteGatePassInv(rowData);
              }}
            />
          </>
        );
      else return <></>;



    ;
  }

  useEffect(() => {
    if (GatePassDetailsRef == null) return;
    if (props.bFirst) {
      setManyTableData(props.ManyTableData);
    }

    setbStockUpdate(props.bStockUpdate);
  }, [props.ManyTableData, props.bStockUpdate]); //

  useEffect(() => {
    if (GatePassDetailsRef.table != null && timeStamp != "") {
      // console.log("Many table", ReceiveDetailsRef.table);
      // console.log("Many table 12222222222");

      GatePassDetailsRef.table.scrollToRow(timeStamp, "center", true);
      GatePassDetailsRef.table.selectRow(timeStamp);
    }
  }, [newBatchRowAdded]);

  const handleReset = () => {
    props.handleReset();
    setManyTableData([]);
  };

  
  const backtolist=()=>{


    let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
    let idx = master_dirty_message_class.indexOf("dnone");
    if(idx === -1){
      // start Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          props.history.push("/gate-pass");
        }
      });
      // End Alert
    }else{
      props.history.push("/gate-pass");
    }

  }


  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Gate Pass - Add/Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {props.addProductForm ? (
                  <Grid item xs={12} className=" text-center">
                    <div className="float-right sw_btn_control">
                      <Button
                       disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button>
                      <Button
                       disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onSaveUpdateBtnClick}
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                       disabled={isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        // onClick={() => props.history.push("/gate-pass")}
                        onClick={() => backtolist()}
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="mt-2 text-center">
                    <Button
                      disabled={props.bStockUpdate || isLoadingUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    {/* <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onPostBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["post"]}
                    </Button> */}
                    <Button
                    disabled={isLoadingUpdate}
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      // onClick={() => props.history.push("/gate-pass")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>

                    <ExportButtonsMany PrintMany={PrintMany}  {...props}></ExportButtonsMany>
                    {/* <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PrintMany()}
                    >
                      <i class="fas fa-print"></i>
                    </Button> */}

                    {/* <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelMany()}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button> */}
                  </Grid>
                )}
              </div>

              <div className="mr-4 float-right mt-15">
                <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
              </div>

            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            {/* <Card className="sw_card">
            <CardHeader title="Personal Information" />
            <CardContent> */}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={true /*bStockUpdate*/}
                      error={props.errorObject.TransactionNo}
                      helperText={props.errorObject.TransactionNo}
                      required
                      id="TransactionNo"
                      name="TransactionNo"
                      label={
                        DispensingLanguage[lan][menukey]["Gate Pass No"]
                      }
                     
                      value={
                        props.formData.TransactionNo || props.generatedInvoiceNo
                      }
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        required
                        error={props.errorObject.TransactionDate}
                        helperText={props.errorObject.TransactionDate}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        id="TransactionDate"
                        label={DispensingLanguage[lan][menukey]["Gate Pass Date"]}
                        autoOk={true}
                        name="TransactionDate"
                        fullWidth
                        showTodayButton={true}
                        disableFuture={true}
                        value={
                          props.formData.TransactionDate || null
                        }
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "TransactionDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  

                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={props.bStockUpdate}
                      //error={props.errorObject.DriverName}
                      //helperText={props.errorObject.DriverName}
                      id="DriverName"
                      name="DriverName"
                      label={
                        DispensingLanguage[lan][menukey][
                          "Driver Name"
                        ]
                      }
                      value={props.formData.DriverName}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={props.bStockUpdate}
                     // error={props.errorObject.VehicleNo}
                      //helperText={props.errorObject.VehicleNo}
                      id="VehicleNo"
                      name="VehicleNo"
                      label={DispensingLanguage[lan][menukey]["Vehicle No"]}
                      value={props.formData.VehicleNo}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    {/* <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Prepared By"]}
                      </InputLabel>
                      <Select
                       // error={props.errorObject.PreparedBy}
                        labelId="demo-simple-select-helper-label"
                        id="PreparedBy"
                        name="PreparedBy"
                        value={props.formData.PreparedBy}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={props.bStockUpdate}
                      >
                        {FacilityEmployee.map((item, index) => {
                          return (
                            <MenuItem value={item.EmpCode}>
                              {item.EmpName}
                            </MenuItem>
                          );
                        })}
                      </Select>

                    </FormControl> */}


                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        disableClearable
                        hidden={!(props.formData.PrepBybActive || props.addProductForm)}
                        disabled={props.bStockUpdate}
                        id="PreparedBy"
                        options={FacilityEmployee}
                        onChange={(event, valueobj) => handleChangeChoosen('PreparedBy', valueobj, valueobj?valueobj.EmpCode:"")}
                        getOptionLabel={(option) => option.EmpName}
                        value={chosenValues['PreparedBy']}
                        renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.EmpName}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          value={props.formData.PreparedBy}
                          label={DispensingLanguage[lan][menukey]["Prepared By"]}
                          variant="standard"
                          id="PreparedBy"
                          name="PreparedBy"
                          fullWidth
                        />
                        )}
                      />

                      <TextField
                        id="PreparedByName"
                        name="PreparedByName"
                        label={DispensingLanguage[lan][menukey]["Prepared By"]}
                        value={props.formData.PreparedByName}
                        disabled= {true}
                        hidden={(props.formData.PrepBybActive || props.addProductForm)}
                        fullWidth
                        autoComplete="family-name"
                        
                      />

                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    {/* <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label-gate-pass-from">
                        {DispensingLanguage[lan][menukey]["Approved By"]}
                      </InputLabel>
                      <Select
                       // error={props.errorObject.ApproveBy}
                        labelId="demo-simple-select-helper-label-gate-pass-from"
                        id="ApproveBy"
                        name="ApproveBy"
                        value={props.formData.ApproveBy}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                       
                      >
                        {FacilityEmployee.map((item, index) => {
                          return (
                            <MenuItem value={item.EmpCode}>
                              {item.EmpName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl> */}

                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        disableClearable
                        hidden={!(props.formData.ApproveBybActive || props.addProductForm)}
                        disabled={props.bStockUpdate}
                        id="ApproveBy"
                        options={FacilityEmployee}
                        onChange={(event, valueobj) => handleChangeChoosen('ApproveBy', valueobj, valueobj?valueobj.EmpCode:"")}
                        getOptionLabel={(option) => option.EmpName}
                        value={chosenValues['ApproveBy']}
                        renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.EmpName}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          value={props.formData.ApproveBy}
                          label={DispensingLanguage[lan][menukey]["Approved By"]}
                          variant="standard"
                          id="ApproveBy"
                          name="ApproveBy"
                          fullWidth
                        />
                        )}
                      />

                      <TextField
                        id="ApproveByName"
                        name="ApproveByName"
                        label={DispensingLanguage[lan][menukey]["Approved By"]}
                        value={props.formData.ApproveByName}
                        disabled= {true}
                        hidden={(props.formData.ApproveBybActive || props.addProductForm)}
                        fullWidth
                        autoComplete="family-name"
                        
                      />

                    </FormControl>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="TransactionId"
                      name="TransactionId"
                      label="TransactionId"
                      value={props.formData.TransactionId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                {/* new row */}
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card">
                    <CardContent>
                      <Grid container className="mb-2">
                        <Grid item xs={12} sm={12}>
                          <div className="float-right sw_btn_control">
                            {/* <Button
                                  className="mr-2"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleClose("OrderOpen")}
                                >
                                  SELECT FROM ORDER
                                </Button>  */}

                            <Button
                              disabled={props.bStockUpdate}
                             // disabled={ props.bStockUpdate || (props.formData.TransactionId > 0 ? false : true) }
                              //disabled={ props.formData.TransactionId > 0 ? false : true }
                             
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              onClick={() => handleClose("ProductsOpen")}
                            >
                              {
                                DispensingLanguage[lan][menukey][
                                  "Select Invoice"
                                ]
                              }
                            </Button>
                          </div>

                          <ProductsModal
                            handleClose={handleClose}
                            open={open}
                            addProduct={addProduct}
                            {...props}
                          />

                          <SourceModal
                            handleClose={handleClose}
                            open={SoureOpen}
                            addProduct={addSource}
                            {...props}
                          />
                        </Grid>
                      </Grid>
                      <ReactTabulator
                        ref={(r) => (GatePassDetailsRef = r)}
                        columns={GatePassDetailsColumns}
                        data={manyTableData}
                        height="350px"
                        layout={"fitColumns"}
                      />
                    </CardContent>
                  </Card>
                </Grid>

                {/* new row */}
              </Grid>
            </Grid>

            {/* </CardContent>
          </Card> */}
          </Grid>

          {/* Action buttons */}
        </Grid>
      </div>
    </div>
  );
};

export default DispenserFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
