import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import swal from "sweetalert";
import * as api from "../../../actions/api";
import {
  Typography,
  Grid,
  FormControlLabel,
  TextField,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useQuery, useMutation, useQueryClient } from "react-query";
import { DeleteOutline, Edit, ViewList, FileCopy } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { useTranslation } from "react-i18next";
import ProductsModal from "../../../services/ProductsModal";
import SourceModal from "../../../services/SourceModal";
import ContractModal from "../../../services/ContractModal";

import {
  cellFocusEditor,
  dateEditor,
  dateEditorAllowPast,
  dateEditorAllowFuture,
} from "../../../services/Common";
import ExportButtonsMany from "../../../components/ExportButtonsMany";

import MomentUtils from "@date-io/moment";
import moment from "moment";
let timeStamp = "";

const DispenserFormData = (props) => {

  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "receive-from-supplier";
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  let ReceiveDetailsRef = null;

  const [newBatchRowAdded, setNewBatchRowAdded] = useState(false);

  const [open, setOpen] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);

  const [SoureOpen, setSoureOpen] = useState(false);

  const [openContract, setContractOpen] = useState(false);

  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);
  const SupplierList = JSON.parse(localStorage.getItem("SupplierListNational"));
  const FacilityEmployee = JSON.parse(localStorage.getItem("FacilityEmployee"));
  const IndentorList = JSON.parse(localStorage.getItem("IndentorList"));

  const [currRowId, setCurrRowId] = useState(0);
  const [currFormulation, setCurrFormulation] = useState("");
  const [bIsDisable, setbIsEnable] = useState(false);

  //====== Start Choosen Dropdown===========
   const [chosenValues, setChosenValues] = useState({
      "SupCode": {"SupCode": "", "SupName": "" },
      "IndentorId": {"IndentorId": "","IndentorName": ""},
      "PreparedBy": {"EmpCode": "","EmpName": ""}
    });
 

  useEffect(() => {
    let chosenValuesData = { ...chosenValues};
    chosenValuesData['SupCode'] = SupplierList[SupplierList.findIndex(supplier_list => supplier_list.SupCode == props.formData.SupCode)];   
    chosenValuesData['IndentorId'] = IndentorList[IndentorList.findIndex(indentor_list => indentor_list.IndentorId == props.formData.IndentorId)]; 
    chosenValuesData['PreparedBy'] = FacilityEmployee[FacilityEmployee.findIndex(facilityEmployee_list => facilityEmployee_list.EmpCode == props.formData.PreparedBy)]; 
    setChosenValues(chosenValuesData); 

  }, [props.formData.SupCode, props.formData.IndentorId, props.formData.PreparedBy]);

  const handleChangeChoosen = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues};
    chosenValuesData[name] = valueobj;   
    setChosenValues(chosenValuesData); 
    props.handleChangeChoosenProps(name, value);
  };

  //====== End Choosen Dropdown===========

  /* =====Start of Print Excel Many Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL + "report/receive_from_supplier_entry_list_print.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TransactionId=" +
        props.formData.TransactionId +
        "&FacilityId=" +
        FacilityId +
        "&FacilityName=" +
        FacilityName +
        "&TimeStamp=" +
        Date.now()
    );
  };

  const ExcelMany = () => {
    let finalUrl =
      EXCEL_EXPORT_URL +
      "report/esigl_receive_from_warehouse_entry_list_excel.php";

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&TransactionId=" +
        props.formData.TransactionId +
        "&FacilityId=" +
        FacilityId +
        "&TimeStamp=" +
        Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;
      case "OrderModal":
        setOpenOrder(false);
        break;
      case "OrderOpen":
        setOpenOrder(true);
        break;

      case "SourceOpen":
        setSoureOpen(true);
        break;

      case "SourceClose":
        setSoureOpen(false);
        break;

      case "Contract":
        setContractOpen(false);
        break;

      case "ContractOpen":
        setContractOpen(true);
        break;

      default:
        break;
    }
  };

  const addProduct = (NonOrderProductData) => {
    // console.log("NonOrderProductData: ", NonOrderProductData);
    // return;

    let temNonordata = NonOrderProductData;
    let rows = [];

    let autoId = 0;
    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;

      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.UnitName = row.UnitName;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.OrderRemainingQty = row.OrderRemainingQty;
      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.Quantity = row.Quantity;
      newRow.BatchNo = row.BatchNo;
      newRow.MfgDate = row.MfgDate;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = row.Quantity * row.UnitPrice;

      newRow.SourceId = row.SourceId;
      newRow.SourceName = row.SourceName;

      rows.push(newRow);
    });

    temNonordata.forEach((row, i) => {
      autoId++;
      let newRow = {};
      newRow.ItemNo = row.ItemNo;

      newRow.ItemCode = row.ItemCode;

      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.UnitName = row.UnitName;

      newRow.SourceId = null;
      newRow.SourceName = null;

      newRow.TransactionItemId = null;
      newRow.OrderRemainingQty = 0;

      // manyTableData.forEach((rowssss, ij) => {
      //   if (rowssss.ItemNo == row.ItemNo) {
      //     newRow.OrderRemainingQty = rowssss.OrderRemainingQty;
      //     return;
      //   }
      // });

      newRow.CurrentStockQty = 0;
      newRow.Quantity = null;
      newRow.BatchNo = null;
      newRow.MfgDate = null;
      newRow.ExpiryDate = null;
      newRow.SKU = autoId;
      newRow.id = autoId;
      newRow.LineTotal = 0;
      rows.push(newRow);
    });

    setManyTableData(rows);
    props.handleAdd(rows);
    handleClose("Products");
    api.dirtyModeEnable();
  };

  const addContract = (ContractData) => {
    // console.log("ContractData: ", ContractData);

    // let row = ContractData;
    // let rows = [];

    // let newRow = {};
    // newRow.ContractName = row[0].ContractName;
    // newRow.ContractNo = row[0].ContractNo;
    // newRow.PackageName = row[0].PackageName;
    // newRow.PackageNo = row[0].PackageNo;
    // newRow.PackageYear = row[0].PackageYear;
    // newRow.YearId = row[0].YearId;
    // rows.push(newRow);

    // console.log(ContractData);
    // console.log(rows);

    // setManyTableData(rows);
    props.handleAddContract(ContractData);
    handleClose("Contract");
    api.dirtyModeEnable();

    
  };

  const addSource = (productSourceData) => {
    if (!productSourceData) {
      return;
    }

    // console.log("rows before pick product: ", manyTableData);
    // console.log("Product batch selected data: ", productBatchData);
    // console.log("Product batch selected currRowId: ", currRowId);

    let tempProductSourceData = productSourceData[0];
    let rows = [];

    let autoId = Date.now(); // 0;

    manyTableData.forEach((row, i) => {
      autoId++;
      let newRow = {};

      if (row.SKU === currRowId) {
        // if(row.TransactionItemId===null)
        // {
        //   //currFormulation
        // }
        // console.log("This is my row: ", row);
        // console.log("This is my row: ", temProductBatchData);
        newRow.SourceId = tempProductSourceData.SourceId;
        newRow.SourceName = tempProductSourceData.SourceName;
      } else {
        newRow.SourceId = row.SourceId;
        newRow.SourceName = row.SourceName;
      }

      newRow.ItemNo = row.ItemNo;
      newRow.ItemCode = row.ItemCode;

      newRow.ItemName = row.ItemName;
      newRow.UnitPrice = row.UnitPrice;
      newRow.TransactionItemId = row.TransactionItemId;
      newRow.OrderRemainingQty = row.OrderRemainingQty;
      newRow.CurrentStockQty = row.CurrentStockQty;
      newRow.Quantity = row.Quantity;
      newRow.BatchNo = row.BatchNo;
      newRow.ExpiryDate = row.ExpiryDate;
      newRow.SKU = row.SKU;
      newRow.id = row.id;
      newRow.LineTotal = row.Quantity * row.UnitPrice;

      // newRow.SourceId =row.SourceId;
      //newRow.SourceName =row.SourceName;

      rows.push(newRow);
    });

    // console.log("rows after link with product: ", rows);

    setManyTableData(rows);
    props.handleAdd(rows);
    //productAdd(rows);
    handleClose("SourceClose");
    api.dirtyModeEnable();

  };

  function ShowSourcePopup(propss: any) {
    const rowData = propss.cell._cell.row.data;

    if (props.bStockUpdate) {
      return <></>;
    } else {
      return (
        <>
          <ViewList
            onClick={() => {
              setCurrRowId(rowData.SKU);
              setCurrFormulation(rowData.SKU);
              handleClose("SourceOpen");
            }}
          />
        </>
      );
    }
  }

  const ReceiveDetailsColumns = [
    { title: "TransactionItemId", field: "TransactionItemId", visible: false },
    { title: "id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      width: 70,
      formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
    },

    {
      title: DispensingLanguage[lan][menukey]["Item Code"],
      field: "ItemCode",
      width: 130,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Name"],
      field: "ItemName",
      headerFilter: true,
      minWidth: 200,
    },
    {
      title: DispensingLanguage[lan][menukey]["Qty"],
      field: "Quantity",
      width: 100,
      hozAlign: "right",
      headerHozAlign: "right",
      validator: ["integer", "min:0"],
      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      cellEdited: function (cell) {
        // const curcellval = cell.getValue();
        // const currow = cell.getRow();
        // const rowdata = currow.getData();
        // const pricecell = currow.getCell("LineTotal");
        // const totprice = rowdata.UnitPrice * curcellval;
        // pricecell.setValue(totprice);
      },
      formatter: "money",
      formatterParams: { thousand: ",", precision: false },
    },
    {
      title: DispensingLanguage[lan][menukey]["Unit"],
      field: "UnitName",
      width: 80,
      // headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Lot No"],
      field: "BatchNo",
      width: 150,
      cssClass: "tabluator-column-editable text-field-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      // cellEdited: function (cell) {
      //   const curcellval = cell.getValue();
      //   const currow = cell.getRow();
      //   const rowdata = currow.getData();
      //   //   props.setUpdatedDetailsGridDataHandler(rowdata);
      // },
    },
    {
      title: DispensingLanguage[lan][menukey]["Mfg. Date"],
      field: "MfgDate",
      width: 100,
      cssClass: "tabluator-column-editable text-field-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      // cssClass: "tabluator-column-editable",
      // editor: props.bStockUpdate == 0 ? dateEditorAllowPast : "",
      // cellEdited: function (cell) {
      //   const curcellval = cell.getValue();
      //   const currow = cell.getRow();
      //   const rowdata = currow.getData();
      // },
    },
    {
      title: DispensingLanguage[lan][menukey]["Expiry Date"],
      field: "ExpiryDate",
      width: 100,
      cssClass: "tabluator-column-editable text-field-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      // editor: props.bStockUpdate == 0 ? dateEditorAllowFuture : "",
      // cellEdited: function (cell) {
      //   const curcellval = cell.getValue();
      //   const currow = cell.getRow();
      //   const rowdata = currow.getData();
      // },
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      headerHozAlign: "center",
      hozAlign: "center",
      width: 80,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  // const onPostBtnClick = () => {
  //   setbStockUpdate(true);
  //   props.handlePost();
  // };

  const onPostBtnClick = () => {
    ///===================Check qty is blank of any item==========/////////////
    if (ReceiveDetailsRef == null) return;

    let blankinfo = {};
    ReceiveDetailsRef.table.getData().forEach((row, i) => {
      if (row.Quantity === null || row.Quantity === "") {
        blankinfo = row;
      }
    });

    if (Object.keys(blankinfo).length > 0) {
      props.openNoticeModal({
        isOpen: true,
        msg:
          t(DispensingLanguage[lan][menukey]["Blank Quantity is not allowed"]) +
          " - " +
          blankinfo.ItemName,
        msgtype: false,
      });
      return;
    }
    ///===================Check qty is blank of any item==========/////////////


    ///===================Check duplicate lots exist in invoice==========/////////////
    // if (ReceiveDetailsRef == null) return;

    let invoiceLotIds = [];
    let duplicateinfo = {};
    ReceiveDetailsRef.table.getData().forEach((row, i) => {
      let uktempid =
        row.ItemCode +
        "_" +
        row.BatchNo +
        "_" +
        row.MfgDate +
        "_" +
        row.ExpiryDate;
      // console.log("uktempid: ", uktempid);
      if (invoiceLotIds.includes(uktempid)) {
        duplicateinfo = row;
      } else {
        invoiceLotIds.push(uktempid);
      }
    });

    if (Object.keys(duplicateinfo).length > 0) {
      props.openNoticeModal({
        isOpen: true,
        msg:
          t(DispensingLanguage[lan][menukey]["Duplicate Lot available"]) +
          " - " +
          duplicateinfo.ItemName,
        msgtype: false,
      });
      return;
    }
    ///===================Check duplicate lots exist in invoice==========/////////////

    //setbStockUpdate(true);
    // props.handlePost();
    handlePost();
  };

  const handlePost = async (e) => {
    let validForm = props.checkValidateForm(
      ReceiveDetailsRef.table.getData(),
      "post"
    );
    // console.log('isval: ', validForm);
    // console.log('props from post: ', props);

    // if (validateForm(formData)) {
    if (validForm) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey][
            "Do you really want to post the stock?"
          ]
        ),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true, */
      }).then((willAction) => {
        if (willAction) {
          let fDtat = { ...validForm, bStockUpdated: 1 };
          // console.log('fDtat: ', fDtat);
          mutateUpdate(fDtat);
        } else {
          // setbStockUpdate(0);
          props.stockPosted(0);
          // let route = `${TransactionId}`;
          let route = `${validForm.TransactionId}`;
          props.history.push(route);
        }
      });
      // End Post Alert
    }
  };

  const onUnPostBtnClick = () => {
    setbStockUpdate(true);
    props.handleUnPost();
  };

  const onSaveUpdateBtnClick = () => {
    // props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData());
    let manydata = ReceiveDetailsRef.table.getData();
    setUpdatedDetailsGridDataHandler(manydata);
  };

  const setUpdatedDetailsGridDataHandler = (rows) => {
    let validForm = props.checkValidateForm(rows, "edit");
    // console.log('isval: ', validForm);
    if (validForm) {
      // console.log('props.addProductForm: ', props.addProductForm);
      if (props.addProductForm) {
        //when save
        mutate(validForm);
      } else {
        //when update
        mutateUpdate(validForm);
      }
    }
  };

  const { isLoading: isLoadingSave, mutate } = useMutation(
    api.ReceiveFromSupplierDataInsert,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });

          let route = `edit/${data.TransactionId}`;
          props.history.push(route);
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      },
    }
  );

  const { isLoading: isLoadingUpdate, mutate: mutateUpdate } = useMutation(
    api.ReceiveFromSupplierDataUpdate,
    {
      onSuccess: (data) => {
        // console.log('mutateUpdate data: ', data);
        if (data.status == 200) {
          //  console.log("props: ",props.formData.TransactionId);
          //setbStockUpdate(data.bStockUpdated);
          // props.successSave(data.bStockUpdated);
          if (data.bStockUpdated == 1) {
            mutatePost(props.formData.TransactionId);
          } else {
            // props.refetchInvoice(data.bStockUpdated); //refetch();
            props.refetchInvoice(); //refetch();
            props.openNoticeModal({
              isOpen: true,
              msg: data.message,
              msgtype: data.success,
            });
          }
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      },
    }
  );

  const { isLoading: isLoadingPost, mutate: mutatePost } = useMutation(
    api.postReceiveFromSupplier,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          // setDeletedDataSet([]);

          if (data.data.success == 0) {
            //setbStockUpdate(0);
            props.stockPosted(0);
            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });
          } else {
            // setbStockUpdate(1);
            props.stockPosted(1);
            props.refetchInvoice();

            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });
          }
        } else {
          // setbStockUpdate(0);
          props.stockPosted(0);
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
        }
      },
    }
  );

  const invoiceCancel = (e) => {
    const { name, checked } = e.target;
    let validForm = props.checkValidateForm(
      ReceiveDetailsRef.table.getData(),
      "cancel"
    );

    if (validForm) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text:
          t(DispensingLanguage[lan][menukey]["Do you really want to cancel?"]) +
          " " +
          t(
            DispensingLanguage[lan][menukey][
              "You will not able to edit this invoice after cancel"
            ]
          ),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true, */
      }).then((willCancel) => {
        if (willCancel) {
          // let fDtat={...formData,'ManyJsonSave':ManyTableData,"BCancel":1,"TableName":"recinv",'deletedDataSet':deletedDataSet};
          let fDtat = { ...validForm, BCancel: 1, TableName: "recinv" };
          mutateUpdate(fDtat);
        } else {
          //setFormData({ ...formData, [name]: false });
        }
      });
    }
  };

  const deleteReceiveInv = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Do you really want to delete the selected item?"
        ]
      ),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willDelete) => {
      if (willDelete) {
        let newarray = manyTableData.filter((element) => element !== data);
        setManyTableData(newarray);
        props.deleteReceiveInv(data);
        api.dirtyModeEnable();
      }
    });
  };

  const CopyBatch = () => {
    props.handleAdd(manyTableData);
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;

    const goToDetails = () => {
      let rowobj = props.cell._cell.row.data;

      timeStamp = Math.round(new Date().getTime() / 100);

      setManyTableData((prevRows) => {
        const tmpPrevRows = [...prevRows];

        let setRposition = tmpPrevRows.findIndex((o) => o.SKU == rowobj.SKU);

        const obj = { ...rowobj };

        obj.id = timeStamp;
        obj.TransactionItemId = "";
        obj.ExpiryDate = "";
        obj.BatchNo = "";
        obj.Quantity = 0;
        obj.SKU = Date.now();

        tmpPrevRows.splice(setRposition + 1, 0, obj);

        return tmpPrevRows;
      });

      // props.handleAdd(manyTableData);
      CopyBatch();
      setNewBatchRowAdded((prevNewBatchRowAdded) => !prevNewBatchRowAdded);
    };

    const cellValue = props.cell._cell.value || "Edit | Show";

    if (bStockUpdate == 0)
      return (
        <>
          {/* <FileCopy
            onClick={() => {
              if (!bStockUpdate) goToDetails(rowData);
            }}
          /> */}

          <DeleteOutline
            onClick={() => {
              if (!bStockUpdate) deleteReceiveInv(rowData);
            }}
          />
        </>
      );
    else return <></>;
  }

  useEffect(() => {
    if (ReceiveDetailsRef == null) return;
    if (props.bFirst) {
      setManyTableData(props.ManyTableData);
    }

    setbStockUpdate(props.bStockUpdate);
  }, [props.ManyTableData, props.bStockUpdate]);

  useEffect(() => {
    if (ReceiveDetailsRef.table != null && timeStamp != "") {
      ReceiveDetailsRef.table.scrollToRow(timeStamp, "center", true);
      ReceiveDetailsRef.table.selectRow(timeStamp);
    }
  }, [newBatchRowAdded]);

  
  const backtolist=()=>{


    let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
    let idx = master_dirty_message_class.indexOf("dnone");
    if(idx === -1){
      // start Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          props.history.push("/receive-from-supplier");
        }
      });
      // End Alert
    }else{
      props.history.push("/receive-from-supplier");
    }

  }

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {
                  DispensingLanguage[lan][menukey][
                    "Receive From Supplier - Add/Edit"
                  ]
                }
              </div>

              <div className="float-right sw_btn_control">
                {props.addProductForm ? (
                  <Grid item xs={12} className="text-center">
                    <div className="float-right sw_btn_control">
                      <Button
                        disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => props.handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button>
                      <Button
                        disabled={props.bStockUpdate || isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onSaveUpdateBtnClick}
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        disabled={isLoadingSave}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        // onClick={() =>props.history.push("/receive-from-supplier")}
                        onClick={() => backtolist()}
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="mt-2 text-center">
                    <Button
                      disabled={
                        props.bStockUpdate || isLoadingUpdate || isLoadingPost
                      }
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    <Button
                      // disabled={props.bStockUpdate}
                      disabled={
                        props.bStockUpdate ||
                        !(manyTableData.length > 0) ||
                        isLoadingUpdate ||
                        isLoadingPost
                      }
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onPostBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["post"]}
                    </Button>

                    {/* <Button
                      disabled={!props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onUnPostBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["Unpost"]}
                    </Button> */}

                    <Button
                      disabled={isLoadingUpdate || isLoadingPost}
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      // onClick={() => props.history.push("/receive-from-supplier")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>

                    <ExportButtonsMany PrintMany={PrintMany}  {...props}></ExportButtonsMany>
                    {/* <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PrintMany()}
                    >
                      <i class="fas fa-print"></i>
                    </Button> */}

                    {/* <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelMany()}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button> */}
                  </Grid>
                )}
              </div>

              <div className="mr-4 float-right mt-15">
                <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
              </div>


            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            {/* <Card className="sw_card">
            <CardHeader title="Personal Information" />
            <CardContent> */}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={true /*bStockUpdate*/}
                      error={props.errorObject.TransactionNo}
                      helperText={props.errorObject.TransactionNo}
                      required
                      id="TransactionNo"
                      name="TransactionNo"
                      label={DispensingLanguage[lan][menukey]["Invoice No"]}
                      value={
                        props.formData.TransactionNo || props.generatedInvoiceNo
                      }
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        required
                        error={props.errorObject.TransactionDate}
                        helperText={props.errorObject.TransactionDate}
                        className={classes.fullWidth}
                        //disableToolbar
                        variant="inline"
                        id="TransactionDate"
                        label={DispensingLanguage[lan][menukey]["Receive Date"]}
                        autoOk={true}
                        name="TransactionDate"
                        fullWidth
                        showTodayButton={true}
                        disableFuture={true}
                        value={props.formData.TransactionDate || null}
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "TransactionDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    {/* <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Receive By"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.PreparedBy}
                        labelId="demo-simple-select-helper-label"
                        id="PreparedBy"
                        name="PreparedBy"
                        value={props.formData.PreparedBy}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={props.bStockUpdate}
                      >
                        {FacilityEmployee.map((item, index) => {
                          return (
                            <MenuItem value={item.EmpCode}>
                              {item.EmpName}
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={props.errorObject.PreparedBy}>
                        {props.errorObject.PreparedBy}
                      </FormHelperText>
                    </FormControl> */}

                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        hidden={props.bStockUpdate}
                        disabled={props.bStockUpdate}
                        autoHighlight
                        id="PreparedBy"
                        options={FacilityEmployee}
                        onChange={(event, valueobj) => handleChangeChoosen('PreparedBy', valueobj, valueobj?valueobj.EmpCode:"")}
                        getOptionLabel={(option) => option.EmpName}
                        value={chosenValues['PreparedBy']}
                        renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.EmpName}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          value={props.formData.PreparedBy}
                          label={DispensingLanguage[lan][menukey]["Receive By"]+ " *"}
                          variant="standard"
                          error={props.errorObject.PreparedBy}
                          helperText={props.errorObject.PreparedBy}
                          id="PreparedBy"
                          name="PreparedBy"
                          fullWidth
                        />
                        )}
                      />

                      <TextField
                        id="PreparedByName"
                        name="PreparedByName"
                        label={DispensingLanguage[lan][menukey]["Receive By"]+ " *"}
                        value={props.formData.PreparedByName}
                        disabled= {true}
                        hidden={!props.bStockUpdate}
                        fullWidth
                        autoComplete="family-name"
                        
                        />

                    </FormControl>
                  </Grid>

                  {/* <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label-receive-from">
                        {DispensingLanguage[lan][menukey]["Supplier"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.SupCode}
                        labelId="demo-simple-select-helper-label-receive-from"
                        id="SupCode"
                        name="SupCode"
                        value={props.formData.SupCode}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={
                          manyTableData.length > 0 ? true : props.bStockUpdate
                        }
                      >
                        {SupplierList.map((item, index) => {
                          return (
                            <MenuItem value={item.SupCode}>
                              {item.SupName}
                            </MenuItem>
                          );
                        })}
                      </Select>

                      <FormHelperText error={props.errorObject.SupCode}>
                        {props.errorObject.SupCode}
                      </FormHelperText>
                    </FormControl>
                  </Grid> */}


                  <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          disabled={
                            manyTableData.length > 0 ? true : props.bStockUpdate
                          }
                          autoHighlight
                          id="SupCode"
                          options={SupplierList}
                          onChange={(event, valueobj) => handleChangeChoosen('SupCode', valueobj, valueobj?valueobj.SupCode:"")}
                          getOptionLabel={(option) => option.SupName}
                         // value={acValue}
                          value={chosenValues['SupCode']}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.SupName}</Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={props.formData.SupCode}
                              label={DispensingLanguage[lan][menukey]["Supplier"]+ " *"}
                              variant="standard"
                              error={props.errorObject.SupCode}
                              helperText={props.errorObject.SupCode}
                              id="SupCode"
                              name="SupCode"
                              fullWidth
                            />
                          )}
                        />

                      </FormControl>

                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={props.bStockUpdate}
                      error={props.errorObject.SupplierInvNo}
                      helperText={props.errorObject.SupplierInvNo}
                      id="SupplierInvNo"
                      name="SupplierInvNo"
                      label={
                        DispensingLanguage[lan][menukey]["Sup Inv No"] + " *"
                      }
                      value={props.formData.SupplierInvNo}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        error={props.errorObject.SupplierInvDate}
                        helperText={props.errorObject.SupplierInvDate}
                        className={classes.fullWidth}
                        // disableToolbar
                        variant="inline"
                        id="SupplierInvDate"
                        label={
                          DispensingLanguage[lan][menukey]["Supplier Date"] +
                          " *"
                        }
                        autoOk={true}
                        name="SupplierInvDate"
                        fullWidth
                        showTodayButton={true}
                        disableFuture={true}
                        value={props.formData.SupplierInvDate || null}
                        format="dd/MM/yyyy"
                        onChange={(e) =>
                          props.handleRDateChange(e, "SupplierInvDate")
                        }
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={props.bStockUpdate}
                      id="BLNo"
                      name="BLNo"
                      label={DispensingLanguage[lan][menukey]["BL No"]}
                      value={props.formData.BLNo}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <MuiPickersUtilsProvider
                      libInstance={moment}
                      utils={DateFnsUtils}
                    >
                      <KeyboardDatePicker
                        disabled={props.bStockUpdate}
                        error={props.errorObject.BLDate}
                        helperText={props.errorObject.BLDate}
                        className={classes.fullWidth}
                        // disableToolbar
                        variant="inline"
                        id="BLDate"
                        label={DispensingLanguage[lan][menukey]["BL Date"]}
                        autoOk={true}
                        name="BLDate"
                        fullWidth
                        showTodayButton={true}
                        value={props.formData.BLDate || null}
                        format="dd/MM/yyyy"
                        onChange={(e) => props.handleRDateChange(e, "BLDate")}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>

                  {/* <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Procured By"]}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="IndentorId"
                        name="IndentorId"
                        value={props.formData.IndentorId}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={props.bStockUpdate}
                      >
                        {IndentorList.map((item, index) => {
                          return (
                            <MenuItem value={item.IndentorId}>
                              {item.IndentorName} - {item.OPDescription}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Grid> */}

                  <Grid item xs={3} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          disabled={props.bStockUpdate}
                          autoHighlight
                          id="IndentorId"
                          options={IndentorList}
                          //onChange={(event, value) => changeIndentorList(value)}
                          //onChange={(event, value) => changeSupplierList('IndentorId', value)}
                          onChange={(event, valueobj) => handleChangeChoosen('IndentorId', valueobj, valueobj?valueobj.IndentorId:"")}
                          getOptionLabel={(option) => option.IndentorName +' - '+ option.OPDescription}
                          //value={indentorValue}
                          value={chosenValues['IndentorId']}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.IndentorName +' - '+ option.OPDescription}</Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={props.formData.IndentorId}
                              label={DispensingLanguage[lan][menukey]["Procured By"]}
                              variant="standard"
                              error={props.errorObject.IndentorId}
                              helperText={props.errorObject.IndentorId}
                              id="IndentorId"
                              name="IndentorId"
                              fullWidth
                            />
                          )}
                        />

                      </FormControl>

                  </Grid>

                  <Grid item xs={2} sm={2}>
                    <TextField
                      disabled={true /*props.bStockUpdate*/}
                      id="PackageYear"
                      name="PackageYear"
                      label={DispensingLanguage[lan][menukey]["Year"]}
                      value={props.formData.PackageYear}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={true /*props.bStockUpdate*/}
                      id="PackageName"
                      name="PackageName"
                      label={DispensingLanguage[lan][menukey]["Package No"]}
                      value={props.formData.PackageName}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={4} sm={4} className="sw_relative">
                    <TextField
                      disabled={true /*props.bStockUpdate*/}
                      id="ContractName"
                      name="ContractName"
                      label={DispensingLanguage[lan][menukey]["Contract No"]}
                      value={props.formData.ContractName}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />

                    <Button
                      disabled={props.bStockUpdate}
                      className="pick_btn_place"
                      variant="contained"
                      color="success"
                      onClick={() => handleClose("ContractOpen")}
                    >
                      ...
                    </Button>

                    <ContractModal
                      handleClose={handleClose}
                      open={openContract}
                      addContract={addContract}
                      {...props}
                    />

                  </Grid>
                  {/* <Grid item xs={1} sm={1}>
                    

                    <ContractModal
                      handleClose={handleClose}
                      open={openContract}
                      addContract={addContract}
                      {...props}
                    />
                  </Grid>
 */}
                  <Grid item xs={6} sm={6}>
                    <TextField
                      disabled={props.bStockUpdate}
                      id="Remarks"
                      name="Remarks"
                      label={DispensingLanguage[lan][menukey]["Remarks"]}
                      value={props.formData.Remarks}
                      fullWidth
                      multiline
                      rows={3}
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  {/* <Grid item xs={12} className="marginTop10"> */}
                  <Grid item xs={1} sm={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="bUpdated"
                          disabled={true}
                          color="primary"
                          checked={props.formData.bUpdated > 0 ? true : false}
                          name="bUpdated"
                          value="no"
                        />
                      }
                      label={DispensingLanguage[lan][menukey]["Updated"]}
                    />
                  </Grid>
                  <Grid item xs={1} sm={1}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id="BCancel"
                          disabled={props.bStockUpdate || props.addProductForm}
                          color="primary"
                          checked={props.formData.BCancel > 0 ? true : false}
                          // onChange={(e) => props.invoiceCancel(e)}
                          onChange={(e) => invoiceCancel(e)}
                          name="BCancel"
                          value="no"
                        />
                      }
                      label={DispensingLanguage[lan][menukey]["CANCEL"]}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="TransactionId"
                      name="TransactionId"
                      label="TransactionId"
                      value={props.formData.TransactionId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                {/* new row */}
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card">
                    <CardContent>
                      <Grid container className="mb-2">
                        <Grid item xs={12} sm={12}>
                          <div className="float-right sw_btn_control">
                            {/* <Button
                                  className="mr-2"
                                  variant="contained"
                                  color="primary"
                                  onClick={() => handleClose("OrderOpen")}
                                >
                                  SELECT FROM ORDER
                                </Button>  */}

                            <Button
                              disabled={props.bStockUpdate}
                              // disabled={ props.bStockUpdate || (props.formData.TransactionId > 0 ? false : true) }
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              onClick={() => handleClose("ProductsOpen")}
                            >
                              {DispensingLanguage[lan][menukey]["SELECT ITEM"]}
                            </Button>
                          </div>

                          <ProductsModal
                            handleClose={handleClose}
                            open={open}
                            addProduct={addProduct}
                            {...props}
                          />

                          <SourceModal
                            handleClose={handleClose}
                            open={SoureOpen}
                            addProduct={addSource}
                            {...props}
                          />
                        </Grid>
                      </Grid>
                      <ReactTabulator
                        ref={(r) => (ReceiveDetailsRef = r)}
                        columns={ReceiveDetailsColumns}
                        data={manyTableData}
                        height="350px"
                        layout={"fitColumns"}
                      />
                    </CardContent>
                  </Card>
                </Grid>

                {/* new row */}
              </Grid>
            </Grid>

            {/* </CardContent>
          </Card> */}
          </Grid>

          {/* Action buttons */}
        </Grid>
      </div>
    </div>
  );
};

export default DispenserFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
