import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import * as api from "../../../actions/api";
import AssetstatusFormData from "./AssetRegisterFormData.js";

const EditAssetRegister = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "asset-register";
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;

  const handleRDateChange = (date, field) => {
    setFormData({ ...formData, [field]: date });
    setErrorObject({ ...errorObject, [field]: null });
    api.dirtyModeEnable();
  };

  //====== Start Choosen Dropdown===========
  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value;
    setFormData(data);
    setErrorObject({ ...errorObject, [name]: "" });
    api.dirtyModeEnable();
  };
  //====== End Choosen Dropdown===========

  const [formData, setFormData] = useState({
    DeptId: "",
    SectionId: "",
    AssetLocation: "",
    ItemNo: "",
    InitialPrice: "",
    BrandName: "",
    UsefulLife: "",
    ModelNo: "",
    MenufacturerSNo: "",
    DateRecvInStore: null,
    InstallationDate: null,
    SupplyDate: null,
    MStatusId: "",
    LastDateWarranty: null,
    SupCode: "",
    ManufacturerId: "",
    CountryId: "",
    ProcuringId: "",
    FundingSourceId: "",
    DepRate: "",
    SalvageVal: "",
    MScheduleId: "",
    Remarks: "",
    StatusDate: null,
    StatusRemarks: "",
    ItemSl: "",
  });
  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data } = useQuery(
    ["AssetRegisterServer", id],
    () =>
      api.getAssetregisterListItem(id).then((res) => {
        setFormData(res.data);
        return res.data;
      }),
    {
      enabled: Boolean(id),
    }
  );

  /* const { isLoading, mutate } = useMutation(api.updateassetregister, {
    onSuccess: (data) => {
      if (data.data.status == 200) {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });

        queryClient.getQueriesData("AssetRegisterServer");
        props.history.push("/asset-register");
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  }); */
  const handleAddContract = (getProductData) => {
    let fdata = { ...formData };
    let row = getProductData;
    fdata["ItemNo"] = row[0].ItemNo;
    fdata["ItemName"] = row[0].ItemName;
    setFormData(fdata);
  };

  const handleAddChangeStatus = (getChangeStatus) => {
    let fdata = { ...formData };
    let row = getChangeStatus;
    fdata["StatusDate"] = getChangeStatus.StatusDate;
    fdata["StatusRemarks"] = getChangeStatus.StatusRemarks;
    setFormData(fdata);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      DeptId: "",
      SectionId: "",
      AssetLocation: "",
      ItemNo: "",
      InitialPrice: "",
      BrandName: "",
      UsefulLife: "",
      ModelNo: "",
      MenufacturerSNo: "",
      DateRecvInStore: null,
      InstallationDate: null,
      SupplyDate: null,
      MStatusId: "",
      LastDateWarranty: null,
      SupCode: "",
      ManufacturerId: "",
      CountryId: "",
      ProcuringId: "",
      FundingSourceId: "",
      DepRate: "",
      SalvageVal: "",
      MScheduleId: "",
      Remarks: "",
      StatusDate: null,
      StatusRemarks: "",
      ItemSl: "",
    });
  };

  const validateForm = (formData) => {
    let validateFields = [
      "InitialPrice",
      "ItemName",
      "ItemNo",
      "BrandName",
      "UsefulLife",
      "ModelNo",
      "MStatusId",
      "SupCode",
      "ManufacturerId",
      "ProcuringId",
    ];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        if (formData[field] != 0) {
          errorData[field] =
            DispensingLanguage[lan][menukey]["field is Required !"];
          isValid = false;
        }
      }
    });
    setErrorObject(errorData);
    return isValid;
  };


  function checkValidateForm(){

     
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName};
   
    return fDtat;
    }else{
    return false;
    }

};

/*   const handleUpdate = async (e) => {
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        menukey: menukey,
        lan: lan,
        UserName: UserName,
      };
      mutate(fDtat);
      //mutate(formData);
    }
  };
 */
  return (
    <>
      <AssetstatusFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        /* handleUpdate={handleUpdate} */
        handleRDateChange={handleRDateChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleAddContract={handleAddContract}
        handleAddChangeStatus={handleAddChangeStatus}
        checkValidateForm={checkValidateForm}
        {...props}
      />
    </>
  );
};

export default EditAssetRegister;
