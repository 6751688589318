import React, { forwardRef } from "react";

// reactstrap components
// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  UncontrolledTooltip,
} from "reactstrap";

//Services
import * as Service from "../../../services/Service.js";

// import MultiSelect from "react-multi-select-component";
import { MultiSelect } from "react-multi-select-component";

// materialui table
import MaterialTable from "material-table";
import { makeStyles } from "@material-ui/core/styles";
// import tableIcons from '../../../assets/icons/materialIcons.js'
import {
  AddBox,
  ArrowDownward,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  SaveAlt,
  Search,
  ViewColumn,
} from "@material-ui/icons";

import { TextField, Modal, Fade, Backdrop } from "@material-ui/core";
import swal from "sweetalert";

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: "60px",
    padding: "10px",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "50px",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid lightgrey",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "50rem",
    borderRadius: "10px",
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const roleData = [
  {
    role: "Admin",
    permissions: ["Dashboard", "owner"],
  },
  {
    role: "User",
    permissions: ["Dashboard"],
  },
  {
    role: "User",
    permissions: ["Products"],
  },
  {
    role: "User",
    permissions: ["Users"],
  },
];

const AdminRoles = (props) => {
  const { useState } = React;
  const classes = useStyles();
  const [selectedRole, setSelectedRoles] = useState();
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [menusOption, setMenusOption] = useState([]);
  const [menusData, setMenusData] = useState([]);
  const [state, setState] = React.useState({
    editRole: false,
    role_id: "",
    roles: [],
    selectedPermissions: [],
  });
  const [menu, setMenu] = useState([]);

  React.useEffect(() => {
    loadData();
    // loadPermissionsData();
  }, []);

  const loadData = () => {
    Service.default
      .getAuthApi("source/roles/")
      .then((res) => {
        if (res.status == 200) {
          setState({ ...state, roles: res.rolesdata });
        } else {
          swal("Oops!", `${res.message}`, "error");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // const loadPermissionsData = () => {
  //   Service.default
  //     .getAuthApi("source/menu/listing.php/")
  //     .then((res) => {
  //       if (res.status == 200) {
  //         setMenu(res.menulist);
  //       } else {
  //         swal("Oops!", `${res.message}`, "error");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const deleteRole = (data) => {
    const body = {
      role_id: data.id,
    };

    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this Role!",
      icon: "warning",
      buttons: {
        confirm: {
          text: "Yes",
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: "No",
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: ["No", "Yes"],
      dangerMode: true, */
    }).then((willDelete) => {
      swal("Poof! Role has been deleted!", {
        icon: "success",
      });
      loadData();
      // if (willDelete) {
      //   Service.default
      //     .postAuthApi("source/roles/delete.php", body)
      //     .then((res) => {
      //       if (res.status == 200) {
      //         swal("Poof! Role has been deleted!", {
      //           icon: "success",
      //         });
      //         loadData();
      //       } else {
      //         swal("Oops!", `${res.message}`, "error");
      //       }
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     });
      // }
    });
  };

  const handleChanges = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleOpen = (e) => {
    let a = [];
    let b = [];
    let per = menu;
    for (var i = 0; i < per.length; i++) {
      b.push({ label: per[i].title, value: per[i].id });
    }
    setMenusOption(b);
    if (e.role && e.menu) {
      setState({
        ...state,
        role_id: e.id,
        roles: e.role,
        editRole: true,
      });

      let z = e.menu;
      for (var i = 0; i < z.length; i++) {
        a.push({ label: z[i].title, value: z[i].id });
      }
      setMenusData(a);
    } else {
      setState({
        ...state,
        role_id: "",
        roles: "",
        // selectedPermissions: arr,
        editRole: false,
      });
      setMenusData([]);
    }

    setOpen(true);
  };
  const handleUpdate = () => {
    let arr = [];
    let data = menusData;
    for (var i = 0; i < data.length; i++) {
      arr.push(data[i].value);
    }
    const body = {
      role_id: state.role_id,
      role: state.roles,
      menu: arr,
    };
    if (state.roles && state.selectedPermissions) {
      swal("Oops!", "", "success");

      // Service.default
      //   .postAuthApi("source/roles/update.php", body)
      //   .then((res) => {
      //     if (res.status == 200) {
      //       setOpen(false);
      //       setState({ ...state, roles: "" });
      //       setMenusData([]);
      //       loadData();
      //     } else {
      //       swal("Oops!", `${res.message}`, "error");
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    } else {
      swal("Oops!", `Please add Roles and Pages`, "error");
    }
  };

  const handleAdd = () => {
    let arr = [];
    let data = menusData;
    for (var i = 0; i < data.length; i++) {
      arr.push(data[i].value);
    }
    const body = {
      role: state.roles,
      menu: arr,
    };
    if (state.roles && state.selectedPermissions) {
      swal("Oops!", "", "success");
      // Service.default
      //   .postAuthApi("source/roles/create.php", body)
      //   .then((res) => {
      //     if (res.status == 200) {
      //       setOpen(false);
      //       setState({ ...state, roles: "" });
      //       setMenusData([]);
      //       loadData();
      //     } else {
      //       swal("Oops!", `${res.message}`, "error");
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    } else {
      swal("Oops!", `Please add Roles and Pages`, "error");
    }
  };

  const handleClose = () => {
    setOpen(false);
    // setPermissions([""]);
    setState({ ...state, role: "", role_id: "", editRole: false });
  };

  return (
    <div className={classes.container}>
      <div className="uidash pad-2">
        <Button color="info" onClick={handleOpen}>
          <i className="now-ui-icons ui-1_simple-add mr-1"></i>
          Add New Role
        </Button>

        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div className={classes.paper}>
              <h3 id="transition-modal-title">
                {state.editRole ? "Edit Role" : "Add Role"}
              </h3>
              <div id="transition-modal-description">
                <div className="row">
                  <div className="col-md-6">
                    <TextField
                      className="full-width"
                      id="standard-basic"
                      label="Role"
                      name="roles"
                      value={state.roles}
                      onChange={(e) => {
                        handleChanges(e);
                      }}
                    />
                  </div>
                </div>

                <div className="row mt-3">
                  <MultiSelect
                    className="width_80 ml-3"
                    options={menusOption}
                    value={menusData}
                    // textField="role"
                    // dataItemKey="id"
                    onChange={setMenusData}
                    labelledBy="Roles"
                  />
                </div>

                <div className="row btn-row-modal">
                  {state.editRole ? (
                    <Button onClick={handleUpdate} color="info">
                      <i className="now-ui-icons ui-1_simple-add mr-1"></i>
                      Update
                    </Button>
                  ) : (
                    <Button onClick={handleAdd} color="info">
                      <i className="now-ui-icons ui-1_simple-add mr-1"></i>
                      Add
                    </Button>
                  )}
                  <Button color="secondary" onClick={handleClose}>
                    <i className="now-ui-icons ui-1_simple-remove mr-1"></i>
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>

        <br />
        <br />
        <MaterialTable
          title="Roles"
          icons={tableIcons}
          columns={[
            { title: "Role", field: "role" },
            {
              title: "Pages",
              field: "menu",
              render: (rowData) => {
                {
                  return rowData.menu.map((r, i) =>
                    r.length != 0 ? (
                      <>
                        {i == rowData.menu.length - 1 ? (
                          <> {r.title}</>
                        ) : (
                          <>{r.title},</>
                        )}
                      </>
                    ) : (
                      <>No data</>
                    )
                  );
                }
              },
            },
          ]}
          data={state.roles}
          options={{
            rowStyle: (rowData) => ({
              backgroundColor:
                selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
            }),
            exportCsv: (columns, data) => {
              alert("This feature is comming soon!!");
            },
            headerStyle: { position: "sticky", fontWeight: "bold" },
            search: true,
            sorting: true,
            actionsColumnIndex: -1,
          }}
          onRowClick={(evt, selectedRow) =>
            setSelectedRow(selectedRow.tableData.id)
          }
          actions={[
            {
              icon: tableIcons.Edit,
              tooltip: "Edit Role",
              onClick: (event, rowData) => {
                handleOpen(rowData);
              },
            },
            {
              icon: tableIcons.Delete,
              tooltip: "Delete Role",
              onClick: (event, rowData) => deleteRole(rowData),
            },
          ]}
        />
      </div>
    </div>
  );
};

export default AdminRoles;
