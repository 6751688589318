import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import jsPDF from "jspdf";

import swal from "sweetalert";
// materialui table
import MaterialTable from "material-table";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
  Typography,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { DeleteOutline, Edit, ViewList } from "@material-ui/icons";

import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import MomentUtils from "@date-io/moment";
import moment from "moment";
import ExportButtons from "../../../components/ExportButtons";
const AdjustmentApproval = (props) => {
  const permissionType = props.permissionType;
    //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const FacilityId = localStorage.getItem("FacilityId");
  const menukey = "adjustment-facility-approval";
  const FacilityName = localStorage.getItem("FacilityName");

  const UseFor = localStorage.getItem("UseFor");
  
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;

  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const [selectedRow, setSelectedRow] = useState(null);
  const [numberRowPerPage, setNumberRowPerPage] = useState(5);
  const [totalRow, setTotalRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [dataItems, setDataItems] = useState([]);

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(90, "days").format("YYYY-MM-DD")
  );

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const [isLoading, setLoading] = useState(true);
  const [checked, setChecked] = React.useState(true);
  const [CompletedOrders, setCompletedOrders] = useState(true);

  let params = {
    action: 'getAdjustmentFacilityInvoiceListApproval',
    menukey: menukey,
    FacilityId: FacilityId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    StartDate: selectedStartDate,
    EndDate: selectedEndDate,
    TransactionTypeId: 3,
    UseFor: UseFor,
    CompletedOrders:CompletedOrders,
  };
  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getAdjustmentFacilityInvoiceListApproval,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setLoading(false);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const { mutate } = useMutation(api.AdjustmentInvoiceDataDalete, {
    onSuccess: (data) => {

      if (data.status == 200) {

        refetch();
        queryClient.getQueriesData("adjustment-facility-approval");

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
          duration: 10000,
        });
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  const handleApproveChange = (event) => {
    setChecked(event.target.checked);
    setLoading(true);
    setCompletedOrders(event.target.checked);
  };

  const handleStartDateChange = (date) => {
    let newDate = moment(date).format("YYYY-MM-DD");
    setSelectedStartDate(newDate);
    setLoading(true);
  };

  const handleEndDateChange = (date) => {
    let newDate = moment(date).format("YYYY-MM-DD");
    setSelectedEndDate(newDate);
    setLoading(true);
  };

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");

    window.open(
      finalUrl +
        "?action=getAdjustmentFacilityApproval" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&FacilityId=" +
        FacilityId +
        "&FacilityName=" +
        FacilityName +
        "&StartDate=" +
        StartDate +
        "&EndDate=" +
        EndDate + 
        "&UseFor=" +
        UseFor +
        "&CompletedOrders=" +
        CompletedOrders +
        "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e: any, row: any) => {
    let rowData = row._row.data;
    editRecord(rowData);
  };

  var StatusIcon = function(cell, formatterParams){ //plain text value
    const CellValueForOrder = cell._cell.row.data;

    //bReceived
    //console.log("bbbbb",CellValueForOrder);
    //console.log("sssss",CellValueForOrder.bCompleted);

    if(CellValueForOrder.bUpdated==1){
      return "<i class='fa fa-check' style='color:green'></i>";
    }else{
        if(CellValueForOrder.bUpdated==0){
          return "<i class='fa fa-check' style='color:#FFCC00'></i>";
        }else if(CellValueForOrder.bUpdated==-1){
          return "<i class='fa fa-times' style='color:red'></i>";
        }
    }
    
   };

   var StatusIconCancel = function(cell, formatterParams){ //plain text value
    const CellValueForOrder = cell._cell.row.data;

    //bReceived
    //console.log("bbbbb",CellValueForOrder);
    //console.log("sssss",CellValueForOrder.bCompleted);

    if(CellValueForOrder.BCancel==1){
      return "<i class='fa fa-check' style='color:green'></i>";
    }else{
        
      return "<i class='fa fa-times' style='color:red'></i>";
       
    }
    
   };

  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 40,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Invoice No"],
      field: "InvNo",
      width: 100,
      headerFilter:true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Invoice Date"],
      width: 110,
      field: "PrepDate",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },
    { 
      title: DispensingLanguage[lan][menukey]["Adj. Type"], 
      field: "AdjTypeName",
      width: 150,
      headerFilter:true,
    },
    { 
      title: DispensingLanguage[lan][menukey]["Prepared By"],
      field: "PreparedBy",
      minWidth: 100,
      headerFilter:true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Approved Date"],
      width: 110,
      field: "AppDate1",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },
    { 
      title: DispensingLanguage[lan][menukey]["Approved By"],
      field: "ApprovedBy",
      minWidth: 150,
      headerFilter:true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Issued Date"],
      width: 110,
      field: "IssuedDate",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      }, 
    },
    { 
      title: DispensingLanguage[lan][menukey]["Issued By"],
      field: "Issued_By",
      minWidth: 100,
      headerFilter:true,
    },{
      title: DispensingLanguage[lan][menukey]["Facility"],
      field: "FacilityName",
      minWidth: 150,
      headerFilter:true,
    },{
      title: DispensingLanguage[lan][menukey]["Updated"],
      width: 95,
      headerHozAlign: "center",
      field: "bUpdated",
      hozAlign: "center",
      formatter:StatusIcon,
      //formatter: "tickCross",
    },{
      title: DispensingLanguage[lan][menukey]["CANCEL"],
      width: 90,
      field: "BCancel",
      hozAlign: "center",
      //formatter: "tickCross",
      formatter:StatusIconCancel,
      headerHozAlign: "center",
    },{
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      headerHozAlign: "center",
      hozAlign: "center",
      width: 100,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const handleChangePage = (data) => {
    setPageNumber(data);
    // setFirstLoad(false);
  };
  const handleChangeRowPerPage = (data) => {
    setNumberRowPerPage(data);
  };
  const editRecord = (data) => {
    let route = `adjustment-facility-approval/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteRecord = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Once deleted, you will not be able to recover this record!"
        ]
      ),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willDelete) => {
      if (willDelete) {
        mutate(data.id);
      }
    });
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    //const cellValue = props.cell._cell.value || "Edit | Show";

    if (rowData.bStockUpdated || rowData.BCancel || permissionType){
      return (
        <>
          <ViewList
            onClick={() => {
              editRecord(rowData);
            }}
          />
        </>
      );
    }else{
      return (
        <>
          <Edit
            onClick={() => {
              editRecord(rowData);
            }}
          />
          {/* <DeleteOutline
            onClick={() => {
              deleteRecord(rowData);
            }}
          /> */}
        </>
      );
    }
  }

  return (
    <>
      <div className={classes.productPageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Adjustment Invoice Approval"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      showTodayButton={true}
                      autoOk={true}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["From"]}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      //disableToolbar
                      autoOk={true}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["To"]}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={2} sm={2}>
              <FormControlLabel className="OrderApprovalCheck"
                control={
                  <Checkbox
                    color="primary"
                    name="bDispensingFilter"
                    checked={checked} 
                    onChange={handleApproveChange}
                    //value="no"
                  />
                }
                label={DispensingLanguage[lan][menukey]["To be approved"]}
              />
              </Grid>

              <Grid item xs={6} sm={6}>
                <div className="float-right sw_btn_control">
                  {/* <Button
                   disabled={permissionType}
                    color="info"
                    className="mr-2"
                    onClick={() => {
                      props.history.push("/adjustment-facility-approval/add");
                    }}
                  >
                    {DispensingLanguage[lan][menukey]["Add Adjustment"]}
                  </Button> */}
                  
                  <ExportButtons PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}  {...props}></ExportButtons>
                  {/* <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button> */}
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="450px"
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdjustmentApproval;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
