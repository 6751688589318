import React, { useEffect, useState } from "react";//, 
import {makeStyles, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { 
  Grid,
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Card,
  CardContent,
  TextField,
  Typography
  

} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";  
import { useQuery, useMutation } from "react-query";
import * as api from "../../../actions/api";
import { useTranslation } from "react-i18next";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator } from "react-tabulator";
import moment from "moment";



const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const DGDAPriceModal =({ handleClose, openOrder, addOrder, ...props }) => {
  
  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "products";
 
  let tblOrderRef = null;
  /*
  const { isLoading, error, isError, data, refetch } = useQuery(
    "products",
    api.getProductsListModalCatalog
  );
  */
  const { t, i18n } = useTranslation();
  const [dataItems, setDataItems] = useState([]);
  const classes = useStyles();

  const Product_Group = JSON.parse(localStorage.getItem("GroupNameList"));
  let All_Product = { id: "0", name: t(DispensingLanguage[lan][menukey]["All"]) };
  const ProductGroupList = [All_Product].concat(Product_Group);
  const [currProductGroupId, setCurrProductGroupId] = useState(0);
  

  let params = {
    menukey: menukey,
    action: "getProductListCatalog",
    lan:lan,
    ProductGroupId:currProductGroupId,
  };

  const {isLoading, error, isError, data, refetch } = useQuery(
    [params],
    api.getProductsListModalCatalog,{
      onSuccess: (data) => {
        setDataItems(data);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );



  const [selectedProducts, setselectedProducts] = useState([]);
  const OrderModalColumns = [
    { title: "id", field: "ItemNo", visible: false },
    { title: "ItemNo", field: "ItemNo", visible: false },
    { title: "Generic Name", field: "GenericName", visible: false },
    { title: DispensingLanguage[lan][menukey]['Sl#'],  formatter: "rownum", width:50, hozAlign: "center" , headerHozAlign:"center", headerSort:false},
    // { title: "Group Name", field: "GroupName", width: 140, headerFilter:true },
    { title: DispensingLanguage[lan][menukey]['Item Code'], field: "ItemCode", width: 140, headerFilter:true },
    { title: DispensingLanguage[lan][menukey]['Item Name'], field: "ItemName", headerFilter:true  },
    { title: DispensingLanguage[lan][menukey]['Unit'], field: "UnitName", width: 80},
    // { title: DispensingLanguage[lan][menukey]['Product Price'], field: "UnitPrice", width: 80, hozAlign: "right", headerHozAlign:"right"  },
  ]; 
  const OrderModaltDataItems = data?.map((item, index) => { 
    return item;
  });
  const {mutate} = useMutation(api.saveNonReceivedOrder, {
    onSuccess: (data)=>{
       
      addOrder(data);
      //queryClient.getQueriesData('receive');
    }
  })
  const SaveSelectedOrder = () => { 

    if(tblOrderRef.table.getSelectedData().length>0)
    mutate(tblOrderRef.table.getSelectedData()[0].OrderId); 
   
  };

  const [btnDisable, setbtnDisable] = useState(false);

  const SaveSelectedProducts = () => {
    
    if(tblOrderRef.table.getSelectedData().length>0)
      setbtnDisable(true);
      setselectedProducts(tblOrderRef.table.getSelectedData());
      setTimeout(() => setbtnDisable(false), 500);
    
  }; 


  const handleProductGroupChange = (event, newValue) => {

    let rowId = '';
    if (newValue == null) {
      rowId = '';
    } else {
      rowId = newValue.id;
    }
    if (rowId !== currProductGroupId) {
      setCurrProductGroupId(rowId);
    }
  };  

  
useEffect(() => { 
    if (tblOrderRef == null) return;
    addOrder(selectedProducts);

}, [selectedProducts]); 



 

  return (
    <div>
     
      <BootstrapDialog
        onClose={() => handleClose('DGDAPriceModal')}
        aria-labelledby="customized-dialog-title"
        open={openOrder}
        fullWidth={true}
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => handleClose('DGDAPriceModal')}
        >
         {DispensingLanguage[lan][menukey]['Item List']}
        </BootstrapDialogTitle>


        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              
                <Grid item xs={5} sm={5}>
                  <FormControl className={classes.fullWidth}>
                  <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="ItemGroupId"
                      disableClearable
                      options={ProductGroupList}               
                      onChange={(event, newValue) => handleProductGroupChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={ProductGroupList[ProductGroupList.findIndex(AProductGroupList => AProductGroupList.id == currProductGroupId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Item Group"]}
                        variant="standard"                       
                      />
                      )}
                    />                   
                  </FormControl>
              </Grid>

            </Grid>
          </CardContent>
        </Card>

        <DialogContent dividers>  
          <ReactTabulator ref={(r) => (tblOrderRef = r)} 
           selectable={1} 
           columns={OrderModalColumns}
           data={dataItems}
          // data={OrderModaltDataItems}
           height="300px" 
          />
        </DialogContent>
        <DialogActions>
          <Button 
          className="mr-2 float-left"
          variant="contained"
          color="primary" 
          onClick={SaveSelectedProducts}>
           {DispensingLanguage[lan][menukey]['Add']} 
          </Button>
          <Button autoFocus 
          className="float-left"
          variant="contained"
          
          onClick={() => handleClose('DGDAPriceModal')}>
            {DispensingLanguage[lan][menukey]['Cancel']} 
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default DGDAPriceModal;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});