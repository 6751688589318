import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Constants from "../../../services/Constants";
import swal from "sweetalert";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import {
  Grid,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";


import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";

import * as api from "../../../actions/api";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, ViewList, preview, Edit } from "@material-ui/icons";
import { cellFocusEditor } from "../../../services/Common";
import moment from "moment";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";



const PhysicalInventory = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const { t, i18n } = useTranslation();
  const lan = localStorage.getItem("LangCode");
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const menukey = "physical-inventory";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;

  const classes = useStyles();
  let tblRef = null;
  const tableRef = useRef();
  const { useState } = React;

  const [firstLoad, setFirstLoad] = useState(true);

  const [ManyJsonSave, setInputChange] = useState({});
  const [radioValue, setRadioValue] = useState(0);
  const [FirstradioValue, setRadioValueHanddel] = useState(0);
  const [productGroupData, selectProductGroupData] = useState(0);
  const [dataItems, setDataItems] = useState([]);


  const [manyTableData, setManyTableData] = useState([]);
  const GroupNameList = JSON.parse(localStorage.getItem("GroupNameList"));
  GroupNameList.unshift({'id':0,'name':"All"});
  const [currStockItem, setcurrstockitem] = useState();


  
    
//====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "GroupCode": {"id": "0","name": "All"}
});

const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;
  setChosenValues(chosenValuesData); 

  selectProductGroupData(value);
  setLoading(true);
  setFirstLoad(true);
 

};
//====== End Choosen Dropdown===========
 

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );



  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(69, "days").format("YYYY-MM-DD")
  );

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    setLoading(true);
    setFirstLoad(true);
  };


  const queryClient = useQueryClient();
  const UseFor = localStorage.getItem("UseFor");

  let params = {
    menukey: menukey,
    FacilityId: FacilityId,
    action: "getStockExpiryByLotView",
    StartDate: moment(selectedStartDate).format("YYYY-MM-DD"),
    EndDate: moment(selectedEndDate).format("YYYY-MM-DD"),
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    RadioValue: radioValue,
    FirstradioValue: FirstradioValue,
    GroupCode: productGroupData,
    UseFor: UseFor,
  };

  const [isLoading, setLoading] = useState(false);

  const { error, isError, data, refetch:stockData } = useQuery(
    [params],
    api.physicalInventoryReport,
    {
      onSuccess: (data) => {
        if(firstLoad){
          localStorage.setItem(
            "StockData",
            JSON.stringify(data)
          );
          
          setDataItems(data);
          setLoading(false);
          setFirstLoad(false);
        }
      },
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 0
  }
  ); 

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reporttype) => {
    let formatedstartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let formatedendDate = moment(selectedEndDate).format("YYYY-MM-DD");
    let finalUrl='';
    if(reporttype =='print'){
       finalUrl = EXCEL_EXPORT_URL + "report/physicalinventory_print.php";
    }else{
       
    }
   

    window.open(
      finalUrl +
        "?menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&StartDate=" +
        formatedstartDate +
        "&EndDate=" +
        formatedendDate +
        "&FacilityId=" +
        FacilityId +
        "&GroupCode=" +
        productGroupData +
        "&RadioValue=" +
        radioValue +
        "&FirstradioValue=" +
        FirstradioValue +
        "&FacilityName=" +
        FacilityName + 
        "&UseFor=" +
        UseFor + 
        "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */



  const columns = [
    {
      title: "Id",
      field: "id",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: "4%",
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
   /*  {
      title: DispensingLanguage[lan][menukey]["Group Name"],
      field: "GroupName",
      headerFilter: false,
      visible: false,
    }, */
    {
      title: DispensingLanguage[lan][menukey]["Item Name"],
      field: "ItemName",
      headerFilter: false,
      minWidth: 200,
      //width: "22%",
    },
    {
      title: DispensingLanguage[lan][menukey]["Opening Blance"],
      field: "OPBL",
      hozAlign: "right",
      headerHozAlign: "right",
      headerFilter: false,
      width: "9%",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == 0 ? "" : Number(value).toLocaleString();
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Received"],
      field: "Received",
      hozAlign: "right",
      headerHozAlign: "right",
      headerFilter: false,
      width: "9%",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == 0 ? "" : Number(value).toLocaleString();
      },
    }, 
    {
      title: DispensingLanguage[lan][menukey]["Issued"],
      field: "Distribution",
      hozAlign: "right",
      headerHozAlign: "right",
      headerFilter: false,
      width: "9%",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == 0 ? "" : Number(value).toLocaleString();
      },

    },
    {
      title: DispensingLanguage[lan][menukey]["Returned"],
      field: "Adjustment",
      hozAlign: "right",
      headerHozAlign: "right",
      headerFilter: false,
      width: "9%",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == 0 ? "" : Number(value).toLocaleString();
      },

    },
    {
      title: DispensingLanguage[lan][menukey]["Closing Blance"],
      field: "CBL",
      hozAlign: "right",
      headerHozAlign: "right",
      headerFilter: false,
      width: "9%",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == 0 ? "" : Number(value).toLocaleString();
      },

    },
    {
      title: DispensingLanguage[lan][menukey]["Physical Count"],
      field: "physicalCount",
      hozAlign: "right",
      headerHozAlign: "right",
      headerFilter: false,
      headerSort: false,
      width: "9%",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == 0 ? "" : Number(value).toLocaleString();
      },

    },
    {
      title: DispensingLanguage[lan][menukey]["Difference"],
      field: "differenceTemp",
      headerFilter: false,
      width: "9%",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Remarks"],
      field: "remarks",
      headerFilter: false,
      minWidth: 150,
      width: "10%",
      headerSort: false,
    },
   
  ];

  const handleEndtDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedEndDate(date);
  };
 
	const [all, setAll] = React.useState('0');
	const handleChange = (event) => {
    setAll(event.target.value);
      selectProductGroupData(event.target.value);
      setFirstLoad(true);
      setLoading(true);
      
    };




  const checkLogin = () => {
    let token = Constants.token;

    if (!token) {
      swal("Oops!", `token expired. Please login again`, "error");
      props.history.push("/login");
    }
  };


  const changeRadioHanddel = (value) => {
    setFirstLoad(true);
    setLoading(true);
    setRadioValueHanddel(value);
  };

  const changeRadio = (value) => {
    setFirstLoad(true);
    setLoading(true);
    setRadioValue(value);
  };

 React.useEffect(() => {
   if (tblRef == null) return;
   setLoading(true);
   stockData();
  }, []);

  return (
    <>
      <div className={classes.productPageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Physical Inventory"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={3} sm={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      //disableToolbar
                      autoOk={true}
                      // disabled={true}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["Start Date"]}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid> 
			        <Grid item xs={3} sm={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      //disableToolbar
                      autoOk={true}
                      // disabled={true}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["End Date"]}
                      value={selectedEndDate}
                      onChange={handleEndtDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  {/* <InputLabel id="">
                    {DispensingLanguage[lan][menukey]["Group Name"]} 
                  </InputLabel>
                  <Select
                   // error={props.errorObject.SupCode}
                    labelId="GroupCode"
                    id="GroupCode"
                    name="GroupCode"
                    defaultValue='0'
                    fullWidth
                    onChange={handleChange}
                    disabled={
                      manyTableData.length > 0 ? true : props.bStockUpdate
                    }
                  >
        
                    {GroupNameList.map((item, index) => {
                      return (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      );
                    })}
                  </Select> */}

                        <Autocomplete
                          autoHighlight
                          disabled={
                            manyTableData.length > 0 ? true : props.bStockUpdate
                          }
                          disableClearable
                          id="GroupCode"
                          options={GroupNameList}
                          onChange={(event, valueobj) => handleChangeChoosen('GroupCode', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValues['GroupCode']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            //value={props.formData.GroupCode}
                            label={DispensingLanguage[lan][menukey]["Group Name"]}
                            variant="standard"
                            id="GroupCode"
                            name="GroupCode"
                            fullWidth
                          />
                          )}
                        />

                </FormControl>
                  </Grid>
				  
				  
              <Grid item xs={3} sm={3}>
                <div className="float-right sw_btn_control">

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  {/* <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button> */}
                </div>
              </Grid>

              <Grid item xs={6} sm={3}>
                <FormControl component="fieldset" className="mt-3 ml-3">
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="row-radio-buttons-group"
                    defaultValue="Usable_Stock"
                  >
                    
                    <FormControlLabel
                      value="Usable_Stock"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["Usable Stock"]}
                      onClick={() => changeRadioHanddel(0)}
                    />
                    <FormControlLabel
                      value="Unusable_Stock"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["Unusable Stock"]}
                      onClick={() => changeRadioHanddel(1)}
                    />
                    
                    
                  </RadioGroup>
                </FormControl>
              </Grid>

              <Grid item xs={6} sm={9}>
                <FormControl component="fieldset" className="mt-3 ml-3">
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="row-radio-buttons-group"
                    defaultValue="SHOW_NON_ZERO"
                  >
                    <FormControlLabel
                      value="SHOW_NON_ZERO"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["Show Only Non-Zero Stock"]}
                      onClick={() => changeRadio(0)}
                    />
                    <FormControlLabel
                      value="ZERO"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["Show Only Zero Stock"]}
                      onClick={() => changeRadio(1)}
                    />
                    <FormControlLabel
                      value="SHOW_ALL"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["Show All Items"]}
                      onClick={() => changeRadio(2)}
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}

          <div className="uniqueName">
            <ReactTabulator
              ref={(r) => (tblRef = r)}
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="400px"
              // groupBy="GroupName"
              options={{
                  groupBy: "GroupName",
                  columnCalcs: "both",
                }}
             // rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PhysicalInventory;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
