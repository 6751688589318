import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import swal from "sweetalert";
import * as api from "../../../actions/api";
import DispenserFormData from "./MCWCMonthlyForm3FormData.js";
// import history from './../../../history';
import { useTranslation } from "react-i18next";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";
import moment from "moment";

const EditMCWCMonthlyForm3 = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "mcwc-monthly-form-3";
  const FacilityId = localStorage.getItem("FacilityId");

  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    TransactionNo: "",
    TransactionDate: "",
    TransactionTypeId: 0,
    FacilityId: FacilityId,
    FacilityCode: FacilityId,
    TotalUpazila: 0,
    YearName: "",
    MonthId: "",
    Remarks: "",
    bUpdated: 0,
    // RCondom:"",
    // RShukhi:"",
    // RIdu:"",
    // RInjectable:"",
    ReportedMonthId: "",
    // UnionCode:"",
    ReportedYear: "",
    // TotalWorkers:"",
    // ReportsSubmitted:"",
  });
  const [selectedDate, setSelectedDate] = useState(
    //new Date()
    moment().format("YYYY-MM-DD")
  );
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const [ManyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState("0");
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
  const [TransactionId, setTransactionId] = useState("0");
  const [deletedDataSet, setDeletedDataSet] = useState([]);

  const [StockPost, setStockPost] = useState(false);

  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState("");
  const [selectedYearName, setSelectedYearName] = useState("");
  const [bFirst, setBFirst] = useState(true);

  const [isPreLoading, setPreLoading] = useState(false);
  const [currItemCode, setCurrItemCode] = useState("CON002");

  const handleRDateChange = (date, field) => {
    let newDate = moment(date).format("YYYY-MM-DD");
    setFormData({ ...formData, [field]: newDate });
    setErrorObject({ ...errorObject, [field]: null });
    api.dirtyModeEnable();
  };

  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data, refetch: ReloadItemTable } = useQuery(
    ["mcwc-monthly-form3", id, currItemCode],
    () =>
      api.getMCWCMonthlyForm3Single(id, currItemCode).then((res) => {
        //This is for user has VIEW/EDIT permission in this page start
        if (props.permissionType === 1) {
          //when permissionType then it is only VIEW permission otherwise normal permission
          res.data.bStockUpdated = 1;
        }
        //This is for user has VIEW/EDIT permission in this page end

        setTransactionId(id);
        setFormData(res.data);
        setbStockUpdate(res.data.bStockUpdated);
        setManyTableData(res.data.ManyJsonSave); //res.data.ManyJsonSave
        setUpdatedDetailGridData(res.data.ManyJsonSave);
        setGeneratedInvoiceNo(res.data.TransactionNo);
        setSelectedYearName(res.data.YearName);
        setDeletedDataSet([]);
        setBFirst(true);

        api.dirtyModeDisable();

        return res.data;
      }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(id),
    }
  );

  const reFetchItems = (SegmentSelectItemCode) => {
    setCurrItemCode(SegmentSelectItemCode);
  };

  useEffect(() => {
    ReloadItemTable();
  }, [currItemCode]);

  const { isLoading, mutate: mutateUpdate } = useMutation(
    api.MCWCMonthlyForm3Update,
    {
      onSuccess: (data) => {
       // console.log("mutateUpdate data: ", data);
        if (data.status == 200) {
          
         // console.log("datadatadatadatadatadatadata: ", data);
          if (data.bStockUpdated == -1) {
            setbStockUpdate(0);
            //Generate form3 items
            let fDtat = {
              ...formData,
              ManyJsonSave: ManyTableData,
              bStockUpdated: 0,
              deletedDataSet: deletedDataSet,
            };
            mutateGenerateForm7(fDtat);
          }
          else if (data.bStockUpdated == 1) {
            setbStockUpdate(data.bStockUpdated);
            //Generate form3 items and post
            let fDtat = {
              ...formData,
              ManyJsonSave: ManyTableData,
              bStockUpdated: 1,
              deletedDataSet: deletedDataSet,
            };
            console.log('formData: ', formData);

            mutateGenerateForm7(fDtat);
          }
          //  else if (data.bStockUpdated == 2) {
          //   //Generate issue voucher
          //   let fDtat = {
          //     ...formData,
          //     ManyJsonSave: ManyTableData,
          //    // bStockUpdated: 0,
          //     deletedDataSet: deletedDataSet,
          //   };
          //   mutateGenerateIssueVoucher(fDtat);
          // } 
          else {
            ReloadItemTable();

            props.openNoticeModal({
              isOpen: true,
              msg: data.message,
              msgtype: data.success,
            });
            setPreLoading(false);
            //swal("Success!", "", "success");
          }
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
          setPreLoading(false);
          setbStockUpdate(0);
        }
      },
    }
  );

  const { isLoadingF7, mutate: mutateGenerateForm7 } = useMutation(
    api.generateMCWCMonthlyForm3Items,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          if (data.data.success == 1) {
            ReloadItemTable();

            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });
          } else {
            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });
            setbStockUpdate(0);

          }
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
          setbStockUpdate(0);

        }

        setPreLoading(false);
      },
    }
  );

  // const { isLoadingSP, mutate: mutateGenerateIssueVoucher } = useMutation(
  //   api.generateIssueVoucher,
  //   {
  //     onSuccess: (data) => {
  //       if (data.status == 200) {
  //         if (data.data.success == 1) {
  //           ReloadItemTable();

  //           props.openNoticeModal({
  //             isOpen: true,
  //             msg: data.data.message,
  //             msgtype: data.data.success,
  //           });
  //         } else {
  //           props.openNoticeModal({
  //             isOpen: true,
  //             msg: data.data.message,
  //             msgtype: data.data.success,
  //           });
  //         }
  //       } else {
  //         props.openNoticeModal({
  //           isOpen: true,
  //           msg: data.message,
  //           msgtype: data.success,
  //         });
  //       }

  //       setPreLoading(false);
  //     },
  //   }
  // );

  const handleAdd = (NonOrderProductData) => {
    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log("name: ", name);

    let data = { ...formData };
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };

  //====== Start Choosen Dropdown===========
  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value;

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: "" });
    api.dirtyModeEnable();
  };
  //====== End Choosen Dropdown===========

  const handleCheck = (e) => {
    // console.log('handleCheck e: ', e);
    const { name, checked } = e.target;
    // console.log('name, checked: ', name, checked);

    setFormData({ ...formData, [name]: checked });
  };

  const invoiceCancel = (e) => {
    // console.log('handleCheck e: ', e);
    const { name, checked } = e.target;
    // console.log('name, checked: ', name, checked);
    // console.log('invoiceCancel formData: ', formData);
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text:
        t(DispensingLanguage[lan][menukey]["Do you really want to cancel?"]) +
        " " +
        t(
          DispensingLanguage[lan][menukey][
            "You will not able to edit this invoice after cancel"
          ]
        ),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willCancel) => {
      if (willCancel) {
        let fDtat = {
          ...formData,
          ManyJsonSave: ManyTableData,
          BCancel: 1,
          TableName: "form7master",
          deletedDataSet: deletedDataSet,
        };
        mutateUpdate(fDtat);
      } else {
        setFormData({ ...formData, [name]: false });
      }
    });
  };

  const handleReset = () => {
    setFormData({
      TransactionNo: "",
      TransactionDate: "",
      TransactionTypeId: 0,
      FacilityId: FacilityId,
      FacilityCode: FacilityId,
      TotalUpazila: 0,
      YearName: "",
      MonthId: "",
      Remarks: "",
      bUpdated: 0,
      // RCondom:"",
      // RShukhi:"",
      // RIdu:"",
      // RInjectable:"",
      ReportedMonthId: "",
      UnionCode: "",
      ReportedYear: "",
      // TotalWorkers:"",
      // ReportsSubmitted:"",
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["TransactionDate", "ReportedYear", "ReportedMonthId"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const deleteReceiveInv = (data) => {
    console.log(ManyTableData);
    let newarray = ManyTableData.filter((element) => element !== data);

    setManyTableData(newarray);
    setUpdatedDetailGridData(newarray);
    if (data.TransactionItemId != null) {
      let newDeletedDataSet = [data.TransactionItemId];
      setDeletedDataSet(newDeletedDataSet.concat(deletedDataSet));
    }
  };

  const handleUpdate = async (e) => {
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        ManyJsonSave: ManyTableData,
        deletedDataSet: deletedDataSet,
      };
      mutateUpdate(fDtat);
    }
  };

  const generateForm7Items = async (e) => {
    if (validateForm(formData)) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey][
            "Do you really want to fill the Form 3 items?"
          ]
        ),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true, */
      }).then((willAction) => {
        if (willAction) {
          setPreLoading(true);
          // let fDtat={...formData,'ManyJsonSave':ManyTableData,"bStockUpdated":0,'deletedDataSet':deletedDataSet};
          // mutateGenerateForm7(fDtat);
          let fDtat = {
            ...formData,
            ManyJsonSave: ManyTableData,
            bStockUpdated: -1,
            deletedDataSet: deletedDataSet,
          };
          mutateUpdate(fDtat);
        } else {
          // setbStockUpdate(0);
          // let route = `${TransactionId}`;
          // props.history.push(route);
        }
      });
      // End Post Alert
    }
  };


  
  const generateForm7ItemsAndPost = async (e) => {
    if (validateForm(formData)) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey][
            "Do you really want to post the Form 3?"
          ]
        ),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true, */
      }).then((willAction) => {
        if (willAction) {
          setPreLoading(true);
          // let fDtat={...formData,'ManyJsonSave':ManyTableData,"bStockUpdated":0,'deletedDataSet':deletedDataSet};
          // mutateGenerateForm7(fDtat);
          let fDtat = {
            ...formData,
            ManyJsonSave: ManyTableData,
            bStockUpdated: 1,
            deletedDataSet: deletedDataSet,
          };
          mutateUpdate(fDtat);
        } else {
          // setbStockUpdate(0);
          // let route = `${TransactionId}`;
          // props.history.push(route);
        }
      });
      // End Post Alert
    }
  };
  // const generateIssueVoucher = async (e) => {
  //   ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //   if (validateForm(formData)) {
  //     swal({
  //       title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
  //       text: t(
  //         DispensingLanguage[lan][menukey][
  //           "Do you want to generate Issue Voucher from supply plan?"
  //         ]
  //       ),
  //       icon: "warning",
  //       buttons: {
  //         confirm: {
  //           text: t(DispensingLanguage[lan][menukey]["Yes"]),
  //           value: true,
  //           visible: true,
  //           className: "",
  //           closeModal: true,
  //         },
  //         cancel: {
  //           text: t(DispensingLanguage[lan][menukey]["No"]),
  //           value: null,
  //           visible: true,
  //           className: "",
  //           closeModal: true,
  //         },
  //       },
  //       /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
  //       t(DispensingLanguage[lan][menukey]["Yes"])],
  //       dangerMode: true, */
  //     }).then((willAction) => {
  //       if (willAction) {
  //         setPreLoading(true);
  //         // let fDtat={...formData,'ManyJsonSave':ManyTableData,"bStockUpdated":0,'deletedDataSet':deletedDataSet};
  //         // mutateGenerateForm7(fDtat);
  //         let fDtat = {
  //           ...formData,
  //           ManyJsonSave: ManyTableData,
  //           bStockUpdated: 2,
  //           deletedDataSet: deletedDataSet,
  //         };
  //         mutateUpdate(fDtat);
  //       } else {
  //         // setbStockUpdate(0);
  //         // let route = `${TransactionId}`;
  //         // props.history.push(route);
  //       }
  //     });
  //     // End Post Alert
  //   }
  // };

  // const handlePost = async (e) => {
   
  //   if (validateForm(formData)) {

  //     mutateSubmitToUpazila(formData);

      
  //   }
  // };


  
// const { isLoading2, mutate:mutateSubmitToUpazila } = useMutation(api.MCWCMonthlyForm3SubmitToUpazila, {
//   onSuccess: (data) => {
 
//     if (data.status == 200) {

//         setbStockUpdate(1);
//         reFetchItems();

//         props.openNoticeModal({
//           isOpen: true,
//           msg: data.message,
//           msgtype: data.success,
//         });
  
//     }else{

//       props.openNoticeModal({
//         isOpen: true,
//         msg: data.message,
//         msgtype: data.success,
//       });
      
//     }
//   }
// })

  const handleUnPost = async (e) => {
    mutateUnpost(formData);
  };

  const { isLoading5, mutate: mutateUnpost } = useMutation(
    api.MCWCMonthlyForm3Unpost,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          setbStockUpdate(0);
          reFetchItems();

          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
          /* setPreLoading(false); */
        }
      },
    }
  );

  const setUpdatedDetailsGridDataHandler = (rows) => {
    if (validateForm(formData)) {
      let fDtat = {
        ...formData,
        ManyJsonSave: rows,
        deletedDataSet: deletedDataSet,
      };
      mutateUpdate(fDtat);
    }

    // const updateManyTableData = ManyTableData.map((row) => {

    //   if (row.SKU == rows.SKU) {
    //     row.Quantity = rows.Quantity;
    //     row.BatchNo = rows.BatchNo;
    //     row.ExpiryDate = rows.ExpiryDate;
    //   }
    //   return row;
    // });
    // console.log('updateManyTableData: ', 'sdgfjsgfsgfjshgfdhsgfhdsfg');
    setManyTableData(rows);
  };

  const deleteForm7Items = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Do you really want to delete the selected Form 3?"
        ]
      ),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willDelete) => {
      if (willDelete) {
        deleteForm7ItemsMutate(formData.TransactionNo);
        // console.log('data: ', formData);
      }
    });
  };

  const { mutate: deleteForm7ItemsMutate } = useMutation(
    api.deleteMCWCMonthlyForm3Items,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          ReloadItemTable();

          queryClient.getQueriesData("mcwc-monthly-form3");

          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
            duration: 10000,
          });
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      },
    }
  );

  return (
    <div className="sw_relative">
      {isPreLoading && <LoadingSpinnerOpaque />}
      <DispenserFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleCheck={handleCheck}
        handleReset={handleReset}
        invoiceCancel={invoiceCancel}
        handleUpdate={handleUpdate}
        ManyTableData={updatedDetailGridData}
        handleRDateChange={handleRDateChange}
        selectedDate={selectedDate}
        handleAdd={handleAdd}
        bStockUpdate={bStockUpdate}
        setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
        // handlePost={handlePost}
        handleUnPost={handleUnPost}
        deleteReceiveInv={deleteReceiveInv}
        generatedInvoiceNo={generatedInvoiceNo}
        selectedYearName={selectedYearName}
        bFirst={bFirst}
        generateForm7Items={generateForm7Items}
        generateForm7ItemsAndPost={generateForm7ItemsAndPost}
        isPreLoading={isPreLoading}
        deleteForm7Items={deleteForm7Items}
        reFetchItems={reFetchItems}
        // generateIssueVoucher={generateIssueVoucher}
        {...props}
      />
    </div>
  );
};

export default EditMCWCMonthlyForm3;
