import React from 'react';
import { Button } from "reactstrap";
import Tooltip from '@material-ui/core/Tooltip';




const ExportButtons = ({ ...props }) => {

    const PrintAndExel = (PrintPDFExcelExport) => {


        props.PrintPDFExcelExportFunction(PrintPDFExcelExport);
        //console.log(PrintPDFExcelExport);
       // console.log(props);
        //PrintPDFExcelExportFunction("Print");
        
      };

  return (
    
    <>
                  <Tooltip  title="Print">
                    <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintAndExel("print")}              
                  >
                    <i class="fas fa-print"></i>
                  </Button>
                  
                  </Tooltip>
                  
                  <Tooltip  title="Export to Excel">
                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintAndExel("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>
                  </Tooltip>
    </>
  );
};

export default ExportButtons;
