import React, { useState, useEffect, useContext } from "react";
import {checkLogin, checkUserPermission} from "../../services/CheckUserAccess";
import swal from "sweetalert";
// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";

import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";

import { Link, AddToHomeScreen, Launch,ViewList } from "@material-ui/icons";

// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

import { useQuery } from "react-query";
import * as api from "../../actions/api";
import ProductSelectInPrescriptionModal from "../../services/IssueInvoiceDetailsModal";

import { useTranslation } from "react-i18next";
// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

import Constants from "services/Constants.js";
import { UserContext } from "../../context/user-info-context";
import { getDefaultMonthYear } from "../../services/Common";

import { ReactTabulator,reactFormatter } from "react-tabulator";
 
const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    width: "20%",
    margin: "0 1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDiv: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },
});

const DashboardPage = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );

  const menukey = "dashboard";

  
  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  const [hasUserPermission, setHasUserPermission] = React.useState(false);

  if (RedirectLogin) {
    setHasUserPermission(checkUserPermission(menukey));// To check user has permission in this page
    checkLogin();
    setRedirectLogin(false);
  }

  const CurrentFacilityName = localStorage.getItem("FacilityName");
  const [DashboardMetaInfo, setDashboardMetaInfo] = useState([]);
  const [CurrentStockStatusChartData, setCurrentStockStatusChartData] = useState([]);
  const [ExpiryData, setExpiryData] = useState([]);

  // const YearList = JSON.parse(localStorage.getItem("YearList"));
  // const MonthList = JSON.parse(localStorage.getItem("MonthList"));
  // const FacilityList = JSON.parse(localStorage.getItem("FacilityList"));
  // const bFacilitySelected = localStorage.getItem("bFacilitySelected");
  const FacilityId = localStorage.getItem("FacilityId");

  const [PendingReceiveData, setPendingReceiveData] = useState([]);
  const [PendingReceiveDataCount,setPendingReceiveDataCount] = useState(0);

  const [WaitingForReceiveData, setWaitingForReceiveData] = useState([]);
  const [WaitingForReceiveCount,setWaitingForReceiveCount] = useState(0);


  exporting(Highcharts);
  const userCtx = useContext(UserContext);
  const lan = localStorage.getItem("LangCode");
  const { t, i18n } = useTranslation();
  const UseFor = localStorage.getItem("UseFor");

  const [currYearId, setCurrYearId] = useState(
    getDefaultMonthYear().defaultYear
  );
  const [currMonthId, setCurrMonthId] = useState(
    getDefaultMonthYear().defaultMonthId
  );

  const [currRowId, setCurrRowId] = useState(0);
  const [InvType, setInvType] = useState(0);
  const [CurrFacilityCode, setCurrFacilityCode] = useState(0);
  const [openSqlLog, setopenSqlLog] = useState(false);
  const [LogDataArray, setLogDataArray] = useState([]);

  const handleClose = (modalname) => {
    switch (modalname) {
    
      case "ProductBatch":
        setopenSqlLog(false);
        break;
      case "ProductBatchOpen":
        setopenSqlLog(true);
        break;

      default:
        break;
    }
  };
  // const [currFacilityId, setCurrFacilityId] = useState(
  //   bFacilitySelected == 1 ? FacilityId : 0
  // );
  // const [PatientCountData, setPatientCountDatat] = useState([]);

  // const [ActivePatientTrendData, setActivePatientTrendData] = useState([]);
  // const [AdultPatientsByProtocolData, setAdultPatientsByProtocolData] =
  //   useState([]);

  const goToTrendOfActivePatients = () => {
    window.open(process.env.REACT_APP_BASE_NAME + `/trend-of-active-patients`);
  };

  const goToAdultPatientsByProtocol = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME + `/adult-patients-by-protocol`
    );
  };

  const goToLotsExpiringReport = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME + `/stock-expiry-By-lot`
    );
  };

  const goToCurrentContraceptiveStockStatus = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME + `/stock-status`
    );
  };
  const goToProducts = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME + `/stock-status`
    );
  };

  const goToUpazilaSDP = () => {

    let SDPUpazilaLink = UseFor=='WIMS'?`/upazila_list`:`/sdp_activeinactive_report`;

      window.open(
        process.env.REACT_APP_BASE_NAME + SDPUpazilaLink
      );
  };

const goToSDPStockOut = () => {

    window.open(
      process.env.REACT_APP_BASE_NAME + `/sdp_stockout_report`
    );
};

  const goToSupplyPlan = () => {

    let SupplyPlanLink = UseFor=='WIMS'?`/supplyplan`:`/upazilasupplyplan`;

      window.open(
        process.env.REACT_APP_BASE_NAME + SupplyPlanLink
      );
  };

  const goToF7andF7B = () => {

    let F7andF7BLink = UseFor=='WIMS'?`/form7`:`/form7buims`;

      window.open(
        process.env.REACT_APP_BASE_NAME + F7andF7BLink
      );
  };

  const handleYearChange = (event) => {
    setCurrYearId(event.target.value);
    // setLoading(true);
  };

  const handleMonthChange = (event) => {
    setCurrMonthId(event.target.value);
    // setLoading(true);
  };

  const handleFacilityChange = (event) => {
    //setCurrFacilityId(event.target.value);
    // setLoading(true);
  };

  function ShowSqlLogPopup(props: any) {
    const rowData = props.cell._cell.row.data;

      return (
        <>
          <ViewList
            onClick={() => {

              //alert(rowData);

              //console.log("gggggggggggggg",rowData.TransactionId);

              setCurrRowId(rowData.TransactionId);
              setCurrFacilityCode(rowData.FacilityId);
              setInvType(rowData.TransactionNo);
              //setLogDataArray([rowData.SqlText,rowData.SqlParams]);
              //console.log("gggggggggggggg",rowData);
              handleClose("ProductBatchOpen");
            
            }}
          />
        </>
      );
    
  }

  

  const { data:PendingReceive } = useQuery(
    "PendingReceives",
    api.getPendingReceiveData,
    {
      onSuccess: (data) => {
        setPendingReceiveData(data);
        setPendingReceiveDataCount(data.length);
        //console.log("Wims ", UseFor);

        console.log("Waiting Count",data.length);
        console.log("Wims ", UseFor);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const columns = [
    {
      title: DispensingLanguage[lan][menukey]["Facility"],
      headerFilter:true,
      field: "FacilityName",
    },
    {
      title: DispensingLanguage[lan][menukey]["Invoice No"],
      headerFilter:true,
      field: "TransactionId",
    },
    {
      title: DispensingLanguage[lan][menukey]["Invoice Date"],
      headerFilter:false,
      field: "TransactionDate",
      width: 180,
    },
    {
      title: DispensingLanguage[lan][menukey]["Issued Date"],
      headerFilter:false,
      field: "TransactionDate",
      width: 180,
    },
    
    {
      title: DispensingLanguage[lan][menukey]["Invoice Type"],
      headerFilter:true,
      field: "InvoiceType",
      width: 230,
    },
    {
      title: "",
      headerSort: false,
      field: "custom",
      hozAlign: "center",
      width: 90,
      formatter: reactFormatter(<ShowSqlLogPopup />),
    },
    
    
   
  ];



  

  const { data:WaitingForReceive } = useQuery(
    "WaitingForReceives",
    api.getWaitingForReceiveData,
    {
      onSuccess: (data) => {
        setWaitingForReceiveData(data);
        setWaitingForReceiveCount(data.length);
        console.log("Waiting Count",WaitingForReceiveCount);
        console.log("Wims ", UseFor);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const WaitingForReceiveColumns = [
    {
      title: DispensingLanguage[lan][menukey]["Facility"],
      headerFilter:true,
      field: "FacilityName",
    },
    {
      title: DispensingLanguage[lan][menukey]["Invoice No"],
      headerFilter:true,
      field: "TransactionId",
    },
    {
      title: DispensingLanguage[lan][menukey]["Invoice Date"],
      headerFilter:false,
      field: "TransactionDate",
      width: 180,
    },
    {
      title: DispensingLanguage[lan][menukey]["Issued Date"],
      headerFilter:false,
      field: "TransactionDate",
      width: 180,
    },
    
    {
      title: DispensingLanguage[lan][menukey]["Invoice Type"],
      headerFilter:true,
      field: "InvoiceType",
      width: 230,
    },
    {
      title: "",
      headerSort: false,
      field: "custom",
      hozAlign: "center",
      width: 90,
      formatter: reactFormatter(<ShowSqlLogPopup />),
    },
    
    
   
  ];

  const getCurrentMetaInfo = useQuery(
    ["maindashboardmetainfo"],
    () => api.getCurrentMetaInfo(),
    {
      onSuccess: (data) => {
        // console.log("Patient Count Data: ", data);
        // setPatientCountDatat(data);
        setDashboardMetaInfo(data);
      },
      // refetchOnWindowFocus: false,
      // enabled: false,
    }
  );

  const getCurrentStockStatusChartData = useQuery(
    ["dashboardcurrentstockstatus"],
    () => api.getCurrentStockStausChart(),
    {
      onSuccess: (data) => {

        setCurrentStockStatusChartData({
          chart: {
            type: "bar",
            borderColor: "#C3DDEC",
            height: 260,
          },
          title: {
            text: DispensingLanguage?t(DispensingLanguage[lan][menukey]["Current Contraceptive Stock Status"]):"",
          },
          xAxis: {
            categories: data?data.category:[],
          },
          yAxis: {
            min: 0,
            //max: 100,
            title: {
              text: DispensingLanguage?t(DispensingLanguage[lan][menukey]["MOS"]):"",
            },
          },
          legend: {
            enabled: false,
          },
          credits: {
            enabled: false,
          },
          tooltip: {
            //    valueSuffix: " %",
            formatter: function() {
              return '<b>' + this.x + '</b><br/>MOS: ' + this.y.toFixed(1) + '<br/>Qty: '+ this.point.UsableQty;
          }

          },
          plotOptions: {
            bar: {
              dataLabels: {
                enabled: true,
                crop: true,
                formatter: function () {
                  // console.log("dataLabels:",this.point.UsableQty);
                  return this.y.toFixed(1); // + "%"
                  
                  // return "MOS: "+this.y + " (Qty: "+this.point.UsableQty+")"; // + "%"
                },
              },
            },
          },
          series: [
            {
              name: DispensingLanguage?t(DispensingLanguage[lan][menukey]["MOS"]):"",
              colorByPoint: true,
              data: data?data.seriesdata:[], //[69.8, 18.2, 2.3, 2.1, 1.5, 1.5, 1, 0.8, 0.6, 0.6, 2.5],
            },
          ],
        });
      },
      // refetchOnWindowFocus: false,
      // enabled: false,
    }
  );

  const getExpiryData = useQuery(
    ["dashboardexpirystockstatus"],
    () => api.getExpiryData(),
    {
      onSuccess: (data) => {

        setExpiryData({
          chart: {
            type: "pie",
            borderColor: "#C3DDEC",
            height: 310,
          },
          title: {
            text: (data?data.productcount:"") +' '+ (DispensingLanguage?t(DispensingLanguage[lan][menukey]["Lots expiring in 6 months"]):""),
          }, 
          // xAxis: {
          //   categories: data.category,
          // },
          yAxis: {
            min: 0,
            max: 100,
            // title: {
            //   text: "",
            // },
          },
          legend: {
            enabled: true,
          },
          credits: {
            enabled: false,
          },
          tooltip: {
            //    valueSuffix: " %",
          },
          plotOptions: {
            pie: {
              showInLegend: true,
              dataLabels: {
                enabled: false,
                crop: true,
                formatter: function () {
                  return this.y; // + "%"
                },
              },
            },
          },
          series: [
            {
              name: DispensingLanguage?t(DispensingLanguage[lan][menukey]["Products"]):"",
              colorByPoint: true,
              data: data?data.seriesdata:[] /*[{
                name: 'Edge',
                y: 14.77
            },  {
                name: 'Firefox',
                y: 4.86
            }, {
                name: 'Safari',
                y: 2.63
            }, {
                name: 'Internet Explorer',
                y: 1.53
            }]*/,
            },
          ],

          // [
          //   {
          //     name: t(DispensingLanguage[lan][menukey]["Products"]),
          //     data: data.seriesdata, //[69.8, 18.2, 2.3, 2.1, 1.5, 1.5, 1, 0.8, 0.6, 0.6, 2.5],
          //   },
          // ],
        });
      },
      // refetchOnWindowFocus: false,
      // enabled: false,
    }
  );

  // useEffect(() => {
  //   console.log("useEffect calling for refresh");

  //   if (currYearId > 0 && currMonthId > 0) {
  //     getCurrentMetaInfo.refetch();
  //     getCurrentStockStatusChartData.refetch();
  //     getExpiryData.refetch();
  //   }
  // }, [currYearId, currMonthId, currFacilityId]);

  const classes = useStyles();


  /* const checkLogin = () => {
    let token = sessionStorage.getItem("token");
    if (!token) {
      swal({
        title: "Oops!",
        text: "token expired. Please login again",
        icon: "warning",
        buttons: ["No", "Yes"],
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          window.location.href = process.env.REACT_APP_BASE_NAME + `/login`;
          sessionStorage.clear();
        }
      });
    }
  }; */

  React.useEffect(() => {
    // checkLogin();
    // checkAccess();
  }, []);

  // const checkAccess = () => {
  //   // let auth = JSON.parse(sessionStorage.getItem("User_info"));
  //   let auth = Constants.USER_INFO;
  //   if (auth) {
  //     let menu = auth[0].menu;
  //     if (menu.length > 0) {
  //       var check = menu.filter((name) => name.title.includes("Dashboard"));
  //       if (check.length == 0) {
  //         props.history.push(menu[0].url);
  //       }
  //     } else {
  //       swal("Oops!", `You don't have permission to access any page`, "error");
  //       props.history.push("/login");
  //       sessionStorage.clear();
  //     }
  //   }
  // };

  // const checkLogin = () => {
  //   let token = sessionStorage.getItem("token");

  //   // let token = Constants.token;

  //   if (!token) {
  //     swal("Oops!", `token expired. Please login again`, "error");
  //     props.history.push("/login");
  //   }
  // };

  //console.log("Waiting Count",WaitingForReceiveCount);
      //  console.log("Wims ", UseFor);

  return (
    hasUserPermission && (
    <>
      <AfterLoginNavbar {...props} />

      <div
        className="section signup-top-padding"
        style={{
          backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          minHeight: "753px",
        }}
      >
        <div className="dashboard-pannel">
          <div className="d-flex justify-product mb-1">
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {t(DispensingLanguage[lan][menukey]["Dashboard"])}
              </div>
            </div>
          </div>

          <ProductSelectInPrescriptionModal
                handleClose={handleClose}
                open={openSqlLog}
                FacilityId={CurrFacilityCode}
                SCurrRowId={currRowId}
                SInvType={InvType}
                {...props}
              />



                          

          {/* start of filter */}
          {/* <Card className="sw_card sw_filter_card">
            <CardContent className="sw_filterCardContent">
              <Grid container spacing={1}>
                <Grid item xs={1} sm={1}>
                  <FormControl className={classes.fullWidth}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="YearList"
                      name="YearList"
                      value={currYearId}
                      onChange={handleYearChange}
                      fullWidth
                    >
                      {YearList.map((item, index) => {
                        return <MenuItem value={item.id}>{item.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2} sm={2}>
                  <FormControl className={classes.fullWidth}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="MonthList"
                      name="MonthList"
                      value={currMonthId}
                      onChange={handleMonthChange}
                      fullWidth
                    >
                      {MonthList.map((item, index) => {
                        return <MenuItem value={item.id}>{item.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={3} sm={3}>
                  <FormControl className={classes.fullWidth}>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="FacilityList"
                      name="FacilityList"
                      value={currFacilityId}
                      onChange={handleFacilityChange}
                      fullWidth
                    >
                      <MenuItem value="0">
                        {t(DispensingLanguage[lan][menukey]["All Facility"])}
                      </MenuItem>

                      {FacilityList.map((item, index) => {
                        return <MenuItem value={item.id}>{item.name}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} sm={6}></Grid>
              </Grid>
            </CardContent>
          </Card> */}
          {/* end of filter */}

          {/* new row */}


          <div className="row">
            <div className="col-md-12 mb-2">
              <Card className="sw_card ">
              <CardContent>
                <div className="stat-cell stat-cell-color-e">
                  <div className="row margin0auto">
                    <div className="col-md-12 ">

                      <span className="text-xlg ">
                        {DispensingLanguage[lan][menukey]["DGFP Electronics Logistics Management Information System (eLMIS)"]}
                      </span>
                    </div>
                  </div>

                  <div className="row margin0auto">

                       <div className="col-md-4 ">   
                          <span>
                            {DispensingLanguage[lan][menukey]["Date"]}: {DashboardMetaInfo.CurrentDate}
                          </span>
                        </div>

                    <div className="col-md-8 ">
                      <span className="text-xlm pull-right">
                        &nbsp;  {CurrentFacilityName}
                      </span>

                      <span className="marginTop5 pull-right">
                        {UseFor == 'WIMS' ? '' : t(DispensingLanguage[lan][menukey]["Upazila Family Planning Store"])}
                      </span>

                    </div>

                  </div>
                </div>
                </CardContent>
              </Card>
            </div>
          </div>















          {/* product and upazila */}
          <div className="row">
            <div className="col-md-4 mb-1">
              <Card className="sw_card">
                <CardContent>
                  <div className="row">
                    <div className="col-md-12 ">
                      <div className="stat-cell stat-cell-color-a ">
                        <i className="fa fa-cubes bg-icon"></i>

                        <a
                            href="javascript:void(0);"
                            className="HyColor iconPositionRight"
                            onClick={goToProducts}
                          >
                            <Launch />
                        </a>

                        <span className="text-xlg" id="total-patients">
                          {DashboardMetaInfo.TotalItemCount}
                        </span>

                        
                        <br></br>
                        <span id="totalcase" className="text-bg ">
                          {t(
                            DispensingLanguage[lan][menukey][
                            "Products in Stock"
                            ]
                          )}
                        </span>
                        {/* {UseFor == 'WIMS' ? ('') : (
                          <div className="marginBottom35"> </div>
                        )} */}




                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>


            <div className="col-md-8 mb-2">

              <Card className="sw_card">
              <CardContent>
                  <div className="row margin0auto stat-cell stat-cell-color-b">
                    <i className="fa fa-users bg-icon"></i>
                   
                       {UseFor == 'WIMS' ? 
                    
                          (<a
                              href="javascript:void(0);"
                              className="HyColor iconPositionRight"
                              onClick={goToUpazilaSDP}
                            >
                              <Launch />
                          </a>)
                          : (
                              ''
                              )
                          } 

                          


                        {UseFor == 'WIMS' ? '' : 
                        
                            <a
                                href="javascript:void(0);"
                                className="HyColor iconPositionRight"
                                onClick={goToSDPStockOut}
                              >
                                <Launch />
                            </a>
                        } 
                        

                    <div className="col-md-6 ">

                        {UseFor == 'WIMS' ? ('')
                        : (
                            
                            <a
                                href="javascript:void(0);"
                                className="HyColor iconPositionRight2"
                                onClick={goToUpazilaSDP}
                              >
                                <Launch />
                            </a>)
                        } 


                      <span className="text-xlg" id="total-patients">
                      {DashboardMetaInfo.TotalUpazilaCount}
                      </span>

                      <span className="text-xlm" id="total-patients">
                          &nbsp; {UseFor == 'WIMS' ? '' : t(DispensingLanguage[lan][menukey]["Total SDP"])} 
                      </span>


                      {/* <br></br>
                      <span id="totalcase" className="text-bg">
                        {UseFor == 'WIMS' ? t(DispensingLanguage[lan][menukey]["Total Upazila"]) : t(DispensingLanguage[lan][menukey]["Total SDP"])}
                      </span> */}
                      
                      <br></br>
                      {UseFor == 'WIMS' ? (
                        
                          <span id="totalcase" className="text-bg">
                          {UseFor == 'WIMS' ? t(DispensingLanguage[lan][menukey]["Total Upazila"]) : ''}
                          </span>
                      ) : (
                          <div className=""> 
                           <span className="text-bg">
                              {DashboardMetaInfo.SDPDetailsCount}
                            </span>
                          </div>
                        )}


                          {/* <br></br><br></br>
                          <span className="text-bg">
                            {UseFor == 'WIMS' ? '' : DashboardMetaInfo.SDPDetailsCount}
                          </span> */}
                    </div>

                    {UseFor == 'WIMS' ? ('') : (
                      <div className="col-md-6 pull-right">
                      <span className="pull-right ">
                            <span className="text-xlg " id="total-patients">
                              {DashboardMetaInfo.SOCount}
                            </span>
                            <br></br>
                            <span id="totalcase" className="text-bg">
                              {t(DispensingLanguage[lan][menukey]["Stock Out"])}
                            </span>
                        </span>
                      </div>
                      )}

                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
          {/* product and upazila */}





          {/* supply plan and f7 block */}
          <div className="row">
            <div className="col-md-6 mb-3">
              <Card className="sw_card">
                <CardContent>
                  <div className="row">
                    <div className="col-md-12 ">
                      <div className="stat-cell stat-cell-color-c">
                        <i className="fa fa-file bg-icon"></i>

                        <a
                            href="javascript:void(0);"
                            className="HyColor iconPositionRight"
                            onClick={goToSupplyPlan}
                          >
                            <Launch />
                        </a>

                        <span className="text-xlg" id="total-patients">
                          {DashboardMetaInfo.LastSupplyPlan}
                        </span>
                        {/* <br></br>
                        <span id="totalcase" className="text-bg">
                          {t(
                            DispensingLanguage[lan][menukey]["Last Supply Plan"]
                          )}
                        </span> */}
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>


            <div className="col-md-6 mb-3">

              <Card className="sw_card">
                <CardContent>
                  <div className="row">
                    <div className="col-md-12 ">
                      <div className="stat-cell stat-cell-color-d">
                        <i className="fa fa-file bg-icon"></i>
                        <a
                            href="javascript:void(0);"
                            className="HyColor iconPositionRight"
                            onClick={goToF7andF7B}
                          >
                            <Launch />
                        </a>
                        <span className="text-xlg" id="total-patients">
                          {DashboardMetaInfo.Last7}
                        </span>
                       {/*  <br></br>
                        <span id="totalcase" className="text-bg">
                          {UseFor == 'WIMS' ? t(DispensingLanguage[lan][menukey]["Last F7"]) : t(DispensingLanguage[lan][menukey]["Last F7B"])}
                        </span> */}
                      </div>

                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>
          {/* supply plan and f7 block */}













          <div className="row">

            <div className="col-md-6">

              <Card className="sw_card mb-3">
                <CardHeader
                  // title={t(DispensingLanguage[lan][menukey]["Current Contraceptive Stock Status"])}
                  action={
                    <a
                      href="javascript:void(0);"
                      className="HyColor"
                      onClick={goToCurrentContraceptiveStockStatus}
                    >
                      <Launch />
                    </a>
                  }
                />

                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={CurrentStockStatusChartData}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="mylegend_area">
                       
                            <div className="my_legend">
                              <div
                                className="mylegend_color"
                                style={{ background: "#d7191c" }}
                              ></div>
                              <div className="mylegend_label"> {t(DispensingLanguage[lan][menukey]["MOS"])}: &#60; 0.1 </div>
                             
                            </div>

                            <div className="my_legend">
                              <div
                                className="mylegend_color"
                                style={{ background: "#FE9929" }}
                              ></div>
                              <div className="mylegend_label"> {t(DispensingLanguage[lan][menukey]["MOS"])}: 0.1 - 0.6 </div>
                            
                            </div>

                            <div className="my_legend">
                              <div
                                className="mylegend_color"
                                style={{ background: "#F0F403" }}
                              ></div>
                              <div className="mylegend_label"> {t(DispensingLanguage[lan][menukey]["MOS"])}: 0.6 - 1.7 </div>
                            
                            </div>

                            <div className="my_legend">
                              <div
                                className="mylegend_color"
                                style={{ background: "#4DAC26" }}
                              ></div>
                              <div className="mylegend_label"> {t(DispensingLanguage[lan][menukey]["MOS"])}: 1.7 - 3 </div>
                             
                            </div>

                            <div className="my_legend">
                              <div
                                className="mylegend_color"
                                style={{ background: "#50ABED" }}
                              ></div>
                              <div className="mylegend_label"> {t(DispensingLanguage[lan][menukey]["MOS"])}: 3+  {t(DispensingLanguage[lan][menukey]["MOS"])} </div>
                             
                            </div>

                        
                      </div>
                    </div>
                  </div>

                </CardContent>
              </Card>



            </div>


            <div className="col-md-6">

              <Card className="sw_card">
                <CardHeader
                  // title={t(DispensingLanguage[lan][menukey]["Lots expiring in 6 months"])}
                  action={
                    <a
                      href="javascript:void(0);"
                      className="HyColor"
                      onClick={goToLotsExpiringReport}
                    >
                      <Launch />
                    </a>
                  }
                />

                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={ExpiryData}
                      />
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>


          { (UseFor=='UIMS') ? <></> : ((WaitingForReceiveCount == 0) ? <></> :
<div className="row">           
      <div className="col-md-12 mb-4">
        <Card className="sw_card">
          <CardHeader
            title={DispensingLanguage[lan][menukey]["Waiting for Receive"]}
            // action={
            //   <a href="javascript:void(0);" className="gotoBtn" onClick={goToTopTenFabriquant}><Launch/></a>
            //   }
          />
          <CardContent>
            <ReactTabulator
              columns={WaitingForReceiveColumns}
              data={WaitingForReceiveData}
              layout={"fitColumns"}
              height={300}
            />
          </CardContent>
        </Card> 
      </div>
</div>

             ) }

          { PendingReceiveDataCount == 0 ? <></> :

            <div className="row">           
                  <div className="col-md-12 mb-3">
                    <Card className="sw_card">

                    { UseFor == 'WIMS' ? 
                    
                    <CardHeader
                        title={DispensingLanguage[lan][menukey]["Pending Receive"]}
                        // action={
                        //   <a href="javascript:void(0);" className="gotoBtn" onClick={goToTopTenFabriquant}><Launch/></a>
                        //   }
                      />
                    
                    :
                      <CardHeader
                        title={DispensingLanguage[lan][menukey]["Waiting for Receive"]}
                        // action={
                        //   <a href="javascript:void(0);" className="gotoBtn" onClick={goToTopTenFabriquant}><Launch/></a>
                        //   }
                      />

                      }

                      <CardContent>
                        <ReactTabulator
                          columns={columns}
                          data={PendingReceiveData}
                          layout={"fitColumns"}
                          height={300}
                        />
                      </CardContent>
                    </Card> 
                  </div>
            </div> 

            }


            

            

            {/* end of row */}

          {/* <div className="row">
            <div className="col-md-12">
              <Card className="sw_card">
                <CardHeader title="Percentage of Patients at Risk" />
                <CardContent>
                  <div className="sw_head_text">
                    <span>Total Patients: 180,104</span>
                    <span className="float-right">Patients At Risk: 8,142</span>
                  </div>
                  <div className="human_list_card mt-3">
                    <span class="badge per_badge">4.5 %</span>
                    <ul className="human_list float-right">
                      <li>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/human/human_6.png"
                          }
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/human/human_0.png"
                          }
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/human/human_0.png"
                          }
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/human/human_0.png"
                          }
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/human/human_0.png"
                          }
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/human/human_0.png"
                          }
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/human/human_0.png"
                          }
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/human/human_0.png"
                          }
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/human/human_0.png"
                          }
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          src={
                            process.env.PUBLIC_URL + "/images/human/human_0.png"
                          }
                          alt=""
                        />
                      </li>
                    </ul>
                  </div>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Formulation</TableCell>
                        <TableCell>Regimen</TableCell>
                        <TableCell align="right">Patient At Risk</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                  
                      <TableRow>
                        <TableCell component="th" scope="row">
                          1st Line Adult
                        </TableCell>
                        <TableCell>ABC/3TC/DTG-1</TableCell>
                        <TableCell align="right">2,643</TableCell>
                      </TableRow>
           
                      <TableRow>
                        <TableCell component="th" scope="row">
                          1st Line Adult
                        </TableCell>
                        <TableCell>ABC/3TC/EFV</TableCell>
                        <TableCell align="right">1,110</TableCell>
                      </TableRow>
          
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2nd Line Adult
                        </TableCell>
                        <TableCell>AZT/3TC/TDF/ATV/r</TableCell>
                        <TableCell align="right">999</TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell component="th" scope="row">
                          1st Line Paediatric
                        </TableCell>
                        <TableCell>ABC/3TC/LPV/r</TableCell>
                        <TableCell align="right">914</TableCell>
                      </TableRow>
               
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2nd Line Adult
                        </TableCell>
                        <TableCell>AZT/3TC/TDF/LPV/r</TableCell>
                        <TableCell align="right">766</TableCell>
                      </TableRow>
                  
                      <TableRow>
                        <TableCell component="th" scope="row">
                          1st Line Paediatric
                        </TableCell>
                        <TableCell>ABC/3TC/LPV/r</TableCell>
                        <TableCell align="right">735</TableCell>
                      </TableRow>
              
                      <TableRow>
                        <TableCell component="th" scope="row">
                          1st Line Adult
                        </TableCell>
                        <TableCell>TDF/3TC/ATV/r</TableCell>
                        <TableCell align="right">676</TableCell>
                      </TableRow>
                 
                      <TableRow>
                        <TableCell component="th" scope="row">
                          2nd Line Adult
                        </TableCell>
                        <TableCell>AZT/3TC/TDF/ATV/r</TableCell>
                        <TableCell align="right">299</TableCell>
                      </TableRow>
                
                
                      <TableRow>
                        <TableCell
                          style={{ "font-weight": "bold" }}
                          colSpan={2}
                        >
                          Total:
                        </TableCell>
                        <TableCell
                          style={{ "font-weight": "bold" }}
                          align="right"
                        >
                          8,142
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>
            </div>
          </div> */}
        </div>
      </div>
    </>
    )
  );
};

export default DashboardPage;
