import React, { useState, useEffect, useContext } from "react";
import {
  checkLogin,
  checkUserPermission,
} from "../../services/CheckUserAccess";
import swal from "sweetalert";
// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
// import moment from "moment";

import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Typography,
  TextField,
} from "@material-ui/core";

import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  Link,
  AddToHomeScreen,
  Launch,
  ViewList,
  ThreeSixty,
} from "@material-ui/icons";

// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

import { useQuery } from "react-query";
import * as api from "../../actions/api";
import ProductSelectInPrescriptionModal from "../../services/IssueInvoiceDetailsModal";

import { useTranslation } from "react-i18next";
// Highcharts component
import Highcharts from "highcharts/highstock";
import HighchartsHeatmap from "highcharts/modules/heatmap";
// import HighchartsMap from "highcharts/highmaps";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

import Constants from "services/Constants.js";
// import BDDistrictMap from "assets/mapfiles/bd_districts_2020_topo.v1.json";
import { UserContext } from "../../context/user-info-context";
import { getDefaultMonthYear } from "../../services/Common";

import { ReactTabulator, reactFormatter } from "react-tabulator";
import MapLeaflet from "./map_leaflet/MapLeaflet";

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    width: "20%",
    margin: "0 1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDiv: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },
});

let tblMenuRef = null;

const DashboardPage = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );

  const menukey = "mcwc-national-dashboard";

  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  const [hasUserPermission, setHasUserPermission] = React.useState(false);

  if (RedirectLogin) {
    setHasUserPermission(checkUserPermission(menukey)); // To check user has permission in this page
    checkLogin();
    setRedirectLogin(false);
  }

  const CurrentFacilityName = localStorage.getItem("FacilityName");
  const [DashboardMetaInfo, setDashboardMetaInfo] = useState([]);

  const [CurrentStockStatusChartData, setCurrentStockStatusChartData] =
    useState([]);
  const [ExpiryData, setExpiryData] = useState([]);
  const [AssetStatusDataChart, setAssetStatusDataChart] = useState([]);
  const [DispenseStatusViewChart, setDispenseStatusViewChart] = useState([]);

  const FacilityId = localStorage.getItem("FacilityId");

  const [TotalStockData, setTotalStockData] = useState([]);
  // const [TotalStockByFacilityMapData, setTotalStockByFacilityMapData] = useState([]);

  const [TotalStockByFacilityMapData, setTotalStockByFacilityMapData] =
    useState([]);
  // const [mapDataList, setMapDataList] = useState([
  //   {
  //     code3: "Bandarban",
  //     z: 106,
  //     code: "Bandarban",
  //   },
  //   {
  //     code3: "Thakurgaon",
  //     z: 34656,
  //     code: "Thakurgaon",
  //   },
  //   {
  //     code3: "Sunamganj",
  //     z: 28813,
  //     code: "Sunamganj",
  //   },
  //   {
  //     code3: "Sherpur",
  //     z: 2876,
  //     code: "Sherpur",
  //   },
  // ]);

  const [TotalStockByFacilityMap, setTotalStockByFacilityMap] = useState([]);

  // const [WaitingForReceiveData, setWaitingForReceiveData] = useState([]);
  // const [WaitingForReceiveCount,setWaitingForReceiveCount] = useState(0);

  const [NumberOfAssetStatusData, setNumberOfAssetStatusData] = useState([]);

  // console.log("BDDistrictMap");
  // console.log(BDDistrictMap);
  // exporting(Highcharts);
  HighchartsHeatmap(Highcharts);

  const userCtx = useContext(UserContext);
  const lan = localStorage.getItem("LangCode");
  const { t, i18n } = useTranslation();
  const UseFor = localStorage.getItem("UseFor");

  const [currYearId, setCurrYearId] = useState(
    getDefaultMonthYear().defaultYear
  );
  const [currMonthId, setCurrMonthId] = useState(
    getDefaultMonthYear().defaultMonthId
  );
  const [currMonthName, setCurrMonthName] = useState(
    getDefaultMonthYear().defaultMonthName
  );

  const [currRowId, setCurrRowId] = useState(0);
  const [InvType, setInvType] = useState(0);
  const [CurrFacilityCode, setCurrFacilityCode] = useState(0);
  const [openSqlLog, setopenSqlLog] = useState(false);
  const [LogDataArray, setLogDataArray] = useState([]);

  const [firstLoad, setFirstLoad] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const FacilityList = JSON.parse(localStorage.getItem("MCWCFacilityList"));
  FacilityList.unshift({
    id: 0,
    name: t(DispensingLanguage[lan][menukey]["All"]),
  });

  const [currFacilityCode, setcurrFacilityCode] = useState(
    FacilityList.length > 0 ? FacilityList[0].id : "0"
  );
  const [currFacilityName, setcurrFacilityName] = useState(
    FacilityList.length > 0
      ? FacilityList[0].name
      : DispensingLanguage[lan][menukey]["All"]
  );

  const [chosenValuesFacility, setChosenValuesFacility] = useState({});

  const handleChangeChoosenFacility = (name, valueobj, value) => {
    let chosenValuesDataFacility = { ...chosenValuesFacility };
    chosenValuesDataFacility[name] = valueobj;
    setChosenValuesFacility(chosenValuesDataFacility);

    setFirstLoad(true);
    setLoading(false);
    setcurrFacilityCode(value);
    setcurrFacilityName(valueobj.name);
  };

  const handleClose = (modalname) => {
    switch (modalname) {
      case "ProductBatch":
        setopenSqlLog(false);
        break;
      case "ProductBatchOpen":
        setopenSqlLog(true);
        break;

      default:
        break;
    }
  };

  const goToTrendOfActivePatients = () => {
    window.open(process.env.REACT_APP_BASE_NAME + `/trend-of-active-patients`);
  };

  const goToAdultPatientsByProtocol = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME + `/adult-patients-by-protocol`
    );
  };

  const goToLotsExpiringReport = () => {
    window.open(process.env.REACT_APP_BASE_NAME + `/stock-expiry-By-lot`);
  };

  const goToNumberOfAssetStatusReport = () => {
    window.open(process.env.REACT_APP_BASE_NAME + `/asset-reports`);
  };

  const goToCurrentContraceptiveStockStatus = () => {
    window.open(process.env.REACT_APP_BASE_NAME + `/stock-status`);
  };
  const goToProducts = () => {
    window.open(process.env.REACT_APP_BASE_NAME + `/stock-status`);
  };

  const goToAsset = () => {
    window.open(process.env.REACT_APP_BASE_NAME + `/asset-reports`);
  };

  const goToF3 = () => {
    window.open(process.env.REACT_APP_BASE_NAME + `/mcwc-monthly-form3`);
  };

  function ShowSqlLogPopup(props: any) {
    const rowData = props.cell._cell.row.data;

    return (
      <>
        <ViewList
          onClick={() => {
            setCurrRowId(rowData.TransactionId);
            setCurrFacilityCode(rowData.FacilityId);
            setInvType(rowData.TransactionNo);
            handleClose("ProductBatchOpen");
          }}
        />
      </>
    );
  }

  const [SelectedItemCodeData, setSelectedItemCode] = useState("");
  const [selectedrow, setSelectedrow] = useState(0);

  const TotalStockDataApi = useQuery(
    ["TotalStockDataApi"],
    () => api.getMCWCTotalStockDataNational(currFacilityCode),
    {
      onSuccess: (data) => {
        setTotalStockData(data);
        setSelectedrow(selectedrow + 1);

        /*  if (firstLoad) {
          setTotalStockData(data);
          setSelectedrow(true);
          setLoading(false);
          setFirstLoad(false);
        } */
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  const TotalStockTableColumns = [
    { title: "Id", field: "id", width: 50, visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Item Group"],
      headerFilter: true,
      field: "GroupName",
      width: 80,
      visible: false,
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Code"],
      headerFilter: true,
      field: "ItemCode",
      width: 65,
      headerSort: false,
      // visible: false
    },
    {
      title: DispensingLanguage[lan][menukey]["Item Name"],
      headerFilter: true,
      field: "ItemName",
      // visible: false
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Balance"],
      headerFilter: true,
      field: "UsableQty",
      width: 65,
      headerHozAlign: "right",
      hozAlign: "right",
      // visible: false
      headerSort: false,
    },
  ];

  // const getTotalStockByFacilityMapDataChart = useQuery(
  //   ["dashboardtotalstockbyfacilitymapchart"],
  //   () => api.getMCWCTotalStockByFacilityDataNational(),
  //   // () => api.getMCWCTotalStockByFacilityMapDataChartNational(),
  //   {
  //     onSuccess: (data) => {

  //     //  setTotalStockByFacilityMapData([
  //     //   {
  //     //     District: "Dhaka",
  //     //     TotalBalance: 150,
  //     //     Latitude: 23.816604361577657,
  //     //     Longitude: 90.37675367851594
  //     //   },
  //     //   {
  //     //     District: "Jamalpur",
  //     //     TotalBalance: 100,
  //     //     Latitude: 23.839530636199868,
  //     //     Longitude: 90.29607283134854
  //     //   }
  //     // ]);
  //     },
  //   }
  // );

  const TotalStockByFacilityDataApi = useQuery(
    ["TotalStockByFacilityDataApi"],
    () =>
      api.getMCWCTotalStockByFacilityDataNational(
        currFacilityCode,
        SelectedItemCodeData
      ),
    {
      onSuccess: (data) => {
        setTotalStockByFacilityMap(data);
        setTotalStockByFacilityMapData(data);

        /*  if (firstLoad) {
          setLoading(false);
          setFirstLoad(false);
        } */

        // console.log('data: ', data);

        // setTotalStockByFacilityMapData([
        //   {
        //     FacilityName: "Dhaka",
        //     UsableQty: 150,
        //     Latitude: 23.816604361577657,
        //     Longitude: 90.37675367851594
        //   },
        //   {
        //     FacilityName: "Jamalpur",
        //     UsableQty: 100,
        //     Latitude: 23.839530636199868,
        //     Longitude: 90.29607283134854
        //   }
        // ]);
      },
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  const rowClick = (e: any, row: any) => {
    let rowData = row._row.data;
    console.log(
      "rowClick calling for refresh rowData======== ",
      rowData.ItemCode
    );
    setSelectedItemCode(rowData.ItemCode);
    //MenuRefetch();
  };

  /* function CallDep(){
  console.log("CallDep calling for refresh ", SelectedItemCodeData);
} */

  useEffect(() => {
    console.log("useEffect calling for refresh ", SelectedItemCodeData);
    TotalStockByFacilityDataApi.refetch();
  }, [SelectedItemCodeData]);

  const TotalStockByFacilityTableColumns = [
    {
      title: DispensingLanguage[lan][menukey]["SL#"],
      hozAlign: "center",
      width: 20,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility"],
      headerFilter: true,
      field: "FacilityName",
      // visible: false
    },
    {
      title: DispensingLanguage[lan][menukey]["Balance"],
      headerFilter: true,
      field: "UsableQty",
      width: 90,
      headerHozAlign: "right",
      hozAlign: "right",
      bottomCalc: "sum",
      // visible: false
    },
  ];

  // const { data:WaitingForReceive } = useQuery(
  //   "WaitingForReceives",
  //   api.getMCWCWaitingForReceiveDataNational,
  //   {
  //     onSuccess: (data) => {
  //       setWaitingForReceiveData(data);
  //       setWaitingForReceiveCount(data.length);
  //     },
  //     refetchOnWindowFocus: false,
  //     refetchOnmount: false,
  //     refetchOnReconnect: false,
  //     retry: false,
  //     staleTime: 0,//1000 * 60 * 60 * 24,
  //   }
  // );

  // const WaitingForReceiveColumns = [
  //   {
  //     title: DispensingLanguage[lan][menukey]["Invoice No"],
  //     headerFilter:true,
  //     field: "InvNo",
  //     width: 130
  //   },
  //   {
  //     title: DispensingLanguage[lan][menukey]["Issued Date"],
  //     headerFilter:false,
  //     field: "IssuedDate",
  //     width: 180,
  //     formatter: function (cell, formatterParams) {
  //       let value = cell.getValue();
  //       return (value==null?'':moment(value).format("DD/MM/YYYY"));
  //     }
  //   },
  //   {
  //     title: DispensingLanguage[lan][menukey]["Issued From"],
  //     headerFilter:true,
  //     field: "IssuedFrom"
  //   },
  //   {
  //     title: DispensingLanguage[lan][menukey]["Issued To"],
  //     headerFilter:true,
  //     field: "IssuedTo",
  //     visible: false
  //   },
  // ];

  const NumberOfAssetStatus = useQuery(
    ["NumberOfAssetStatus"],
    () => api.getNumberOfAssetStatusDataNational(currFacilityCode),
    {
      onSuccess: (data) => {
        setNumberOfAssetStatusData(data);
        // setWaitingForReceiveCount(data.length);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0, //1000 * 60 * 60 * 24,
    }
  );

  const NumberOfAssetStatusColumns = [
    {
      title: DispensingLanguage[lan][menukey]["SL#"],
      hozAlign: "center",
      width: 70,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Asset Status"],
      headerFilter: true,
      field: "MStatus",
    },
    {
      title: DispensingLanguage[lan][menukey]["Number of Asset"],
      width: 150,
      headerFilter: false,
      field: "NumberOfAsset",
      headerHozAlign: "right",
      hozAlign: "right",
      bottomCalc: "sum",
      // bottomCalcFormatter: "money",
      // bottomCalcFormatterParams: {
      //   thousand: ",",
      //   precision: false,
      // },
    },
  ];

  const getCurrentMetaInfo = useQuery(
    ["maindashboardmetainfonational"],
    () =>
      api.getMCWCCurrentMetaInfoNational(
        currMonthId,
        currYearId,
        currFacilityCode
      ),
    {
      onSuccess: (data) => {
        setDashboardMetaInfo(data);
      },
    }
  );

  const getCurrentStockStatusChartData = useQuery(
    ["dashboardcurrentstockstatus"],
    () => api.getMCWCCurrentStockStausChartNational(currFacilityCode),
    {
      onSuccess: (data) => {
        setCurrentStockStatusChartData({
          chart: {
            type: "bar",
            borderColor: "#C3DDEC",
            height: 260,
          },
          title: {
            text: DispensingLanguage
              ? t(
                  DispensingLanguage[lan][menukey][
                    "Current Contraceptive Stock Status"
                  ]
                )
              : "",
          },
          xAxis: {
            categories: data ? data.category : [],
          },
          yAxis: {
            min: 0,
            //max: 100,
            title: {
              text: DispensingLanguage
                ? t(DispensingLanguage[lan][menukey]["MOS"])
                : "",
            },
          },
          legend: {
            enabled: false,
          },
          credits: {
            enabled: false,
          },
          tooltip: {
            //    valueSuffix: " %",
            formatter: function () {
              return (
                "<b>" +
                this.x +
                "</b><br/>MOS: " +
                this.y.toFixed(1) +
                "<br/>Qty: " +
                this.point.UsableQty
              );
            },
          },
          plotOptions: {
            bar: {
              dataLabels: {
                enabled: true,
                crop: true,
                formatter: function () {
                  // console.log("dataLabels:",this.point.UsableQty);
                  return this.y.toFixed(1); // + "%"

                  // return "MOS: "+this.y + " (Qty: "+this.point.UsableQty+")"; // + "%"
                },
              },
            },
          },
          series: [
            {
              name: DispensingLanguage
                ? t(DispensingLanguage[lan][menukey]["MOS"])
                : "",
              colorByPoint: true,
              data: data ? data.seriesdata : [], //[69.8, 18.2, 2.3, 2.1, 1.5, 1.5, 1, 0.8, 0.6, 0.6, 2.5],
            },
          ],
        });
      },
      // refetchOnWindowFocus: false,
      // enabled: false,
    }
  );

  const getExpiryData = useQuery(
    ["dashboardexpirystockstatus"],
    () => api.getMCWCExpiryDataNational(currFacilityCode),
    {
      onSuccess: (data) => {
        setExpiryData({
          chart: {
            type: "pie",
            borderColor: "#C3DDEC",
            height: 310,
          },
          title: {
            text:
              (data ? data.productcount : "") +
              " " +
              (DispensingLanguage
                ? t(
                    DispensingLanguage[lan][menukey][
                      "Lots expiring in 6 months"
                    ]
                  )
                : ""),
          },
          yAxis: {
            min: 0,
            max: 100,
          },
          legend: {
            enabled: true,
          },
          credits: {
            enabled: false,
          },
          tooltip: {},
          plotOptions: {
            pie: {
              showInLegend: true,
              dataLabels: {
                enabled: false,
                crop: true,
                formatter: function () {
                  return this.y; // + "%"
                },
              },
            },
          },
          series: [
            {
              name: DispensingLanguage
                ? t(DispensingLanguage[lan][menukey]["Products"])
                : "",
              colorByPoint: true,
              data: data ? data.seriesdata : [],
            },
          ],
        });
      },
    }
  );

  const getAssetStatusDataChart = useQuery(
    ["dashboardassetstatuschart"],
    () => api.getMCWCAssetStatusDataChartNational(currFacilityCode),
    {
      onSuccess: (data) => {
        setAssetStatusDataChart({
          chart: {
            type: "pie",
            borderColor: "#C3DDEC",
            height: 300,
          },
          title: {
            text: null,
            // text: DispensingLanguage
            //   ? t(DispensingLanguage[lan][menukey]["Number of Asset Status"])
            //   : "",
          },
          yAxis: {
            min: 0,
            max: 100,
          },
          legend: {
            enabled: true,
          },
          credits: {
            enabled: false,
          },
          tooltip: {},
          plotOptions: {
            pie: {
              showInLegend: true,
              dataLabels: {
                enabled: false,
                crop: true,
                formatter: function () {
                  return this.y; // + "%"
                },
              },
            },
          },
          series: [
            {
              name: DispensingLanguage
                ? t(DispensingLanguage[lan][menukey]["Number of Asset"])
                : "",
              colorByPoint: true,
              data: data ? data.seriesdata : [],
            },
          ],
        });
      },
    }
  );

  const getDispenseStatusViewDataChart = useQuery(
    ["dashboarddispensestatusviewchart"],
    () => api.getMCWCDispenseStatusViewDataChartNational(currFacilityCode),
    {
      onSuccess: (data) => {
        setDispenseStatusViewChart({
          chart: {
            type: "heatmap",
            marginTop: 40,
            marginBottom: 80,
            plotBorderWidth: 1,
            height:
              data.yaxiscategories.length > 1
                ? 45 * data.yaxiscategories.length
                : 150,
            // height: 310,
            //  height: 150,
          },

          title: {
            text: null,
            // style: {
            //   fontSize: "1em",
            // },
          },

          xAxis: {
            // categories: [
            //   "Alexander",
            //   "Marie",
            //   "Maximilian",
            // ],
            categories: data.xaxiscategories,
            opposite: true,
          },

          yAxis: {
            categories: data.yaxiscategories,
            // categories: [
            //   "Monday",
            //   "Friday",
            // ],
            title: null,
            reversed: true,
          },

          colorAxis: {
            min: 0,
            dataClasses: [
              {
                from: -99,
                to: 0,
                color: "#C3C5CC",
                name: "No Data",
              },
              // {
              //   from: 10,
              //   to: 200,
              //   color: "green",
              // },
              {
                from: 1,
                color: "#16469A",
                name: "Dispensed",
              },
            ],
          },
          credits: {
            enabled: false,
          },
          exporting: {
            enabled: false,
          },
          tooltip: {
            enabled: false,
          },
          //   tooltip: {
          //     format: '<b>{series.xAxis.categories.(point.x)}</b> sold<br>' +
          //         '<b>{point.value}</b> items on <br>' +
          //         '<b>{series.yAxis.categories.(point.y)}</b>'
          // },

          series: [
            {
              /* name: "Number of dispense", */
              name: DispensingLanguage
                ? t(DispensingLanguage[lan][menukey]["Number of dispense"])
                : "",
              borderWidth: 1,
              data: data.seriesdata,
              // data: [
              //   [0, 0, 0],
              //   [0, 1, -1],
              //   [0, 2, 8]
              // ],
              dataLabels: {
                enabled: true,
                style: {
                  fontWeight: "normal",
                },
                // color: "#000000",
              },
            },
          ],
        });
      },
    }
  );

  const classes = useStyles();

  /*   React.useEffect(() => {
    // checkLogin();
    // checkAccess();
  }, []);
 */

  useEffect(() => {
    if (selectedrow) {
      //console.log("selectedrow ",tblMenuRef);
      tblMenuRef.table.selectRow(1);
      let SelectedFirstCode = tblMenuRef.table.getSelectedData();
      if (SelectedFirstCode.length > 0) {
        //console.log("tblMenuRef 1",SelectedFirstCode[0].ItemCode);
        setSelectedItemCode(SelectedFirstCode[0].ItemCode);
      } else {
        setSelectedItemCode("");
      }
    }
  }, [selectedrow]);

  React.useEffect(() => {
    /*  if (tblRef == null) return; */
    setLoading(true);
    TotalStockDataApi.refetch();
    TotalStockByFacilityDataApi.refetch();
    NumberOfAssetStatus.refetch();
    getCurrentMetaInfo.refetch();
    getCurrentStockStatusChartData.refetch();
    getExpiryData.refetch();
    getAssetStatusDataChart.refetch();
    getDispenseStatusViewDataChart.refetch();
  }, [currFacilityCode]);

  return (
    hasUserPermission && (
      <>
        <AfterLoginNavbar {...props} />

        <div
          className="section signup-top-padding"
          style={{
            backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center",
            minHeight: "753px",
          }}
        >
          <div className="dashboard-pannel">
            <div className="d-flex justify-product mb-1">
              <div className="sw_page_heading">
                <div className="sw_heading_title">
                  {t(
                    DispensingLanguage[lan][menukey]["MCWC National Dashboard"]
                  )}
                </div>
              </div>
            </div>

            <ProductSelectInPrescriptionModal
              handleClose={handleClose}
              open={openSqlLog}
              FacilityId={CurrFacilityCode}
              SCurrRowId={currRowId}
              SInvType={InvType}
              {...props}
            />

            {/* new row */}

            {/* start of filter */}
            <div className="row">
              <div className="col-md-4 mb-2">
                <Card className="sw_card">
                  <CardContent>
                    <div className="row">
                      <div className="col-md-12 ">
                        <div className=" ">
                          <FormControl className={classes.fullWidth}>
                            <Autocomplete
                              autoHighlight
                              disableClearable
                              id="FacilityCode"
                              options={FacilityList}
                              onChange={(event, valueobj) =>
                                handleChangeChoosenFacility(
                                  "FacilityCode",
                                  valueobj,
                                  valueobj ? valueobj.id : ""
                                )
                              }
                              getOptionLabel={(option) => option.name}
                              defaultValue={
                                FacilityList[
                                  FacilityList.findIndex(
                                    (facilitylist) =>
                                      facilitylist.id == currFacilityCode
                                  )
                                ]
                              }
                              renderOption={(option) => (
                                <Typography className="sw_fontSize">
                                  {option.name}
                                </Typography>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label={
                                    DispensingLanguage[lan][menukey]["Facility"]
                                  }
                                  variant="standard"
                                  id="FacilityCode"
                                  name="FacilityCode"
                                  fullWidth
                                />
                              )}
                            />
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>

              <div className="col-md-4 mb-2">
                <Card className="sw_card">
                  <CardContent>
                    <div className="row ">
                      <div className="col-md-12 ">
                        <div className="stat-cell stat-cell-color-c c-height">
                          <Grid
                            className=""
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            <span id="totalcase" className="c-text-bg ">
                              {DispensingLanguage[lan][menukey]["Date"]}:{" "}
                              {DashboardMetaInfo.CurrentDate}
                            </span>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>

              <div className="col-md-4 mb-2">
                <Card className="sw_card">
                  <CardContent>
                    <div className="row">
                      <div className="col-md-12 ">
                        <div className="stat-cell stat-cell-color-d c-height">
                          <Grid
                            className=" "
                            container
                            justifyContent="center"
                            alignItems="center"
                          >
                            <span className="c-text-bg" id="total-patients">
                              {DashboardMetaInfo.Form3PostStatus}
                            </span>

                            <span id="totalcase" className="c-text-bg ">
                              &nbsp;
                              {t(
                                DispensingLanguage[lan][menukey][
                                  "Form 3 Posted"
                                ]
                              ) +
                                " (" +
                                currMonthName +
                                ", " +
                                currYearId +
                                ")"}
                            </span>
                          </Grid>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
            {/* end of filter */}

          

            {/* <div className="row mb-3">
              <div className="col-md-12">
                <Card className="sw_card ">
                  <CardContent>
                    <div className="stat-cell stat-cell-color-e">
                      <div className="row margin0auto">
                        <div className="col-md-12 ">
                          <span className="text-xlg ">
                            {
                              DispensingLanguage[lan][menukey][
                                "DGFP Electronics Logistics Management Information System (eLMIS)"
                              ]
                            }
                          </span>
                        </div>
                      </div>

                      <div className="row margin0auto">
                        <div className="col-md-4 ">
                          <span>
                            {DispensingLanguage[lan][menukey]["Date"]}:{" "}
                            {DashboardMetaInfo.CurrentDate}
                          </span>
                        </div>

                        <div className="col-md-8 ">
                       
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div> */}

            {/* Stock Status by MCWC */}
            <div className="row">
              <div className="col-md-4 mb-4">
                <Card className="sw_card">
                  <CardHeader
                    title={DispensingLanguage[lan][menukey]["Total Stock"]}
                  />
                  <CardContent>
                    <ReactTabulator
                      ref={(r) => (tblMenuRef = r)}
                      columns={TotalStockTableColumns}
                      data={TotalStockData}
                      layout={"fitColumns"}
                      height={530}
                      options={{
                        selectable: 1,
                        //selectableRangeMode: "click",
                        groupBy: "GroupName",
                        columnCalcs: "both",
                      }}
                      rowClick={(e, row) => rowClick(e, row)}
                    />
                  </CardContent>
                </Card>
              </div>
              <div className="col-md-8 mb-8">
                <Card className="sw_card">
                  <CardHeader
                    title={
                      DispensingLanguage[lan][menukey][
                        "Stock Status By Facility"
                      ]
                    }
                    // action={
                    //   <a
                    //     href="javascript:void(0);"
                    //     className="HyColor"
                    //     onClick={goToNumberOfAssetStatusReport}
                    //   >
                    //     <Launch />
                    //   </a>
                    // }
                  />
                  {/* <ReactTabulator
                        columns={NumberOfAssetStatusColumns}
                        data={NumberOfAssetStatusData}
                        layout={"fitColumns"}
                        height={300}
                      /> */}

                  <CardContent>
                    <div className="row">
                      <div className="col-md-7">
                        {/* <HighchartsReact
                          highcharts={HighchartsMap}
                          options={TotalStockByFacilityMapData}
                        /> */}

                        <MapLeaflet
                          MapData={TotalStockByFacilityMapData}
                          Height={"530px"}
                          menukey={menukey}
                          lan={lan}
                          DispensingLanguage={DispensingLanguage}
                          zoom={7}
                          {...props}
                        />
                      </div>

                      <div className="col-md-5">
                        <ReactTabulator
                          columns={TotalStockByFacilityTableColumns}
                          data={TotalStockByFacilityMap}
                          layout={"fitColumns"}
                          height={530}
                        />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>

              {/* <div className="col-md-4 mb-4">
                <Card className="sw_card">
                  <CardHeader
                    title={
                      DispensingLanguage[lan][menukey][
                        "Stock Status By Facility"
                      ]
                    }
                    // action={
                    //   <a
                    //     href="javascript:void(0);"
                    //     className="HyColor"
                    //     onClick={goToNumberOfAssetStatusReport}
                    //   >
                    //     <Launch />
                    //   </a>
                    // }
                  />
                  <CardContent>
                  <ReactTabulator
                      columns={TotalStockByFacilityTableColumns}
                      data={TotalStockByFacilityMap}
                      layout={"fitColumns"}
                      height={300}
                    />
                  </CardContent>
                </Card>
              </div> */}
            </div>

            <div className="row  mb-4">
              <div className="col-md-6">
                <Card className="sw_card">
                  {/* <CardHeader
                  // title={t(DispensingLanguage[lan][menukey]["Current Contraceptive Stock Status"])}
                  // action={
                  //   <a
                  //     href="javascript:void(0);"
                  //     className="HyColor"
                  //     onClick={goToCurrentContraceptiveStockStatus}
                  //   >
                  //     <Launch />
                  //   </a>
                  // }
                  /> */}

                  <CardContent>
                    <div className="row">
                      <div className="col-md-12">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={CurrentStockStatusChartData}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="mylegend_area">
                          <div className="my_legend">
                            <div
                              className="mylegend_color"
                              style={{ background: "#d7191c" }}
                            ></div>
                            <div className="mylegend_label">
                              {" "}
                              {t(DispensingLanguage[lan][menukey]["MOS"])}:
                              &#60; 0.1{" "}
                            </div>
                          </div>

                          <div className="my_legend">
                            <div
                              className="mylegend_color"
                              style={{ background: "#FE9929" }}
                            ></div>
                            <div className="mylegend_label">
                              {" "}
                              {t(DispensingLanguage[lan][menukey]["MOS"])}: 0.1
                              - 0.6{" "}
                            </div>
                          </div>

                          <div className="my_legend">
                            <div
                              className="mylegend_color"
                              style={{ background: "#F0F403" }}
                            ></div>
                            <div className="mylegend_label">
                              {" "}
                              {t(DispensingLanguage[lan][menukey]["MOS"])}: 0.6
                              - 1.7{" "}
                            </div>
                          </div>

                          <div className="my_legend">
                            <div
                              className="mylegend_color"
                              style={{ background: "#4DAC26" }}
                            ></div>
                            <div className="mylegend_label">
                              {" "}
                              {t(DispensingLanguage[lan][menukey]["MOS"])}: 1.7
                              - 3{" "}
                            </div>
                          </div>

                          <div className="my_legend">
                            <div
                              className="mylegend_color"
                              style={{ background: "#50ABED" }}
                            ></div>
                            <div className="mylegend_label">
                              {" "}
                              {t(DispensingLanguage[lan][menukey]["MOS"])}: 3+{" "}
                              {t(DispensingLanguage[lan][menukey]["MOS"])}{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>

              <div className="col-md-6">
                <Card className="sw_card">
                  {/* <CardHeader
                  // action={
                  //   <a
                  //     href="javascript:void(0);"
                  //     className="HyColor"
                  //     onClick={goToLotsExpiringReport}
                  //   >
                  //     <Launch />
                  //   </a>
                  // }
                  /> */}

                  <CardContent>
                    <div className="row">
                      <div className="col-md-12">
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={ExpiryData}
                        />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>

            <div className="row  mb-4">
              {/* <div className="col-md-6 mb-4">
                  <Card className="sw_card">
                    <CardHeader
                      title={DispensingLanguage[lan][menukey]["Waiting for Receive"]}
                    />
                    <CardContent>
                      <ReactTabulator
                        columns={WaitingForReceiveColumns}
                        data={WaitingForReceiveData}
                        layout={"fitColumns"}
                        height={300}
                      />
                    </CardContent>
                  </Card> 
                </div> */}
              <div className="col-md-6">
                <Card className="sw_card">
                  <CardHeader
                    title={
                      DispensingLanguage[lan][menukey]["Number of Asset Status"]
                    }
                    // action={
                    //   <a
                    //     href="javascript:void(0);"
                    //     className="HyColor"
                    //     onClick={goToNumberOfAssetStatusReport}
                    //   >
                    //     <Launch />
                    //   </a>
                    // }
                  />
                  <CardContent>
                    <ReactTabulator
                      columns={NumberOfAssetStatusColumns}
                      data={NumberOfAssetStatusData}
                      layout={"fitColumns"}
                      height={300}
                    />
                  </CardContent>
                </Card>
              </div>

              <div className="col-md-6">
                <Card className="sw_card">
                  <CardHeader
                    title={
                      DispensingLanguage[lan][menukey]["Number of Asset Status"]
                    }

                    // action={
                    //   <a
                    //     href="javascript:void(0);"
                    //     className="HyColor"
                    //     onClick={goToLotsExpiringReport}
                    //   >
                    //     <Launch />
                    //   </a>
                    // }
                  />

                  <CardContent>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={AssetStatusDataChart}
                    />
                  </CardContent>
                </Card>
              </div>
            </div>

            <div className="row  mb-4">
              <div className="col-md-12">
                <Card className="sw_card">
                  <CardHeader
                    title={
                      DispensingLanguage[lan][menukey]["Daily Dispense Status"]
                    }
                  />
                  <CardContent>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={DispenseStatusViewChart}
                    />
                  </CardContent>
                </Card>
              </div>

              {/* <div className="col-md-3 mb-3">
              <Card className="sw_card">
                <CardContent>
                  <div className="row">
                    <div className="col-md-12 ">
                      <div className="stat-cell stat-cell-color-c ">
                        <i className="fa fa-cubes bg-icon"></i>

                        <a
                            href="javascript:void(0);"
                            className="HyColor iconPositionRight"
                            onClick={goToAsset}
                          >
                            <Launch />
                        </a>

                        <span className="text-xlg" id="total-patients">
                          {DashboardMetaInfo.TotalAsset}
                        </span>
                        <br></br>
                        <span id="totalcase" className="text-bg ">
                          {t(
                            DispensingLanguage[lan][menukey][
                            "Asset"
                            ]
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div> */}

              {/* <div className="col-md-5 mb-3">
              <Card className="sw_card">
                <CardContent>
                  <div className="row">
                    <div className="col-md-12 ">
                      <div className="stat-cell stat-cell-color-d" style={{padding:'20px 8px'}}>
                        <i className="fa fa-file bg-icon"></i>
                        <a
                            href="javascript:void(0);"
                            className="HyColor iconPositionRight"
                            onClick={goToF3}
                          >
                            <Launch />
                        </a>
                        <span className="text-xlg" id="total-patients">
                          {DashboardMetaInfo.Last7}
                        </span>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div> */}
            </div>

            {/* <div className="row">
              <div className="col-md-4 mb-4">
                <Card className="sw_card">
                  <CardContent>
                    <div className="row">
                      <div className="col-md-12 ">
                        <div className="stat-cell stat-cell-color-a ">
                          

                          <span className="text-xlg" id="total-patients">
                            {DashboardMetaInfo.Form3PostStatus}
                          </span>
                          <br></br>
                          <span id="totalcase" className="text-bg ">
                            {t(
                              DispensingLanguage[lan][menukey]["Form 3 Posted"]
                            ) +
                              " (" +
                              currMonthName +
                              ", " +
                              currYearId +
                              ")"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div> */}
          </div>
        </div>
      </>
    )
  );
};

export default DashboardPage;
