import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import * as Service from "../../../services/Service.js";

import { useTranslation } from "react-i18next";
//get DispensingLanguage
const OptionsFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const UseFor = localStorage.getItem("UseFor");

  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const FacilityEmployee = JSON.parse(localStorage.getItem("FacilityEmployee"));
  const FacilityList = JSON.parse(localStorage.getItem("FacilityList"));
  const StoreType = JSON.parse(localStorage.getItem("StoreType"));

  const [AllFacilityEmployee, setAllFacilityEmployeeListLocal] = useState(FacilityEmployee);
  const FacilityId = localStorage.getItem("FacilityId");

  const lan = localStorage.getItem('LangCode');
  const menukey = "options";


  
//====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "InvPrepBy": {"EmpCode": "","EmpName": ""},
  "InvAppBy": {"EmpCode": "","EmpName": ""},
  "InvSupBy": {"EmpCode": "","EmpName": ""},
  "StoreTypeId": {"StoreTypeId": "","StoreType": ""}
});


React.useEffect(() => {
  
  let chosenValuesData = { ...chosenValues};
   chosenValuesData['InvPrepBy'] = AllFacilityEmployee[AllFacilityEmployee.findIndex(AllFacility_Employee => AllFacility_Employee.EmpCode == formData.InvPrepBy)];
   chosenValuesData['InvAppBy'] = AllFacilityEmployee[AllFacilityEmployee.findIndex(AllFacility_Employee => AllFacility_Employee.EmpCode == formData.InvAppBy)];
   chosenValuesData['InvSupBy'] = AllFacilityEmployee[AllFacilityEmployee.findIndex(AllFacility_Employee => AllFacility_Employee.EmpCode == formData.InvSupBy)];
   chosenValuesData['StoreTypeId'] = StoreType[StoreType.findIndex(Store_Type => Store_Type.StoreTypeId == formData.StoreTypeId)];
   setChosenValues(chosenValuesData); 

  }, [formData.InvPrepBy, formData.InvAppBy, formData.InvSupBy, formData.StoreTypeId]);

const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  setChosenValues(chosenValuesData); 
  props.handleChangeChoosenProps(name, value);
};

//====== End Choosen Dropdown===========

  
  function cFacilityEmployeeList() {
    let FacilityEmployeeParam = { action: "AllFacilityEmployee", FacilityId: FacilityId, menukey: "" }
    Service.default
      .postApi("source/combo_generic.php", FacilityEmployeeParam)
      .then((res) => {
        setAllFacilityEmployeeListLocal(res.datalist);
     
        //cCommuneList();
      })
      .catch((err) => { });


  }




  React.useEffect(() => {

    cFacilityEmployeeList();

  }, [formData]);


  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading text-center">
              <div className="sw_heading_title_inner">{DispensingLanguage[lan][menukey]['Options - Edit']}</div>
            </div>
          </Grid>
        </div>


        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['Options Form']} />

              <CardContent>
                <Grid container spacing={1}>

                <div className="mr-4 float-right mt-15">
                  <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
                </div> 

              
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={1}>

                    <Grid item xs={4} sm={4}></Grid>
                      <Grid item xs={4} sm={4}>
                        <TextField
                          //error={errorObject.FacilityName}
                          //helperText={errorObject.FacilityName}
                         // required
                          id="FacilityCode"
                          name="FacilityCode"
                          label={DispensingLanguage[lan][menukey]['Code']}
                          value={formData.FacilityCode}
                          disabled = {true}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>

                      {/* <Grid item xs={2} sm={2}>
                        <TextField
                          //error={errorObject.FacilityName}
                         // helperText={errorObject.FacilityName}
                          //required
                          id="UseFor"
                          name="UseFor"
                          label={DispensingLanguage[lan][menukey]['Type']}
                          value={formData.UseFor}
                          disabled = {true}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid> */}

                      <Grid item xs={4} sm={4}></Grid>
                      
                    <Grid item xs={4} sm={4}></Grid>
                      <Grid item xs={4} sm={4}>
                        <TextField
                          error={errorObject.FacilityName}
                          helperText={errorObject.FacilityName}
                          required
                          id="FacilityName"
                          name="FacilityName"
                          
                          label={(formData.UseFor=='UIMS')? DispensingLanguage[lan][menukey]['Upazila']:(formData.UseFor=='MCWC')? DispensingLanguage[lan][menukey]['MCWC']: DispensingLanguage[lan][menukey]['Warehouse']}
                          value={formData.FacilityName}
                          disabled = {true}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={4} sm={4}></Grid>



                      <Grid item xs={4} sm={4}></Grid>
                      {(UseFor=='UIMS' || UseFor=='MCWC') ? (
                                            
                          <Grid item xs={4} sm={4}>
                            <FormControl className={classes.fullWidth}>
                              <InputLabel id="demo-simple-select-helper-label">
                                {DispensingLanguage[lan][menukey]["Warehouse"]}
                              </InputLabel>
                              <Select
                                //error={errorObject.InvSupBy}
                                labelId="demo-simple-select-helper-label"
                                id="SupplyFrom"
                                name="SupplyFrom"
                                disabled={true}
                                value={formData.SupplyFrom}
                                fullWidth
                                onChange={(e) => handleChange(e)}
                                //disabled={props.bStockUpdate}
                              >
                                {FacilityList.map((item, index) => {
                                  return (
                                    <MenuItem value={item.id}>
                                      {item.name}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid> 

                      ):(<Grid item xs={4} sm={4}></Grid>)}<Grid item xs={4} sm={4}></Grid>





                      <Grid item xs={4} sm={4}></Grid>
                      <Grid item xs={4} sm={4}>
                        <TextField
                          //error={errorObject.Address}
                          //helperText={errorObject.Address}
                         // required
                          id="Address"
                          name="Address"
                          label={DispensingLanguage[lan][menukey]['Address']}
                          value={formData.Address}
                          disabled = {true}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={4} sm={4}></Grid>

                      <Grid item xs={4} sm={4}></Grid>
                      <Grid item xs={4} sm={4}>
                        <FormControl className={classes.fullWidth}>
                         {/*  <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]["Prepared By"]}
                          </InputLabel>
                          <Select
                           // error={errorObject.InvPrepBy}
                            labelId="demo-simple-select-helper-label"
                            id="InvPrepBy"
                            name="InvPrepBy"
                            value={formData.InvPrepBy}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                            //disabled={props.bStockUpdate}
                          >
                            {AllFacilityEmployee.map((item, index) => {
                              return (
                                <MenuItem value={item.EmpCode}>
                                  {item.EmpName}
                                </MenuItem>
                              );
                            })}
                          </Select> */}

                          <Autocomplete
                            autoHighlight
                          
                            /* disabled={
                              manyTableData.length > 0 ? true : props.bStockUpdate
                            } */
                            disableClearable
                            id="InvPrepBy"
                            disabled={props.bStockUpdate}
                            options={AllFacilityEmployee}
                            onChange={(event, valueobj) => handleChangeChoosen('InvPrepBy', valueobj, valueobj?valueobj.EmpCode:"")}
                            getOptionLabel={(option) => option.EmpName}
                            value={chosenValues['InvPrepBy']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.EmpName}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={formData.InvPrepBy}
                              label={DispensingLanguage[lan][menukey]["Prepared By"]}
                              variant="standard"
                              error={errorObject.InvPrepBy}
                              helperText={errorObject.InvPrepBy}
                              id="InvPrepBy"
                              name="InvPrepBy"
                              fullWidth
                            />
                            )}
                          />
                        
                        </FormControl>
                      </Grid> 
                      <Grid item xs={4} sm={4}></Grid>

                      <Grid item xs={4} sm={4}></Grid>
                      <Grid item xs={4} sm={4}>
                        <FormControl className={classes.fullWidth}>
                          {/* <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]["Approved By"]}
                          </InputLabel>
                          <Select
                           // error={errorObject.InvAppBy}
                            labelId="demo-simple-select-helper-label"
                            id="InvAppBy"
                            name="InvAppBy"
                            value={formData.InvAppBy}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                            //disabled={props.bStockUpdate}
                          >
                            {AllFacilityEmployee.map((item, index) => {
                              return (
                                <MenuItem value={item.EmpCode}>
                                  {item.EmpName}
                                </MenuItem>
                              );
                            })}
                          </Select> */}

                          <Autocomplete
                            autoHighlight
                          
                            /* disabled={
                              manyTableData.length > 0 ? true : props.bStockUpdate
                            } */
                            disableClearable
                            id="InvAppBy"
                            disabled={props.bStockUpdate}
                            options={AllFacilityEmployee}
                            onChange={(event, valueobj) => handleChangeChoosen('InvAppBy', valueobj, valueobj?valueobj.EmpCode:"")}
                            getOptionLabel={(option) => option.EmpName}
                            value={chosenValues['InvAppBy']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.EmpName}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={formData.InvAppBy}
                              label={DispensingLanguage[lan][menukey]["Approved By"]}
                              variant="standard"
                              error={errorObject.InvAppBy}
                              helperText={errorObject.InvAppBy}
                              id="InvAppBy"
                              name="InvAppBy"
                              fullWidth
                            />
                            )}
                          />
                          
                        </FormControl>
                      </Grid> 
                      <Grid item xs={4} sm={4}></Grid>

                      <Grid item xs={4} sm={4}></Grid>
                      <Grid item xs={4} sm={4}>
                        <FormControl className={classes.fullWidth}>
                          {/* <InputLabel id="demo-simple-select-helper-label">
                            {DispensingLanguage[lan][menukey]["Issued By"]}
                          </InputLabel>
                          <Select
                            //error={errorObject.InvSupBy}
                            labelId="demo-simple-select-helper-label"
                            id="InvSupBy"
                            name="InvSupBy"
                            value={formData.InvSupBy}
                            fullWidth
                            onChange={(e) => handleChange(e)}
                            //disabled={props.bStockUpdate}
                          >
                            {AllFacilityEmployee.map((item, index) => {
                              return (
                                <MenuItem value={item.EmpCode}>
                                  {item.EmpName}
                                </MenuItem>
                              );
                            })}
                          </Select> */}

                          <Autocomplete
                            autoHighlight
                          
                            /* disabled={
                              manyTableData.length > 0 ? true : props.bStockUpdate
                            } */
                            disableClearable
                            id="InvSupBy"
                            disabled={props.bStockUpdate}
                            options={AllFacilityEmployee}
                            onChange={(event, valueobj) => handleChangeChoosen('InvSupBy', valueobj, valueobj?valueobj.EmpCode:"")}
                            getOptionLabel={(option) => option.EmpName}
                            value={chosenValues['InvSupBy']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.EmpName}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={formData.InvSupBy}
                              label={DispensingLanguage[lan][menukey]["Issued By"]}
                              variant="standard"
                              error={errorObject.InvSupBy}
                              helperText={errorObject.InvSupBy}
                              id="InvSupBy"
                              name="InvSupBy"
                              fullWidth
                            />
                            )}
                          />
                        </FormControl>
                      </Grid> 
                      <Grid item xs={4} sm={4}></Grid>


                      <Grid item xs={4} sm={4}></Grid>
                      {UseFor=='UIMS' ? (
                                             
                          <Grid item xs={4} sm={4}>
                            <FormControl className={classes.fullWidth}>
                              {/* <InputLabel id="demo-simple-select-helper-label">
                                {DispensingLanguage[lan][menukey]["Store Type"]}
                              </InputLabel>
                              <Select
                                //error={errorObject.InvSupBy}
                                labelId="demo-simple-select-helper-label"
                                id="StoreTypeId"
                                name="StoreTypeId"
                                value={formData.StoreTypeId}
                                fullWidth
                                onChange={(e) => handleChange(e)}
                                //disabled={props.bStockUpdate}
                              >
                                {StoreType.map((item, index) => {
                                  return (
                                    <MenuItem value={item.StoreTypeId}>
                                      {item.StoreType}
                                    </MenuItem>
                                  );
                                })}
                              </Select> */}

                            <Autocomplete
                                autoHighlight
                              
                                /* disabled={
                                  manyTableData.length > 0 ? true : props.bStockUpdate
                                } */
                                disableClearable
                                id="StoreTypeId"
                                disabled={props.bStockUpdate}
                                options={StoreType}
                                onChange={(event, valueobj) => handleChangeChoosen('StoreTypeId', valueobj, valueobj?valueobj.StoreTypeId:"")}
                                getOptionLabel={(option) => option.StoreType}
                                value={chosenValues['StoreTypeId']}
                                renderOption={(option) => (
                                <Typography className="sw_fontSize">{option.StoreType}</Typography>
                                )}
                                renderInput={(params) => (
                                <TextField
                                  {...params}
                                  value={formData.StoreTypeId}
                                  label={DispensingLanguage[lan][menukey]["Store Type"]}
                                  variant="standard"
                                  error={errorObject.StoreTypeId}
                                  helperText={errorObject.StoreTypeId}
                                  id="StoreTypeId"
                                  name="StoreTypeId"
                                  fullWidth
                                />
                                )}
                              />
                            </FormControl>
                          </Grid> 

                      ):""}<Grid item xs={4} sm={4}></Grid>
                      
                      




                      <Grid item xs={4} sm={4}></Grid>

                    </Grid>
                  </Grid>

                </Grid>

              
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}
          {addProductForm ? (
            <Grid item xs={12} className="mt-4 text-center">
              <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => handleReset()}
              >
                 {DispensingLanguage[lan][menukey]['Reset']} 
              </Button>
              <Button
                disabled={props.bStockUpdate}
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
               {DispensingLanguage[lan][menukey]['Save']}
              </Button>
              {/* <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/options")}
              >
                 {DispensingLanguage[lan][menukey]['Cancel']}
              </Button> */}
            </Grid>
          ) : (
            <Grid item xs={12} className="mt-2 text-center">
              <Button
                disabled={props.bStockUpdate}
                className="mr-2"
                variant="contained"
                color="primary"
                onClick={() => handleUpdate()}
              >
                {DispensingLanguage[lan][menukey]['Save']}
              </Button>
              {/* <Button
                className="mr-2"
                variant="contained"
                type="reset"
                onClick={() => props.history.push("/options")}
              >
                {DispensingLanguage[lan][menukey]['Cancel']}
              </Button> */}
            </Grid>
          )}
          
        </Grid>
      </div>
    </div>
  );
};

export default OptionsFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
