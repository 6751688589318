import React, { useState , useEffect} from "react";
import {
  Typography
} from "@material-ui/core";
import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query'
import { useParams } from 'react-router-dom';
import swal from "sweetalert";
import * as api from '../../../actions/api';
import DispenserFormData from './LMISForm2FormData.js';
// import history from './../../../history';
import { useTranslation } from "react-i18next";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";
import moment from "moment";

const EditLMISForm2 = (props) => {

  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey =  "lmisform2";
const FacilityId=localStorage.getItem('FacilityId');
   

  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    TransactionNo: "", 
    TransactionDate: "", 
    TransactionTypeId:0,
    "FacilityId":FacilityId,
    FacilityCode:FacilityId,
    TotalUpazila:0,
    YearName:"", 
    MonthId:"", 
    Remarks:"",
    bUpdated:0,
    RCondom:"", 
    RShukhi:"", 
    RIdu:"", 
    RInjectable:"", 
    ReportedMonthId:"",
    UnionCode:"",
    ReportedYear:"",
    TotalWorkers:"",
    ReportsSubmitted:"",
    ExistingbShow:"",
    UnionName:"",
    });
    const [selectedDate, setSelectedDate] = useState(
        //new Date()
        moment().format("YYYY-MM-DD")
    );
    const handleDateChange = (date) => {
      setSelectedDate(date);
    };
  const [ManyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState("0");
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
  const [TransactionId, setTransactionId] = useState("0");
  const [deletedDataSet, setDeletedDataSet] = useState([]);

  const [StockPost, setStockPost] = useState(false);
  
  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState( '');
  const [selectedYearName, setSelectedYearName] = useState(''); 
  const [bFirst, setBFirst] = useState(true); 

  const [isPreLoading, setPreLoading] = useState(false);
  const [currItemCode, setCurrItemCode] = useState("");
  // const [currItemCode, setCurrItemCode] = useState("CON008");

  
const handleRDateChange = (date, field) => {
  let newDate = moment(date).format("YYYY-MM-DD");
  setFormData({ ...formData, [field]: newDate });
  setErrorObject({ ...errorObject, [field]: null });
  api.dirtyModeEnable();
};


  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();


  const { data,refetch:ReloadItemTable } = useQuery(['lmisform2', id, currItemCode], () =>
    api.getLMISForm2Single(id,currItemCode).then((res) => {

      //This is for user has VIEW/EDIT permission in this page start
      if (props.permissionType === 1) {
        //when permissionType then it is only VIEW permission otherwise normal permission
        res.data.bStockUpdated = 1;
      }
      //This is for user has VIEW/EDIT permission in this page end


      setTransactionId(id); 
      setFormData(res.data); 
      setbStockUpdate(res.data.bStockUpdated);
      setManyTableData(res.data.ManyJsonSave);//res.data.ManyJsonSave
      setUpdatedDetailGridData(res.data.ManyJsonSave);
      setGeneratedInvoiceNo(res.data.TransactionNo);
      setSelectedYearName(res.data.YearName);
      setDeletedDataSet([]);
      setBFirst(true);

      
      api.dirtyModeDisable();
      return res.data
    }), {
    refetchOnWindowFocus: false,
    enabled: Boolean(id)
  })

  const reFetchItems = (SegmentSelectItemCode) => {
    setCurrItemCode(SegmentSelectItemCode);
  };

  useEffect(() => { 
    ReloadItemTable();
  }, [currItemCode]); 
 
   


  
  // const { isLoading, mutate:mutateUpdate } = useMutation(api.LMISForm2Update, {
  //   onSuccess: (data) => {
  //     console.log('mutateUpdate data: ', data);
  //     if (data.status == 200) {
 
  //       setbStockUpdate(data.bStockUpdated);
  //       // console.log('datadatadatadatadatadatadata: ', data);
  //       if(data.bStockUpdated==1){
  //         //Generate form2 items or post
  //         let fDtat={...formData,'ManyJsonSave':ManyTableData,"bStockUpdated":0,'deletedDataSet':deletedDataSet};
  //         mutateGenerateForm7(fDtat); 
  //       }
  //       // else if(data.bStockUpdated==2){
  //       //   //Generate issue voucher
  //       //   let fDtat={...formData,'ManyJsonSave':ManyTableData,"bStockUpdated":0,'deletedDataSet':deletedDataSet};
  //       //   mutateGenerateIssueVoucher(fDtat); 
  //       // }
  //       else{
  //         ReloadItemTable();

  //         props.openNoticeModal({
  //           isOpen: true,
  //           msg: data.message,
  //           msgtype: data.success,
  //         });
  //         setPreLoading(false);
  //         //swal("Success!", "", "success");
  //       }


  //     }else{

  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.message,
  //         msgtype: data.success,
  //       });
  //       setPreLoading(false);
  //     }
  //   }
  // })


  // const { isLoading1, mutate:mutatePost } = useMutation(api.postIssueVoucherIndent, {
  //   onSuccess: (data) => { 
  //     if (data.status == 200) { 

  //       setbStockUpdate(1);
  //       setDeletedDataSet([]);

  //       if(data.data.success==0){ 

  //         props.openNoticeModal({
  //           isOpen: true,
  //           msg: data.data.message,
  //           msgtype: data.data.success,
  //         });
         
  //        }else{
         
  //         props.openNoticeModal({
  //           isOpen: true,
  //           msg: data.data.message,
  //           msgtype: data.data.success,
  //         });
  
  //        } 

  //     }else{

  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.data.message,
  //         msgtype: data.data.success,
  //       });
  //     }
  //   }
  // })


  // const { isLoadingF7, mutate:mutateGenerateForm7 } = useMutation(api.generateLMISForm2Items, {
  //   onSuccess: (data) => {
  //     console.log('mutateGenerateForm7 data: ', data);
 
  //     if (data.status == 200) {
 
  //       if(data.data.success==1){ 
  //         ReloadItemTable();

  //         props.openNoticeModal({
  //           isOpen: true,
  //           msg: data.data.message,
  //           msgtype: data.data.success,
  //         });
         
  //        }else{
  //         props.openNoticeModal({
  //           isOpen: true,
  //           msg: data.data.message,
  //           msgtype: data.data.success,
  //         });
  //       }

  //     }else{
  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.message,
  //         msgtype: data.success,
  //       });
  //     }

  //     setPreLoading(false);

  //   }
  // })
 
  
  
  // const { isLoadingSP, mutate:mutateGenerateIssueVoucher } = useMutation(api.generateIssueVoucher, {
  //   onSuccess: (data) => {
  //     console.log('mutateGenerateIssueVoucher data: ', data);
 
  //     if (data.status == 200) {
 
  //       if(data.data.success==1){ 
  //         ReloadItemTable();

  //         props.openNoticeModal({
  //           isOpen: true,
  //           msg: data.data.message,
  //           msgtype: data.data.success,
  //         });
         
  //        }else{
  //         props.openNoticeModal({
  //           isOpen: true,
  //           msg: data.data.message,
  //           msgtype: data.data.success,
  //         });
  //       }

  //     }else{
  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.message,
  //         msgtype: data.success,
  //       });
  //     }

  //     setPreLoading(false);

  //   }
  // })





  const handleAdd = (NonOrderProductData) => {
      
    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);
    

};
  const handleChange = (e) => {
    const { name, value } = e.target;
     
      let data = { ...formData };
      data[name] = value;   
      setFormData(data); 
    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };

  //====== Start Choosen Dropdown===========
  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value;   
    setFormData(data); 
    setErrorObject({ ...errorObject, [name]: "" });
    api.dirtyModeEnable();
  }
  //====== End Choosen Dropdown===========

  const handleCheck = (e) => {
    
    // console.log('handleCheck e: ', e);
    const { name, checked } = e.target;
    // console.log('name, checked: ', name, checked);

    setFormData({ ...formData, [name]: checked });
  };


  // const invoiceCancel = (e)=>{

  //   const { name, checked } = e.target;
  //   swal({
  //     title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
  //     text: t(DispensingLanguage[lan][menukey]["Do you really want to cancel?"])+" "+t(DispensingLanguage[lan][menukey]["You will not able to edit this invoice after cancel"]),
  //     icon: "warning",
  //     buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
  //     t(DispensingLanguage[lan][menukey]["Yes"])],
  //     dangerMode: true,
  //   }).then((willCancel) => {
  //     if (willCancel) {

  //       let fDtat={...formData,'ManyJsonSave':ManyTableData,"BCancel":1,"TableName":"form7master",'deletedDataSet':deletedDataSet};
  //       mutateUpdate(fDtat);
  //     }else{
  //       setFormData({ ...formData, [name]: false });
  //     }
  //   });

  // };

  
 
  

  const handleReset = () => {
    setFormData({
      TransactionNo: "", 
      TransactionDate: "", 
      TransactionTypeId:0,
      "FacilityId":FacilityId,
      FacilityCode:FacilityId,
      TotalUpazila:0,
      YearName:"", 
      MonthId:"", 
      Remarks:"",
      bUpdated:0,
      RCondom:"", 
      RShukhi:"", 
      RIdu:"", 
      RInjectable:"", 
      ReportedMonthId:"",
      UnionCode:"",
      ReportedYear:"",
      TotalWorkers:"",
      ReportsSubmitted:"",
      ExistingbShow:"",
      UnionName:"",
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["UnionCode","TransactionDate", "TransactionNo","ReportedYear","ReportedMonthId"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false
      }
    })
    setErrorObject(errorData);
    return isValid
  }

  const deleteReceiveInv=(data)=>{
    console.log(ManyTableData);
    let newarray = ManyTableData.filter(element => element !== data);
    
    setManyTableData(newarray);
    setUpdatedDetailGridData(newarray);
    if(data.TransactionItemId!=null){
      let newDeletedDataSet=[data.TransactionItemId]; 
      setDeletedDataSet(newDeletedDataSet.concat(deletedDataSet)); 
    }
    

  }

  // const handleUpdate = async (e) => {
  //   if (validateForm(formData)) {
  //   let fDtat={...formData,'ManyJsonSave':ManyTableData,'deletedDataSet':deletedDataSet}; 
  //   mutateUpdate(fDtat); 
  //   }
  // };

  
  
  // const generateForm7Items = async (e) => {  
    
  //   if (validateForm(formData)) {
  //     swal({
  //       title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
  //       text: t(DispensingLanguage[lan][menukey]["Do you really want to fill the Form 2 items?"]),
  //       icon: "warning",
  //       buttons: {
  //         confirm: {
  //           text: t(DispensingLanguage[lan][menukey]["Yes"]),
  //           value: true,
  //           visible: true,
  //           className: "",
  //           closeModal: true,
  //         },
  //         cancel: {
  //           text: t(DispensingLanguage[lan][menukey]["No"]),
  //           value: null,
  //           visible: true,
  //           className: "",
  //           closeModal: true,
  //         },
  //       },
  //       /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
  //       t(DispensingLanguage[lan][menukey]["Yes"])],
  //       dangerMode: true, */
  //     }).then((willAction) => {
  //       if (willAction) {
  //         setPreLoading(true);
  //         let fDtat={...formData,'ManyJsonSave':ManyTableData,"bStockUpdated":1,'deletedDataSet':deletedDataSet};
  //         mutateUpdate(fDtat); 
          
  //       }else{
  //         // setbStockUpdate(0);
  //         // let route = `${TransactionId}`;
  //         // props.history.push(route);
  //       }
  //     });  
  //     // End Post Alert
  //   }
     
  // };


  
  // const generateIssueVoucher = async (e) => {
  //   if (validateForm(formData)) {
  //     swal({
  //       title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
  //       text: t(DispensingLanguage[lan][menukey]["Do you want to generate Issue Voucher from supply plan?"]),
  //       icon: "warning",
  //       buttons: {
  //         confirm: {
  //           text: t(DispensingLanguage[lan][menukey]["Yes"]),
  //           value: true,
  //           visible: true,
  //           className: "",
  //           closeModal: true,
  //         },
  //         cancel: {
  //           text: t(DispensingLanguage[lan][menukey]["No"]),
  //           value: null,
  //           visible: true,
  //           className: "",
  //           closeModal: true,
  //         },
  //       },
  //       /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
  //       t(DispensingLanguage[lan][menukey]["Yes"])],
  //       dangerMode: true, */
  //     }).then((willAction) => {
  //       if (willAction) {
  //         setPreLoading(true);
  //         // let fDtat={...formData,'ManyJsonSave':ManyTableData,"bStockUpdated":0,'deletedDataSet':deletedDataSet};
  //         // mutateGenerateForm7(fDtat); 
  //         let fDtat={...formData,'ManyJsonSave':ManyTableData,"bStockUpdated":2,'deletedDataSet':deletedDataSet};
  //         mutateUpdate(fDtat); 
          
  //       }else{
  //         // setbStockUpdate(0);
  //         // let route = `${TransactionId}`;
  //         // props.history.push(route);
  //       }
  //     });  
  //     // End Post Alert
  //   }
     
  // };

  // const handlePost = async (e) => {  
  //   ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //   if (validateForm(formData)) {
  //     swal({
  //       title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
  //       text: t(DispensingLanguage[lan][menukey]["Do you really want to post?"]),
  //       icon: "warning",
  //       buttons: {
  //         confirm: {
  //           text: t(DispensingLanguage[lan][menukey]["Yes"]),
  //           value: true,
  //           visible: true,
  //           className: "",
  //           closeModal: true,
  //         },
  //         cancel: {
  //           text: t(DispensingLanguage[lan][menukey]["No"]),
  //           value: null,
  //           visible: true,
  //           className: "",
  //           closeModal: true,
  //         },
  //       },
  //       /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
  //       t(DispensingLanguage[lan][menukey]["Yes"])],
  //       dangerMode: true, */
  //     }).then((willAction) => {
  //       if (willAction) {
  //         setPreLoading(true);
  //         let fDtat={...formData,'ManyJsonSave':ManyTableData,"bStockUpdated":1,'deletedDataSet':deletedDataSet};
  //         mutateUpdate(fDtat); 
          
  //       }else{
  //         setbStockUpdate(0);
  //         let route = `${TransactionId}`;
  //         props.history.push(route);
  //       }
  //     });  
  //     // End Post Alert

      
  //   }
     
     
  // };


  // const setUpdatedDetailsGridDataHandler = (rows) => {
       
  //   if (validateForm(formData)) {
  //     let fDtat={...formData,'ManyJsonSave':rows,'deletedDataSet':deletedDataSet}; 
  //     mutateUpdate(fDtat); 
  //     }

  //   setManyTableData(rows); 
  // };

  
  // const deleteForm7Items = (data) => {
  //   swal({
  //     title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
  //     text: t(
  //       DispensingLanguage[lan][menukey][
  //         "Do you really want to delete the selected Form 2?"
  //       ]
  //     ),
  //     icon: "warning",
  //     buttons: {
  //       confirm: {
  //         text: t(DispensingLanguage[lan][menukey]["Yes"]),
  //         value: true,
  //         visible: true,
  //         className: "",
  //         closeModal: true,
  //       },
  //       cancel: {
  //         text: t(DispensingLanguage[lan][menukey]["No"]),
  //         value: null,
  //         visible: true,
  //         className: "",
  //         closeModal: true,
  //       },
  //     },
  //     /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
  //     t(DispensingLanguage[lan][menukey]["Yes"])],
  //     dangerMode: true, */
  //   }).then((willDelete) => {
  //     if (willDelete) {
  //       deleteForm7ItemsMutate(formData.TransactionNo);
  //     }
  //   });
  // };

  
  // const { mutate:deleteForm7ItemsMutate } = useMutation(api.deleteLMISForm2Items, {
  //   onSuccess: (data) => {
  //     if (data.status == 200) {
  //       ReloadItemTable();
   
  //     queryClient.getQueriesData("lmisform2");
      
  //     props.openNoticeModal({
  //       isOpen: true,
  //       msg: data.message,
  //       msgtype: data.success,
  //       duration: 10000,
  //     });

  //     }else{ 
  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.message,
  //         msgtype: data.success,
  //       });  
           
  //       }
  //   },
  // });

  
  function checkValidateForm(rows, callfrom){

    if(callfrom == "edit"){
      setManyTableData(rows); 
    }

    // return validateForm(formData);
    if (validateForm(formData)) {
        let fDtat={...formData,'ManyJsonSave':rows,'deletedDataSet':deletedDataSet}; 
        //mutateUpdate(fDtat); 
        return fDtat;
      }else{
        return false;
      }
  };

  function formDataForGenerate(bStockUpdated){

    let fDtat={...formData,'ManyJsonSave':ManyTableData,"bStockUpdated":bStockUpdated,'deletedDataSet':deletedDataSet};
        return fDtat;
  };

  function refetchInvoice(){
    ReloadItemTable();
  };

  function stockPosted(val){
    setbStockUpdate(val);
    // setDeletedDataSet([]);
  };

  function setPreLoadingState(val){
    setPreLoading(val);
  };

  return (
    <div className="sw_relative">
      {isPreLoading && <LoadingSpinnerOpaque />}
      <DispenserFormData 
        errorObject={errorObject} 
        addProductForm={false}
        formData={formData} 
        handleChange={handleChange}
        handleChangeChoosenProps={handleChangeChoosenProps} 
        handleCheck={handleCheck} 
        handleReset={handleReset} 
        // invoiceCancel={invoiceCancel} 
        // handleUpdate={handleUpdate}  
        ManyTableData={updatedDetailGridData} 
        handleRDateChange={handleRDateChange} 
        selectedDate={selectedDate} 
        handleAdd={handleAdd} 
        bStockUpdate={bStockUpdate} 
        // setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler} 
        // handlePost={handlePost} 
        deleteReceiveInv={deleteReceiveInv} 
        generatedInvoiceNo={generatedInvoiceNo} 
        selectedYearName={selectedYearName}
        bFirst={bFirst}
        // generateForm7Items={generateForm7Items} 
        isPreLoading={isPreLoading} 
        // deleteForm7Items={deleteForm7Items} 
        reFetchItems={reFetchItems}
        ReloadItemTable={ReloadItemTable}
        setPreLoadingState={setPreLoadingState}
        formDataForGenerate={formDataForGenerate}
        // generateIssueVoucher={generateIssueVoucher}
        
        checkValidateForm={checkValidateForm}
        refetchInvoice={refetchInvoice}
        stockPosted={stockPosted}
        {...props} />
    </div>
  );
};

export default EditLMISForm2;