import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  Grid,
  TextField,
  Button,
  FormControl,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Snackbar,
  Stack,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import swal from "sweetalert";
import * as api from "../../../actions/api";

const AssetUnitEntryForm = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  open,
  ...props
}) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "asset-unit-entry";
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const DepartmentList = JSON.parse(localStorage.getItem("DepartmentList"));
  console.log("DepartmentList:", DepartmentList);

  //====== Start Choosen Dropdown===========
  const [chosenValues, setChosenValues] = useState({
    DeptId: { DeptId: "", DeptName: "" },
  });

  useEffect(() => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData["DeptId"] =
      DepartmentList[
        DepartmentList.findIndex(
          (Department_List) => Department_List.DeptId == formData.DeptId
        )
      ];
    console.log("DepartmentList:", DepartmentList);

    setChosenValues(chosenValuesData);
  }, [formData.DeptId]);

  const handleChangeChoosen = (DeptName, valueobj, value) => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData[DeptName] = valueobj;
    setChosenValues(chosenValuesData);
    props.handleChangeChoosenProps(DeptName, value);
  };

  //====== End Choosen Dropdown===========

  const backtolist = () => {
    let master_dirty_message_class = document
      .getElementById("master_dirty_message")
      .getAttribute("class");
    let idx = master_dirty_message_class.indexOf("dnone");
    if (idx === -1) {
      // start Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey][
            "You have unsaved changes which will be lost. Continue?"
          ]
        ),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          props.history.push("/asset-unit-entry");
        }
      });
      // End Alert
    } else {
      props.history.push("/asset-unit-entry");
    }
  };

  const onSaveUpdateBtnClick = () => {
    setUpdatedDetailsGridDataHandler();
  };

  const setUpdatedDetailsGridDataHandler = () => {
    let validForm = props.checkValidateForm();

    // console.log('isval: ', validForm);
    if (validForm) {
      if (addProductForm) {
        //when save
        mutate(validForm);
      } else {
        //when update
        mutateUpdate(validForm);
      }
    }
  };

  const { isLoading: isLoadingSave, mutate } = useMutation(api.saveAssetUnit, {
    onSuccess: (data) => {
      console.log("data: ", data);

      if (data.status == 200) {
        // api.getAllDropdown("ABC_List").then((response) => {
        //   localStorage.setItem(
        //     "ABC_List",
        //     JSON.stringify(response.datalist.ABC_List)
        //   );
        // });

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        queryClient.getQueriesData("AssetUnit"); //api page name
        props.history.push("/asset-unit-entry"); //
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        queryClient.getQueriesData("AssetUnit"); //api page name
        props.history.push("/asset-unit-entry"); //
      }
    },
  });

  const { isLoading: isLoadingUpdate, mutate: mutateUpdate } = useMutation(
    api.updateAssetUnit,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          // api.getAllDropdown("ABC_List").then((response) => {
          //   localStorage.setItem(
          //     "ABC_List",
          //     JSON.stringify(response.datalist.ABC_List)
          //   );
          // });

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

          queryClient.getQueriesData("AssetUnit"); // include the page name
          props.history.push("/asset-unit-entry"); //page url
          api.dirtyModeDisable();
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      },
    }
  );

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {
                  DispensingLanguage[lan][menukey][
                    "Asset Unit Entry - Add/Edit"
                  ]
                }
              </div>
            </div>
            <div className="float-right sw_btn_control">
              {addProductForm ? (
                <Grid item xs={12} className="text-center">
                  <div className="float-right sw_btn_control">
                    <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => handleReset()}
                    >
                      {DispensingLanguage[lan][menukey]["Reset"]}
                    </Button>
                    <Button
                      disabled={props.bStockUpdate || isLoadingSave}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                      // onClick={() => handleSubmit()}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={() => props.history.push("/menu-entry")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Cancel"]}
                    </Button>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} className="mt-2 text-center">
                  <Button
                    disabled={props.bStockUpdate || isLoadingUpdate}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClick}
                    // onClick={() => handleUpdate()}
                  >
                    {DispensingLanguage[lan][menukey]["Update"]}
                  </Button>

                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    //onClick={() => props.history.push("/menu-entry")}
                    onClick={() => backtolist()}
                  >
                    {DispensingLanguage[lan][menukey]["Cancel"]}
                  </Button>
                </Grid>
              )}
            </div>

            <div className="mr-4 float-right mt-15">
              <span
                id="master_dirty_message"
                className={"masterredtextcolor dnone"}
              >
                {" "}
                {DispensingLanguage[lan][menukey]["You have unsaved changes"]}
              </span>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item xs={6} sm={6}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        // disableClearable
                        disabled={props.permissionType}
                        id="DeptId"
                        options={DepartmentList}
                        onChange={(event, valueobj) =>
                          handleChangeChoosen(
                            "DeptId",
                            valueobj,
                            valueobj ? valueobj.DeptId : ""
                          )
                        }
                        getOptionLabel={(option) =>
                          option && option.DeptName ? option.DeptName : ""
                        }
                        value={chosenValues["DeptId"] || null} // Ensure value is not undefined
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.DeptName}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={formData.DeptId}
                            label={
                              DispensingLanguage[lan][menukey]["Department"]
                            }
                            variant="standard"
                            error={errorObject.DeptId}
                            helperText={errorObject.DeptId}
                            id="DeptId"
                            name="DeptId"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} sm={6}>
                    <TextField
                      error={errorObject.SectionName}
                      helperText={errorObject.SectionName}
                      required
                      disabled={props.permissionType}
                      id="SectionName"
                      name="SectionName"
                      label={
                        DispensingLanguage[lan][menukey]["Asset Unit Name"]
                      }
                      value={formData.SectionName}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                </Grid>

                {/* New Row */}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default AssetUnitEntryForm;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
