import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Autocomplete from "@material-ui/lab/Autocomplete";
import swal from "sweetalert";
import * as api from "../../../actions/api";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

//get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "user-entry";

const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
const userId = UserInfo == 0 ? "" : UserInfo[0].id;

const DispenserFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  const classes = useStyles();

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const RoleList = JSON.parse(localStorage.getItem("RoleList"));
  const LanguageList = JSON.parse(localStorage.getItem("LanguageList"));
  const FacilityList = JSON.parse(localStorage.getItem("FacilityList"));
  //FacilityList.unshift({'id':"",'name':t(DispensingLanguage[lan][menukey]["All Facility"])});

  //====== Start Choosen Dropdown===========
  const [chosenValues, setChosenValues] = useState({
    role_id: { id: "", name: "" },
    FacilityId: { id: "", name: "" },
  });

  useEffect(() => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData["role_id"] =
      RoleList[
        RoleList.findIndex((Role_List) => Role_List.id == formData.role_id)
      ];
    chosenValuesData["FacilityId"] =
      FacilityList[
        FacilityList.findIndex(
          (Facility_List) => Facility_List.id == formData.FacilityId
        )
      ];
    setChosenValues(chosenValuesData);
  }, [formData.role_id, formData.FacilityId]);

  const handleChangeChoosen = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;
    setChosenValues(chosenValuesData);
    props.handleChangeChoosenProps(name, value);
  };

  //====== End Choosen Dropdown===========

  const getRxLevel = (type = null) => {
    let level = "";
    if (formData.rxLevel) {
      level = formData.rxLevel;
    }
    return level;
  };

  const backtolist = () => {
    let master_dirty_message_class = document
      .getElementById("master_dirty_message")
      .getAttribute("class");
    let idx = master_dirty_message_class.indexOf("dnone");
    if (idx === -1) {
      // start Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey][
            "You have unsaved changes which will be lost. Continue?"
          ]
        ),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          props.history.push("/user-entry");
        }
      });
      // End Alert
    } else {
      props.history.push("/user-entry");
    }
  };

  const onSaveUpdateBtnClick = () => {
    setUpdatedDetailsGridDataHandler();
  };

  const setUpdatedDetailsGridDataHandler = () => {
    let validForm = props.checkValidateForm();

    // console.log('isval: ', validForm);
    if (validForm) {
      if (addProductForm) {
        //when save
        mutate(validForm);
      } else {
        //when update
        mutateUpdate(validForm);
      }
    }
  };

  const { isLoading: isLoadingSave, mutate } = useMutation(api.saveUser, {
    onSuccess: (data) => {
      console.log("data: ", data);

      if (data.status == 200) {
        api.getAllDropdown("UserList").then((response) => {
          localStorage.setItem(
            "UserList",
            JSON.stringify(response.datalist.UserList)
          );
        });

        api.getAllDropdown("UserListEdit").then((response) => {
          localStorage.setItem(
            "UserListEdit",
            JSON.stringify(response.datalist.UserListEdit)
          );
        });

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        queryClient.getQueriesData("userlist");
        props.history.push("/user-entry");
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        queryClient.getQueriesData("userlist");
        props.history.push("/user-entry");
      }
    },
  });

  const { isLoading: isLoadingUpdate, mutate: mutateUpdate } = useMutation(
    api.updateUser,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          //
          if (data.data.switchUserData == 1) {
            if (userId == data.data.UserId) {
              const auth_token = sessionStorage.getItem("token")
                ? sessionStorage.getItem("token")
                : null;
              let options = {};
              options = {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${auth_token}`,
                },
                task: "UOM",
              };

              api.getDispensingLanguage(options).then((res1) => {
                //console.log(res)
                let DispensingLanguageData = res1.datalist;
                localStorage.setItem(
                  "DispensingLanguage",
                  JSON.stringify(DispensingLanguageData)
                );

                api.getAllDropdown("ALL").then((response) => {
                  if (response.success == 1) {
                    localStorage.setItem(
                      "AdjType",
                      JSON.stringify(response.datalist.AdjType)
                    );
                    /*  localStorage.setItem(
                "CountryList",
                JSON.stringify(response.datalist.CountryList)
              ); */
                    /*  localStorage.setItem(
                "DirectionList",
                JSON.stringify(response.datalist.DirectionList)
              ); */
                    /* localStorage.setItem(
                "DosageFormlist",
                JSON.stringify(response.datalist.DosageFormlist)
              ); */
                    localStorage.setItem(
                      "FacilityList",
                      JSON.stringify(response.datalist.FacilityList)
                    );
                    /*  localStorage.setItem(
                "GenericList",
                JSON.stringify(response.datalist.GenericList)
              ); */
                    /* localStorage.setItem(
                "IntervalList",
                JSON.stringify(response.datalist.IntervalList)
              ); */
                    /*  localStorage.setItem(
                "OrderType",
                JSON.stringify(response.datalist.OrderType)
              ); */
                    /* localStorage.setItem(
                "PackSizelist",
                JSON.stringify(response.datalist.PackSizelist)
              ); */
                    /* localStorage.setItem(
                "PostList",
                JSON.stringify(response.datalist.PostList)
              ); */
                    /* localStorage.setItem(
                "RouteOfAdminlist",
                JSON.stringify(response.datalist.RouteOfAdminlist)
              ); */
                    /* localStorage.setItem(
                "RxLevelList",
                JSON.stringify(response.datalist.RxLevelList)
              ); */
                    /* localStorage.setItem(
                "SpecialisationList",
                JSON.stringify(response.datalist.SpecialisationList)
              ); */
                    /*  localStorage.setItem(
                "Strengthlist",
                JSON.stringify(response.datalist.Strengthlist)
              ); */
                    /* localStorage.setItem(
                "TitleList",
                JSON.stringify(response.datalist.TitleList)
              ); */
                    /*  localStorage.setItem(
                "VEN_List",
                JSON.stringify(response.datalist.VEN_List)
              ); */

                    localStorage.setItem(
                      "Unitofmeasurelist",
                      JSON.stringify(response.datalist.Unitofmeasurelist)
                    );

                    /* localStorage.setItem(
                "ABC_List",
                JSON.stringify(response.datalist.ABC_List)
              ); */

                    /* localStorage.setItem(
                "language_preference",
                JSON.stringify(response.datalist.t_language_preference)
              ); */
                    /* localStorage.setItem(
                "Gender",
                JSON.stringify(response.datalist.Gender)
              ); */
                    /*  localStorage.setItem(
                "MaritalStatus_List",
                JSON.stringify(response.datalist.MaritalStatus_List)
              ); */
                    /* localStorage.setItem(
                "LABTest_List",
                JSON.stringify(response.datalist.LABTest_List)
              ); */
                    localStorage.setItem(
                      "MenuList",
                      JSON.stringify(response.datalist.MenuList)
                    );

                    localStorage.setItem(
                      "UserList",
                      JSON.stringify(response.datalist.UserList)
                    );

                    localStorage.setItem(
                      "UserListEdit",
                      JSON.stringify(response.datalist.UserListEdit)
                    );

                    localStorage.setItem(
                      "YearList",
                      JSON.stringify(response.datalist.YearList)
                    );

                    localStorage.setItem(
                      "MonthList",
                      JSON.stringify(response.datalist.MonthList)
                    );

                    /* localStorage.setItem(
                "Direction",
                JSON.stringify(response.datalist.Direction)
              ); */

                    localStorage.setItem(
                      "RoleList",
                      JSON.stringify(response.datalist.RoleList)
                    );

                    localStorage.setItem(
                      "LanguageList",
                      JSON.stringify(response.datalist.LanguageList)
                    );
                    if (data.data.FacilityId.substring(0, 1) === "T") {
                      localStorage.setItem("UseFor", "UIMS");
                    } else if (data.data.FacilityId.substring(0, 1) === "U") {
                      localStorage.setItem("UseFor", "MCWC");
                    } else {
                      localStorage.setItem("UseFor", "WIMS");
                    }
                    // localStorage.setItem(

                    //   "UseFor",

                    //   data.data.FacilityId.substring(0, 1)==='T'?'UIMS':'WIMS'
                    // );

                    localStorage.setItem("FacilityId", data.data.FacilityId);
                    for (
                      let i = 0;
                      i < response.datalist.FacilityList.length;
                      i++
                    ) {
                      if (
                        response.datalist.FacilityList[i]["id"] ==
                        data.data.FacilityId
                      ) {
                        localStorage.setItem(
                          "FacilityName",
                          response.datalist.FacilityList[i]["name"]
                        );
                        // console.log('response.datalist.FacilityList[i][]: ', response.datalist.FacilityList[i]['name']);
                        break;
                      } else {
                        localStorage.setItem("FacilityName", "");
                        //  console.log('FacilityName: ', 'FacilityName');
                      }
                    }

                    //localStorage.setItem("LangCode", data.data.LangCode);

                    api
                      .getLoginFacilityListNotEqual99ANDSuppliers(
                        options,
                        "ALL"
                      )
                      .then((response) => {
                        localStorage.setItem(
                          "OrderTo",
                          JSON.stringify(response.datalist.OrderTo)
                        );

                        localStorage.setItem(
                          "PrescriberList",
                          JSON.stringify(response.datalist.PrescriberList)
                        );
                        localStorage.setItem(
                          "DispenserList",
                          JSON.stringify(response.datalist.DispenserList)
                        );

                        //queryClient.getQueriesData("userlist");
                        // props.history.push("/user-entry");
                        window.location.href = `${process.env.REACT_APP_BASE_NAME}/user-entry`;
                      });
                  }

                  //
                });
              });
            } else {
              queryClient.getQueriesData("userlist");
              props.history.push("/user-entry");
            }
          } else {
            queryClient.getQueriesData("userlist");
            props.history.push("/user-entry");
          }
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
          queryClient.getQueriesData("userlist");
          props.history.push("/user-entry");
          api.dirtyModeDisable();
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
          queryClient.getQueriesData("userlist");
          props.history.push("/user-entry");
        }
      },
    }
  );

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["User - Add/Edit"]}
              </div>
            </div>
            <div className="float-right sw_btn_control">
              {addProductForm ? (
                <Grid item xs={12} className=" text-center">
                  <div className="float-right sw_btn_control">
                    <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => handleReset()}
                    >
                      {DispensingLanguage[lan][menukey]["Reset"]}
                    </Button>
                    <Button
                      disabled={props.bStockUpdate || isLoadingSave}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                      // onClick={() => handleSubmit()}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={() => props.history.push("/user-entry")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} className="mt-2 text-center">
                  <Button
                    disabled={props.bStockUpdate || isLoadingUpdate}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClick}
                    // onClick={() => handleUpdate()}
                  >
                    {DispensingLanguage[lan][menukey]["Update"]}
                  </Button>

                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    //onClick={() => props.history.push("/user-entry")}
                    onClick={() => backtolist()}
                  >
                    {DispensingLanguage[lan][menukey]["Back to List"]}
                  </Button>
                </Grid>
              )}
            </div>
            <div className="mr-4 float-right mt-15">
              <span
                id="master_dirty_message"
                className={"masterredtextcolor dnone"}
              >
                {" "}
                {DispensingLanguage[lan][menukey]["You have unsaved changes"]}
              </span>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.name}
                      helperText={errorObject.name}
                      required
                      id="name"
                      name="name"
                      label={DispensingLanguage[lan][menukey]["Name"]}
                      value={formData.name}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                      disabled={props.bStockUpdate}
                    />
                  </Grid>

                  <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.email}
                      helperText={errorObject.email}
                      disabled={props.bStockUpdate}
                      required
                      id="email"
                      name="email"
                      label={DispensingLanguage[lan][menukey]["Email"]}
                      value={formData.email}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.loginname}
                      helperText={errorObject.loginname}
                      required
                      disabled={props.bStockUpdate}
                      id="loginname"
                      name="loginname"
                      label={DispensingLanguage[lan][menukey]["Login Name"]}
                      value={formData.loginname}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <TextField
                      error={errorObject.password}
                      helperText={errorObject.password}
                      required
                      disabled={props.bStockUpdate}
                      id="password"
                      name="password"
                      type="password"
                      label={DispensingLanguage[lan][menukey]["Password"]}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={2} sm={2}>
                    <TextField
                      error={errorObject.confirmChangePassword}
                      helperText={errorObject.confirmChangePassword}
                      id="confirmChangePassword"
                      name="confirmChangePassword"
                      type="password"
                      disabled={props.bStockUpdate}
                      label={
                        DispensingLanguage[lan][menukey]["Confirm Password"]
                      }
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.designation}
                      helperText={errorObject.designation}
                      required
                      id="designation"
                      name="designation"
                      disabled={props.bStockUpdate}
                      label={DispensingLanguage[lan][menukey]["Designation"]}
                      value={formData.designation}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>

                  {/* <Grid item xs={4} sm={4}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Language"]}*
                      </InputLabel>
                      <Select
                        error={errorObject.LangCode}
                        labelId="demo-simple-select-helper-label"
                        id="LangCode"
                        name="LangCode"
                        value={formData.LangCode||'fr_FR' }
                        fullWidth
                        onChange={(e) => handleChange(e)}
                      >
                        {LanguageList.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={errorObject.LangCode}>
                        {errorObject.LangCode}
                      </FormHelperText>
                    </FormControl>
                  </Grid> */}
                  <Grid item xs={4} sm={4}>
                    <FormControl className={classes.fullWidth}>
                      {/* <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]["Role"]}*
                      </InputLabel>
                      <Select
                        disabled={formData.id==1?true:false}
                        error={errorObject.role_id}
                        labelId="demo-simple-select-helper-label"
                        id="role_id"
                        name="role_id"
                        value={formData.role_id}
                        fullWidth
                        onChange={(e) => handleChange(e)}
                      >
                        {RoleList.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={errorObject.role_id}>
                        {errorObject.role_id}
                      </FormHelperText> */}

                      <Autocomplete
                        autoHighlight
                        id="role_id"
                        options={RoleList}
                        onChange={(event, valueobj) =>
                          handleChangeChoosen(
                            "role_id",
                            valueobj,
                            valueobj ? valueobj.id : ""
                          )
                        }
                        getOptionLabel={(option) => option.name}
                        value={chosenValues["role_id"]}
                        disabled={props.bStockUpdate}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.name}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={formData.role_id}
                            label={
                              DispensingLanguage[lan][menukey]["Role"] + " *"
                            }
                            variant="standard"
                            error={errorObject.role_id}
                            helperText={errorObject.role_id}
                            id="role_id"
                            name="role_id"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={4} sm={4}>
                    <FormControl className={classes.fullWidth}>
                      {/* <InputLabel id="demo-simple-select-helper-label">
                     
                      {DispensingLanguage[lan][menukey]["Facility Name"]}
                      </InputLabel>
                      <Select
                        error={errorObject.FacilityId}
                        labelId="demo-simple-select-helper-label"
                        id="FacilityId"
                        name="FacilityId"
                        value={formData.FacilityId}
                        fullWidth
                        onChange={(e) => handleChange(e)}
                      >
                      <MenuItem value="">
                        {DispensingLanguage[lan][menukey]["All Facility"]}
                      </MenuItem>

                        {FacilityList.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={errorObject.FacilityId}>
                        {errorObject.FacilityId}
                      </FormHelperText> */}

                      <Autocomplete
                        autoHighlight
                        id="FacilityId"
                        options={FacilityList}
                        onChange={(event, valueobj) =>
                          handleChangeChoosen(
                            "FacilityId",
                            valueobj,
                            valueobj ? valueobj.id : ""
                          )
                        }
                        getOptionLabel={(option) => option.name}
                        value={chosenValues["FacilityId"]}
                        disabled={props.bStockUpdate}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.name}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={formData.FacilityId}
                            label={
                              DispensingLanguage[lan][menukey]["Facility Name"]
                            }
                            variant="standard"
                            error={errorObject.FacilityId}
                            helperText={errorObject.FacilityId}
                            id="FacilityId"
                            name="FacilityId"
                            fullWidth
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={4} sm={4} className="sw_active_patient">
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          disabled={
                            props.bStockUpdate || formData.id == 1
                              ? true
                              : false
                          }
                          checked={formData.IsActive}
                          onChange={(e) => handleCheck(e)}
                          name="IsActive"
                          value="no"
                        />
                      }
                      label={DispensingLanguage[lan][menukey]["Active"]}
                    />
                  </Grid>
                </Grid>

                {/* New Row */}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default DispenserFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
