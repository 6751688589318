import React, { forwardRef, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Grid, Checkbox, TextField, CardContent } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import moment from "moment";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";



let tblMenuRef = null;


const Form_Item_Serial = (props) => {
  //get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "upazila-form-item-serial";
const UserInfo = sessionStorage.getItem("User_info")? JSON.parse(sessionStorage.getItem("User_info")): 0;  
const UserName = UserInfo==0?'': UserInfo[0].id;
const FacilityId = localStorage.getItem("FacilityId");
  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const [dataItems1, setDataItems1] = useState([]);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const [selectedRoleData, setSelectedRole] = useState(6);

  const [selectedrow, setSelectedrow] = useState(false);

  const [toggleButtonValue, setToggleButton] = React.useState("1");
  
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const { isLoading, error, isError, data, refetch } = useQuery(
    "upazila-form-item-serial",
    api.getFormItemSerialList,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setSelectedrow(true);
      },
    }
  );

  let params = {
    menukey: menukey,
    FacilityId: FacilityId,
   // ItemCode: SettingsId,
    action: "getFormItemSerialDetailsList",
    SettingsId: selectedRoleData,
    toggleButtonValue: toggleButtonValue,
  };

  const {
    isLoading1,
    error1,
    isError1,
    data: menuData,
    refetch: MenuRefetch,
  } = useQuery([params], api.getFormItemSerialDetails, {
    onSuccess: (menuData) => {
      setDataItems1(menuData);
      
      
    },
  });

  const rowClick = (e, row) => {
    let rowData = row._row.data;
    console.log(rowData.SettingsId);
    setSelectedRole(rowData.SettingsId);
    //MenuRefetch();
  };


/* =====Start of Excel Export Code==== */
const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

const PrintPDFExcelExportFunction = (reportType) => {
  let formatedDate = moment(selectedStartDate).format("YYYY-MM-DD");
  let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

  window.open(
    finalUrl +
      "?action=SlowmovieExport" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&StartDate=" +
      formatedDate +
      "&FacilityId=" +
      FacilityId +
       "&TimeStamp=" + Date.now()
  );
};


function RowInputData(props, type = null) {
  const rowData = props.cell._cell.row.data;
  let fieldName = props.field;
  let fieldType = props.fieldType;
  // let labelName = props.labelName;
  // let dropDownKey = props.dropDownKey;

  switch (fieldType) {
    case "check-box":
      return rowData.type == "IsActive" ? (
        <></>
      ) : (
        <Checkbox
          color="primary"
          checked={rowData[fieldName]}
          name={fieldName}
          value={rowData[fieldName]}
        />
      );
      break;

    default:
      return <></>;
      break;
  }
  return <></>;
}


  useEffect(() => {
    console.log("useEffect calling for refresh");
    if (selectedRoleData > 0) {
      MenuRefetch();
    }
  }, [selectedRoleData]);

  const columns = [
    {
      title: "Id",
      field: "id",
      visible: false,
    },

    {
      title: DispensingLanguage[lan][menukey]["Set No"],
      field: "SettingsId",
      headerSort: false,
      //headerFilter: true,
      width: 70,
    },
    {
      title: DispensingLanguage[lan][menukey]["From Date"],
      field: "FromDate",
      headerSort: false,
      //headerFilter: true,
      //width: 105,
    },
    {
      title: DispensingLanguage[lan][menukey]["To Date"],
      field: "ToDate",
      headerSort: false,
      //headerFilter: true,
      //width: 105,
    }

  ];

  const columns1 = [
    { title: "Id", field: "id", width: 50, visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Item Code"],
      field: "ProdCode",
      headerFilter: true,
      headerSort: true,
      width: 140,
     
    },
    { title: DispensingLanguage[lan][menukey]["Item Name"],
     field: "ProdText",
     headerSort: true,
     headerFilter: true,
    
     },
    { title: DispensingLanguage[lan][menukey]["Linked Items"],
     field: "LinkedItems",
     headerFilter: true,
     headerSort: false,
     width: 200,
     },
   
      {
        title: DispensingLanguage[lan][menukey]["Print"],
        field: "PrintType",
        hozAlign: "center",
        headerHozAlign: "center",
        width: 80,
        headerSort: false,
        visible: ((toggleButtonValue==3)?true:false),
        formatter: reactFormatter(
          <RowInputData
            labelName="PrintType"
            field="PrintType"
            fieldType="check-box"
          />
        ),
      },
      
      { title: DispensingLanguage[lan][menukey]["SDP Assign Group"],
        field: "AssignFGroup",
        width: 200,
        headerSort: false,
        headerFilter: true,
        visible: ((toggleButtonValue==3)?true:false),
      },
      {
        title: DispensingLanguage[lan][menukey]["Field Calculate"],
        field: "isFieldCal",
        hozAlign: "center",
        headerHozAlign: "center",
        width: 80,
        headerSort: false,
        visible: ((toggleButtonValue==3)?true:false),
        formatter: reactFormatter(
          <RowInputData
            labelName="isFieldCal"
            field="isFieldCal"
            fieldType="check-box"
          />
        ),
      },
  ];


  const handleChangeToggle = (event, tmpToggleButtonValue) => {
    if (tmpToggleButtonValue) {
      console.log("tmpToggleButtonValue: ", tmpToggleButtonValue);

      setToggleButton(tmpToggleButtonValue);

      /* let isValid = checkFormValidation();
   
      if (isValid > 0) {
        return;
      }

      var tableData = ReceiveDetailsRef.table.getData();
      console.log("handleChangeToggle props.formData: ", props.formData);
      console.log("handleChangeToggle tableData: ", tableData);
      let isedit = 0;
      tableData.forEach((row, i) => {
        if (row.isedit === 1) {
          isedit = 1;
        }
      });

      console.log("isedit: ", isedit);
      console.log("props.bStockUpdate: ", props.bStockUpdate);

      //isedit = 0 when not changed any row data, isedit = 1 when changed any row data
      if (isedit === 0 || props.bStockUpdate) {
        props.reFetchItems(tmpToggleButtonValue);
        setToggleButton(tmpToggleButtonValue);
      } else {
      
        let fDtat = {
          ...props.formData,
          ManyJsonSave: tableData,
          tmpToggleButtonValue: tmpToggleButtonValue,
        };
        mutateUpdate(fDtat);
      } */
    }
  };

  
  useEffect(() => {
    if(selectedrow == true){
      tblMenuRef.table.selectRow(6);
    }
  }, [selectedrow]);


  return (
    <>
      <div className={classes.dispenserPageTitle}>
        <div className="d-flex justify-dispenser mb-2">
          <Grid item xs={12} sm={10}>
            <div className="sw_page_heading">
              <div className="sw_heading_title"> {DispensingLanguage[lan][menukey]["Upaziala Form Item Serial"]}</div>
                </div>
          </Grid>
          <Grid item xs={2} sm={2}>
                <div className="float-right sw_btn_control">
                

                 {/*  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button> */}
                </div>
              </Grid>
        </div>
        <CardContent>
          <Grid container spacing={10}>
            <Grid item xs={4} sm={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={3}>
                  <div className="uniqueName">
                    <ReactTabulator 
                    ref={(r) => (tblMenuRef = r)}
                      options={{
                        selectable:1,
                        selectableRangeMode: 'click',
                         //groupBy: "GroupName",
                          //columnCalcs: "both",
                          //sorter:"date",
                       }}
                      columns={columns}
                      data={dataItems}
                      layout={"fitColumns"}
                      height="470px"
                      rowClick={(e, row) => rowClick(e, row)}
                      
                    />
                  </div>
                </Grid>

                <Grid item xs={12} sm={9}>
                  <div className="uniqueName">
                    <ReactTabulator
                      columns={columns1}
                      data={dataItems1}
                      layout={"fitColumns"}
                      height={"470px"}
                    />
                  </div>



                  <ToggleButtonGroup
                          className="sw_ToggleButtonGroup"
                          size="small"
                          aria-label="Small sizes"
                          color="primary"
                          value={toggleButtonValue}
                          exclusive
                          onChange={handleChangeToggle}
                        >
                        
                          <ToggleButton value="1">
                             {DispensingLanguage[lan][menukey]["Form 2"]}
                          </ToggleButton>

                          <ToggleButton value="2">
                             {DispensingLanguage[lan][menukey]["Form 3"]}
                          </ToggleButton>

                          <ToggleButton value="3">
                            {DispensingLanguage[lan][menukey]["Form 7B"]}
                          </ToggleButton>

                        </ToggleButtonGroup>

                        
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </div>
    </>
  );
};

export default Form_Item_Serial;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
