import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as Service from "../../../services/Service.js";
import {
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import {
  useQuery,
  useMutation,
  useQueryClient
} from 'react-query'
import * as api from "../../../actions/api";

const UnitEntryFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  open,
  ...props
}) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "unit-entry";
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  // const FacilityId = localStorage.getItem('FacilityId');
  // const FacilityName = localStorage.getItem('FacilityName');

  // const ReceivingWarehouseList = JSON.parse(localStorage.getItem('OrderTo'));


  const [firstLoad, setFirstLoad] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [manyTableData, setManyTableData] = useState([]);
  const UpazilaList = JSON.parse(localStorage.getItem("UpazilaList"));

  
//====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "UpazilaCode": {"id": "","name": ""}
});


useEffect(() => {
  
  let chosenValuesData = { ...chosenValues};
   chosenValuesData['UpazilaCode'] = UpazilaList[UpazilaList.findIndex(Upazila_List => Upazila_List.id == formData.UpazilaCode)];
   setChosenValues(chosenValuesData); 

  }, [formData.UpazilaCode]);

const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  setChosenValues(chosenValuesData); 
  props.handleChangeChoosenProps(name, value);
};

//====== End Choosen Dropdown===========


const backtolist=()=>{


  let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
  let idx = master_dirty_message_class.indexOf("dnone");
  if(idx === -1){
    // start Alert
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then((willAction) => {
      if (willAction) {
        props.history.push("/unit-entry");
      }
    });
    // End Alert
  }else{
    props.history.push("/unit-entry");
  }

}


const onSaveUpdateBtnClick = () => {

  setUpdatedDetailsGridDataHandler();
};


const setUpdatedDetailsGridDataHandler = () => {
  let validForm = props.checkValidateForm();
 
  // console.log('isval: ', validForm);
    if (validForm) {

      if(addProductForm){
        //when save
        mutate(validForm);
        
      }else{
        //when update
        mutateUpdate(validForm); 
      }
      

      
    }
};


const { isLoading : isLoadingSave, mutate } = useMutation(api.saveUnitEntry, {
  onSuccess: (data) => {
   // console.log("data: ", data);
    if (data.status == 200) {
      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
      queryClient.getQueriesData("UnitEntry"); //api page name
      props.history.push("/unit-entry"); //
    } else {
      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
      queryClient.getQueriesData("UnitEntry"); //api page name
      props.history.push("/unit-entry"); //
    }
  },
});

const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.UpdateUnitEn, {
  onSuccess: (data) => { 
    if (data.status == 200) {
   
      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });

      queryClient.getQueriesData("UnitEntry"); // include the page name
      props.history.push("/unit-entry"); //page url
    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
    }
  },
});


  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title"> {DispensingLanguage[lan][menukey]['Unit Entry- Add/Edit']} </div>
            </div>
            <div className="float-right sw_btn_control">

              {addProductForm ? (
                <Grid item xs={12} className="mt-4 text-center tex_margin">
                  <Button
                    disabled={props.permissionType}
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => handleReset()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Reset'])}
                  </Button>
                  <Button
                    disabled={props.permissionType || isLoadingSave}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClick}
                    // onClick={() => handleSubmit()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Save'])}
                  </Button>
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    // onClick={() => props.history.push("/unit-entry")}
                    onClick={() => backtolist()}

                  >
                    {t(DispensingLanguage[lan][menukey]['Back to List'])}
                  </Button>
                </Grid>
              ) : (
                <Grid item xs={12} className="mt-2 text-center">
                  <Button
                    disabled={props.permissionType || isLoadingUpdate}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClick}
                    // onClick={() => handleUpdate()}
                  >
                    {t(DispensingLanguage[lan][menukey]['Update'])}
                  </Button>
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    // onClick={() => props.history.push("/unit-entry")}
                    onClick={() => backtolist()}

                  >
                    {t(DispensingLanguage[lan][menukey]['Back to List'])}
                  </Button>
                </Grid>
              )}



            </div>

            <div className="mr-4 float-right mt-15">
                <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
              </div>

          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={10}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardHeader title={DispensingLanguage[lan][menukey]['Unit Entry']} />
              <CardContent>
                <Grid container spacing={10}>
                  <Grid item xs={4} sm={12}>
                    <Grid container spacing={4}>
                      <Grid item xs={6} sm={6}>
                        <FormControl className={classes.fullWidth}>
                          {/* <InputLabel id="">
                            {DispensingLanguage[lan][menukey]["Upazila"]}
                          </InputLabel>
                          <Select
                            error={errorObject.UpazilaCode}
                            required
                            labelId="UpazilaCode"
                            id="UpazilaCode"
                            name="UpazilaCode"
                            value={formData.UpazilaCode}
                            fullWidth
                           onChange={(e) => handleChange(e)}
                           disabled={props.permissionType}
                           
                          >

                            <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                            {UpazilaList.map((item, index) => {
                              return (
                                <MenuItem value={item.id}> {item.name}</MenuItem>
                              );
                            })}
                          </Select>
                          <FormHelperText error={errorObject.UpazilaCode}>
                            {errorObject.UpazilaCode}
                          </FormHelperText> */}

                          <Autocomplete
                            autoHighlight
                              //disableClearable
                            disabled={props.permissionType}
                            id="UpazilaCode"
                            options={UpazilaList}
                            onChange={(event, valueobj) => handleChangeChoosen('UpazilaCode', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValues['UpazilaCode']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={formData.UpazilaCode}
                              label={DispensingLanguage[lan][menukey]["Upazila"]}
                              variant="standard"
                              error={errorObject.UpazilaCode}
                              helperText={errorObject.UpazilaCode}
                              id="UpazilaCode"
                              name="UpazilaCode"
                              fullWidth
                            />
                            )}
                          />

                        </FormControl>
                      </Grid>

                      <Grid item xs={6} sm={6}>

                        <TextField
                          error={errorObject.sunitname}
                          helperText={errorObject.sunitname}
                          required
                          disabled={props.permissionType}
                          id="sunitname"
                          name="sunitname"
                          label={DispensingLanguage[lan][menukey]["Unit"]}
                          value={formData.sunitname}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      
                   
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          {/* Action buttons */}



        </Grid>
      </div>
    </div>
  );
};

export default UnitEntryFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
