import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import swal from "sweetalert";
import * as api from "../../../actions/api";

import { useTranslation } from "react-i18next";


const RoleFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  const classes = useStyles();
//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "role-entry";
const MenuUrl = JSON.parse(localStorage.getItem("MenuUrl"));
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  
//====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "defaultredirect": {"url": "","name": ""}
});


useEffect(() => {
  
  let chosenValuesData = { ...chosenValues};
   chosenValuesData['defaultredirect'] = MenuUrl[MenuUrl.findIndex(Menu_Url => Menu_Url.url == formData.defaultredirect)];
   setChosenValues(chosenValuesData); 

  }, [formData.defaultredirect]);

const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  setChosenValues(chosenValuesData); 
  props.handleChangeChoosenProps(name, value);
};

//====== End Choosen Dropdown===========



const backtolist=()=>{


  let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
  let idx = master_dirty_message_class.indexOf("dnone");
  if(idx === -1){
    // start Alert
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then((willAction) => {
      if (willAction) {
        props.history.push("/role-entry");
      }
    });
    // End Alert
  }else{
    props.history.push("/role-entry");
  }

}


const onSaveUpdateBtnClick = () => {

  setUpdatedDetailsGridDataHandler();
};


const setUpdatedDetailsGridDataHandler = () => {
  let validForm = props.checkValidateForm();
 
  // console.log('isval: ', validForm);
    if (validForm) {

      if(addProductForm){
        //when save
        mutate(validForm);
        
      }else{
        //when update
        mutateUpdate(validForm); 
      }
      

      
    }
};

const { isLoading : isLoadingSave, mutate } = useMutation(api.saveRole, {
  onSuccess: (data) => {

    if(data.status==200) {

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      queryClient.getQueriesData("roles");//api page name
      props.history.push("/role-entry");//

    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
     
    }
  },
});

const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.updateRole, {
  onSuccess: (data) => {
    if (data.status == 200) {


      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });

      queryClient.getQueriesData("role");// include the page name
      props.history.push("/role-entry");//page url
      api.dirtyModeDisable();
    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
     
    }
  },
});


return (
  <div className={classes.productPageTitle}>
    <div className="sw_makeStyles_tableContainer">
      <div className="d-flex justify-product mb-3">
        <Grid item xs={12} sm={12}>
          <div className="sw_page_heading">
            <div className="sw_heading_title">
              {DispensingLanguage[lan][menukey]["Role Entry Form - Add/Edit"]}
            </div>
            </div>
            <div className="float-right sw_btn_control">
              {addProductForm ? (
                <Grid item xs={12} className="text-center">
                  <div className="float-right sw_btn_control">
                    <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => handleReset()}
                    >
                      {DispensingLanguage[lan][menukey]["Reset"]}
                    </Button>
                    <Button
                      disabled={props.bStockUpdate || isLoadingSave}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                      // onClick={() => handleSubmit()}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={() => props.history.push("/role-entry")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} className="mt-2 text-center">
                  <Button
                      disabled={props.bStockUpdate || isLoadingUpdate}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClick}
                    // onClick={() => handleUpdate()}
                  >
                    {DispensingLanguage[lan][menukey]["update"]}
                  </Button>

                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    //onClick={() => props.history.push("/role-entry")}
                    onClick={() => backtolist()}
                  >
                    {DispensingLanguage[lan][menukey]["Back to List"]}
                  </Button>
                </Grid>
              )}
            </div>
          
             <div className="mr-4 float-right mt-15">
              <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
            </div>
        </Grid>
      </div>

      {/* New row */}
      <Grid container spacing={3}>
        {/* New row */}
        <Grid item xs={12} sm={12}>
          <Card className="sw_card">
            <CardContent>
              <Grid container spacing={4}>
                    <Grid item xs={6} sm={4}>
                      <TextField
                      
                        disabled={props.bStockUpdate}
                        error={errorObject.role}
                        helperText={errorObject.role}
                        required
                        id="role"
                        name="role"
                        label= {DispensingLanguage[lan][menukey]['Role Name'] }
                        value={formData.role}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                    <Grid item xs={6} sm={4}>
                    <FormControl className={classes.fullWidth}>
                        {/* <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]['Default Redirect']}
                        </InputLabel>
                        <Select
                          error={errorObject.defaultredirect}
                          labelId="demo-simple-select-helper-label"
                          id="defaultredirect"
                          name="defaultredirect"
                          value={formData.defaultredirect}
                          fullWidth
                          onChange={(e) => handleChange(e)}
                        >
                            {
                      MenuUrl.map(
                      (item, index) => {
                        return (
                          <MenuItem value={item.url}>
                            {item.name}
                          </MenuItem>
                        );
                      }
                    )}
                        </Select>
                        <FormHelperText error={errorObject.defaultredirect}>
                          {errorObject.defaultredirect}
                        </FormHelperText> */}

                              <Autocomplete
                                autoHighlight
                                
                                /* disabled={
                                  manyTableData.length > 0 ? true : props.bStockUpdate
                                } */
                                id="defaultredirect"
                                disabled={props.bStockUpdate}
                                options={MenuUrl}
                                onChange={(event, valueobj) => handleChangeChoosen('defaultredirect', valueobj, valueobj?valueobj.url:"")}
                                getOptionLabel={(option) => option.name}
                                value={chosenValues['defaultredirect']}
                                renderOption={(option) => (
                                <Typography className="sw_fontSize">{option.name}</Typography>
                                )}
                                renderInput={(params) => (
                                <TextField
                                  {...params}
                                  value={formData.defaultredirect}
                                  label={DispensingLanguage[lan][menukey]["Default Redirect"]}
                                  variant="standard"
                                  error={errorObject.defaultredirect}
                                  helperText={errorObject.defaultredirect}
                                  id="defaultredirect"
                                  name="defaultredirect"
                                  fullWidth
                                />
                                )}
                              />

                      </FormControl>
                      
                    </Grid>
                    <Grid item xs={6} sm={2} className="sw_active_patient">
                    <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={formData.bFacilitySelected}
                        onChange={(e) => handleCheck(e)}
                        name="bFacilitySelected"
                        value="no"
                        disabled={props.bStockUpdate}
                      />
                    }
                     label={DispensingLanguage[lan][menukey]["Is Active"]}
                  />
                    </Grid>

                    <Grid item xs={6} sm={2} className="sw_active_patient">
                    <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        checked={formData.bCatalog}
                        onChange={(e) => handleCheck(e)}
                        name="bCatalog"
                        value="no"
                        disabled={props.bStockUpdate}
                      />
                    }
                     label={DispensingLanguage[lan][menukey]["Is Catalog"]}
                  />
                    </Grid>


                  </Grid>

              {/* New Row */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  </div>
);
};

export default RoleFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
