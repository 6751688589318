import React, { forwardRef, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import {
  Grid,
  FormControlLabel,
  Checkbox,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
//import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, Edit } from "@material-ui/icons";


const EntryandEditofPackage = (props) => {
  const permissionType = props.permissionType;
    //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "entry-and-edit-of-package";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo==0?'': UserInfo[0].id;
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const [dataItemsContract, setdataItemsContract] = useState([]);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const [selectedPackageNo, setSelectedPackageNo] = useState(0);
  const [selectedSwitchType, setSwitchType] = useState(true);
  const [addProductForm, setaddProductForm] = useState(true);
  let ReceiveDetailsRef = null;


  const { isLoading, error, isError, data, refetch } = useQuery(
    'entryAndEditofPackages',
    api.getEntryandEditofPackageList,
    {
      onSuccess: (data) => {
        setDataItems(data); 
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24, 
    }
    );
  

    let params = {
      menukey: menukey,
      FacilityId: FacilityId,
      action: "getContractListData",
      PackageNo: selectedPackageNo,
    };
  
    const {
      isLoading1,
      error1,
      isError1,
      data: menuData,
      refetch: ContractRefetch,
    } = useQuery([params], api.getContractListData, {
       onSuccess: (menuData) => {
        setdataItemsContract(menuData);
        
        console.log("fetch data =========== ",menuData);
      },
      enabled:false,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    });


    const rowClick = (e, row) => {
      let rowData = row._row.data;

      console.log("Selected PackageNo Row ====> ", rowData.PackageNo);
      setSelectedPackageNo(rowData.PackageNo);
      setSwitchType(true);
      setaddProductForm(true);
     
    };
 
    useEffect(() => {
      console.log("useEffect calling for refresh");
      if (selectedPackageNo > 0) {
        ContractRefetch();
      }
    }, [selectedPackageNo]);
 
    

  const { mutate:deleteApi } = useMutation(api.deleteEntryandEditofPackageList, {
    onSuccess: (data) => {
      if (data.data.status == 200) {

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        }); 
        refetch();
        queryClient.getQueriesData("entryAndEditofPackages");
       // swal(data.data.message, "", "success");
        
      }else{   
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
        }
    },
  });

/* =====Start of Excel Export Code==== */
const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

const PrintPDFExcelExportFunction = (reportType) => {
  let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

  window.open(
    finalUrl +
      "?action=OthersFacilityListExport" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&FacilityId=" +
      FacilityId +
      "&FacilityName=" +
      FacilityName +
      "&lan=" +
      localStorage.getItem("LangCode") + "&TimeStamp=" + Date.now()
  );
};
/* =====End of Excel Export Code==== */

/* const tableRowDoubleClick = (e, row) => {
  let rowData = row._row.data;
  editPackageList(rowData);
}; */

  const columns = [
    { title: "Id", field: "id", width: 50, visible: false },
    { title: DispensingLanguage[lan][menukey]['Sl#.'] , formatter: "rownum", width: 30, hozAlign: "center",headerSort:false, headerHozAlign:"center"},
    { title: DispensingLanguage[lan][menukey]['Year'] , headerFilter: true, width: 100, field: "PackageYear"},
    { title: DispensingLanguage[lan][menukey]['Indentor'] , headerFilter: true, width: 120,  field: "IndentorName"},
    { title: DispensingLanguage[lan][menukey]['Package Name'] , headerFilter: true,  field: "PackageName"},
   
    {
      title: DispensingLanguage[lan][menukey]['Actions'],
      field: "custom",
      hozAlign: "center",
      width: 80,
      headerSort:false,
      headerHozAlign:"center",
      //frozen:true,
      formatter: reactFormatter(<ActionButton />),
    },
  ];
  



  
  const columnsContract = [
    { title: "Id", field: "id", width: 50, visible: false },
    { title: DispensingLanguage[lan][menukey]['Sl#.'] , formatter: "rownum", width: 30, hozAlign: "center",headerSort:false, headerHozAlign:"center"},
    { title: DispensingLanguage[lan][menukey]['Contract Name'] , headerFilter: true,  field: "ContractName"},
    {
      title: DispensingLanguage[lan][menukey]['Actions'],
      field: "custom",
      hozAlign: "center",
      width: 80,
      headerSort:false,
      headerHozAlign:"center",
      //frozen:true,
      formatter: reactFormatter(<ActionButtonContract />),
    },
  ];



  const editPackageList = (data) => {
    let route = `entry-and-edit-of-package/edit/${data.id}`;
    props.history.push(route);
  };



  const deleteEntryandEditofPackageList = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]['Are you sure?']),
      text: t(DispensingLanguage[lan][menukey]['Once deleted, you will not be able to recover this Record!']),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willDelete) => {
      if (willDelete) {
        deleteApi({id:data.id,"menukey": menukey,"lan":lan,"UserName":UserName,"action": "dataDalete"});
      }
    });
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    // const cellValue = props.cell._cell.value || "Edit | Show";
    if (permissionType) return <></>;
    else if(rowData.id==1)
    return (
      <>
        <Edit
          onClick={() => {
            editPackageList(rowData);
          }}
        />
        
      </>
    );
    else
    return (
      <>
        <Edit
          onClick={() => {
            editPackageList(rowData);
          }}
        />
      {/*  <DeleteOutline
          onClick={() => {
          
            deleteEntryandEditofPackageList(rowData);
          }}
        /> */}
      </>
    );
}



function ActionButtonContract(props) {
  const rowData = props.cell._cell.row.data;
  const cellValue = props.cell._cell.value || "Edit | Show";

  
 
  return (
    <>
      <Edit
        onClick={() => {
          editPackageContractList(rowData);
        }}
      />
 
    </>
  );
}



/* ==========Start Contract Add Edit Form =============*/


const [errorObject, setErrorObject] = useState({});

const [formData, setFormData] = useState({
  ContractName:"",
 });
 

 const handleReset = () => {
  setFormData({
    ContractName:"",
    
  });
};
 


const [editedContractId, setEditedid] = useState(0);


const editPackageContractList = (data) => {
 
  // console.log("edit contract================",data.id);
   setEditedid(data.id);
   setSwitchType(false);
   setaddProductForm(false);
   //let route = `entry-and-edit-of-package/editcontract/${data.id}`;
   //props.history.push(route);

 };


 const { data3, refetch: ContractEditRefetch } = useQuery(
  ["entryAndEditofPackages", editedContractId],
  () =>
    api.getEntryandEditofContract(editedContractId).then((res) => {
      console.log("EEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEDIT>>>>>>>> ",res.data);
      setFormData(res.data);
      return res.data;
    }),
  {
    enabled: Boolean(editedContractId),
  }
); 


const AddContractFormListSwitch = (sParam) => {

  if ((selectedPackageNo == 0) && (sParam === false)){
    props.openNoticeModal({
      isOpen: true,
      msg: DispensingLanguage[lan][menukey]['Please select a package'],
      msgtype: 0,
    });
    return;
  }


  setSwitchType(sParam);
  setaddProductForm(true);
  setErrorObject({});
  //console.log("sparam ===================== > ",sParam);

    if ((editedContractId > 0) && (sParam === true)){
      ContractEditRefetch();
      
    }else{
      setFormData({
        ContractName:"",
        
      });  
    } 
  

};




const { isLoading : isLoadingSave, mutate } = useMutation(api.saveEntryandEditofContactList, {
  onSuccess: (data) => {

    if(data.status==200) {

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      queryClient.getQueriesData("entryAndEditofPackages");//api page name
      //props.history.push("/entry-and-edit-of-package");//
      ContractRefetch();
      console.log("call from insert");
      setSwitchType(true);

    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
     
    }
  },
});


const {isLoading: isLoadingUpdate, mutate: mutate2 } = useMutation(api.updateEntryandEditofContractList, {
  onSuccess: (data) => {
    if (data.status == 200) {


      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });

      queryClient.getQueriesData("entryAndEditofPackages");// include the page name
      ContractRefetch();
      console.log("call from update");
      setSwitchType(true);
    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
     
    }
  },
});





const handleChange = (e) => {
  const { name, value } = e.target;

  let data = { ...formData };
  console.log("formData: ", formData);
  data[name] = value;

  setFormData(data);

  setErrorObject({ ...errorObject, [name]: null });
};


const validateForm = (formData) => {
  let validateFields = ["ContractName"];
  let errorData = {};
  let isValid = true;
  validateFields.map((field) => {
    if (!formData[field]) {
      errorData[field] = (DispensingLanguage[lan][menukey]['field is Required !']);
      isValid = false;
    }
  });
  setErrorObject(errorData);
  return isValid;
};

const handleSubmit = async (e) => {
  console.log("from submit ", formData);
  if (validateForm(formData)) {
    let fDtat={...formData, "PackageNo":selectedPackageNo, "menukey": menukey,"lan":lan,"UserName":UserName}; 
    mutate(fDtat);
   // swal("Success!", "", "success");
  }
};

/* End Contract Edit Form */



  /*   useEffect(() => {
    console.log("useEffect calling for refresh");
    if (selectedSwitchType == false) {
    

    }
  }, [selectedSwitchType]); */



  const handleUpdate = async (e) => {
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
      //mutate2(fDtat);
      mutate2({fDtat});
     
    }
  };


/* End Contract Add Edit Form */

  return (
    <>
    
      <div className={classes.dispenserPageTitle}>
       
        <CardContent>
          <Grid container spacing={10}>
            <Grid item xs={4} sm={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>

                <div className="d-flex justify-dispenser mb-2">
                  <Grid item xs={12} sm={12}>
                      <div className="sw_page_heading">
                        <div className="sw_heading_title">{DispensingLanguage[lan][menukey]['Package List']}</div>
                        <div className="float-right sw_btn_control">
                          <Button
                           disabled={permissionType}
                            //color="info"
                            //className="mr-2"
                            className="mr-2"
                            variant="contained"
                            color="primary"

                            onClick={() => {
                              props.history.push("/entry-and-edit-of-package/add");
                            }}
                          >
                            {DispensingLanguage[lan][menukey]['Add Package']} 
                          </Button>
                        
                        </div>
                      </div>
                    </Grid>
                </div>


                  <div className="uniqueName">
                    <ReactTabulator
                     ref={(r) => (ReceiveDetailsRef = r)}
                      options={{
                        selectable:1,
                        selectableRangeMode: 'click',
                   
                       }}
                    columns={columns} data={dataItems}
                    layout={"fitColumns"}
                    height={500}
                    rowClick={(e, row) => rowClick(e, row)}
                    />
                  </div>
                  
                </Grid>

                {/* Start Contract Grid */}

              {selectedSwitchType && 

                <Grid item xs={12} sm={6}>

                  <div className="d-flex justify-dispenser mb-2">
                    <Grid item xs={12} sm={12}>
                        <div className="sw_page_heading">
                          <div className="sw_heading_title">{DispensingLanguage[lan][menukey]['Contract List']}</div>
                          <div className="float-right sw_btn_control">
                            <Button
                             disabled={permissionType}
                              //color="info"
                              //className="mr-2"
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              
                              onClick={() => AddContractFormListSwitch(false)}

                              /* onClick={() => {
                                props.history.push("/entry-and-edit-of-package/addcontract");
                              }} */

                            >
                              {DispensingLanguage[lan][menukey]['Add Contract']} 
                            </Button>
                          
                          </div>
                        </div>
                      </Grid>
                  </div>

                  <div className="uniqueName">
                    <ReactTabulator 
                    columns={columnsContract} 
                    data={dataItemsContract}
                    layout={"fitColumns"}
                    height={500}
                    //rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
                    />
                  </div>
                </Grid>

              }


              {!selectedSwitchType && 

                <Grid item xs={12} sm={6}>

                  




                      <div className={classes.productPageTitle}>
                            <div className="sw_makeStyles_tableContainer">
                              <div className="d-flex justify-product mb-3">
                                <Grid item xs={12} sm={12}>
                                  <div className="sw_page_heading text-left">
                                    <div className="sw_heading_title_inner"> {DispensingLanguage[lan][menukey]['Contract Entry Form - Add/Edit']} </div>
                                  </div>
                                </Grid>
                              </div>

                              {/* New row */}
                            
                                {/* New row */}
                                <Grid item xs={12} sm={12}>
                                  <Card className="sw_card">
                                    <CardHeader title= {DispensingLanguage[lan][menukey]['Contract Entry from']}/>
                                    <CardContent>
                                      <Grid container spacing={10}>
                                        <Grid item xs={4} sm={12}>
                                          <Grid container spacing={4}>
                                          {/* <Grid item xs={3} sm={3}> </Grid> */}
                                              <Grid item xs={12} sm={12}>
                                                <TextField
                                                
                                                  error={errorObject.ContractName}
                                                  helperText={errorObject.ContractName}
                                                  required
                                                  id="ContractName"
                                                  name="ContractName"
                                                  label= {DispensingLanguage[lan][menukey]['Contract Name'] }
                                                  value={formData.ContractName}
                                                  fullWidth
                                                  autoComplete="family-name"
                                                 onChange={(e) => handleChange(e)}
                                                />
                                              </Grid>
                                              

                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </CardContent>
                                  </Card>
                                </Grid>

                                {/* Action buttons */}
                                {addProductForm ? (
                                  <Grid item xs={12} className="mt-4 text-center">
                                    <Button
                                      className="mr-2"
                                      variant="contained"
                                      type="reset"
                                      onClick={() => handleReset()}
                                    >
                                      {t(DispensingLanguage[lan][menukey]['Reset'])}
                                    </Button>
                                    <Button
                                     disabled={isLoadingSave}
                                      className="mr-2"
                                      variant="contained"
                                      color="primary"
                                      onClick={() => handleSubmit()}
                                    >
                                      {t( DispensingLanguage[lan][menukey]['Save'])}
                                    </Button>
                                    <Button
                                      className="mr-2"
                                      variant="contained"
                                      type="reset"
                                      onClick={() => AddContractFormListSwitch(true)}
                                      //onClick={() => props.history.push("/entry-and-edit-of-package")}
                                    >
                                      {t( DispensingLanguage[lan][menukey]['Cancel'])}
                                    </Button>
                                  </Grid>
                                ) : (
                                  <Grid item xs={12} className="mt-2 text-center">
                                    <Button
                                      disabled={isLoadingUpdate}
                                      className="mr-2"
                                      variant="contained"
                                      color="primary"
                                      onClick={() => handleUpdate()}
                                    >
                                      {t(DispensingLanguage[lan][menukey]['update'])}
                                    </Button>
                                    <Button
                                      className="mr-2"
                                      variant="contained"
                                      type="reset"
                                      onClick={() => AddContractFormListSwitch(true)}
                                      //onClick={() => props.history.push("/entry-and-edit-of-package")}
                                    >
                                      {t(DispensingLanguage[lan][menukey]['Cancel'])}
                                    </Button>
                                  </Grid>
                                )}
                             
                            </div>
                          </div>

                          




                  
                </Grid>

               }


              {/* End Contract Grid */}



              </Grid>
            </Grid>
          </Grid>
        </CardContent>



          
        </div>
    


      
    </>
  );
};

export default EntryandEditofPackage;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
