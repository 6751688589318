import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import MaterialTable from "material-table";
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";
import {
  Typography,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  Checkbox,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import ExportButtons from "../../../components/ExportButtons";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { DeleteOutline, Edit, ViewList } from "@material-ui/icons";

import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import MomentUtils from "@date-io/moment";
import moment from "moment";

//Services
import * as Service from "../../../services/Service.js";

const UnionEntry = (props) => {
  const permissionType = props.permissionType;
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );

  
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const lan = localStorage.getItem("LangCode");
  const FacilityId = localStorage.getItem("FacilityId");
  const menukey = "union-entry-form";
  const FacilityName = localStorage.getItem("FacilityName");

  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;
  const { useState } = React;
  //Group working
  const [firstLoad, setFirstLoad] = useState(true);
  const [getsetUpazilaList, setUpazilaList] = useState(0);

  const UpazilaList = JSON.parse(localStorage.getItem("UpazilaList"));
  UpazilaList.unshift({ 'id': 0, 'name': t(DispensingLanguage[lan][menukey]["All"]) });


  const classes = useStyles();
  const tableRef = useRef();
  const [selectedRow, setSelectedRow] = useState(null);
  const [numberRowPerPage, setNumberRowPerPage] = useState(5);
  const [totalRow, setTotalRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [dataItems, setDataItems] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(90, "days").format("YYYY-MM-DD")
  );



  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );


  const [isLoading, setLoading] = useState(true);


 //====== Start Choosen Dropdown===========
   const [chosenValues, setChosenValues] = useState({
    "upazila_name": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
  
  });

  const handleChangeChoosen = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues};
    chosenValuesData[name] = valueobj;
    setChosenValues(chosenValuesData); 

    setUpazilaList(value);
    setFirstLoad(true);
    setLoading(false);

  };  
//====== End Choosen Dropdown===========


  let params = {
    action: 'getunionList',
    menukey: menukey,
    FacilityId: FacilityId,
    getsetUpazilaList: getsetUpazilaList,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",

    //TransactionTypeId: 3,


  };
  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getUnionList,
    {
      onSuccess: (data) => {
        if (firstLoad) {
          localStorage.setItem(
            "StockData",
            JSON.stringify(data)
          );

          setDataItems(data);
          setLoading(false);
          setFirstLoad(false);
        }


      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    setLoading(true);
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    setLoading(true);
  };

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    // let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    //let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");

    window.open(
      finalUrl +
      "?action=getUnionListEntry" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&FacilityId=" +
      FacilityId +
      "&getsetUpazilaList=" +
      getsetUpazilaList +
      "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    editRecord(rowData);
  };

  function RowInputData(props, type = null) {
    const rowData = props.cell._cell.row.data;
    let fieldName = props.field;
    let fieldType = props.fieldType;
   // let labelName = props.labelName;
   // let dropDownKey = props.dropDownKey;

    switch (fieldType) {
      case "check-box":
        return rowData.type == "bDispensing" ? (
          <></>
        ) : (
          <Checkbox
            color="primary"
            checked={rowData[fieldName]}
            name={fieldName}
            value={rowData[fieldName]}
          />
        );
        break;

      default:
        return <></>;
        break;
    }
    return <></>;
  }




  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 70,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Union Name"],
      field: "UnionName",
      // width: "80%",
      headerFilter: true,
    },{
      title: DispensingLanguage[lan][menukey]["Active"],
      field: "bActive",
      hozAlign: "center",
      headerHozAlign: "center",
      width: 80,
      headerSort: false,
      formatter: reactFormatter(
        <RowInputData
          labelName="bDispensing"
          field="bActive"
          fieldType="check-box"
        />
      ),
    },
    
    
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      headerHozAlign: "center",
      hozAlign: "center",
      width: 100,
      formatter: reactFormatter(<ActionButton />),
    },
  ];


  const handleChange = (event) => {
    // setAll(event.target.value);
    setUpazilaList(event.target.value);
    setFirstLoad(true);
    setLoading(false);
  };


  const handleChangePage = (data) => {
    setPageNumber(data);
    // setFirstLoad(false);
  };
  const handleChangeRowPerPage = (data) => {
    setNumberRowPerPage(data);
  };

  const editRecord = (data) => {
    console.log('data d', data);

    let route = `union-entry-form/edit/${data.Uid}`;
    props.history.push(route);
  };

  const deleteRecord = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
        "Once deleted, you will not be able to recover this record!"
        ]
      ),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: [t(DispensingLanguage[lan][menukey]["No"]),
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willDelete) => {
      if (willDelete) {
        // mutate(data.id);
      }
    });
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    // console.log('UnionEntery data',rowData);
    // const cellValue = props.cell._cell.value || "Edit | Show";
    if(permissionType)
    return <></>
    else
    return (
      <>
        <Edit
          onClick={() => {
            editRecord(rowData);
          }}
        />
      </>
    );
  }

  return (
    <>
      <div className={classes.productPageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Union List Entry"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  {/* <InputLabel id="">
                    {DispensingLanguage[lan][menukey]["Upazila Name"]}
                  </InputLabel>
                  <Select
                    labelId="upazila_name"
                    id="upazila_name"
                    name="upazila_name"
                     value={getsetUpazilaList}
                    defaultValue='0'
                    fullWidth
                    onChange={handleChange}
                  >
                     <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                    {UpazilaList.map((item, index) => {
                      return (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      );
                    })}
                  </Select> */}

                        <Autocomplete
                          autoHighlight
                          disableClearable
                          id="upazila_name"
                          options={UpazilaList}
                          onChange={(event, valueobj) => handleChangeChoosen('upazila_name', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValues['upazila_name']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Upazila Name"]}
                            variant="standard"
                            id="upazila_name"
                            name="upazila_name"
                            fullWidth
                          />
                          )}
                        />
                </FormControl>
              </Grid>

              <Grid item xs={9} sm={9}>
                <div className="float-right sw_btn_control">
                  <Button
                    color="info"
                    className="mr-2"
                    disabled={permissionType}

                    onClick={() => {
                      if (getsetUpazilaList == 0) {
                        
                        props.openNoticeModal({
                          isOpen: true,
                          msg: DispensingLanguage[lan][menukey]["Please select an upazilla"],
                          msgtype: 0,
                        });

                      //  alert("Please Select the Upazila Name");
                      } else {
                        props.history.push("/union-entry-form/add/" + getsetUpazilaList);
                      }

                    }}

                  >
                    {DispensingLanguage[lan][menukey]["Add New"]}
                  </Button>

                  <ExportButtons PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}  {...props}></ExportButtons>
                  {/* <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button> */}
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="450px"
              options={{
                groupBy: "upazila_name",
                columnCalcs: "both",
              }}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UnionEntry;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
