import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function Images() {
  return (
    <>
      <div className="section">
        <Container>
          <Row>
            <Col md="5">
              <div className="sw_home_img">
                <img
                  alt="..."
                  src={require("assets/img/dispensing_vector_small.png")}
                ></img>
              </div>
            </Col>
            <Col md="7">
              <div className="sw_home_content">
                <p>
                DGFP Electronic Logistics Management Information System (eLMIS) is an online stock management software for Central Warehouse, Regional Warehouses & sub-district stores of Directorate General of Family Planning (DGFP) under Ministry of Health and Family Welfare (MOHFW), Bangladesh.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Images;
