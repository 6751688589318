import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import jsPDF from "jspdf";

//Services
import * as Service from "../../../services/Service.js";
import Constants from "../../../services/Constants";
import swal from "sweetalert";
// materialui table
import MaterialTable from "material-table";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
  Typography,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
} from "@material-ui/core";

import ExportButtons from "../../../components/ExportButtons";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//import ReactExport from "react-data-export";

import { useTranslation } from "react-i18next";

//import XLSX from "xlsx";
//import FileSaver from "file-saver";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";

import * as api from "../../../actions/api";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, ViewList, preview, Edit } from "@material-ui/icons";
import { cellFocusEditor } from "../../../services/Common";
import moment from "moment";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";



const Products = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const menukey = "stock-status";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;

  const classes = useStyles();
  let tblRef = null;
  const tableRef = useRef();
  const { useState } = React;
  const [selectedRow, setSelectedRow] = useState(null);
  const [productsData, selectProductsData] = useState([]);
  const [productsAllData, selectProductsAllData] = useState([]);
  const [productGroupData, selectProductGroupData] = useState([]);
  const [productGroupFilter, selectProductGroupFilter] = useState("0");
  const [firstLoad, setFirstLoad] = useState(true);
  const [numberRowPerPage, setNumberRowPerPage] = useState(5);
  const [totalRow, setTotalRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [query, setQuery] = useState("");

  const [TableDataUpdate, setTableDataUpdate] = useState([]);
  const [ManyJsonSave, setInputChange] = useState({});
  const [radioValue, setRadioValue] = useState(2);
  const [dataItems, setDataItems] = useState([]);
   

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();
  const UseFor = localStorage.getItem("UseFor");

  let params = {
    menukey: menukey,
    FacilityId: FacilityId,
    action: "getDataList",
    StartDate: selectedStartDate,
    UseFor: UseFor,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    RadioValue: radioValue,
  };

  const [isLoading, setLoading] = useState(false);

  const { error, isError, data, refetch:stockData } = useQuery(
    [params],
    api.getstockstatus,
    {
      onSuccess: (data) => {
        if(firstLoad){
          localStorage.setItem(
            "StockData",
            JSON.stringify(data)
          );
          
          setDataItems(data);
          setLoading(false);
          setFirstLoad(false);
        }
      },
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 0
  }
  ); 


  const setUpdatedDetailsGridDataHandler = (rows) => {
    const updateTableData = data.map((row) => {
      return row;
    });
    //setTableDataUpdate(updateTableData);
    //setDataItems(data);
  };

  const { isLoading3, mutate: SaveAMC } = useMutation(api.UpdateAMC, {
    onSuccess: (data) => {
      if (data.status == 200) {
        stockData();
        
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
        //swal("Success!", "", "success");
      }else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    },
  });

  const handleSave = async (e) => {
    let FinalData = {
      action: "AMCUpdate",
      ManyJsonSave: tblRef.table.getData(),
      menukey: menukey,
      FacilityId: FacilityId,
      lan: lan,
      UserName: UserName
    };
    SaveAMC(FinalData);
  };

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let formatedDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";

    window.open(
      finalUrl +
        "?action=StockMasterExcelExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&StartDate=" +
        formatedDate +
        "&FacilityId=" +
        FacilityId +
        "&RadioValue=" +
        radioValue +
        "&UseFor=" +
        UseFor +
        "&FacilityName=" +
        FacilityName + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e, row) => {
    let rowData = row._row.data;
    console.log(row._row);
    row._row.parent.rows.forEach((rowss,i) => {

      
      if(row._row.data.ItemNo==rowss.data.ItemNo)
      {
        localStorage.setItem("StockDataIndex",JSON.stringify(i));
        editUser(rowData);
        return;
      }
        
      }) ;

    
  };

  const columns = [
    {
      title: "Id",
      field: "id",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 80,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Group"],
      field: "GroupName",
      headerFilter: true,
      width: 165,
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Code"],
      field: "ItemNo",
      headerFilter: true,
      width: 130,
    },
    {
      title: DispensingLanguage[lan][menukey]["Product Name"],
      field: "ProductName",
      headerFilter: true,
    }, 
    {
      title: DispensingLanguage[lan][menukey]["Usable Qty"],
      field: "UsableQty",
      hozAlign: "right",
      headerHozAlign: "right",
      headerFilter: true,
      width: 110,
     formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : Number(value).toLocaleString();
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Unusable Qty"],
      field: "UnusableQty",
      hozAlign: "right",
      headerHozAlign: "right",
      headerFilter: true,
      width: 125,
     /*  formatter: "money",
      formatterParams:{ thousand:",",  precision:false }, */
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : Number(value).toLocaleString();
      },

    },
    {
      title: DispensingLanguage[lan][menukey]["Unit"],
      field: "UnitName",
      headerFilter: true,
      width: 100,
    }, 
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      hozAlign: "center", 
      headerHozAlign: "center", 
      width: 80,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
   // console.log(props.cell._cell.row);
    //const cellValue = props.cell._cell.value || 'Edit | Show';
    return (
      <>
        <ViewList
          onClick={() => {
            dataItems.forEach((row,i) => {
              if(rowData.ItemNo==row.ItemNo)
              {
                localStorage.setItem(
                  "StockDataIndex",
                  JSON.stringify(i)
                );
                editUser(rowData);
                return;
              } 
             
            });  
           
          }}
        />
      </>
    );
  }

  const handleStartDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    let newDate = moment(date).format("YYYY-MM-DD");
    setSelectedStartDate(newDate);
    console.log("date: ",date);
    console.log("selectedStartDate: ",selectedStartDate);
  };

  
  const handleChangePage = (data) => {
    setPageNumber(data);
    setFirstLoad(false);
  };
  const handleChangeRowPerPage = (data) => {
    setNumberRowPerPage(data);
  };

  const checkLogin = () => {
    let token = Constants.token;

    if (!token) {
      swal("Oops!", `token expired. Please login again`, "error");
      props.history.push("/login");
    }
  };
  const editUser = (data,dataItemss) => { 
    // console.log('Row dblclick radioValue: ', radioValue);
    // selectedStartDate
    let route = 'stock-status/edit/'+data.ItemNo+'|'+data.MasterFilterDate+'|'+data.MasterFilterRadio;
    props.history.push(route);
  };

  const changeRadio = (value) => {
    setFirstLoad(true);
    setLoading(true);
    setRadioValue(value);
  };

 React.useEffect(() => {
   if (tblRef == null) return;
   setLoading(true);
   stockData();
  }, []);


  // React.useEffect(() => {
  //   let token = Constants.token;
  //   if (token) {
  //   }
  // }, []);

  // React.useEffect(() => {}, [
  //   pageNumber,
  //   numberRowPerPage,
  //   query,
  //   productGroupFilter,
  // ]);


  return (
    <>
      <div className={classes.productPageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Stock Status"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      //disableToolbar
                      autoOk={true}
                      // disabled={true}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["Stock Date"]}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={8} sm={8}>
                <FormControl component="fieldset" className="mt-3 ml-3">
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="row-radio-buttons-group"
                    defaultValue="SHOW_NONE_ZERO"
                  >
                    
                    
                    <FormControlLabel
                      value="SHOW_NONE_ZERO"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["SHOW NONE-ZERO"]}
                      onClick={() => changeRadio(2)}
                    />
                    
                    <FormControlLabel
                      value="SHOW_ZERO"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["SHOW ZERO"]}
                      onClick={() => changeRadio(1)}
                    />
                    <FormControlLabel
                      value="SHOW_ALL"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["SHOW ALL"]}
                      onClick={() => changeRadio(0)}
                    />

                    <FormControlLabel
                      value="Show_Only_Usable_Stock"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["Show Only Usable Stock"]}
                      onClick={() => changeRadio(3)}
                    />

                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={2} sm={2}>
                <div className="float-right sw_btn_control">
                  {/* <Button
                    className="mr-2"
                    variant="contained"
                    color="info"
                    type="submit"
                    onClick={() => handleSave()}
                  >
                    {DispensingLanguage[lan][menukey]["Save AMC"]}
                  </Button> */}

                  <ExportButtons PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}  {...props}></ExportButtons>
                  
                  {/* <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button> */}
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}

          <div className="uniqueName">
            <ReactTabulator
              ref={(r) => (tblRef = r)}
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="400px"
              // groupBy="GroupName"
              options={{
                  groupBy: "GroupName",
                  columnCalcs: "both",
                }}
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Products;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
