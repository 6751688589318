import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";

import ProductGroupFormData from "./ProductGroupFormData";




const AddProductGroup = (props) => {
  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "product-group";
const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;  
const UserName = UserInfo==0?'': UserInfo[0].id;

  const [formData, setFormData] = useState({
    GroupCode:"",
    GroupName: ""
  });
  const [errorObject, setErrorObject] = useState({});
  const [pricebesideLevel, setPricebesideLevel] = useState(null);
  const { id } = useParams();
  const queryClient = useQueryClient();


  useQuery(
    ["productgroup", id],
    () =>
      api.getProductGroup(id).then((res) => {
        setFormData(res.data);
      }),
    {
      enabled: Boolean(id),
    }
  );

  // const { isLoading, mutate } = useMutation(api.saveProductGroup, {
  //   onSuccess: (data) => {
  //     if (data.status == 200) {
  //       api.getAllDropdown('ProductGroup').then((response) => {
  //         localStorage.setItem(
  //           "ProductGroup",
  //           JSON.stringify(response.datalist.ProductGroup)
  //         );
  //       }) 
  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.message,
  //         msgtype: data.success,
  //       });
  //      // swal(data.data.message, "", "success");
  //     queryClient.getQueriesData("productgroup");//api page name
  //     props.history.push("/product-group");//

  //     }else{   
  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.message,
  //         msgtype: data.success,
  //       });
  //     }
  //   },
  // });

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    console.log("formData: ", formData);
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      GroupCode:"",
      GroupName: ""
      
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["GroupCode","GroupName"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =   DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  function checkValidateForm(){

     
    if (validateForm(formData)) {
    let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName,}; 
   
    return fDtat;
    }else{
    return false;
    }

};

  // const handleSubmit = async (e) => {
    
  //   if (validateForm(formData)) {

  //     let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
  //     mutate(fDtat);
     
  //   }
  // };

  return (
    <>
      <ProductGroupFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        checkValidateForm={checkValidateForm}
        // handleSubmit={handleSubmit}
        bStockUpdate={props.permissionType}
        {...props}
      />
    </>
  );
};

export default AddProductGroup;

