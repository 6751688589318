import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import swal from "sweetalert";

import { DeleteOutline, Edit, ViewList, FileCopy } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import ExportButtonsMany from "../../../components/ExportButtonsMany";
//react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import { useTranslation } from "react-i18next";
import * as api from "../../../actions/api";
import { cellFocusEditor, dateEditor } from "../../../services/Common";

import moment from "moment";
let timeStamp = "";

const SupplyPlanFormData = (props) => {
  const permissionType = props.permissionType;
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "supply-plan";
  const FacilityId = localStorage.getItem("FacilityId");

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  let ReceiveDetailsRef = null;

  const [newBatchRowAdded, setNewBatchRowAdded] = useState(false);

  const [open, setOpen] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);

  const [SoureOpen, setSoureOpen] = useState(false);

  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);

  const IssuingToFacility = JSON.parse(
    localStorage.getItem("IssuingToFacility")
  );
  const MonthList = JSON.parse(localStorage.getItem("MonthList"));

  // const FacilityEmployee = JSON.parse(localStorage.getItem("FacilityEmployee"));
  // const Designation = JSON.parse(localStorage.getItem("Designation"));
  // const [currRowId, setCurrRowId] = useState(0);
  // const [currFormulation, setCurrFormulation] = useState("");
  // const [bIsDisable, setbIsEnable] = useState(false);
  const [SupplyPlanSettingItems,setSupplyPlanSettingItems] = useState({});

  const [toggleButtonValue, setToggleButton] = React.useState("");
  // const [toggleButtonValue, setToggleButton] = React.useState("CON002");
  const UseFor = localStorage.getItem("UseFor");

  
//====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "MonthId": {"id": "","name": ""}
});


useEffect(() => {
  
  let chosenValuesData = { ...chosenValues};
   chosenValuesData['MonthId'] = MonthList[MonthList.findIndex(Month_List => Month_List.id == props.formData.MonthId)];
   setChosenValues(chosenValuesData); 

}, [props.formData.MonthId]); 


const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  setChosenValues(chosenValuesData); 

  props.handleChangeChoosenProps(name, value);
};

//====== End Choosen Dropdown===========

   /* =====Start of Print Excel Many Code==== */
   const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

   const PrintMany = () => {
     let finalUrl = EXCEL_EXPORT_URL + "report/supplyplan_print_excel.php";
 
     window.open(
       finalUrl +
         "?menukey=" +
         menukey +
         "&lan=" +
         localStorage.getItem("LangCode") +
         "&TransactionId=" +
         props.formData.TransactionNo +
         "&currItemCode=" +
         props.formData.currItemCode +
         "&FacilityId=" +
         FacilityId +
         "&UseFor=" +
        UseFor +
         "&TimeStamp=" +
         Date.now()
     );
   };
 
   /* =====End of Excel Export Code==== */
 
 
  const { data:SettingItems,refetch:getSettingItems } = useQuery(['supplyplansettingitems', props.formData.TransactionNo], () =>
  api.getSupplySettingItems(props.TransactionNo).then((res) => {
    console.log('SettingItems: ', res.data);
    
    if(Object.keys(res.data).length>0){
      setToggleButton(res.data[0].ProdCode);
    }
    
    // console.log("SupplyPlanSettingItems:",SupplyPlanSettingItems);
    // console.log("SupplyPlanSettingItems:",Object.keys(SupplyPlanSettingItems).length);
    setSupplyPlanSettingItems(res.data); 
    // console.log("res.data[0]",res.data[0].ProdCode); 
    
    return res.data
  }), {
  refetchOnWindowFocus: false,
  enabled: Boolean(props.formData.TransactionNo)
})


  const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;

      default:
        break;
    }
  };

  const generateForm7Items = () => {
    props.generateForm7Items();
  };

  const generateIssueVoucher = () => {
    props.generateIssueVoucher();
  };

  const editCheck = function(cell){
    const rowData = cell._cell.row.data;
    return (rowData.bUpdated == 0); 
  }


  const ReceiveDetailsColumns = [
    { title: "TransactionItemId", field: "TransactionItemId", visible: false },
    { title: "id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      field: "ItemCode",
      width: 40,
      formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
      // formatter: function (cell, formatterParams) {
      //   let _ItemCode = cell.getValue();
      //   if(_ItemCode == "CON004" || _ItemCode == "CON005"){
      //     return "";
      //   }else{
      //     serialNo++;
      //     return serialNo;
      //   }
      // },
    },
    {
      title: DispensingLanguage[lan][menukey]["Upazila"],
      field: "FacilityName",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["AMD"],
      field: "LastMonthsDist",
      width: 80,
      hozAlign: "right",
      headerHozAlign: "right",
      bottomCalc: "sum",
      bottomCalcFormatter: "money",
      bottomCalcFormatterParams:  {
          thousand: ",",
          precision:false
        },
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : Number(value).toLocaleString();
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Max Quantity"],
      field: "MaxQty",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
      bottomCalc: "sum",
      bottomCalcFormatter: "money",
      bottomCalcFormatterParams:  {
          thousand: ",",
          precision:false
        },
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : Number(value).toLocaleString();
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Closing"],
      field: "PrevClosing",
      width: 85,
      hozAlign: "right",
      headerHozAlign: "right",
      bottomCalc: "sum",
      bottomCalcFormatter: "money",
      bottomCalcFormatterParams:  {
          thousand: ",",
          precision:false
        },
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : Number(value).toLocaleString();
      },
    },

    {
      title: DispensingLanguage[lan][menukey]["Alloted Quantity"],
      field: "AllotedQty",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
      bottomCalc: "sum",
      bottomCalcFormatter: "money",
      bottomCalcFormatterParams:  {
          thousand: ",",
          precision:false
        },
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : Number(value).toLocaleString();
      },
    },
    {
      title: "Actual Supply Quantity Old Value",
      field: "ActualSupply_Old",
      width: 125,
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Actual Supply Quantity"],
      field: "ActualSupply",
      width: 125,
      hozAlign: "right",
      headerHozAlign: "right",
      bottomCalc: "sum",
      cssClass: "tabluator-column-editable",
      editor: props.bStockUpdate == 0 ? cellFocusEditor : "",
      // editor: cellFocusEditor,
      editable:editCheck,
      formatter: "money",
      formatterParams:{ thousand:",",  precision:false },
      bottomCalcFormatter: "money",
      bottomCalcFormatterParams:  {
          thousand: ",",
          precision:false
        },
      // formatter: function (cell, formatterParams) {
      //   let value = cell.getValue();
      //   return value == 0 ? "" : value;
      // },
    },

    {
      title: DispensingLanguage[lan][menukey]["Generate Indent"],
      field: "bGenerate",
      width: 100,
      hozAlign: "center",
      headerHozAlign: "center",
      // editor: true,
      editor:props.bStockUpdate == 0 ? true : false,
      editable:editCheck,
      formatter: "tickCross",
      // formatter: function (cell, formatterParams) {
      //   let value = cell.getValue();
      //   return value == 0 ? "" : value;
      // },
      // cellEdited: function (cell) {
      //   api.dirtyModeEnable();
      // },
    },
    {
      title: DispensingLanguage[lan][menukey]["Updated"],
      field: "bUpdated",
      headerSort: false,
      hozAlign: "center",
      headerHozAlign: "center",
      width: 80,
      formatter: reactFormatter(<ActionButton />),
    },

    {
      title: DispensingLanguage[lan][menukey]["Invoice No"],
      field: "invno",
      width: 115,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },
    },

    {
      title: DispensingLanguage[lan][menukey]["AMD Month"],
      field: "AMDMonth",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },
    },
  ];

  const handleChangeToggle = (event, tmpToggleButtonValue) => {
    if (tmpToggleButtonValue) {
      console.log("tmpToggleButtonValue: ", tmpToggleButtonValue);
      props.reFetchItems(tmpToggleButtonValue);
      // if (tmpToggleButtonValue == 1) {
      //   // setForm7BTableColumns(UpazilaStoreColumns);
      // } else {
      //   // setForm7BTableColumns(UpazilaFieldColumns);
      // }

      setToggleButton(tmpToggleButtonValue);
    }
  };

const getItemStock =(currStockJson)=>{
  let stockQty = 0;
  if(currStockJson){
    let currStockJsonList = JSON.parse(currStockJson);
    // console.log("getItemStock toggleButtonValue: ", toggleButtonValue);

    currStockJsonList.forEach((row, i) => {
      if(row.ItemCode == toggleButtonValue){
        stockQty = row.Qty;
      }

    });

  }

  return stockQty;
  };


  const onSaveUpdateBtnClick = () => {
    props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData());
    //  props.handleUpdate();
  };

  const deleteReceiveInv = (data) => {
    let newarray = manyTableData.filter((element) => element !== data);
    setManyTableData(newarray);
    props.deleteReceiveInv(data);
  };

  const CopyBatch = () => {
    props.handleAdd(manyTableData);
  };

  function ActionButton(props) {
    const cellValue = props.cell._cell.value;
    // console.log('cellValue: ', cellValue);
    if (cellValue)
      return (
        <>
          <i class="fa fa-lock"></i>
        </>
      );
    else return <></>;
  }

  useEffect(() => {
    if (ReceiveDetailsRef == null) return;
    if (props.bFirst) {
      setManyTableData(props.ManyTableData);
    }

    setbStockUpdate(props.bStockUpdate);
  }, [props.ManyTableData, props.bStockUpdate]); //

  useEffect(() => {
    if (ReceiveDetailsRef.table != null && timeStamp != "") {
      ReceiveDetailsRef.table.scrollToRow(timeStamp, "center", true);
      ReceiveDetailsRef.table.selectRow(timeStamp);
    }
  }, [newBatchRowAdded]);

  const handleReset = () => {
    props.handleReset();
    setManyTableData([]);
  };
  
  const backtolist=()=>{


    let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
    let idx = master_dirty_message_class.indexOf("dnone");
    if(idx === -1){
      // start Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          props.history.push("/supplyplan");
        }
      });
      // End Alert
    }else{
      props.history.push("/supplyplan");
    }

  }

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-1">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Supply Plan - Add/Edit"]}
              </div>

              <div className="float-right sw_btn_control">
                {props.addProductForm ? (
                  <Grid item xs={12} className=" text-center">
                    <div className="float-right sw_btn_control">
                      <Button
                      disabled={props.bStockUpdate}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button>
                      <Button
                        disabled={props.bStockUpdate}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onSaveUpdateBtnClick} //() => props.handleSubmit()
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        // onClick={() => props.history.push("/supplyplan")}
                      onClick={() => backtolist()}

                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="mt-2 text-center">
                    <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    {/* <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onPostBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["post"]}
                    </Button> */}
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      // onClick={() => props.history.push("/supplyplan")}
                      onClick={() => backtolist()}

                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>

                    <ExportButtonsMany PrintMany={PrintMany}  {...props}></ExportButtonsMany>
                    {/* <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PrintMany()}
                    >
                      <i class="fas fa-print"></i>
                    </Button> */}

                    {/* <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelMany()}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button> */}
                  </Grid>
                )}
              </div>
              
              <div className="mr-4 float-right mt-15">
                <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
              </div>

            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={1}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            {/* <Card className="sw_card">
            <CardHeader title="Personal Information" />
            <CardContent> */}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={1}>
                  {/* <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={true}
                      error={props.errorObject.TransactionNo}
                      helperText={props.errorObject.TransactionNo}
                      required
                      id="TransactionNo"
                      name="TransactionNo"
                      label={DispensingLanguage[lan][menukey]["ID"]}
                      value={
                        props.formData.TransactionNo || props.generatedInvoiceNo
                      }
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid> */}

                  <Grid item xs={3} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <InputLabel id="demo-simple-select-helper-label-receive-from">
                        {DispensingLanguage[lan][menukey]["WH"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.FacilityCode}
                        labelId="demo-simple-select-helper-label-receive-from"
                        id="FacilityCode"
                        name="FacilityCode"
                        value={props.formData.FacilityCode}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        disabled={true /*props.bStockUpdate*/}
                      >
                        {IssuingToFacility.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>

                      <FormHelperText error={props.errorObject.FacilityCode}>
                        {props.errorObject.FacilityCode}
                      </FormHelperText>
                    </FormControl>
                  </Grid>

                  <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                      {/* <InputLabel id="demo-simple-select-helper-label-receive-from">
                        {DispensingLanguage[lan][menukey]["Report Month"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.MonthId}
                        labelId="demo-simple-select-helper-label-receive-from"
                        id="MonthId"
                        name="MonthId"
                        value={props.formData.MonthId}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        // disabled={props.bStockUpdate}
                        disabled={
                          props.bStockUpdate ||
                          (props.addProductForm ? false : true)
                        }
                      >
                        {MonthList.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>

                      <FormHelperText error={props.errorObject.MonthId}>
                        {props.errorObject.MonthId}
                      </FormHelperText> */}

                      <Autocomplete
                        autoHighlight
                        disableClearable
                        disabled={
                          (props.bStockUpdate ||
                            !props.addProductForm ? true : false)
                          }
                        id="MonthId"
                        options={MonthList}
                        onChange={(event, valueobj) => handleChangeChoosen('MonthId', valueobj, valueobj?valueobj.id:"")}
                        getOptionLabel={(option) => option.name}
                        value={chosenValues['MonthId']}
                        renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          value={props.formData.MonthId}
                          label={DispensingLanguage[lan][menukey]["Report Month"]+ " *"}
                          variant="standard"
                          error={props.errorObject.MonthId}
                          helperText={props.errorObject.MonthId}
                          id="MonthId"
                          name="MonthId"
                          fullWidth
                        />
                        )}
                      />


                    </FormControl>
                  </Grid>

                  <Grid item xs={2} sm={2}>
                    <TextField
                     disabled={
                      (props.bStockUpdate ||
                        !props.addProductForm ? true : false)
                      }
                      error={props.errorObject.YearName}
                      helperText={props.errorObject.YearName}
                      required
                      id="YearName"
                      name="YearName"
                      label={DispensingLanguage[lan][menukey]["Report Year"]}
                      value={props.formData.YearName || props.selectedYearName}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={5} sm={5}>
                    <TextField
                      disabled={props.bStockUpdate}
                      id="Remarks"
                      name="Remarks"
                      label={DispensingLanguage[lan][menukey]["Remarks"]}
                      value={props.formData.Remarks}
                      fullWidth
                      multiline
                      rows={3}
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="TransactionId"
                      name="TransactionId"
                      label="TransactionId"
                      value={props.formData.TransactionId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={1}>
                {/* new row */}
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card">
                    <CardContent>

                      <Grid item xs={12} sm={12}>
                        <ReactTabulator
                          ref={(r) => (ReceiveDetailsRef = r)}
                          columns={ReceiveDetailsColumns}
                          rowFormatter={function (row) {
                            var data = row.getData();
                            if (data.linkNo > 0) {
                              row.getElement().style.backgroundColor =
                                "#D9FCFF";
                              row.getElement().style.fontWeight = "bold";
                            }
                          }}
                          data={manyTableData}
                          height="300px"
                          // minHeight="350px"
                          layout={"fitColumns"}
                        />
                      </Grid>
 
                      <Grid item xs={12} sm={12}>
                        <div
                          className="float-rights sw_btns_control"
                          style={{ padding: "5px 0px 5px 0px" }}
                        >
                          

                          <Button
                            disabled={
                              (props.bStockUpdate ||
                                props.addProductForm ? true : false)
                              }
                            className="mr-2"
                            variant="contained"
                            color="primary"
                            onClick={() => generateForm7Items()}
                          >
                            {DispensingLanguage[lan][menukey]["Generate"]}
                          </Button>
                          <Button
                           disabled={
                            (props.bStockUpdate ||
                              props.addProductForm || manyTableData.length==0? true : false)
                            }
                            className="mr-2"
                            variant="contained"
                            color="primary"
                            onClick={() => props.deleteForm7Items()}
                          >
                            {DispensingLanguage[lan][menukey]["Delete"]}
                          </Button>
                          
                          {props.formData.CreationDate &&
                            <  >
                              {DispensingLanguage[lan][menukey]["Stock as on"] +" "+ moment(props.formData.CreationDate).format("DD/MM/YYYY") +": "+ getItemStock(props.formData.StockJson) }
                            </ >
                          }
                        
                        </div>
                       
                        

                      </Grid>

                      <Grid item xs={12} sm={12}>
                        <ToggleButtonGroup
                          className="sw_ToggleButtonGroup"
                          color="primary"
                          value={toggleButtonValue}
                          exclusive
                          onChange={handleChangeToggle}
                        >

                        {Object.keys(SupplyPlanSettingItems).length && (SupplyPlanSettingItems.map((item, index) => {
                          return (
                            <ToggleButton disabled={( props.addProductForm ? true : false) } value={item.ProdCode}>
                              {item.ProdText}
                            </ToggleButton>

                          );
                        }))}



                          {/* <ToggleButton disabled={props.bStockUpdate ||(props.addProductForm ? true : false) } value="CON002">
                            {DispensingLanguage[lan][menukey]["Condom (pcs)"]}
                          </ToggleButton>

                          <ToggleButton disabled={props.bStockUpdate ||(props.addProductForm ? true : false) } value="CON008">
                            {DispensingLanguage[lan][menukey]["Shukhi (cycles)"]}
                          </ToggleButton>

                          <ToggleButton disabled={props.bStockUpdate ||(props.addProductForm ? true : false) } value="CON007">
                            {DispensingLanguage[lan][menukey]["IUD CT-380A (pcs)"]}
                          </ToggleButton>

                          <ToggleButton disabled={props.bStockUpdate ||(props.addProductForm ? true : false) } value="CON006">
                            {DispensingLanguage[lan][menukey]["Injectables (vials)"]}
                          </ToggleButton>

                          <ToggleButton disabled={props.bStockUpdate ||(props.addProductForm ? true : false) } value="CON001">
                            {DispensingLanguage[lan][menukey]["AD Syringe (1ML)"]}
                          </ToggleButton>

                          <ToggleButton disabled={props.bStockUpdate ||(props.addProductForm ? true : false) } value="CON005">
                            {DispensingLanguage[lan][menukey]["Implant (nos)"]}
                          </ToggleButton>

                          <ToggleButton disabled={props.bStockUpdate ||(props.addProductForm ? true : false) } value="MCH002">
                            {DispensingLanguage[lan][menukey]["DDS Kit"]}
                          </ToggleButton> */}

                        </ToggleButtonGroup>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={1} sm={1}>
                  <div className="paddingTop">
                    {DispensingLanguage[lan][menukey]["Rationing %"]}
                  </div>
                </Grid>

                <Grid item xs={1} sm={1}>
                  <TextField
                  
                  className="RfontSize"
                    disabled={props.bStockUpdate}
                    id="RCondom"
                    name="RCondom"
                    label={DispensingLanguage[lan][menukey]["Condom"]}
                    value={props.formData.RCondom}
                    fullWidth
                    autoComplete="family-name"
                    onChange={(e) => props.handleChange(e)}
                  />
                </Grid>

                <Grid item xs={1} sm={1}>
                  <TextField
                  
                  className="RfontSize"
                  disabled={props.bStockUpdate}
                    id="RShukhi"
                    name="RShukhi"
                    label={DispensingLanguage[lan][menukey]["Shukhi"]}
                    value={props.formData.RShukhi}
                    fullWidth
                    autoComplete="family-name"
                    onChange={(e) => props.handleChange(e)}
                  />
                </Grid>

                <Grid item xs={1} sm={1}>
                  <TextField
            
            className="RfontSize"
                    id="RShukhi3G"
                    name="RShukhi3G"
                    label={DispensingLanguage[lan][menukey]["Shukhi (3rd Gen)"]}
                    value={props.formData.RShukhi3G}
                    fullWidth
                    autoComplete="family-name"
                    onChange={(e) => props.handleChange(e)}
                  />
                </Grid>

                <Grid item xs={1} sm={1}>
                  <TextField
                  
                  className="RfontSize"
                  disabled={props.bStockUpdate}
                    id="RIdu"
                    name="RIdu"
                    label={DispensingLanguage[lan][menukey]["IUD"]}
                    value={props.formData.RIdu}
                    fullWidth
                    autoComplete="family-name"
                    onChange={(e) => props.handleChange(e)}
                  />
                </Grid>

                <Grid item xs={1} sm={1}>
                  <TextField
                  
                  className="RfontSize"
                   disabled={props.bStockUpdate}
                    id="RInjectable"
                    name="RInjectable"
                    label={DispensingLanguage[lan][menukey]["Injectable"]}
                    value={props.formData.RInjectable}
                    fullWidth
                    autoComplete="family-name"
                    onChange={(e) => props.handleChange(e)}
                  />
                </Grid>

                <Grid item xs={2} sm={2}>
                  <Button
                    disabled={
                      (props.bStockUpdate ||
                      props.addProductForm || manyTableData.length==0? true : false)
                    }
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={() => generateForm7Items()}
                  >
                    {DispensingLanguage[lan][menukey]["Recalculate"]}
                  </Button>
                </Grid>
                <Grid item xs={1} sm={1}></Grid>
                <Grid item xs={3} sm={3}>
                  <Button
                    disabled={
                      (props.bStockUpdate ||
                      props.addProductForm || manyTableData.length==0? true : false)
                    }
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={() => generateIssueVoucher()}
                  >
                    {DispensingLanguage[lan][menukey]["Generate Issue Voucher"]}
                  </Button>
                </Grid>

                {/* new row */}
              </Grid>
            </Grid>

            {/* </CardContent>
          </Card> */}
          </Grid>

          {/* Action buttons */}
        </Grid>
      </div>
    </div>
  );
};

export default SupplyPlanFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
  paddingTop: {
    paddingTop: "15px",
  },
});
