import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardContent
} from "@material-ui/core";

//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "funding-source-entry";


const FundingsourceFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  handleChangeColorAdd,
  ...props
}) => {
  const classes = useStyles();

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
      <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Funding Source Entry - Add/Edit"]}
              </div>
            </div>
            <div className="float-right sw_btn_control">
              {addProductForm ? (
                <Grid item xs={12} className="text-center">
                  <div className="float-right sw_btn_control">
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => handleReset()}>
                      {DispensingLanguage[lan][menukey]["Reset"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => handleSubmit()}>
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() =>
                        props.history.push("/funding-source-entry")
                      }>
                      {DispensingLanguage[lan][menukey]["Cancel"]}
                    </Button>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} className="mt-2 text-center">
                  <Button
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={() => handleUpdate()}>
                    {DispensingLanguage[lan][menukey]["update"]}
                  </Button>
                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    onClick={() => props.history.push("/funding-source-entry")}>
                    {DispensingLanguage[lan][menukey]["Cancel"]}
                  </Button>
                </Grid>
              )}
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardContent>
                <Grid container spacing={1}>
              
                  <Grid item xs={12} sm={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={6} sm={6}>
                        <TextField
                          error={errorObject.FundingSourceName}
                          helperText={errorObject.FundingSourceName}
                          required
                          id="FundingSourceName"
                          name="FundingSourceName"
                          label={DispensingLanguage[lan][menukey]['Funding Source']}
                          inputProps={{ maxLength: 50 }}
                          value={formData.FundingSourceName}
                          fullWidth
                          autoComplete="family-name"
                          onChange={(e) => handleChange(e)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={6}>
                          <TextField
                            error={errorObject.FundingSourceDesc}
                            helperText={errorObject.FundingSourceDesc}
                            id="FundingSourceDesc"
                            name="FundingSourceDesc"
                            type="text"
                            inputProps={{ maxLength: 6 }}
                            label={DispensingLanguage[lan][menukey]['Funding Source Desc']}
                            value={formData.FundingSourceDesc}
                            fullWidth
                            autoComplete="family-name"
                            onChange={(e) => handleChange(e)}
                          />
                        </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default FundingsourceFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
