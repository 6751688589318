import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Grid,
  Typography,
  TextField,
  FormControlLabel,
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  Radio,
  FormLabel,
  RadioGroup,
  MenuItem,
  Paper,
  CardHeader,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const FacilityId = localStorage.getItem('FacilityId');
const menukey = "return-to-the-supplier-from-provisional-receive";


const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};


const ProductsModal = ({ handleClose, open, addProduct,Ts, ReturnType, ...props }) => {


  
 const [ReturnTypeValue, setRadioValueHanddel] = useState(0);
 const [dataList, setDataList] = useState([]);

 
  let tblRef = null;
  

  let params = {
    action: 'getProducts',
    menukey: menukey,
    ContractNo: props.ContractNo,
    FacilityId: FacilityId,
    ReturnTypeValue: ReturnType,
    //Ts: Date.now(),
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
  };

/*   const { isLoading, error, isError, data, refetch } = useQuery(
    [params],
    api.getReturnProvisionalReceiveItemsList, {
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 0,
        enabled: false
    }

  ); */

  
  const { isLoading, error, isError, data, refetch } = useQuery(
    [params],
    api.getReturnProvisionalReceiveItemsList,
      {
        onSuccess: (data) => {
          setDataList(data);
        },
          refetchOnWindowFocus: false,
          refetchOnmount: false,
          refetchOnReconnect: false,
          retry: true,
          staleTime: 0,
          //enabled: false
    }
  );

  const [selectedProducts, setselectedProducts] = useState([]);

  const ProductColumns = [
    { title: "id", field: "ItemNo", visible: false },
    { title: "ItemNo", field: "ItemNo", visible: false },
    { title: "ItemSl", field: "ItemSl", visible: false },
    { title: DispensingLanguage[lan][menukey]['Sl#'],  formatter: "rownum", width:30, hozAlign: "center" , headerHozAlign:"center", headerSort:false},
    { title: DispensingLanguage[lan][menukey]['Sup Inv No'], field: "SupRef", width: 80, headerFilter:false },
    { title: DispensingLanguage[lan][menukey]['Sup Date'], field: "SupDate", width: 100, headerFilter:false },
    { title: DispensingLanguage[lan][menukey]['Received Date'], field: "pReceiveDate", width: 100, headerFilter:false },
    { title: DispensingLanguage[lan][menukey]['Item Code'], field: "ItemCode", width: 105, headerFilter:true },
    { title: DispensingLanguage[lan][menukey]['Item Name'], field: "ItemName", minWidth: 150, headerFilter:true  },
    { title: DispensingLanguage[lan][menukey]['Provisional Received Qty'], hozAlign: "right", headerHozAlign: "right",  field: "Qty", width: 100, formatter: "money",
    formatterParams:{ thousand:",",  precision:false },},
    { title: DispensingLanguage[lan][menukey]['Returnable Qty'], hozAlign: "right", headerHozAlign: "right",  field: "RemQty", width: 100, formatter: "money",
    formatterParams:{ thousand:",",  precision:false },},
    { title: DispensingLanguage[lan][menukey]['Lot No'], field: "BatchNo", width: 80},
    { title: DispensingLanguage[lan][menukey]['Mfg. Date'], field: "MfgDate", width: 90},
    { title: DispensingLanguage[lan][menukey]['Expiry Date'], field: "ExpDate", width: 90},
    // { title: DispensingLanguage[lan][menukey]['Product Price'], field: "UnitPrice", width: 80, hozAlign: "right", headerHozAlign:"right"  },
  ];

  let selected = [];


  
  let rows = [];

  let autoId = Date.now(); // 0;

    props.ManyTableData.forEach((rowData, i) => {

        
          let newRow = {};

          newRow.ApprovedQty = null;
          newRow.BatchNo = null;
          newRow.ContractName = null;
          newRow.ContractQty = null;
          newRow.DisApprovedQty = null;
          newRow.ExpDate = null;
          newRow.ItemCode = rowData.ItemCode;
          newRow.ItemName = null;
          newRow.ItemSl = null;
          newRow.MfgDate = null;
          newRow.Qty = null;
          newRow.RemQty = null;
          newRow.SupCode = null;
          newRow.SupDate = null;
          newRow.SupRef = null;
          newRow.UnitName = null;
          newRow.pReceiveDate = null;
          newRow.ItemSl = null;
        
          rows.push(newRow); 
  
         
    });


    let filteredProductList = [];
    if (dataList){
        const productListvalues = Object.values(dataList);
        filteredProductList = productListvalues.filter(o1 => !rows.some(o2 => o1.ItemCode === o2.ItemCode));
    }else{
        filteredProductList = [];
    }
  
       
    const ProdustDataItems = filteredProductList?.map((item, index) => { 
      return item;
    });


 /*  const ProdustDataItems = data?.map((item, index) => { 
    return item;
  }); */

  const [btnDisable, setbtnDisable] = useState(false);
  
  const SaveSelectedProducts = () => {
    if(tblRef.table.getSelectedData().length>0){
      setbtnDisable(true);
      setselectedProducts(tblRef.table.getSelectedData());
      setTimeout(() => setbtnDisable(false), 500);
    }
    
    //console.log(tblRef.table.getSelectedData());
    
  };

  const handlePopupClose = () => {
      handleClose('Products');
      setRadioValueHanddel(0); 
    
  };

  useEffect(() => { 
    if (tblRef == null) return;
    addProduct(selectedProducts);
    setRadioValueHanddel(0);   

}, [selectedProducts]); 


useEffect(() => {
  //console.log("ReturnTypeValue 1111111111111111---:",ReturnTypeValue);
  refetch();
}, [ReturnTypeValue, Ts]);

const changeRadioHanddel = (value) => {

 /*  if(!props.addProductForm){
    props.filterchangeRadioHanddel(value);
    setRadioValueHanddel(value);
  } */

  setRadioValueHanddel(value);

};

  return (
    <div>
      <BootstrapDialog
        //onClose={() => handleClose('Products')}
        onClose={handlePopupClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth={true}
        maxWidth="lg"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          //onClose={() => handleClose('Products')}
          onClose={handlePopupClose}
        >
        {DispensingLanguage[lan][menukey]['Item List']} 
        </BootstrapDialogTitle>
          
             {/*  <Grid item xs={12} sm={12}>
                <FormControl component="fieldset" className="mt-0 ml-4">
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="row-radio-buttons-group"
                    defaultValue="Usable_Stock"
                    
                  >
                    
                    <FormControlLabel
                      value="Usable_Stock"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["Disapproved Return"]}
                      onClick={() => changeRadioHanddel(0)}
                      //disabled={props.addProductForm || isLoadingUpdate2}
                      
                    />
                    <FormControlLabel
                      value="Unusable_Stock"
                      control={<Radio />}
                      label={DispensingLanguage[lan][menukey]["Direct Return"]}
                      onClick={() => changeRadioHanddel(1)}
                      // disabled={props.addProductForm || isLoadingUpdate2}
                    />
                    
                    
                  </RadioGroup>
                </FormControl>
              </Grid> */}
           
        <DialogContent dividers>
          <ReactTabulator
            ref={(r) => (tblRef = r)}
            options={{
              selectableRangeMode: 'click',
             }}
            selectable={true}
            columns={ProductColumns}
            data={ProdustDataItems}
            height="300px"
            layout={"fitColumns"}

          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={btnDisable}
            className="mr-2 float-left"
            variant="contained"
            color="primary"
            onClick={SaveSelectedProducts}
          >
           {DispensingLanguage[lan][menukey]['ADD']}
          </Button>
          <Button
            autoFocus
            className="float-left"
            variant="contained" 
            //onClick={() => handleClose('Products')}
            onClick={handlePopupClose}
          >
            {DispensingLanguage[lan][menukey]['CANCEL']}   
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default ProductsModal;
