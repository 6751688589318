import React, { useState ,useEffect } from "react";//, useEffect
import {
  Typography
} from "@material-ui/core";

import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query'
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import * as api from '../../../actions/api';
import DispenserFormData from './Form7BFormData.js';

import Constants from "../../../services/Constants";

const AddForm7B = (props) => {

  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "form-7b-view-global";
const FacilityId=localStorage.getItem('FacilityId');
  
const [selectedDate, setSelectedDate] = useState(new Date());
const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState(''); 
const [countTotalUpazila, setTotalUpazila] = useState(0); 

let currentYear = new Date().getFullYear();
let currentMonthId = new Date().getMonth(); // 0,1,2,3,4,5........

//select previous month.
if(currentMonthId == 0){
  currentYear = currentYear-1;
  currentMonthId = 12;
}

const [selectedYearName, setSelectedYearName] = useState(currentYear); 
// console.log('selectedYearName: ', selectedYearName);
const [selectedMonthId, setSelectedMonthId] = useState(currentMonthId); 
// console.log('selectedMonthId: ', selectedMonthId);

  const [formData, setFormData] = useState({
      TransactionNo: generatedInvoiceNo, 
      TransactionDate: selectedDate,
      TransactionTypeId:0,
      "FacilityId":FacilityId,
      FacilityCode:FacilityId,
      TotalUpazila:countTotalUpazila,
      YearName:selectedYearName,
      MonthId:selectedMonthId,
      Remarks:"",
      bUpdated:0,
      
  });

  const [ManyTableData, setManyTableData] = useState( []); 
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);

   
  
const handleRDateChange = (date, field) => {
  
  setFormData({ ...formData, [field]: date });
  setErrorObject({ ...errorObject, [field]: null });
};

 const handleDateChange = (date) => {
  setSelectedDate(date);
}; 
 

  const [errorObject, setErrorObject] = useState({});
   
  const { id } = useParams();
  const queryClient = useQueryClient();

  let params={"menukey": "form-7b", "FacilityId":FacilityId, "TransactionTypeId":0, TransactionDate: '', TableName: 'form7bmaster'};
  const {  data:invoiceNo, refetch } = useQuery([params], api.getInvoiceNo, 
    {onSuccess: (data) => {
      
      setGeneratedInvoiceNo(data);
      let fdata = { ...formData }; 
      fdata["TransactionNo"] = data; 
      setFormData(fdata); 

    }}
    );

    let params1={"menukey": "form-7b","action": "getTotalUpazilaCount", "FacilityId":FacilityId, "TransactionTypeId":0, TransactionDate: '', TableName: 'form7bmaster'};
    const {  data:TotalUpazila, refetch: getTotalUpazila } = useQuery([params1], api.getTotalUpazilaCount, 
      {onSuccess: (data) => {
        
        setTotalUpazila(data);
        let fdata = { ...formData }; 
        fdata["TotalUpazila"] = data; 
        setFormData(fdata); 
  
      }}
      );
 

  const { isLoading, mutate } = useMutation(api.saveForm7, {
    onSuccess: (data) => { 
      if (data.status == 200) {

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      let route = `edit/${data.TransactionId}`;
      props.history.push(route); 
      
      }else{
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })  
  const handleAdd = (NonOrderProductData) => {
  
      setManyTableData(NonOrderProductData);
      setUpdatedDetailGridData(NonOrderProductData);
      

  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('formData: ', e.target);

      let data = { ...formData };
      
      data[name] = value; 
      setFormData(data); 
    setErrorObject({ ...errorObject, [name]: null });
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };


  const invoiceCancel = (e)=>{

    // // console.log('handleCheck e: ', e);
    // const { name, checked } = e.target;
    // // console.log('name, checked: ', name, checked);
    // // console.log('invoiceCancel formData: ', formData);

    // swal({
    //   title: DispensingLanguage[lan][menukey]["Are you sure?"],
    //   text: DispensingLanguage[lan][menukey]["Do you really want to cancel?"]+" "+DispensingLanguage[lan][menukey]["You will not able to edit this invoice after cancel"],
    //   icon: "warning",
    //   buttons: [DispensingLanguage[lan][menukey]["No"], 
    //   DispensingLanguage[lan][menukey]["Yes"]],
    //   dangerMode: true,
    // }).then((willCancel) => {
    //   if (willCancel) {
    //     let fDtat={...formData,'ManyJsonSave':ManyTableData,"BCancel":1,"menukey":"receive"};
    //     mutate(fDtat);
    //   }else{
    //     setFormData({ ...formData, [name]: false });
    //   }
    // });

  };

  const handleReset = () => {
    setFormData({
      TransactionNo: generatedInvoiceNo, 
      TransactionDate: selectedDate,
      TransactionTypeId:0,
      "FacilityId":FacilityId,
      FacilityCode:FacilityId,
      TotalUpazila:countTotalUpazila,
      YearName:selectedYearName,
      MonthId:selectedMonthId,
      Remarks:"",
      bUpdated:0,
      ManyJsonSave: []
    });
    setManyTableData([]);
  };

  const validateForm = (formData) => {
    let validateFields = ["TransactionDate", "TransactionNo","YearName","MonthId"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !']
        isValid = false
      }
    })
    setErrorObject(errorData);
    return isValid
  }

  const handleSubmit = async (e) => {
    console.log("from submit ", 
    
    );
    if (validateForm(formData)) {
      let fDtat={...formData,'ManyJsonSave':ManyTableData,"menukey":"form-7b"};
      mutate(fDtat);
      
    }
  };
  const deleteReceiveInv=(data)=>{
    
    let newarray = ManyTableData.filter(element => element !== data); 
    setManyTableData(newarray);

  }

  const setUpdatedDetailsGridDataHandler = (rows) => { 
    if (validateForm(formData)) {
      let fDtat={...formData,'ManyJsonSave':rows,"menukey":"form-7b"}; 
      mutate(fDtat); 
      }

    setManyTableData(rows); 
  };


  
  useEffect(() => { 
    
     
      
    
  }, []); 




  return (
    <> 
      <DispenserFormData 
      errorObject={errorObject} 
      addProductForm={true} 
      formData={formData} 
      handleChange={handleChange} 
      handleCheck={handleCheck} 
      handleReset={handleReset} 
      invoiceCancel={invoiceCancel} 
      handleSubmit={handleSubmit} 
      ManyTableData={updatedDetailGridData}   
      handleRDateChange={handleRDateChange} 
      selectedDate={selectedDate} 
      deleteReceiveInv={deleteReceiveInv}
      setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
      bStockUpdate={0}
      handleAdd={handleAdd} 
      generatedInvoiceNo={generatedInvoiceNo}
      selectedYearName={selectedYearName}
      {...props} />
      
      
    </>

    
  );
};

export default AddForm7B;