import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useQuery, useMutation, useQueryClient } from "react-query";
import {
  Grid,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  Snackbar,
  Stack,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import * as api from "../../../actions/api";

const CountryEntryFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  open,
  ...props
}) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "country-entry";
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const backtolist = () => {
    let master_dirty_message_class = document
      .getElementById("master_dirty_message")
      .getAttribute("class");
    let idx = master_dirty_message_class.indexOf("dnone");
    if (idx === -1) {
      // start Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey][
            "You have unsaved changes which will be lost. Continue?"
          ]
        ),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          props.history.push("/country-entry");
        }
      });
      // End Alert
    } else {
      props.history.push("/country-entry");
    }
  };

  const onSaveUpdateBtnClick = () => {
    setUpdatedDetailsGridDataHandler();
  };

  const setUpdatedDetailsGridDataHandler = () => {
    let validForm = props.checkValidateForm();

    // console.log('isval: ', validForm);
    if (validForm) {
      if (addProductForm) {
        //when save
        mutate(validForm);
      } else {
        //when update
        mutateUpdate(validForm);
      }
    }
  };

  const { isLoading: isLoadingSave, mutate } = useMutation(
    api.saveCountryEntry,
    {
      onSuccess: (data) => {
        console.log("data: ", data);

        if (data.status == 200) {
          // api.getAllDropdown("ABC_List").then((response) => {
          //   localStorage.setItem(
          //     "ABC_List",
          //     JSON.stringify(response.datalist.ABC_List)
          //   );
          // });

          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
          queryClient.getQueriesData("CountryEntry"); //api page name
          props.history.push("/country-entry"); //
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
          queryClient.getQueriesData("CountryEntry"); //api page name
          props.history.push("/country-entry"); //
        }
      },
    }
  );

  const { isLoading: isLoadingUpdate, mutate: mutateUpdate } = useMutation(
    api.updateCountryEntry,
    {
      onSuccess: (data) => {
        if (data.status == 200) {
          // api.getAllDropdown("ABC_List").then((response) => {
          //   localStorage.setItem(
          //     "ABC_List",
          //     JSON.stringify(response.datalist.ABC_List)
          //   );
          // });

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });

          queryClient.getQueriesData("CountryEntry"); // include the page name
          props.history.push("/country-entry"); //page url
          api.dirtyModeDisable();
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }
      },
    }
  );

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Country Entry - Add/Edit"]}
              </div>
            </div>
            <div className="float-right sw_btn_control">
              {addProductForm ? (
                <Grid item xs={12} className="text-center">
                  <div className="float-right sw_btn_control">
                    <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => handleReset()}
                    >
                      {DispensingLanguage[lan][menukey]["Reset"]}
                    </Button>
                    <Button
                      disabled={props.bStockUpdate || isLoadingSave}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                      // onClick={() => handleSubmit()}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={() => props.history.push("/menu-entry")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Cancel"]}
                    </Button>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} className="mt-2 text-center">
                  <Button
                    disabled={props.bStockUpdate || isLoadingUpdate}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClick}
                    // onClick={() => handleUpdate()}
                  >
                    {DispensingLanguage[lan][menukey]["Update"]}
                  </Button>

                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    //onClick={() => props.history.push("/menu-entry")}
                    onClick={() => backtolist()}
                  >
                    {DispensingLanguage[lan][menukey]["Cancel"]}
                  </Button>
                </Grid>
              )}
            </div>

            <div className="mr-4 float-right mt-15">
              <span
                id="master_dirty_message"
                className={"masterredtextcolor dnone"}
              >
                {" "}
                {DispensingLanguage[lan][menukey]["You have unsaved changes"]}
              </span>
            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      error={errorObject.CountryCode}
                      helperText={errorObject.CountryCode}
                      required
                      disabled={props.permissionType}
                      id="CountryCode"
                      name="CountryCode"
                      label={DispensingLanguage[lan][menukey]["Country Code"]}
                      value={formData.CountryCode}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      error={errorObject.ISO3}
                      helperText={errorObject.ISO3}
                      required
                      disabled={props.permissionType}
                      id="ISO3"
                      name="ISO3"
                      label={DispensingLanguage[lan][menukey]["ISO3"]}
                      value={formData.ISO3}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      error={errorObject.CountryName}
                      helperText={errorObject.CountryName}
                      required
                      disabled={props.permissionType}
                      id="CountryName"
                      name="CountryName"
                      label={DispensingLanguage[lan][menukey]["Country Name"]}
                      value={formData.CountryName}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      error={errorObject.CenterLat}
                      helperText={errorObject.CenterLat}
                      required
                      disabled={props.permissionType}
                      id="CenterLat"
                      name="CenterLat"
                      label={
                        DispensingLanguage[lan][menukey]["Center Latitude"]
                      }
                      value={formData.CenterLat}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <TextField
                      error={errorObject.CenterLong}
                      helperText={errorObject.CenterLong}
                      required
                      disabled={props.permissionType}
                      id="CenterLong"
                      name="CenterLong"
                      label={
                        DispensingLanguage[lan][menukey]["Center Longitude"]
                      }
                      value={formData.CenterLong}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                
                  <Grid item xs={6} sm={3}>
                    <TextField
                      error={errorObject.ZoomLevel}
                      helperText={errorObject.ZoomLevel}
                      required
                      disabled={props.permissionType}
                      id="ZoomLevel"
                      name="ZoomLevel"
                      label={DispensingLanguage[lan][menukey]["Zoom Level"]}
                      value={formData.ZoomLevel}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                    />
                  </Grid>
                </Grid>

                {/* New Row */}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default CountryEntryFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
