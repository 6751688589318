import React, { forwardRef, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import { Typography, Grid, Checkbox, TextField, Card,CardContent,FormControl, InputLabel, Select,  MenuItem, } from "@material-ui/core";

import { ViewList} from "@material-ui/icons";

import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
import ProductSelectInPrescriptionModal from "../../../services/AuditLogModal";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import MomentUtils from "@date-io/moment";
import moment from "moment";



let tblMenuRef = null;


const AuditlogProductCatalog = (props) => {
  //get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const { t, i18n } = useTranslation();
const menukey = "audit-log-product-catalog";
const UserInfo = sessionStorage.getItem("User_info")? JSON.parse(sessionStorage.getItem("User_info")): 0;  
const UserName = UserInfo==0?'': UserInfo[0].id;
const Users_List = JSON.parse(localStorage.getItem("UserList"));


let All_UserList_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
const UserList = [All_UserList_label].concat(Users_List); 


const CurrentFacilitySetting=JSON.parse(localStorage.getItem('CurrentFacilitySetting'));
const Facility_List = JSON.parse(localStorage.getItem("WarehouseAndFacilityList"));

let All_Facility_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
const WarehouseAndFacilityList = [All_Facility_label].concat(Facility_List); 


  const classes = useStyles();
  const tableRef = useRef();
  const { useState } = React;
  const [dataItems, setDataItems] = useState([]);
  const [dataItems1, setDataItems1] = useState([]);

  const queryClient = useQueryClient();
  const [selectedRoleData, setSelectedRole] = useState(0);
  const [bFirst, setBFirst] = useState(true); 

  const [isLoadingll, setLoading] = useState(true);

  const [selectedrow, setSelectedrow] = useState(false);

  const [currRequestType, setcurrRequestType] = useState(0);
  const [currDatabaseTables, setcurrDatabaseTables] = useState(0);
  const [currUserListId, setcurrUserListId] = useState(0);
  const [currFacilityCode, setcurrFacilityCode] = useState((CurrentFacilitySetting.length === 0)?0:CurrentFacilitySetting[0].FacilityCode);

  const [DbName, setDbName] = useState();
  const [TableName, setTableName] = useState([{id:"0", name: t(DispensingLanguage[lan][menukey]["All"])}]);

  const [currRowId, setCurrRowId] = useState(0);
  const [openSqlLog, setopenSqlLog] = useState(false);


  const [LogDataArray, setLogDataArray] = useState([]);



  const handleClose = (modalname) => {
    switch (modalname) {
    
      case "ProductBatch":
        setopenSqlLog(false);
        break;
      case "ProductBatchOpen":
        setopenSqlLog(true);
        break;

      default:
        break;
    }
  };


  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(7, "days").format("YYYY-MM-DD")
  );

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date);
    setLoading(true);
    setSelectedRole(0);
  };

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date);
    setLoading(true);
    setSelectedRole(0);
  };

  let params0 = {
    menukey: menukey,
    action: "getDataList",
    logStartDate:selectedStartDate,
    logEndDate:selectedEndDate,
    RequestType: currRequestType,
    cboDatabaseTables: currDatabaseTables,
    UserList: currUserListId,
    FacilityCode: currFacilityCode,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    
  };

  const { isLoading, error, isError, data, refetch } = useQuery(
    [params0],
    api.getAuditLogDataCatalog,
    {
      onSuccess: (data) => {
        setDataItems(data);
        
        //setSelectedrow(true);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  let params = {
    menukey: menukey,
    action: "getAuditLogList",
    log_id: selectedRoleData,
  };

  const {
    isLoading1,
    error1,
    isError1,
    data: logData,
    refetch: MenuRefetch,
  } = useQuery([params], api.getLogSingleRowCatalog, {
    onSuccess: (logData) => {
      setDataItems1(logData);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });


  let params2 = {
    menukey: menukey,
    action: "getDataBaseInfo",
  };

  const {
    isLoading2,
    error2,
    isError2,
    data: logData2,
    refetch: MenuRefetch2,
  } = useQuery([params2], api.gDataBaseInfoCatalog, {
    onSuccess: (logData2) => {
      setDbName(logData2.DBName);
     //console.log("DB Name", logData2.DBName);
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });


  let params3 = {
    menukey: menukey,
    action: "getTableInfo",
    DataBaseName:DbName
  };

  const {
    isLoading3,
    error3,
    isError3,
    data: logData3,
    refetch: MenuRefetch3,
  } = useQuery([params3], api.gTableInfoForCatalog, {
    onSuccess: (logData3) => {

      let All_Facility_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
      const TableList = [All_Facility_label].concat(logData3); 
      setcurrDatabaseTables(0);
      setTableName(TableList);
  
    },
    refetchOnWindowFocus: false,
    refetchOnmount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,//1000 * 60 * 60 * 24,
  });
  

  const rowClick = (e: any, row: any) => {
    let rowData = row._row.data;
    console.log(rowData.id);
    setSelectedRole(rowData.id);
    //MenuRefetch();
  };

  
  const handleRequestTypeChange = (event) => { 
    setcurrRequestType(event.target.value);
      setSelectedRole(0);
     };

/*   const handleDatabaseTablesChange = (event) => { 
      setcurrDatabaseTables(event.target.value);
      setSelectedRole(0);

   }; */
   
/*   const handleUserListIdChange = (event) => { 
    setcurrUserListId(event.target.value);
    setSelectedRole(0);
  }; */

  const handleFacilityCodeChange = (event) => { 
    setcurrFacilityCode(event.target.value);
    setSelectedRole(0);
  };

  const handleFacilityChange = (event, newValue) => {
    let rowId = '';
    if(newValue == null){
      rowId = '';
    }else{
      rowId = newValue.id;
    }

    setcurrFacilityCode(rowId);
    setSelectedRole(0);
 };


  const handleUserListIdChange = (event, newValue) => {
    let rowId = '';
    if(newValue == null){
      rowId = '';
    }else{
      rowId = newValue.id;
    }

    console.log("ssssssss",rowId);

    setcurrUserListId(rowId);
    setSelectedRole(0);
 };

 const handleDatabaseTablesChange = (event, newValue) => {
  let rowId = '';
  if(newValue == null){
    rowId = '';
  }else{
    rowId = newValue.id;
  }

  setcurrDatabaseTables(rowId);
  setSelectedRole(0);
};



  


  useEffect(() => {
    //handleClose("ProductBatchOpen");
  }, [LogDataArray]);


  useEffect(() => {
    console.log("useEffect calling for refresh");
    if (selectedRoleData > 0) {
      MenuRefetch();
    }
  }, [selectedRoleData]);


  



  const columns = [
    { title: "Id", field: "id", width: 50, visible: false },
    {
      title: DispensingLanguage[lan][menukey]["SL."],
      formatter: "rownum",
      width: 40,
      hozAlign: "center",
      headerSort: false,
      headerHozAlign: "center",
    },
    { title: DispensingLanguage[lan][menukey]["Date"], field: "LogDate",headerFilter: true, },
   /* { title: DispensingLanguage[lan][menukey]["Facility"], field: "FacilityName",headerFilter: true, }, */
    { title: DispensingLanguage[lan][menukey]["User"], field: "userName",headerFilter: true, },
    { title: DispensingLanguage[lan][menukey]["Remote IP"], field: "RemoteIP",headerFilter: true, width: 110,},
    { title: DispensingLanguage[lan][menukey]["Query Type"], field: "QueryType",headerFilter: true, width: 120,},
    { title: DispensingLanguage[lan][menukey]["Table Name"], field: "TableName",headerFilter: true,},
    {
      title: "",
      headerSort: false,
      field: "custom",
      hozAlign: "center",
      width: 8,
      formatter: reactFormatter(<ShowSqlLogPopup />),
    },

    
  ];

  const columns1 = [
    { title: "Id", field: "id", width: 50, visible: false },
    {
        title: DispensingLanguage[lan][menukey]["SL."],
        formatter: "rownum",
        width: 30,
        hozAlign: "center",
        headerSort: false,
        headerHozAlign: "center",
    },
    { title: DispensingLanguage[lan][menukey]["Field Name"], field: "FieldName", width: 110, headerSort: false, },
    { title: DispensingLanguage[lan][menukey]["Old Value"], field: "OldValue", headerSort: false, },
    { title: DispensingLanguage[lan][menukey]["New Value"], field: "NewValue", headerSort: false, },

  ];

  
  useEffect(() => {
    // if(selectedrow == true){
    //   tblMenuRef.table.selectRow(1);
    // }
  }, [selectedrow]);




  function ShowSqlLogPopup(props: any) {
    const rowData = props.cell._cell.row.data;

      return (
        <>
          <ViewList
            onClick={() => {
      
              setCurrRowId(rowData.id);
              setLogDataArray([rowData.SqlText,rowData.SqlParams]);
              //console.log("gggggggggggggg",rowData);
              handleClose("ProductBatchOpen");
            
            }}
          />
        </>
      );
    
  }
  


  // useEffect(() => {
  //   if (tblMenuRef == null) return; 
  //   if(bFirst){
  //     setDataItems(data);
  //   }
    
  //  }, [dataItems]); 


  return (
    <>
      <div className={classes.dispenserPageTitle}>
        <div className="d-flex justify-dispenser mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title"> {DispensingLanguage[lan][menukey]["Audit Log - Product Catalog"]}</div>
             
               
                </div>
          </Grid>

        </div>

        <ProductSelectInPrescriptionModal
                handleClose={handleClose}
                open={openSqlLog}
                LogDataArray={LogDataArray}
                {...props}
              />

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>

            
            <Grid item xs={2} sm={2}>
              

              

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["Log Start Date"]}
                      autoOk={true}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>

               
              </Grid>

              <Grid item xs={2} sm={2}>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["Log End Date"]}
                      autoOk={true}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>

              </Grid>

                <Grid item xs={2} sm={3}>
                  <FormControl className={classes.fullWidth}>
                        <InputLabel id="demo-simple-select-helper-label">
                          {DispensingLanguage[lan][menukey]["Request Type"]}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-helper-label"
                          id="RequestType"
                          name="RequestType"   
                          value={currRequestType} 
                          onChange={handleRequestTypeChange}            
                          fullWidth
                        >
                        <MenuItem value="0">{DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                        <MenuItem value="INSERT">INSERT</MenuItem>
                        <MenuItem value="UPDATE">UPDATE</MenuItem>
                        <MenuItem value="DELETE">DELETE</MenuItem>
                
                        </Select>
                      </FormControl>
                </Grid>


               <Grid item xs={2} sm={3}>
                <FormControl className={classes.fullWidth}>
                 {/*  <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["Table Name"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="DatabaseTables"
                    name="DatabaseTables"
                    value={currDatabaseTables}
                    onChange={handleDatabaseTablesChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>

                   {TableName.map((item, index) => {
                              return (
                                <MenuItem value={item.id}>{item.name}</MenuItem>
                              );
                      })}

          
                  </Select> */}

                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="DatabaseTables"
                      disableClearable
                      options={TableName}
                      //disabled={UserName=="admin"?false:true}
                      onChange={(event, newValue) => handleDatabaseTablesChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={TableName[TableName.findIndex(Table_Name => Table_Name.id == currDatabaseTables)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["Table Name"]} 
                        variant="standard"
                       
                      />
                      )}
                    />
                </FormControl>
              </Grid>



              <Grid item xs={2} sm={2} className="display_none">
                <FormControl className={classes.fullWidth}>
                  
                  {/* <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]["User List"]}
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="UserListId"
                    name="UserListId"
                    value={currUserListId}
                    onChange={handleUserListIdChange}
                    fullWidth
                  >
                   <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>

                   {UserList.map((item, index) => {
                              return (
                                <MenuItem value={item.name}>{item.name}</MenuItem>
                              );
                      })}

          
                  </Select> */}

                    <Autocomplete
                      autoHighlight
                      className="sw_chosen_filter"
                      id="UserListId"
                      disableClearable
                      options={UserList}
                      //disabled={UserName=="admin"?false:true}
                      onChange={(event, newValue) => handleUserListIdChange(event, newValue)}
                      getOptionLabel={(option) => option.name}
                      defaultValue={UserList[UserList.findIndex(User_List => User_List.id == currUserListId)]}
                      renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                      )}
                      
                      renderInput={(params) => (
                      <TextField
                        {...params}
                        label={DispensingLanguage[lan][menukey]["User List"]} 
                        variant="standard"
                       
                      />
                      )}
                    />

                </FormControl>
              </Grid>



              
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}




        
            <Grid item xs={12} sm={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={8}>

                <div className="sw_relative">
                  <div className="uniqueName">
                    <ReactTabulator 
                    ref={(r) => (tblMenuRef = r)}
                      options={{
                        selectable:1,
                        selectableRangeMode: 'click',
                       
                       }}
                      columns={columns}
                      data={dataItems}
                      layout={"fitColumns"}
                      height={"500px"}
                      // tooltips={true}
                      // dataTree={true}
                    
                      rowClick={(e, row) => rowClick(e, row)}
                      
                    />
                   </div>
                </div>
              </Grid>

                <Grid item xs={12} sm={4}>
                 <div className="sw_relative">
                  <div className="uniqueName">
                    <ReactTabulator
                      columns={columns1}
                      data={dataItems1}
                      layout={"fitColumns"}
                      height={"500px"}
                    />
                  </div>
                 </div>
                </Grid>
              </Grid>
            </Grid>
         
      </div>
    </>
  );
};

export default AuditlogProductCatalog;

const useStyles = makeStyles({
  dispenserPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
