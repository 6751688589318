import React, { useState, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import swal from "sweetalert";
import * as api from "../../../actions/api";
import DispenserFormData from "./Form7FormData.js";
// import history from './../../../history';
import { useTranslation } from "react-i18next";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

const EditForm7 = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "form7";
  const FacilityId = localStorage.getItem("FacilityId");

  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    TransactionNo: "",
    TransactionDate: "",
    TransactionTypeId: 0,
    FacilityId: FacilityId,
    FacilityCode: FacilityId,
    TotalUpazila: 0,
    YearName: "",
    MonthId: "",
    Remarks: "",
    bUpdated: 0,
  });
  const [selectedDate, setSelectedDate] = useState(new Date());
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const [ManyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState("0");
  
  const [bUnpostDisable, setBUnpostDisable] = useState("0");
  
  const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);
  const [TransactionId, setTransactionId] = useState("0");
  const [deletedDataSet, setDeletedDataSet] = useState([]);

  const [StockPost, setStockPost] = useState(false);

  const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState("");
  const [selectedYearName, setSelectedYearName] = useState("");
  const [bFirst, setBFirst] = useState(true);

  const [isPreLoading, setPreLoading] = useState(false);

  const handleRDateChange = (date, field) => {
    setFormData({ ...formData, [field]: date });
    setErrorObject({ ...errorObject, [field]: null });
    api.dirtyModeEnable();
  };

  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  const { data, refetch } = useQuery(
    ["form7", id],
    () =>
      api.getForm7Single(id).then((res) => {

              
      //This is for user has VIEW/EDIT permission in this page start
      if (props.permissionType === 1) {
        //when permissionType then it is only VIEW permission otherwise normal permission
        res.data.bStockUpdated = 1;
      }
      //This is for user has VIEW/EDIT permission in this page end

      
        setTransactionId(id);
        setFormData(res.data);
        setbStockUpdate(res.data.bStockUpdated);
        setBUnpostDisable(res.data.bUnpostDisable);
        setManyTableData(res.data.ManyJsonSave); //res.data.ManyJsonSave
        setUpdatedDetailGridData(res.data.ManyJsonSave);
        setGeneratedInvoiceNo(res.data.TransactionNo);
        setSelectedYearName(res.data.YearName);
        setDeletedDataSet([]);
        setBFirst(true);

        api.dirtyModeDisable();
        return res.data;
      }),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(id),
    }
  );

  const { isLoading, mutate: mutateUpdate } = useMutation(api.Form7Update, {
    onSuccess: (data) => {
      console.log("mutateUpdate data: ", data);
      if (data.status == 200) {
        setbStockUpdate(data.bStockUpdated);
        if (data.bStockUpdated == 1) {
          let fDtat = {
            ...formData,
            ManyJsonSave: ManyTableData,
            bStockUpdated: 1,
            deletedDataSet: deletedDataSet,
          };
          mutateGenerateForm7(fDtat);
          // mutatePost(TransactionId);
        } else {
          refetch();

          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
          setPreLoading(false);
          //swal("Success!", "", "success");
        }
      } else {
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
        setPreLoading(false);
      }
    },
  });

  // const { isLoading1, mutate:mutatePost } = useMutation(api.postIssueVoucherIndent, {
  //   onSuccess: (data) => {
  //     if (data.status == 200) {

  //       setbStockUpdate(1);
  //       setDeletedDataSet([]);

  //       if(data.data.success==0){

  //         props.openNoticeModal({
  //           isOpen: true,
  //           msg: data.data.message,
  //           msgtype: data.data.success,
  //         });

  //        }else{

  //         props.openNoticeModal({
  //           isOpen: true,
  //           msg: data.data.message,
  //           msgtype: data.data.success,
  //         });

  //        }

  //     }else{

  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.data.message,
  //         msgtype: data.data.success,
  //       });
  //     }
  //   }
  // })

  const { isLoadingF7, mutate: mutateGenerateForm7 } = useMutation(
    api.generateForm7Items,
    {
      onSuccess: (data) => {
        console.log("mutateGenerateForm7 data: ", data);

        if (data.status == 200) {
          if (data.data.success == 1) {
            refetch();

            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });
          } else {
            props.openNoticeModal({
              isOpen: true,
              msg: data.data.message,
              msgtype: data.data.success,
            });
          }
        } else {
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
        }

        setPreLoading(false);
      },
    }
  );

  const handleAdd = (NonOrderProductData) => {
    setManyTableData(NonOrderProductData);
    setUpdatedDetailGridData(NonOrderProductData);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    data[name] = value;
    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };

    //====== Start Choosen Dropdown===========
    const handleChangeChoosenProps = (name, value) => {
      let data = { ...formData };
      data[name] = value; 
      
      setFormData(data); 
      setErrorObject({ ...errorObject, [name]: "" });
      api.dirtyModeEnable();
    }
    //====== End Choosen Dropdown===========


  const handleCheck = (e) => {
    // console.log('handleCheck e: ', e);
    const { name, checked } = e.target;
    // console.log('name, checked: ', name, checked);

    setFormData({ ...formData, [name]: checked });
  };

  const invoiceCancel = (e) => {
    // console.log('handleCheck e: ', e);
    const { name, checked } = e.target;
    // console.log('name, checked: ', name, checked);
    // console.log('invoiceCancel formData: ', formData);

    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text:
        t(DispensingLanguage[lan][menukey]["Do you really want to cancel?"]) +
        " " +
        t(
          DispensingLanguage[lan][menukey][
            "You will not able to edit this invoice after cancel"
          ]
        ),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willCancel) => {
      if (willCancel) {
        setPreLoading(true);
        let fDtat = {
          ...formData,
          ManyJsonSave: ManyTableData,
          BCancel: 1,
          TableName: "form7master",
          deletedDataSet: deletedDataSet,
        };
        mutateUpdate(fDtat);
      } else {
        setFormData({ ...formData, [name]: false });
      }
    });
  };

  const handleReset = () => {
    setFormData({
      TransactionNo: "",
      TransactionDate: "",
      TransactionTypeId: 0,
      FacilityId: FacilityId,
      FacilityCode: FacilityId,
      TotalUpazila: 0,
      YearName: "",
      MonthId: "",
      Remarks: "",
      bUpdated: 0,
    });
  };

  const validateForm = (formData) => {
    let validateFields = [
      "TransactionDate",
      "TransactionNo",
      "YearName",
      "MonthId",
    ];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  const deleteReceiveInv = (data) => {
    console.log(ManyTableData);
    let newarray = ManyTableData.filter((element) => element !== data);

    setManyTableData(newarray);
    setUpdatedDetailGridData(newarray);
    if (data.TransactionItemId != null) {
      let newDeletedDataSet = [data.TransactionItemId];
      setDeletedDataSet(newDeletedDataSet.concat(deletedDataSet));
    }
  };

  const handleUpdate = async (e) => {
    if (validateForm(formData)) {
      setPreLoading(true);
      let fDtat = {
        ...formData,
        ManyJsonSave: ManyTableData,
        deletedDataSet: deletedDataSet,
      };
      mutateUpdate(fDtat);
    }
  };

  const generateForm7Items = async (e) => {
    if (validateForm(formData)) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey][
            "Do you really want to fill the Form7 items?"
          ]
        ),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true, */
      }).then((willAction) => {
        if (willAction) {
          setPreLoading(true);
          let fDtat = {
            ...formData,
            ManyJsonSave: ManyTableData,
            bStockUpdated: 0,
            deletedDataSet: deletedDataSet,
          };
          mutateGenerateForm7(fDtat);
        } else {
          // setbStockUpdate(0);
          // let route = `${TransactionId}`;
          // props.history.push(route);
        }
      });
      // End Post Alert
    }
  };

  const handlePost = async (e) => {
    if (validateForm(formData)) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey]["Do you really want to post?"]
        ),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true, */
      }).then((willAction) => {
        if (willAction) {
          setPreLoading(true);
          let fDtat = {
            ...formData,
            ManyJsonSave: ManyTableData,
            bStockUpdated: 1,
            deletedDataSet: deletedDataSet,
          };
          mutateUpdate(fDtat);
        } else {
          setbStockUpdate(0);
          let route = `${TransactionId}`;
          props.history.push(route);
        }
      });
      // End Post Alert
    }
  };

  const handleUnPost = async (e) => {
    if (validateForm(formData)) {
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(
          DispensingLanguage[lan][menukey]["Do you really want to unpost?"]
        ),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true, */
      }).then((willAction) => {
        if (willAction) {
          setPreLoading(true);
          let fDtat = {
            ...formData,
            ManyJsonSave: ManyTableData,
            bStockUpdated: 2,
            deletedDataSet: deletedDataSet,
          };
          mutateUpdate(fDtat);
        } else {
          //setbStockUpdate(0);
          let route = `${TransactionId}`;
          props.history.push(route);
        }
      });
      // End Post Alert
    }
  };

  const setUpdatedDetailsGridDataHandler = (rows) => {
    if (validateForm(formData)) {
      setPreLoading(true);
      let fDtat = {
        ...formData,
        ManyJsonSave: rows,
        deletedDataSet: deletedDataSet,
      };
      mutateUpdate(fDtat);
    }

    // const updateManyTableData = ManyTableData.map((row) => {

    //   if (row.SKU == rows.SKU) {
    //     row.Quantity = rows.Quantity;
    //     row.BatchNo = rows.BatchNo;
    //     row.ExpiryDate = rows.ExpiryDate;
    //   }
    //   return row;
    // });
    // console.log('updateManyTableData: ', 'sdgfjsgfsgfjshgfdhsgfhdsfg');
    setManyTableData(rows);
  };

  const deleteForm7Items = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Do you really want to delete the selected Form7 items?"
        ]
      ),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willDelete) => {
      if (willDelete) {
        setPreLoading(true);
        deleteForm7ItemsMutate(formData.TransactionNo);
        // console.log('data: ', formData);
      }
    });
  };

  const { mutate: deleteForm7ItemsMutate } = useMutation(api.deleteForm7Items, {
    onSuccess: (data) => {
      if (data.status == 200) {
        setPreLoading(false);
        refetch();

        queryClient.getQueriesData("form7");

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
          duration: 10000,
        });
      } else {
        setPreLoading(false);
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    },
  });

  // useEffect(() => {

  // }, []);

  return (
    <div className="sw_relative">
      {isPreLoading && <LoadingSpinnerOpaque />}
      <DispenserFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleCheck={handleCheck}
        handleReset={handleReset}
        invoiceCancel={invoiceCancel}
        handleUpdate={handleUpdate}
        ManyTableData={updatedDetailGridData}
        handleRDateChange={handleRDateChange}
        selectedDate={selectedDate}
        handleAdd={handleAdd}
        bStockUpdate={bStockUpdate}
        bUnpostDisable={bUnpostDisable}
        setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
        handlePost={handlePost}
        handleUnPost={handleUnPost}
        deleteReceiveInv={deleteReceiveInv}
        generatedInvoiceNo={generatedInvoiceNo}
        selectedYearName={selectedYearName}
        bFirst={bFirst}
        generateForm7Items={generateForm7Items}
        isPreLoading={isPreLoading}
        deleteForm7Items={deleteForm7Items}
        {...props}
      />
    </div>
  );
};

export default EditForm7;
