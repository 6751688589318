import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
//import jsPDF from "jspdf";

//Services
import * as Service from "../../../services/Service.js";
import Constants from "../../../services/Constants";
import swal from "sweetalert";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import {
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TablePagination,
  Typography,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  TextField,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import ExportButtons from "../../../components/ExportButtons";
import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";

import * as api from "../../../actions/api";
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import { DeleteOutline, ViewList, preview, Edit } from "@material-ui/icons";
import { cellFocusEditor } from "../../../services/Common";
import moment from "moment";

const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
const fileExtension = ".xlsx";

const IssueDetailsByItem = (props) => {

  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const { t, i18n } = useTranslation();
  const FacilityId = localStorage.getItem("FacilityId");
  const FacilityName = localStorage.getItem("FacilityName");
  const menukey = "upazila-list";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;
  const whstatusmapwarahouse = JSON.parse(localStorage.getItem("whstatusmapwarahouse"));
  whstatusmapwarahouse.unshift({'id':0,'name':t(DispensingLanguage[lan][menukey]["All"])});

  const classes = useStyles();
  let tblRef = null;
  const tableRef = useRef();
  const { useState } = React;


  const [firstLoad, setFirstLoad] = useState(true);
  const currentDate = new Date();
  const [ManyJsonSave, setInputChange] = useState({});
  //const [radioValue, setRadioValue] = useState(2);
  const [productGroupData, selectProductGroupData] = useState();
  const [dataItems, setDataItems] = useState([]);
  const [currGroupCode, setcurrGroupCode] = useState(0);
  const [currSDPCode, setcurrSDPCode] = useState(0);
  const UseFor = localStorage.getItem("UseFor");
  const [currSupplyFrom, setcurrSupplyFrom] = useState(UseFor=='WIMS'?FacilityId: 0);

  
  const [chosenValues, setChosenValues] = useState({
    "ItemCode": {"id": 0,"name": t(DispensingLanguage[lan][menukey]["All"])}
  });


  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(30, "days").format("YYYY-MM-DD")
  );

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const handleStartDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setFirstLoad(true);
    setLoading(true);
    setSelectedEndDate(date);
  };
  const handleChangeMonth = (event) => {
    setFirstLoad(true);
    setLoading(false);
    setcurrSupplyFrom(event.target.value);
  };

  const handleChangeChoosen = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues};
    chosenValuesData[name] = valueobj;

    setFirstLoad(true);
    setLoading(false);
    setChosenValues(chosenValuesData);
    setcurrSupplyFrom(value); 
  
 
  
  };
 
  


  const queryClient = useQueryClient();

  let params = {
    menukey: menukey,
    FacilityId: FacilityId,
    action: "getUazilaList",
    SupplyFrom: currSupplyFrom,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
   
  };

  const [isLoading, setLoading] = useState(false);

  const { error, isError, data, refetch:stockData } = useQuery(
    [params],
    api.UpazilaList,
    {
      onSuccess: (data) => {
        if(firstLoad){
          /* localStorage.setItem(
            "StockData",
            JSON.stringify(data)
          );
           */
          setDataItems(data);
          setLoading(false);
          setFirstLoad(false);
        }
      },
        refetchOnWindowFocus: false,
        refetchOnmount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 0
  }
  ); 


  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let formatedDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";


    window.open(
      finalUrl +
        "?action=getUpazilaListExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&SupplyFrom=" +
        currSupplyFrom +
        "&FacilityId=" +
        FacilityId +
        "&FacilityName=" +
        FacilityName + 
        "&UseFor=" +
        UseFor + 
        "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */


  const columns = [
    {
      title: "Id",
      field: "id",
      visible: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 5,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    
   
    {
      title: DispensingLanguage[lan][menukey]["Facility Code"],
      field: "FacilityCode",
      headerFilter: true,
      width: 130,
    }, 
    {
      title: DispensingLanguage[lan][menukey]["Facility"],
      field: "FacilityName",
      headerFilter: true,
     
    },
    {
      title: DispensingLanguage[lan][menukey]["Geo Code"],
      field: "GeoCode",
      headerFilter: true,
      width: 100,

    },
    
    {
      title: DispensingLanguage[lan][menukey]["District"],
      field: "DistrictName",
      headerFilter: true,
      width: 180,
      
    },   
    {
      title: DispensingLanguage[lan][menukey]["Warehouse Code"],
      field: "SupplyFrom",
      headerFilter: true,
      width: 160,
      
    },   
    {
      title: DispensingLanguage[lan][menukey]["Warehouse Name"],
      field: "SupplyFromName",
      headerFilter: true,
      width: 200,
      
    },   
    {
      title: DispensingLanguage[lan][menukey]["Phone No"],
      field: "PhoneNo",
      headerFilter: true,
      width: 120,
      
    },   
    
  ];




  const checkLogin = () => {
    let token = Constants.token;

    if (!token) {
      swal("Oops!", `token expired. Please login again`, "error");
      props.history.push("/login");
    }
  };


  // React.useEffect(() => {
  //   setcurrItemCode(0); 
  //   cItemList();
  // }, [currGroupCode]);


 React.useEffect(() => {
   if (tblRef == null) return;
   setLoading(true);
   stockData();
  }, [currSupplyFrom]);

  return (
    <>
      <div className={classes.productPageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Upazila List"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
             

            <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                      {/* <InputLabel id="">
                        {DispensingLanguage[lan][menukey]["WareHouse List"]} 
                      </InputLabel>
                      <Select
                       // error={props.errorObject.ItemCode}
                        labelId="SupplyFrom"
                        id="SupplyFrom"
                        name="SupplyFrom"
                        value={currSupplyFrom}
                        fullWidth
                        onChange={handleChangeMonth}
                       
                      >
                       <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                        {whstatusmapwarahouse.map((item, index) => {
                          return (
                           <MenuItem value={item.id}> {item.name}</MenuItem>
                          );
                        })}
                      </Select> */}

                        <Autocomplete
                          autoHighlight
                          disableClearable
                          id="ItemCode"
                          options={whstatusmapwarahouse}
                          onChange={(event, valueobj) => handleChangeChoosen('ItemCode', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValues['ItemCode']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            //value={props.formData.ItemCode}
                            label={DispensingLanguage[lan][menukey]["WareHouse List"]}
                            variant="standard"
                            //error={props.errorObject.ItemCode}
                            //helperText={props.errorObject.ItemCode}
                            id="ItemCode"
                            name="ItemCode"
                            fullWidth
                          />
                          )}
                        />

                    </FormControl>
                  </Grid>

                

                  


              <Grid item xs={10} sm={10}>
                <div className="float-right sw_btn_control">
                <ExportButtons PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}  {...props}></ExportButtons>
                  {/* <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button> */}
                </div>
              </Grid>

            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}

          <div className="uniqueName">
            <ReactTabulator
              ref={(r) => (tblRef = r)}
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="400px"
              // groupBy="GroupName"
              // options={{
              //     groupBy: ["ReportStatus", "FGroupName"],
              //     columnCalcs: "both",
              //   }}
             // rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IssueDetailsByItem;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
