import React, { useState, useRef, useEffect } from "react"; //forwardRef,
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
// materialui table
import MaterialTable from "material-table";

//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";

import { Grid, Card, CardContent } from "@material-ui/core";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//import ReactExport from "react-data-export";
import { useTranslation } from "react-i18next";

//import XLSX from "xlsx";
//import FileSaver from "file-saver";
import { Button } from "reactstrap";
import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";
// import OrderModal from "./OrderModal";
import ExportButtons from "../../../components/ExportButtons";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import MomentUtils from "@date-io/moment";
import moment from "moment";

import { DeleteOutline, Edit, ViewList } from "@material-ui/icons";



const IssueVoucherPush = (props) => {
  const permissionType = props.permissionType;

  //get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "issuevoucherpush";
const FacilityId = localStorage.getItem("FacilityId");
const FacilityName = localStorage.getItem("FacilityName");

  const [openOrder, setOpenOrder] = useState(false);

  const classes = useStyles();
  const tableRef = useRef();

  const [selectedRow, setSelectedRow] = useState(null);
  const [productsData, selectProductsData] = useState([]);
  const [productsAllData, selectProductsAllData] = useState([]);
  const [productGroupData, selectProductGroupData] = useState([]);
  const [productGroupFilter, selectProductGroupFilter] = useState("0");
  const [firstLoad, setFirstLoad] = useState(true);
  const [numberRowPerPage, setNumberRowPerPage] = useState(5);
  const [totalRow, setTotalRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [dataItems, setDataItems] = useState([]);
  //const [query, setQuery] = useState("");

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    moment().subtract(90, "days").format("YYYY-MM-DD")
  );

  const handleStartDateChange = (date) => {
    let newDate = moment(date).format("YYYY-MM-DD");
    setSelectedStartDate(newDate);
    setLoading(true);
  };

  const [selectedEndDate, setSelectedEndDate] = React.useState(
    moment().format("YYYY-MM-DD")
  );

  const handleEndDateChange = (date) => {
    let newDate = moment(date).format("YYYY-MM-DD");
    setSelectedEndDate(newDate);
    setLoading(true);
  };

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const [isLoading, setLoading] = useState(true);

  let params = {
    menukey: menukey,
    action: 'getIssueVoucherList',
    FacilityId: FacilityId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",
    StartDate: selectedStartDate,
    EndDate: selectedEndDate,
    TransactionTypeId: 1,
  };
  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getIssueVoucherPush,
    {
      onSuccess: (data) => {
        setDataItems(data);
        setLoading(false);
      },
    }
  );

  const { mutate } = useMutation(api.deleteIssueVoucherPushInv, {
    onSuccess: (data) => {
      if (data.status == 200) {
      refetch();
   
      queryClient.getQueriesData("issuevoucherpush");
      
      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
        duration: 10000,
      });

      }else{ 
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });  
           
        }
    },
  });

  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");

    window.open(
      finalUrl +
        "?action=IssueVoucherPushExport" +
        "&reportType=" +
        reportType +
        "&menukey=" +
        menukey +
        "&lan=" +
        localStorage.getItem("LangCode") +
        "&FacilityId=" +
        FacilityId +
        "&FacilityName=" +
        FacilityName +
        "&StartDate=" +
        StartDate +
        "&EndDate=" +
        EndDate + "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e: any, row: any) => {
    let rowData = row._row.data;
    editReceiveInv(rowData);
  };

  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: 50,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Facility"],
      field: "FacilityName",
      // width: 200,
      minWidth: 200,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Invoice No"],
      field: "TransactionNo",
      headerFilter: true,
      width: 110,
    },
    {
      title: DispensingLanguage[lan][menukey]["Invoice Date"],
      width: 110,
      field: "TransactionDate",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },  
    {
      title: DispensingLanguage[lan][menukey]["Prepareed By"],
      field: "PrepByName",
      // width: 160,
      minWidth: 150,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Approved Date"],
      width: 100,
      field: "AppDate1",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Approved By"],
      field: "AppByName",
      minWidth: 150,
      // width: 220,
      headerFilter: true,
    },
    
    {
      title: DispensingLanguage[lan][menukey]["Issued Date"],
      width: 100,
      field: "IssuedDate",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();

        return value == null ? "" : moment(value).format("DD/MM/YYYY");
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Issued By"],
      field: "IssuedByName",
      // width: 220,
      minWidth: 150,
      headerFilter: true,
    },

    {
      title: DispensingLanguage[lan][menukey]["Updated"],
      width: 95,
      field: "bStockUpdated",
      hozAlign: "center",
      formatter: "tickCross",
      headerHozAlign: "center", 
    },{
      title: DispensingLanguage[lan][menukey]["CANCEL"],
      width: 90,
      field: "BCancel",
      hozAlign: "center",
      formatter: "tickCross",
      headerHozAlign: "center", 
    },
    {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      hozAlign: "center", 
      headerHozAlign: "center", 
      width: 80,
      formatter: reactFormatter(<ActionButton />),
    },
  ];

  const handleChangePage = (data) => {
    setPageNumber(data);
    setFirstLoad(false);
  };
  const handleChangeRowPerPage = (data) => {
    setNumberRowPerPage(data);
  };

  const editReceiveInv = (data) => {
    let route = `/issuevoucherpush/edit/${data.id}`;
    props.history.push(route);
  };

  const deleteReceiveInv = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Once deleted, you will not be able to recover this Issue Invoice!"
        ]
      ),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
     /*  buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willDelete) => {
      if (willDelete) {
        mutate(data.id);
      }
    });
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    //const cellValue = props.cell._cell.value || "Edit | Show";

    if (rowData.bStockUpdated || rowData.BCancel || permissionType)
      return (
        <>
          <ViewList
            onClick={() => {
              editReceiveInv(rowData);
            }}
          />
        </>
      );
    else
      return (
        <>
          <Edit
            onClick={() => {
              editReceiveInv(rowData);
            }}
          />
          <DeleteOutline
            onClick={() => {
              deleteReceiveInv(rowData);
            }}
          />
        </>
      );
  }

  const addOrder = (OrderProductData) => {
    editReceiveInv(OrderProductData);

    //setManyTableData(OrderProductData.concat(ManyTableData));
    // handleAdd(OrderProductData.concat(ManyTableData));
    // handleClose('OrderModal');
  };

  const handleModal = (modalname) => {
    switch (modalname) {
      case "OrderModal":
        setOpenOrder(false);
        break;
      case "OrderOpen":
        setOpenOrder(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    refetch();
  }, [selectedEndDate, selectedEndDate]);

  return (
    <>
      <div className={classes.ReceivePageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Issue Voucher Push"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>
              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["From"]}
                      autoOk={true}
                      value={selectedStartDate}
                      onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>

              <Grid item xs={2} sm={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid container justifyContent="space-around">
                    <KeyboardDatePicker
                      className={classes.fullWidth}
                      variant="inline"
                      format="dd/MM/yyyy"
                      id="date-picker-inline"
                      label={DispensingLanguage[lan][menukey]["To"]}
                      autoOk={true}
                      value={selectedEndDate}
                      onChange={handleEndDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={8} sm={8}>
                <div className="float-right sw_btn_control">
                  {/* <Button
                    color="info"
                    className="mr-2"
                    onClick={() => handleModal("OrderOpen")}
                  >
                    {DispensingLanguage[lan][menukey]["Select From Order"]}
                  </Button> */}

                  <Button
                  disabled={permissionType}
                    color="info"
                    className="mr-2"
                    onClick={() => {
                      props.history.push("/issuevoucherpush/add");
                    }}
                  >
                    {DispensingLanguage[lan][menukey]["Add Issue"]}
                  </Button>

                  <ExportButtons PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}  {...props}></ExportButtons>

                  {/* <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>

                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button> */}
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        {/* <OrderModal
          handleClose={handleModal}
          openOrder={openOrder}
          addOrder={addOrder}
          {...props}
        /> */}
        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="500px"
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IssueVoucherPush;

const useStyles = makeStyles({
  ReceivePageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
