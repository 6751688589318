import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  FormHelperText,
} from "@material-ui/core";

import ExportButtonsMany from "../../../components/ExportButtonsMany";
import swal from "sweetalert";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { DeleteOutline, Edit, ViewList, FileCopy } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

//react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

import Form7BUIMSInvStatusValidation from "../../../services/Form7BUIMSInvStatusValidation";
import Form7BUIMSMistakeStatus from "../../../services/Form7BUIMSMistakeStatus";

import * as api from "../../../actions/api";
import { useTranslation } from "react-i18next";

let timeStamp = "";

//errorObject, addProductForm, formData, handleChange, handleCheck, handleReset, handleSubmit, handleUpdate, ManyTableData,ManyJsonSave,handleRDateChange,selectedDate,handleAdd,handleRefech,
const Form7BuimsFormData = (props) => {
  const permissionType = props.permissionType;
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "form-7b-uims";
  const FacilityId = localStorage.getItem("FacilityId");

  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  let ReceiveDetailsRef = null;

  const [newBatchRowAdded, setNewBatchRowAdded] = useState(false);

  const [open, setOpen] = useState(false);
  const [openMistake, setOpenMistake] = useState(false);
  const [openOrder, setOpenOrder] = useState(false);

  const [SoureOpen, setSoureOpen] = useState(false);

  const [manyTableData, setManyTableData] = useState([]);
  const [bStockUpdate, setbStockUpdate] = useState(false);
  const [toggleButtonValue, setToggleButton] = React.useState("1");

  const IssuingToFacility = JSON.parse(
    localStorage.getItem("IssuingToFacility")
  );
  const MonthList = JSON.parse(localStorage.getItem("MonthList"));
  const UseFor = localStorage.getItem("UseFor");
  // const UsersListByFacility = JSON.parse(localStorage.getItem("UsersListByFacility"));
  const FacilityEmployee = JSON.parse(localStorage.getItem("FacilityEmployee"));
  const Designation = JSON.parse(localStorage.getItem("Designation"));
  const [currRowId, setCurrRowId] = useState(0);
  const [currFormulation, setCurrFormulation] = useState("");
  const [bIsDisable, setbIsEnable] = useState(false);


  
//====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "MonthId": {"id": "","name": ""}
});


useEffect(() => {
  
  let chosenValuesData = { ...chosenValues};
   chosenValuesData['MonthId'] = MonthList[MonthList.findIndex(Month_List => Month_List.id == props.formData.MonthId)];
   setChosenValues(chosenValuesData); 

}, [props.formData.MonthId]); 



const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  setChosenValues(chosenValuesData); 

  props.handleChangeChoosenProps(name, value);
};

//====== End Choosen Dropdown===========



  /* =====Start of Print Excel Many Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintMany = () => {
    console.log("Test Data",props.formData);
    let finalUrl =
      EXCEL_EXPORT_URL +
      "report/form7bUIMS_print .php";

      window.open(
        finalUrl +
          "?menukey=" +
          menukey +
          "&lan=" +
          localStorage.getItem("LangCode") +
          "&TransactionId=" +
          props.formData.id +
          "&transferFacilityCode=" +
          props.formData.transferFacilityCode +
          "&FacilityId=" +
          FacilityId +
          "&UseFor=" +
          UseFor +
          "&TimeStamp=" +
          Date.now()
      );
  };


  const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;

      case "ProductsMistake":
        setOpenMistake(false);
        break;
      case "ProductsMistakeOpen":
        setOpenMistake(true);
        break;


        


      // case "OrderModal":
      //   setOpenOrder(false);
      //   break;
      // case "OrderOpen":
      //   setOpenOrder(true);
      //   break;

      // case "SourceOpen":
      //   setSoureOpen(true);
      //   break;

      // case "SourceClose":
      //   setSoureOpen(false);
      //   break;

      default:
        break;
    }
  };

  
  const generateForm7Items = () => {
    //console.log("111");
    getInvoiceStatusByMonth();
  };



  const { isLoading, error, isError, data: MyData, refetch:getInvoiceStatusByMonth } = useQuery(
    ["form7bcheckinvunpostuims", props.formData.YearName, props.formData.MonthId],
    api.getHasUnPostInvoiceByMonthForm7BUims,{
      onSuccess: (isunpostinv) => {
        // console.log("============",props.formData.MonthId);
        // console.log("============",props.formData.YearName);
        // console.log('isunpostinv: ', isunpostinv);
        if(isunpostinv > 0){
          handleClose("ProductsOpen");///////////////////////////////////////
        }else{
          checkItemMistake();
          // props.generateForm7Items();
        }
      },
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );



  
  const { isLoading4, error4, isError4, data: MyMistakeData, refetch:checkItemMistake } = useQuery(
    ["form7bcheckinvmistakeuims", props.formData.YearName, props.formData.MonthId],
    api.checkItemMistake,{
      onSuccess: (ismistakeinv) => {
        // console.log("============",props.formData.MonthId);
        // console.log("============",props.formData.YearName);
        // console.log('isunpostinv: ', isunpostinv);
        if(ismistakeinv > 0){
          handleClose("ProductsMistakeOpen");///////////////////////////////////////
        }else{
          props.generateForm7Items();
        }
      },
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const actionFromMistakePopup = (fdata) => {
    console.log("Press on Yes");
    handleClose("ProductsMistake");
    props.generateForm7Items();
  };








  const UpazilaStoreColumns = [
    { title: "TransactionItemId", field: "TransactionItemId", visible: false },
    { title: "id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      field: "rowNo",
      width: 40,
      // formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
    //   formatter: function (cell, formatterParams) {
    //     console.log('cell: ', cell);
    //     console.log('formatterParams: ', formatterParams);
    //     let _ItemCode = cell.getValue();
    //     if(_ItemCode == "CON004" || _ItemCode == "CON005"){
    //       return "";
    //     }else{
    //       serialNo++;
    //       console.log('serialNo serialNo: ', serialNo);
    //       return serialNo;
    //     }
    //   },
    },
    {
      title: DispensingLanguage[lan][menukey]["Item"],
      field: "ProdText",
      minWidth: 200,
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Opening Balance"],
      field: "S_OpeningBalance",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : Number(value).toLocaleString();
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Received"],
      field: "S_Received",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : Number(value).toLocaleString();
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Issued"],
      field: "S_Issued",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : Number(value).toLocaleString();
      },
    },
    // { title: "F_Received", field: "F_Received", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Adjustment Plus"],
      field: "S_AdjustPlus",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : Number(value).toLocaleString();
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Adjustment Minus"],
      field: "S_AdjustMinus",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : Number(value).toLocaleString();
      },
    },

    {
      title: DispensingLanguage[lan][menukey]["Closing Balance"],
      field: "S_ClosingBalance",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : Number(value).toLocaleString();
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["MOS"],
      field: "S_MOS",
      width: 80,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Total Useable Stock"],
      field: "Total_ClosingBalance",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
      visible: false,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },
    },

    {
      title: DispensingLanguage[lan][menukey]["Total SDP Stockout"],
      field: "SOSDPCount",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
      visible: false,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },
    },
     {
      title: "SOSDPCount",
      field: "SOSDPCount",
      visible: false,
    }, 
  ];
  

  const UpazilaFieldColumns = [
    { title: "TransactionItemId", field: "TransactionItemId", visible: false },
    { title: "id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      field: "rowNo",
      width: 40,
      // formatter: "rownum",
      hozAlign: "center",
      headerHozAlign: "center",
      headerSort: false,
      // formatter: function (cell, formatterParams) {
      //   let _ItemCode = cell.getValue();
      //   if(_ItemCode == "CON004" || _ItemCode == "CON005"){
      //     return "";
      //   }else{
      //     serialNo++;
      //     return serialNo;
      //   }
      // },
    },
    {
      title: DispensingLanguage[lan][menukey]["Item"],
      field: "ProdText",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Opening Balance"],
      field: "F_OpeningBalance",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },
    },
    // { title: "S_Issued", field: "S_Issued", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Received"],
      field: "F_Received",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Distributed to Acceptors"],
      field: "F_Issued",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Adjustment Plus"],
      field: "F_AdjustPlus",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["Adjustment Minus"],
      field: "F_AdjustMinus",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },
    },

    {
      title: DispensingLanguage[lan][menukey]["Closing Balance"],
      field: "F_ClosingBalance",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },
    },
    {
      title: DispensingLanguage[lan][menukey]["MOS"],
      field: "F_MOS",
      width: 80,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },
    }
    ,
    {
      title: DispensingLanguage[lan][menukey]["Total Useable Stock"],
      field: "Total_ClosingBalance",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },
    },

    {
      title: DispensingLanguage[lan][menukey]["Total SDP Stockout"],
      field: "SOSDPCount",
      width: 120,
      hozAlign: "right",
      headerHozAlign: "right",
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return value == 0 ? "" : value;
      },
    },
    {
      title: "SOSDPCount",
      field: "SOSDPCount",
      visible: false,
    },
  ];
  const [Form7BTableColumns, setForm7BTableColumns] = useState(UpazilaStoreColumns);
  
  const handleChangeToggle = (event, tmpToggleButtonValue) => {
    if (tmpToggleButtonValue) {
      
      console.log('tmpToggleButtonValue: ', tmpToggleButtonValue);

      if(tmpToggleButtonValue == 1){
        setForm7BTableColumns(UpazilaStoreColumns);
      }
      else{
        setForm7BTableColumns(UpazilaFieldColumns);
      }

      setToggleButton(tmpToggleButtonValue);
    }
  };



  const onPostBtnClick = () => {




    ///===================Check Isseue and Receive not matched==========/////////////
    
    // let duplicateinfo = {};
    // manyTableData.forEach((row, i) => {
    //   if((row.S_Issued != row.F_Received) && (Object.keys(duplicateinfo).length===0)){
    //     duplicateinfo = row;
    //   }
    // });
    
    // if(Object.keys(duplicateinfo).length>0){
    //   props.openNoticeModal({
    //     isOpen: true,
    //     msg: t(DispensingLanguage[lan][menukey]["Issued and Received are not matched"]) + ' - ' + duplicateinfo.ProdText,
    //     // msg: "Issued and Received are not matched" + ' - ' + duplicateinfo.ProdText,
    //     msgtype: false,
    //   });
    //   return;
    // }
  ///===================Check Isseue and Receive not matched==========/////////////






    getInvoiceStatusByMonth1();
    // setbStockUpdate(true);
    // props.handlePost();
  };

  
  const { isLoading1, error1, isError1, data: MyData1, refetch:getInvoiceStatusByMonth1 } = useQuery(
    ["form7bcheckinvunpostuims1", props.formData.YearName, props.formData.MonthId],
    api.getHasUnPostInvoiceByMonthForm7BUims,{
      onSuccess: (isunpostinv) => {
        // console.log("============",props.formData.MonthId);
        // console.log("============",props.formData.YearName);
        // console.log('isunpostinv: ', isunpostinv);
        if(isunpostinv > 0){
          handleClose("ProductsOpen");//////////////////////////////
        }else{
          setbStockUpdate(true);
          props.handlePost();
        }
      },
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  

  const onUnPostBtnClick = () => {
    getInvoiceStatusByMonthWhenUnpost();
    // setbStockUpdate(true);
    // props.handlePost();
  };

  
  const { isLoading3, error3, isError3, data: MyData3, refetch:getInvoiceStatusByMonthWhenUnpost } = useQuery(
    ["form7bcheckunpostuims1", props.formData.YearName, props.formData.MonthId],
    api.getUnPostCheckByMonthForm7BUims,{
      onSuccess: (isunpostinv) => {
        // console.log("============",props.formData.MonthId);
        // console.log("============",props.formData.YearName);
        // console.log('isunpostinv: ', isunpostinv);
        if(isunpostinv > 0){
          handleClose("ProductsOpen");
        }else{
          setbStockUpdate(false);
          props.handleUnPost();
        }
      },
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );


  const onSaveUpdateBtnClick = () => {
    props.setUpdatedDetailsGridDataHandler(ReceiveDetailsRef.table.getData());
    //  props.handleUpdate();
  };

  const deleteReceiveInv = (data) => {
    let newarray = manyTableData.filter((element) => element !== data);
    setManyTableData(newarray);
    props.deleteReceiveInv(data);
  };

  const CopyBatch = () => {
    props.handleAdd(manyTableData);
  };

  function ActionButton(props) {
    //console.log("console props......", props123);

    // return;

    const rowData = props.cell._cell.row.data;

    const goToDetails = () => {
      let rowobj = props.cell._cell.row.data; //{};
      // console.log("I copied this line", rowobj);
      //console.log("I copied this line", props.cell._cell.row);

      timeStamp = Math.round(new Date().getTime() / 100);

      setManyTableData((prevRows) => {
        const tmpPrevRows = [...prevRows];

        let setRposition = tmpPrevRows.findIndex((o) => o.SKU == rowobj.SKU);
        // console.log("Rposition", setRposition);

        const obj = { ...rowobj };

        obj.id = timeStamp;
        obj.TransactionItemId = "";
        obj.ExpiryDate = "";
        obj.BatchNo = "";
        obj.Quantity = 0;
        obj.SKU = Date.now();

        // console.log("new object added in grid:", obj);
        tmpPrevRows.splice(setRposition + 1, 0, obj);

        return tmpPrevRows;
      });

      // props.handleAdd(manyTableData);
      CopyBatch();
      setNewBatchRowAdded((prevNewBatchRowAdded) => !prevNewBatchRowAdded);
    };

    const cellValue = props.cell._cell.value || "Edit | Show";
    if (bStockUpdate == 0)
      return (
        <>
          <FileCopy
            onClick={() => {
              if (!bStockUpdate) goToDetails(rowData);
            }}
          />

          <DeleteOutline
            onClick={() => {
              if (!bStockUpdate) deleteReceiveInv(rowData);
            }}
          />
        </>
      );
    else return <></>;
  }

  
  const addProduct = (NonOrderProductData) => {
  };



  useEffect(() => {
    if (ReceiveDetailsRef == null) return;
    if (props.bFirst) {
      setManyTableData(props.ManyTableData);
    }

    setbStockUpdate(props.bStockUpdate);
  }, [props.ManyTableData, props.bStockUpdate]); //

  useEffect(() => {
    if (ReceiveDetailsRef.table != null && timeStamp != "") {
      // console.log("Many table", ReceiveDetailsRef.table);
      // console.log("Many table 12222222222");

      ReceiveDetailsRef.table.scrollToRow(timeStamp, "center", true);
      ReceiveDetailsRef.table.selectRow(timeStamp);
    }
  }, [newBatchRowAdded]);

  const handleReset = () => {
    props.handleReset();
    setManyTableData([]);
  };

  
  const backtolist=()=>{


    let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
    let idx = master_dirty_message_class.indexOf("dnone");
    if(idx === -1){
      // start Alert
      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
      }).then((willAction) => {
        if (willAction) {
          props.history.push("/form7buims");
        }
      });
      // End Alert
    }else{
      props.history.push("/form7buims");
    }

  }
  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Form7B"]}
              </div>

              <div className="float-right sw_btn_control">
                {props.addProductForm ? (
                  <Grid item xs={12} className=" text-center">
                    <div className="float-right sw_btn_control">
                      <Button
                      disabled={props.bStockUpdate}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button>
                      <Button
                      disabled={props.bStockUpdate}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={onSaveUpdateBtnClick} //() => props.handleSubmit()
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        // onClick={() => props.history.push("/form7buims")}
                      onClick={() => backtolist()}

                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="mt-2 text-center">
                    <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>

                   <Button
                      // disabled={props.bStockUpdate}
                      disabled={props.bStockUpdate || !(manyTableData.length>0)}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onPostBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["Post"]}
                    </Button>
                    

                    <Button
                      disabled={!props.bStockUpdate || permissionType}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onUnPostBtnClick}
                    >
                      {DispensingLanguage[lan][menukey]["Unpost"]}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      // onClick={() => props.history.push("/form7buims")}
                      onClick={() => backtolist()}

                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>

                    <ExportButtonsMany PrintMany={PrintMany}  {...props}></ExportButtonsMany>
{/* 
                    <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => PrintMany()}
                    >
                      <i class="fas fa-print"></i>
                    </Button> */}

                    {/* <Button
                      variant="contained"
                      color="primary"
                      className="mr-2 sw_icons"
                      onClick={() => ExcelMany()}
                    >
                      <i className="far fa-file-excel"></i>
                    </Button> */}
                  </Grid>
                )}
              </div>

              <div className="mr-4 float-right mt-15">
                <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
              </div>

            </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            {/* <Card className="sw_card">
            <CardHeader title="Personal Information" />
            <CardContent> */}

            <Grid container spacing={3}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={3}>
                

                  <Grid item xs={2} sm={2}>
                    <TextField
                      disabled={true}
                      id="id"
                      name="id"
                      label={DispensingLanguage[lan][menukey]["Id"]}
                      value={props.formData.TransactionNo}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>
                

                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={true}
                      id="FacilityName"
                      name="FacilityName"
                      label={DispensingLanguage[lan][menukey]["Upazila"]}
                      value={props.formData.FacilityName}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>
                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={true}
                      id="DistrictName"
                      name="DistrictName"
                      label={DispensingLanguage[lan][menukey]["District"]}
                      value={props.formData.DistrictName}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                 
                  <Grid item xs={3} sm={2}>
                    <TextField
                      disabled={true}
                      id="TotalSDP"
                      name="TotalSDP"
                      label={DispensingLanguage[lan][menukey]["Total SDP"]}
                      value={props.formData.TotalSDP}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid> 
                  <Grid item xs={2} sm={2}>
                    <TextField
                      disabled={true}
                      id="TotalRepSubmitted"
                      name="TotalRepSubmitted"
                      label={DispensingLanguage[lan][menukey]["Reports Submitted"]}
                      value={props.formData.TotalRepSubmitted}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>
                  
                 

                  {/* <Grid item xs={2} sm={2}>
                    <TextField
                      disabled={true}
                      id="reportMonth"
                      name="reportMonth"
                      label={DispensingLanguage[lan][menukey]["Report Month"]}
                      value={props.formData.reportMonth}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>  */}
                   <Grid item xs={2} sm={2}>
                    <FormControl className={classes.fullWidth}>
                      {/* <InputLabel id="demo-simple-select-helper-label-receive-from">
                        {DispensingLanguage[lan][menukey]["Report Month"]} *
                      </InputLabel>
                      <Select
                        error={props.errorObject.MonthId}
                        labelId="demo-simple-select-helper-label-receive-from"
                        id="MonthId"
                        name="MonthId"
                        value={props.formData.MonthId}
                        fullWidth
                        onChange={(e) => props.handleChange(e)}
                        // disabled={props.bStockUpdate}
                        disabled={
                          props.bStockUpdate ||
                          (manyTableData.length > 0)
                        }
                      >
                        {MonthList.map((item, index) => {
                          return (
                            <MenuItem value={item.id}>{item.name}</MenuItem>
                          );
                        })}
                      </Select>

                      <FormHelperText error={props.errorObject.MonthId}>
                        {props.errorObject.MonthId}
                      </FormHelperText> */}

                      <Autocomplete
                        autoHighlight
                        disableClearable
                        disabled={
                          props.bStockUpdate ||
                          (manyTableData.length > 0)
                        }
                        id="MonthId"
                        options={MonthList}
                        onChange={(event, valueobj) => handleChangeChoosen('MonthId', valueobj, valueobj?valueobj.id:"")}
                        getOptionLabel={(option) => option.name}
                        value={chosenValues['MonthId']}
                        renderOption={(option) => (
                        <Typography className="sw_fontSize">{option.name}</Typography>
                        )}
                        renderInput={(params) => (
                        <TextField
                          {...params}
                          value={props.formData.MonthId}
                          label={DispensingLanguage[lan][menukey]["Report Month"]+ " *"}
                          variant="standard"
                          error={props.errorObject.MonthId}
                          helperText={props.errorObject.MonthId}
                          id="MonthId"
                          name="MonthId"
                          fullWidth
                        />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  
                  <Grid item xs={3} sm={3}>
                    <TextField
                      disabled={
                        props.bStockUpdate ||
                        (manyTableData.length > 0)
                      }
                      id="YearName"
                      name="YearName"
                      label={DispensingLanguage[lan][menukey]["Report Year"]}
                      value={props.formData.YearName}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid> 
             

                  <Grid item xs={5} sm={5}>
                    <TextField
                     disabled={
                      props.bStockUpdate
                    }
                      id="Remarks"
                      name="Remarks"
                      label={DispensingLanguage[lan][menukey]["Remarks"]}
                      value={props.formData.Remarks}
                      fullWidth
                      multiline
                      rows={3}
                      autoComplete="family-name"
                      onChange={(e) => props.handleChange(e)}
                    />
                  </Grid>

                  <Grid item xs={3} sm={3}>
                    <TextField
                      id="FacilityId"
                      name="FacilityId"
                      label="FacilityId"
                      value={props.formData.FacilityId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                    <TextField
                      id="TransactionId"
                      name="TransactionId"
                      label="TransactionId"
                      value={props.formData.TransactionId}
                      fullWidth
                      hidden
                      autoComplete="family-name"
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                {/* new row */}
                <Grid item xs={12} sm={12}>
                  <Card className="sw_card">
                    <CardContent>
                     
                      {/* <ReactTabulator
                        ref={(r) => (ReceiveDetailsRef = r)}
                        columns={UpazilaStoreColumns}
                        rowFormatter={function (row) {
                          var data = row.getData();
                          if (data.linkNo > 0) {
                            row.getElement().style.backgroundColor = "#D9FCFF";
                            row.getElement().style.fontWeight = "bold";
                          }
                        }}
                        data={manyTableData}
                        height="450px"
                        layout={"fitColumns"}
                      /> */}




                  <Grid container spacing={1}>
 

                  <Grid container className="mb-2">
                        <Grid item xs={12} sm={12}>
                          <div className="float-right sw_btn_control">
                            <Button
                              disabled={
                                props.bStockUpdate ||
                                (props.addProductForm ? true : false)
                              }
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              onClick={() => generateForm7Items()}
                            >
                              {DispensingLanguage[lan][menukey]["GENERATE"]}
                            </Button>
                            <Button
                              disabled={props.bStockUpdate || (props.addProductForm ? true : false) || !(manyTableData.length>0) }
                              className="mr-2"
                              variant="contained"
                              color="primary"
                              onClick={() => props.deleteForm7Items()}
                            >
                              {DispensingLanguage[lan][menukey]["DELETE"]}
                            </Button>
                          </div>


                          <Form7BUIMSInvStatusValidation
                            handleClose={handleClose}
                            open={open}
                            addProduct={addProduct}
                            FacilityId={FacilityId}
                            YearId={props.formData.YearName}
                            MonthId={props.formData.MonthId}
                            {...props}
                          /> 

                          <Form7BUIMSMistakeStatus
                            handleClose={handleClose}
                            open={openMistake}
                            actionFromMistakePopup={actionFromMistakePopup}
                            FacilityId={FacilityId}
                            YearId={props.formData.YearName}
                            MonthId={props.formData.MonthId}
                            {...props}
                          /> 

                        </Grid>
                      </Grid>

                      
                    {/* new row */}
                    <Grid item xs={12} sm={12}>
                      <div className="sw_relative">
                        <div className="uniqueName mb-2">
                        <ReactTabulator
                          ref={(r) => (ReceiveDetailsRef = r)}
                          columns={Form7BTableColumns}
                          rowFormatter={function (row) {
                            var data = row.getData();
                            if (data.linkNo > 0) {
                              row.getElement().style.backgroundColor = "#D9FCFF";
                              row.getElement().style.fontWeight = "bold";
                            }
                          }}
                          data={manyTableData}
                          height="320px"
                          layout={"fitColumns"}
                        />
                        </div>
                      </div>

                    </Grid>

                    <Grid item xs={12} sm={12}>
                      <ToggleButtonGroup
                        className="sw_ToggleButtonGroup"
                        color="primary"
                        value={toggleButtonValue}
                        exclusive
                        onChange={handleChangeToggle}
                      >
                        <ToggleButton value="1">
                          {DispensingLanguage[lan][menukey]["Upazila Store"]}
                        </ToggleButton>
                        <ToggleButton
                          value="2"
                        >
                          {DispensingLanguage[lan][menukey]["Field Store"]}
                        </ToggleButton>
                    
                      </ToggleButtonGroup>
                    </Grid>

                    
                  </Grid>







                    </CardContent>
                  </Card>
                </Grid>

                {/* new row */}
              </Grid>
            </Grid>

            {/* </CardContent>
          </Card> */}
          </Grid>

          {/* Action buttons */}
        </Grid>
      </div>
    </div>
  );
};

export default Form7BuimsFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
