import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, styled } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
          className="float-right"
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};



const AuditLogModal = ({ handleClose, open,LogDataArray,...props }) => {
  
  //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "audit-log";


  let tblRef = null;



  // return (
  //   <div>
  //     <BootstrapDialog
  //       onClose={() => handleClose('ProductBatch')}
  //       aria-labelledby="customized-dialog-title"
  //       open={open}
  //       fullWidth={true}
  //       maxWidth="md"
  //     >
  //       <BootstrapDialogTitle
  //         id="customized-dialog-title"
  //         onClose={() => handleClose('ProductBatch')}
  //       >
  //       {DispensingLanguage[lan][menukey]['Audit Log']} 
  //       </BootstrapDialogTitle>


  //     <div className="LogClass"> <b>{DispensingLanguage[lan][menukey]['SQL']}:</b> {LogDataArray[0]}</div> 
  //     <div className="LogClass"><b>{DispensingLanguage[lan][menukey]['Parameter(s)']}:</b> {LogDataArray[1]}</div> 

       

  //       <DialogActions>
         
  //         <Button
  //           autoFocus
  //           className="float-left"
  //           variant="contained" 
  //           onClick={() => handleClose('ProductBatch')}
  //         >
  //           {DispensingLanguage[lan][menukey]['Close']}  
  //         </Button>
  //       </DialogActions>

  //     </BootstrapDialog>
  //   </div>
  // );






  return (
    <div>
      
      <BootstrapDialog
         onClose={() => handleClose('ProductBatch')}
         aria-labelledby="customized-dialog-title"
         open={open}
         fullWidth={true}
         maxWidth="md"
       >
         <BootstrapDialogTitle
           id="customized-dialog-title"
           onClose={() => handleClose('ProductBatch')}
         >


        {DispensingLanguage[lan][menukey]['Audit Log']} 
        </BootstrapDialogTitle>
        <DialogContent dividers>

       <div className="LogClass"> <b>{DispensingLanguage[lan][menukey]['SQL']}:</b> {LogDataArray[0]}</div> 
       <div className="LogClass"><b>{DispensingLanguage[lan][menukey]['Parameter(s)']}:</b> {LogDataArray[1]}</div> 
          
        </DialogContent>
        
        
        <DialogActions>
          <Button
          autoFocus
          className="float-left"
          variant="contained" 
          onClick={() => handleClose('ProductBatch')}
          >
          {DispensingLanguage[lan][menukey]['Close']}  
          </Button>
        </DialogActions> 


      </BootstrapDialog>
    </div>
  );





};

export default AuditLogModal;
