import React, { useCallback,useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import { useQuery, useMutation, useQueryClient } from "react-query";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import * as api from "../../../actions/api";
//Services
import * as Service from "../../../services/Service.js";

const OthersFacilityListFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  const classes = useStyles();
//get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "health-facility-entry";
const DistrictList = JSON.parse(localStorage.getItem("DistrictList"));
  const { t, i18n } = useTranslation();

  let TempUnionList = useState([]);
  const [UnionList, setUnionListLocal] = useState(TempUnionList);
  const queryClient = useQueryClient();

  
  //====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "UnionCode": {"id": "","name": ""}
});


React.useEffect(() => {
  
  let chosenValuesData = { ...chosenValues};
   chosenValuesData['UnionCode'] = UnionList[UnionList.findIndex(Union_List => Union_List.id == formData.UnionCode)];
   setChosenValues(chosenValuesData); 

  }, [formData.UnionCode]);

const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  setChosenValues(chosenValuesData); 
  props.handleChangeChoosenProps(name, value);
};

//====== End Choosen Dropdown===========

  //const [currUpazilaCode, setcurrUpazilaCode] = useState(0);
  const [num, setNum] = useState(0);

  const incrementNum = useCallback(() => {
    setNum(prev => prev + 1);
  }, []);


  React.useEffect(() => {
    
    incrementNum();
    if(formData.UpazilaCode && num <= 1){
      cUnionList();
    }
      
  }, [formData,incrementNum]);
  

  
  
  const cUnionList = () => {
    let fields = formData.UpazilaCode.split('_');
    let UpazilaCode = fields[0];
    let UnionListParam = {action:"getUnionList", UpazilaCode:UpazilaCode, menukey:""}
  
    Service.default
        .postApi("source/combo_generic.php",UnionListParam)
        .then((res) => {

          if(!addProductForm){
            let chosenValuesData = { ...chosenValues};
            chosenValuesData['UnionCode'] = res.datalist[res.datalist.findIndex(fpacily_Lists2 => fpacily_Lists2.id == formData.UnionCode)];
            setChosenValues(chosenValuesData);  

        }else{
            setChosenValues({
            "UnionCode": {"id": "0","name": ""}
            }); 
            formData["UnionCode"] = "";
          } 

          setUnionListLocal(res.datalist); 
        })
        .catch((err) => {});
  
  }



  
const backtolist=()=>{


  let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
  let idx = master_dirty_message_class.indexOf("dnone");
  if(idx === -1){
    // start Alert
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then((willAction) => {
      if (willAction) {
        props.history.push("/health-facility-entry");
      }
    });
    // End Alert
  }else{
    props.history.push("/health-facility-entry");
  }

}


const onSaveUpdateBtnClick = () => {

  setUpdatedDetailsGridDataHandler();
};


const setUpdatedDetailsGridDataHandler = () => {
  let validForm = props.checkValidateForm();
 
  // console.log('isval: ', validForm);
    if (validForm) {

      if(addProductForm){
        //when save
        mutate(validForm);
        
      }else{
        //when update
        mutateUpdate(validForm); 
      }
      

      
    }
};


const { isLoading : isLoadingSave, mutate } = useMutation(api.saveHealthFacilityList, {
  onSuccess: (data) => {

    if(data.status==200) {

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });

      queryClient.getQueriesData("HealthFacilityEntry");//api page name
      props.history.push("/health-facility-entry");//

    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
     
    }
  },
});

const { isLoading: isLoadingUpdate, mutate:mutateUpdate } = useMutation(api.updateHealthFacilityList, {
  onSuccess: (data) => {
    if (data.status == 200) {


      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });

      queryClient.getQueriesData("HealthFacilityEntry");// include the page name
      props.history.push("/health-facility-entry");//page url
      api.dirtyModeDisable();
    }else{

      props.openNoticeModal({
        isOpen: true,
        msg: data.message,
        msgtype: data.success,
      });
     
    }
  },
});


return (
  <div className={classes.productPageTitle}>
    <div className="sw_makeStyles_tableContainer">
      <div className="d-flex justify-product mb-3">
        <Grid item xs={12} sm={12}>
          <div className="sw_page_heading">
            <div className="sw_heading_title">
              {DispensingLanguage[lan][menukey]["Health Facility Entry - Add/Edit"]}
            </div>
            </div>
            <div className="float-right sw_btn_control">
              {addProductForm ? (
                <Grid item xs={12} className="text-center">
                  <div className="float-right sw_btn_control">
                    <Button
                      disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      onClick={() => handleReset()}
                    >
                      {DispensingLanguage[lan][menukey]["Reset"]}
                    </Button>
                    <Button
                      disabled={props.bStockUpdate || isLoadingSave}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={onSaveUpdateBtnClick}
                      // onClick={() => handleSubmit()}
                    >
                      {DispensingLanguage[lan][menukey]["Save"]}
                    </Button>
                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={() => props.history.push("/menu-entry")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Cancel"]}
                    </Button>
                  </div>
                </Grid>
              ) : (
                <Grid item xs={12} className="mt-2 text-center">
                  <Button
                      disabled={props.bStockUpdate || isLoadingUpdate}
                    className="mr-2"
                    variant="contained"
                    color="primary"
                    onClick={onSaveUpdateBtnClick}
                    // onClick={() => handleUpdate()}
                  >
                    {DispensingLanguage[lan][menukey]["update"]}
                  </Button>

                  <Button
                    className="mr-2"
                    variant="contained"
                    type="reset"
                    //onClick={() => props.history.push("/menu-entry")}
                    onClick={() => backtolist()}
                  >
                    {DispensingLanguage[lan][menukey]["Cancel"]}
                  </Button>
                </Grid>
              )}
            </div>
          
             <div className="mr-4 float-right mt-15">
              <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
            </div>
        </Grid>
      </div>

      {/* New row */}
      <Grid container spacing={3}>
        {/* New row */}
        <Grid item xs={12} sm={12}>
          <Card className="sw_card">
            <CardContent>
               <Grid container spacing={10}>
                <Grid item xs={4} sm={12}>
                  <Grid container spacing={4}>

                  <Grid item xs={6} sm={4}>
                    <FormControl className={classes.fullWidth}>
                        {/* <InputLabel id="demo-simple-select-helper-label">
                        {DispensingLanguage[lan][menukey]['Union']+'*'}
                        </InputLabel>
                        <Select
                          error={errorObject.UnionCode}
                          labelId="demo-simple-select-helper-label"
                          id="UnionCode"
                          name="UnionCode"
                          value={formData.UnionCode}
                          fullWidth
                          disabled={props.permissionType}
                          onChange={(e) => handleChange(e)}
                         >
                            {
                            UnionList.map(
                            (item, index) => {
                              return (
                                <MenuItem value={item.id}>
                                  {item.name}
                                </MenuItem>
                              );
                            }
                          )}
                        </Select>
                        <FormHelperText error={errorObject.UnionCode}>
                          {errorObject.UnionCode}
                        </FormHelperText> */}

                        <Autocomplete
                          autoHighlight
                            //disableClearable
                          disabled={props.permissionType}
                          id="UnionCode"
                          options={UnionList}
                          onChange={(event, valueobj) => handleChangeChoosen('UnionCode', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValues['UnionCode']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            value={formData.UnionCode}
                            label={DispensingLanguage[lan][menukey]["Union"]+" *"}
                            variant="standard"
                            error={errorObject.UnionCode}
                            helperText={errorObject.UnionCode}
                            id="UnionCode"
                            name="UnionCode"
                            fullWidth
                          />
                          )}
                        />

                      </FormControl>
                      
                    </Grid>

                    <Grid item xs={6} sm={8}>
                      <TextField
                        error={errorObject.fpfacility}
                        helperText={errorObject.fpfacility}
                        required
                        id="fpfacility"
                        name="fpfacility"
                        disabled={props.permissionType}
                        label= {DispensingLanguage[lan][menukey]['Health Facility'] }
                        value={formData.fpfacility}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                   
                  </Grid>
                </Grid>
              </Grid>

              {/* New Row */}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  </div>
);
};

export default OthersFacilityListFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
