import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardHeader,
  CardContent,
  FormHelperText,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useTranslation } from "react-i18next";
import swal from "sweetalert";

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

//get DispensingLanguage
const DispensingLanguage = JSON.parse(
  localStorage.getItem("DispensingLanguage")
);
const lan = localStorage.getItem("LangCode");
const menukey = "employee-entry";


const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;  
const userId = UserInfo==0?'': UserInfo[0].id;
 
const EmployeeFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  ...props
}) => {
  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const RoleList = JSON.parse(localStorage.getItem("RoleList"));
  const LanguageList = JSON.parse(localStorage.getItem("LanguageList"));
  const [IssueToFacilityVal, setIssueToFacilityVal] = useState(false); 
  const DesignationList = JSON.parse(localStorage.getItem("Designation"));
  const [IssuedToGrpVal, setIssuedToGrpVal] = useState(false); 

  const getRxLevel = (type = null) => {
    let level = ""; 
    if (formData.rxLevel) {
      level = formData.rxLevel;
    }
    return level;
  };


const [acValue, setACValue] = useState({ DesigCode: '0', Designation: ""});

const changeIssuedToval = (val) => {
  setACValue(val);
  if(val != null){
    formData.DesigCode = val.DesigCode;
  }

  
};



  
//====== Start Choosen Dropdown===========
const [chosenValues, setChosenValues] = useState({
  "DesigCode": {"DesigCode": "","Designation": ""}
});


useEffect(() => {
  
  let chosenValuesData = { ...chosenValues};
   chosenValuesData['DesigCode'] = DesignationList[DesignationList.findIndex(Designation_List => Designation_List.DesigCode == formData.DesigCode)];
   setChosenValues(chosenValuesData); 

  }, [formData.DesigCode]);

const handleChangeChoosen = (name, valueobj, value) => {
  let chosenValuesData = { ...chosenValues};
  chosenValuesData[name] = valueobj;   
  setChosenValues(chosenValuesData); 
  props.handleChangeChoosenProps(name, value);
};

//====== End Choosen Dropdown===========


let bfalse=true;

  useEffect(() => {

  
    if(!addProductForm ){
     bfalse=false;
     setIssuedToGrpVal(formData.DesigCode);
    }



  }, [formData]);  


/* useEffect(() => {
  
  
  console.log(DesignationList);

  if(!addProductForm ){
    //setIssuedToGrpVal(formData.DesigCode);
    formData.DesigCode= IssuedToGrpVal;
    setACValue(DesignationList[DesignationList.findIndex(atclist => atclist.DesigCode == IssuedToGrpVal)]);
    bfalse=true;
  }
  

}, [formData.DesigCode]); */



/* 
useEffect(() => {


 if(addProductForm ){



      if(DesignationList.length==1){
         
        setACValue(
          DesignationList[0]
        );
        
        formData.adjIssueToFacility=DesignationList[0].DesigCode;
      }else{
        setACValue({ DesigCode: '0', Designation: ""});
        
      }
     
    }else{
    
      
      if(DesignationList[DesignationList.findIndex(atclist => atclist.DesigCode == formData.DesigCode)]==undefined)
      setACValue( { DesigCode: '0', Designation: ""});
      else setACValue(DesignationList[DesignationList.findIndex(atclist => atclist.DesigCode == formData.DesigCode)]);
 
    }  
   


  }, [DesignationList]); 
 */
 

  

const backtolist=()=>{


  let master_dirty_message_class = document.getElementById("master_dirty_message").getAttribute("class"); 
  let idx = master_dirty_message_class.indexOf("dnone");
  if(idx === -1){
    // start Alert
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(DispensingLanguage[lan][menukey]["You have unsaved changes which will be lost. Continue?"]),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
    }).then((willAction) => {
      if (willAction) {
        props.history.push("/employee-entry");
      }
    });
    // End Alert
  }else{
    props.history.push("/employee-entry");
  }

}

  return (
    <div className={classes.productPageTitle}>
      <div className="sw_makeStyles_tableContainer">
        <div className="d-flex justify-product mb-3">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Employee Entry- Add/Edit"]}
              </div>
              </div>
              <div className="float-right sw_btn_control">
                {addProductForm ? (
                  <Grid item xs={12} className="text-center">
                    <div className="float-right sw_btn_control">
                      <Button
                        disabled={props.bStockUpdate}
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        onClick={() => handleReset()}
                      >
                        {DispensingLanguage[lan][menukey]["Reset"]}
                      </Button>
                      <Button
                        disabled={props.bStockUpdate}
                        className="mr-2"
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit()}
                      >
                        {DispensingLanguage[lan][menukey]["Save"]}
                      </Button>
                      <Button
                        className="mr-2"
                        variant="contained"
                        type="reset"
                        //onClick={() => props.history.push("/employee-entry")}
                        onClick={() => backtolist()}
                      >
                        {DispensingLanguage[lan][menukey]["Back to List"]}
                      </Button>
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={12} className="mt-2 text-center">
                    <Button
                        disabled={props.bStockUpdate}
                      className="mr-2"
                      variant="contained"
                      color="primary"
                      onClick={() => handleUpdate()}
                    >
                      {DispensingLanguage[lan][menukey]["Update"]}
                    </Button>

                    <Button
                      className="mr-2"
                      variant="contained"
                      type="reset"
                      //onClick={() => props.history.push("/employee-entry")}
                      onClick={() => backtolist()}
                    >
                      {DispensingLanguage[lan][menukey]["Back to List"]}
                    </Button>
                  </Grid>
                )}
              </div>
            
               <div className="mr-4 float-right mt-15">
                <span id="master_dirty_message" className={'masterredtextcolor dnone'}> {DispensingLanguage[lan][menukey]["You have unsaved changes"]}</span>
              </div>
          </Grid>
        </div>

        {/* New row */}
        <Grid container spacing={3}>
          {/* New row */}
          <Grid item xs={12} sm={12}>
            <Card className="sw_card">
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.EmpName}
                      helperText={errorObject.EmpName}
                      required
                      id="EmpName"
                      name="EmpName"
                      label={DispensingLanguage[lan][menukey]["Employee Name"]}
                      value={formData.EmpName}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                      disabled={props.bStockUpdate}
                    />
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <FormControl className={classes.fullWidth}>
                     {/*  <InputLabel id="demo-simple-select-helper-label">
                     
                      {DispensingLanguage[lan][menukey]["Designation Name"]} *
                      </InputLabel>
                      <Select
                        error={errorObject.DesigCode}
                        helperText={errorObject.DesigCode}
                        required
                        labelId="demo-simple-select-helper-label"
                        id="DesigCode"
                        name="DesigCode"
                        value={formData.DesigCode}
                        fullWidth
                        onChange={(e) => handleChange(e)}
                      >
                   

                        {DesignationList.map((item, index) => {
                          return (
                            <MenuItem value={item.DesigCode}>{item.Designation}</MenuItem>
                          );
                        })}
                      </Select>
                      <FormHelperText error={errorObject.DesigCode}>
                        {errorObject.DesigCode}
                      </FormHelperText> */}

                          <Autocomplete
                            autoHighlight
                          
                            /* disabled={
                              manyTableData.length > 0 ? true : props.bStockUpdate
                            } */
                            id="DesigCode"
                            options={DesignationList}
                            onChange={(event, valueobj) => handleChangeChoosen('DesigCode', valueobj, valueobj?valueobj.DesigCode:"")}
                            getOptionLabel={(option) => option.Designation}
                            value={chosenValues['DesigCode']}
                            disabled={props.bStockUpdate}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.Designation}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              value={formData.DesigCode}
                              label={DispensingLanguage[lan][menukey]["Designation Name"]+ " *"}
                              variant="standard"
                              error={errorObject.DesigCode}
                              helperText={errorObject.DesigCode}
                              id="DesigCode"
                              name="DesigCode"
                              fullWidth
                            />
                            )}
                          />

                    </FormControl>
                  </Grid>  

                 
                  <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.MobileNo}
                      helperText={errorObject.MobileNo}
                      required
                      id="MobileNo"
                      name="MobileNo"
                      label={DispensingLanguage[lan][menukey]["Mobile Number"]}
                      value={formData.MobileNo}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                      disabled={props.bStockUpdate}
                      inputProps={{
                        maxLength: 11,
                      }}
                    />
                  </Grid>

                  <Grid item xs={4} sm={4}>
                    <TextField
                      error={errorObject.Email}
                      helperText={errorObject.Email}
                      
                      id="Email"
                      name="Email"
                      label={DispensingLanguage[lan][menukey]["Email"]}
                      value={formData.Email}
                      fullWidth
                      autoComplete="family-name"
                      onChange={(e) => handleChange(e)}
                      disabled={props.bStockUpdate}
                    />
                  </Grid>

                  
                  <Grid item xs={4} sm={4} className="sw_active_patient">
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          disabled={((formData.id==1)|| props.bStockUpdate)?true:false}
                          checked={formData.bActive}
                          onChange={(e) => handleCheck(e)}
                          name="bActive"
                          value="no" 
                        />
                      }
                      label="Active"
                    />
                  </Grid>

                </Grid>

                {/* New Row */}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default EmployeeFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
