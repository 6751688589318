import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom"; 
import * as api from "../../../actions/api";
import EntryofWarehouseFormData from "./EntryofWarehouseFormData";



const EditEntryofWarehouse = (props) => {

    //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "entry-edit-of-warehouse";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;  
  const UserName = UserInfo==0?'': UserInfo[0].id;


  const [selectedDate, setSelectedDate] = useState(
    new Date()
  );


  const [formData, setFormData] = useState({
    wh_id:"",
    wh_name:"",
    wh_district:"",
    wh_address:"",
    contact_person:"",
    contemail:"",
    extra:"",
    phoneno:"",
    UsingSoftware:"",
  });

  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  useQuery(
    ["EntryofWarehouse", id],
    () =>
      api.getEntryWareHouses(id).then((res) => {
        setFormData(res.data);
      }),
    {
      enabled: Boolean(id),
    }
  );

  // const { mutate } = useMutation(api.updateWareHouse, {
  //   onSuccess: (data) => { 
  //     if (data.status == 200) {
  //       // api.getAllDropdown("ABC_List").then((response) => {
  //       //   localStorage.setItem(
  //       //     "ABC_List",
  //       //     JSON.stringify(response.datalist.ABC_List)
  //       //   );
  //       // });

  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.data.message,
  //         msgtype: data.data.success,
  //       });

  //       queryClient.getQueriesData("EntryofWarehouse"); // include the page name
  //       props.history.push("/entry-edit-of-warehouse"); //page url
  //       api.dirtyModeDisable();
  //     }else{

  //       props.openNoticeModal({
  //         isOpen: true,
  //         msg: data.message,
  //         msgtype: data.success,
  //       });
  //     }
  //   },
  // });


  const handleDateChange = (date) => {
    setSelectedDate(date);
    api.dirtyModeEnable();
  }; 

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
    api.dirtyModeEnable();
  };

  const handleReset = () => {
    setFormData({
      wh_id:"",
      wh_name:"",
      wh_district:"",
      wh_address:"",
      contact_person:"",
      contemail:"",
      extra:"",
      phoneno:"",
      UsingSoftware:"",
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["wh_name","wh_id"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  function checkValidateForm(){

     
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
   
    return fDtat;
    }else{
    return false;
    }

};


  // const handleUpdate = async (e) => {
  //   if (validateForm(formData)) {
  //     let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
  //     mutate(fDtat);
     
       
  //   }
  // };


  const handleRDateChange = (date, field) => {
  
    setFormData({ ...formData, [field]: date });
    setErrorObject({ ...errorObject, [field]: null });
    api.dirtyModeEnable();
  };

  return (
    <>
      <EntryofWarehouseFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        checkValidateForm={checkValidateForm}
        // handleUpdate={handleUpdate}
        handleRDateChange={handleRDateChange} 
        bStockUpdate={props.permissionType}
        {...props}
      />
    </>
  );
};

export default EditEntryofWarehouse;
