import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Constants from "../../services/Constants";
// reactstrap components

import * as Service from "../../services/Service.js";

import swal from "sweetalert";

import { UserContext } from "../../context/user-info-context";
import "assets/css/sw_menu.css";
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Button,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

function AfterLoginNavbar(props) {
  const userCtx = useContext(UserContext);

  const [navbarColor, setNavbarColor] = React.useState("sticky");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [role, setRole] = React.useState(false);
  const [menu, setMenu] = React.useState([]);
  const [pageTitle, setPageTitle] = React.useState('');
  const lan = localStorage.getItem("LangCode"); //userCtx.userInfo.LangId; //"fr_FR";

  const menukey = "menu";

  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );


  const UserInfo = sessionStorage.getItem("User_info") ? JSON.parse(sessionStorage.getItem("User_info")) : 0;
  /*
  const setMenuTable = () => {
    // let auth = sessionStorage.getItem("User_info");
    //auth = JSON.parse(auth);
    // console.log("auth");
    // console.log(auth);

    let token = sessionStorage.getItem("token");
    //  let token = Constants.token;
    if (!token) {
      // swal("Oops!", `token expired. Please login again`, "error");
      props.history.push("/login");
      sessionStorage.clear();
    }

    let auth = Constants.USER_INFO;
    // console.log("USER_INFO");
    // console.log(USER_INFO);

    if (auth) {
      if (menu.length == 0 && auth.length > 0) {
        setMenu(auth[0].menu);
      }
    } else {
      props.history.push("login");
    }
  };*/

  const setMenuTable = () => {
    let auth = sessionStorage.getItem("User_info");
    auth = JSON.parse(auth);
    if (auth) {
      if (menu.length == 0 && auth.length > 0) {
        // setMenu(auth[0].menu);
        setMenu(auth[0].menu1);

        console.log("auth[0].menu: ", auth[0].menu1);
      }
    } else {
      props.history.push("login");
    }
  };

  function recurciveMenus(menu) {}

  React.useEffect(() => {
    setMenuTable();
    let auth = sessionStorage.getItem("User_info");
    auth = JSON.parse(auth);
    // let auth = Constants.USER_INFO;
    if (auth) {
      var isAdmin = auth[0].role.filter((name) => name.role.includes("Admin"));
      if (isAdmin.length > 0) {
        sessionStorage.setItem("userRole", "Admin");
      } else {
        sessionStorage.setItem("userRole", "User");
      }
    }

    let r = sessionStorage.getItem("userRole");
    setRole(r);
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 50 ||
        document.body.scrollTop > 50
      ) {
        //  setNavbarColor("");
        setNavbarColor("sticky");
      } else if (
        document.documentElement.scrollTop < 51 ||
        document.body.scrollTop < 51
      ) {
        //  setNavbarColor("navbar-transparent");
        setNavbarColor("sticky");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  const onMobileMenuOpen = () => {
    let element = document.getElementById("mobileMenuWrapper");
    element.classList.add("open");
  };
  const onMobileMenuClose = () => {
    let element = document.getElementById("mobileMenuWrapper");
    element.classList.remove("open");
  };
   
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}

      {/*============================*/}

      <div id="sticky-header" className={"header-menu " + navbarColor}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3">
              <div className="logo_card">
                <div className="logo">
                    <a href={process.env.REACT_APP_BASE_NAME+"/"}>
                    <img alt="..." src={require("assets/img/logo.png")}></img>
                    </a>
                </div> 
                <div className="site_name">
                  <a href={process.env.REACT_APP_BASE_NAME+"/"} id="navbar-brand">DGFP eLMIS</a>
                  <span class="sw_sub_title"> {localStorage.getItem("FacilityName")} </span>
                </div>
              </div>
              {/* <div class="logo">
                <a href={process.env.REACT_APP_BASE_NAME+"/"}>DGFP eLMIS</a>
                <span class="sw_sub_title">
                  {localStorage.getItem("FacilityName")}
                </span>
              </div> */}
              <button
                onClick={() => onMobileMenuOpen()}
                className="mobile_menu_bars_icon"
                type="button"
              >
                <i class="fas fa-bars"></i>
              </button>
            </div>
            <div className="col-lg-9">
              <div className="tp-mega-full">
                <div className="tp-menu align-self-center">
                  <nav className="desktop_menu">
                    <ul>
                      {menu.map((row, i) => {
                        if(props.location.pathname==row.url)
                        document.title ='DGFP eLMIS - '+ DispensingLanguage[lan][menukey][row.title]; 

                         if(props.location.pathname=="/my-profile")
                          document.title ='DGFP eLMIS - '+ DispensingLanguage[lan][menukey]["My Profile"];
                       
                        return (
                          <li>
                            <a
                              href="javascript:void(0)"
                              onClick={() => props.history.push(row.url)}
                            >
                              {DispensingLanguage[lan][menukey][row.title]}

                              {row.submenu.length > 0 ? (
                                <span class="tp-angle pull-right">
                                  <i class="fa fa-angle-down"></i>
                                </span>
                              ) : (
                                <></>
                              )}
                            </a>

                            {row.submenu.length > 0 ? (
                              <ul className={"submenu " + row.position}>
                                {row.submenu.map((row1, i1) => {
                                  if(props.location.pathname==row1.url)
                                  document.title ='DGFP eLMIS - '+ DispensingLanguage[lan][menukey][row1.title];


                                  return (
                                    <li>
                                      <a
                                        href="javascript:void(0)"
                                        onClick={() =>
                                          props.history.push(row1.url)
                                        }
                                      >
                                       
                                        {
                                          
                                          DispensingLanguage[lan][menukey][
                                            row1.title
                                          ]
                                        }

                                        {row1.submenu.length > 0 ? (
                                          <span class="tp-angle pull-right">
                                            <i class="fa fa-angle-right"></i>
                                          </span>
                                        ) : (
                                          <></>
                                        )}
                                      </a>

                                      {row1.submenu.length > 0 ? (
                                        <ul className={"submenu " + row1.position}>
                                          {row1.submenu.map((row2, i2) => {
                                            if(props.location.pathname==row2.url)
                                            document.title ='DGFP eLMIS - '+ DispensingLanguage[lan][menukey][row2.title];
          
                                            return (
                                              <li>
                                                <a
                                                  href="javascript:void(0)"
                                                  onClick={() =>
                                                    props.history.push(row2.url)
                                                  }
                                                >
                                                  {
                                                    DispensingLanguage[lan][
                                                      menukey
                                                    ][row2.title]
                                                  }
                                                </a>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      ) : (
                                        <></>
                                      )}
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : (
                              <></>
                            )}
                          </li>
                        );
                      })}

                      <li>
                        <a href="#">
                        {
                  DispensingLanguage[lan][menukey]['Profile']
                }
                          <span class="tp-angle pull-right">
                            <i class="fa fa-angle-down"></i>
                          </span>
                        </a>
                        <ul className="submenu right_position">
                          {/* <li>
                            <a href="#">My account</a>
                          </li>
                          <li>
                            <a href="#">My profile</a>
                          </li>
                          <li>
                            <a href="#">Admin Permissions</a>
                          </li> */}


                          <li>
                              <Link href="javascript:void(0)" onClick={(e) => {
                              e.preventDefault();
                              props.history.push("/my-profile");
                              //props.history.push("/my-profile");
                              }}>{ 
                              DispensingLanguage[lan][menukey]["My Profile"]}</Link>
                          </li>

                          <li>
                            <a
                              href="javascript:void(0)"
                              onClick={(e) => {
                                document.title ='DGFP eLMIS - '+ 'Login';
                                e.preventDefault();
                                sessionStorage.clear();
                                setTimeout(() => {
                                  props.history.push("/login");
                                }, 1000);
                              }}
                            >
                                {DispensingLanguage[lan][menukey]['Logout']}
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>

              {UserInfo &&                  
              <div className="login_info_title">                  
                  <span class="login_info_sub_title">User: {UserInfo[0].name}, {UserInfo[0].designation}</span>
              </div> 
              }


            </div>
          </div>
        </div>
      </div>

      {/* =========Mobile Menu======== */}
      <div id="mobileMenuWrapper" className="mobile-menu-wrapper">
        <div className="off-canvas-overlay">
          <div className="offcanvas-body">
            <div className="mobile_menu_close">
              <button
                onClick={() => onMobileMenuClose()}
                className="mobile_menu_close_icon"
                type="button"
              >
                <i class="fas fa-times"></i>
              </button>
            </div>
            <ul className="sw_mobile_menu">
              {menu.map((row, i) => {
                 if(props.location.pathname==row.url)
                 document.title ='DGFP eLMIS - '+ DispensingLanguage[lan][menukey][row.title];

                return (
                  <li>
                    <a
                      href="javascript:void(0)"
                      onClick={() => props.history.push(row.url)}
                    >
                      {DispensingLanguage[lan][menukey][row.title]}

                      {row.submenu.length > 0 ? (
                        <span class="tp-angle">
                          <i class="fa fa-angle-down"></i>
                        </span>
                      ) : (
                        <></>
                      )}
                    </a>

                    {row.submenu.length > 0 ? (
                      <ul className="submenu">
                        {row.submenu.map((row1, i1) => {
                           if(props.location.pathname==row1.url)
                           document.title ='DGFP eLMIS - '+ DispensingLanguage[lan][menukey][row1.title];

                          return (
                            <li>
                              <a
                                href="javascript:void(0)"
                                onClick={() => props.history.push(row1.url)}
                              >
                                {DispensingLanguage[lan][menukey][row1.title]}

                                {row1.submenu.length > 0 ? (
                                  <span class="tp-angle">
                                    <i class="fa fa-angle-right"></i>
                                  </span>
                                ) : (
                                  <></>
                                )}
                              </a>

                              {row1.submenu.length > 0 ? (
                                <ul className="submenu">
                                  {row1.submenu.map((row2, i2) => {
                                     if(props.location.pathname==row2.url)
                                     document.title ='DGFP eLMIS - '+ DispensingLanguage[lan][menukey][row2.title];
   
                                    return (
                                      <li>
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() =>
                                            
                                            props.history.push(row2.url)
                                          }
                                        >
                                          {
                                            DispensingLanguage[lan][menukey][
                                              row2.title
                                            ]
                                          }
                                        </a>
                                      </li>
                                    );
                                  })}
                                </ul>
                              ) : (
                                <></>
                              )}
                            </li>
                          );
                        })}
                      </ul>
                    ) : (
                      <></>
                    )}
                  </li>
                );
              })}

              <li>
                <a href="#">
                {
                  DispensingLanguage[lan][menukey]['Profile']
                }
                 
                  <span class="tp-angle">
                    <i class="fa fa-angle-down"></i>
                  </span>
                </a>
                <ul className="submenu">
                  {/*<li>
                    <a href="#">My account</a>
                  </li>
                  <li>
                    <a href="#">My profile</a>
                  </li>
                  <li>
                    <a href="#">Admin Permissions</a>
                  </li>*/}

                  <li>
                      <Link href="javascript:void(0)" onClick={(e) => {
                     document.title ='DGFP eLMIS - '+ DispensingLanguage[lan][menukey]["My Profile"];
                      e.preventDefault();
                      props.history.push("/my-profile");
                      //props.history.push("/my-profile");
                      }}>{DispensingLanguage[lan][menukey]["My Profile"]}</Link>
                  </li>

                  <li>
                    <a
                      href="javascript:void(0)"
                      onClick={(e) => {
                        document.title ='DGFP eLMIS - '+ DispensingLanguage[lan][menukey]["Logout"];
                        e.preventDefault();
                        sessionStorage.clear();
                        setTimeout(() => {
                          props.history.push("/login");
                        }, 1000);
                      }}
                    >
                      {DispensingLanguage[lan][menukey]['Logout']}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* =========Mobile Menu======== */}
    </>
  );
}

export default AfterLoginNavbar;
