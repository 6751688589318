import React, { forwardRef, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import swal from "sweetalert";
import MaterialTable from "material-table";
//Import Preloader
import LoadingSpinnerOpaque from "services/LoadingSpinnerOpaque";
import {
  Typography,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';


import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import { DeleteOutline, Edit, ViewList } from "@material-ui/icons";

import { useTranslation } from "react-i18next";

import { Button } from "reactstrap";

import { useQuery, useMutation, useQueryClient } from "react-query";
import * as api from "../../../actions/api";

// react-tabulator
import "react-tabulator/lib/styles.css"; // required styles
import "react-tabulator/lib/css/tabulator.min.css"; // theme
import { ReactTabulator, reactFormatter } from "react-tabulator";
import ExportButtons from "../../../components/ExportButtons";
//import Tooltip from '@material-ui/core/Tooltip';

import MomentUtils from "@date-io/moment";
import moment from "moment";

//Services
import * as Service from "../../../services/Service.js";

const UnitEntry = (props) => {
  const permissionType = props.permissionType;
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const FacilityId = localStorage.getItem("FacilityId");
  const menukey = "unit-entry";
  const FacilityName = localStorage.getItem("FacilityName");
  const UserInfo = sessionStorage.getItem("User_info")
    ? JSON.parse(sessionStorage.getItem("User_info"))
    : 0;
  const UserName = UserInfo == 0 ? "" : UserInfo[0].id;
  const { useState } = React;
  //Group working
  const [firstLoad, setFirstLoad] = useState(true);

  const getWarehouseList = JSON.parse(localStorage.getItem("gWarehouseListAll"));
  getWarehouseList.unshift({'id':"0",'name':"All"});







  const [WarehouseId, selectWarehouse] = useState(0);
  //console.log('WareHouse id',WarehouseId);
  let TempItemList = useState([]);

  const [DistrictList, setDistrictItem] = useState([]);
  //console.log('District send by warehouse id',DistrictList);
  const [DistrictId, selectDistrictData] = useState(0);
  //console.log('Test Indentor data',DistrictId);

  const [UpzilaList, setUpozilaItem] = useState(TempItemList);
  //console.log('contract combo Data',UpzilaList);
  const [UpazilaId, SetUpzilaLocal] = useState(0);
  //console.log('Show Package Data',CureentContract);



  const classes = useStyles();
  const tableRef = useRef();
  const [selectedRow, setSelectedRow] = useState(null);
  const [numberRowPerPage, setNumberRowPerPage] = useState(5);
  const [totalRow, setTotalRow] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [dataItems, setDataItems] = useState([]);
  const FacilityEmployee = JSON.parse(localStorage.getItem("FacilityEmployee"));

  const { t, i18n } = useTranslation();
  const queryClient = useQueryClient();

  const [isLoading, setLoading] = useState(true);


  //====== Start Choosen Dropdown===========
  const [chosenValuesWarehouse, setChosenValuesWarehouse] = useState({
    "Warehouse": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
  
  });

  const [chosenValuesDistrict, setChosenValuesDistrict] = useState({
    "DistrictName": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
  
  });

  const [chosenValuesUpazila, setChosenValuesUpazila] = useState({
    "upazila_name": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
  
  });


  
const handleChangeChoosenWarehouse = (name, valueobj, value) => {
  let chosenValuesDataWarehouse = { ...chosenValuesWarehouse};
  chosenValuesDataWarehouse[name] = valueobj;
  setChosenValuesWarehouse(chosenValuesDataWarehouse); 

  setFirstLoad(true);
  setLoading(false);
  selectWarehouse(value);

 setChosenValuesDistrict({
    "DistrictName": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
   }); 
  
   setChosenValuesUpazila({
    "upazila_name": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
   }); 

 

};

const handleChangeChoosenDistrict = (name, valueobj, value) => {
  let chosenValuesDataDistrict = { ...chosenValuesDistrict};
  chosenValuesDataDistrict[name] = valueobj;
  setChosenValuesDistrict(chosenValuesDataDistrict); 

  setFirstLoad(true);
  setLoading(false);
  selectDistrictData(value);

 setChosenValuesUpazila({
    "upazila_name": {"id": "0","name": t(DispensingLanguage[lan][menukey]["All"])}
   }); 


};


const handleChangeChoosenUpazila = (name, valueobj, value) => {
  let chosenValuesDataUpazila = { ...chosenValuesUpazila};
  chosenValuesDataUpazila[name] = valueobj;
  setChosenValuesUpazila(chosenValuesDataUpazila); 

  setFirstLoad(true);
  setLoading(false);
  SetUpzilaLocal(value);

};



//====== End Choosen Dropdown===========

  let params = {
    action: 'getUnitEntryList',
    menukey: menukey,
    FacilityId: FacilityId,
    WarehouseId: WarehouseId,
    DistrictId: DistrictId,
    UpazilaCode: UpazilaId,
    page_limit: JSON.stringify(100),
    page_number: JSON.stringify(0 + 1),
    search_key: "",

    //TransactionTypeId: 3,


  };
  const { error, isError, data, refetch } = useQuery(
    [params],
    api.getUnitEntry,
    {
      onSuccess: (data) => {
        if (firstLoad) {
          localStorage.setItem(
            "UnitEntry",
            JSON.stringify(data)
          );

          setDataItems(data);
          setLoading(false);
          setFirstLoad(false);
        }


      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

 


  const { mutate:deleteApi } = useMutation(api.deleteUnit, {
    onSuccess: (data) => {
      if (data.data.status == 200) {
     setFirstLoad(true);
     refetch();
     queryClient.getQueriesData("UnitEntry");


      
      props.openNoticeModal({
        isOpen: true,
        msg: data.data.message,
        msgtype: data.data.success,
      });

      }else{ 
        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });  
           
        }
    },
  });








  /* =====Start of Excel Export Code==== */
  const EXCEL_EXPORT_URL = process.env.REACT_APP_API_URL;

  const PrintPDFExcelExportFunction = (reportType) => {

   
    let finalUrl = EXCEL_EXPORT_URL + "report/print_pdf_excel_server.php";
    // let StartDate = moment(selectedStartDate).format("YYYY-MM-DD");
    //let EndDate = moment(selectedEndDate).format("YYYY-MM-DD");

    window.open(
      finalUrl +
      "?action=getUnitEntry" +
      "&reportType=" +
      reportType +
      "&menukey=" +
      menukey +
      "&lan=" +
      localStorage.getItem("LangCode") +
      "&FacilityId=" +
      FacilityId +

      "&WarehouseId=" +
      WarehouseId +
      "&DistrictId=" +
      DistrictId +
      "&UpazilaCode=" +
      UpazilaId +


      "&TimeStamp=" + Date.now()
    );
  };
  /* =====End of Excel Export Code==== */

  const tableRowDoubleClick = (e: any, row: any) => {
    let rowData = row._row.data;
    editRecord(rowData);
  };

  const columns = [
    { title: "Id", field: "id", visible: false },
    {
      title: DispensingLanguage[lan][menukey]["Sl#"],
      hozAlign: "center",
      width: "5%",
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Upazila"],
      field: "upazila_name",
      width: "50%",
      headerFilter: true,
    },
    {
      title: DispensingLanguage[lan][menukey]["Unit"],
      width: "40%",
      field: "sunitname",
      headerFilter: true,
    }, {
      title: DispensingLanguage[lan][menukey]["Actions"],
      field: "custom",
      headerSort: false,
      headerHozAlign: "center",
      hozAlign: "center",
      width: "5%",
      formatter: reactFormatter(<ActionButton />),
    },
  ];




  const WarehousehandleChange = (event) => {

    setFirstLoad(true);
    setLoading(false);
    selectWarehouse(event.target.value);
    //console.log('warehouse Data',event.target.value);

  };


  const DistricthandleChange = (event) => {
    setFirstLoad(true);
    setLoading(false);
    selectDistrictData(event.target.value);
  };


  React.useEffect(() => {
    selectDistrictData(0);
    setDistrictData();
  }, [WarehouseId]);

  const setDistrictData = () => {

    let getDistrictParam = { action: "getDistrictList", WarehouseId: WarehouseId, FacilityId: FacilityId, menukey: "" }

    Service.default
      .postApi("source/combo_generic.php", getDistrictParam)
      .then((res) => {

        let All_District_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
        const District_List = [All_District_label].concat(res.datalist); 

        setDistrictItem(District_List);

      })
      .catch((err) => { });

  }


  const UpazilahandleChange = (event) => {
    setFirstLoad(true);
    setLoading(false);
    SetUpzilaLocal(event.target.value);
  };

  React.useEffect(() => {
    SetUpzilaLocal(0);
    ContractItem();
  }, [DistrictId, WarehouseId]);

  const ContractItem = () => {

    let CurrentContractParam = { action: "getUpazilaList", DistrictId: DistrictId, WarehouseId: WarehouseId, FacilityId: FacilityId, menukey: "" }

    Service.default
      .postApi("source/combo_generic.php", CurrentContractParam)
      .then((res) => {
        
        let All_Upazila_label = {id:"0", name: t(DispensingLanguage[lan][menukey]["All"])};
        const Upazila_List = [All_Upazila_label].concat(res.datalist); 
        setUpozilaItem(Upazila_List);

      })
      .catch((err) => { });

  }







  const handleChangePage = (data) => {
    setPageNumber(data);
    // setFirstLoad(false);
  };
  const handleChangeRowPerPage = (data) => {
    setNumberRowPerPage(data);
  };

  const editRecord = (data) => {
    console.log('data d', data);
    let route = `unit-entry/edit/${data.Uid}`;
    props.history.push(route);
  };

  const deleteunit = (data) => {
    swal({
      title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
      text: t(
        DispensingLanguage[lan][menukey][
          "Once deleted, you will not be able to recover this Record!"
        ]
      ),
      icon: "warning",
      buttons: {
        confirm: {
          text: t(DispensingLanguage[lan][menukey]["Yes"]),
          value: true,
          visible: true,
          className: "",
          closeModal: true,
        },
        cancel: {
          text: t(DispensingLanguage[lan][menukey]["No"]),
          value: null,
          visible: true,
          className: "",
          closeModal: true,
        },
      },
      /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
      t(DispensingLanguage[lan][menukey]["Yes"])],
      dangerMode: true, */
    }).then((willDelete) => {
      if (willDelete) {
          deleteApi({sunitid:data.id,UpazilaCode:data.UpazilaCode,"menukey": menukey,"lan":lan,
          "UserName":UserName,"action": "dataDalete"});
      }
    });
  };

  function ActionButton(props) {
    const rowData = props.cell._cell.row.data;
    // const cellValue = props.cell._cell.value || "Edit | Show";

    if (rowData.bStockUpdated || rowData.BCancel || permissionType) {
      return (
        <>
          <ViewList
            onClick={() => {
              editRecord(rowData);
            }}
          />
        </>
      );
    } else {
      return (
        <>
          <Edit
            onClick={() => {
              editRecord(rowData);
            }}
          />
          {/* <DeleteOutline
            onClick={() => {
              deleteunit(rowData);
            }}
          /> */}
        </>
      );
    }
  }



  return (
    <>
      <div className={classes.productPageTitle}>
        <div className="d-flex justify-product mb-2">
          <Grid item xs={12} sm={12}>
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {DispensingLanguage[lan][menukey]["Unit Entry"]}
              </div>
            </div>
          </Grid>
        </div>

        {/* start of filter */}
        <Card className="sw_card sw_filter_card">
          <CardContent className="sw_filterCardContent">
            <Grid container>

              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  {/* <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]['Select Warehouse']}
                  </InputLabel>
                  <Select
                    required
                    labelId="demo-simple-select-helper-label"
                    id="Warehouse"
                    name="Warehouse"
                    value={WarehouseId}
                    fullWidth
                    onChange={WarehousehandleChange}
                  >
                    <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                    {getWarehouseList.map((item, index) => {
                      return (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      );
                    })}
                  </Select> */}

                        <Autocomplete
                          autoHighlight
                          disableClearable
                          id="Warehouse"
                          options={getWarehouseList}
                          onChange={(event, valueobj) => handleChangeChoosenWarehouse('Warehouse', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValuesWarehouse['Warehouse']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Select Warehouse"]}
                            variant="standard"
                            id="Warehouse"
                            name="Warehouse"
                            fullWidth
                          />
                          )}
                        />

                </FormControl>
              </Grid>
              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  {/* <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]['Select District']}
                  </InputLabel>
                  <Select
                    required
                    labelId="demo-simple-select-helper-label"
                    id="DistrictName"
                    name="DistrictName"
                    value={DistrictId}
                    fullWidth
                    onChange={DistricthandleChange}
                  >
                    <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                    {DistrictList.map((item, index) => {
                      return (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      );
                    })}
                  </Select> */}

                        <Autocomplete
                          autoHighlight
                          disableClearable
                          id="DistrictName"
                          options={DistrictList}
                          onChange={(event, valueobj) => handleChangeChoosenDistrict('DistrictName', valueobj, valueobj?valueobj.id:"")}
                          getOptionLabel={(option) => option.name}
                          value={chosenValuesDistrict['DistrictName']}
                          renderOption={(option) => (
                          <Typography className="sw_fontSize">{option.name}</Typography>
                          )}
                          renderInput={(params) => (
                          <TextField
                            {...params}
                            label={DispensingLanguage[lan][menukey]["Select District"]}
                            variant="standard"
                            id="DistrictName"
                            name="DistrictName"
                            fullWidth
                          />
                          )}
                        />
                </FormControl>
              </Grid>
              <Grid item xs={3} sm={3}>
                <FormControl className={classes.fullWidth}>
                  {/* <InputLabel id="demo-simple-select-helper-label">
                    {DispensingLanguage[lan][menukey]['Select Upazila']}
                  </InputLabel>
                  <Select
                    required
                    labelId="demo-simple-select-helper-label"
                    id="upazila_name"
                    name="upazila_name"
                    value={UpazilaId}
                    fullWidth
                    onChange={UpazilahandleChange}
                  >
                    <MenuItem value="0"> {DispensingLanguage[lan][menukey]["All"]}</MenuItem>
                    {UpzilaList.map((item, index) => {
                      return (
                        <MenuItem value={item.id}>{item.name}</MenuItem>
                      );
                    })}
                  </Select> */}

                          <Autocomplete
                            autoHighlight
                            disableClearable
                            id="upazila_name"
                            options={UpzilaList}
                            onChange={(event, valueobj) => handleChangeChoosenUpazila('upazila_name', valueobj, valueobj?valueobj.id:"")}
                            getOptionLabel={(option) => option.name}
                            value={chosenValuesUpazila['upazila_name']}
                            renderOption={(option) => (
                            <Typography className="sw_fontSize">{option.name}</Typography>
                            )}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              label={DispensingLanguage[lan][menukey]["Select Upazila"]}
                              variant="standard"
                              id="upazila_name"
                              name="upazila_name"
                              fullWidth
                            />
                            )}
                          />
                </FormControl>
              </Grid>


              <Grid item xs={3} sm={3}>
                <div className="float-right sw_btn_control">
                  <Button
                    disabled={permissionType}
                    color="info"
                    className="mr-2"
                    onClick={() => {
                      props.history.push("/unit-entry/add");
                    }}
                  >
                  {DispensingLanguage[lan][menukey]["Add New"]}

                  </Button>

                  <ExportButtons PrintPDFExcelExportFunction={PrintPDFExcelExportFunction}  {...props}></ExportButtons>

                  {/* <Tooltip  title="Print">
                    <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("print")}
                  >
                    <i class="fas fa-print"></i>
                  </Button>
                  </Tooltip>
                  
                  <Tooltip  title="Excel">
                  <Button
                    color="info"
                    className="mr-2 sw_icons"
                    onClick={() => PrintPDFExcelExportFunction("excel")}
                  >
                    <i className="far fa-file-excel"></i>
                  </Button>
                  </Tooltip> */}


                  

                  

                



                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {/* end of filter */}

        <div className="sw_relative">
          {isLoading && <LoadingSpinnerOpaque />}
          <div className="uniqueName">
            <ReactTabulator
              columns={columns}
              data={dataItems}
              layout={"fitColumns"}
              height="600px"
              rowDblClick={(e, row) => tableRowDoubleClick(e, row)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UnitEntry;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    background: "whitesmoke",
    color: "black",
    borderRadius: "10px",
    padding: "1rem",
  },
  tableContainer: { backgroundColor: "whitesmoke", borderRadius: "10px" },
  fullWidth: {
    width: "95%",
  },
  filterDiv: {
    width: "80%",
    display: "flex",
  },
});
