import React, { useState, useEffect, useContext } from "react";
import {checkLogin, checkUserPermission} from "../../services/CheckUserAccess";
import swal from "sweetalert";
// material components
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import {
  Card,
  CardHeader,
  CardContent,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";

import { Link, AddToHomeScreen, Launch,ViewList } from "@material-ui/icons";

// core components
import AfterLoginNavbar from "components/Navbars/AfterLoginNavbar.js";

import { useQuery } from "react-query";
import * as api from "../../actions/api";
import ProductSelectInPrescriptionModal from "../../services/IssueInvoiceDetailsModal";

import { useTranslation } from "react-i18next";
// Highcharts component
import Highcharts from "highcharts/highstock";
import exporting from "highcharts/modules/exporting.js";
import HighchartsReact from "highcharts-react-official";

import Constants from "services/Constants.js";
import { UserContext } from "../../context/user-info-context";
import { getDefaultMonthYear } from "../../services/Common";

import { ReactTabulator,reactFormatter } from "react-tabulator";
 
const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    width: "20%",
    margin: "0 1rem",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  cardDiv: {
    display: "flex",
  },
  fullWidth: {
    width: "100%",
  },
});

const DashboardPage = (props) => {
  //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );

  const menukey = "mcwc-dashboard";

  
  const [RedirectLogin, setRedirectLogin] = React.useState(true);
  const [hasUserPermission, setHasUserPermission] = React.useState(false);

  if (RedirectLogin) {
    setHasUserPermission(checkUserPermission(menukey));// To check user has permission in this page
    checkLogin();
    setRedirectLogin(false);
  }

  const CurrentFacilityName = localStorage.getItem("FacilityName");
  const [DashboardMetaInfo, setDashboardMetaInfo] = useState([]);
  const [CurrentStockStatusChartData, setCurrentStockStatusChartData] = useState([]);
  const [ExpiryData, setExpiryData] = useState([]);

  const FacilityId = localStorage.getItem("FacilityId");

  const [WaitingForReceiveData, setWaitingForReceiveData] = useState([]);
  const [WaitingForReceiveCount,setWaitingForReceiveCount] = useState(0);

  const [NumberOfAssetStatusData, setNumberOfAssetStatusData] = useState([]);

  exporting(Highcharts);
  const userCtx = useContext(UserContext);
  const lan = localStorage.getItem("LangCode");
  const { t, i18n } = useTranslation();
  const UseFor = localStorage.getItem("UseFor");

  const [currYearId, setCurrYearId] = useState(
    getDefaultMonthYear().defaultYear
  );
  const [currMonthId, setCurrMonthId] = useState(
    getDefaultMonthYear().defaultMonthId
  );

  const [currRowId, setCurrRowId] = useState(0);
  const [InvType, setInvType] = useState(0);
  const [CurrFacilityCode, setCurrFacilityCode] = useState(0);
  const [openSqlLog, setopenSqlLog] = useState(false);
  const [LogDataArray, setLogDataArray] = useState([]);

  const handleClose = (modalname) => {
    switch (modalname) {
    
      case "ProductBatch":
        setopenSqlLog(false);
        break;
      case "ProductBatchOpen":
        setopenSqlLog(true);
        break;

      default:
        break;
    }
  };

  const goToTrendOfActivePatients = () => {
    window.open(process.env.REACT_APP_BASE_NAME + `/trend-of-active-patients`);
  };

  const goToAdultPatientsByProtocol = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME + `/adult-patients-by-protocol`
    );
  };

  const goToLotsExpiringReport = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME + `/stock-expiry-By-lot`
    );
  };

  const goToNumberOfAssetStatusReport = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME + `/asset-reports`
    );
  };

  const goToCurrentContraceptiveStockStatus = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME + `/stock-status`
    );
  };
  const goToProducts = () => {
    window.open(
      process.env.REACT_APP_BASE_NAME + `/stock-status`
    );
  };

const goToAsset = () => {

    window.open(
      process.env.REACT_APP_BASE_NAME + `/asset-reports`
    );
};

  const goToF3 = () => {

      window.open(
        process.env.REACT_APP_BASE_NAME  + `/mcwc-monthly-form3`
      );
  };

  function ShowSqlLogPopup(props: any) {
    const rowData = props.cell._cell.row.data;

      return (
        <>
          <ViewList
            onClick={() => {
              setCurrRowId(rowData.TransactionId);
              setCurrFacilityCode(rowData.FacilityId);
              setInvType(rowData.TransactionNo);
              handleClose("ProductBatchOpen");
            
            }}
          />
        </>
      );
  }

  const { data:WaitingForReceive } = useQuery(
    "WaitingForReceives",
    api.getMCWCWaitingForReceiveData,
    {
      onSuccess: (data) => {
        setWaitingForReceiveData(data);
        setWaitingForReceiveCount(data.length);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );

  const WaitingForReceiveColumns = [
    {
      title: DispensingLanguage[lan][menukey]["Invoice No"],
      headerFilter:true,
      field: "InvNo",
      width: 130
    },
    {
      title: DispensingLanguage[lan][menukey]["Issued Date"],
      headerFilter:false,
      field: "IssuedDate",
      width: 180,
      formatter: function (cell, formatterParams) {
        let value = cell.getValue();
        return (value==null?'':moment(value).format("DD/MM/YYYY"));
      }
    },
    {
      title: DispensingLanguage[lan][menukey]["Issued From"],
      headerFilter:true,
      field: "IssuedFrom"
    },
    {
      title: DispensingLanguage[lan][menukey]["Issued To"],
      headerFilter:true,
      field: "IssuedTo",
      visible: false
    },
  ];

  const { data:NumberOfAssetStatus } = useQuery(
    "NumberOfAssetStatus",
    api.getNumberOfAssetStatusData,
    {
      onSuccess: (data) => {
        setNumberOfAssetStatusData(data);
        // setWaitingForReceiveCount(data.length);
      },
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 0,//1000 * 60 * 60 * 24,
    }
  );
  
  const NumberOfAssetStatusColumns = [
    {
      title: DispensingLanguage[lan][menukey]["SL#"],
      hozAlign: "center",
      width: 70,
      formatter: "rownum",
      headerHozAlign: "center",
      headerSort: false,
    },
    {
      title: DispensingLanguage[lan][menukey]["Asset Status"],
      headerFilter:true,
      field: "MStatus"
    },
    {
      title: DispensingLanguage[lan][menukey]["Number of Asset"],
      width: 150,
      headerFilter:false,
      field: "NumberOfAsset",
      headerHozAlign: "right",
      hozAlign: "right"
    },
  ];

  const getCurrentMetaInfo = useQuery(
    ["maindashboardmetainfo"],
    () => api.getMCWCCurrentMetaInfo(),
    {
      onSuccess: (data) => {
        setDashboardMetaInfo(data);
      },
    }
  );

  const getCurrentStockStatusChartData = useQuery(
    ["dashboardcurrentstockstatus"],
    () => api.getMCWCCurrentStockStausChart(),
    {
      onSuccess: (data) => {

        setCurrentStockStatusChartData({
          chart: {
            type: "bar",
            borderColor: "#C3DDEC",
            height: 260,
          },
          title: {
            text: DispensingLanguage?t(DispensingLanguage[lan][menukey]["Current Contraceptive Stock Status"]):"",
          },
          xAxis: {
            categories: data?data.category:[],
          },
          yAxis: {
            min: 0,
            //max: 100,
            title: {
              text: DispensingLanguage?t(DispensingLanguage[lan][menukey]["MOS"]):"",
            },
          },
          legend: {
            enabled: false,
          },
          credits: {
            enabled: false,
          },
          tooltip: {
            //    valueSuffix: " %",
            formatter: function() {
              return '<b>' + this.x + '</b><br/>MOS: ' + this.y.toFixed(1) + '<br/>Qty: '+ this.point.UsableQty;
          }

          },
          plotOptions: {
            bar: {
              dataLabels: {
                enabled: true,
                crop: true,
                formatter: function () {
                  // console.log("dataLabels:",this.point.UsableQty);
                  return this.y.toFixed(1); // + "%"
                  
                  // return "MOS: "+this.y + " (Qty: "+this.point.UsableQty+")"; // + "%"
                },
              },
            },
          },
          series: [
            {
              name: DispensingLanguage?t(DispensingLanguage[lan][menukey]["MOS"]):"",
              colorByPoint: true,
              data: data?data.seriesdata:[], //[69.8, 18.2, 2.3, 2.1, 1.5, 1.5, 1, 0.8, 0.6, 0.6, 2.5],
            },
          ],
        });
      },
      // refetchOnWindowFocus: false,
      // enabled: false,
    }
  );

  const getExpiryData = useQuery(
    ["dashboardexpirystockstatus"],
    () => api.getMCWCExpiryData(),
    {
      onSuccess: (data) => {

        setExpiryData({
          chart: {
            type: "pie",
            borderColor: "#C3DDEC",
            height: 310,
          },
          title: {
            text: (data?data.productcount:"") +' '+ (DispensingLanguage?t(DispensingLanguage[lan][menukey]["Lots expiring in 6 months"]):""),
          }, 
          yAxis: {
            min: 0,
            max: 100,
          },
          legend: {
            enabled: true,
          },
          credits: {
            enabled: false,
          },
          tooltip: {
          },
          plotOptions: {
            pie: {
              showInLegend: true,
              dataLabels: {
                enabled: false,
                crop: true,
                formatter: function () {
                  return this.y; // + "%"
                },
              },
            },
          },
          series: [
            {
              name: DispensingLanguage?t(DispensingLanguage[lan][menukey]["Products"]):"",
              colorByPoint: true,
              data: data?data.seriesdata:[]
            },
          ],
        });
      },
    }
  );

  const classes = useStyles();

  React.useEffect(() => {
    // checkLogin();
    // checkAccess();
  }, []);

  return (
    hasUserPermission && (
    <>
      <AfterLoginNavbar {...props} />

      <div
        className="section signup-top-padding"
        style={{
          backgroundImage: "url(" + require("assets/img/bg8.jpg") + ")",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          minHeight: "753px",
        }}
      >
        <div className="dashboard-pannel">
          <div className="d-flex justify-product mb-1">
            <div className="sw_page_heading">
              <div className="sw_heading_title">
                {t(DispensingLanguage[lan][menukey]["MCWC Dashboard"])}
              </div>
            </div>
          </div>

          <ProductSelectInPrescriptionModal
                handleClose={handleClose}
                open={openSqlLog}
                FacilityId={CurrFacilityCode}
                SCurrRowId={currRowId}
                SInvType={InvType}
                {...props}
              />

          {/* new row */}

          <div className="row">
            <div className="col-md-12 mb-2">
              <Card className="sw_card ">
              <CardContent>
                <div className="stat-cell stat-cell-color-e">
                  <div className="row margin0auto">
                    <div className="col-md-12 ">

                      <span className="text-xlg ">
                        {DispensingLanguage[lan][menukey]["DGFP Electronics Logistics Management Information System (eLMIS)"]} 
                      </span>
                    </div>
                  </div>

                  <div className="row margin0auto">
                       <div className="col-md-4 ">   
                          <span>
                            {DispensingLanguage[lan][menukey]["Date"]}: {DashboardMetaInfo.CurrentDate}
                          </span>
                        </div>

                    <div className="col-md-8 ">
                      <span className="text-xlm pull-right">
                        &nbsp;  {CurrentFacilityName}
                      </span>

                      <span className="marginTop5 pull-right">
                        {UseFor == 'WIMS' ? '' : t(DispensingLanguage[lan][menukey]["Upazila Family Planning Store"])}
                      </span>

                    </div>

                  </div>
                </div>
                </CardContent>
              </Card>
            </div>
          </div>

          {/* product and upazila */}
          <div className="row">

            <div className="col-md-4 mb-3">
              <Card className="sw_card">
                <CardContent>
                  <div className="row">
                    <div className="col-md-12 ">
                      <div className="stat-cell stat-cell-color-a ">
                        <i className="fa fa-cubes bg-icon"></i>

                        <a
                            href="javascript:void(0);"
                            className="HyColor iconPositionRight"
                            onClick={goToProducts}
                          >
                            <Launch />
                        </a>

                        <span className="text-xlg" id="total-patients">
                          {DashboardMetaInfo.TotalItemCount}
                        </span>
                        <br></br>
                        <span id="totalcase" className="text-bg ">
                          {t(
                            DispensingLanguage[lan][menukey][
                            "Products in Stock"
                            ]
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>

            <div className="col-md-3 mb-3">
              <Card className="sw_card">
                <CardContent>
                  <div className="row">
                    <div className="col-md-12 ">
                      <div className="stat-cell stat-cell-color-c ">
                        <i className="fa fa-cubes bg-icon"></i>

                        <a
                            href="javascript:void(0);"
                            className="HyColor iconPositionRight"
                            onClick={goToAsset}
                          >
                            <Launch />
                        </a>

                        <span className="text-xlg" id="total-patients">
                          {DashboardMetaInfo.TotalAsset}
                        </span>
                        <br></br>
                        <span id="totalcase" className="text-bg ">
                          {t(
                            DispensingLanguage[lan][menukey][
                            "Asset"
                            ]
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>

            <div className="col-md-5 mb-3">
              <Card className="sw_card">
                <CardContent>
                  <div className="row">
                    <div className="col-md-12 ">
                      <div className="stat-cell stat-cell-color-d" style={{padding:'20px 8px'}}>
                        <i className="fa fa-file bg-icon"></i>
                        <a
                            href="javascript:void(0);"
                            className="HyColor iconPositionRight"
                            onClick={goToF3}
                          >
                            <Launch />
                        </a>
                        <span className="text-xlg" id="total-patients">
                          {DashboardMetaInfo.Last7}
                        </span>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>

          </div>
          {/* product and upazila */}


          <div className="row">

            <div className="col-md-6">

              <Card className="sw_card mb-3">
                <CardHeader
                  // title={t(DispensingLanguage[lan][menukey]["Current Contraceptive Stock Status"])}
                  action={
                    <a
                      href="javascript:void(0);"
                      className="HyColor"
                      onClick={goToCurrentContraceptiveStockStatus}
                    >
                      <Launch />
                    </a>
                  }
                />

                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={CurrentStockStatusChartData}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="mylegend_area">
                       
                            <div className="my_legend">
                              <div
                                className="mylegend_color"
                                style={{ background: "#d7191c" }}
                              ></div>
                              <div className="mylegend_label"> {t(DispensingLanguage[lan][menukey]["MOS"])}: &#60; 0.1 </div>
                             
                            </div>

                            <div className="my_legend">
                              <div
                                className="mylegend_color"
                                style={{ background: "#FE9929" }}
                              ></div>
                              <div className="mylegend_label"> {t(DispensingLanguage[lan][menukey]["MOS"])}: 0.1 - 0.6 </div>
                            
                            </div>

                            <div className="my_legend">
                              <div
                                className="mylegend_color"
                                style={{ background: "#F0F403" }}
                              ></div>
                              <div className="mylegend_label"> {t(DispensingLanguage[lan][menukey]["MOS"])}: 0.6 - 1.7 </div>
                            
                            </div>

                            <div className="my_legend">
                              <div
                                className="mylegend_color"
                                style={{ background: "#4DAC26" }}
                              ></div>
                              <div className="mylegend_label"> {t(DispensingLanguage[lan][menukey]["MOS"])}: 1.7 - 3 </div>
                             
                            </div>

                            <div className="my_legend">
                              <div
                                className="mylegend_color"
                                style={{ background: "#50ABED" }}
                              ></div>
                              <div className="mylegend_label"> {t(DispensingLanguage[lan][menukey]["MOS"])}: 3+  {t(DispensingLanguage[lan][menukey]["MOS"])} </div>
                             
                            </div>
                      </div>
                    </div>
                  </div>

                </CardContent>
              </Card>
            </div>


            <div className="col-md-6">
              <Card className="sw_card">
                <CardHeader
                  action={
                    <a
                      href="javascript:void(0);"
                      className="HyColor"
                      onClick={goToLotsExpiringReport}
                    >
                      <Launch />
                    </a>
                  }
                />

                <CardContent>
                  <div className="row">
                    <div className="col-md-12">
                      <HighchartsReact
                        highcharts={Highcharts}
                        options={ExpiryData}
                      />
                    </div>
                  </div>
                </CardContent>
              </Card>
            </div>
          </div>

            <div className="row">           
                <div className="col-md-6 mb-4">
                  <Card className="sw_card">
                    <CardHeader
                      title={DispensingLanguage[lan][menukey]["Waiting for Receive"]}
                    />
                    <CardContent>
                      <ReactTabulator
                        columns={WaitingForReceiveColumns}
                        data={WaitingForReceiveData}
                        layout={"fitColumns"}
                        height={300}
                      />
                    </CardContent>
                  </Card> 
                </div>
                <div className="col-md-6 mb-4">
                  <Card className="sw_card">
                    <CardHeader
                      title={DispensingLanguage[lan][menukey]["Number of Asset Status"]}
                      action={
                        <a
                          href="javascript:void(0);"
                          className="HyColor"
                          onClick={goToNumberOfAssetStatusReport}
                        >
                          <Launch />
                        </a>
                      }
                    />
                    <CardContent>
                      <ReactTabulator
                        columns={NumberOfAssetStatusColumns}
                        data={NumberOfAssetStatusData}
                        layout={"fitColumns"}
                        height={300}
                      />
                    </CardContent>
                  </Card> 
                </div>
            </div>

        </div>
      </div>
    </>
    )
  );
};

export default DashboardPage;
